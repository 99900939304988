import * as React from 'react';
import { RouteChildrenProps } from 'react-router';
import { Col, FormGroup, Label } from 'reactstrap';

type FormLabelProps = {
	for?: string;
	horizontal?: boolean | number;
	noWrap?: boolean;
	children?: ((props: RouteChildrenProps<any>) => React.ReactNode) | React.ReactNode;
}

export const FormLabel = (props: FormLabelProps) => {
	const xs: number | undefined = !props.horizontal ? undefined
		: typeof props.horizontal === 'number' ? props.horizontal : 3;
	return <Label xs={xs} for={props.for} className={props.noWrap ? 'text-nowrap' : ''}>{props.children}</Label>;
}


type FormFieldProps = {
	horizontal?: boolean | number;
	addBreak?: boolean;
	children?: ((props: RouteChildrenProps<any>) => React.ReactNode) | React.ReactNode;
}

export const FormField = (props: FormFieldProps) => {
	if (props.horizontal) {
		const xs: number = typeof props.horizontal === 'number' ? 12 - props.horizontal : 9;
		return <Col xs={xs}>{props.children}</Col>;
	} else {
		return (
			<>
				{props.addBreak && <br />}
				{props.children}
			</>
		);
	}
}


export const FormFieldOnly = (props: FormFieldProps) => {
	const horizontal = props.horizontal === undefined || props.horizontal;
	if (horizontal) {
		const xs: number = typeof props.horizontal === 'number' ? 12 - props.horizontal : 9;
		return <Col xs={xs} className={`offset-${12 - xs}`}>{props.children}</Col>;
	} else {
		return (
			<>
				{props.addBreak && <br />}
				{props.children}
			</>
		);
	}
}


export const FormGroupContent = (props: FormFieldProps) => {
	const horizontal = props.horizontal === undefined || props.horizontal;
	return (
		<FormGroup row={!!horizontal}>
			<FormFieldOnly horizontal={horizontal} addBreak={props.addBreak}>
				{props.children}
			</FormFieldOnly>
		</FormGroup>
	);
}
