import * as React from 'react';
import { useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { ApplicationState } from '../../store';
import { SummaryLoadStatus2 } from '../../store/summary/SummaryCommon';
import { DocumentLibrarySummaryState, userSummaryActionInvokers } from '../../store/summary/UserSummaryStore';
import { LoggedInState } from '../../store/user/LoginStore';
import { UserRole } from '../../store/user/UserCommon';
import * as Icon from '../support/Icon';
import Loading from '../support/Loading';
import { StandardSummarySize } from '../support/Summaries';
import { View, ViewBody } from '../support/Views';
import * as Configuration from '../../Configuration';


export const DocumentLibrarySummary = () => {
	const summary = useSelector(state => (state as ApplicationState).summary.documentLibrary);
	const loggedIn = useSelector(state => (state as ApplicationState).user.login.loggedIn!);
	const history = useHistory();

	React.useEffect(() => {
		userSummaryActionInvokers.requestDocumentLibrary();
	}, []);

	const documentLibraryUrl = '/library/documents/me?name=Me';
	const goToLibrary = () => history.push(documentLibraryUrl);

	return (
		<StandardSummarySize>
			<View header='Document Library' onClickHeader={goToLibrary}>
				<ViewBody>
					<Body summary={summary} documentLibraryUrl={documentLibraryUrl} loggedIn={loggedIn} />
				</ViewBody>
			</View>
		</StandardSummarySize>
	);
}


interface BodyProps {
	summary: DocumentLibrarySummaryState | null;
	documentLibraryUrl: string;
	loggedIn: LoggedInState;
}

const Body = (props: BodyProps) => {
	switch (props.summary?.status) {
		case SummaryLoadStatus2.Loading:
			return <Loading />;
		case SummaryLoadStatus2.Loaded:
			return (
				<>
					<p>Documents: {props.summary.detail.numberOfDocuments}</p>
					<Link to={props.documentLibraryUrl}>Open my library</Link>
					{Configuration.enableFeature.uploadResults && props.loggedIn.role === UserRole.Pilot && (
						<>
							<br />
							<Link to='/organisation/upload?name=Me&userid=me'>Upload a self-test</Link>
						</>
					)}
					{props.loggedIn.role === UserRole.Administrator && (
						<>
							<br />
							Select a pilot and click the <Icon.Library /> icon to see their document library
						</>
					)}
				</>
			);
		case SummaryLoadStatus2.Failure:
			return <p className='text-danger'>Unable to load.</p>;
		default:
			return null;
	}
}
