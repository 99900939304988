// From SkyCert v1

export enum SummaryStatus {
    Loading,
    Success,
    Failure,
}
export enum SummaryLoadStatus2 {
	Idle,
	Loading,
	Loaded,
	Failure,
}
