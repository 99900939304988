import * as React from 'react';
import { useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { ApplicationState } from '../../store';
import { SummaryLoadStatus2 } from '../../store/summary/SummaryCommon';
import * as UserSummaryStore from '../../store/summary/UserSummaryStore';
import Loading from '../support/Loading';
import { StandardSummarySize } from '../support/Summaries';
import { View, ViewBody } from '../support/Views';


export const AssociatedOrganisationsSummary = () => {
	const summaryState = useSelector(state => (state as ApplicationState).summary.associatedOrganisations);
	const history = useHistory();

	React.useEffect(() => {
		UserSummaryStore.userSummaryActionInvokers.requestAssociatedOrganisationsSummary();
	}, []);

	return (
		<StandardSummarySize>
			<View header='Associated Organisations' onClickHeader={() => history.push('/specialist/mySpecialistPractice/organisations')} color='primary'>
				<ViewBody>
					<Body summaryState={summaryState} />
				</ViewBody>
			</View>
		</StandardSummarySize>
	);
}


interface BodyProps {
	summaryState: UserSummaryStore.AssociatedOrganisationsState | null;
}

const Body = (props: BodyProps) => {
	switch (props.summaryState?.status) {
		case undefined:
		case SummaryLoadStatus2.Idle:
			return null;
		case SummaryLoadStatus2.Loading:
			return <Loading />;
		case SummaryLoadStatus2.Failure:
			return <p className='text-danger'>Unable to load!</p>;
		case SummaryLoadStatus2.Loaded:
			return (
				<>
					<p>
						{props.summaryState.summary.numberOfOrganisations} organisation{props.summaryState.summary.numberOfOrganisations !== 1 && 's'}.
					</p>
					<Link to='/specialist/mySpecialistPractice/organisations'>Manage</Link>
				</>
			);
		default:
			throw Error(`The status ${props.summaryState!.status} is not implemented yet.`);
	}
}
