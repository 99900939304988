import { Action, combineReducers, Reducer } from 'redux';
import * as AdminSummaryStore from './AdminSummaryStore';
import * as UserSummaryStore from './UserSummaryStore';

// From SkyCert v1

// STATE **********************************************************************

export interface SummaryState {
	// Administrators only.
	organisations: AdminSummaryStore.OrganisationsState | null;
	practices: AdminSummaryStore.PracticesState | null;
	specialistPractices: AdminSummaryStore.SpecialistPracticesState | null;
	users: AdminSummaryStore.UsersState | null;
	videos: AdminSummaryStore.VideosState | null;

	// Users.
	appointments: UserSummaryStore.AppointmentsState | null;
	associatedOrganisations: UserSummaryStore.AssociatedOrganisationsState | null;
	documentLibrary: UserSummaryStore.DocumentLibrarySummaryState | null;
	invoices: UserSummaryStore.InvoicesState | null;
	mandatoryRequirements: UserSummaryStore.MandatoryRequirementsSummaryState | null;
	medicals: UserSummaryStore.MedicalsState | null;
	organisation: UserSummaryStore.OrganisationState | null;
	organisationPilots: UserSummaryStore.OrganisationPilotsState | null;
	overheadJoin: UserSummaryStore.OverheadJoinSummaryState | null;
	pilot: UserSummaryStore.PilotState | null;
	pilotMedical: UserSummaryStore.PilotMedicalState | null;
	pilotBooking: UserSummaryStore.PilotBookingState | null;
	practice: UserSummaryStore.PracticeState | null;
	practicePilots: UserSummaryStore.PracticePilotsState | null;
	reminders: UserSummaryStore.RemindersState | null;
	specialistPractice: UserSummaryStore.SpecialistPracticeState | null;
	userMessages: UserSummaryStore.UserMessagesSummaryState | null;
	userVideos: UserSummaryStore.UserVideosSummaryState | null;
}

// REDUCERS *******************************************************************

const combinedReducer: Reducer<SummaryState> = combineReducers<SummaryState>({
	organisations: AdminSummaryStore.organisationsReducer,
	practices: AdminSummaryStore.practicesReducer,
	specialistPractices: AdminSummaryStore.specialistPracticesReducer,
	videos: AdminSummaryStore.videosReducer,
	users: AdminSummaryStore.usersReducer,

	appointments: UserSummaryStore.appointmentsReducer,
	associatedOrganisations: UserSummaryStore.associatedOrganisationsReducer,
	documentLibrary: UserSummaryStore.documentLibraryReducer,
	invoices: UserSummaryStore.invoicesReducer,
	mandatoryRequirements: UserSummaryStore.mandatoryRequirementsReducer,
	medicals: UserSummaryStore.medicalsReducer,
	organisation: UserSummaryStore.organisationReducer,
	organisationPilots: UserSummaryStore.organisationPilotsReducer,
	overheadJoin: UserSummaryStore.overheadJoinReducer,
	pilot: UserSummaryStore.pilotReducer,
	pilotMedical: UserSummaryStore.pilotMedicalReducer,
	pilotBooking: UserSummaryStore.pilotBookingReducer,
	practice: UserSummaryStore.practiceReducer,
	practicePilots: UserSummaryStore.practicePilotsReducer,
	reminders: UserSummaryStore.remindersReducer,
	specialistPractice: UserSummaryStore.specialistPracticeReducer,
	userMessages: UserSummaryStore.userMessagesReducer,
	userVideos: UserSummaryStore.userVideosReducer,
})

export const reducer: Reducer<SummaryState> = (state: SummaryState | undefined, action: Action) => {
	if (action.type === 'REQUEST_LOGOUT')
		state = undefined;
	return combinedReducer(state, action);
}
