import * as React from 'react';
import { ReactNode, useState } from 'react';
import { RouteChildrenProps } from 'react-router';
import { Card, CardBody, CardHeader, Table } from 'reactstrap';
import ChildrenProps from './ChildrenProps';
import * as Icon from './Icon';
import { LayoutContainer, LayoutSize } from './Layouts';


///////////////////////////////////////////////////////////////////////////////

export const StandardViewSize = (props: ChildrenProps) => (
	<LayoutSize gridClassName='col-12 col-md-6 col-xl-4'>
		{props.children}
	</LayoutSize>
);


///////////////////////////////////////////////////////////////////////////////

export const StandardParentViewSize = (props: ChildrenProps) => (
	<LayoutSize gridClassName='col-12 col-md-6 col-xl-8'>
		{props.children}
	</LayoutSize>
);

export const StandardChildViewSize = (props: ChildrenProps) => (
	<LayoutSize gridClassName='col-12 col-xl-4'>
		{props.children}
	</LayoutSize>
);

export const CompanionToParentViewSize = (props: ChildrenProps) => (
	<LayoutSize gridClassName='col-12 col-md-6 col-xl-4'>
		{props.children}
	</LayoutSize>
);


///////////////////////////////////////////////////////////////////////////////

export const StandardParentViewSize2 = (props: ChildrenProps) => (
	<LayoutSize gridClassName='col-12 col-lg-8 col-xl-6'>
		{props.children}
	</LayoutSize>
);

export const StandardChildViewSize2 = (props: ChildrenProps) => (
	<LayoutSize gridClassName='col-12 col-xl-4'>
		{props.children}
	</LayoutSize>
);

export const StandardChildLayoutContainerSize2 = (props: ChildrenProps) => (
	<LayoutContainer mode='column' columnClassName='standard-child-layout-2'>
		{props.children}
	</LayoutContainer>
);

export const CompanionToParentViewSize2 = (props: ChildrenProps) => (
	<LayoutSize gridClassName='col-12 col-lg-4 col-xl-6'>
		{props.children}
	</LayoutSize>
);


///////////////////////////////////////////////////////////////////////////////

export interface BasicViewProps {
	color?: string;
	className?: string;
	children?: ((props: RouteChildrenProps<any>) => React.ReactNode) | React.ReactNode;
}

export const BasicView = (props: BasicViewProps) => (
	<Card outline color={props.color || 'primary'} className={'faded mb-3 ' + (props.className || '')}>
		{props.children}
	</Card>
);


///////////////////////////////////////////////////////////////////////////////

export interface ViewProps {
	header: ReactNode;
	footer?: ReactNode;
	color?: string;
	onClickHeader?: () => void;
	className?: string;
	children?: ((props: RouteChildrenProps<any>) => React.ReactNode) | React.ReactNode;
}

export const View = (props: ViewProps) => {
	const color = props.color || 'primary';
	const onClickHeader = (event: React.MouseEvent<HTMLElement>) => {
		if (props.onClickHeader)
			props.onClickHeader();
	}
	return (
		<BasicView color={color} className={props.className}>
			<CardHeader tag='h4' className={`text-${color} bg-${color} faded ${props.onClickHeader ? 'clickable link-plain' : ''}`} onClick={onClickHeader}>
				{props.header}
			</CardHeader>
			{props.children}
			{props.footer}
		</BasicView>
	);
}


///////////////////////////////////////////////////////////////////////////////

export const ViewBody = (props: ChildrenProps) => (
	<CardBody>
		{props.children}
	</CardBody>
);


///////////////////////////////////////////////////////////////////////////////

export const ViewTable = (props: ChildrenProps) => (
	<Table className='table-sm mb-0'>
		{props.children}
	</Table>
);


///////////////////////////////////////////////////////////////////////////////

interface CollapsingViewProps {
	header: ReactNode;
	footer?: ReactNode;
	color?: string;
	initiallyCollapsed?: boolean;
	className?: string;
	classNameExpanded?: string;
	classNameCollapsed?: string;
	onOpen?: (isFirstToggle: boolean) => void;
	onClose?: (isFirstToggle: boolean) => void;
	children?: ((props: RouteChildrenProps<any>) => React.ReactNode) | React.ReactNode;
}

export const CollapsingView = (props: CollapsingViewProps) => {
	const [isOpen, setIsOpen] = useState(!props.initiallyCollapsed);
	const [isFirstToggle, setIsFirstToggle] = useState(true);
	const icon = isOpen ? <Icon.Collapse className='float-right' />
		: <Icon.Expand className='float-right' />;
	const classNameEc = isOpen ? props.classNameExpanded : props.classNameCollapsed;
	const className = props.className
		? (classNameEc ? props.className + ' ' + classNameEc : props.className)
		: classNameEc;
	const toggle = () => {
		if (!isOpen && props.onOpen)
			props.onOpen(isFirstToggle);
		else if (isOpen && props.onClose)
			props.onClose(isFirstToggle);
		if (isFirstToggle)
			setIsFirstToggle(false);
		setIsOpen(!isOpen);
	}
	return (
		<View
			header={<>{props.header}{icon}</>}
			footer={props.footer}
			color={props.color}
			className={className}
			onClickHeader={toggle}
		>
			{isOpen && props.children}
		</View>
	);
}
