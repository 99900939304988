import { Reducer } from 'redux';
import { store } from '../..';
import { getJson, postJson, putJson } from '../myfetch';
import { dispatchErrorToast, dispatchToast } from '../notification/NotificationStore';
import { LoadStatus2 } from '../StoreCommon';
import { SecondaryRole } from '../user/UserCommon';
import { specialistPracticeListActionInvokers } from './SpecialistPracticeListStore';


// STATE **********************************************************************

export interface SelectedSpecialistPracticeState {
	status: LoadStatus2;
	specialistPractice?: SpecialistPractice;
	specialistPracticeIsSaving?: boolean;	// undefined if no changes have been made, false if changes have been made, true if saving

	selectedSpecialist?: SpecialistPracticeSpecialist;
	selectedSpecialistIsSaving?: boolean;	// undefined if no changes have been made, false if changes have been made, true if saving

	selectedStaff?: SpecialistPracticeStaff;
	selectedStaffIsSaving?: boolean;	// undefined if no changes have been made, false if changes have been made, true if saving
}

export interface SpecialistPractice {
	specialistPracticeId: number;
	isActive: boolean;
	name: string;
	phoneNumber: string;
	mobileNumber: string;
	email: string;
	publishEmail?: string;
	accountsEmail?: string;
	localAddress: string;
	localSuburb: string;
	localCity: string;
	localState: string;
	localPostcode: string;
	localCountry: string;
	postalAddress: string;
	postalSuburb: string;
	postalCity: string;
	postalState: string;
	postalPostcode: string;
	postalCountry: string;
	activities: SpecialistPracticeActivity;
	practiceSpecialists: SpecialistPracticeSpecialist[];
	practiceStaff: SpecialistPracticeStaff[];
}

export enum SpecialistPracticeActivity {
	None = 0,
	Practice = 1,
	Tester = 2,
	Distributor = 4,
}

export interface SpecialistPracticeSpecialist {
	practiceOfSpecialistId: number;
	specialistUserId: string;
	specialistName: string;
	specialistEmail?: string;
	specialistPracticeToInvoiceId: number;
	specialistPracticeToInvoiceName: string;
	role: SecondaryRole;
	effectiveTo?: string;
	isActive: boolean;
	isReadOnly: boolean;
	canImpersonate: boolean;
}

export interface SpecialistPracticeStaff {
	specialistStaffUserId: string;
	userName: string;
	firstName: string;
	lastName: string;
	knownAs: string;
	phoneNumber: string;
	email: string;
	role: SecondaryRole;
	effectiveTo?: string;
	isActive: boolean;
	canImpersonate: boolean;
}


// ACTIONS ********************************************************************

interface PrepareNewSpecialistPracticeAction { type: 'PREPARE_NEW_SPECIALIST_PRACTICE'; }

interface RequestLoadSpecialistPracticeAction { type: 'REQUEST_LOAD_SPECIALIST_PRACTICE'; }
interface ReceiveLoadSpecialistPracticeAction { type: 'RECEIVE_LOAD_SPECIALIST_PRACTICE'; specialistPractice?: SpecialistPractice; }

interface UpdateSelectedSpecialistPracticeFieldAction { type: 'UPDATE_SELECTED_SPECIALIST_PRACTICE_FIELD'; name: string; value: any; isRequired: boolean; }

interface RequestSaveSpecialistPracticeAction { type: 'REQUEST_SAVE_SPECIALIST_PRACTICE'; }
interface ReceiveSaveSpecialistPracticeAction { type: 'RECEIVE_SAVE_SPECIALIST_PRACTICE'; specialistPractice?: SpecialistPractice; }

interface PrepareNewSpecialistPracticeSpecialistAction { type: 'PREPARE_NEW_SPECIALIST_PRACTICE_SPECIALIST'; }
interface SelectSpecialistPracticeSpecialistAction { type: 'SELECT_SPECIALIST_PRACTICE_SPECIALIST'; practiceOfSpecialistId?: number; }
interface UpdateSelectedSpecialistPracticeSpecialistFieldAction { type: 'UPDATE_SELECTED_SPECIALIST_PRACTICE_SPECIALIST_FIELD'; name: string; value: any; isRequired: boolean; }

interface RequestSaveSpecialistPracticeSpecialistAction { type: 'REQUEST_SAVE_SPECIALIST_PRACTICE_SPECIALIST'; }
interface ReceiveSaveSpecialistPracticeSpecialistAction { type: 'RECEIVE_SAVE_SPECIALIST_PRACTICE_SPECIALIST'; practiceSpecialists?: SpecialistPracticeSpecialist[]; }

interface PrepareNewSpecialistPracticeStaffAction { type: 'PREPARE_NEW_SPECIALIST_PRACTICE_STAFF'; }
interface SelectSpecialistPracticeStaffAction { type: 'SELECT_SPECIALIST_PRACTICE_STAFF'; specialistStaffUserId?: string; }
interface UpdateSelectedSpecialistPracticeStaffFieldAction { type: 'UPDATE_SELECTED_SPECIALIST_PRACTICE_STAFF_FIELD'; name: string; value: any; isRequired: boolean; }

interface RequestSaveSpecialistPracticeStaffAction { type: 'REQUEST_SAVE_SPECIALIST_PRACTICE_STAFF'; }
interface ReceiveSaveSpecialistPracticeStaffAction { type: 'RECEIVE_SAVE_SPECIALIST_PRACTICE_STAFF'; practiceStaff?: SpecialistPracticeStaff[]; }

type KnownAction = PrepareNewSpecialistPracticeAction
	| RequestLoadSpecialistPracticeAction | ReceiveLoadSpecialistPracticeAction
	| UpdateSelectedSpecialistPracticeFieldAction
	| RequestSaveSpecialistPracticeAction | ReceiveSaveSpecialistPracticeAction
	| PrepareNewSpecialistPracticeSpecialistAction | SelectSpecialistPracticeSpecialistAction
	| UpdateSelectedSpecialistPracticeSpecialistFieldAction
	| RequestSaveSpecialistPracticeSpecialistAction | ReceiveSaveSpecialistPracticeSpecialistAction
	| PrepareNewSpecialistPracticeStaffAction | SelectSpecialistPracticeStaffAction
	| UpdateSelectedSpecialistPracticeStaffFieldAction
	| RequestSaveSpecialistPracticeStaffAction | ReceiveSaveSpecialistPracticeStaffAction
	;


// ACTION INVOKERS ************************************************************

export const selectedSpecialistPracticeActionInvokers = {
	prepareNewPractice: () => store.dispatch({ type: 'PREPARE_NEW_SPECIALIST_PRACTICE', } as KnownAction),

	requestLoadPractice: async (practiceId: string) => {
		try {
			const response = await getJson(`api/specialists/${practiceId}`);
			store.dispatch({ type: 'RECEIVE_LOAD_SPECIALIST_PRACTICE', specialistPractice: response.specialistPractice, } as KnownAction);
		} catch (error) {
			store.dispatch({ type: 'RECEIVE_LOAD_SPECIALIST_PRACTICE', } as KnownAction);
			dispatchErrorToast(true, 'Specialist Practice', error);
        }
	},

	updatePracticeField: (name: string, value: any, isRequired: boolean) => store.dispatch({ type: 'UPDATE_SELECTED_SPECIALIST_PRACTICE_FIELD', name, value, isRequired, } as KnownAction),

	requestSavePractice: async () => {
		try {
			store.dispatch({ type: 'REQUEST_SAVE_SPECIALIST_PRACTICE', } as KnownAction);
			const specialistPractice = store.getState().specialist.selectedPractice!.specialistPractice!;
			const response = !specialistPractice.specialistPracticeId
				? await postJson('api/specialists', specialistPractice)
				: await putJson(`api/specialists/${specialistPractice.specialistPracticeId}`, specialistPractice)
				;
			store.dispatch({ type: 'RECEIVE_SAVE_SPECIALIST_PRACTICE', specialistPractice: response.specialistPractice, } as KnownAction);
			dispatchToast(true, 'success', 'Specialist Practice', 'The practice details have been saved.');
			specialistPracticeListActionInvokers.invalidateSpecialistPracticeList();
		} catch (error) {
			store.dispatch({ type: 'RECEIVE_SAVE_SPECIALIST_PRACTICE', } as KnownAction);
			dispatchErrorToast(true, 'Specialist Practice', error);
        }
	},

	prepareNewPracticeSpecialist: () => store.dispatch({ type: 'PREPARE_NEW_SPECIALIST_PRACTICE_SPECIALIST', } as KnownAction),
	selectSpecialist: (practiceOfSpecialistId?: number) => store.dispatch({ type: 'SELECT_SPECIALIST_PRACTICE_SPECIALIST', practiceOfSpecialistId, } as KnownAction),
	updateSpecialistField: (name: string, value: any, isRequired: boolean) => store.dispatch({ type: 'UPDATE_SELECTED_SPECIALIST_PRACTICE_SPECIALIST_FIELD', name, value, isRequired, } as KnownAction),

	requestSaveSpecialist: async () => {
		try {
			store.dispatch({ type: 'REQUEST_SAVE_SPECIALIST_PRACTICE_SPECIALIST', } as KnownAction);
			const selectedPractice = store.getState().specialist.selectedPractice!;
			const practiceSpecialist = selectedPractice.selectedSpecialist!;
			const response = !practiceSpecialist.practiceOfSpecialistId
				? await postJson(`api/specialists/${selectedPractice.specialistPractice!.specialistPracticeId}/specialists`, practiceSpecialist)
				: await putJson(`api/specialists/${selectedPractice.specialistPractice!.specialistPracticeId}/specialists/${practiceSpecialist.practiceOfSpecialistId}`, practiceSpecialist)
				;
			store.dispatch({ type: 'RECEIVE_SAVE_SPECIALIST_PRACTICE_SPECIALIST', practiceSpecialists: response.practiceSpecialists, } as KnownAction);
			dispatchToast(true, 'success', 'Specialist', 'The specialist details have been saved.');
			specialistPracticeListActionInvokers.invalidateSpecialistPracticeList();
		} catch (error) {
			store.dispatch({ type: 'RECEIVE_SAVE_SPECIALIST_PRACTICE_SPECIALIST', } as KnownAction);
			dispatchErrorToast(true, 'Specialist', error);
		}
	},

	prepareNewPracticeStaff: () => store.dispatch({ type: 'PREPARE_NEW_SPECIALIST_PRACTICE_STAFF', } as KnownAction),
	selectStaff: (specialistStaffUserId?: string) => store.dispatch({ type: 'SELECT_SPECIALIST_PRACTICE_STAFF', specialistStaffUserId, } as KnownAction),
	updateStaffField: (name: string, value: any, isRequired: boolean) => store.dispatch({ type: 'UPDATE_SELECTED_SPECIALIST_PRACTICE_STAFF_FIELD', name, value, isRequired, } as KnownAction),

	requestSaveStaff: async (password: string) => {
		try {
			store.dispatch({ type: 'REQUEST_SAVE_SPECIALIST_PRACTICE_STAFF', } as KnownAction);
			const selectedPractice = store.getState().specialist.selectedPractice!;
			const practiceStaff = { ...selectedPractice.selectedStaff!, password, };
			const response = !practiceStaff.specialistStaffUserId
				? await postJson(`api/specialists/${selectedPractice.specialistPractice!.specialistPracticeId}/staff`, practiceStaff)
				: await putJson(`api/specialists/${selectedPractice.specialistPractice!.specialistPracticeId}/staff/${practiceStaff.specialistStaffUserId}`, practiceStaff)
				;
			store.dispatch({ type: 'RECEIVE_SAVE_SPECIALIST_PRACTICE_STAFF', practiceStaff: response.practiceStaff, } as KnownAction);
			dispatchToast(true, 'success', 'Specialist Staff', 'The staff member details have been saved.');
			specialistPracticeListActionInvokers.invalidateSpecialistPracticeList();
		} catch (error) {
			store.dispatch({ type: 'RECEIVE_SAVE_SPECIALIST_PRACTICE_STAFF', } as KnownAction);
			dispatchErrorToast(true, 'Specialist Staff', error);
		}
	},
}


// INITIAL STATE **************************************************************

const initialPracticeState: SpecialistPractice = {
	specialistPracticeId: 0,
	isActive: true,
	name: '',
	phoneNumber: '',
	mobileNumber: '',
	email: '',
	localAddress: '',
	localSuburb: '',
	localCity: '',
	localState: '',
	localPostcode: '',
	localCountry: '',
	postalAddress: '',
	postalSuburb: '',
	postalCity: '',
	postalState: '',
	postalPostcode: '',
	postalCountry: '',
	activities: SpecialistPracticeActivity.None,
	practiceSpecialists: [],
	practiceStaff: [],
}

const initialPracticeSpecialistState: SpecialistPracticeSpecialist = {
	practiceOfSpecialistId: 0,
	specialistUserId: '',
	specialistName: '',
	role: SecondaryRole.Specialist,
	specialistPracticeToInvoiceId: 0,
	specialistPracticeToInvoiceName: '',
	isActive: true,
	isReadOnly: false,
	canImpersonate: false,
}

const initialPracticeStaffState: SpecialistPracticeStaff = {
	specialistStaffUserId: '',
	userName: '',
	firstName: '',
	lastName: '',
	knownAs: '',
	phoneNumber: '',
	email: '',
	role: SecondaryRole.Staff,
	isActive: true,
	canImpersonate: false,
}


// REDUCERS *******************************************************************

export const reducer: Reducer<SelectedSpecialistPracticeState | null> = (state: SelectedSpecialistPracticeState | null | undefined, action: KnownAction) => {
	if (state === undefined)
		return null;
	state = state!;
	switch (action.type) {
		case 'PREPARE_NEW_SPECIALIST_PRACTICE':
			return {
				status: LoadStatus2.Loaded,
				specialistPractice: initialPracticeState,
				specialistPracticeIsSaving: undefined,
			} as SelectedSpecialistPracticeState;
		case 'REQUEST_LOAD_SPECIALIST_PRACTICE':
			return {
				status: LoadStatus2.Loading,
			} as SelectedSpecialistPracticeState;
		case 'RECEIVE_LOAD_SPECIALIST_PRACTICE':
			return {
				status: action.specialistPractice ? LoadStatus2.Loaded : LoadStatus2.Failure,
				specialistPractice: action.specialistPractice,
				specialistPracticeIsSaving: undefined,
			} as SelectedSpecialistPracticeState;
		case 'UPDATE_SELECTED_SPECIALIST_PRACTICE_FIELD':
			return {
				...state,
				specialistPractice: {
					...state.specialistPractice,
					[action.name]: (action.value === '' && !action.isRequired) ? null : action.value,
				},
				specialistPracticeIsSaving: false,
			} as SelectedSpecialistPracticeState;
		case 'REQUEST_SAVE_SPECIALIST_PRACTICE':
			return {
				...state,
				status: LoadStatus2.Saving,
				specialistPracticeIsSaving: true,
			} as SelectedSpecialistPracticeState;
		case 'RECEIVE_SAVE_SPECIALIST_PRACTICE':
			return {
				...state,
				status: LoadStatus2.Loaded,
				specialistPractice: action.specialistPractice ?? state.specialistPractice,
				specialistPracticeIsSaving: action.specialistPractice ? undefined : false,
			} as SelectedSpecialistPracticeState;

		case 'PREPARE_NEW_SPECIALIST_PRACTICE_SPECIALIST':
			return {
				...state,
				selectedSpecialist: initialPracticeSpecialistState,
				selectedSpecialistIsSaving: undefined,
			} as SelectedSpecialistPracticeState;
		case 'SELECT_SPECIALIST_PRACTICE_SPECIALIST':
			return {
				...state,
				selectedSpecialist: state.specialistPractice!.practiceSpecialists.find(ps => ps.practiceOfSpecialistId === action.practiceOfSpecialistId),
				selectedSpecialistIsSaving: undefined,
			} as SelectedSpecialistPracticeState;
		case 'UPDATE_SELECTED_SPECIALIST_PRACTICE_SPECIALIST_FIELD':
			return {
				...state,
				selectedSpecialist: {
					...state.selectedSpecialist,
					[action.name]: (action.value === '' && !action.isRequired) ? null : action.value,
				},
				selectedSpecialistIsSaving: false,
			} as SelectedSpecialistPracticeState;
		case 'REQUEST_SAVE_SPECIALIST_PRACTICE_SPECIALIST':
			return {
				...state,
				selectedSpecialistIsSaving: true,
			} as SelectedSpecialistPracticeState;
		case 'RECEIVE_SAVE_SPECIALIST_PRACTICE_SPECIALIST':
			return {
				...state,
				specialistPractice: {
					...state.specialistPractice!,
					practiceSpecialists: action.practiceSpecialists ?? state.specialistPractice!.practiceSpecialists,
				},
				selectedSpecialist: action.practiceSpecialists ? undefined : state.selectedSpecialist,
				selectedSpecialistIsSaving: action.practiceSpecialists ? undefined : false,
			} as SelectedSpecialistPracticeState;

		case 'PREPARE_NEW_SPECIALIST_PRACTICE_STAFF':
			return {
				...state,
				selectedStaff: initialPracticeStaffState,
				selectedStaffIsSaving: undefined,
			} as SelectedSpecialistPracticeState;
		case 'SELECT_SPECIALIST_PRACTICE_STAFF':
			return {
				...state,
				selectedStaff: state.specialistPractice!.practiceStaff.find(ps => ps.specialistStaffUserId === action.specialistStaffUserId),
				selectedStaffIsSaving: undefined,
			} as SelectedSpecialistPracticeState;
		case 'UPDATE_SELECTED_SPECIALIST_PRACTICE_STAFF_FIELD':
			return {
				...state,
				selectedStaff: {
					...state.selectedStaff,
					[action.name]: (action.value === '' && !action.isRequired) ? null : action.value,
				},
				selectedStaffIsSaving: false,
			} as SelectedSpecialistPracticeState;
		case 'REQUEST_SAVE_SPECIALIST_PRACTICE_STAFF':
			return {
				...state,
				selectedStaffIsSaving: true,
			} as SelectedSpecialistPracticeState;
		case 'RECEIVE_SAVE_SPECIALIST_PRACTICE_STAFF':
			return {
				...state,
				specialistPractice: {
					...state.specialistPractice!,
					practiceStaff: action.practiceStaff ?? state.specialistPractice!.practiceStaff,
				},
				selectedStaff: action.practiceStaff ? undefined : state.selectedStaff,
				selectedStaffIsSaving: action.practiceStaff ? undefined : false,
			} as SelectedSpecialistPracticeState;

		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		default: const exhaustiveCheck: never = action;
	}
	return state;
}
