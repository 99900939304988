import * as React from 'react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { CardBody } from 'reactstrap';
import { ApplicationState } from '../../store';
import { userSummaryActionCreators } from '../../store/summary/UserSummaryStore';
import { StandardSummarySize } from '../support/Summaries';
import { View } from '../support/Views';

export default () => {
	const summary = useSelector(state => (state as ApplicationState).summary.overheadJoin);
	const history = useHistory();
	const dispatch = useDispatch();
	useEffect(() => {
		dispatch(userSummaryActionCreators.requestOverheadJoinSummary());
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	const summaryType = summary?.summary?.summaryType || 0;
	const numberOfMatches = summary?.summary?.numberOfMatches || 0;
	const numberOfOffers = summary?.summary?.numberOfOffers || 0;
	const headerUrl = summaryType === 3 ? '/overhead-join/offers' : '/overhead-join/matches';
	return (
		<StandardSummarySize>
			<View header='Overhead Join'
				color={(summaryType === 2 && numberOfMatches > 0) || (summaryType === 3 && numberOfOffers > 0) ? 'danger' : 'success'}
				onClickHeader={() => history.push(headerUrl)}>
				<CardBody>
					<p>
						<Link to='/user/manage/me'>Configure in profile</Link>
					</p>
					{summaryType !== 0 &&
						<p>
							{numberOfMatches > 0 &&
								<>{numberOfMatches} matches<br /></>
							}
							{numberOfOffers > 0 &&
								<>{numberOfOffers} offers<br /></>
							}
							{summaryType !== 3 &&
								<Link to='/overhead-join/matches'>View matches</Link>
							}
							{summaryType === 3 &&
								<Link to='/overhead-join/offers'>View offers</Link>
							}
						</p>
					}
					<p>
						<Link to='/info/overhead-join'>What is Overhead Join?</Link><br />
						<Link to='/info/overhead-join/code-of-conduct'>Code of Conduct</Link>
					</p>
				</CardBody>
			</View>
		</StandardSummarySize>
	);
}
