import { Action, Reducer } from 'redux';
import { store } from '../..';
import { getJson } from '../myfetch';
import { dispatchErrorToast } from '../notification/NotificationStore';
import { LoadStatus2 } from '../StoreCommon';

// STATE **********************************************************************

export interface HistoryTrendState {
	userId?: string;

	documentTypeId?: number;
	documentTypeName?: string;
	fields?: HistoryField[];

	historyListFieldNames?: string[];
	historyList?: History[];
	historyListStatus?: LoadStatus2;	// unassigned, Loading, Loaded, Failure, Changed
	historyIsComplete?: boolean;
}

export interface HistoryField {
	formQuestionId: number;
	questionText: string;
	sectionName: string;
}

export interface History {
	filledFormId: number;
	timeStamp: number;
	date: string;
	values: string[];
}


// ACTIONS ********************************************************************

interface RequestHistoryTrendAction { type: 'REQUEST_HISTORY_TREND'; }
interface ReceiveHistoryTrendAction { type: 'RECEIVE_HISTORY_TREND'; userId?: string; documentTypeId?: number; documentTypeName?: string; fields?: HistoryField[]; historyListFieldNames?: string[], historyList?: History[]; historyIsComplete?: boolean; }

interface SelectHistoryTrendFilledFormAction { type: 'SELECT_HISTORY_TREND_DOCUMENT_TYPE'; documentTypeId: number; }
interface AddHistoryTrendFieldAction { type: 'ADD_HISTORY_TREND_FIELD'; field: HistoryField; }
interface RemoveHistoryTrendFieldAction { type: 'REMOVE_HISTORY_TREND_FIELD'; formQuestionId: number; }

type KnownAction = RequestHistoryTrendAction | ReceiveHistoryTrendAction
	| SelectHistoryTrendFilledFormAction
	| AddHistoryTrendFieldAction | RemoveHistoryTrendFieldAction
	;


// ACTION INVOKERS ************************************************************

const historyTrendFavouriteName = 'historyTrendFavourite';

export const historyTrendActionInvokers = {
	requestHistoryList: (userId: string) => {
		const history = store.getState().form.historyTrends;
		const fieldIds = history?.fields?.map(f => f.formQuestionId).join(',');
		actionHelpers.requestHistoryList(userId, history?.documentTypeId, fieldIds);
	},

	requestRestoreHistoryListDefault: async (userId: string) => {
		actionHelpers.requestHistoryList(userId);
	},

	requestRestoreHistoryListFavourite: async (userId: string) => {
		const favouriteText = window.localStorage.getItem(historyTrendFavouriteName);
		if (!favouriteText) {
			actionHelpers.requestHistoryList(userId);
			return;
		}
		const favourite = JSON.parse(favouriteText);
		actionHelpers.requestHistoryList(userId, favourite.documentTypeId, favourite.fieldIds);
	},

	requestMoreHistoryList: async () => {
		const history = store.getState().form.historyTrends;
		const fieldIds = history!.fields!.map(f => f.formQuestionId).join(',');
		const historyList = history!.historyList!;
		const oldestHistoryLoaded = historyList.length ? historyList[historyList.length - 1].timeStamp : undefined;
		actionHelpers.requestHistoryList(history!.userId!, history!.documentTypeId, fieldIds, oldestHistoryLoaded);
	},

	selectDocumentType: (documentTypeId: number | string) => {
		if (typeof documentTypeId === 'string')
			documentTypeId = parseInt(documentTypeId);
		store.dispatch({ type: 'SELECT_HISTORY_TREND_DOCUMENT_TYPE', documentTypeId, } as KnownAction);
	},
	addField: (field: HistoryField) => { store.dispatch({ type: 'ADD_HISTORY_TREND_FIELD', field, } as KnownAction); },
	removeField: (formQuestionId: number) => { store.dispatch({ type: 'REMOVE_HISTORY_TREND_FIELD', formQuestionId, } as KnownAction); },

	makeFavourite: () => {
		const state = store.getState().form.historyTrends;
		const favourite = { documentTypeId: state?.documentTypeId, fieldIds: state?.fields?.map(f => f.formQuestionId).join(','), };
		const favouriteText = JSON.stringify(favourite);
		window.localStorage.setItem(historyTrendFavouriteName, favouriteText);
	}
}


// ACTION HELPERS *************************************************************

const actionHelpers = {
	requestHistoryList: async (userId: string, documentTypeId?: number, fieldIds?: string, oldestHistoryLoaded?: number) => {
		store.dispatch({ type: 'REQUEST_HISTORY_TREND', } as KnownAction);
		try {
			const oParameter = oldestHistoryLoaded ? `&o=${oldestHistoryLoaded}` : '';
			const response = await getJson(`api/users/${userId}/history?d=${documentTypeId || ''}&f=${fieldIds || ''}${oParameter}`)
			store.dispatch({
				type: 'RECEIVE_HISTORY_TREND',
				userId: userId,
				documentTypeId: response.documentTypeId,
				documentTypeName: response.documentTypeName,
				fields: response.fields,
				historyListFieldNames: response.historyListFieldNames,
				historyList: response.historyList,
				historyIsComplete: response.historyIsComplete,
			} as KnownAction);
		} catch (error) {
			store.dispatch({ type: 'RECEIVE_HISTORY_TREND', } as KnownAction);
			dispatchErrorToast(true, 'History Trends', error);
		}
	},
}


// REDUCERS *******************************************************************

export const reducer: Reducer<HistoryTrendState | null> = (state: HistoryTrendState | undefined | null, incomingAction: Action) => {
	if (state === undefined || incomingAction.type === 'REQUEST_LOGOUT')
		return null;
	const action = incomingAction as KnownAction;
	switch (action.type) {
		case 'REQUEST_HISTORY_TREND':
			return {
				...state!,
				historyListFieldNames: undefined,
				historyList: undefined,
				historyListStatus: LoadStatus2.Loading,
			};
		case 'RECEIVE_HISTORY_TREND':
			return {
				userId: action.userId || state!.userId,
				documentTypeId: action.documentTypeId || state!.documentTypeId,
				documentTypeName: action.documentTypeName || state!.documentTypeName,
				fields: action.fields || state!.fields,
				historyListFieldNames: action.historyListFieldNames || state!.historyListFieldNames,
				historyList: action.historyList || state!.historyList,
				historyListStatus: action.historyList ? LoadStatus2.Loaded : LoadStatus2.Failure,
				historyIsComplete: action.historyIsComplete,
			};
		case 'SELECT_HISTORY_TREND_DOCUMENT_TYPE':
			return {
				...state!,
				documentTypeId: action.documentTypeId,
				fields: [],
				historyListStatus: state?.historyListStatus === LoadStatus2.Failure ? state.historyListStatus : LoadStatus2.Changed,
			};
		case 'ADD_HISTORY_TREND_FIELD':
			return {
				...state!,
				fields: [...state!.fields!, action.field],
				historyListStatus: state?.historyListStatus === LoadStatus2.Failure ? state.historyListStatus : LoadStatus2.Changed,
			};
		case 'REMOVE_HISTORY_TREND_FIELD':
			return {
				...state!,
				fields: state!.fields!.filter(f => f.formQuestionId !== action.formQuestionId),
				historyListStatus: state?.historyListStatus === LoadStatus2.Failure ? state.historyListStatus : LoadStatus2.Changed,
			};
		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		default: const exhaustiveCheck: never = action;
	}
	return state;
};
