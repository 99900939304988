import { Reducer } from 'redux';
import { AppThunkAction } from '..';
import { getJson } from '../myfetch';
import { dispatchErrorToast } from '../notification/NotificationStore';
import { LoadStatus2 } from '../StoreCommon';

// STATE **********************************************************************

export interface SelectedReportState {
	definitionStatus?: LoadStatus2;
	definition?: ReportDefinition;
	data?: ReportData;
}

export interface ReportDefinition {
	reportDefinitionId: number;
	name: string;
	description: string;
	columns: ReportColumn[];
	filters: ReportFilter[];
	parameters: ReportParameter[];
	detailUrl?: string;
	hasTableQuery: boolean;
	hasGraphQuery: boolean;
}

export interface ReportColumn {
	name: string;
	isSortable: boolean;
}

export interface ReportFilter {
	name: string;
}

export interface ReportParameter {
	name: string;
	type: string;
	isRequired: boolean;
	isGraphFilter: boolean;
}

export interface ReportData {
	filter: string;
	orderBy: string;
	orderByAscending: boolean;
	parameterValues: (string | undefined)[];
	breakDown: string;

	tableStatus?: LoadStatus2;	// undefined if filter/orderBy/parameterValues have been changed
	tableQueryString?: string;
	table?: string[][];

	graphType?: string;	// Line, Area, Bar
	graphHeight?: number;

	graphStatus?: LoadStatus2;	// undefined if filter/orderBy/parameterValues have been changed
	graphQueryString?: string;
	graphSeries?: string[];
	graph?: ReportGraphData[];
}

export interface ReportGraphData {
	month: number;
	values: (number | undefined)[];
}

// ACTIONS ********************************************************************

interface RequestGetReportDefinitionAction { type: 'REQUEST_GET_REPORT_DEFINITION'; }
interface ReceiveGetReportDefinitionAction { type: 'RECEIVE_GET_REPORT_DEFINITION'; definition?: ReportDefinition; }

interface UpdateReportDataFilter { type: 'UPDATE_REPORT_DATA_FILTER'; filter: string; }
interface UpdateReportDataOrderBy { type: 'UPDATE_REPORT_DATA_ORDER_BY'; orderBy: string; }
interface UpdateReportDataParameter { type: 'UPDATE_REPORT_DATA_PARAMETER'; name: string; value?: string; isRequired: boolean; }
interface UpdateReportDataBreakDown { type: 'UPDATE_REPORT_DATA_BREAK_DOWN'; breakDown: string; }
interface UpdateReportGraphType { type: 'UPDATE_REPORT_GRAPH_TYPE'; graphType: string; }
interface UpdateReportGraphHeight { type: 'UPDATE_REPORT_GRAPH_HEIGHT'; graphHeight: number; }

interface RequestGetReportTableAction { type: 'REQUEST_GET_REPORT_TABLE'; orderBy: string; orderByAscending: boolean; }
interface ReceiveGetReportTableAction { type: 'RECEIVE_GET_REPORT_TABLE'; table?: string[][]; tableQueryString?: string; }

interface RequestGetReportGraphAction { type: 'REQUEST_GET_REPORT_GRAPH'; }
interface ReceiveGetReportGraphAction { type: 'RECEIVE_GET_REPORT_GRAPH'; graphSeries?: string[]; graph?: ReportGraphData[]; graphQueryString?: string; }

type KnownAction =
	RequestGetReportDefinitionAction | ReceiveGetReportDefinitionAction
	| UpdateReportDataFilter | UpdateReportDataOrderBy | UpdateReportDataParameter
	| UpdateReportDataBreakDown | UpdateReportGraphType | UpdateReportGraphHeight
	| RequestGetReportTableAction | ReceiveGetReportTableAction
	| RequestGetReportGraphAction | ReceiveGetReportGraphAction
	;

// ACTION CREATORS ************************************************************

export const selectedReportActionCreators = {
	getReportDefinition: (reportId: string): AppThunkAction<KnownAction> => async (dispatch, getState) => {
		dispatch({ type: 'REQUEST_GET_REPORT_DEFINITION', });
		try {
			const response = await getJson(`api/reports/${reportId}`);
			dispatch({ type: 'RECEIVE_GET_REPORT_DEFINITION', definition: response.definition, });
		} catch (error) {
			dispatch({ type: 'RECEIVE_GET_REPORT_DEFINITION', });
			dispatchErrorToast(true, 'Report Data', error);
		}
	},

	updateReportDataFilter: (filter: string) => ({ type: 'UPDATE_REPORT_DATA_FILTER', filter, } as KnownAction),
	updateReportDataOrderBy: (orderBy: string) => ({ type: 'UPDATE_REPORT_DATA_ORDER_BY', orderBy, } as KnownAction),
	updateReportDataParameter: (name: string, value: string | undefined, isRequired: boolean) => ({ type: 'UPDATE_REPORT_DATA_PARAMETER', name, value, isRequired, } as KnownAction),
	updateReportDataBreakDown: (breakDown: string) => ({ type: 'UPDATE_REPORT_DATA_BREAK_DOWN', breakDown, } as KnownAction),

	updateReportGraphType: (graphType: string) => ({ type: 'UPDATE_REPORT_GRAPH_TYPE', graphType, } as KnownAction),
	updateReportGraphHeight: (graphHeight: number) => ({ type: 'UPDATE_REPORT_GRAPH_HEIGHT', graphHeight, } as KnownAction),

	getReportTable: (orderBy: string, orderByAscending: boolean): AppThunkAction<KnownAction> => async (dispatch, getState) => {
		const selectedReport = getState().report.selectedReport;
		dispatch({ type: 'REQUEST_GET_REPORT_TABLE', orderBy, orderByAscending, });
		try {
			let url = `api/reports/${selectedReport!.definition!.reportDefinitionId}/table`;
			let tableQueryString = `?f=${selectedReport!.data!.filter}&o=${orderBy}&oa=${orderByAscending}`;
			selectedReport!.data!.parameterValues.forEach(pv => {
				tableQueryString += '&p=' + pv || '';
			});
			const response = await getJson(url + tableQueryString);
			dispatch({ type: 'RECEIVE_GET_REPORT_TABLE', table: response.table, tableQueryString, });
		} catch (error) {
			dispatch({ type: 'RECEIVE_GET_REPORT_TABLE', });
			dispatchErrorToast(true, 'Report Data', error);
		}
	},

	getReportGraph: (): AppThunkAction<KnownAction> => async (dispatch, getState) => {
		const selectedReport = getState().report.selectedReport;
		dispatch({ type: 'REQUEST_GET_REPORT_GRAPH', });
		try {
			let url = `api/reports/${selectedReport!.definition!.reportDefinitionId}/graph`;
			let graphQueryString = `?f=${selectedReport!.data!.filter}&b=${selectedReport!.data!.breakDown}`;
			selectedReport!.data!.parameterValues.forEach(pv => {
				graphQueryString += '&p=' + pv || '';
			});
			const response = await getJson(url + graphQueryString);
			dispatch({ type: 'RECEIVE_GET_REPORT_GRAPH', graphSeries: response.graphSeries, graph: response.graph, graphQueryString, });
		} catch (error) {
			dispatch({ type: 'RECEIVE_GET_REPORT_GRAPH', });
			dispatchErrorToast(true, 'Report Data', error);
		}
	},
};

// REDUCERS *******************************************************************

export const selectedReportReducer: Reducer<SelectedReportState | null> = (state: SelectedReportState | null | undefined, action: KnownAction) => {
	if (state === undefined)
		return null;
	state = state!;
	switch (action.type) {
		case 'REQUEST_GET_REPORT_DEFINITION':
			return {
				definitionStatus: LoadStatus2.Loading,
			};
		case 'RECEIVE_GET_REPORT_DEFINITION':
			let data: ReportData | undefined = undefined;
			if (action.definition)
				data = {
					filter: action.definition.filters[0].name,
					orderBy: action.definition.columns[0].name,
					orderByAscending: true,
					parameterValues: action.definition.parameters.map(p => ''),
					breakDown: '',
					graphType: 'Line',
					graphHeight: 300,
				};
			return {
				definitionStatus: action.definition ? LoadStatus2.Loaded : LoadStatus2.Failure,
				definition: action.definition,
				data,
			};
		case 'UPDATE_REPORT_DATA_FILTER':
			return {
				...state,
				data: {
					...state.data!,
					filter: action.filter,
					tableStatus: undefined,
					graphStatus: undefined,
				},
			};
		case 'UPDATE_REPORT_DATA_ORDER_BY':
			return {
				...state,
				data: {
					...state.data!,
					orderBy: action.orderBy,
					orderByAscending: action.orderBy !== state.data!.orderBy || !state.data!.orderByAscending,
					tableStatus: undefined,
					graphStatus: undefined,
				},
			};
		case 'UPDATE_REPORT_DATA_PARAMETER':
			let parameterValues = [...state.data!.parameterValues,];
			const iParameter = state.definition!.parameters.findIndex(p => p.name === action.name);
			parameterValues[iParameter] = action.value;
			return {
				...state,
				data: {
					...state.data!,
					parameterValues,
					tableStatus: undefined,
					graphStatus: undefined,
				},
			};
		case 'UPDATE_REPORT_DATA_BREAK_DOWN':
			return {
				...state,
				data: {
					...state.data!,
					breakDown: action.breakDown,
					tableStatus: undefined,
					graphStatus: undefined,
				},
			};
		case 'UPDATE_REPORT_GRAPH_TYPE':
			return {
				...state,
				data: {
					...state.data!,
					graphType: action.graphType,
				},
			};
		case 'UPDATE_REPORT_GRAPH_HEIGHT':
			return {
				...state,
				data: {
					...state.data!,
					graphHeight: action.graphHeight,
				},
			};
		case 'REQUEST_GET_REPORT_TABLE':
			return {
				...state,
				data: {
					...state.data!,
					orderBy: action.orderBy,
					orderByAscending: action.orderByAscending,
					tableStatus: LoadStatus2.Loading,
					tableQueryString: undefined,
					table: undefined,
				},
			};
		case 'RECEIVE_GET_REPORT_TABLE':
			return {
				...state,
				data: {
					...state.data!,
					tableStatus: action.table ? LoadStatus2.Loaded : LoadStatus2.Failure,
					tableQueryString: action.tableQueryString,
					table: action.table,
				},
			};
		case 'REQUEST_GET_REPORT_GRAPH':
			return {
				...state,
				data: {
					...state.data!,
					graphStatus: LoadStatus2.Loading,
					graphQueryString: undefined,
					graphSeries: undefined,
					graph: undefined,
				},
			};
		case 'RECEIVE_GET_REPORT_GRAPH':
			return {
				...state,
				data: {
					...state.data!,
					graphStatus: action.graph ? LoadStatus2.Loaded : LoadStatus2.Failure,
					graphQueryString: action.graphQueryString,
					graphSeries: action.graphSeries,
					graph: action.graph,
				},
			};
		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		default: const exhaustiveCheck: never = action;
	}
	return state;
}
