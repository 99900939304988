import { Action, Reducer } from "redux";
import { AppThunkAction } from "..";
import { store } from "../..";
import { connectNotificationsClient, disconnectNotificationsClient } from "../../notificationsClient";
import { handleWithActionAndErrorToast, handleWithErrorToast, postJson, setAccessToken } from "../myfetch";
import { dispatchErrorToast, dispatchToast } from "../notification/NotificationStore";
import { SecondaryRole, UserRole } from "./UserCommon";

export interface LoginState {
	status: LoginStatus;
	isTimeout?: boolean;	// true only when the user's session has timed out and they have not yet logged out.
	credentials: LoginCredentials;
	questions?: number[];
	connections?: LoginConnection[];
	loggedIn?: LoggedInState;
}

export enum LoginStatus {
	EnteringDetails = 0,
	SelectingConnection = 1,
	EnteringTfa = 2,
	EnteringAnswers = 3,
	Submitting = 4,
	LoggedIn = 5,
}

export interface LoginCredentials {
	userName: string;
	role: UserRole;
	connectionId?: number;
	twoFactorCode?: string;
	rememberMeOnThisComputer?: boolean;
	questionsToken?: string;
	answer1?: string;
	answer2?: string;
	expires?: number;
}

export interface LoginConnection {
	connectionId: number;
	name: string;
}

export interface LoggedInState {
	userId: string;
	userName: string;
	role: UserRole;
	displayRole: UserRole;
	displayName: string;
	connectionId?: number;
	connectionName?: string;
	secondaryRole: SecondaryRole;
	permissions: number;
	expires: number;
	accessToken?: string;
	featureSet: number;
	actions: ActionsStatus;
	isImpersonation: boolean;
}

export enum FeatureSetFlags {
	None = 0,
	Upload_Results = 1,
	Select_Application_Type = 2,
	User_Messages = 4,
	Terms_And_Conditions = 8,	// Keep this because there are no T&Cs yet for administrators.
	//History_Trends = 16,
	Medical_Tests_Needed = 32,
	//Flying_Organisations = 64,
	//Overhead_Join = 128,
	//Reports = 256,

	// Permanent flags
	Pre_Audit_Medicals = 65536,	// Enabled for new medical examiners
	Virtual_Medicals = 131072,	// Don't forget to enable/disable the Virtual Medical Request and Virtual MER document types.
}

export interface ActionsStatus {
	actionsRequired: RequiredActionFlags;
	actionsRequested: RequiredActionFlags;
	dateTCAccepted?: string;
	dateTCRequired?: string;
	dateQuestionsRequired?: string;
	tfaConfigured?: boolean;
	dateTfaRequired?: string;
}

export enum RequiredActionFlags {
	None = 0,
	ChangePassword = 1,
	AcceptTermsAndConditions = 2,
	ConfigureTwoFactorAuthentication = 4,
	ConfigureSecurityQuestions = 8,
}

// Actions ////////////////////////////////////////////////////////////////////

interface UpdateLoginCredentialsAction { type: 'UPDATE_LOGIN_CREDENTIALS'; name: string; value: any; isRequired: boolean; }
interface UseLoginSecurityQuestionsAction { type: 'USE_LOGIN_SECURITY_QUESTIONS'; }

interface RequestLoginAction { type: 'REQUEST_LOGIN'; }
interface ReceiveLoginSuccessAction { type: 'RECEIVE_LOGIN_SUCCESS'; loggedIn: LoggedInState; }
interface ReceiveLoginFailureAction { type: 'RECEIVE_LOGIN_FAILURE'; }
interface ReceiveLoginQuestionsAction { type: 'RECEIVE_LOGIN_QUESTIONS'; questions: number[]; questionsToken: string; }
interface ReceiveLoginConnectionsAction { type: 'RECEIVE_LOGIN_CONNECTIONS'; connections: LoginConnection[]; }

interface TimeoutLogin { type: 'TIMEOUT_LOGIN'; }
interface RequestLogoutAction { type: 'REQUEST_LOGOUT'; }

interface ClearLoginActionFlagAction { type: 'CLEAR_LOGIN_ACTION_FLAG'; flag: RequiredActionFlags; }
interface MarkTermsAndConditionsAcceptedAction { type: 'MARK_TERMS_CONDITIONS_ACCEPTED'; }
interface MarkSecurityQuestionsSavedAction { type: 'MARK_SECURITY_QUESTIONS_SAVED'; }
interface MarkTwoFactorConfiguredAction { type: 'MARK_TWO_FACTOR_CONFIGURED'; }
interface MarkTwoFactorDisabledAction { type: 'MARK_TWO_FACTOR_DISABLED'; }

type KnownAction =
	UpdateLoginCredentialsAction | UseLoginSecurityQuestionsAction
	| RequestLoginAction | ReceiveLoginSuccessAction | ReceiveLoginFailureAction
	| ReceiveLoginQuestionsAction | ReceiveLoginConnectionsAction
	| TimeoutLogin | RequestLogoutAction
	| ClearLoginActionFlagAction
	| MarkTermsAndConditionsAcceptedAction | MarkSecurityQuestionsSavedAction
	| MarkTwoFactorConfiguredAction | MarkTwoFactorDisabledAction
	;

// Action Invokers ////////////////////////////////////////////////////////////

export const loginActionInvokers = {
	updateLoginCredentials: (name: string, value: any, isRequired: boolean) => store.dispatch({ type: 'UPDATE_LOGIN_CREDENTIALS', name, value, isRequired, } as KnownAction),
	useLoginSecurityQuestions: () => store.dispatch({ type: 'USE_LOGIN_SECURITY_QUESTIONS', } as KnownAction),

	loginNow: (password: string) => {
		const credentials = store.getState().user.login.credentials;
		postJson('api/account/login', { ...credentials, password, })
			.then(response => {
				if (response.status === 300) {
					if (!response.connections)
						store.dispatch({ type: 'RECEIVE_LOGIN_QUESTIONS', questions: response.questions, questionsToken: response.questionsToken, } as KnownAction);
					else store.dispatch({ type: 'RECEIVE_LOGIN_CONNECTIONS', connections: response.connections, } as KnownAction);
				} else {
					store.dispatch({ type: 'RECEIVE_LOGIN_SUCCESS', loggedIn: response.loggedIn, } as KnownAction);
					connectNotificationsClient();
				}
			})
			.catch(handleWithActionAndErrorToast('Login', 'RECEIVE_LOGIN_FAILURE'));
		store.dispatch({ type: 'REQUEST_LOGIN' } as KnownAction);
	},

	logout: () => {
		postJson('api/account/logout', null)
			//.then(disconnectNotificationsClient)
			.catch(handleWithErrorToast('Logout'));
		store.dispatch({ type: 'REQUEST_LOGOUT' } as KnownAction);
		disconnectNotificationsClient();
	},

	timeoutLogin: () => store.dispatch({ type: 'TIMEOUT_LOGIN', } as KnownAction),

	impersonateUser: async (userId: string, role: UserRole, connectionId: number | string | undefined = undefined) => {
		try {
			const response = await postJson(`api/account/impersonate/${userId}`, { role, connectionId, });
			store.dispatch({ type: 'RECEIVE_LOGIN_SUCCESS', loggedIn: response.loggedIn, } as KnownAction);
			dispatchToast(true, 'success', 'Impersonate User', `You are now logged in as ${response.loggedIn.userName}.`);
		} catch (error) {
			dispatchErrorToast(true, 'Impersonate User', error);
		}
    },
}

// Action Creators ////////////////////////////////////////////////////////////

export const loginActionCreators = {
	clearLoginActionFlag: (flag: RequiredActionFlags) => ({ type: 'CLEAR_LOGIN_ACTION_FLAG', flag, } as KnownAction),
	markTermsAndConditionsAccepted: () => ({ type: 'MARK_TERMS_CONDITIONS_ACCEPTED', } as KnownAction),
	markSecurityQuestionsSaved: () => ({ type: 'MARK_SECURITY_QUESTIONS_SAVED', } as KnownAction),
	markTwoFactorConfigured: () => ({ type: 'MARK_TWO_FACTOR_CONFIGURED', } as KnownAction),
	markTwoFactorDisabled: () => ({ type: 'MARK_TWO_FACTOR_DISABLED', } as KnownAction),

	// Obsolete - use loginActionInvokers.impersonateUser instead
	impersonateUser: (userId: string, role: UserRole, connectionId: number | undefined = undefined): AppThunkAction<KnownAction> => (dispatch, getState) => {
		postJson(`api/account/impersonate/${userId}`, { role, connectionId, })
			.then(response => {
				dispatch({ type: 'RECEIVE_LOGIN_SUCCESS', loggedIn: response.loggedIn, } as KnownAction);
				dispatchToast(true, 'success', 'Impersonate User', `You are now logged in as ${response.loggedIn.userName}.`);
			})
			.catch(handleWithErrorToast('Impersonate User'));
	},
}

// Initial State //////////////////////////////////////////////////////////////

const initialState: LoginState = {
	status: LoginStatus.EnteringDetails,
	credentials: {
		userName: '',
		role: UserRole.Pilot,
	},
};

// Reducer ////////////////////////////////////////////////////////////////////

export const loginReducer: Reducer<LoginState> = (state: LoginState | undefined, incomingAction: Action): LoginState => {
	if (state === undefined)
		return initialState;
	const action: KnownAction = incomingAction as KnownAction;
	switch (action.type) {
		case 'UPDATE_LOGIN_CREDENTIALS':
			return {
				...state,
				credentials: {
					...state.credentials,
					[action.name]: (action.value === '' && !action.isRequired) ? null : action.value,
				},
			};
		case 'USE_LOGIN_SECURITY_QUESTIONS':
			return {
				...state,
				status: LoginStatus.EnteringAnswers,
			};
		case 'REQUEST_LOGIN':
			return {
				...state,
				status: LoginStatus.Submitting,
			};
		case 'RECEIVE_LOGIN_SUCCESS':
			setAccessToken(action.loggedIn.accessToken);
			return {
				status: LoginStatus.LoggedIn,
				credentials: {
					userName: action.loggedIn.userName,
					role: action.loggedIn.isImpersonation ? action.loggedIn.role : state.credentials.role,
					expires: action.loggedIn.expires,
				},
				loggedIn: action.loggedIn,
			};
		case 'RECEIVE_LOGIN_QUESTIONS':
			return {
				...state,
				status: LoginStatus.EnteringTfa,
				credentials: {
					...state.credentials,
					questionsToken: action.questionsToken,
				},
				questions: action.questions,
			};
		case 'RECEIVE_LOGIN_CONNECTIONS':
			return {
				status: LoginStatus.SelectingConnection,
				credentials: state.credentials,
				connections: action.connections,
			};
		case 'TIMEOUT_LOGIN':
			return {
				...state,
				status: LoginStatus.EnteringDetails,
				isTimeout: true,
			};
		case 'RECEIVE_LOGIN_FAILURE':
		case 'REQUEST_LOGOUT':
			return {
				status: LoginStatus.EnteringDetails,
				credentials: {
					userName: state.credentials.userName,
					role: state.credentials.role,
				},
			};
		case 'CLEAR_LOGIN_ACTION_FLAG':
			return {
				...state,
				loggedIn: {
					...state.loggedIn!,
					actions: {
						...state.loggedIn!.actions,
						actionsRequested: state.loggedIn!.actions.actionsRequested & ~action.flag,
					},
				},
			};
		case 'MARK_TERMS_CONDITIONS_ACCEPTED':
			return {
				...state,
				loggedIn: {
					...state.loggedIn!,
					actions: {
						...state.loggedIn!.actions,
						actionsRequested: state.loggedIn!.actions.actionsRequested & ~RequiredActionFlags.AcceptTermsAndConditions,
						actionsRequired: state.loggedIn!.actions.actionsRequired & ~RequiredActionFlags.AcceptTermsAndConditions,
						dateTCAccepted: 'today',
						dateTCRequired: undefined,
					},
				},
			};
		case 'MARK_SECURITY_QUESTIONS_SAVED':
			return {
				...state,
				loggedIn: {
					...state.loggedIn!,
					actions: {
						...state.loggedIn!.actions,
						actionsRequested: state.loggedIn!.actions.actionsRequested & ~RequiredActionFlags.ConfigureSecurityQuestions,
						actionsRequired: state.loggedIn!.actions.actionsRequired & ~RequiredActionFlags.ConfigureSecurityQuestions,
						dateQuestionsRequired: undefined,
					},
				},
			};
		case 'MARK_TWO_FACTOR_CONFIGURED':
			return {
				...state,
				loggedIn: {
					...state.loggedIn!,
					actions: {
						...state.loggedIn!.actions,
						actionsRequested: state.loggedIn!.actions.actionsRequested & ~RequiredActionFlags.ConfigureTwoFactorAuthentication,
						actionsRequired: state.loggedIn!.actions.actionsRequired & ~RequiredActionFlags.ConfigureTwoFactorAuthentication,
						tfaConfigured: true,
						dateTfaRequired: undefined,
					},
				},
			};
		case 'MARK_TWO_FACTOR_DISABLED':
			return {
				...state,
				loggedIn: {
					...state.loggedIn!,
					actions: {
						...state.loggedIn!.actions,
						tfaConfigured: false,
					},
				},
			};
		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		default: const exhaustiveCheck: never = action;
	}
	return state;
}
