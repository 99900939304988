import * as React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Container } from 'reactstrap';
import * as Configuration from '../../Configuration';
import { ApplicationState } from '../../store';
import { LoginStatus } from '../../store/user/LoginStore';
import FooterLogo from '../res/OneKete-logo-footer.png';
import WhiteLogo from '../res/OneKete-logo-white.png';
import './Footer.css';

export default () => {
	const login = useSelector(state => (state as ApplicationState).user.login);

	return (
		<footer>
			<Container className='d-print-none'>
				<img src={FooterLogo} alt={`${Configuration.text.siteName} logo`} />
				<ul>
					<li><Link to='/#what'>WHAT&nbsp;IS&nbsp;{Configuration.text.siteName.toUpperCase()}?</Link></li>
					<li><Link to='/info/about-us'>ABOUT&nbsp;US</Link></li>
					<li><Link to='/info/contact-us'>CONTACT&nbsp;US</Link></li>
					{login.status !== LoginStatus.LoggedIn && (
						<>
							<li className='special'><Link to='/user/sign-up' rel='nofollow'>SIGN&nbsp;UP</Link></li>
							<li><Link to='/dashboard' rel='nofollow'>LOGIN</Link></li>
						</>
					)}
				</ul>
				<p>
					&copy;2018,&nbsp;2021&nbsp;SkyCert&nbsp;Ltd, All&nbsp;Rights&nbsp;Reserved
					&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;
					<Link to='/info/privacy-policy'>Privacy&nbsp;Policy</Link>
				</p>
			</Container>
			<div className='d-none d-print-block'>
				<p>&copy;2018, 2021 SkyCert Ltd, All Rights Reserved</p>
				<img src={WhiteLogo} alt={`${Configuration.text.siteName} logo`} />
			</div>
		</footer>
	);
}
