import { Action, combineReducers, Reducer } from "redux";
import { accountReducer, AccountState } from "./AccountStore";
import { loginReducer, LoginState } from "./LoginStore";
import * as ReminderListStore from './ReminderListStore';
import * as SelectedReminderStore from './SelectedReminderStore';
import * as SelectedUserStore from './SelectedUserStore';
import * as UserListStore from './UserListStore';

// From SkyCert v1

export interface UserState {
	account: AccountState | null;
	login: LoginState;
	selectedUser: SelectedUserStore.SelectedUserState | null;
	list: UserListStore.UserListState | null;
	listOfReminders: ReminderListStore.ReminderListState | null;
	selectedReminder: SelectedReminderStore.SelectedReminderState | null;
}

const combinedReducer: Reducer<UserState> = combineReducers<UserState>({
	account: accountReducer,
	login: loginReducer,
	selectedUser: SelectedUserStore.reducer,
	list: UserListStore.reducer,
	listOfReminders: ReminderListStore.reducer,
	selectedReminder: SelectedReminderStore.reducer,
});

export const reducer: Reducer<UserState> = (state: UserState | undefined, action: Action): UserState => {
	if (action.type === 'REQUEST_LOGOUT')
		return {
			account: null,
			login: loginReducer(state!.login, action),
			selectedUser: null,
			list: null,
			listOfReminders: null,
			selectedReminder: null,
		};
	return combinedReducer(state, action);
}
