import * as React from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { UserRole } from '../../../store/user/UserCommon';
import { Page } from '../../app/Page';
import { AcceptTermsAndConditions, TermsAndConditionsAcceptance } from './TermsAndConditionsSupport';

// TODO @@@ Add the other roles into this page (search for air traffic controller on this page)
export default () => (
	<Page>
		<Helmet>
			<title>SkyCert Medical Examiner / Nurse Terms and Conditions</title>
			<meta name='description' content="Terms and Conditions for the use of SkyCert by medical examiners and nurses." />
			<meta name='robots' content='noindex, nofollow' />
		</Helmet>
		<h1>Terms and Conditions</h1>
		<h2>For Medical Examiners and Nurses</h2>
		<TermsAndConditionsAcceptance role={UserRole.MedicalExaminer} />
		<TermsAndConditionsAcceptance role={UserRole.Nurse} />
		<h3>1.	Background</h3>
		<p>1.1	SkyCert Limited ("SkyCert", "we", "us" and "our") operates the websites www.skcert.nz and www.skycert-demo.azure.net ("Website"), which medical professionals, such as doctors, nurses, audiologists, optometrists and cardiologists that your medical practice employs or engages ("Medical Professionals") can use to confirm medical certification of pilots, skydivers and air traffic controllers with the New Zealand Civil Aviation Authority and also to train in the use of, and investigate, the services we provide.</p>
		<p>1.2	These Terms and Conditions apply to any services that SkyCert supplies to your medical practice ("you", "your") through or in relation to the Website ("Services"). These Terms and Conditions comprise our contract with you for the provision of the Services.</p>
		<p>1.3	You confirm your understanding and acceptance of these Terms and Conditions when you first log in to the Website by marking the required tick-box and clicking 'Agree'. By clicking 'Agree' you are also consenting to our <Link to='/info/privacy-policy' target='blank'>Privacy Policy</Link>.</p>
		<h3>2.	Your Use of the Services</h3>
		<p>2.1	In using the Services, you will:</p>
		<ol type='a'>
			<li>be responsible for the conduct of any Medical Professionals that use the Services;</li>
			<li>only use the Services for the purposes which they are intended;</li>
			<li>ensure that your Medical Professionals maintain the security of their passwords and login IDs and not disclose such information to any third party;</li>
			<li>remove the access of any Medical Professional to the Services when they cease to be engaged or employed by you;</li>
			<li>immediately notify us and change, or procure the change, of any password if you believe your or any Medical Professional's account has been accessed by a third party or such password has been compromised or otherwise disclosed to any third party;</li>
			<li>other than in respect of your Medical Professionals, not permit any third party to enter any information into the Website on your behalf;</li>
			<li>not knowingly introduce, and take all reasonable precautions to prevent the introduction of, any malware (including viruses, ransomware, spyware and disabling code) into our operating environment (including the Website) or any other infrastructure, network or system owned or used by us; and</li>
			<li>not do, or omit to do, anything which you are aware or ought reasonably to be aware, could have an adverse effect on: our brand or reputation (or which could otherwise bring our brand or reputation into disrepute).</li>
		</ol>
		<p>2.2	Subject to clause 6.1b, in the event the Services are unavailable for any reason, we will email you the requisite forms for the medical certification for your completion upon request. Once you have completed the forms, scan and email them us and the New Zealand Civil Aviation Authority.</p>
		<p>2.3	In the event an individual appoints another medical practice to carry out its medical certification requirements, you will continue to have access to your historical records but not any future information held in respect of the individual.</p>
		<h3>3.	Price and Payment</h3>
		<p>3.1	You will pay us a set fee per medical examination and subsequent certification that utilises the Services ("Fee"). The Fee will be as notified by us to you.</p>
		<p>3.2	We can revise the Fee payable by you at any time. In the event that we revise the Fee, we will notify you of such change which will come into effect the month following the month in which you are notified.</p>
		<p>3.3	You agree to set up a direct debit authorisation with us to enable payment of the Fee. We will invoice you at the end of each calendar month in respect of the total Fees that you owe of that month and we will automatically deduct such amount from your account on or after the 20th day of the following calendar month.</p>
		<p>3.4	You agree that</p>
		<ol type='a'>
			<li>interest may be charged on any amount you owe us after the due date at the rate of 1.5% per month accruing on a daily basis on all overdue amounts until the date of payment in full to us; and</li>
			<li>any costs and expenses, including debt collection and legal costs, which we may incur as a consequence of having to enforce any of our rights contained in this contract, shall be payable by you.</li>
		</ol>
		<h3>4.	Intellectual Property</h3>
		<p>4.1	You agree that we own all intellectual property rights, including any patents, trade marks, design rights and copyright, in the Website (and its content), the Services and in any other computer programs, advertising materials, websites, electronic data, manuals, videos, documents and other material that we produce in connection with any Services or otherwise supply to you ("Intellectual Property").</p>
		<p>4.2	Your use of the Website and Services does not provide you with any right, title or interest in the Intellectual Property other than as expressly set out in these Terms and Conditions.</p>
		<p>4.3	You agree not to, at any time, directly or indirectly challenge or contest (nor assist any other person to challenge or contest) our right and title to, and interest in, the Intellectual Property.</p>
		<p>4.4	You warrant that you are authorised to use all forms and documentation (including being authorised by the owner of the copyright) that you upload through, or to, the Website.</p>
		<h3>5.	Restraint</h3>
		<p>5.1	In consideration of us providing you with access to the Services (which may include you being exposed to our commercially sensitive information in relation to the operation of our Website and delivery of the Services), you agree that you will not, and you will procure that any related company (as that term is defined in section 2(3) of the Companies Act 1993) does not, during, and for a period of 3 years from the end of, the Term develop a website or software application or program which provides Services the same or similar to the Services or otherwise competes with the Services. You acknowledge that this restraint is reasonable to allow us to protect our business interests.</p>
		<h3>6.	Liability & Warranties</h3>
		<p>6.1	To the extent permitted by law, you acknowledge that:</p>
		<ol type='a'>
			<li>all Services are provided "as is" and any express or implied warranties and/or representations (other than warranties or conditions which may not lawfully be excluded), including implied warranties of merchantability and fitness for a particular purpose, are disclaimed or otherwise excluded;</li>
			<li>without prejudice to the generality of clause 6.1a, we make no warranty or representation regarding the availability of the Services or that the Website and/or Services (including any data or personal information that you provide to us) will be free from security breaches or other unauthorised access and we may suspend, deactivate or limit your access to the Website and/or Services
		<ol type='i'>
					<li>if we reasonably believe that you or someone authorised by you breaches these Terms and Conditions;</li>
					<li>if we consider necessary due to technical or security issues;</li>
					<li>if we are requested to do so by a government or law enforcement agency; or</li>
					<li>if it is otherwise considered necessary by us at our sole discretion;</li>
				</ol>
			</li>
			<li>your use of any Services is at your sole risk and responsibility.</li>
		</ol>
		<p>6.2	We will not be liable to you or any third party (whether for breach of contract, tort, for breach of statute or on any other basis) for any:</p>
		<ol type='a'>
			<li>indirect, consequential or special loss, damage or injury; or</li>
			<li>loss of data, profits, revenue, business opportunity, anticipated savings, wasted overheads or damage to goodwill, regardless of whether in the circumstances it is deemed direct, indirect, consequential or special loss.</li>
		</ol>
		<p>6.3	Subject to clauses 6.1 and 6.2, if we are deemed to be liable to you for any loss or damage of any kind whatsoever, arising from the supply of Services by us to you whether suffered or incurred by you or another person or entity and whether in contract or tort (including our negligence) or otherwise, then you agree that our liability will be limited to the fullest extent permitted by law.</p>
		<h3>7.	Indemnity</h3>
		<p>7.1	You agree to indemnify us from and against any actions, claims and liabilities arising out of or relating to your breach of these Terms (including any breach of warranty) or any unlawful use of our Website or otherwise arising from your use of the Services.</p>
		<h3>8.	Term and Termination</h3>
		<p>8.1	These Terms and Conditions will come into force from the date upon which you accept them and shall continue until terminated in accordance with either clause 8.2 or clause 8.3 ("Term").</p>
		<p>8.2	Either party may terminate the Services by providing the other party with 30 days notice in writing.</p>
		<p>8.3	We may immediately terminate these Terms and Conditions and/or suspend provision of Services under these Terms and Conditions upon written notice to you if:</p>
		<ol type='a'>
			<li>a Default Event occurs with respect to you;</li>
			<li>you fail to pay any monies owing by the due date;</li>
			<li>you commit material breach of this Agreement which is not capable of being remedied; or</li>
			<li>you commit a material breach of this Agreement that is reasonably capable of being remedied, but has not been remedied within 20 business days after receiving a notice from the First Party requiring it to remedy the relevant breach.</li>
		</ol>
		<p>8.4	Each of the following events will be a Default Event for the purposes of clause 8.3(a):</p>
		<ol>
			<li>you go into liquidation, or a receiver, administrator, statutory manager or similar officer is appointed to all or any part of your business or assets;</li>
			<li>you become or are deemed by law to be insolvent;</li>
			<li>you cease, or threaten to cease, to carry on all or substantially all of your business; and/or</li>
			<li>you make any composition, arrangement or assignment with or for the benefit of your creditors.</li>
		</ol>
		<p>8.5	In addition to the rights of termination set out in this clause 8.5, we may by notice in writing to you suspend any part of any contract for the supply of Services to you if you fail to pay any money owing to us by the applicable due date.</p>
		<p>8.6	Any termination or suspension will not affect our claim for money due to us at the time of termination or suspension, damages for any breach of your obligations to us under these Terms and Conditions and any other legal rights we have.</p>
		<h3>9.	Miscellaneous</h3>
		<p>9.1	We may assign or otherwise transfer any of our rights under these Terms and Conditions to any other person without your consent. We agree that we may novate our obligations under these Terms and Conditions to any other person and you will enter into any form of novation that we may reasonably require to give effect to this upon request.</p>
		<p>9.2	We may revise or amend these Terms and Conditions from time to time. If we do make any such changes we will notify you of the revised terms and conditions and you must accept the revised Terms and Conditions before continuing to use the Services, App and/or Website.</p>
		<p>9.3	If we fail to enforce any of the provisions contained in these Terms and Conditions it shall not be deemed to be a waiver of any of the rights or obligations we have under our contract with you.</p>
		<p>9.4	If any of the provisions of these Terms and Conditions are determined to be invalid, void or illegal or unenforceable, the validity, legality and enforceability of the remaining provisions shall not be affected, prejudiced or impaired.</p>
		<p>9.5	These Terms and Conditions are governed by the laws of New Zealand and all disputes or claims in relation to these Terms and Conditions will be subject to the exclusive jurisdiction of the New Zealand courts.</p>
		<AcceptTermsAndConditions role={UserRole.MedicalExaminer} />
		<AcceptTermsAndConditions role={UserRole.Nurse} />
	</Page>
);
