import * as React from 'react';
import { Link, useHistory } from 'react-router-dom';
import { CardBody } from 'reactstrap';
import { StandardSummarySize } from '../support/Summaries';
import { View } from '../support/Views';

export const UploadCodesSummary = () => {
	const history = useHistory();

	return (
		<StandardSummarySize>
			<View header='Upload Codes' onClickHeader={() => history.push('/specialist/upload-codes')}>
				<CardBody>
					<div>
						<Link to='/specialist/upload-codes'>See/create upload codes</Link>
					</div>
				</CardBody>
			</View>
		</StandardSummarySize>
	);
}
