import * as React from 'react';
import { useSelector } from 'react-redux';
import { Button, Form, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { ApplicationState } from '../../store';
import { alertsActionInvokers, MyAlertState, MyConfirmOption, MyConfirmState, MyPromptsState, MyPromptState } from '../../store/alert/AlertsStore';
import { TextEditor } from './TextEditors';

export const MyDialog = () => {
	const alerts = useSelector(state => (state as ApplicationState).alerts);
	if ((alerts?.states.length ?? 0) === 0)
		return null;
	return (
		<>
			{alerts!.states.map((s, i) => (
				<React.Fragment key={i}>
					{s.alert && <MyAlert {...s.alert} />}
					{s.confirm && <MyConfirm {...s.confirm} />}
					{s.prompt && <MyPrompt {...s.prompt} />}
					{s.prompts && <MyPrompts {...s.prompts} />}
				</React.Fragment>
			))}
		</>
	);
};


const MyAlert = (props: MyAlertState) => {
	const onClose = props.onClose ?? alertsActionInvokers.close;
	return (
		<Modal backdrop={props.backdrop} isOpen toggle={onClose}>
			<ModalHeader>{props.heading}</ModalHeader>
			<ModalBody>
				{typeof props.content === 'object' && 'map' in props.content && typeof props.content[0] === 'string'
					? (props.content as string[]).map(s => <p key={s}>{s}</p>)
					: props.content
				}
			</ModalBody>
			<ModalFooter>
				<Button color='secondary' onClick={onClose}>Close</Button>
			</ModalFooter>
		</Modal>
	);
}


const MyConfirm = (props: MyConfirmState) => {
	const onClose = props.onClose ?? alertsActionInvokers.close;
	const yes = (props.options & MyConfirmOption.Yes) !== 0 ? () => { onClose(); props.onAction(MyConfirmOption.Yes); } : undefined;
	const no = (props.options & MyConfirmOption.No) !== 0 ? () => { onClose(); props.onAction(MyConfirmOption.No); } : undefined;
	const ok = (props.options & MyConfirmOption.Ok) !== 0 ? () => { onClose(); props.onAction(MyConfirmOption.Ok); } : undefined;
	const cancel = (props.options & MyConfirmOption.Cancel) !== 0 ? () => { onClose(); props.onAction(MyConfirmOption.Cancel); } : undefined;
	return (
		<Modal backdrop={props.backdrop} isOpen toggle={no ?? cancel}>
			<ModalHeader>{props.heading}</ModalHeader>
			<ModalBody>
				{typeof props.content === 'object' && 'map' in props.content && typeof props.content[0] === 'string'
					? (props.content as string[]).map(s => <p key={s}>{s}</p>)
					: props.content
				}
			</ModalBody>
			<ModalFooter>
				{yes && <Button color='primary' onClick={yes}>Yes</Button>}
				{no && <Button color='secondary' onClick={no}>No</Button>}
				{ok && <Button color='primary' onClick={ok}>Ok</Button>}
				{cancel && <Button color='primary' onClick={cancel}>Cancel</Button>}
			</ModalFooter>
		</Modal>
	);
}


export const MyPrompt = (props: MyPromptState) => {
	const [value, setValue] = React.useState(props.initialValue);

	const onClose = props.onClose ?? alertsActionInvokers.close;
	const saveChanges = (event: React.FormEvent<HTMLFormElement> | React.MouseEvent<HTMLButtonElement>) => {
		if (event.preventDefault)
			event.preventDefault();
		const form = document.getElementById('myPromptForm') as HTMLFormElement;
		if (form.checkValidity()) {
			onClose();
			props.onOk(value);
		}
	}

	return (
		<Modal backdrop='static' isOpen toggle={onClose}>
			<ModalHeader>{props.heading}</ModalHeader>
			<ModalBody>
				<Form onSubmit={saveChanges} id='myPromptForm'>
					<TextEditor
						label={props.label}
						value={value}
						additionalText={props.additionalText}
						onChangeValue={setValue}
						maxLength={props.maxLength}
						pattern={props.pattern}
						required={props.required}
					/>
				</Form>
			</ModalBody>
			<ModalFooter>
				<Button color='primary' onClick={saveChanges}>Save</Button>
				<Button color='secondary' onClick={onClose}>Cancel</Button>
			</ModalFooter>
		</Modal>
	);
}


export const MyPrompts = (props: MyPromptsState) => {
	const [values, setValues] = React.useState(props.initialValues);

	const changeField = (name: string, value: any, isRequired: boolean) => {
		const index = parseInt(name);
		const newValues = values.map((v, i) => i === index ? value : v);
		setValues(newValues);
	}
	const onClose = props.onClose ?? alertsActionInvokers.close;
	const saveChanges = (event: React.FormEvent<HTMLFormElement> | React.MouseEvent<HTMLButtonElement>) => {
		const form = document.getElementById('myPromptsForm') as HTMLFormElement;
		if (form.checkValidity()) {
			onClose();
			props.onOk(values);
		}
	}

	return (
		<Modal backdrop='static' isOpen toggle={onClose}>
			<ModalHeader>{props.heading}</ModalHeader>
			<ModalBody>
				<Form onSubmit={saveChanges} id='myPromptsForm'>
					{props.editors.map((editor, index) => {
						const EditorType = editor.editorType ?? TextEditor;
						return <EditorType key={index}
							name={index.toString()}
							label={editor.label}
							value={values[index]}
							onChangeField={changeField}
							maxLength={editor.maxLength}
							pattern={editor.pattern}
							required={editor.required}
						/>;
					})}
				</Form>
			</ModalBody>
			<ModalFooter>
				<Button color='primary' onClick={saveChanges}>Save</Button>
				<Button color='secondary' onClick={onClose}>Cancel</Button>
			</ModalFooter>
		</Modal>
	);
}
