import * as React from 'react';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Form } from 'reactstrap';
import { ApplicationState } from '../../store';
import { accountActionCreators } from '../../store/user/AccountStore';
import { Page } from '../app/Page';
import Loading from '../support/Loading';
import { PasswordEditor, SetPasswordEditor } from '../support/TextEditors';
import { StandardViewSize } from '../support/Views';

export default () => (
	<Page defaultHelmet='Change Password'>
		<h1>Change Password</h1>
		<StandardViewSize>
			<ChangePasswordForm />
		</StandardViewSize>
	</Page>
)

const ChangePasswordForm = () => {
	const FormName = 'changePasswordForm';
	const [oldPassword, setOldPassword] = useState('');
	const [newPassword, setNewPassword] = useState('');
	const account = useSelector(state => (state as ApplicationState).user.account);
	const dispatch = useDispatch();

	const changePassword = (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault();
		let form: any = document.getElementById(FormName);
		if (form.checkValidity()) {
			dispatch(accountActionCreators.changePassword(oldPassword, newPassword));
			setOldPassword('');
		}
	};

	if (!account || !account.changePasswordSubmitting) {
		return (
			<Form id={FormName} onSubmit={changePassword}>
				<p>Please enter your existing password and the new password that you want to use.</p>
				<PasswordEditor name='oldPassword'
					label='Old password'
					value={oldPassword}
					required
					onChangeValue={setOldPassword}
				/>
				<SetPasswordEditor id='newPassword'
					onChangePassword={setNewPassword}
				/>
				<p>
					<Button type='submit' disabled={!oldPassword || !newPassword} color='primary' className='form-control'>Change password</Button>
				</p>
			</Form>
		);
	} else {
		return <Loading action='Changing the password' />;
	}
}
