import { Action, Reducer } from 'redux';
import { AppThunkAction } from '..';
import { ListCriteria, ListPage, ListState, ListStatus2 } from '../list/ListCommon';
import { criteriaMatches, findExistingPage, getCriteriaUrl } from '../listSupport';
import { getJson, handleWithActionAndErrorToast } from '../myfetch';

// From SkyCert v1

// STATE **********************************************************************

export interface UserListState extends ListState<UserSummary> { };

export interface UserSummary {
	userId: string;
	userName: string;
	firstName: string;
	lastName: string;
	email: string;
	effectiveFrom: string;
	status: string;
	roles: string;
	isEmailConfirmed: boolean;
}

export enum UserListOrderBy {
	UserName = 'UserName',
	FirstName = 'FirstName',
	LastName = 'LastName',
	Email = 'Email',
	EffectiveFrom = 'EffectiveFrom',
}

export enum UserListFilter1 {
	All = 'All',
	Administrators = 'Administrators',
	FlyingOrganisationStaff = 'FlyingOrganisationStaff',
	MedicalExaminers = 'MedicalExaminers',
	Nurses = 'Nurses',
	Pilots = 'Pilots',
	UnconfirmedEmail = 'UnconfirmedEmail',
	RAAgents = 'RAAgents',
	NoRAId = 'NoRAId',
}

// ACTIONS ********************************************************************

interface RequestUserListAction {
	type: 'REQUEST_USER_LIST';
	criteria: ListCriteria;
}
interface ReceiveUserListSuccessAction {
    type: 'RECEIVE_USER_LIST_SUCCESS';
	numberOfPages: number;
	totalNumberOfItems: number;
	page: ListPage<UserSummary>;
}
interface ReceiveUserListFailureAction { type: 'RECEIVE_USER_LIST_FAILURE'; }
interface InvalidateCurrentUserListAction { type: 'INVALIDATE_CURRENT_USER_LIST'; }
type KnownAction = RequestUserListAction | ReceiveUserListSuccessAction | ReceiveUserListFailureAction
	| InvalidateCurrentUserListAction
	;

// ACTION CREATORS ************************************************************

export const userListActionCreators = {
	requestUserList: (criteria: ListCriteria): AppThunkAction<Action> => (dispatch, getState) => {
		const userState = getState().user;
		if (userState.list && userState.list.listStatus !== ListStatus2.Idle) {
			const state: UserListState = userState.list!;
			if (criteriaMatches(criteria, state.criteria)
				&& findExistingPage<UserSummary>(state, criteria, 'RECEIVE_USER_LIST_SUCCESS', dispatch))
				return;
		}
		// The requested page is not already loaded.
		const criteriaUrlSuffix: string = getCriteriaUrl(criteria);
		getJson(`api/users?${criteriaUrlSuffix}`)
			//getJson(`api/users/${listType}?page=${pageNumber}&usersPerPage=${usersPerPage}&orderBy=${orderBy}&orderAscending=${orderAscending}&filter=${filter}`)
			.then(response => {
				dispatch({ type: 'RECEIVE_USER_LIST_SUCCESS', numberOfPages: response.numberOfPages, page: response.page, totalNumberOfItems: response.totalNumberOfItems, } as KnownAction);
			})
			.catch(handleWithActionAndErrorToast('Users', 'RECEIVE_USER_LIST_FAILURE'));
		dispatch({ type: 'REQUEST_USER_LIST', criteria, } as KnownAction);
	},

	invalidateCurrentUsersPage: () => ({ type: 'INVALIDATE_CURRENT_USER_LIST' } as KnownAction),
};

// REDUCERS *******************************************************************

export const reducer: Reducer<UserListState | null> = (state: UserListState | null | undefined, action: KnownAction) => {
	if (state === undefined)
		return null;
	state = state!;	// @@@ I hope this works
    switch (action.type) {
		case 'REQUEST_USER_LIST':
			const criteriaMatch: boolean = state && criteriaMatches(action.criteria, state.criteria);
			return {
				criteria: action.criteria,
				listStatus: ListStatus2.Loading,
				numberOfPages: criteriaMatch ? state.numberOfPages : 1,
				pages: criteriaMatch ? state.pages : [],
			};
		case 'RECEIVE_USER_LIST_SUCCESS':
			let newPages: ListPage<UserSummary>[] = [
				action.page,
				...state.pages.filter(page => page.pageNumber !== action.page.pageNumber),
			];
			//while (newPages.length > numberOfUsersPagesToKeep)
			//	newPages.pop();
            return {
				...state,
				criteria: {
					...state.criteria,
					pageNumber: action.page.pageNumber,
				},
				listStatus: ListStatus2.Loaded,
				numberOfPages: action.numberOfPages,
				totalNumberOfItems: action.totalNumberOfItems,
				pages: newPages,
            };
        case 'RECEIVE_USER_LIST_FAILURE':
            return {
				...state,
				status: ListStatus2.Failure,
			};
		case 'INVALIDATE_CURRENT_USER_LIST':
			if (!state)
				return state;
			return {
				...state,
				status: ListStatus2.Dirty,
				pages: [],
			}
		// eslint-disable-next-line @typescript-eslint/no-unused-vars
        default: const exhaustiveCheck: never = action;
    }

    return state;
};
