import { Action, Reducer } from 'redux';
import { AppThunkAction } from '..';
import { store } from '../..';
import { getJson, handleWithActionAndErrorToast } from '../myfetch';
import { dispatchErrorToast } from '../notification/NotificationStore';
import { SummaryLoadStatus2, SummaryStatus } from './SummaryCommon';

// From SkyCert v1

// STATE **********************************************************************

export interface OrganisationsState {
	status: SummaryStatus;
	summary: OrganisationsSummaryState;
}
export interface OrganisationsSummaryState {
	numberOfOrganisationsWithoutStaff: number;
	numberOfStaffWithoutOrganisations: number;
	numberOfInactiveOrganisations: number;
	numberOfActiveOrganisations: number;
	numberOfInactiveStaff: number;
	numberOfActiveStaff: number;
}

export interface PracticesState {
	status: SummaryStatus;
	numberOfPracticesWithoutExaminers: number;
	numberOfExaminersWithoutPractice: number;
	numberOfPractices: number;
	numberOfExaminers: number;
}

export interface SpecialistPracticesState {
	status: SummaryLoadStatus2;
	summary: SpecialistPracticesSummaryState;
}
export interface SpecialistPracticesSummaryState {
	numberOfPracticesWithoutSpecialists: number;
	numberOfSpecialistsWithoutPractice: number;
	numberOfPractices: number;
	numberOfSpecialists: number;
}

export interface UsersState {
	status: SummaryStatus;
	numberOfUsersToConfirmEmail: number;
	numberOfAdministrators: number,
	numberOfUsers: number;
}

export interface VideosState {
	status: SummaryLoadStatus2;
	summary: VideosSummaryState;
}
interface VideosSummaryState {
	numberOfRoles: number;
	numberOfCategories: number;
	numberOfVideos: number;
}

// ACTIONS ********************************************************************

interface RequestUsersSummaryAction { type: 'REQUEST_USERS_SUMMARY'; }
interface ReceiveUsersSummarySuccessAction {
	type: 'RECEIVE_USERS_SUMMARY_SUCCESS';
    numberOfUsersWaitingApproval: number;
	numberOfUsersToConfirmEmail: number;
	numberOfAdministrators: number;
    numberOfUsers: number;
}
interface ReceiveUsersSummaryFailureAction { type: 'RECEIVE_USERS_SUMMARY_FAILURE'; }
type KnownUsersAction = RequestUsersSummaryAction | ReceiveUsersSummarySuccessAction | ReceiveUsersSummaryFailureAction;

interface RequestPracticesSummaryAction { type: 'REQUEST_PRACTICES_SUMMARY'; }
interface ReceivePracticesSummarySuccessAction {
	type: 'RECEIVE_PRACTICES_SUMMARY_SUCCESS';
	numberOfPracticesWithoutExaminers: number;
	numberOfExaminersWithoutPractice: number;
	numberOfPractices: number;
	numberOfExaminers: number;
}
interface ReceivePracticesSummaryFailureAction { type: 'RECEIVE_PRACTICES_SUMMARY_FAILURE'; }
type KnownPracticesAction = RequestPracticesSummaryAction | ReceivePracticesSummarySuccessAction | ReceivePracticesSummaryFailureAction;

interface RequestOrganisationsSummaryAction { type: 'REQUEST_ORGANISATIONS_SUMMARY'; }
interface ReceiveOrganisationsSummaryAction {
	type: 'RECEIVE_ORGANISATIONS_SUMMARY';
	summary?: OrganisationsSummaryState;
}
type KnownOrganisationsAction = RequestOrganisationsSummaryAction | ReceiveOrganisationsSummaryAction | ReceiveOrganisationsSummaryAction;

interface RequestSpecialistPracticesSummaryAction { type: 'REQUEST_SPECIALIST_PRACTICES_SUMMARY'; }
interface ReceiveSpecialistPracticesSummaryAction { type: 'RECEIVE_SPECIALIST_PRACTICES_SUMMARY'; summary?: SpecialistPracticesSummaryState; }
type KnownSpecialistPracticesAction = RequestSpecialistPracticesSummaryAction | ReceiveSpecialistPracticesSummaryAction;

interface RequestVideosSummaryAction { type: 'REQUEST_VIDEOS_SUMMARY'; }
interface ReceiveVideosSummaryAction {
	type: 'RECEIVE_VIDEOS_SUMMARY';
	summary?: VideosSummaryState;
}
type KnownVideosAction = RequestVideosSummaryAction | ReceiveVideosSummaryAction;

// ACTION INVOKERS ************************************************************

export const adminSummaryActionInvokers = {
	requestSpecialistPracticesSummary: async () => {
		store.dispatch({ type: 'REQUEST_SPECIALIST_PRACTICES_SUMMARY', } as KnownSpecialistPracticesAction);
		try {
			const response = await getJson('api/specialists/summary');
			store.dispatch({ type: 'RECEIVE_SPECIALIST_PRACTICES_SUMMARY', summary: response.summary, } as KnownSpecialistPracticesAction);
		} catch (error) {
			store.dispatch({ type: 'RECEIVE_SPECIALIST_PRACTICES_SUMMARY', } as KnownSpecialistPracticesAction);
			dispatchErrorToast(true, 'Specialist Practices', error);
        }
	},
}

// ACTION CREATORS ************************************************************

export const adminSummaryActionCreators = {
    requestAdminUsersSummary: (): AppThunkAction<Action> => (dispatch, getState) => {
		getJson('api/users/summary')
			.then(response => {
				dispatch({ type: 'RECEIVE_USERS_SUMMARY_SUCCESS', numberOfUsersWaitingApproval: response.numberOfUsersWaitingApproval, numberOfUsersToConfirmEmail: response.numberOfUsersToConfirmEmail, numberOfAdministrators: response.numberOfAdministrators, numberOfUsers: response.numberOfUsers } as KnownUsersAction);
			})
			.catch(handleWithActionAndErrorToast('Users', 'RECEIVE_USERS_SUMMARY_FAILURE'));
		dispatch({ type: 'REQUEST_USERS_SUMMARY' } as KnownUsersAction);
    },

	requestAdminPracticesSummary: (): AppThunkAction<Action> => (dispatch, getState) => {
		getJson('api/practices/summary')
			.then(response => {
				dispatch({ type: 'RECEIVE_PRACTICES_SUMMARY_SUCCESS', numberOfPracticesWithoutExaminers: response.numberOfPracticesWithoutExaminers, numberOfExaminersWithoutPractice: response.numberOfExaminersWithoutPractice, numberOfPractices: response.numberOfPractices, numberOfExaminers: response.numberOfExaminers } as KnownPracticesAction);
			})
			.catch(handleWithActionAndErrorToast('Medical Practices', 'RECEIVE_PRACTICES_SUMMARY_FAILURE'));
		dispatch({ type: 'REQUEST_PRACTICES_SUMMARY' } as KnownPracticesAction);
	},

	requestAdminOrganisationsSummary: (): AppThunkAction<Action> => (dispatch, getState) => {
		getJson('api/organisations/summary')
			.then(response => {
				dispatch({
					type: 'RECEIVE_ORGANISATIONS_SUMMARY',
					summary: response.summary,
				} as KnownOrganisationsAction);
			})
			.catch(handleWithActionAndErrorToast('Flying Organisations', 'RECEIVE_ORGANISATIONS_SUMMARY'));
		dispatch({ type: 'REQUEST_ORGANISATIONS_SUMMARY' } as KnownOrganisationsAction);
	},

	getVideosSummary: (): AppThunkAction<Action> => (dispatch, getState) => {
		getJson('api/videos/summary')
			.then(response => {
				dispatch({ type: 'RECEIVE_VIDEOS_SUMMARY', summary: response.summary, } as KnownVideosAction);
			})
			.catch(handleWithActionAndErrorToast('Videos', 'RECEIVE_VIDEOS_SUMMARY'));
		dispatch({ type: 'REQUEST_VIDEOS_SUMMARY' } as KnownVideosAction);
	},
};

// INITIAL STATE **************************************************************

const initialUsersState: UsersState = {
	status: SummaryStatus.Loading,
	numberOfUsersToConfirmEmail: 0,
	numberOfAdministrators: 0,
	numberOfUsers: 0,
};

const initialPracticesState: PracticesState = {
	status: SummaryStatus.Loading,
	numberOfPracticesWithoutExaminers: 0,
	numberOfExaminersWithoutPractice: 0,
	numberOfPractices: 0,
	numberOfExaminers: 0,
};

const initialOrganisationsState: OrganisationsState = {
	status: SummaryStatus.Loading,
	summary: {
		numberOfOrganisationsWithoutStaff: 0,
		numberOfStaffWithoutOrganisations: 0,
		numberOfActiveOrganisations: 0,
		numberOfInactiveOrganisations: 0,
		numberOfActiveStaff: 0,
		numberOfInactiveStaff: 0,
	},
};

const initialSpecialistPracticesState: SpecialistPracticesState = {
	status: SummaryLoadStatus2.Loading,
	summary: {
		numberOfPracticesWithoutSpecialists: 0,
		numberOfSpecialistsWithoutPractice: 0,
		numberOfPractices: 0,
		numberOfSpecialists: 0,
	},
}

const initialVideosState: VideosState = {
	status: SummaryLoadStatus2.Idle,
	summary: {
		numberOfRoles: 0,
		numberOfCategories: 0,
		numberOfVideos: 0,
	},
}

// REDUCERS *******************************************************************

export const usersReducer: Reducer<UsersState | null> = (state: UsersState | null | undefined, action: KnownUsersAction) => {
	if (state === undefined)
		return null;
	state = state!;	// @@@ I hope this works
    switch (action.type) {
		case 'REQUEST_USERS_SUMMARY':
			if (state) {
				return {
					...state,
					status: SummaryStatus.Loading,
				};
			} else {
				return initialUsersState;
			}
        case 'RECEIVE_USERS_SUMMARY_SUCCESS':
            return {
				status: SummaryStatus.Success,
				numberOfUsersWaitingApproval: action.numberOfUsersWaitingApproval,
				numberOfUsersToConfirmEmail: action.numberOfUsersToConfirmEmail,
				numberOfAdministrators: action.numberOfAdministrators, 
				numberOfUsers: action.numberOfUsers,
            };
        case 'RECEIVE_USERS_SUMMARY_FAILURE':
            return {
				...state,
				status: SummaryStatus.Failure,
            };
		// eslint-disable-next-line @typescript-eslint/no-unused-vars
        default: const exhaustiveCheck: never = action;
    }

    return state;
};

export const practicesReducer: Reducer<PracticesState | null> = (state: PracticesState | null | undefined, action: KnownPracticesAction) => {
	if (state === undefined)
		return null;
	state = state!;	// @@@ I hope this works
	switch (action.type) {
		case 'REQUEST_PRACTICES_SUMMARY':
			if (state) {
				return {
					...state,
					status: SummaryStatus.Loading,
				};
			} else {
				return initialPracticesState;
			}
		case 'RECEIVE_PRACTICES_SUMMARY_SUCCESS':
			return {
				status: SummaryStatus.Success,
				numberOfPracticesWithoutExaminers: action.numberOfPracticesWithoutExaminers,
				numberOfExaminersWithoutPractice: action.numberOfExaminersWithoutPractice,
				numberOfPractices: action.numberOfPractices,
				numberOfExaminers: action.numberOfExaminers,
			};
		case 'RECEIVE_PRACTICES_SUMMARY_FAILURE':
			return {
				...state,
				status: SummaryStatus.Failure,
			};
		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		default: const exhaustiveCheck: never = action;
	}

	return state;
};

export const organisationsReducer: Reducer<OrganisationsState | null> = (state: OrganisationsState | null | undefined, action: KnownOrganisationsAction) => {
	if (state === undefined)
		return null;
	state = state!;	// @@@ I hope this works
	switch (action.type) {
		case 'REQUEST_ORGANISATIONS_SUMMARY':
			if (state)
				return {
					...initialOrganisationsState,
					status: SummaryStatus.Loading,
				};
			else return initialOrganisationsState;
		case 'RECEIVE_ORGANISATIONS_SUMMARY':
			return {
				status: action.summary ? SummaryStatus.Success : SummaryStatus.Failure,
				summary: action.summary || initialOrganisationsState.summary,
			};
		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		default: const exhaustiveCheck: never = action;
	}
	return state;
};

export const specialistPracticesReducer: Reducer<SpecialistPracticesState | null> = (state: SpecialistPracticesState | null | undefined, action: KnownSpecialistPracticesAction) => {
	if (state === undefined)
		return null;
	state = state!;
	switch (action.type) {
		case 'REQUEST_SPECIALIST_PRACTICES_SUMMARY':
			return {
				...(state ?? initialSpecialistPracticesState),
				status: SummaryLoadStatus2.Loading,
			} as SpecialistPracticesState;
		case 'RECEIVE_SPECIALIST_PRACTICES_SUMMARY':
			return {
				status: action.summary ? SummaryLoadStatus2.Loaded : SummaryLoadStatus2.Failure,
				summary: action.summary ?? initialSpecialistPracticesState.summary,
			} as SpecialistPracticesState;
		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		default: const exhaustiveCheck: never = action;
	}
	return state;
};

export const videosReducer: Reducer<VideosState | null> = (state: VideosState | null | undefined, action: KnownVideosAction) => {
	if (state === undefined)
		return null;
	state = state!;	// @@@ I hope this works
	switch (action.type) {
		case 'REQUEST_VIDEOS_SUMMARY':
			return {
				...initialVideosState,
				status: SummaryLoadStatus2.Loading,
			};
		case 'RECEIVE_VIDEOS_SUMMARY':
			return {
				status: !action.summary ? SummaryLoadStatus2.Failure : SummaryLoadStatus2.Loaded,
				summary: action.summary || state.summary,
			};
		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		default: const exhaustiveCheck: never = action;
	}

	return state;
};
