import { Action, combineReducers, Reducer } from "redux";
import * as ResultUploadCodeListStore from './ResultUploadCodeListStore';
import * as SpecialistOrganisationListStore from './SpecialistOrganisationListStore';
import * as SelectedSpecialistPracticeStore from './SelectedSpecialistPracticeStore';
import * as SpecialistPracticeListStore from './SpecialistPracticeListStore';
import * as UploadResultStore from './UploadResultStore';

// STATE **********************************************************************

export interface SpecialistState {
	listOfOrganisations: SpecialistOrganisationListStore.SpecialistOrganisationListState | null;
	listOfPractices: SpecialistPracticeListStore.SpecialistPracticeListState | null;
	listOfResultUploadCodes: ResultUploadCodeListStore.ResultUploadCodeListState | null;
	selectedPractice: SelectedSpecialistPracticeStore.SelectedSpecialistPracticeState | null;
	uploadResult: UploadResultStore.UploadResultState | null;
}

// REDUCERS *******************************************************************

const combinedReducer: Reducer<SpecialistState> = combineReducers<SpecialistState>({
	listOfOrganisations: SpecialistOrganisationListStore.reducer,
	listOfPractices: SpecialistPracticeListStore.reducer,
	listOfResultUploadCodes: ResultUploadCodeListStore.reducer,
	selectedPractice: SelectedSpecialistPracticeStore.reducer,
	uploadResult: UploadResultStore.reducer,
});

export const reducer: Reducer<SpecialistState> = (state: SpecialistState | undefined, action: Action) => {
	if (state === undefined || action.type === 'REQUEST_LOGOUT')
		state = undefined;
	return combinedReducer(state, action);
}
