import { Action, Reducer } from 'redux';
import { AppThunkAction } from '..';
import { store } from '../..';
import { ListStatus2 } from '../list/ListCommon';
import { getJson, handleWithActionAndErrorToast, handleWithErrorToast } from '../myfetch';
import { dispatchErrorToast } from '../notification/NotificationStore';
import { LoadStatus2 } from '../StoreCommon';

// From SkyCert v1

// STATE **********************************************************************

export interface SelectionsState {
	// Airfields
	airfieldsStatus: ListStatus2;
	airfields_RegionId?: number;
	airfields?: AirfieldSelection[];

	// Checklists
	checklistList: ChecklistSelection[];

	// Flying organisations
	flyingOrganisationsStatus: ListStatus2;
	flyingOrganisations?: FlyingOrganisationSelection[];

	// Document types
	documentTypesStatus?: LoadStatus2;
	documentTypesFilter1?: string;
	documentTypes?: DocumentTypeGroupSelection[];

	// Flying organisation staff
	flyingOrganisationStaffStatus: ListStatus2;
	flyingOrganisationStaff_FlyingOrganisationId?: number;
	flyingOrganisationStaff?: UserRoleSelection[];

	// Forms
	medicalDocumentTypeListStatus: ListStatus2;
	medicalDocumentTypeList: MedicalDocumentTypeSelection[];

	// Form sections and questions for a document type
	formSectionsStatus?: LoadStatus2;
	formSectionsDocumentTypeId?: number;
	formSections?: FormSectionSelection[];

	// Medical examiners
	medicalExaminerListStatus: ListStatus2;
	medicalExaminerListMedicalPracticeId?: number;
	medicalExaminerList?: MedicalExaminerSelection[];

	// Medical practices
	medicalPracticeListStatus: MedicalPracticeListStatus;
	medicalPracticeList?: MedicalPracticeSelection[];

	// Medical practices and examiners
	practiceExaminersStatus: ListStatus2;
	practiceExaminersState: string;
	practiceExaminersRegionId: string;
	practiceExaminers: PracticeExaminerSelection[];

	// Pilots
	pilotsStatus: ListStatus2;
	pilots_FlyingOrganisationId?: number;
	pilots?: UserRoleSelection[];

	// Practice locations
	practiceLocationListStatus: ListStatus2;
	practiceLocationListMedicalPracticeId?: number;
	practiceLocationList?: PracticeLocationListSelection[];

	// Regions
	regionsStatus: ListStatus2;
	regions: RegionStateSelection[];

	// Specialists
	specialistListStatus: ListStatus2;
	specialistListSpecialistPracticeId?: number;
	specialistList?: SpecialistSelection[];

	// Specialist practices
	specialistPracticeListStatus: ListStatus2;
	specialistPracticeList?: SpecialistPracticeSelection[];

	// Standard endorsements
	standardEndorsementListStatus: ListStatus2;
	standardEndorsementList?: StandardEndorsementSelection[];

	// User metric groups
	userMetricGroupsStatus: ListStatus2;
	userMetricGroups: string[];

	// Video categories
	videoCategoriesStatus: ListStatus2;
	videoCategories: VideoCategoryStateSelection[];
}

export interface DocumentTypeGroupSelection {
	groupName: string;
	documentTypes: DocumentTypeSelection[];
}

export interface DocumentTypeSelection {
	documentTypeId: number;
	name: string;
	allowDuplicates: boolean;
	canCreate: boolean;
	canUpload: boolean;
	canTest: boolean;
	canEdit: boolean;
	canDelete: boolean;
	isForm: boolean;
	requireUploadCode: boolean;
	warningOnCreate?: string;
}

export interface FormSectionSelection {
	formSectionId: number;
	sectionName: string;
	questions: FormQuestionSelection[];
}

export interface FormQuestionSelection {
	formQuestionId: number;
	questionText: string;
}

export interface UserRoleSelection {
	userId: string;
	name: string;
}

export interface MedicalExaminerSelection {
	userId: string;
	name: string;
}

export interface SpecialistSelection {
	userId: string;
	name: string;
}

export enum MedicalPracticeListStatus {
	NotRequested,
	Loading,
	Loaded,
	Failure,
}
export interface MedicalPracticeSelection {
	medicalPracticeId: number;
	name: string;
	singleExaminerName: string;
}

export interface SpecialistPracticeSelection {
	specialistPracticeId: number;
	name: string;
}

export interface StandardEndorsementSelection {
	name: string;
	description: string;
}

export interface MedicalDocumentTypeSelection {
	documentTypeId: number;
	name: string;
	groupName: string;
	allowDuplicates: boolean;
	canCreate: boolean;
	canUpload: boolean;
	canEdit: boolean;	// TODO @@@ Is this needed?
	canDelete: boolean;
	warningOnCreate?: string;
}

export interface ChecklistSelection {
	code: string;
	name: string;
	box3Label?: string;
	items: ChecklistItemSelection[];
}
export interface ChecklistItemSelection {
	text: string;
	value: number;
	allowRequired: boolean;
	allowBox3: boolean;
}

export interface RegionStateSelection {
	state: string;
	regions: RegionSelection[];
}
export interface RegionSelection {
	regionId: number;
	name: string;
}

export interface VideoCategoryStateSelection {
	role: string;
	categories: VideoCategorySelection[];
}
export interface VideoCategorySelection {
	videoCategoryId: number;
	name: string;
}

export interface PracticeExaminerSelection {
	examinerName: string;
	examinerPhoto: string;
	jurisdictions?: string;
	practiceName: string;
	practiceLogo?: string;
	locations: PracticeLocationSelection[];
}
export interface PracticeLocationSelection {
	name: string;
	address: string;
	phone?: string;
	email?: string;
	website?: string;
	tests?: string;
}

export interface PracticeLocationListSelection {
	name: string;
	practiceLocationId: number;
}

export interface FlyingOrganisationSelection {
	flyingOrganisationId: number;
	name: string;
}

export interface AirfieldSelection {
	airfieldId: number;
	displayName: string;
}

// ACTIONS ********************************************************************

interface RequestLoadAvailableMedicalExaminerListAction { type: 'REQUEST_LOAD_AVAILABLE_MEDICAL_EXAMINER_LIST', medicalPracticeId?: number; }
interface ReceiveLoadAvailableMedicalExaminerListSuccessAction { type: 'RECEIVE_LOAD_AVAILABLE_MEDICAL_EXAMINER_LIST_SUCCESS'; medicalExaminerList: MedicalExaminerSelection[]; medicalPracticeId?: number; }
interface ReceiveLoadAvailableMedicalExaminerListFailureAction { type: 'RECEIVE_LOAD_AVAILABLE_MEDICAL_EXAMINER_LIST_FAILURE' }

interface RequestLoadAvailableSpecialistListAction { type: 'REQUEST_LOAD_AVAILABLE_SPECIALIST_LIST'; specialistPracticeId?: number; }
interface ReceiveLoadAvailableSpecialistListAction { type: 'RECEIVE_LOAD_AVAILABLE_SPECIALIST_LIST'; specialistList?: SpecialistSelection[]; specialistPracticeId?: number; }

interface RequestLoadAvailablePracticeLocationListAction { type: 'REQUEST_LOAD_AVAILABLE_PRACTICE_LOCATION_LIST', medicalPracticeId?: number; }
interface ReceiveLoadAvailablePracticeLocationListSuccessAction { type: 'RECEIVE_LOAD_AVAILABLE_PRACTICE_LOCATION_LIST_SUCCESS'; practiceLocationList: PracticeLocationListSelection[]; medicalPracticeId?: number; }
interface ReceiveLoadAvailablePracticeLocationListFailureAction { type: 'RECEIVE_LOAD_AVAILABLE_PRACTICE_LOCATION_LIST_FAILURE' }

interface RequestLoadAvailableMedicalPracticeListAction { type: 'REQUEST_LOAD_AVAILABLE_MEDICAL_PRACTICE_LIST' }
interface ReceiveLoadAvailableMedicalPracticeListSuccessAction { type: 'RECEIVE_LOAD_AVAILABLE_MEDICAL_PRACTICE_LIST_SUCCESS'; medicalPracticeList: MedicalPracticeSelection[]; }
interface ReceiveLoadAvailableMedicalPracticeListFailureAction { type: 'RECEIVE_LOAD_AVAILABLE_MEDICAL_PRACTICE_LIST_FAILURE' }

interface RequestLoadAvailableSpecialistPracticeListAction { type: 'REQUEST_LOAD_AVAILABLE_SPECIALIST_PRACTICE_LIST'; }
interface ReceiveLoadAvailableSpecialistPracticeListAction { type: 'RECEIVE_LOAD_AVAILABLE_SPECIALIST_PRACTICE_LIST'; specialistPracticeList?: SpecialistPracticeSelection[]; }

interface RequestStandardEndorsementListAction { type: 'REQUEST_STANDARD_ENDORSEMENT_LIST'; }
interface ReceiveStandardEndorsementListSuccessAction { type: 'RECEIVE_STANDARD_ENDORSEMENT_LIST_SUCCESS'; standardEndorsementList: StandardEndorsementSelection[]; }
interface ReceiveStandardEndorsementListFailureAction { type: 'RECEIVE_STANDARD_ENDORSEMENT_LIST_FAILURE'; }

interface RequestMedicalDocumentTypeListAction { type: 'REQUEST_MEDICAL_DOCUMENT_TYPE_LIST'; }
interface ReceiveMedicalDocumentTypeListSuccessAction { type: 'RECEIVE_MEDICAL_DOCUMENT_TYPE_LIST_SUCCESS'; medicalDocumentTypeList: MedicalDocumentTypeSelection[]; }
interface ReceiveMedicalDocumentTypeListFailureAction { type: 'RECEIVE_MEDICAL_DOCUMENT_TYPE_LIST_FAILURE'; }

interface ReceiveChecklistSelectionSuccessAction { type: 'RECEIVE_CHECKLIST_SELECTION_SUCCESS'; checklist: ChecklistSelection; }

interface RequestRegionListAction { type: 'REQUEST_REGION_LIST'; }
interface ReceiveRegionListAction { type: 'RECEIVE_REGION_LIST'; regions?: RegionStateSelection[]; }

interface RequestVideoCategoryListAction { type: 'REQUEST_VIDEO_CATEGORY_LIST'; }
interface ReceiveVideoCategoryListAction { type: 'RECEIVE_VIDEO_CATEGORY_LIST'; videoCategories?: VideoCategoryStateSelection[]; }

interface RequestPracticeExaminersSelectionsAction { type: 'REQUEST_PRACTICE_EXAMINERS_SELECTIONS'; state: string; regionId: string; }
interface ReceivePracticeExaminersSelectionsAction { type: 'RECEIVE_PRACTICE_EXAMINERS_SELECTIONS'; practiceExaminers?: PracticeExaminerSelection[]; state?: string; regionId?: string; }

interface RequestUserMetricGroupsSelectionsAction { type: 'REQUEST_USER_METRICS_GROUPS_SELECTIONS'; }
interface ReceiveUserMetricGroupsSelectionsAction { type: 'RECEIVE_USER_METRICS_GROUPS_SELECTIONS'; userMetricGroups?: string[]; }

interface RequestFlyingOrganisationsAction { type: 'REQUEST_FLYING_ORGANISATIONS'; }
interface ReceiveFlyingOrganisationsAction { type: 'RECEIVE_FLYING_ORGANISATIONS'; flyingOrganisations: FlyingOrganisationSelection[]; flyingOrganisationId?: number; }

interface RequestFlyingOrganisationStaffSelectionsAction { type: 'REQUEST_FLYING_ORGANISATION_STAFF_SELECTIONS'; flyingOrganisationId?: number; }
interface ReceiveFlyingOrganisationStaffSelectionsAction { type: 'RECEIVE_FLYING_ORGANISATION_STAFF_SELECTIONS'; flyingOrganisationStaff: UserRoleSelection[]; flyingOrganisationId?: number; }

interface RequestPilotsSelectionsAction { type: 'REQUEST_PILOTS_SELECTIONS'; flyingOrganisationId?: number; }
interface ReceivePilotsSelectionsAction { type: 'RECEIVE_PILOTS_SELECTIONS'; pilots: UserRoleSelection[]; flyingOrganisationId?: number; }

interface RequestAirfieldsSelectionsAction { type: 'REQUEST_AIRFIELDS_SELECTIONS'; regionId?: number; }
interface ReceiveAirfieldsSelectionsAction { type: 'RECEIVE_AIRFIELDS_SELECTIONS'; airfields?: AirfieldSelection[]; regionId?: number; }

interface RequestDocumentTypesAction { type: 'REQUEST_DOCUMENT_TYPES'; filter1: string; }
interface ReceiveDocumentTypesAction { type: 'RECEIVE_DOCUMENT_TYPES'; filter1?: string; documentTypes?: DocumentTypeGroupSelection[]; }

interface RequestFormQuestionsAction { type: 'REQUEST_FORM_SECTIONS'; documentTypeId: number; }
interface ReceiveFormQuestionsAction { type: 'RECEIVE_FORM_SECTIONS'; documentTypeId?: number; formSections?: FormSectionSelection[]; }

type KnownAction =
	| RequestLoadAvailableMedicalExaminerListAction | ReceiveLoadAvailableMedicalExaminerListSuccessAction | ReceiveLoadAvailableMedicalExaminerListFailureAction
	| RequestLoadAvailableSpecialistListAction | ReceiveLoadAvailableSpecialistListAction
	| RequestLoadAvailablePracticeLocationListAction | ReceiveLoadAvailablePracticeLocationListSuccessAction | ReceiveLoadAvailablePracticeLocationListFailureAction
	| RequestLoadAvailableMedicalPracticeListAction | ReceiveLoadAvailableMedicalPracticeListSuccessAction | ReceiveLoadAvailableMedicalPracticeListFailureAction
	| RequestStandardEndorsementListAction | ReceiveStandardEndorsementListSuccessAction | ReceiveStandardEndorsementListFailureAction
	| RequestMedicalDocumentTypeListAction | ReceiveMedicalDocumentTypeListSuccessAction | ReceiveMedicalDocumentTypeListFailureAction
	| ReceiveChecklistSelectionSuccessAction
	| RequestRegionListAction | ReceiveRegionListAction
	| RequestVideoCategoryListAction | ReceiveVideoCategoryListAction
	| RequestPracticeExaminersSelectionsAction | ReceivePracticeExaminersSelectionsAction
	| RequestUserMetricGroupsSelectionsAction | ReceiveUserMetricGroupsSelectionsAction
	| RequestFlyingOrganisationsAction | ReceiveFlyingOrganisationsAction
	| RequestFlyingOrganisationStaffSelectionsAction | ReceiveFlyingOrganisationStaffSelectionsAction
	| RequestPilotsSelectionsAction | ReceivePilotsSelectionsAction
	| RequestAirfieldsSelectionsAction | ReceiveAirfieldsSelectionsAction
	| RequestDocumentTypesAction | ReceiveDocumentTypesAction
	| RequestFormQuestionsAction | ReceiveFormQuestionsAction
	| RequestLoadAvailableSpecialistPracticeListAction | ReceiveLoadAvailableSpecialistPracticeListAction
	;

// ACTION INVOKERS ************************************************************

export const selectionsActionInvokers = {
	/// filter1: medical, view
	requestDocumentTypes: async (filter1: string) => {
		store.dispatch({ type: 'REQUEST_DOCUMENT_TYPES', filter1, } as KnownAction);
		try {
			const response = await getJson(`/api/selections/documenttypes?filter1=${filter1}`);
			store.dispatch({ type: 'RECEIVE_DOCUMENT_TYPES', filter1, documentTypes: response.documentTypes, } as KnownAction);
		} catch (error) {
			store.dispatch({ type: 'RECEIVE_DOCUMENT_TYPES', } as KnownAction);
			dispatchErrorToast(true, 'Document Types', error);
		}
	},

	requestFormQuestions: async (documentTypeId: number) => {
		store.dispatch({ type: 'REQUEST_FORM_SECTIONS', documentTypeId, } as KnownAction);
		try {
			const response = await getJson(`/api/selections/documenttypes/${documentTypeId}/formsections`);
			store.dispatch({ type: 'RECEIVE_FORM_SECTIONS', documentTypeId, formSections: response.formSections, } as KnownAction);
		} catch (error) {
			store.dispatch({ type: 'RECEIVE_FORM_SECTIONS', } as KnownAction);
			dispatchErrorToast(true, 'Form Sections', error);
		}
	},

	requestSpecialists: async (specialistPracticeId?: number) => {
		const selections = store.getState().selections;
		if (selections.specialistListSpecialistPracticeId === specialistPracticeId && (selections.specialistListStatus === ListStatus2.Loaded || selections.specialistListStatus === ListStatus2.Loading))
			return;
		try {
			store.dispatch({ type: 'REQUEST_LOAD_AVAILABLE_SPECIALIST_LIST', specialistPracticeId, } as KnownAction);
			const practiceSuffix = !specialistPracticeId ? '' : `?specialistPracticeId=${specialistPracticeId}`;
			const response = await getJson(`api/selections/specialists${practiceSuffix}`);
			store.dispatch({ type: 'RECEIVE_LOAD_AVAILABLE_SPECIALIST_LIST', specialistList: response.specialistList, specialistPracticeId, } as KnownAction);
		} catch (error) {
			store.dispatch({ type: 'RECEIVE_LOAD_AVAILABLE_SPECIALIST_LIST', specialistPracticeId, } as KnownAction);
			dispatchErrorToast(true, 'Specialists', error);
		}
	},

	requestSpecialistPractices: async () => {
		try {
			store.dispatch({ type: 'REQUEST_LOAD_AVAILABLE_SPECIALIST_PRACTICE_LIST', } as KnownAction);
			const response = await getJson('api/selections/specialistpractices');
			store.dispatch({ type: 'RECEIVE_LOAD_AVAILABLE_SPECIALIST_PRACTICE_LIST', specialistPracticeList: response.specialistPracticeList, } as KnownAction);
		} catch (error) {
			store.dispatch({ type: 'RECEIVE_LOAD_AVAILABLE_SPECIALIST_PRACTICE_LIST', } as KnownAction);
			dispatchErrorToast(true, 'Specialist Practices', error);
		}
	},
};

// ACTION CREATORS ************************************************************

export const selectionsActionCreators = {
	requestLoadAvailableMedicalExaminerList: (medicalPracticeId: number | undefined): AppThunkAction<Action> => (dispatch, getState) => {
		const selections: SelectionsState = getState().selections;
		if (medicalPracticeId === selections.medicalExaminerListMedicalPracticeId
			&& (selections.medicalExaminerListStatus === ListStatus2.Loaded
				|| selections.medicalExaminerListStatus === ListStatus2.Loading))
			return;
		const practiceSuffix: string = medicalPracticeId === undefined ? '' : `?medicalPracticeId=${medicalPracticeId}`;
		getJson(`api/selections/examiners${practiceSuffix}`)
			.then(response => {
				dispatch({ type: 'RECEIVE_LOAD_AVAILABLE_MEDICAL_EXAMINER_LIST_SUCCESS', medicalExaminerList: response.medicalExaminerList, medicalPracticeId, } as KnownAction);
			})
			.catch(handleWithActionAndErrorToast('Medical Examiners', 'RECEIVE_LOAD_AVAILABLE_MEDICAL_EXAMINER_LIST_FAILURE'));
		dispatch({ type: 'REQUEST_LOAD_AVAILABLE_MEDICAL_EXAMINER_LIST', medicalPracticeId, } as KnownAction);
	},

	requestLoadAvailablePracticeLocationList: (medicalPracticeId: number | undefined): AppThunkAction<Action> => (dispatch, getState) => {
		const selections: SelectionsState = getState().selections;
		if (medicalPracticeId === selections.practiceLocationListMedicalPracticeId
			&& (selections.practiceLocationListStatus === ListStatus2.Loaded
			|| selections.practiceLocationListStatus === ListStatus2.Loading))
			return;
		const practiceSuffix: string = !medicalPracticeId ? '' : `?medicalPracticeId=${medicalPracticeId}`;
		getJson(`api/selections/locations${practiceSuffix}`)
			.then(response => {
				dispatch({ type: 'RECEIVE_LOAD_AVAILABLE_PRACTICE_LOCATION_LIST_SUCCESS', practiceLocationList: response.practiceLocationList, medicalPracticeId, } as KnownAction);
			})
			.catch(handleWithActionAndErrorToast('Practice Locations', 'RECEIVE_LOAD_AVAILABLE_PRACTICE_LOCATION_LIST_FAILURE'));
		dispatch({ type: 'REQUEST_LOAD_AVAILABLE_PRACTICE_LOCATION_LIST', medicalPracticeId, } as KnownAction);
	},

	requestLoadAvailableMedicalPracticeList: (): AppThunkAction<Action> => (dispatch, getState) => {
		getJson(`api/selections/practices`)
			.then(response => {
				dispatch({ type: 'RECEIVE_LOAD_AVAILABLE_MEDICAL_PRACTICE_LIST_SUCCESS', medicalPracticeList: response.medicalPracticeList } as KnownAction);
			})
			.catch(handleWithActionAndErrorToast('Medical Practices', 'RECEIVE_LOAD_AVAILABLE_MEDICAL_PRACTICE_LIST_FAILURE'));
		dispatch({ type: 'REQUEST_LOAD_AVAILABLE_MEDICAL_PRACTICE_LIST' } as KnownAction);
	},

	requestStandardEndorsementList: (): AppThunkAction<Action> => (dispatch, getState) => {
		getJson(`api/selections/standardendorsements`)
			.then(response => {
				dispatch({ type: 'RECEIVE_STANDARD_ENDORSEMENT_LIST_SUCCESS', standardEndorsementList: response.standardEndorsementList, } as KnownAction);
			})
			.catch(handleWithActionAndErrorToast('Standard Endorsements', 'RECEIVE_STANDARD_ENDORSEMENT_LIST_FAILURE'));
		dispatch({ type: 'REQUEST_STANDARD_ENDORSEMENT_LIST', } as KnownAction);
	},

	// TODO @@@ OBSOLETE - replace with actionInvokers call
	requestMedicalDocumentTypeList: (): AppThunkAction<Action> => (dispatch, getState) => {
		getJson(`api/selections/medicaldocumenttypes`)
			.then(response => {
				dispatch({ type: 'RECEIVE_MEDICAL_DOCUMENT_TYPE_LIST_SUCCESS', medicalDocumentTypeList: response.medicalDocumentTypeList, } as KnownAction);
			})
			.catch(handleWithActionAndErrorToast('Document Types', 'RECEIVE_MEDICAL_DOCUMENT_TYPE_LIST_FAILURE'));
		dispatch({ type: 'REQUEST_MEDICAL_DOCUMENT_TYPE_LIST', } as KnownAction);
	},

	requestChecklistSelection: (code: string): AppThunkAction<Action> => (dispatch, getState) => {
		if (getState().selections.checklistList.find(c => c.code === code))
			return;
		getJson(`api/selections/checklist/${code}`)
			.then(response => {
				dispatch({ type: 'RECEIVE_CHECKLIST_SELECTION_SUCCESS', checklist: response.checklist, } as KnownAction);
			})
			.catch(handleWithErrorToast('Checklist'));
	},

	getRegionList: (forceLoad: boolean = false): AppThunkAction<Action> => (dispatch, getState) => {
		const selections: SelectionsState = getState().selections;
		if (selections.regionsStatus === ListStatus2.Loaded && !forceLoad)
			return;
		getJson(`api/regions/selections`)
			.then(response => {
				dispatch({ type: 'RECEIVE_REGION_LIST', regions: response.regions, } as KnownAction);
			})
			.catch(handleWithActionAndErrorToast('Regions', 'RECEIVE_REGION_LIST'));
		dispatch({ type: 'REQUEST_REGION_LIST', } as KnownAction);
	},

	getVideoCategoryList: (forceLoad: boolean = false): AppThunkAction<Action> => (dispatch, getState) => {
		const selections: SelectionsState = getState().selections;
		if (selections.videoCategoriesStatus === ListStatus2.Loaded && !forceLoad)
			return;
		getJson(`api/videos/categories/selections`)
			.then(response => {
				dispatch({ type: 'RECEIVE_VIDEO_CATEGORY_LIST', videoCategories: response.videoCategories, } as KnownAction);
			})
			.catch(handleWithActionAndErrorToast('Video Categories', 'RECEIVE_VIDEO_CATEGORY_LIST'));
		dispatch({ type: 'REQUEST_VIDEO_CATEGORY_LIST', } as KnownAction);
	},

	getPracticeExaminerList: (state: string, regionId: string, forceLoad: boolean = false): AppThunkAction<Action> => (dispatch, getState) => {
		const selections: SelectionsState = getState().selections;
		if (selections.practiceExaminersStatus === ListStatus2.Loaded
			&& state === selections.practiceExaminersState
			&& regionId === selections.practiceExaminersRegionId
			&& !forceLoad)
			return;
		getJson(`api/practices/selections/${state || 0}/${regionId || 0}`)
			.then(response => {
				dispatch({ type: 'RECEIVE_PRACTICE_EXAMINERS_SELECTIONS', practiceExaminers: response.practiceExaminers, state, regionId } as KnownAction);
			})
			.catch(handleWithActionAndErrorToast('Practice Examiners', 'RECEIVE_PRACTICE_EXAMINERS_SELECTIONS'));
		dispatch({ type: 'REQUEST_PRACTICE_EXAMINERS_SELECTIONS', state, regionId, } as KnownAction);
	},

	getUserMetricGroupList: (): AppThunkAction<Action> => (dispatch, getState) => {
		const selections: SelectionsState = getState().selections;
		if (selections.userMetricGroupsStatus === ListStatus2.Loaded || selections.userMetricGroupsStatus === ListStatus2.Loading)
			return;
		getJson('api/selections/userMetricGroups')
			.then(response => {
				dispatch({ type: 'RECEIVE_USER_METRICS_GROUPS_SELECTIONS', userMetricGroups: response.userMetricGroups, } as KnownAction);
			})
			.catch(handleWithActionAndErrorToast('User Metrics Groups', 'RECEIVE_USER_METRICS_GROUPS_SELECTIONS'));
		dispatch({ type: 'REQUEST_USER_METRICS_GROUPS_SELECTIONS', } as KnownAction);
	},

	getAvailableFlyingOrganisations: (forceLoad: boolean = false): AppThunkAction<Action> => (dispatch, getState) => {
		const selections: SelectionsState = getState().selections;
		if (selections.flyingOrganisationsStatus === ListStatus2.Loaded && !forceLoad)
			return;
		getJson(`api/selections/Organisations`)
			.then(response => {
				dispatch({ type: 'RECEIVE_FLYING_ORGANISATIONS', flyingOrganisations: response.flyingOrganisations, } as KnownAction);
			})
			.catch(handleWithActionAndErrorToast('Flying Organisations', 'RECEIVE_FLYING_ORGANISATIONS'));
		dispatch({ type: 'REQUEST_FLYING_ORGANISATIONS', } as KnownAction);
	},

	getAvailableFlyingOrganisationStaff: (flyingOrganisationId: number | undefined): AppThunkAction<Action> => (dispatch, getState) => {
		const state: SelectionsState = getState().selections;
		if (flyingOrganisationId === state.flyingOrganisationStaff_FlyingOrganisationId && state.flyingOrganisationStaffStatus !== ListStatus2.Idle)
			return;
		const suffix: string = flyingOrganisationId === undefined ? '' : `?flyingOrganisationId=${flyingOrganisationId}`;
		getJson(`api/selections/OrganisationStaff${suffix}`)
			.then(response => {
				dispatch({ type: 'RECEIVE_FLYING_ORGANISATION_STAFF_SELECTIONS', flyingOrganisationStaff: response.flyingOrganisationStaff, flyingOrganisationId, } as KnownAction);
			})
			.catch(handleWithActionAndErrorToast('Staff Members', 'RECEIVE_FLYING_ORGANISATION_STAFF_SELECTIONS'));
		dispatch({ type: 'REQUEST_FLYING_ORGANISATION_STAFF_SELECTIONS', flyingOrganisationId, } as KnownAction);
	},

	getAvailablePilots: (flyingOrganisationId: string | number | undefined): AppThunkAction<Action> => (dispatch, getState) => {
		const state: SelectionsState = getState().selections;
		if (flyingOrganisationId === state.pilots_FlyingOrganisationId && state.pilotsStatus !== ListStatus2.Idle)
			return;
		const suffix: string = flyingOrganisationId === undefined ? '' : `?flyingOrganisationId=${flyingOrganisationId}`;
		getJson(`api/selections/Pilots${suffix}`)
			.then(response => {
				dispatch({ type: 'RECEIVE_PILOTS_SELECTIONS', pilots: response.pilots, flyingOrganisationId, } as KnownAction);
			})
			.catch(handleWithActionAndErrorToast('Pilots', 'RECEIVE_PILOTS_SELECTIONS'));
		dispatch({ type: 'REQUEST_PILOTS_SELECTIONS', flyingOrganisationId, } as KnownAction);
	},

	getAirfieldList: (regionId: number): AppThunkAction<Action> => (dispatch, getState) => {
		const state: SelectionsState = getState().selections;
		if (regionId === state.airfields_RegionId && state.airfieldsStatus !== ListStatus2.Idle)
			return;
		getJson(`api/selections/airfields?regionId=${regionId}`)
			.then(response => {
				dispatch({ type: 'RECEIVE_AIRFIELDS_SELECTIONS', airfields: response.airfields, regionId, } as KnownAction);
			})
			.catch(handleWithActionAndErrorToast('Airfields', 'RECEIVE_AIRFIELDS_SELECTIONS'));
		dispatch({ type: 'REQUEST_AIRFIELDS_SELECTIONS', regionId, } as KnownAction);
	},

	requestDocumentTypeList: (filter1: string): AppThunkAction<Action> => (dispatch, getState) => {
		getJson(`api/selections/documentTypes?filter1=${filter1}`)
			.then(response => {
				dispatch({ type: 'RECEIVE_DOCUMENT_TYPES', filter1, documentTypes: response.documentTypes, } as KnownAction);
			})
			.catch(handleWithActionAndErrorToast('Document Types', 'RECEIVE_DOCUMENT_TYPES'));
		dispatch({ type: 'REQUEST_DOCUMENT_TYPES', filter1, } as KnownAction);
	},
};

// INITIAL STATE **************************************************************

const initialState: SelectionsState = {
	medicalExaminerListStatus: ListStatus2.Idle,
	practiceLocationListStatus: ListStatus2.Idle,
	medicalPracticeListStatus: MedicalPracticeListStatus.NotRequested,
	standardEndorsementListStatus: ListStatus2.Idle,
	medicalDocumentTypeListStatus: ListStatus2.Idle,
	medicalDocumentTypeList: [],
	checklistList: [],
	regionsStatus: ListStatus2.Idle,
	regions: [],
	videoCategoriesStatus: ListStatus2.Idle,
	videoCategories: [],
	practiceExaminersStatus: ListStatus2.Idle,
	practiceExaminersState: '',
	practiceExaminersRegionId: '',
	practiceExaminers: [],
	userMetricGroupsStatus: ListStatus2.Idle,
	userMetricGroups: [],
	flyingOrganisationsStatus: ListStatus2.Idle,
	flyingOrganisationStaffStatus: ListStatus2.Idle,
	pilotsStatus: ListStatus2.Idle,
	airfieldsStatus: ListStatus2.Idle,
	specialistListStatus: ListStatus2.Idle,
	specialistPracticeListStatus: ListStatus2.Idle,
}

// REDUCERS *******************************************************************

export const reducer: Reducer<SelectionsState> = (state: SelectionsState | undefined, action: KnownAction) => {
	if (state === undefined || (action as Action).type === 'REQUEST_LOGOUT')
		return initialState;
	switch (action.type) {
		case 'REQUEST_LOAD_AVAILABLE_MEDICAL_EXAMINER_LIST':
			return {
				...state,
				medicalExaminerListStatus: ListStatus2.Loading,
				medicalExaminerListMedicalPracticeId: action.medicalPracticeId,
				medicalExaminerList: undefined,
			};
		case 'RECEIVE_LOAD_AVAILABLE_MEDICAL_EXAMINER_LIST_SUCCESS':
			return {
				...state,
				medicalExaminerListStatus: ListStatus2.Loaded,
				medicalExaminerListMedicalPracticeId: action.medicalPracticeId,
				medicalExaminerList: action.medicalExaminerList,
			};
		case 'RECEIVE_LOAD_AVAILABLE_MEDICAL_EXAMINER_LIST_FAILURE':
			return {
				...state,
				medicalExaminerListStatus: ListStatus2.Failure,
				medicalExaminerListMedicalPracticeId: undefined,
				medicalExaminerList: undefined,
			};
		case 'REQUEST_LOAD_AVAILABLE_PRACTICE_LOCATION_LIST':
			return {
				...state,
				practiceLocationListStatus: ListStatus2.Loading,
				practiceLocationListMedicalPracticeId: action.medicalPracticeId,
				practiceLocationList: undefined,
			};
		case 'RECEIVE_LOAD_AVAILABLE_PRACTICE_LOCATION_LIST_SUCCESS':
			return {
				...state,
				practiceLocationListStatus: ListStatus2.Loaded,
				practiceLocationListMedicalPracticeId: action.medicalPracticeId,
				practiceLocationList: action.practiceLocationList,
			};
		case 'RECEIVE_LOAD_AVAILABLE_PRACTICE_LOCATION_LIST_FAILURE':
			return {
				...state,
				practiceLocationListStatus: ListStatus2.Failure,
				practiceLocationListMedicalPracticeId: undefined,
				practiceLocationList: undefined,
			};
		case 'REQUEST_LOAD_AVAILABLE_MEDICAL_PRACTICE_LIST':
			return {
				...state,
				medicalPracticeListStatus: MedicalPracticeListStatus.Loading,
				medicalPracticeList: undefined,
			};
		case 'RECEIVE_LOAD_AVAILABLE_MEDICAL_PRACTICE_LIST_SUCCESS':
			return {
				...state,
				medicalPracticeListStatus: MedicalPracticeListStatus.Loaded,
				medicalPracticeList: action.medicalPracticeList,
			};
		case 'RECEIVE_LOAD_AVAILABLE_MEDICAL_PRACTICE_LIST_FAILURE':
			return {
				...state,
				medicalPracticeListStatus: MedicalPracticeListStatus.Failure,
				medicalPracticeList: undefined,
			};
		case 'REQUEST_STANDARD_ENDORSEMENT_LIST':
			return {
				...state,
				standardEndorsementListStatus: ListStatus2.Loading,
				standardEndorsementList: undefined,
			};
		case 'RECEIVE_STANDARD_ENDORSEMENT_LIST_SUCCESS':
			return {
				...state,
				standardEndorsementListStatus: ListStatus2.Loaded,
				standardEndorsementList: action.standardEndorsementList,
			};
		case 'RECEIVE_STANDARD_ENDORSEMENT_LIST_FAILURE':
			return {
				...state,
				standardEndorsementListStatus: ListStatus2.Failure,
				standardEndorsementList: undefined,
			};
		case 'REQUEST_MEDICAL_DOCUMENT_TYPE_LIST':
			return {
				...state,
				medicalDocumentTypeListStatus: ListStatus2.Loading,
				medicalDocumentTypeList: [],
			};
		case 'RECEIVE_MEDICAL_DOCUMENT_TYPE_LIST_SUCCESS':
			return {
				...state,
				medicalDocumentTypeListStatus: ListStatus2.Loaded,
				medicalDocumentTypeList: action.medicalDocumentTypeList,
			};
		case 'RECEIVE_MEDICAL_DOCUMENT_TYPE_LIST_FAILURE':
			return {
				...state,
				medicalDocumentTypeListStatus: ListStatus2.Failure,
				medicalDocumentTypeList: [],
			};
		case 'RECEIVE_CHECKLIST_SELECTION_SUCCESS':
			return {
				...state,
				checklistStatus: ListStatus2.Loaded,
				checklistList: [
					...state.checklistList,
					action.checklist,
				],
			};
		case 'REQUEST_REGION_LIST':
			return {
				...state,
				regionsStatus: ListStatus2.Loading,
				regions: [],
			};
		case 'RECEIVE_REGION_LIST':
			return {
				...state,
				regionsStatus: !action.regions ? ListStatus2.Failure : ListStatus2.Loaded,
				regions: action.regions || [],
			};
		case 'REQUEST_VIDEO_CATEGORY_LIST':
			return {
				...state,
				videoCategoriesStatus: ListStatus2.Loading,
				videoCategories: [],
			};
		case 'RECEIVE_VIDEO_CATEGORY_LIST':
			return {
				...state,
				videoCategoriesStatus: !action.videoCategories ? ListStatus2.Failure : ListStatus2.Loaded,
				videoCategories: action.videoCategories || [],
			};
		case 'REQUEST_PRACTICE_EXAMINERS_SELECTIONS':
			return {
				...state,
				practiceExaminersStatus: ListStatus2.Loading,
				practiceExaminersState: action.state,
				practiceExaminersRegionId: action.regionId,
				practiceExaminers: [],
			};
		case 'RECEIVE_PRACTICE_EXAMINERS_SELECTIONS':
			return {
				...state,
				practiceExaminersStatus: !action.practiceExaminers ? ListStatus2.Failure : ListStatus2.Loaded,
				state: action.state!,
				regionId: action.regionId!,
				practiceExaminers: action.practiceExaminers || [],
			};
		case 'REQUEST_USER_METRICS_GROUPS_SELECTIONS':
			return {
				...state,
				userMetricGroupsStatus: ListStatus2.Loading,
				userMetricGroups: [],
			};
		case 'RECEIVE_USER_METRICS_GROUPS_SELECTIONS':
			return {
				...state,
				userMetricGroupsStatus: !action.userMetricGroups ? ListStatus2.Failure : ListStatus2.Loaded,
				userMetricGroups: action.userMetricGroups || [],
			};
		case 'REQUEST_FLYING_ORGANISATIONS':
			return {
				...state,
				flyingOrganisationsStatus: ListStatus2.Loading,
				flyingOrganisations: undefined,
			};
		case 'RECEIVE_FLYING_ORGANISATIONS':
			return {
				...state,
				flyingOrganisationsStatus: action.flyingOrganisations ? ListStatus2.Loaded : ListStatus2.Failure,
				flyingOrganisations: action.flyingOrganisations,
			};
		case 'REQUEST_FLYING_ORGANISATION_STAFF_SELECTIONS':
			return {
				...state,
				flyingOrganisationStaffStatus: ListStatus2.Loading,
				flyingOrganisationStaff_FlyingOrganisationId: action.flyingOrganisationId,
				flyingOrganisationStaff: undefined,
			};
		case 'RECEIVE_FLYING_ORGANISATION_STAFF_SELECTIONS':
			return {
				...state,
				flyingOrganisationStaffStatus: action.flyingOrganisationStaff ? ListStatus2.Loaded : ListStatus2.Failure,
				flyingOrganisationStaff_FlyingOrganisationId: action.flyingOrganisationId,
				flyingOrganisationStaff: action.flyingOrganisationStaff,
			};
		case 'REQUEST_PILOTS_SELECTIONS':
			return {
				...state,
				pilotsStatus: ListStatus2.Loading,
				pilots_FlyingOrganisationId: action.flyingOrganisationId,
				pilots: undefined,
			};
		case 'RECEIVE_PILOTS_SELECTIONS':
			return {
				...state,
				pilotsStatus: action.pilots ? ListStatus2.Loaded : ListStatus2.Failure,
				pilots_FlyingOrganisationId: action.flyingOrganisationId,
				pilots: action.pilots,
			};
		case 'REQUEST_AIRFIELDS_SELECTIONS':
			return {
				...state,
				airfieldsStatus: ListStatus2.Loading,
				airfields_RegionId: action.regionId,
				airfields: undefined,
			};
		case 'RECEIVE_AIRFIELDS_SELECTIONS':
			return {
				...state,
				airfieldsStatus: action.airfields ? ListStatus2.Loaded : ListStatus2.Failure,
				airfields_RegionId: action.regionId,
				airfields: action.airfields,
			};
		case 'REQUEST_DOCUMENT_TYPES':
			return {
				...state,
				documentTypesStatus: LoadStatus2.Loading,
				documentTypesFilter1: action.filter1,
				documentTypes: undefined,
			};
		case 'RECEIVE_DOCUMENT_TYPES':
			return {
				...state,
				documentTypesStatus: action.documentTypes ? LoadStatus2.Loaded : LoadStatus2.Failure,
				documentTypesFilter1: action.filter1,
				documentTypes: action.documentTypes,
			};
		case 'REQUEST_FORM_SECTIONS':
			return {
				...state,
				formSectionsStatus: LoadStatus2.Loading,
				formSectionsDocumentTypeId: action.documentTypeId,
				formSections: undefined,
			};
		case 'RECEIVE_FORM_SECTIONS':
			return {
				...state,
				formSectionsStatus: action.formSections ? LoadStatus2.Loaded : LoadStatus2.Failure,
				formSectionsDocumentTypeId: action.documentTypeId,
				formSections: action.formSections,
			};

		case 'REQUEST_LOAD_AVAILABLE_SPECIALIST_LIST':
			return {
				...state,
				specialistListStatus: ListStatus2.Loading,
				specialistListSpecialistPracticeId: action.specialistPracticeId,
				specialistList: undefined,
			} as SelectionsState;
		case 'RECEIVE_LOAD_AVAILABLE_SPECIALIST_LIST':
			return {
				...state,
				specialistListStatus: action.specialistList ? ListStatus2.Loaded : ListStatus2.Failure,
				specialistListSpecialistPracticeId: action.specialistPracticeId,
				specialistList: action.specialistList,
			} as SelectionsState;

		case 'REQUEST_LOAD_AVAILABLE_SPECIALIST_PRACTICE_LIST':
			return {
				...state,
				specialistPracticeListStatus: ListStatus2.Loading,
				specialistPracticeList: undefined,
			} as SelectionsState;
		case 'RECEIVE_LOAD_AVAILABLE_SPECIALIST_PRACTICE_LIST':
			return {
				...state,
				specialistPracticeListStatus: action.specialistPracticeList ? ListStatus2.Loaded : ListStatus2.Failure,
				specialistPracticeList: action.specialistPracticeList,
			} as SelectionsState;

		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		default: const exhaustiveCheck: never = action;
	}
	return state;
}
