import { Action, Reducer } from 'redux';
import { AppThunkAction } from '..';
import { ListState } from '../list/ListCommon';
import { getJson, handleWithAction } from '../myfetch';
import { LoadStatus2 } from '../StoreCommon';

// STATE **********************************************************************

export interface MonitorState {
	loggedInStatus?: LoadStatus2;	// undefined, Loading
	loggedIn?: LoggedIn[];

	recentActivity?: RecentActivityState & { upTo: Date; };
}

export interface LoggedIn {
	siteName: string;
	userName: string;
	name: string;
	lastUserActivity?: string;
	minutesAgo: number;
	recentCount: number;
	recentTimerCount: number;
	currentCount: number;
	errorCount: number;
	unauthorisedCount: number;
	ipAddress: string;
	activity: string;	// Values: error, logout, timer, unauthorised, user
}

export interface MetricsSnapshot {
	timestamp: number;
	allocatedMemory: number;
}

export interface RecentActivityState extends ListState<RecentActivity> { };

export interface RecentActivity {
	time: string;
	minutesAgo: number;
	siteName: string;
	ipAddress: string;
	raId?: string;
	user?: string;
	method: string;
	path: string;
	query?: string;
	statusCode: string;
}

export enum RecentActivityOrderBy {
	Time = 'Time',
	SiteName = 'SiteName',
	IpAddress = 'IpAddress',
	Path = 'Path',
	StatusCode = 'StatusCode',
}

export enum RecentActivityFilter1 {
	All = 'All',
	Normal = 'Normal',
	Errors = 'Errors',
	Logins = 'Logins',
	Anonymous = 'Anonymous',
}

// ACTIONS ********************************************************************

interface RequestLoggedInAction { type: 'REQUEST_LOGGED_IN'; };
interface ReceiveLoggedInAction { type: 'RECEIVE_LOGGED_IN'; loggedIn?: LoggedIn[]; };

type KnownAction =
	RequestLoggedInAction | ReceiveLoggedInAction
	;

// ACTION CREATORS ************************************************************

export const monitorActionCreators = {
	getLoggedIn: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
		getJson('api/monitors/loggedIn')
			.then(response => {
				dispatch({ type: 'RECEIVE_LOGGED_IN', loggedIn: response.loggedIn, });
			})
			.catch(handleWithAction('RECEIVE_LOGGED_IN'));
		dispatch({ type: 'REQUEST_LOGGED_IN', });
	},
};

// REDUCERS *******************************************************************

export const reducer: Reducer<MonitorState | null> = (state: MonitorState | undefined | null, incomingAction: Action) => {
	if (state === undefined || incomingAction.type === 'REQUEST_LOGOUT')
		return null;
	const action = incomingAction as KnownAction;
	switch (action.type) {
		case 'REQUEST_LOGGED_IN':
			return {
				...state,
				loggedInStatus: LoadStatus2.Loading,
			};
		case 'RECEIVE_LOGGED_IN':
			return {
				...state,
				loggedInStatus: undefined,
				loggedIn: action.loggedIn || state!.loggedIn,
			};
		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		default: const exhaustiveCheck: never = action;
	}
	return state;
};
