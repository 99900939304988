import * as React from 'react';
import { useSelector } from 'react-redux';
import * as Configuration from '../../Configuration';
import { Route } from 'react-router';
import { ApplicationState } from '../../store';
import { FeatureSetFlags } from '../../store/user/LoginStore';
import { SecondaryRole, UserRole } from '../../store/user/UserCommon';
import { Page } from '../app/Page';
import AllAppointmentsSummary from '../summaries/AllAppointmentsSummary';
import AllInvoicesSummary from '../summaries/AllInvoicesSummary';
import AllMedicalsSummary from '../summaries/AllMedicalsSummary';
import AllRemindersSummary from '../summaries/AllRemindersSummary';
import AmcManagementSummary from '../summaries/AmcManagementSummary';
import AnalyticalReportsSummary from '../summaries/AnalyticalReportsSummary';
import { DocumentLibrarySummary } from '../summaries/DocumentLibrarySummary';
import FlyingOrganisationStaffInfoSummary from '../summaries/FlyingOrganisationStaffInfoSummary';
import { MandatoryRequirementsSummary } from '../summaries/MandatoryRequirementsSummary';
import MedicalExaminerInfoSummary from '../summaries/MedicalExaminerInfoSummary';
import OrganisationPilotsSummary from '../summaries/OrganisationPilotsSummary';
import OrganisationSummary from '../summaries/OrganisationSummary';
import OverheadJoinSummary from '../summaries/OverheadJoinSummary';
import PilotBookingSummary from '../summaries/PilotBookingSummary';
import PilotInfoSummary from '../summaries/PilotInfoSummary';
import PilotMedicalSummary, { PilotMedicalSummary2 } from '../summaries/PilotMedicalSummary';
import PilotSummary from '../summaries/PilotSummary';
import PracticePilotsSummary from '../summaries/PracticePilotsSummary';
import MedicalPracticeSummary from '../summaries/MedicalPracticeSummary';
import RegulatoryAuthorityAgentInfoSummary from '../summaries/RegulatoryAuthorityAgentInfoSummary';
import RegulatoryAuthoritySubscriptionSummary from '../summaries/RegulatoryAuthoritySubscriptionSummary';
import ReportSummary from '../summaries/ReportSummary';
import { SpecialistInfoSummary } from '../summaries/SpecialistInfoSummary';
import { SpecialistPracticeSummary } from '../summaries/SpecialistPracticeSummary';
import SuspensionManagementSummary from '../summaries/SuspensionManagementSummary';
import UserMessagesSummary from '../summaries/UserMessagesSummary';
import { getDateText, getTimeText12Hm } from '../support/dateTimeSupport';
import { LayoutContainer } from '../support/Layouts';
import { UploadResultSummary } from '../summaries/UploadResultSummary';
import { AssociatedOrganisationsSummary } from '../summaries/AssociatedOrganisationsSummary';
import { UploadCodesSummary } from '../summaries/UploadCodesSummary';

export default () => {
	const login = useSelector(state => (state as ApplicationState).user.login);
	const expiresDateTime = new Date(login.loggedIn!.expires);
	const expiresDate = getDateText(expiresDateTime);
	const expiresTime = getTimeText12Hm(expiresDateTime);
	return (
		<Page wide defaultHelmet>
			<h1>Dashboard</h1>
			{login.loggedIn!.connectionName &&
				<h2>{login.loggedIn!.connectionName}</h2>
			}
			{login.loggedIn!.role === UserRole.FlyingOrganisationStaff &&
				<FlyingOrganisationStaffDashboard featureSet={login.loggedIn!.featureSet} hasConnection={!!login.loggedIn!.connectionId} />
			}
			{login.loggedIn!.role === UserRole.MedicalExaminer &&
				<MedicalExaminerDashboard featureSet={login.loggedIn!.featureSet} hasConnection={!!login.loggedIn!.connectionId} secondaryRole={login.loggedIn!.secondaryRole} />
			}
			{login.loggedIn!.role === UserRole.Nurse &&
				<NurseDashboard featureSet={login.loggedIn!.featureSet} hasConnection={!!login.loggedIn!.connectionId} secondaryRole={login.loggedIn!.secondaryRole} />
			}
			{login.loggedIn!.role === UserRole.Pilot &&
				<PilotDashboard featureSet={login.loggedIn!.featureSet} />
			}
			{login.loggedIn!.role === UserRole.RegulatoryAuthorityAgent &&
				<RegulatoryAuthorityAgentDashboard featureSet={login.loggedIn!.featureSet} />
			}
			{login.loggedIn!.role === UserRole.Specialist &&
				<SpecialistDashboard featureSet={login.loggedIn!.featureSet} hasConnection={!!login.loggedIn!.connectionId} />
			}
			{login.loggedIn!.role === UserRole.SpecialistStaff &&
				<SpecialistStaffDashboard featureSet={login.loggedIn!.featureSet} hasConnection={!!login.loggedIn!.connectionId} />
			}
			<p className='text-muted'>Your current session will expire at {expiresTime} {expiresDate}.</p>
		</Page>
	);
}


interface DashboardProps {
	featureSet: number;
	hasConnection?: boolean;
	secondaryRole?: string;
}


const FlyingOrganisationStaffDashboard = (props: DashboardProps) => (
	<LayoutContainer>
		<Route component={FlyingOrganisationStaffInfoSummary} />
		{(props.featureSet & FeatureSetFlags.User_Messages) !== 0 &&
			<UserMessagesSummary />
		}
		{props.hasConnection &&
			<Route component={OrganisationPilotsSummary} />
		}
		{Configuration.enableFeature.reminders &&
			<Route component={AllRemindersSummary} />
		}
		<Route component={ReportSummary} />
		{props.hasConnection &&
			<Route component={OrganisationSummary} />
		}
	</LayoutContainer>
);
// TODO @@@ Should flying organisation staff be allowed to have a document library?


const MedicalExaminerDashboard = (props: DashboardProps) => (
	<LayoutContainer>
		<Route component={MedicalExaminerInfoSummary} />
		{props.hasConnection &&
			<Route component={AllMedicalsSummary} />
		}
		{(props.featureSet & FeatureSetFlags.User_Messages) !== 0 &&
			<UserMessagesSummary />
		}
		{props.hasConnection && props.secondaryRole !== SecondaryRole.Locum &&
			<Route component={PracticePilotsSummary} />
		}
		{props.hasConnection &&
			<Route component={AllAppointmentsSummary} />
		}
		{Configuration.enableFeature.reminders &&
			<Route component={AllRemindersSummary} />
		}
		{(props.featureSet & FeatureSetFlags.Medical_Tests_Needed) !== 0 &&
			<Route component={MandatoryRequirementsSummary} />
		}
		<Route component={ReportSummary} />
		{Configuration.enableFeature.invoices && 
			(props.secondaryRole === SecondaryRole.Administrator || props.secondaryRole === SecondaryRole.Owner) && props.hasConnection &&
			<Route component={AllInvoicesSummary} />
		}
		{props.hasConnection &&
			<Route component={MedicalPracticeSummary} />
		}
	</LayoutContainer>
);


const NurseDashboard = (props: DashboardProps) => (
	<LayoutContainer>
		<Route component={MedicalExaminerInfoSummary} />
		{props.hasConnection &&
			<Route component={AllMedicalsSummary} />
		}
		{(props.featureSet & FeatureSetFlags.User_Messages) !== 0 &&
			<UserMessagesSummary />
		}
		{props.hasConnection &&
			<>
				<Route component={PracticePilotsSummary} />
				<Route component={AllAppointmentsSummary} />
			</>
		}
		{Configuration.enableFeature.reminders &&
			<Route component={AllRemindersSummary} />
		}
		{(props.featureSet & FeatureSetFlags.Medical_Tests_Needed) !== 0 &&
			<Route component={MandatoryRequirementsSummary} />
		}
		<Route component={ReportSummary} />
		{Configuration.enableFeature.invoices &&
			(props.secondaryRole === SecondaryRole.Administrator || props.secondaryRole === SecondaryRole.AdministratorNurse) &&
			props.hasConnection &&
			<Route component={AllInvoicesSummary} />
		}
		{props.hasConnection &&
			<Route component={MedicalPracticeSummary} />
		}
	</LayoutContainer>
);
// TODO @@@ Should flying organisation staff be allowed to have a document library?


const PilotDashboard = (props: DashboardProps) => (
	<LayoutContainer>
		<Route component={PilotInfoSummary} />
		<Route component={PilotSummary} />
		{Configuration.enableFeature.overheadJoin &&
			<OverheadJoinSummary />
		}
		{(props.featureSet & FeatureSetFlags.User_Messages) !== 0 &&
			<UserMessagesSummary />
		}
		{Configuration.enableFeature.reminders &&
			<Route component={AllRemindersSummary} />
		}
		{Configuration.enableFeature.medicals &&
			(props.featureSet & FeatureSetFlags.Select_Application_Type) !== 0 &&
			<PilotMedicalSummary2 />
		}
		{Configuration.enableFeature.medicals &&
			(props.featureSet & FeatureSetFlags.Select_Application_Type) === 0 &&
			<Route component={PilotMedicalSummary} />
		}
		{Configuration.enableFeature.bookings &&
			<Route component={PilotBookingSummary} />
		}
		{Configuration.enableFeature.medicals &&
			(props.featureSet & FeatureSetFlags.Medical_Tests_Needed) !== 0 &&
			<Route component={MandatoryRequirementsSummary} />
		}
		{Configuration.enableFeature.myMetrics &&
			<Route component={ReportSummary} />
		}
		<DocumentLibrarySummary />
	</LayoutContainer>
);


const RegulatoryAuthorityAgentDashboard = (props: DashboardProps) => (
	<LayoutContainer>
		<RegulatoryAuthorityAgentInfoSummary />
		{(props.featureSet & FeatureSetFlags.User_Messages) !== 0 &&
			<UserMessagesSummary />
		}
		<Route component={AllMedicalsSummary} />
		{Configuration.enableFeature.reminders &&
			<Route component={AllRemindersSummary} />
		}
		{(props.featureSet & FeatureSetFlags.Medical_Tests_Needed) !== 0 &&
			<Route component={MandatoryRequirementsSummary} />
		}
		<Route component={ReportSummary} />
		{false && 
			<Route component={AmcManagementSummary} />
		}
		{false && 
			<Route component={SuspensionManagementSummary} />
		}
		{false && 
			<Route component={AnalyticalReportsSummary} />
		}
		<RegulatoryAuthoritySubscriptionSummary />
	</LayoutContainer>
);
// TODO @@@ Should regulatory authority agents be allowed to have a document library?


const SpecialistDashboard = (props: DashboardProps) => (
	<LayoutContainer>
		<SpecialistInfoSummary />
		{(props.featureSet & FeatureSetFlags.User_Messages) !== 0 &&
			<UserMessagesSummary />
		}
		{(props.featureSet & FeatureSetFlags.Upload_Results) !== 0 &&
			<UploadResultSummary />
		}
		{Configuration.enableFeature.reminders &&
			<Route component={AllRemindersSummary} />
		}
		<Route component={ReportSummary} />
		{props.hasConnection && (
			<>
				<SpecialistPracticeSummary />
				<AssociatedOrganisationsSummary />
			</>
		)}
		<UploadCodesSummary />
	</LayoutContainer>
);


const SpecialistStaffDashboard = (props: DashboardProps) => (
	<LayoutContainer>
		<SpecialistInfoSummary />
		{(props.featureSet & FeatureSetFlags.User_Messages) !== 0 &&
			<UserMessagesSummary />
		}
		{(props.featureSet & FeatureSetFlags.Upload_Results) !== 0 &&
			<UploadResultSummary />
		}
		{Configuration.enableFeature.reminders &&
			<Route component={AllRemindersSummary} />
		}
		<Route component={ReportSummary} />
		{props.hasConnection && (
			<>
				<SpecialistPracticeSummary />
				<AssociatedOrganisationsSummary />
			</>
		)}
	</LayoutContainer>
);
// TODO @@@ Should specialist staff be allowed to have a document library?
