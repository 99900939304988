import { Action, Reducer } from 'redux';
import { AppThunkAction } from '..';
import { store } from '../..';
import { documentLibraryActionInvokers } from '../library/DocumentLibraryStore';
import { deleteJson, getJson, handleWithActionAndErrorToast, handleWithErrorToast, postJson, putJson } from '../myfetch';
import { dispatchErrorToast, dispatchToast } from '../notification/NotificationStore';
import { listOfOrganisationPilotsActionCreators } from '../organisation/OrganisationStore';
import { listOfPracticePilotsActionCreators } from '../practice/PracticeStore';
import { LoadStatus2 } from '../StoreCommon';
import { mandatoryRequirementsActionInvokers } from './MandatoryRequirementsStore';
import { Medical, MedicalDocument, MedicalForm, MedicalPdfStatus, MedicalReminderCategory, MedicalStatus, RegulatoryAuthorityReminderCategory } from './MedicalCommon';
import { medicalListActionInvokers } from './MedicalListStore';

// From SkyCert v1

// Architecture: v2

// STATE **********************************************************************

export interface SelectedMedicalState {
	selectedMedicalLoadStatus: LoadStatus2;
	selectedMedical?: Medical;
	isUploading?: boolean;
	isSending?: boolean;
}

// ACTIONS ********************************************************************

interface RequestLoadMedicalAction { type: 'REQUEST_LOAD_MEDICAL'; medicalId: string; }
interface ReceiveLoadMedicalSuccessAction { type: 'RECEIVE_LOAD_MEDICAL_SUCCESS'; medical: Medical; }
interface ReceiveLoadMedicalFailureAction { type: 'RECEIVE_LOAD_MEDICAL_FAILURE'; }

interface RequestCreatePdfsAction { type: 'REQUEST_CREATE_PDFS'; }

interface ReceiveReloadMedicalAction { type: 'RECEIVE_RELOAD_MEDICAL'; medical?: Medical; }

interface ReceiveDeleteFilledFormSuccessAction { type: 'RECEIVE_DELETE_FILLED_FORM_SUCCESS', medicalForms: MedicalForm[], }
interface ReceiveRestoreFilledFormSuccessAction { type: 'RECEIVE_RESTORE_FILLED_FORM_SUCCESS', medicalForms: MedicalForm[], }

interface RequestUploadMedicalDocumentAction { type: 'REQUEST_UPLOAD_MEDICAL_DOCUMENT'; }
interface ReceiveUploadMedicalDocumentSuccessAction { type: 'RECEIVE_UPLOAD_MEDICAL_DOCUMENT_SUCCESS'; medicalDocuments: MedicalDocument[]; }
interface ReceiveUploadMedicalDocumentFailureAction { type: 'RECEIVE_UPLOAD_MEDICAL_DOCUMENT_FAILURE'; }

interface ReceiveDeleteMedicalDocumentSuccessAction { type: 'RECEIVE_DELETE_MEDICAL_DOCUMENT_SUCCESS'; medicalDocuments: MedicalDocument[]; }
interface ReceiveRestoreMedicalDocumentSuccessAction { type: 'RECEIVE_RESTORE_MEDICAL_DOCUMENT_SUCCESS'; medicalDocuments: MedicalDocument[]; }
interface ReceiveRotateMedicalDocumentSuccessAction { type: 'RECEIVE_ROTATE_MEDICAL_DOCUMENT_SUCCESS'; medicalDocuments: MedicalDocument[]; }

interface InvalidateSelectedMedicalAction { type: 'INVALIDATE_SELECTED_MEDICAL', }

interface SetSelectedMedicalFieldsAction { type: 'SET_SELECTED_MEDICAL_FIELDS'; fields: any; }

interface RequestSendPdfAction { type: 'REQUEST_SEND_PDF'; }
interface ReceiveSendPdfAction { type: 'RECEIVE_SEND_PDF'; }

type KnownAction = RequestLoadMedicalAction | ReceiveLoadMedicalSuccessAction | ReceiveLoadMedicalFailureAction
	| RequestCreatePdfsAction 
	| ReceiveReloadMedicalAction
	| ReceiveDeleteFilledFormSuccessAction | ReceiveRestoreFilledFormSuccessAction
	| RequestUploadMedicalDocumentAction | ReceiveUploadMedicalDocumentSuccessAction | ReceiveUploadMedicalDocumentFailureAction
	| ReceiveDeleteMedicalDocumentSuccessAction | ReceiveRestoreMedicalDocumentSuccessAction
	| ReceiveRotateMedicalDocumentSuccessAction
	| InvalidateSelectedMedicalAction
	| SetSelectedMedicalFieldsAction
	| RequestSendPdfAction | ReceiveSendPdfAction
	;

// ACTION INVOKERS ////////////////////////////////////////////////////////////

export const selectedMedicalActionInvokers = {
	requestLoadMedical: async (medicalId: number) => {
		// TODO @@@ Check if it is already loaded
		store.dispatch({ type: 'REQUEST_LOAD_MEDICAL', } as KnownAction);
		try {
			const response = await getJson(`api/medicals/${medicalId}`);
			store.dispatch({ type: 'RECEIVE_LOAD_MEDICAL_SUCCESS', medical: response, } as KnownAction);
		} catch (error) {
			store.dispatch({ type: 'RECEIVE_LOAD_MEDICAL_FAILURE', } as KnownAction);
			dispatchErrorToast(true, 'Medical', error);
		}
	},

	updateChangedMedical: (medicalId: number) => {
		const state = store.getState().medical.selectedMedical;
		if (state.selectedMedicalLoadStatus === LoadStatus2.Loaded && state.selectedMedical?.medicalId === medicalId) {
			selectedMedicalActionInvokers.requestReloadMedical(medicalId);
		}
	},

	updateFromChangedReminder: (pilotUserId: string) => {
		const state = store.getState().medical.selectedMedical;
		if (state.selectedMedicalLoadStatus === LoadStatus2.Loaded && state.selectedMedical?.pilotUserId === pilotUserId) {
			selectedMedicalActionInvokers.requestReloadMedical(state.selectedMedical!.medicalId);
		}
	},

	requestReloadMedical: async (medicalId: number) => {
		try {
			const response = await getJson(`api/medicals/${medicalId}`);
			store.dispatch({ type: 'RECEIVE_RELOAD_MEDICAL', medical: response } as KnownAction);
		} catch (error) {
			store.dispatch({ type: 'RECEIVE_RELOAD_MEDICAL', });
		}
	},

	requestSetBookingStar: async (pilotUserId: string, isBookingStar: boolean) => {
		const selectedMedical = store.getState().medical.selectedMedical.selectedMedical!;
		const oldIsBookingStar = !!selectedMedical.isBookingStar;
		store.dispatch({ type: 'SET_SELECTED_MEDICAL_FIELDS', fields: { isBookingStar, }, } as KnownAction);
		try {
			const response = await putJson(`api/users/${pilotUserId}/pilot/isBookingStar`, { value: isBookingStar, });
			store.dispatch({ type: 'SET_SELECTED_MEDICAL_FIELDS', fields: { isBookingStar: response.isBookingStar, }, } as KnownAction);
		} catch (error) {
			store.dispatch({ type: 'SET_SELECTED_MEDICAL_FIELDS', fields: { isBookingStar: oldIsBookingStar, }, } as KnownAction);
			dispatchErrorToast(true, 'Booking Star', error);
		}
	},

	requestSetExaminer: async (medicalExaminerUserId: string) => {
		const selectedMedical = store.getState().medical.selectedMedical.selectedMedical!;
		const oldMedicalExaminerUserId = selectedMedical.medicalExaminerUserId;
		const oldUserIsExaminer = selectedMedical.userIsExaminer;
		store.dispatch({ type: 'SET_SELECTED_MEDICAL_FIELDS', fields: { medicalExaminerUserId, userIsExaminer: false, }, } as KnownAction);
		try {
			const response = await putJson(`api/medicals/${selectedMedical.medicalId}/examiner`, { value: medicalExaminerUserId, });
			store.dispatch({ type: 'RECEIVE_RELOAD_MEDICAL', medical: response, } as KnownAction);
			medicalListActionInvokers.invalidateCurrentMedicalsPage();
		} catch (error) {
			store.dispatch({ type: 'SET_SELECTED_MEDICAL_FIELDS', fields: { medicalExaminerUserId: oldMedicalExaminerUserId, userIsExaminer: oldUserIsExaminer, }, } as KnownAction);
			dispatchErrorToast(true, 'Medical Examiner', error);
		}
	},

	requestSetMedicalChecklist: async (required: number, done: number, seen: number) => {
		const selectedMedical = store.getState().medical.selectedMedical.selectedMedical!;
		const oldChecklistRequired = selectedMedical.checklistRequired;
		const oldChecklistDone = selectedMedical.checklistDone;
		const oldChecklistSeen = selectedMedical.checklistSeen;
		store.dispatch({ type: 'SET_SELECTED_MEDICAL_FIELDS', fields: { checklistRequired: required, checklistDone: done, checklistSeen: seen, }, } as KnownAction);
		try {
			const response = await putJson(`api/medicals/${selectedMedical.medicalId}/medicalchecklist`, { required, done, box3: seen, })
			store.dispatch({ type: 'SET_SELECTED_MEDICAL_FIELDS', fields: { checklistRequired: response.checklist.required, checklistDone: response.checklist.done, checklistSeen: response.checklist.box3, }, } as KnownAction);
		} catch (error) {
			store.dispatch({ type: 'SET_SELECTED_MEDICAL_FIELDS', fields: { checklistRequired: oldChecklistRequired, checklistDone: oldChecklistDone, checklistSeen: oldChecklistSeen, }, } as KnownAction);
			dispatchErrorToast(true, 'Medical Checklist', error);
		}
	},

	requestSetMedicalReminderCategories: async (medicalReminderCategories: MedicalReminderCategory) => {
		const selectedMedical = store.getState().medical.selectedMedical.selectedMedical!;
		const oldMedicalReminderCategories = selectedMedical.medicalReminderCategories;
		store.dispatch({ type: 'SET_SELECTED_MEDICAL_FIELDS', fields: { medicalReminderCategories, }, } as KnownAction);
		try {
			const response = await putJson(`api/medicals/${selectedMedical.medicalId}/medicalremindercategories`, { value: medicalReminderCategories, });
			store.dispatch({ type: 'SET_SELECTED_MEDICAL_FIELDS', fields: { medicalReminderCategories: response.medicalReminderCategories, }, } as KnownAction);
			// TODO @@@ invalidate current medicals page?
		} catch (error) {
			store.dispatch({ type: 'SET_SELECTED_MEDICAL_FIELDS', fields: { medicalReminderCategories: oldMedicalReminderCategories, }, } as KnownAction);
			dispatchErrorToast(true, 'Medical Reminder Categories', error);
		}
	},

	requestSetAuthorityReminderCategories: async (authorityReminderCategories: RegulatoryAuthorityReminderCategory) => {
		const selectedMedical = store.getState().medical.selectedMedical.selectedMedical!;
		const oldAuthorityReminderCategories = selectedMedical.authorityReminderCategories;
		store.dispatch({ type: 'SET_SELECTED_MEDICAL_FIELDS', fields: { authorityReminderCategories, }, } as KnownAction);
		try {
			const response = await putJson(`api/medicals/${selectedMedical.medicalId}/authorityremindercategories`, { authorityReminderCategories, });
			store.dispatch({ type: 'SET_SELECTED_MEDICAL_FIELDS', fields: { authorityReminderCategories: response.authorityReminderCategories, }, } as KnownAction);
			// TODO @@@ invalidate current medicals page?
		} catch (error) {
			store.dispatch({ type: 'SET_SELECTED_MEDICAL_FIELDS', fields: { authorityReminderCategories: oldAuthorityReminderCategories, }, } as KnownAction);
			dispatchErrorToast(true, 'Authority Reminder Categories', error);
		}
	},

	requestSetRegulatoryAuthorityId: async (pilotUserId: string, regulatoryAuthorityId: string) => {
		try {
			const response = await putJson(`api/users/${pilotUserId}/raid`, { value: regulatoryAuthorityId, });
			dispatchToast(true, 'success', 'Pilot ID', "The pilot's ID has been assigned.", 'The pilot and their medical examiner have been notified.');
			store.dispatch({ type: 'SET_SELECTED_MEDICAL_FIELDS', fields: { pilotRegulatoryAuthorityId: response.regulatoryAuthorityId, }, } as KnownAction);
		} catch (error) {
			dispatchErrorToast(true, 'Pilot ID', error);
		}
	},

	requestCreate059Reminders: async (medicalId: number) => {
		// TODO @@@ Do I need to spin the propellor here?  And stop it spinning?
		try {
			await postJson(`api/medicals/${medicalId}/reminders`, {});
			dispatchToast(true, 'success', 'Reminders', 'The reminders have been created.');
		} catch (error) {
			dispatchErrorToast(true, "Reminders", error);
		}
	},

	requestCreatePdfs: async (medicalId: number, pdfBuildMode: string) => {
		store.dispatch({ type: 'REQUEST_CREATE_PDFS', } as KnownAction);
		try {
			const response = await postJson(`api/medicals/${medicalId}/pdfs/built`, { value: pdfBuildMode, });
			medicalListActionInvokers.invalidateCurrentMedicalsPage();
			dispatchToast(true, 'success', 'Medical PDFs', response.message);
		} catch (error) {
			dispatchErrorToast(true, 'Medical PDFs', error);
			// The next line is primarily to ensure that the propellor stops spinning.
			await selectedMedicalActionInvokers.requestReloadMedical(medicalId);
		}
	},

	setMandatoryRequirementStatus: async (mandatoryRequirementId: number, statusName: string, status: string) => {
		try {
			await putJson(`/api/mandatoryrequirements/${mandatoryRequirementId}/${statusName}`, { value: status, });
			mandatoryRequirementsActionInvokers.invalidateMandatoryRequirementsList();
		} catch (error) {
			dispatchErrorToast(true, 'Mandatory Requirement', error);
		}
	},

	clearMandatoryRequirementStatus: async (mandatoryRequirementId: number) => {
		try {
			await deleteJson(`/api/mandatoryrequirements/${mandatoryRequirementId}`);
			mandatoryRequirementsActionInvokers.invalidateMandatoryRequirementsList();
		} catch (error) {
			dispatchErrorToast(true, 'Mandatory Requirement', error);
		}
	},

	setComments: async (mandatoryRequirementId: number, commentsName: string, comments?: string) => {
		try {
			await putJson(`/api/mandatoryrequirements/${mandatoryRequirementId}/${commentsName}`, { value: comments, });
			mandatoryRequirementsActionInvokers.invalidateMandatoryRequirementsList();
			return true;
		} catch (error) {
			dispatchErrorToast(true, 'Mandatory Requirement', error);
			return false;
		}
	},
};

// ACTION CREATORS ************************************************************

export const selectedMedicalActionCreators = {
	requestUnsignMedicalApplication: (medicalId: number): AppThunkAction<Action> => (dispatch, getState) => {
		putJson(`api/medicals/${medicalId}/application/status`, { value: 'Editing', })
			.then(response => {
				medicalListActionInvokers.invalidateCurrentMedicalsPage();
				dispatch({ type: 'RECEIVE_RELOAD_MEDICAL', medical: response, } as KnownAction);
			})
			.catch(handleWithErrorToast('Medical Application'));
	},

	requestSetMedicalStatus: (medicalId: number, medicalStatus: MedicalStatus): AppThunkAction<Action> => (dispatch, getState) => {
		putJson(`api/medicals/${medicalId}/status`, { value: medicalStatus, })
			.then(response => {
				medicalListActionInvokers.invalidateCurrentMedicalsPage();
				dispatch(listOfPracticePilotsActionCreators.invalidateCurrentParentPilotsPage());
				dispatch(listOfOrganisationPilotsActionCreators.invalidateCurrentParentPilotsPage());
				dispatch({ type: 'RECEIVE_RELOAD_MEDICAL', medical: response, } as KnownAction);
			})
			.catch(handleWithErrorToast('Medical Status'));
	},

	// The pdfBuildMode is required for any old medicals that were built before this was coded into the alternative name of built medical documents.
	requestSendToRegulatoryAuthority: (medicalId: number, pdfBuildMode: string): AppThunkAction<Action> => (dispatch, getState) => {
		postJson(`api/medicals/${medicalId}/pdfs/sent`, { value: pdfBuildMode, })
			.then(response => {
				dispatch({ type: 'RECEIVE_SEND_PDF', } as KnownAction);
				medicalListActionInvokers.invalidateCurrentMedicalsPage();
				dispatchToast(true, 'success', 'Send PDF', response.message);
			})
			.catch(handleWithActionAndErrorToast('Send PDF', 'RECEIVE_SEND_PDF'));
		dispatch({ type: 'REQUEST_SEND_PDF', } as KnownAction);
	},

	requestUploadMedicalDocument: (medicalId: number, documentTypeId: number, alternativeName: string | undefined, files: FileList, password?: string): AppThunkAction<Action> => (dispatch, getState) => {
		let formData = new FormData();
		formData.append('details', JSON.stringify({ documentTypeId, alternativeName, password, }));
		var { document, support, } = SelectedMedicalSupport.getFiles(files);
		if (!document) {
			dispatchToast(true, 'danger', 'Medical Document', 'Please select one and only one document to upload.');
			return;
		}
		formData.append('document', document);
		if (support)
			formData.append('support', support);
		postJson(`api/medicals/${medicalId}/documents`, formData)
			.then(response => {
				dispatch({ type: 'RECEIVE_UPLOAD_MEDICAL_DOCUMENT_SUCCESS', medicalDocuments: response.medicalDocuments, } as KnownAction);
				dispatchToast(true, 'success', 'Medical Document', response.message);
				documentLibraryActionInvokers.invalidateDocumentLibrary();
			})
			.catch(handleWithActionAndErrorToast('Medical Document', 'RECEIVE_UPLOAD_MEDICAL_DOCUMENT_FAILURE'));
		dispatch({ type: 'REQUEST_UPLOAD_MEDICAL_DOCUMENT', } as KnownAction);
	},

	requestDeleteMedicalDocument: (medicalId: number, medicalDocumentId: number, reason: string): AppThunkAction<Action> => (dispatch, getState) => {
		deleteJson(`api/medicals/${medicalId}/documents/${medicalDocumentId}?reason=${reason}`)
			.then(response => {
				dispatch({ type: 'RECEIVE_DELETE_MEDICAL_DOCUMENT_SUCCESS', medicalDocuments: response.medicalDocuments, } as KnownAction);
				dispatchToast(true, 'success', 'Medical Document', response.message, 'You can recover it by ticking the "Show all" box.');
				documentLibraryActionInvokers.invalidateDocumentLibrary();
			})
			.catch(handleWithErrorToast('Medical Document'));
	},

	requestRestoreMedicalDocument: (medicalId: number, medicalDocumentId: number): AppThunkAction<Action> => (dispatch, getState) => {
		postJson(`api/medicals/${medicalId}/documents/${medicalDocumentId}/isActive`, { })
			.then(response => {
				dispatch({ type: 'RECEIVE_RESTORE_MEDICAL_DOCUMENT_SUCCESS', medicalDocuments: response.medicalDocuments, } as KnownAction);
				dispatchToast(true, 'success', 'Medical Document', response.message);
				documentLibraryActionInvokers.invalidateDocumentLibrary();
			})
			.catch(handleWithErrorToast('Medical Document'));
	},

	requestRotateMedicalDocument: (medicalId: number, medicalDocumentId: number, rotationDegrees: number): AppThunkAction<Action> => (dispatch, getState) => {
		putJson(`api/medicals/${medicalId}/documents/${medicalDocumentId}/rotation`, { value: rotationDegrees, })
			.then(response => {
				dispatch({ type: 'RECEIVE_ROTATE_MEDICAL_DOCUMENT_SUCCESS', medicalDocuments: response.medicalDocuments, } as KnownAction);
				dispatchToast(true, 'success', 'Medical Document', response.message);
			})
			.catch(handleWithErrorToast('Medical Document'))
	},

	requestEmailMedicalDocument: (medicalId: number, medicalDocumentId: number, emailTo: string, ccAuthority: boolean): AppThunkAction<Action> => (dispatch, getState) => {
		postJson(`api/medicals/${medicalId}/documents/${medicalDocumentId}/sent`, { emailTo, ccAuthority, })
			.then(response => {
				dispatchToast(true, 'success', 'Medical Document', response.message);
			})
			.catch(handleWithErrorToast('Medical Document'));
	},

	requestLinkMedicalForm: (filledFormId: number | string): AppThunkAction<Action> => (dispatch, getState) => {
		postJson(`api/filledforms/${filledFormId}/links`, {})
			.then(response => {
				dispatchToast(true, 'success', 'Medical Form', response.message);
				documentLibraryActionInvokers.invalidateDocumentLibrary();
			})
			.catch(handleWithErrorToast('Medical Form'));
	},

	requestLinkMedicalDocument: (medicalDocumentId: number | string, userId: string): AppThunkAction<Action> => (dispatch, getState) => {
		postJson(`api/users/${userId}/documents/${medicalDocumentId}/links`, {})
			.then(response => {
				dispatchToast(true, 'success', 'Medical Document', response.message);
				documentLibraryActionInvokers.invalidateDocumentLibrary();
			})
			.catch(handleWithErrorToast('Medical Document'));
	},

	requestDeleteFilledForm: (medicalId: number, filledFormId: number, reason: string): AppThunkAction<Action> => (dispatch, getState) => {
		deleteJson(`api/filledforms/medicals/${medicalId}/${filledFormId}?reason=${reason}`)
			.then(response => {
				dispatch({ type: 'RECEIVE_DELETE_FILLED_FORM_SUCCESS', medicalForms: response.medicalForms, } as KnownAction);
				dispatchToast(true, 'success', 'Medical Form', response.message);
				documentLibraryActionInvokers.invalidateDocumentLibrary();
			})
			.catch(handleWithErrorToast('Medical Form'));
	},

	requestRestoreFilledForm: (medicalId: number, filledFormId: number): AppThunkAction<Action> => (dispatch, getState) => {
		postJson(`api/filledforms/medicals/${medicalId}/${filledFormId}/restore`, {})
			.then(response => {
				dispatch({ type: 'RECEIVE_RESTORE_FILLED_FORM_SUCCESS', medicalForms: response.medicalForms, } as KnownAction);
				dispatchToast(true, 'success', 'Medical Form', response.message);
				documentLibraryActionInvokers.invalidateDocumentLibrary();
			})
			.catch(handleWithErrorToast('Medical Form'));
	},

	requestCreatePdfOfForm: (medicalId: number, filledFormId: number): AppThunkAction<Action> => (dispatch, getState) => {
		postJson(`api/medicals/${medicalId}/forms/${filledFormId}/built`, null)
			.then(response => {
				dispatchToast(true, 'success', 'Medical Form', response.message);
				documentLibraryActionInvokers.invalidateDocumentLibrary();
			})
			.catch(handleWithErrorToast('Medical Form'));
		dispatch({ type: 'REQUEST_CREATE_PDFS', } as KnownAction);
	},
};


// SUPPORT ********************************************************************

const SelectedMedicalSupport = {
	getFiles: (files: FileList) => {
		if (files.length === 1)
			return { document: files[0], support: undefined, };
		else if (files.length !== 2)
			return {};

		// This code makes the assumption that multiple files involve a .csv file
		var document: File | undefined = undefined;
		var support: File | undefined = undefined;
		for (let iFile = 0; iFile < files.length; iFile++) {
			const file = files[iFile];
			const isCsv = file.name.match(/.csv/i);
			if (isCsv) {
				if (document)
					return {};
				document = file;
			}
			else {
				if (support)
					return {};
				support = file;
			}
		}
		return { document, support, };
	},
}


// INITIAL STATE **************************************************************

export const initialState: SelectedMedicalState = {
	selectedMedicalLoadStatus: LoadStatus2.Idle,
}


// REDUCERS *******************************************************************

export const reducer: Reducer<SelectedMedicalState> = (state: SelectedMedicalState | undefined, action: KnownAction) => {
	if (!state)
		return initialState;
	switch (action.type) {
		case 'REQUEST_LOAD_MEDICAL':
			return {
				selectedMedicalLoadStatus: LoadStatus2.Loading,
				selectedMedical: undefined,
			};
		case 'RECEIVE_LOAD_MEDICAL_SUCCESS':
			return {
				...state,
				selectedMedicalLoadStatus: LoadStatus2.Loaded,
				selectedMedical: action.medical,
			};
		case 'RECEIVE_LOAD_MEDICAL_FAILURE':
			return {
				...state,
				selectedMedicalLoadStatus: LoadStatus2.Failure,
				selectedMedical: undefined,
			};
		case 'REQUEST_CREATE_PDFS':
			return {
				...state,
				selectedMedical: {
					...state.selectedMedical!,
					medicalPdfStatus: MedicalPdfStatus.Building,
				}
			};
		case 'RECEIVE_RELOAD_MEDICAL':
			return {
				...state,
				selectedMedical: action.medical || state.selectedMedical,
			};
		case 'RECEIVE_DELETE_FILLED_FORM_SUCCESS':
		case 'RECEIVE_RESTORE_FILLED_FORM_SUCCESS':
			return {
				...state,
				selectedMedical: {
					...state.selectedMedical!,
					medicalForms: action.medicalForms,
				},
			};
		case 'REQUEST_UPLOAD_MEDICAL_DOCUMENT':
		case 'RECEIVE_UPLOAD_MEDICAL_DOCUMENT_FAILURE':
			return {
				...state,
				isUploading: action.type === 'REQUEST_UPLOAD_MEDICAL_DOCUMENT',
			};
		case 'RECEIVE_UPLOAD_MEDICAL_DOCUMENT_SUCCESS':
			return {
				...state,
				selectedMedical: {
					...state.selectedMedical!,
					medicalDocuments: action.medicalDocuments,
				},
				isUploading: false,
			};
		case 'RECEIVE_DELETE_MEDICAL_DOCUMENT_SUCCESS':
		case 'RECEIVE_RESTORE_MEDICAL_DOCUMENT_SUCCESS':
		case 'RECEIVE_ROTATE_MEDICAL_DOCUMENT_SUCCESS':
			return {
				...state,
				selectedMedical: {
					...state.selectedMedical!,
					medicalDocuments: action.medicalDocuments,
				},
			};
		case 'INVALIDATE_SELECTED_MEDICAL':
			return initialState;
		case 'SET_SELECTED_MEDICAL_FIELDS':
			return {
				...state,
				selectedMedical: {
					...state.selectedMedical!,
					...action.fields,
				},
			};
		case 'REQUEST_SEND_PDF':
		case 'RECEIVE_SEND_PDF':
			return {
				...state,
				isSending: action.type === 'REQUEST_SEND_PDF',
			};
		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		default: const exhaustiveCheck: never = action;
	}
	return state;
}
