import { Action, Reducer } from 'redux';
import { AppThunkAction } from '..';
import { getDateText } from '../../components/support/dateTimeSupport';
import { deleteJson, getJson, handleWithActionAndErrorToast, postJson } from '../myfetch';
import { dispatchErrorToast } from '../notification/NotificationStore';
import { LoadStatus2 } from '../StoreCommon';

// From SkyCert v1

// STATE **********************************************************************

export interface SelectedUserMeasuresState {
	status: LoadStatus2;
	userId: string;
	metricGroupName: string;
	userMetrics?: UserMetric[];
	userMeasures?: UserMeasure[];
	userDisplay?: string;
	isSavingEditingUserMeasures: boolean;
	editingUserMeasures: EditingUserMeasures;
}

export interface UserMetric {
	name: string;
	type: string;
	sequence: number;
	graph: number;
	yAxis: number;
	yLabel?: string;
	mean?: number;
	reference?: number;
	notes?: string;
}

export interface UserMeasure {
	isUserEntered: boolean;
	date: number;
	values: (number | undefined)[];
}

export interface EditingUserMeasures {
	date?: string;
	values: (number | undefined)[];
}

// ACTIONS ********************************************************************

interface RequestGetUserMeasuresAction { type: 'REQUEST_GET_USER_MEASURES'; userId: string; metricGroupName: string; }
interface ReceiveGetUserMeasuresAction { type: 'RECEIVE_GET_USER_MEASURES'; userId: string; metricGroupName: string; userMetrics?: UserMetric[]; userMeasures?: UserMeasure[]; userDisplay?: string; }

interface SelectUserMeasuresAction { type: 'SELECT_USER_MEASURES'; date: number; }
interface UpdateUserMeasureFieldAction { type: 'UPDATE_USER_MEASURE_FIELD'; name: string; value: any; }

interface RequestSaveUserMeasuresAction { type: 'REQUEST_SAVE_USER_MEASURES'; }
interface ReceiveSaveUserMeasuresAction { type: 'RECEIVE_SAVE_USER_MEASURES'; userMeasures?: UserMeasure[]; }

interface RequestDeleteUserMeasuresAction { type: 'REQUEST_DELETE_USER_MEASURES'; }
interface ReceiveDeleteUserMeasuresAction { type: 'RECEIVE_DELETE_USER_MEASURES'; userMeasures?: UserMeasure[]; }

type KnownAction = RequestGetUserMeasuresAction | ReceiveGetUserMeasuresAction
	| SelectUserMeasuresAction | UpdateUserMeasureFieldAction
	| RequestSaveUserMeasuresAction | ReceiveSaveUserMeasuresAction
	| RequestDeleteUserMeasuresAction | ReceiveDeleteUserMeasuresAction
	;

// ACTION CREATORS ************************************************************

export const selectedUserMeasuresActionCreators = {
	getUserMeasures: (userId: string, metricGroupName: string): AppThunkAction<Action> => (dispatch, getState) => {
		getJson(`api/reports/usermeasures?userId=${userId}&metric=${metricGroupName}`)
			.then(response => {
				dispatch({ type: 'RECEIVE_GET_USER_MEASURES', userId, metricGroupName, userMetrics: response.userMetrics, userMeasures: response.userMeasures, userDisplay: response.userDisplay, } as KnownAction);
			})
			.catch((error: Error) => {
				dispatch({ type: 'RECEIVE_GET_USER_MEASURES', userId, metricGroupName, } as KnownAction);
				dispatchErrorToast(true, 'User Measures', error);
			});
		dispatch({ type: 'REQUEST_GET_USER_MEASURES', userId, metricGroupName, } as KnownAction);
	},

	selectUserMeasures: (date: number) => ({ type: 'SELECT_USER_MEASURES', date, } as KnownAction),
	updateUserMeasureField: (name: string, value: any) => ({ type: 'UPDATE_USER_MEASURE_FIELD', name, value, } as KnownAction),

	saveUserMeasures: (userId: string, metricGroupName: string, date: string, values: (number | undefined)[]): AppThunkAction<Action> => (dispatch, getState) => {
		postJson(`api/reports/usermeasures?userId=${userId}&metric=${metricGroupName}`, { date, values, })
			.then(response => {
				dispatch({ type: 'RECEIVE_SAVE_USER_MEASURES', userMeasures: response.userMeasures, } as KnownAction);
			})
			.catch(handleWithActionAndErrorToast('User Measures', 'RECEIVE_SAVE_USER_MEASURES'));
		dispatch({ type: 'REQUEST_SAVE_USER_MEASURES', } as KnownAction);
	},

	deleteUserMeasures: (userId: string, metricGroupName: string, date: string): AppThunkAction<Action> => (dispatch, getState) => {
		deleteJson(`api/reports/usermeasures?userId=${userId}&metric=${metricGroupName}&date=${date}`)
			.then(response => {
				dispatch({ type: 'RECEIVE_DELETE_USER_MEASURES', userMeasures: response.userMeasures, } as KnownAction);
			})
			.catch(handleWithActionAndErrorToast('User Measures', 'RECEIVE_DELETE_USER_MEASURES'));
		dispatch({ type: 'REQUEST_DELETE_USER_MEASURES', } as KnownAction);
	},
};

// INITIAL STATE **************************************************************

//const initialEditState: UserMeasure = {
//	isUserEntered: true,
//	values: [],
//}

// REDUCERS *******************************************************************

export const reducer: Reducer<SelectedUserMeasuresState | null> = (state: SelectedUserMeasuresState | null | undefined, action: KnownAction) => {
	if (state === undefined)
		return null;
	state = state!;	// @@@ I hope this works
	switch (action.type) {
		case 'REQUEST_GET_USER_MEASURES':
			return {
				status: LoadStatus2.Loading,
				userId: action.userId,
				metricGroupName: action.metricGroupName,
				userDisplay: state && action.userId === state.userId ? state.userDisplay : undefined,
				isSavingEditingUserMeasures: false,
				editingUserMeasures: {
					isUserEntered: true,
					values: [],
				},
			};
		case 'RECEIVE_GET_USER_MEASURES':
			let values: (number | undefined)[] = [];
			if (action.userMetrics)
				while (values.length < action.userMetrics.length)
					values.push(undefined);
			return {
				status: !action.userMeasures ? LoadStatus2.Failure : LoadStatus2.Loaded,
				userId: action.userId,
				metricGroupName: action.metricGroupName,
				userMetrics: action.userMetrics,
				userMeasures: action.userMeasures,
				userDisplay: action.userDisplay,
				isSavingEditingUserMeasures: false,
				editingUserMeasures: {
					isUserEntered: true,
					values,
				},
			};
		case 'SELECT_USER_MEASURES':
			const measure: UserMeasure = state.userMeasures!.find(m => m.date === action.date)!;
			return {
				...state,
				editingUserMeasures: {
					date: getDateText(new Date(action.date)),
					isUserEntered: true,
					values: measure.values.slice(0),
				},
			};
		case 'UPDATE_USER_MEASURE_FIELD':
			let editingUserMeasures = { ...state.editingUserMeasures };
			if (action.name === 'date')
				editingUserMeasures.date = action.value;
			else {
				const index = parseInt(action.name.substr(6));
				editingUserMeasures.values[index] = action.value;
			}
			return {
				...state,
				editingUserMeasures,
			};
		case 'REQUEST_SAVE_USER_MEASURES':
			return {
				...state,
				isSavingEditingUserMeasures: true,
			};
		case 'RECEIVE_SAVE_USER_MEASURES':
			return {
				...state,
				isSavingEditingUserMeasures: false,
				userMeasures: action.userMeasures || state.userMeasures,
			};
		case 'REQUEST_DELETE_USER_MEASURES':
			return {
				...state,
				isSavingEditingUserMeasures: true,
			};
		case 'RECEIVE_DELETE_USER_MEASURES':
			return {
				...state,
				isSavingEditingUserMeasures: false,
				userMeasures: action.userMeasures || state.userMeasures,
			};
		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		default: const exhaustiveCheck: never = action;
	}
	return state;
}
