import { Action, Reducer } from 'redux';
import { AppThunkAction } from '..';
import { ListCriteria, ListPage, ListState, ListStatus2 } from '../list/ListCommon';
import { criteriaMatches, findExistingPage, getCriteriaUrl } from '../listSupport';
import { getJson, handleWithAction } from '../myfetch';

// From SkyCert v1

// STATE **********************************************************************

const numberOfPracticesPagesToKeep: number = 3;

export interface PracticeListState extends ListState<PracticeSummary> { };

export interface PracticeSummary {
	practiceId: string;
	name: string;
	isActive: boolean;
	numberOfExaminers: number;
	numberOfPilots: number;
}

export enum PracticeListOrderBy {
	Name = 'Name',
	NumberOfExaminers = 'NumberOfExaminers',
	NumberOfPilots = 'NumberOfPilots',
}

export enum PracticeListFilter1 {
	All = 'All',
	PracticesWithoutExaminers = 'PracticesWithoutExaminers',
}

// ACTIONS ********************************************************************

interface RequestPracticeListAction {
	type: 'REQUEST_PRACTICE_LIST';
	criteria: ListCriteria;
}
interface ReceivePracticeListSuccessAction {
	type: 'RECEIVE_PRACTICE_LIST_SUCCESS';
	numberOfPages: number;
	totalNumberOfItems: number;
	page: ListPage<PracticeSummary>;
}
interface ReceivePracticeListFailureAction {
	type: 'RECEIVE_PRACTICE_LIST_FAILURE';
}
interface InvalidateCurrentPracticeListAction {
	type: 'INVALIDATE_CURRENT_PRACTICE_LIST';
}
type KnownAction = RequestPracticeListAction | ReceivePracticeListSuccessAction | ReceivePracticeListFailureAction
	| InvalidateCurrentPracticeListAction
	;

// ACTION CREATORS ************************************************************

export const practiceListActionCreators = {
	requestPracticeList: (criteria: ListCriteria): AppThunkAction<Action> => (dispatch, getState) => {
		const practiceState = getState().practice;
		if (practiceState.list && practiceState.list.listStatus !== ListStatus2.Idle) {
			const state: PracticeListState = practiceState.list!;
			if (criteriaMatches(criteria, state.criteria)
				&& findExistingPage<PracticeSummary>(state, criteria, 'RECEIVE_PRACTICE_LIST_SUCCESS', dispatch))
				return;
		}
		// The requested page is not already loaded.
		const criteriaUrlSuffix: string = getCriteriaUrl(criteria);
		getJson(`api/practices?${criteriaUrlSuffix}`)
			.then(response => {
				dispatch({ type: 'RECEIVE_PRACTICE_LIST_SUCCESS', numberOfPages: response.numberOfPages, page: response.page, totalNumberOfItems: response.totalNumberOfItems, } as KnownAction);
			})
			.catch(handleWithAction('RECEIVE_PRACTICE_LIST_FAILURE'));
		dispatch({ type: 'REQUEST_PRACTICE_LIST', criteria, } as KnownAction);
	},

	invalidateCurrentPracticesPage: () => ({ type: 'INVALIDATE_CURRENT_PRACTICE_LIST' } as KnownAction),
};

// REDUCERS *******************************************************************

export const reducer: Reducer<PracticeListState | null> = (state: PracticeListState | null | undefined, action: KnownAction) => {
	if (state === undefined)
		return null;
	state = state!;	// @@@ I hope this works
	switch (action.type) {
		case 'REQUEST_PRACTICE_LIST':
			const criteriaMatch: boolean = state && criteriaMatches(action.criteria, state.criteria);
			return {
				criteria: action.criteria,
				listStatus: ListStatus2.Loading,
				numberOfPages: criteriaMatch ? state.numberOfPages : 1,
				pages: criteriaMatch ? state.pages : [],
			};
		case 'RECEIVE_PRACTICE_LIST_SUCCESS':
			let newPages: ListPage<PracticeSummary>[] = [
				action.page,
				...state.pages.filter(page => page.pageNumber !== action.page.pageNumber),
			];
			while (newPages.length > numberOfPracticesPagesToKeep) newPages.pop();
			return {
				...state,
				criteria: {
					...state.criteria,
					pageNumber: action.page.pageNumber,
				},
				listStatus: ListStatus2.Loaded,
				numberOfPages: action.numberOfPages,
				totalNumberOfItems: action.totalNumberOfItems,
				pages: newPages,
			};
		case 'RECEIVE_PRACTICE_LIST_FAILURE':
			return {
				...state,
				listStatus: ListStatus2.Failure,
			};
		case 'INVALIDATE_CURRENT_PRACTICE_LIST':
			if (!state)
				return state;
			return {
				...state,
				listStatus: ListStatus2.Dirty,
				pages: [],
			}
		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		default: const exhaustiveCheck: never = action;
	}

	return state;
};
