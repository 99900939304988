import * as React from 'react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RouteChildrenProps } from 'react-router';
import { CardBody } from 'reactstrap';
import { ApplicationState } from '../../store';
import { LoadStatus2 } from '../../store/StoreCommon';
import { videoUrlsActionCreators } from '../../store/video/VideoUrlsStore';
import Loading from '../support/Loading';
import { CollapsingView } from '../support/Views';

interface VideoViewProps {
	header: string;
	color?: string;
	url?: string;
	fileName?: string;
	initiallyCollapsed?: boolean;
	children?: ((props: RouteChildrenProps<any>) => React.ReactNode) | React.ReactNode;
}

export const VideoView = (props: VideoViewProps) => {
	const videoUrls = useSelector((state: ApplicationState) => state.video.videoUrls);
	const dispatch = useDispatch();

	useEffect(() => {
		if (props.url) {
			if (props.fileName)
				throw Error('Only one of url and fileName should be specified.');
			else return;
		} else if (props.fileName && !props.initiallyCollapsed) {
			dispatch(videoUrlsActionCreators.getVideoUrl(props.fileName));
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.fileName, props.initiallyCollapsed, props.url]);

	let url = props.url;
	if (!url) {
		const videoUrl = videoUrls
			? videoUrls.urls.find(vu => vu.fileName === props.fileName)
			: undefined;
		if (videoUrl)
			url = videoUrl.url;
	}

	const openView = (isFirstToggle: boolean) => {
		if (isFirstToggle && !url && props.fileName)
			dispatch(videoUrlsActionCreators.getVideoUrl(props.fileName));
	}

	return (
		<CollapsingView color={props.color} header={props.header}
			initiallyCollapsed={props.initiallyCollapsed}
			onOpen={!url && props.fileName ? openView : undefined}
		>
			<CardBody className='video'>
				{url 
					? <>
						<video width='100%' preload='metadata' controls>
							<source src={url} type='video/mp4' />
							Your browser doesn't support HTML5 video.
							You can access it using <a href={url}>this link</a>.
						</video>
						{props.children}
					</>
					: !videoUrls || videoUrls.status === LoadStatus2.Idle
						? <>
							<p className='text-danger'>
								Unable to find the video.
							</p>
							{props.children}
						</>
						: <Loading />
				}
			</CardBody>
		</CollapsingView>
	);
}
