import * as React from 'react';
import { Form } from 'reactstrap';
import ChildrenProps from './ChildrenProps';


export const useSubmit = (formId: string, action: () => void) => (event: React.FormEvent<HTMLFormElement>) => {
	event.preventDefault();
	let form: any = document.getElementById(formId);
	if (form.checkValidity())
		action();
}


export const useSubmitForm = (formId: string, action: (setIsBusy: (isBusy: boolean) => void) => void): { SubmitForm: (props: ChildrenProps) => JSX.Element, isBusy: boolean } => {
	const [isBusy, setIsBusy] = React.useState(false);
	const submit = (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault();
		let form: any = document.getElementById(formId);
		if (form.checkValidity())
			action(setIsBusy);
	};
	const SubmitForm = (props: ChildrenProps) => (
		<Form onSubmit={submit} id={formId}>
			{props.children}
		</Form>
	);
	return { SubmitForm, isBusy };
}
