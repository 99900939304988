import * as React from 'react';
import { Button, Modal, ModalBody, ModalHeader } from 'reactstrap';
import { SpecialDocumentType } from '../../../store/medical/MedicalCommon';
import { DialogProvider } from '../../support/DialogProvider';


const applications = [
	{ name: 'Aviation', type: SpecialDocumentType.Pilot_Application, },
	{ name: 'Land transport', type: SpecialDocumentType.Land_Transport_Application, },
];


export interface NewMedicalDialogProps {
	render: (toggleIsOpen: () => void) => void;
	onNewMedical: (applicationType: SpecialDocumentType | undefined) => void;
}

export const NewMedicalDialog = (props: NewMedicalDialogProps) => {
	return (
		<DialogProvider
			render={props.render}
			dialog={dialog(props.onNewMedical)}
		/>
	);
}


const dialog = (onNewMedical: (applicationType: SpecialDocumentType | undefined) => void) => (toggleIsOpen: () => void) => {
	const select = (event: React.MouseEvent<HTMLButtonElement>) => {
		onNewMedical(event.currentTarget.dataset.value as SpecialDocumentType | undefined);
		toggleIsOpen();
	}

	return (
		<Modal
			isOpen
			toggle={select}
			backdrop='static'
		>
			<ModalHeader>Start a New Medical</ModalHeader>
			<ModalBody>
				<p>Select the type of application form...</p>
				{applications.map(a => (
					<p className='mb-3' key={a.type}>
						<Button className='col-12' color='info' data-value={a.type} onClick={select}>{a.name}</Button>
					</p>
				))}
				<Button className='col-12' color='secondary' onClick={select}>Cancel</Button>
			</ModalBody>
		</Modal>
	);
}
