import QRCode from 'qrcode.react';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, Prompt } from 'react-router-dom';
import { Button, CardBody, Col, Form, Row } from 'reactstrap';
import { ApplicationState } from '../../store';
import { LoadStatus2 } from '../../store/StoreCommon';
import { accountActionCreators, AccountState } from '../../store/user/AccountStore';
import { loginActionCreators, LoginState, RequiredActionFlags } from '../../store/user/LoginStore';
import { UserRole } from '../../store/user/UserCommon';
import { Page } from '../app/Page';
import Loading from '../support/Loading';
import { TextEditor } from '../support/TextEditors';
import { CollapsingView, StandardViewSize, View, ViewBody } from '../support/Views';
import { VideoView } from '../video/VideoView';
import * as Configuration from '../../Configuration';

export default () => {
	const userState = useSelector(state => (state as ApplicationState).user);
	const account = userState.account;
	const login = userState.login;
	const dispatch = useDispatch();

	useEffect(() => {
		if (!login.loggedIn!.actions.dateQuestionsRequired
			&& !login.loggedIn!.actions.tfaConfigured)
			dispatch(accountActionCreators.getTwoFactorConfiguration());
		return () => {
			dispatch(accountActionCreators.clearTwoFactorTokens());
		};
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	// If have date questions required (in future) and ???
	const showAskLater: boolean = !!login.loggedIn!.actions.dateTfaRequired
		&& (login.loggedIn!.actions.actionsRequested & RequiredActionFlags.ConfigureTwoFactorAuthentication) !== 0
		&& (login.loggedIn!.actions.actionsRequired & RequiredActionFlags.ConfigureTwoFactorAuthentication) === 0;
	// If pilot
	const showDontAskAgain: boolean = !login.loggedIn!.actions.dateQuestionsRequired
		&& !!login.loggedIn!.actions.dateTfaRequired
		&& login.loggedIn!.actions.dateTfaRequired === 'optional'
		&& (login.loggedIn!.actions.actionsRequested & RequiredActionFlags.ConfigureTwoFactorAuthentication) !== 0;
	// If have date required and not required yet
	const showTimeText: boolean = !!login.loggedIn!.actions.dateTfaRequired
		&& login.loggedIn!.actions.dateTfaRequired !== 'optional'
		&& (login.loggedIn!.actions.actionsRequired & RequiredActionFlags.ConfigureTwoFactorAuthentication) === 0;
	const showNeedQuestions: boolean = !!login.loggedIn!.actions.dateQuestionsRequired;
	return (
		<Page wide defaultHelmet='Two Factor Authentication'>
			<Prompt when={!!account && account.twoFactorStatus === LoadStatus2.Changed}
				message={'The two factor configuration has not been saved.\nAre you sure you want to leave this page?'}
			/>
			<h1>Two Factor Authentication</h1>
			{showDontAskAgain &&
				<>
					<p><strong>This is optional for {Configuration.text.pilots} who only use {Configuration.text.siteName} to access their own information - you do not have to set up two factor authentication.</strong></p>
					<p>
						If you don't want to use two factor authentication, click the red "Don't ask me again" button.
						You can still enable two factor authentication later if you wish by clicking on your name at the top right of the page.
					</p>
					<p><Button color='primary' onClick={() => dispatch(accountActionCreators.disableTwoFactor())}>Don't ask me again</Button></p>
				</>
			}
			{showTimeText &&
				<p style={{ fontSize: '2em', }}>You can continue to use {Configuration.text.siteName} until {login.loggedIn!.actions.dateTfaRequired}, but from this date you must configure two factor authentication to continue using {Configuration.text.siteName}.</p>
			}
			{showAskLater &&
				<p><Button color='warning' onClick={() => dispatch(loginActionCreators.clearLoginActionFlag(RequiredActionFlags.ConfigureTwoFactorAuthentication))}>Ask me later</Button></p>
			}
			{showNeedQuestions &&
				<p className='text-danger'>
					You must <Link to='/user/securityquestions'>configure the security questions</Link> before two factor authentiction.
				</p>
			}
			{!showNeedQuestions &&
				<TwoFactorForm
					account={account}
					login={login}
					dispatch={dispatch}
				/>
			}
		</Page>
	);
}

interface TwoFactorFormProps {
	account?: AccountState | null;
	login: LoginState;
	dispatch: any;
}

const TwoFactorForm = (props: TwoFactorFormProps) => {
	if (!props.account || props.account.twoFactorStatus === undefined
		|| props.account.twoFactorStatus === LoadStatus2.Idle) {
		return (
			<Row>
				<p className='col-12'>Two Factor Authentication is already configured.</p>
				<ClearRememberMeForm
					dispatch={props.dispatch}
				/>
				<ChangeTwoFactorForm
					isPilot={props.login.loggedIn!.role === UserRole.Pilot}
					dispatch={props.dispatch}
				/>
			</Row>
		);
	} else if (props.account.twoFactorStatus === LoadStatus2.Loading) {
		return <Loading />;
	} else if (props.account.twoFactorStatus === LoadStatus2.Saving) {
		return <Loading action='Saving' />;
	} else if (props.account.twoFactorStatus === LoadStatus2.Failure) {
		return <p className='text-danger'>Unable to load the two factor configuration.  If this continues please contact {Configuration.text.siteName}.</p>;
	} else if (props.account.twoFactorStatus === LoadStatus2.Changed) {
		return (
			<Row>
				<Col lg={6}>
					<WhatIsTwoFactor />
					<VideoView color='primary' header='How to configure TFA'
						fileName={'TwoFactorSetup.mp4'} />
				</Col>
				<Col lg={6}>
					<ConfigureTwoFactorForm
						account={props.account}
						dispatch={props.dispatch}
					/>
				</Col>
			</Row>
		);
	} else {
		throw new Error(props.account.twoFactorStatus + ' is not a supported status.');
	}
}

interface ClearRememberMeFormProps {
	dispatch: any;
}

const ClearRememberMeForm = (props: ClearRememberMeFormProps) => {
	const clearRememberMe = () => {
		if (window.confirm('Are you sure that you want to clear the "remember me" setting for two factor authentication on this computer?'))
			props.dispatch(accountActionCreators.clearRememberMe());
	}
	return (
		<StandardViewSize>
			<View header='Clear Remember Me' color='primary'>
				<ViewBody>
					<p>
						Use the following button to clear the "remember me" setting for two factor authentication on this computer.
					</p>
					<p>
						<Button type='button' color='primary' onClick={clearRememberMe}>Clear "remember me"</Button>
					</p>
					<p>
						You will be prompted to authenticate next time you log in on this computer.
					</p>
					<p className='text-muted'>
						If you want to clear the remember me for all computers, reset the two factor authentication.
					</p>
				</ViewBody>
			</View>
		</StandardViewSize>
	);
}

interface ChangeTwoFactorFormProps {
	isPilot?: boolean;
	dispatch: any;
}

const ChangeTwoFactorForm = (props: ChangeTwoFactorFormProps) => {
	const FormName = 'resetTwoFactorForm';
	const [tfaCode, setTfaCode] = useState('');

	const resetTwoFactor = () => {
		const form: any = document.getElementById(FormName);
		if (form.checkValidity()
			&& window.confirm('Are you sure that you want to reset the two factor configuration?'))
			props.dispatch(accountActionCreators.getTwoFactorConfiguration(tfaCode));
	}
	const disableTwoFactor = () => {
		const form: any = document.getElementById(FormName);
		if (form.checkValidity()
			&& window.confirm('Are you sure that you want to disable the two factor authentication?'))
			props.dispatch(accountActionCreators.disableTwoFactor(tfaCode));
	}

	return (
		<StandardViewSize>
			<View header='Reset Two Factor Authentication' color='primary'>
				<ViewBody>
					<p>
						Use the following form to reset
						{props.isPilot ? ' or clear ' : ' '}
						your two factor authentication.
					</p>
					<Form id={FormName} onSubmit={e => e.preventDefault()}>
						<TextEditor name='tfaCode'
							label='Two factor code'
							additionalText='Please enter the code generated on your authenticator app.'
							required
							autoFocus
							value={tfaCode}
							onChangeValue={setTfaCode}
						/>
						<Row>
							<Col xs={6}>
								<p>
									<Button type='submit' color='primary' className='form-control' onClick={resetTwoFactor}>Reset 2FA</Button>
								</p>
								<p>
									This will invalidate the existing settings on your authenticator app.
								</p>
							</Col>
							{props.isPilot &&
								<Col xs={6}>
									<p>
										<Button type='button' color='danger' className='form-control' onClick={disableTwoFactor}>Disable 2FA</Button>
									</p>
									<p>
										You can configure it again from the drop-down menu at the top right.
									</p>
								</Col>
							}
						</Row>
					</Form>
				</ViewBody>
			</View>
		</StandardViewSize>
	);
}

interface ConfigureTwoFactorFormProps {
	account: AccountState;
	dispatch: any;
}

const ConfigureTwoFactorForm = (props: ConfigureTwoFactorFormProps) => {
	const FormName = 'configureTwoFactorForm';
	const [tfaCode, setTfaCode] = useState('');

	const setTwoFactor = (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault();
		const form: any = document.getElementById(FormName);
		if (form.checkValidity())
			props.dispatch(accountActionCreators.setTwoFactorConfiguration(tfaCode));
	}

	return (
		<CollapsingView color='primary' header='Configure two factor authentication'>
			<CardBody>
				<Form id={FormName} onSubmit={setTwoFactor}>
					<ol>
						<li className='pb-3'>
							Download a Two Factor Authenticator app
							such as Microsoft Authenticator or Google Authenticator
							from the Play Store (on Android) or the App Store (on iOS).
						</li>
						<li>
							Using the app add an account (if Microsoft Authenticator choose "Other account") by either:<br />
							<ul className='pb-3'>
								<li className='pb-3'>
									scanning this QR code:
									<br /><br />
									<QRCode value={props.account.twoFactor!.keyUrl} />
								</li>
								<li className='pb-3'>
									or entering this key (case and spaces do not matter):
									<br /><br />
									<span className='text-white bg-dark p-1'>&nbsp;{props.account.twoFactor!.key}&nbsp;</span>
								</li>
							</ul>
						</li>
						<li className='pb-3'>
							After the app has accepted the configuration it will show you a code.
							Enter the code here:
							<br /><br />
							<TextEditor name='aaa' label=' '
								required
								value={tfaCode}
								placeholder='Two factor code'
								onChangeValue={setTfaCode}
							/>
							<div className='clearfix' />
						</li>
						<li>
							<Button color='primary' type='submit'>Configure Two Factor Authentication</Button><br />
						</li>
					</ol>
				</Form>
			</CardBody>
		</CollapsingView>
	);
}

const WhatIsTwoFactor = () => (
	<CollapsingView color='primary' header='What is TFA?' initiallyCollapsed>
		<CardBody>
			Two Factor Authentication helps to prevent unauthorised access to your {Configuration.text.siteName} account.
			When you log in you are prompted to enter a code generated by an authenticator app on your phone or tablet.
			Even if someone were able to get your password, they would still not be able to log in without your phone or tablet.
		</CardBody>
	</CollapsingView>
);
