import * as signalR from '@microsoft/signalr';
import * as React from 'react';
import { Link } from 'react-router-dom';
import * as Icon from './components/support/Icon';
import { selectedMedicalActionInvokers } from './store/medical/SelectedMedicalStore';
import { userMessageActionInvokers } from './store/message/MessageStore';
import { dispatchToast } from './store/notification/NotificationStore';

// https://docs.microsoft.com/en-us/aspnet/core/signalr/javascript-client

const connection = new signalR.HubConnectionBuilder()
    .configureLogging(signalR.LogLevel.Warning)
    .withUrl('/api/notifications')
	.withAutomaticReconnect()
    .build();

export const connectNotificationsClient = async () => {
	try {
		await connection.start();
		//connection.onclose(reconnectNotificationsClient);
		connection.on('YouHaveMail', onNewMessage);
        connection.on('MedicalUpdated', onMedicalUpdated);
        connection.on('ReminderUpdated', onReminderUpdated);
		connection.on('Alert', onAlert);
	} catch (error) {
		console.log(error);
		setTimeout(connectNotificationsClient, 5000);
	}
}

//const reconnectNotificationsClient = async (ex?: Error) => {
//    if (ex) {
//        console.error(ex);
//        await connectNotificationsClient();
//    }
//}
//connection.onclose(reconnectNotificationsClient);

export const disconnectNotificationsClient = () => {
    connection.off('YouHaveMail');
    connection.off('MedicalUpdated');
    connection.off('ReminderUpdated');
    connection.off('Alert');
    connection.stop();
}

const onNewMessage = (messageId: number, fromName: string, subject: string, hasBody: boolean) => {
    userMessageActionInvokers.invalidateMessageList();
    let details: (string | JSX.Element)[] = ['You have a new message from ' + fromName + ':', subject];
    if (hasBody)
        details.push(<span><Link to={`/message/me/view/${messageId}`} target='_blank'><Icon.Email title='View message' /> Click here</Link> to view the full message in a new window.</span>);
    dispatchToast(30, 'info', 'Messages', ...details);
	userMessageActionInvokers.setIsNotified(messageId);
}

const onMedicalUpdated = (medicalId: number) => {
    selectedMedicalActionInvokers.updateChangedMedical(medicalId);
}

const onReminderUpdated = (pilotUserId: string) => {
    selectedMedicalActionInvokers.updateFromChangedReminder(pilotUserId);
}

const onAlert = (autoHide: boolean | number, icon: string, title: string, message: string[]) => {
    const details = message.map(text => createJsx(text));
	dispatchToast(autoHide, icon, title, ...details);
}

// The following was derived from https://stackoverflow.com/questions/36104302/how-do-i-convert-a-string-to-jsx
const createJsxArray = (nodeArray: ChildNode[]): JSX.Element[] => {
    return nodeArray.map((node: any) => {
        let attributeObj: any = {};
        const {
            attributes,
            localName,
            childNodes,
            nodeValue
        } = node;
        if (attributes) {
            Array.from(attributes).forEach((attribute: any) => {
                if (attribute.name === "style") {
                    let styleAttributes = attribute.nodeValue.split(";");
                    let styleObj: any = {};
                    styleAttributes.forEach((attribute: any) => {
                        let [key, value] = attribute.split(":");
                        styleObj[key] = value;
                    });
                    attributeObj[attribute.name] = styleObj;
                } else {
                    attributeObj[attribute.name] = attribute.nodeValue;
                }
            });
        }
        return localName ?
            React.createElement(
                localName,
                attributeObj,
                childNodes && Array.isArray(Array.from(childNodes)) ? createJsxArray(Array.from(childNodes)) : []
            ) :
            nodeValue;
    });
};

const createJsx = (text: string) => {
    const nodes = new DOMParser().parseFromString(text, "text/html").body.childNodes;
    const nodeArray = Array.from(nodes);
    const jsx = createJsxArray(nodeArray);
    if (jsx.length === 1)
        return jsx[0];
    return React.createElement("span", {}, jsx);
}
