import { Action, Reducer } from 'redux';
import { store } from '../..';
import { getJson, postJson, putJson } from '../myfetch';
import { dispatchErrorToast, dispatchToast } from '../notification/NotificationStore';
import { LoadStatus2 } from '../StoreCommon';
import { MedicalMandatoryRequirement } from './MedicalCommon';

// STATE **********************************************************************

export interface MedicalTestsNeededState {
	status?: LoadStatus2;	// undefined, Loading, Failure
	userId: string;
	configuration?: MedicalTestsNeededConfiguration;
	tests?: MedicalTestNeededDetails[];
	requirements?: RequirementDetails[];
}

export enum MedicalTestsNeededCondition {
	None = 0,
	Initial = 1,
	Smoker = 2,
}

export interface MedicalTestsNeededConfiguration {
	conditions: number;	// TODO @@@ use type MedicalTestsNeededCondition?
	pilotCertificate: string;
	airTrafficControllerCertificate: string;
	dateOfBirth?: string;
	dateOfMedical?: string;
	pilotName?: string;
}

export interface MedicalTestNeededDetails {
	name: string;
	lastRecorded?: string;
	isRequired: boolean;
	setRequired: number;
	repeatFromAge?: number;
	repeatInYears?: number;
	allowedTestAgeInMonths: number;
}

export interface RequirementDetails {
	requirement: MedicalMandatoryRequirement;
	notes: string;
}

// ACTIONS ********************************************************************

interface RequestMedicalTestsNeededConfigurationAction { type: 'REQUEST_MEDICAL_TESTS_NEEDED_CONFIGURATION'; userId: string; }
interface ReceiveMedicalTestsNeededConfigurationAction { type: 'RECEIVE_MEDICAL_TESTS_NEEDED_CONFIGURATION'; configuration?: MedicalTestsNeededConfiguration; tests?: MedicalTestNeededDetails[]; requirements?: RequirementDetails[]; }

interface UpdateMedicalTestsNeededConfigurationAction { type: 'UPDATE_MEDICAL_TESTS_NEEDED_CONFIGURATION'; name: string; value: any; isRequired: boolean; }

interface RequestMedicalTestsNeededAction { type: 'REQUEST_MEDICAL_TESTS_NEEDED'; }
interface ReceiveMedicalTestsNeededAction { type: 'RECEIVE_MEDICAL_TESTS_NEEDED'; tests?: MedicalTestNeededDetails[]; requirements?: RequirementDetails[]; }

interface RequestUpdateMedicalTestsNeededChecklistAction { type: 'REQUEST_UPDATE_MEDICAL_TESTS_NEEDED_CHECKLIST'; }
interface ReceiveUpdateMedicalTestsNeededChecklistAction { type: 'RECEIVE_UPDATE_MEDICAL_TESTS_NEEDED_CHECKLIST'; tests?: MedicalTestNeededDetails[]; requirements?: RequirementDetails[]; }

type KnownAction =
	RequestMedicalTestsNeededConfigurationAction | ReceiveMedicalTestsNeededConfigurationAction
	| UpdateMedicalTestsNeededConfigurationAction
	| RequestMedicalTestsNeededAction | ReceiveMedicalTestsNeededAction
	| RequestUpdateMedicalTestsNeededChecklistAction | ReceiveUpdateMedicalTestsNeededChecklistAction
	;

// ACTION INVOKERS ************************************************************

export const medicalTestsNeededActionInvokers = {
	requestConfiguration: async (userId: string) => {
		store.dispatch({ type: 'REQUEST_MEDICAL_TESTS_NEEDED_CONFIGURATION', userId, } as KnownAction);
		try {
			const response = await getJson(`api/users/${userId}/testsneeded`);
			store.dispatch({ type: 'RECEIVE_MEDICAL_TESTS_NEEDED_CONFIGURATION', configuration: response.configuration, tests: response.tests, requirements: response.requirements, } as KnownAction);
		} catch (error) {
			store.dispatch({ type: 'RECEIVE_MEDICAL_TESTS_NEEDED_CONFIGURATION', } as KnownAction);
			dispatchErrorToast(true, 'Tests Needed', error);
		}
	},

	updateConfiguration: (name: string, value: any, isRequired: boolean) => { store.dispatch({ type: 'UPDATE_MEDICAL_TESTS_NEEDED_CONFIGURATION', name, value, isRequired, } as KnownAction); },

	requestTestsNeeded: async () => {
		const state = store.getState().medical.testsNeeded!;
		store.dispatch({ type: 'REQUEST_MEDICAL_TESTS_NEEDED', } as KnownAction);
		try {
			const response = await postJson(`api/users/${state.userId}/testsneeded`, state.configuration);
			store.dispatch({ type: 'RECEIVE_MEDICAL_TESTS_NEEDED', tests: response.tests, requirements: response.requirements, requirements2: response.requirements2, } as KnownAction);
		} catch (error) {
			store.dispatch({ type: 'RECEIVE_MEDICAL_TESTS_NEEDED', } as KnownAction);
			dispatchErrorToast(true, 'Tests Needed', error);
		}
	},

	requestUpdateMedicalChecklist: async () => {
		const state = store.getState().medical.testsNeeded!;
		store.dispatch({ type: 'REQUEST_UPDATE_MEDICAL_TESTS_NEEDED_CHECKLIST', } as KnownAction);
		const checklistRequired = state.tests?.reduce((previous, current) => previous + current.setRequired, 0);
		try {
			const response = await putJson(`api/users/${state.userId}/testsNeeded`, { configuration: state.configuration, checklistRequired, })
			store.dispatch({ type: 'RECEIVE_UPDATE_MEDICAL_TESTS_NEEDED_CHECKLIST', tests: response.tests, requirements: response.requirements, requirements2: response.requirements2, } as KnownAction);
			dispatchToast(true, 'success', 'Medical Checklist', response.message);
		} catch (error) {
			store.dispatch({ type: 'RECEIVE_UPDATE_MEDICAL_TESTS_NEEDED_CHECKLIST', } as KnownAction);
			dispatchErrorToast(true, 'Medical Checklist', error);
		}
	},
}

// REDUCERS *******************************************************************

export const reducer: Reducer<MedicalTestsNeededState | null> = (state: MedicalTestsNeededState | null | undefined, incomingAction: Action) => {
	if (state === undefined || incomingAction.type === 'REQUEST_LOGOUT')
		return null;
	const action = incomingAction as KnownAction;
	switch (action.type) {
		case 'REQUEST_MEDICAL_TESTS_NEEDED_CONFIGURATION':
			return {
				status: LoadStatus2.Loading,
				userId: action.userId,
			};
		case 'RECEIVE_MEDICAL_TESTS_NEEDED_CONFIGURATION':
			return {
				...state!,
				status: action.configuration ? undefined : LoadStatus2.Failure,
				configuration: action.configuration,
				tests: action.tests,
				requirements: action.requirements,
			};
		case 'UPDATE_MEDICAL_TESTS_NEEDED_CONFIGURATION':
			return {
				...state!,
				status: undefined,
				configuration: {
					...state!.configuration!,
					[action.name]: (action.value === '' && !action.isRequired) ? null : action.value,
				},
				tests: undefined,
				requirements: undefined,
				requirements2: undefined,
			};
		case 'REQUEST_MEDICAL_TESTS_NEEDED':
			return {
				...state!,
				status: LoadStatus2.Loading,
			};
		case 'RECEIVE_MEDICAL_TESTS_NEEDED':
			return {
				...state!,
				status: action.tests ? undefined : LoadStatus2.Failure,
				tests: action.tests,
				requirements: action.requirements,
			};
		case 'REQUEST_UPDATE_MEDICAL_TESTS_NEEDED_CHECKLIST':
			return {
				...state!,
				status: LoadStatus2.Loading,
			};
		case 'RECEIVE_UPDATE_MEDICAL_TESTS_NEEDED_CHECKLIST':
			return {
				...state!,
				status: action.tests ? undefined : LoadStatus2.Failure,
			// TODO @@@ tests and requirements are not currently returned from the API
			//	tests: action.tests,
			//	requirements: action.requirements,
			};
		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		default: const exhaustiveCheck: never = action;
	}

	return state;
};
