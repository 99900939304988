import * as React from 'react';
import { useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { CardBody } from 'reactstrap';
import { ApplicationState } from '../../store';
import { SummaryLoadStatus2 } from '../../store/summary/SummaryCommon';
import { MandatoryRequirementsSummaryState, userSummaryActionInvokers } from '../../store/summary/UserSummaryStore';
import { LoggedInState } from '../../store/user/LoginStore';
import { UserRole } from '../../store/user/UserCommon';
import Loading from '../support/Loading';
import NewFeature from '../support/NewFeature';
import { StandardSummarySize } from '../support/Summaries';
import { View } from '../support/Views';

export const MandatoryRequirementsSummary = () => {
	const loggedIn = useSelector(state => (state as ApplicationState).user.login.loggedIn)!;
	const mandatoryRequirements = useSelector(state => (state as ApplicationState).summary.mandatoryRequirements);
	const history = useHistory();

	React.useEffect(() => {
		userSummaryActionInvokers.requestMandatoryRequirements();
	}, []);

	const url = getUrl(loggedIn);

	const goToRequirements = () => history.push(url);

	const color = !mandatoryRequirements || mandatoryRequirements.status !== SummaryLoadStatus2.Loaded ? 'primary'
		: mandatoryRequirements.summary.numberOfOverdueRequirements > 0 ? 'danger'
			: mandatoryRequirements.summary.numberOfImminentRequirements > 0 ? 'warning'
				: 'primary';
	return (
		<StandardSummarySize>
			<View header='Mandatory Requirements' onClickHeader={goToRequirements} color={color}>
				<CardBody>
					<NewFeature id='MandatoryRequirements' endDate='1 Feb 2022'
						description={<><p>The status of mandatory requirements can be reviewed here.</p></>}
					>
						<SummaryBody summary={mandatoryRequirements} />
						<p className='mb-0'>
							<Link to={url}>View requirements</Link>
						</p>
					</NewFeature>
				</CardBody>
			</View>
		</StandardSummarySize>
	);
}


const getUrl = (loggedIn: LoggedInState) => {
	switch (loggedIn.role) {
		case UserRole.Administrator:
		case UserRole.RegulatoryAuthorityAgent:
			return '/medical/mandatory-requirements/all';
		case UserRole.MedicalExaminer:
		case UserRole.Nurse:
			return '/medical/mandatory-requirements/practice/myPractice';
		case UserRole.Pilot:
			return '/medical/mandatory-requirements/pilot/me';
		case UserRole.FlyingOrganisationStaff:
		default: throw Error("Should never get here!");
	}
}


interface SummaryBodyProps {
	summary: MandatoryRequirementsSummaryState | null;
}

const SummaryBody = (props: SummaryBodyProps) => {
	if (!props.summary || props.summary.status === SummaryLoadStatus2.Idle) {
		return null;	// This should only ever occur briefly.
	} else if (props.summary.status === SummaryLoadStatus2.Loading) {
		return <Loading />;
	} else if (props.summary.status === SummaryLoadStatus2.Loaded) {
		return (
			<p>
				{props.summary.summary.numberOfActiveRequirements} requirements.<br />
				{props.summary.summary.numberOfImminentRequirements} imminent.<br />
				{props.summary.summary.numberOfOverdueRequirements} overdue.
			</p>
		);
	} else if (props.summary.status === SummaryLoadStatus2.Failure) {
		return <p className='text-danger'>Unable to load.</p>;
	} else return null;
}
