import { Action, Reducer } from 'redux';
import { store } from '../..';
import { List2Criteria, List2State, List2Status } from '../list/ListCommon';
import { getCriteriaUrl2 } from '../listSupport';
import { getJson, postJson, putJson } from '../myfetch';
import { dispatchErrorToast, dispatchToast } from '../notification/NotificationStore';

// STATE **********************************************************************

export interface OverheadJoinState {
	matchesList?: List2State<OverheadJoinMatch>;
}

export interface OverheadJoinMatch {
	overheadJoinMatchId?: number;
	offerFromPilotName: string;
	offerToPilotName: string;
	offerToPilotUserId?: string;	// Only needed for new offers
	fly: string;
	airfieldName: string;
	isBlocked?: boolean;
	dateOfferMade?: string;
	dateOfferAccepted?: string;
}

export enum OverheadJoinMatchesFilter1 {
	All = 'All',
	Matches = 'Matches',
	Offered = 'Offered',
	Accepted = 'Accepted',
	Blocked = 'Blocked',
}
export enum OverheadJoinMatchesOrderBy {
	FromPilot = 'FromPilot',
	ToPilot = 'ToPilot',
}

// ACTIONS ********************************************************************

interface RequestOverheadJoinMatchesAction { type: 'REQUEST_OVERHEAD_JOIN_MATCHES'; criteria: List2Criteria; }
interface ReceiveOverheadJoinMatchesSuccessAction {
	type: 'RECEIVE_OVERHEAD_JOIN_MATCHES_SUCCESS';
	numberOfPages: number;
	totalNumberOfItems: number;
	items: OverheadJoinMatch[];
}
interface RequestOverheadJoinMatchesFailureAction { type: 'RECEIVE_OVERHEAD_JOIN_MATCHES_FAILURE'; }

interface InvalidateOverheadJoinMatchesAction { type: 'INVALIDATE_OVERHEAD_JOIN_MATCHES'; }

type KnownAction =
	RequestOverheadJoinMatchesAction | ReceiveOverheadJoinMatchesSuccessAction | RequestOverheadJoinMatchesFailureAction
	| InvalidateOverheadJoinMatchesAction
	;

// ACTION INVOKERS ************************************************************

export const overheadJoinActionInvokers = {
	getMatches: async (criteria: List2Criteria) => {
		store.dispatch({ type: 'REQUEST_OVERHEAD_JOIN_MATCHES', criteria, });
		try {
			const response = await getJson(`api/overheadjoin/matches?${getCriteriaUrl2(criteria)}`);
			store.dispatch({
				type: 'RECEIVE_OVERHEAD_JOIN_MATCHES_SUCCESS',
				numberOfPages: response.numberOfPages,
				totalNumberOfItems: response.totalNumberOfItems,
				items: response.items,
			});
		} catch (error) {
			store.dispatch({ type: 'RECEIVE_OVERHEAD_JOIN_MATCHES_FAILURE', });
			dispatchErrorToast(true, 'Matches', error);
		}
	},

	saveMatch: async (match: OverheadJoinMatch, stateChange: string) => {
		try {
			const response = match.overheadJoinMatchId
				? await putJson(`api/overheadjoin/matches/${match.overheadJoinMatchId}`, { stateChange, })
				: await postJson('api/overheadjoin/matches', {
					stateChange,
					offerToPilotUserId: match.offerToPilotUserId,
				});
			dispatchToast(true, 'success', 'Overhead Join', response.message);
			store.dispatch({ type: 'INVALIDATE_OVERHEAD_JOIN_MATCHES', });
		} catch (error) {
			dispatchErrorToast(true, 'Overhead Join', error);
		}
	},

	getOffers: async (criteria: List2Criteria) => {
		store.dispatch({ type: 'REQUEST_OVERHEAD_JOIN_MATCHES', criteria, });
		try {
			const response = await getJson(`api/overheadjoin/offers?${getCriteriaUrl2(criteria)}`);
			store.dispatch({
				type: 'RECEIVE_OVERHEAD_JOIN_MATCHES_SUCCESS',
				numberOfPages: response.numberOfPages,
				totalNumberOfItems: response.totalNumberOfItems,
				items: response.items,
			});
		} catch (error) {
			store.dispatch({ type: 'RECEIVE_OVERHEAD_JOIN_MATCHES_FAILURE', });
			dispatchErrorToast(true, 'Offers', error);
		}
	},

	saveOffer: async (match: OverheadJoinMatch, stateChange: string) => {
		try {
			const response = await putJson(`api/overheadjoin/offers/${match.overheadJoinMatchId}`, { stateChange, });
			dispatchToast(true, 'success', 'Overhead Join', response.message);
			store.dispatch({ type: 'INVALIDATE_OVERHEAD_JOIN_MATCHES', });
		} catch (error) {
			dispatchErrorToast(true, 'Overhead Join', error);
		}
	},
}

// REDUCER ********************************************************************

export const reducer: Reducer<OverheadJoinState | null> = (state: OverheadJoinState | undefined | null, incomingAction: Action) => {
	if (state === undefined || incomingAction.type === 'REQUEST_LOGOUT')
		return null;
	const action = incomingAction as KnownAction;
	switch (action.type) {
		case 'REQUEST_OVERHEAD_JOIN_MATCHES':
			return {
				...state,
				matchesList: {
					criteria: action.criteria,
					listStatus: List2Status.Loading,
					numberOfPages: 0,
					totalNumberOfItems: undefined,
					items: [],
				},
			};
		case 'RECEIVE_OVERHEAD_JOIN_MATCHES_SUCCESS':
			return {
				...state,
				matchesList: {
					criteria: state!.matchesList!.criteria,
					listStatus: List2Status.Loaded,
					numberOfPages: action.numberOfPages,
					totalNumberOfItems: action.totalNumberOfItems,
					items: action.items,
				}
			};
		case 'RECEIVE_OVERHEAD_JOIN_MATCHES_FAILURE':
			return {
				...state,
				matchesList: {
					...state!.matchesList!,
					listStatus: List2Status.Failure,
				}
			};
		case 'INVALIDATE_OVERHEAD_JOIN_MATCHES':
			return {
				...state,
				matchesList: {
					...state!.matchesList!,
					listStatus: List2Status.Dirty,
				},
			}
		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		default: const exhaustiveCheck: never = action;
	}
	return state;
};
