import { Action, Reducer } from "redux";
import { ApplicationState } from "..";
import { store } from "../..";
import { List2Criteria, List2State, List2Status } from "../list/ListCommon";
import { getCriteriaUrl2 } from "../listSupport";
import { deleteJson, getJson, postJson, putJson } from "../myfetch";
import { dispatchErrorToast, dispatchToast } from "../notification/NotificationStore";
import { LoadStatus2 } from "../StoreCommon";

// STATE //////////////////////////////////////////////////////////////////////

export interface MessageState {
	list?: List2State<UserMessage>;
	userId?: string;
	name?: string;

	properties?: UserMessage;
	isSavingProperties?: boolean;

	conversationList?: UserMessage[];
	conversationListStatus?: List2Status;
	conversationId?: string;
	conversationIsComplete?: boolean;
	conversationCategoryOrSignificance?: string;

	current?: UserMessage;
	currentStatus?: LoadStatus2;

	newMessage?: NewUserMessage;
	standardRecipients?: UserMessageRecipient[];
	newMessageIsSaving?: boolean;
	newMessageHasChanges?: boolean;
}

export interface UserMessage {
	id: number;
	date: string;
	from: string;
	to: string;
	toMeOrGroup: boolean;
	subject: string;
	category: string;
	significance: string;
	isSeen: boolean;
	conversationId?: string;
	isActive: boolean;
	bodyPreview?: string;
	isPreviewComplete: boolean;
	body?: string;
}

export interface NewUserMessage {
	fromGroup: boolean;
	to: UserMessageRecipient[];
	cc: UserMessageRecipient[];
	bcc: UserMessageRecipient[];
	subject: string;
	body?: string;
}

export interface UserMessageRecipient {
	id: string;
	name: string;
}

export enum UserMessageOrderBy {
	Date = 'Date',
}

export enum UserMessageFilter {
	All = 'All',
	Received = 'Received',
	Sent = 'Sent',
	Archived = 'Archived',
	Unseen = 'Unseen',
}

// ACTIONS ////////////////////////////////////////////////////////////////////

interface RequestUserMessageListAction { type: 'REQUEST_USER_MESSAGE_LIST', criteria: List2Criteria; userId?: string; }
interface ReceiveUserMessageListSuccessAction { type: 'RECEIVE_USER_MESSAGE_LIST_SUCCESS'; numberOfPages: number; totalNumberOfItems: number; items: UserMessage[]; name: string; }
interface ReceiveUserMessageListFailureAction { type: 'RECEIVE_USER_MESSAGE_LIST_FAILURE'; }

interface InvalidateUserMessageListAction { type: 'INVALIDATE_USER_MESSAGE_LIST'; }

interface SelectUserMessagePropertiesAction { type: 'SELECT_USER_MESSAGE_PROPERTIES'; message?: UserMessage; }
interface UpdateUserMessagePropertiesFieldAction { type: 'UPDATE_USER_MESSAGE_PROPERTIES_FIELD'; name: string; value: any; isRequired: boolean; }
interface RequestSaveUserMessagePropertiesAction { type: 'REQUEST_SAVE_USER_MESSAGE_PROPERTIES'; }
interface ReceiveSaveUserMessagePropertiesAction { type: 'RECEIVE_SAVE_USER_MESSAGE_PROPERTIES'; }

interface RequestConversationListAction { type: 'REQUEST_CONVERSATION_LIST'; }
interface ReceiveConversationListAction { type: 'RECEIVE_CONVERSATION_LIST'; conversationList?: UserMessage[]; conversationId: string; categoryOrSignificance?: string; conversationIsComplete?: boolean; }
interface RequestUpdateConversationListAction { type: 'REQUEST_UPDATE_CONVERSATION_LIST'; }
interface ReceiveUpdateConversationListAction { type: 'RECEIVE_UPDATE_CONVERSATION_LIST'; conversationList?: UserMessage[]; recentList?: UserMessage[]; conversationIsComplete?: boolean; }

interface RequestUserMessageAction { type: 'REQUEST_USER_MESSAGE'; }
interface ReceiveUserMessageAction { type: 'RECEIVE_USER_MESSAGE'; message?: UserMessage; }

interface RequestNewUserMessageAction { type: 'REQUEST_NEW_USER_MESSAGE'; }
interface ReceiveNewUserMessageAction { type: 'RECEIVE_NEW_USER_MESSAGE'; newMessage?: NewUserMessage; standardRecipients?: UserMessageRecipient[]; originalId?: number; }

interface UpdateUserMessageFieldAction { type: 'UPDATE_USER_MESSAGE_FIELD'; name: string; value: any; isRequired: boolean; }

interface RequestSendUserMessageAction { type: 'REQUEST_SEND_USER_MESSAGE'; }
interface ReceiveSendUserMessageAction { type: 'RECEIVE_SEND_USER_MESSAGE'; isOk: boolean; }

type KnownAction =
	RequestUserMessageListAction | ReceiveUserMessageListSuccessAction | ReceiveUserMessageListFailureAction
	| InvalidateUserMessageListAction
	| SelectUserMessagePropertiesAction | UpdateUserMessagePropertiesFieldAction
	| RequestSaveUserMessagePropertiesAction | ReceiveSaveUserMessagePropertiesAction
	| RequestConversationListAction | ReceiveConversationListAction
	| RequestUpdateConversationListAction | ReceiveUpdateConversationListAction
	| RequestUserMessageAction | ReceiveUserMessageAction
	| RequestNewUserMessageAction | ReceiveNewUserMessageAction
	| UpdateUserMessageFieldAction
	| RequestSendUserMessageAction | ReceiveSendUserMessageAction
	;


// ACTION INVOKERS ////////////////////////////////////////////////////////////

export const userMessageActionInvokers = {
	requestMessageList: async (criteria: List2Criteria, userId: string) => {
		store.dispatch({ type: 'REQUEST_USER_MESSAGE_LIST', criteria, userId, } as KnownAction);
		try {
			const response = await getJson(`/api/users/${userId}/messages?${getCriteriaUrl2(criteria)}`);
			store.dispatch({ type: 'RECEIVE_USER_MESSAGE_LIST_SUCCESS', numberOfPages: response.numberOfPages, totalNumberOfItems: response.totalNumberOfItems, items: response.items, name: response.name, } as KnownAction);
		} catch (error) {
			store.dispatch({ type: 'RECEIVE_USER_MESSAGE_LIST_FAILURE', } as KnownAction);
			dispatchErrorToast(true, 'Messages', error);
		}
	},

	invalidateMessageList: () => { store.dispatch({ type: 'INVALIDATE_USER_MESSAGE_LIST', } as KnownAction); },

	setIsNotified: async (messageId: number) => {
		try {
			await putJson(`/api/users/me/messages/${messageId}/IsNotified`, { value: true, });
		} catch (error) {
			console.error(`Unable to set IsNotified for message ${messageId}`);
		}
	},

	deleteMessage: async (userId: string, messageId: string) => {
		try {
			await deleteJson(`/api/users/${userId}/messages/${messageId}`);
			userMessageActionInvokers.invalidateMessageList();
			dispatchToast(true, 'success', 'Delete Message', 'The message has been archived.');
		} catch (error) {
			dispatchErrorToast(true, 'Delete Message', error);
		}
	},

	restoreMessage: async (userId: string, messageId: string) => {
		try {
			await putJson(`/api/users/${userId}/messages/${messageId}/isActive`, { value: true, });
			userMessageActionInvokers.invalidateMessageList();
			dispatchToast(true, 'success', 'Restore Message', 'The message has been restored.');
		} catch (error) {
			dispatchErrorToast(true, 'Restore Message', error);
		}
	},

	selectMessage: (message?: UserMessage) => { store.dispatch({ type: 'SELECT_USER_MESSAGE_PROPERTIES', message, } as KnownAction); },

	updatePropertiesField: (name: string, value: any, isRequired: boolean) => {
		store.dispatch({ type: 'UPDATE_USER_MESSAGE_PROPERTIES_FIELD', name, value, isRequired, } as KnownAction);
	},

	saveProperties: async () => {
		store.dispatch({ type: 'REQUEST_SAVE_USER_MESSAGE_PROPERTIES', } as KnownAction);
		try {
			const state = store.getState().message!;
			let category = state!.properties!.category;
			if (!category)
				category = 'None';
			else category = category.replace(/\|/g, ',');
			let significance = state!.properties!.significance;
			if (!significance)
				significance = 'None';
			else significance = significance.replace(/\|/g, ',');
			const properties = {
				category,
				significance,
			};
			await putJson(`/api/users/${state.userId}/Messages/${state.properties!.id}`, properties);
			userMessageActionInvokers.invalidateMessageList();
			userMessageActionInvokers.selectMessage(undefined);
			dispatchToast(true, 'success', 'Edit Properties', 'The message properties have been updated.');
		} catch (error) {
			dispatchErrorToast(true, 'Edit Properties', error);
		}
		store.dispatch({ type: 'RECEIVE_SAVE_USER_MESSAGE_PROPERTIES', } as KnownAction);
	},

	requestConversationList: async (userId: string, conversationId: string, categoryOrSignificance: string) => {
		store.dispatch({ type: 'REQUEST_CONVERSATION_LIST', } as KnownAction);
		try {
			const response = await getJson(`/api/users/${userId}/messages/conversations/${conversationId}?cos=${categoryOrSignificance}`);
			store.dispatch({ type: 'RECEIVE_CONVERSATION_LIST', conversationList: response.conversationList, conversationId, conversationIsComplete: response.conversationIsComplete, categoryOrSignificance, } as KnownAction);
		} catch (error) {
			store.dispatch({ type: 'RECEIVE_CONVERSATION_LIST', } as KnownAction);
			dispatchErrorToast(true, 'Conversation', error);
		}
	},

	requestUpdateConversationList: async () => {
		store.dispatch({ type: 'REQUEST_UPDATE_CONVERSATION_LIST' } as KnownAction);
		try {
			const messages = (store.getState() as ApplicationState).message;
			const conversationMessages = messages!.conversationList!;
			const newestMessageId = conversationMessages.length ? conversationMessages[0].id : 0;
			const oldestMessageId = conversationMessages.length ? conversationMessages[conversationMessages.length - 1].id : 0;
			const response = await getJson(`/api/users/${messages!.userId}/messages/conversations/${messages!.conversationId}?cos=${messages!.conversationCategoryOrSignificance}&n=${newestMessageId}&o=${oldestMessageId}`);
			store.dispatch({ type: 'RECEIVE_UPDATE_CONVERSATION_LIST', conversationList: response.conversationList, recentList: response.recentList, conversationIsComplete: response.conversationIsComplete, } as KnownAction);
		} catch (error) {
			store.dispatch({ type: 'RECEIVE_UPDATE_CONVERSATION_LIST', } as KnownAction);
			dispatchErrorToast(true, 'Conversation', error);
		}
	},

	requestMessage: async (userId: string, messageId: string | number) => {
		store.dispatch({ type: 'REQUEST_USER_MESSAGE' } as KnownAction);
		try {
			const response = await getJson(`/api/users/${userId}/messages/${messageId}`);
			store.dispatch({ type: 'RECEIVE_USER_MESSAGE', message: response.message, } as KnownAction);
		} catch (error) {
			dispatchErrorToast(true, 'Message Body', error);
		}
	},

	requestNewMessage: async (userId: string, type: string | undefined, originalId: string | number | undefined) => {
		store.dispatch({ type: 'REQUEST_NEW_USER_MESSAGE', } as KnownAction);
		try {
			const response = await getJson(`/api/users/${userId}/messages/new?type=${type || ''}&originalId=${originalId || ''}`);
			const iOriginalId = typeof originalId === 'string' ? parseInt(originalId) : originalId;
			store.dispatch({ type: 'RECEIVE_NEW_USER_MESSAGE', newMessage: response.newMessage, standardRecipients: response.standardRecipients, originalId: iOriginalId, } as KnownAction);
		} catch (error) {
			store.dispatch({ type: 'RECEIVE_NEW_USER_MESSAGE', newMessage: { fromGroup: true, to: [], cc: [], bcc: [], subject: '', }, standardRecipients: [], } as KnownAction);
			dispatchErrorToast(true, 'New Message', error);
		}
	},

	updateRecipients: (name: string, recipients: UserMessageRecipient[]) => { store.dispatch({ type: 'UPDATE_USER_MESSAGE_FIELD', name, value: recipients, isRequired: true, } as KnownAction); },
	updateNewMessageField: (name: string, value: any, isRequired: boolean) => { store.dispatch({ type: 'UPDATE_USER_MESSAGE_FIELD', name, value, isRequired, } as KnownAction); },

	getMatchingRecipients: (userId: string, search: string) => {
		return getJson(`/api/users/${userId}/messages/new/recipients?search=${search}`)
			.then(response => response.recipients)
			.catch(error => []);
	},

	sendMessage: async (userId: string, originalId?: string) => {
		const message = store.getState().message?.newMessage;
		if (message && message.to.length === 0 && message.cc.length === 0) {
			dispatchToast(true, 'danger', 'Send Message', 'No recipient has been defined.');
			return false;
		} else {
			try {
				const suffix = !originalId ? '' : '?originalId=' + originalId;
				const response = await postJson(`/api/users/${userId}/messages${suffix}`, message);
				store.dispatch({ type: 'RECEIVE_SEND_USER_MESSAGE', isOk: true, } as KnownAction);
				dispatchToast(true, 'success', 'Send Message', response.message);
				userMessageActionInvokers.invalidateMessageList();
				return true;
			} catch (error) {
				store.dispatch({ type: 'RECEIVE_SEND_USER_MESSAGE', isOk: false, } as KnownAction);
				dispatchErrorToast(true, 'Send Message', error);
				return false;
			}
		}
	},
};


// REDUCER ////////////////////////////////////////////////////////////////////

export const reducer: Reducer<MessageState | null> = (state: MessageState | undefined | null, incomingAction: Action) => {
	if (state === undefined || incomingAction.type === 'REQUEST_LOGOUT')
		return null;
	const action = incomingAction as KnownAction;
	switch (action.type) {
		case 'REQUEST_USER_MESSAGE_LIST':
			return {
				...state,
				list: {
					criteria: action.criteria,
					listStatus: List2Status.Loading,
					numberOfPages: 0,
					totalNumberOfItems: undefined,
					items: [],
				},
				userId: action.userId,
				name: undefined,
				properties: undefined,
				isSavingProperties: undefined,
				conversationList: state?.userId === action.userId ? state?.conversationList : undefined,
				conversationListStatus: state?.userId === action.userId ? state?.conversationListStatus : undefined,
				current: undefined,
				currentStatus: undefined,
			};
		case 'RECEIVE_USER_MESSAGE_LIST_SUCCESS':
			return {
				...state,
				list: {
					criteria: state!.list!.criteria,
					listStatus: List2Status.Loaded,
					numberOfPages: action.numberOfPages,
					totalNumberOfItems: action.totalNumberOfItems,
					items: action.items,
				},
				name: action.name,
			};
		case 'RECEIVE_USER_MESSAGE_LIST_FAILURE':
			return {
				...state,
				list: {
					...state!.list!,
					listStatus: List2Status.Failure,
				},
			};
		case 'INVALIDATE_USER_MESSAGE_LIST':
			return {
				...state,
				list: {
					...state!.list!,
					listStatus: List2Status.Dirty,
				},
				properties: undefined,
				isSavingProperties: undefined,
				current: undefined,
				currentStatus: undefined,
			};
		case 'SELECT_USER_MESSAGE_PROPERTIES':
			return {
				...state,
				properties: action.message,
			};
		case 'UPDATE_USER_MESSAGE_PROPERTIES_FIELD':
			return {
				...state,
				properties: {
					...state?.properties!,
					[action.name]: (action.value === '' && !action.isRequired) ? null : action.value,
				},
			};
		case 'REQUEST_SAVE_USER_MESSAGE_PROPERTIES':
			return {
				...state,
				isSavingProperties: true,
			};
		case 'RECEIVE_SAVE_USER_MESSAGE_PROPERTIES':
			return {
				...state,
				isSavingProperties: undefined,
			};
		case 'REQUEST_CONVERSATION_LIST':
			return {
				...state,
				conversationList: undefined,
				conversationListStatus: List2Status.Loading,
				conversationId: undefined,
			};
		case 'RECEIVE_CONVERSATION_LIST':
			return {
				...state,
				conversationList: action.conversationList,
				conversationListStatus: !!action.conversationList ? List2Status.Loaded : List2Status.Failure,
				conversationId: action.conversationId,
				conversationIsComplete: action.conversationIsComplete,
				conversationCategoryOrSignificance: action.categoryOrSignificance,
			};
		case 'REQUEST_UPDATE_CONVERSATION_LIST':
			return {
				...state,
				conversationListStatus: List2Status.Loading,
			};
		case 'RECEIVE_UPDATE_CONVERSATION_LIST':
			{
				let conversationList = [...state!.conversationList!, ...action.conversationList!];
				if (action.recentList)
					conversationList = [...action.recentList, ...conversationList];
				return {
					...state,
					conversationList,
					conversationListStatus: !!action.conversationList ? List2Status.Loaded : List2Status.Failure,
					conversationIsComplete: action.conversationIsComplete,
				};
			}
		case 'REQUEST_USER_MESSAGE':
			return {
				...state,
				current: undefined,
				currentStatus: LoadStatus2.Loading,
			};
		case 'RECEIVE_USER_MESSAGE': {
			const list = !state!.list ? undefined : {
				...state!.list,
				items: state!.list.items?.map(m => m.id === action.message?.id ? action.message : m),
			};
			const conversationList = state!.conversationList?.map(m => m.id === action.message?.id ? action.message : m);
			return {
				...state,
				list,
				conversationList,
				current: action.message,
				currentStatus: !action.message ? LoadStatus2.Failure : LoadStatus2.Loaded,
			};
		}
		case 'REQUEST_NEW_USER_MESSAGE':
			return {
				...state,
				newMessage: undefined,
				standardRecipients: undefined,
				newMessageIsSaving: undefined,
				newMessageHasChanges: undefined,
			};
		case 'RECEIVE_NEW_USER_MESSAGE':
			const list = !state!.list ? undefined : {
				...state!.list,
				items: state!.list.items?.map(m => m.id === action.originalId ? { ...m, isSeen: true, } : m),
			};
			return {
				...state,
				list,
				newMessage: action.newMessage,
				standardRecipients: action.standardRecipients,
			};
		case 'UPDATE_USER_MESSAGE_FIELD':
			return {
				...state,
				newMessage: {
					...state!.newMessage!,
					[action.name]: (action.value === '' && !action.isRequired) ? null : action.value,
				},
				newMessageHasChanges: true,
			};
		case 'REQUEST_SEND_USER_MESSAGE':
			return {
				...state,
				newMessageIsSaving: true,
			};
		case 'RECEIVE_SEND_USER_MESSAGE':
			return {
				...state,
				newMessage: action.isOk ? undefined : state!.newMessage,
				standardRecipients: action.isOk ? undefined : state!.standardRecipients,
				newMessageIsSaving: undefined,
				newMessageHasChanges: action.isOk ? undefined : state!.newMessageHasChanges,
			};
		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		default: const exhaustiveCheck: never = action;
	}
	return state;
}
