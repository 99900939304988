import { Action, Reducer } from 'redux';
import { store } from '../..';
import { List2Criteria, List2State, List2Status } from '../list/ListCommon';
import { getCriteriaUrl2 } from '../listSupport';
import { getJson } from '../myfetch';
import { dispatchErrorToast } from '../notification/NotificationStore';
import { MedicalMandatoryRequirement } from './MedicalCommon';

// STATE **********************************************************************

export interface MandatoryRequirementsState {
	requirementsList: MandatoryRequirementsListState;
	selectedRequirement?: MedicalMandatoryRequirement;
}

export interface MandatoryRequirementsListState extends List2State<MedicalMandatoryRequirement> {
	userId?: string;
	medicalPracticeId?: string;
}

export enum MandatoryRequirementsFilter {
	All = 'All',
	Current = 'Current',
	Recent = 'Recent',
	Imminent = 'Imminent',
	Overdue = 'Overdue',
	Inactive = 'Inactive',
}

export enum MandatoryRequirementsOrderBy {
	DueBy = 'DueBy',
	PilotName = 'PilotName',
	PilotRaId = 'PilotRaId',
}

// ACTIONS ********************************************************************

interface RequestMandatoryRequirementsAction { type: 'REQUEST_MANDATORY_REQUIREMENTS'; criteria: List2Criteria; }
interface ReceiveMandatoryRequirementsSuccessAction { type: 'RECEIVE_MANDATORY_REQUIREMENTS_SUCCESS'; numberOfPages: number; totalNumberOfItems: number; items: MedicalMandatoryRequirement[]; userId?: string; medicalPracticeId?: string; }
interface ReceiveMandatoryRequirementsFailureAction { type: 'RECEIVE_MANDATORY_REQUIREMENTS_FAILURE'; }

interface InvalidateMandatoryRequirementsList { type: 'INVALIDATE_MANDATORY_REQUIREMENTS_LIST'; }

interface SelectMandatoryRequirementAction { type: 'SELECT_MANDATORY_REQUIREMENT'; selectedRequirement?: MedicalMandatoryRequirement; }

type KnownAction =
	RequestMandatoryRequirementsAction | ReceiveMandatoryRequirementsSuccessAction | ReceiveMandatoryRequirementsFailureAction
	| InvalidateMandatoryRequirementsList
	| SelectMandatoryRequirementAction
	;

// ACTION INVOKERS ************************************************************

export const mandatoryRequirementsActionInvokers = {
	requestMandatoryRequirements: async (criteria: List2Criteria, userId?: string, medicalPracticeId?: string) => {
		store.dispatch({ type: 'REQUEST_MANDATORY_REQUIREMENTS', criteria, } as KnownAction);
		try {
			const medicalPracticeIdSuffix = !medicalPracticeId ? '' : `&medicalPracticeId=${medicalPracticeId}`;
			const url = userId ? `api/users/${userId}/mandatoryrequirements?${getCriteriaUrl2(criteria)}${medicalPracticeIdSuffix}`
				: `api/mandatoryrequirements?${getCriteriaUrl2(criteria)}${medicalPracticeIdSuffix}`;
			const response = await getJson(url);
			store.dispatch({
				type: 'RECEIVE_MANDATORY_REQUIREMENTS_SUCCESS',
				numberOfPages: response.numberOfPages,
				totalNumberOfItems: response.totalNumberOfItems,
				items: response.items,
				userId,
				medicalPracticeId,
			} as KnownAction);
		} catch (error) {
			store.dispatch({ type: 'RECEIVE_MANDATORY_REQUIREMENTS_FAILURE', } as KnownAction);
			dispatchErrorToast(true, 'Mandatory Requirements', error);
		}
	},

	invalidateMandatoryRequirementsList: () => { store.dispatch({ type: 'INVALIDATE_MANDATORY_REQUIREMENTS_LIST', } as KnownAction); },

	selectMandatoryRequirement: (selectedRequirement: MedicalMandatoryRequirement) => { store.dispatch({ type: 'SELECT_MANDATORY_REQUIREMENT', selectedRequirement, } as KnownAction); },
}

// REDUCERS *******************************************************************

export const reducer: Reducer<MandatoryRequirementsState | null> = (state: MandatoryRequirementsState | null | undefined, incomingAction: Action) => {
	if (state === undefined || incomingAction.type === 'REQUEST_LOGOUT')
		return null;
	const action = incomingAction as KnownAction;
	switch (action.type) {
		case 'REQUEST_MANDATORY_REQUIREMENTS':
			return {
				...state,
				requirementsList: {
					criteria: action.criteria,
					listStatus: List2Status.Loading,
					numberOfPages: state?.requirementsList.numberOfPages ?? 0,
					totalNumberOfItems: state?.requirementsList.totalNumberOfItems,
					items: [],
				},
			};
		case 'RECEIVE_MANDATORY_REQUIREMENTS_SUCCESS':
			return {
				...state,
				requirementsList: {
					criteria: state!.requirementsList.criteria,
					listStatus: List2Status.Loaded,
					numberOfPages: action.numberOfPages,
					totalNumberOfItems: action.totalNumberOfItems,
					items: action.items,
					userId: action.userId,
					medicalPracticeId: action.medicalPracticeId,
				},
			};
		case 'RECEIVE_MANDATORY_REQUIREMENTS_FAILURE':
			return {
				...state,
				requirementsList: {
					...state!.requirementsList,
					listStatus: List2Status.Failure,
				},
			};
		case 'INVALIDATE_MANDATORY_REQUIREMENTS_LIST':
			return {
				...state,
				requirementsList: {
					...state!.requirementsList,
					listStatus: List2Status.Dirty,
				}
			};
		case 'SELECT_MANDATORY_REQUIREMENT':
			return {
				...state!,
				selectedRequirement: action.selectedRequirement,
			};
		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		default: const exhaustiveCheck: never = action;
	}

	return state;
};
