import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'reactstrap';
import { ApplicationState } from '../../../store';
import { accountActionCreators } from '../../../store/user/AccountStore';
import { loginActionCreators, RequiredActionFlags } from '../../../store/user/LoginStore';
import { UserRole } from '../../../store/user/UserCommon';

interface TermsAndConditionsSupportProps {
	role: string;
}

export const TermsAndConditionsAcceptance = (props: TermsAndConditionsSupportProps) => {
	const login = useSelector(state => (state as ApplicationState).user.login);
	const dispatch = useDispatch();

	if (login?.loggedIn == null || props.role !== login.loggedIn!.role) {
		return null;
	} else if (login.loggedIn!.actions.dateTCAccepted) {
		return <p className='d-print-none'><em>You accepted these terms and conditions on {login.loggedIn!.actions.dateTCAccepted}.</em></p>;
	}

	// If have date required and requested but not required
	const showAskLater: boolean = !!login.loggedIn!.actions.dateTCRequired
		&& (login.loggedIn!.actions.actionsRequested & RequiredActionFlags.AcceptTermsAndConditions) !== 0
		&& (login.loggedIn!.actions.actionsRequired & RequiredActionFlags.AcceptTermsAndConditions) === 0;
	// If have date required and not required yet
	const showTimeText: boolean = !!login.loggedIn!.actions.dateTCRequired
		&& (login.loggedIn!.actions.actionsRequired & RequiredActionFlags.AcceptTermsAndConditions) === 0;
	// TODO @@@ Accept button

	return (
		<>
			{showTimeText &&
				<p className='text-danger d-print-none'>You can continue to use SkyCert until {login.loggedIn!.actions.dateTCRequired}, but from this date you must accept the terms and conditions (using the button at the end of the page) to continue using SkyCert.</p>
			}
			{showAskLater &&
				<p className='d-print-none'><Button color='warning' onClick={() => dispatch(loginActionCreators.clearLoginActionFlag(RequiredActionFlags.AcceptTermsAndConditions))}>Ask me later</Button></p>
			}
		</>
	);
}

export const AcceptTermsAndConditions = (props: TermsAndConditionsSupportProps) => {
	const login = useSelector(state => (state as ApplicationState).user.login);
	const dispatch = useDispatch();

	if (login?.loggedIn == null || props.role !== login.loggedIn!.role || login.loggedIn!.actions.dateTCAccepted) {
		return null;
	}

	return (
		<p className='d-print-none'>
			<Button type='button' color='success' onClick={() => dispatch(accountActionCreators.acceptTermsAndConditions())}>I accept these terms and conditions</Button>
			{(props.role === UserRole.MedicalExaminer || props.role === UserRole.Nurse) &&
				<small className='text-muted'>&nbsp;&nbsp;&nbsp;except for those clauses that conflict with an individual agreement with your medical practice</small>
			}
		</p>
	);
}
