import * as React from 'react';
import { createContext, useContext } from 'react';
import { RouteChildrenProps } from 'react-router';
import { CardColumns, CardDeck, CardGroup, Col, Row } from 'reactstrap';


export type LayoutMode = 'group' | 'deck' | 'grid' | 'column';

export const defaultLayout = {
	mode: 'grid' as LayoutMode,
	gridClassName: undefined as string | undefined,
};

const LayoutContext = createContext(defaultLayout);


///////////////////////////////////////////////////////////////////////////////

interface LayoutContainerProps {
	mode?: LayoutMode;	// Defaults to grid
	gridClassName?: string;	// e.g. row-cols-1 row-cols-md-2 row-cols-xl-3
	columnCount?: number;
	columnClassName?: string;
	children?: ((props: RouteChildrenProps<any>) => React.ReactNode) | React.ReactNode;
}

export const LayoutContainer = (props: LayoutContainerProps) => {
	const mode = props.mode || 'grid';
	let children: any;
	switch (mode) {
		case 'group':
			children = (
				<CardGroup>
					{props.children}
				</CardGroup>
			);
			break;
		case 'deck':
			children = (
				<CardDeck>
					{props.children}
				</CardDeck>
			);
			break;
		case 'grid':
			children = (
				<Row className={props.gridClassName || ''}>
					{props.children}
				</Row>
			);
			break;
		case 'column':
			children = (
				<CardColumns style={{ columnCount: props.columnCount, }} className={props.columnClassName}>
					{props.children}
				</CardColumns>
			);
			break;
		// TODO @@@ Handle default?  Throw an error?
	}
	return (
		<LayoutContext.Provider value={{ mode, gridClassName: props.gridClassName, }}>
			{children}
		</LayoutContext.Provider>
	);
}

///////////////////////////////////////////////////////////////////////////////

interface LayoutSizeProps {
	gridClassName?: string;
	children?: ((props: RouteChildrenProps<any>) => React.ReactNode) | React.ReactNode;
}

export const LayoutSize = (props: LayoutSizeProps) => {
	const context = useContext(LayoutContext);
	switch (context.mode) {
		case 'group':
			return (
				<>
					{props.children}
				</>
			);
		case 'deck':
			return (
				<>
					{props.children}
				</>
			);
		case 'grid':
			return (
				<Col className={context.gridClassName ? '' : props.gridClassName}>
					{props.children}
				</Col>
			);
		case 'column':
			return (
				<>
					{props.children}
				</>
			);
		// TODO @@@ Handle default?  Throw an error?
	}
}
