import * as React from 'react';
import { Link } from "react-router-dom";
import { Button, Col, Form, Row } from 'reactstrap';
import * as Configuration from '../../Configuration';
import { SecurityQuestions } from '../../store/user/AccountStore';
import { LoginConnection, LoginCredentials } from "../../store/user/LoginStore";
import { UserRole } from "../../store/user/UserCommon";
import { FieldLength } from '../support/FieldLength';
import { CheckboxEditor, SelectEditor } from '../support/NonTextEditors';
import { PasswordEditor, TextEditor } from '../support/TextEditors';
import { useSubmit } from '../support/useSubmit';


///////////////////////////////////////////////////////////////////////////////

interface LoginWithUsernameEditorProps {
	raName: string;
	credentials: LoginCredentials;
	isTimeout: boolean;
	onUpdateCredentials: (name: string, value: any, isRequired: boolean) => void;
	onLogin: (password: string) => void;
	onCancel: () => void;
};

export const LoginWithUsernameEditor = (props: LoginWithUsernameEditorProps) => {
	const FormName = 'loginForm';
	const [password, setPassword] = React.useState('');

	const login = useSubmit(FormName,
		() => props.onLogin(password)
	);

	return (
		<>
			<Form onSubmit={login} id={FormName}>
				{!props.isTimeout &&
					<TextEditor name='userName' label='User name'
						additionalText={"Initially this is the email address you signed up with."}
						value={props.credentials.userName}
						required
						autoFocus
						onChangeField={props.onUpdateCredentials}
						autoComplete='username'
						maxLength={FieldLength.UserNameLength}
					/>
				}
				{!props.isTimeout && props.credentials.role !== UserRole.Administrator &&
					<SelectEditor name='role' label='Role' id='loginRole'
						value={props.credentials.role}
						required
						onChangeField={props.onUpdateCredentials}
					>
						<Configuration.LoginRoles />
					</SelectEditor>
				}
				<PasswordEditor name='password' label='Password'
					value={password}
					required
					autoFocus={props.isTimeout}
					onChangeValue={setPassword}
					autoComplete='current-password'
				/>
				<Row>
					<Col xs={6}><Button className='col-12' color='primary' type='submit'>Login</Button></Col>
					<Col xs={6}><Button className='col-12' color='secondary' onClick={props.onCancel}>Cancel</Button></Col>
				</Row>
			</Form>
			{!props.isTimeout &&
				<>
					<p className='mt-5'>
						Forgot your password?
						<Link to='/user/request-password-reset'> Click here </Link> to reset your password.
					</p>
					<p>
						Not registered?
						<Link to='/user/signup' rel='nofollow'> Click here </Link> to sign up as a pilot.
					</p>
				</>
			}
		</>
	);
}


///////////////////////////////////////////////////////////////////////////////

interface LoginWithTwoFactorEditorProps {
	credentials: LoginCredentials;
	allowSecurityQuestions: boolean;
	onUpdateCredentials: (name: string, value: any, isRequired: boolean) => void;
	onLogin: () => void;
	onCancel: () => void;
	onUseSecurityQuestions: () => void;
};

export const LoginWithTwoFactorEditor = (props: LoginWithTwoFactorEditorProps) => {
	const FormName = 'twoFactorLoginForm';

	const login = useSubmit(FormName,
		() => props.onLogin()
	);

	return (
		<>
			<Form onSubmit={login} id={FormName}>
				<TextEditor name='twoFactorCode'
					label='Two factor code'
					additionalText='Please enter the code generated on your authenticator app.'
					value={props.credentials.twoFactorCode}
					required
					autoFocus
					onChangeField={props.onUpdateCredentials}
				/>
				<CheckboxEditor name='rememberMeOnThisComputer'
					label='Remember me on this computer'
					additionalText="If selected, you won't be prompted again for a two factor code.  Only use this on personal devices and never on public computers."
					value={props.credentials.rememberMeOnThisComputer}
					onChangeField={props.onUpdateCredentials}
				/>
				<Row className='mb-3'>
					<Col xs={6}><Button className='col-12' color='primary' type='submit'>Login</Button></Col>
					<Col xs={6}><Button className='col-12' color='secondary' onClick={props.onCancel}>Cancel</Button></Col>
				</Row>
			</Form>
			{props.allowSecurityQuestions &&
				<p className='mb-3'>
					<span className='font-weight-bold text-danger'>Lost your phone?</span>
					&nbsp;&nbsp;
					<Button color='link' onClick={props.onUseSecurityQuestions}>Click here </Button>
					to log in using your security questions.
				</p>
			}
		</>
	);
};


///////////////////////////////////////////////////////////////////////////////

interface LoginWithSecurityQuestionsEditorProps {
	credentials: LoginCredentials;
	questions: number[];
	onUpdateCredentials: (name: string, value: any, isRequired: boolean) => void;
	onLogin: () => void;
	onCancel: () => void;
};

export const LoginWithSecurityQuestionsEditor = (props: LoginWithSecurityQuestionsEditorProps) => {
	const FormName = 'questionsLoginForm';

	const login = useSubmit(FormName,
		() => props.onLogin()
	);

	const credentials: any = props.credentials;
	return (
		<Form onSubmit={login} id={FormName}>
			<p>So that we can be sure this is really you, please answer the following security questions...</p>
			{props.questions.map((q, index) =>
				<TextEditor name={'answer' + (index + 1).toString()} key={index}
					label={SecurityQuestions[q - 1]}
					required
					autoFocus={index === 0}
					value={credentials['answer' + (index + 1).toString()]}
					onChangeField={props.onUpdateCredentials}
					maxLength={FieldLength.SecurityQuestionLength}
				/>
			)}
			<Row className='mb-3'>
				<Col xs={6}><Button className='col-12' color='primary' type='submit'>Login</Button></Col>
				<Col xs={6}><Button className='col-12' color='secondary' onClick={() => props.onCancel}>Cancel</Button></Col>
			</Row>
		</Form>
	);
};


///////////////////////////////////////////////////////////////////////////////

interface LoginSelectConnectionEditorProps {
	role: string;
	connections: LoginConnection[];
	onLogin: (connectionId: number) => void;
	onCancel: () => void;
};

export const LoginSelectConnectionEditor = (props: LoginSelectConnectionEditorProps) => {
	return (
		<>
			<p>Please select the {props.role === UserRole.FlyingOrganisationStaff ? 'organisation' : 'practice'} to login to...</p>
			{props.connections.map(c =>
				<p key={c.connectionId} className='mb-3'>
					<Button className='col-12' color='info' onClick={() => props.onLogin(c.connectionId)}>{c.name}</Button>
				</p>
			)}
			<Button className='col-12' color='secondary' onClick={props.onCancel}>Cancel</Button>
		</>
	);
}
