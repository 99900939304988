import { Action, combineReducers, Reducer } from 'redux';
import * as AppointmentListStore from './AppointmentListStore';
import * as BookingDatesStore from './BookingDatesStore';
import * as CurrentBookingStore from './CurrentBookingStore';
import * as PracticeConfigurationStore from './PracticeConfigurationStore';

// From SkyCert v1

// STATE **********************************************************************

export interface BookingState {
	practiceConfiguration: PracticeConfigurationStore.PracticeConfigurationState | null;
	bookingDates: BookingDatesStore.BookingDatesState | null;
	appointmentList: AppointmentListStore.AppointmentListState | null;
	currentBooking: CurrentBookingStore.CurrentBookingState | null;
}

// REDUCERS *******************************************************************

const combinedReducer: Reducer<BookingState> = combineReducers<BookingState>({
	practiceConfiguration: PracticeConfigurationStore.reducer,
	bookingDates: BookingDatesStore.reducer,
	appointmentList: AppointmentListStore.reducer,
	currentBooking: CurrentBookingStore.reducer,
})

export const reducer: Reducer<BookingState> = (state: BookingState | undefined, action: Action) => {
	if (action.type === 'REQUEST_LOGOUT')
		state = undefined;
	return combinedReducer(state, action);
}
