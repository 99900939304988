import { Reducer } from "redux";
import { store } from "../..";
import { List2Criteria, List2State, List2Status } from "../list/ListCommon";
import { getCriteriaUrl2 } from "../listSupport";
import { getJson } from "../myfetch";
import { dispatchErrorToast } from "../notification/NotificationStore";

// STATE //////////////////////////////////////////////////////////////////////

export interface SpecialistPracticeListState {
	practicesList?: List2State<SpecialistPracticeSummary>;
}

export interface SpecialistPracticeSummary {
	practiceId: string;
	name: string;
	activities: string;
	isActive: boolean;
	numberOfSpecialists: number;
}

export enum SpecialistPracticeListOrderBy {
	Name = 'Name',
}

export enum SpecialistPracticeListFilter1 {
	All = 'All',
}


// ACTIONS ////////////////////////////////////////////////////////////////////

interface RequestSpecialistPracticeListAction {
	type: 'REQUEST_SPECIALIST_PRACTICE_LIST';
	criteria: List2Criteria;
}
interface ReceiveSpecialistPracticeListSuccessAction {
	type: 'RECEIVE_SPECIALIST_PRACTICE_LIST_SUCCESS';
	numberOfPages: number;
	totalNumberOfItems: number;
	items: SpecialistPracticeSummary[];
}
interface ReceiveSpecialistPracticeListFailureAction { type: 'RECEIVE_SPECIALIST_PRACTICE_LIST_FAILURE'; }

interface InvalidateSpecialistPracticeListAction { type: 'INVALIDATE_SPECIALIST_PRACTICE_LIST'; }

type KnownAction =
	RequestSpecialistPracticeListAction | ReceiveSpecialistPracticeListSuccessAction | ReceiveSpecialistPracticeListFailureAction
	| InvalidateSpecialistPracticeListAction
	;


// ACTION INVOKERS ////////////////////////////////////////////////////////////

export const specialistPracticeListActionInvokers = {
	requestSpecialistPracticeList: async (criteria: List2Criteria) => {
		try {
			store.dispatch({ type: 'REQUEST_SPECIALIST_PRACTICE_LIST', criteria, } as KnownAction);
			const criteriaUrlSuffix = getCriteriaUrl2(criteria);
			const url = `api/specialists?${criteriaUrlSuffix}`;
			const response = await getJson(url);
			store.dispatch({ type: 'RECEIVE_SPECIALIST_PRACTICE_LIST_SUCCESS', numberOfPages: response.numberOfPages, totalNumberOfItems: response.totalNumberOfItems, items: response.items, } as KnownAction);
		} catch (error) {
			store.dispatch({ type: 'RECEIVE_SPECIALIST_PRACTICE_LIST_FAILURE', } as KnownAction);
			dispatchErrorToast(true, 'Specialist Practice List', error);
        }
	},

	invalidateSpecialistPracticeList: () => { store.dispatch({ type: 'INVALIDATE_SPECIALIST_PRACTICE_LIST', } as KnownAction); },
};


// REDUCER ////////////////////////////////////////////////////////////////////

export const reducer: Reducer<SpecialistPracticeListState | null> = (state: SpecialistPracticeListState | undefined | null, action: KnownAction) => {
	if (state === undefined)
		return null;
	switch (action.type) {
		case 'REQUEST_SPECIALIST_PRACTICE_LIST':
			return {
				practicesList: {
					criteria: action.criteria,
					listStatus: List2Status.Loading,
					numberOfPages: 0,
					totalNumberOfItems: undefined,
					items: [],
                },
			};
		case 'RECEIVE_SPECIALIST_PRACTICE_LIST_SUCCESS':
			return {
				...state,
				practicesList: {
					criteria: state!.practicesList!.criteria,
					listStatus: List2Status.Loaded,
					numberOfPages: action.numberOfPages,
					totalNumberOfItems: action.totalNumberOfItems,
					items: action.items,
				},
			};
		case 'RECEIVE_SPECIALIST_PRACTICE_LIST_FAILURE':
			return {
				...state,
				practicesList: {
					...state!.practicesList!,
					listStatus: List2Status.Failure,
				},
			};
		case 'INVALIDATE_SPECIALIST_PRACTICE_LIST':
			if (!state)
				return state;
			return {
				...state,
				practicesList: {
					...state!.practicesList!,
					listStatus: List2Status.Dirty,
				},
            }
 		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		default: const exhaustiveCheck: never = action;
	}
	return state;
}
