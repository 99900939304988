import * as React from 'react';
import { lazy, Suspense } from 'react';
import { Route } from 'react-router';
import Layout from '../app/Layout';
import * as Configuration from '../../Configuration';
import Loading from '../support/Loading';
import './custom.css';
import Home from './Home';
import { Page } from './Page';
import PrivateRoute from './PrivateRoute';
import UserDashboard from './UserDashboard';
import ViewPages from './ViewPages';

const AdminPages = lazy(() => import('../admin/AdminPages'));
const BookingPages = lazy(() => import('../booking/BookingPages'));
const InfoPages = lazy(() => import('../info/InfoPages'));
const LibraryPages = lazy(() => import('../library/LibraryPages'));
const MedicalPages = lazy(() => import('../medical/MedicalPages'));
const MessagePages = lazy(() => import('../message/MessagePages'));
const MonitorPages = lazy(() => import('../monitor/MonitorPages'));
const OrganisationPages = lazy(() => import('../organisation/OrganisationPages'));
const OverheadJoinPages = lazy(() => import('../overheadjoin/OverheadJoinPages'));
const PracticePages = lazy(() => import('../practice/PracticePages'));
const ReportPages = lazy(() => import('../report/ReportPages'));
const SpecialistPages = lazy(() => import('../specialist/SpecialistPages'));
const TestPages = lazy(() => import('../test/TestPages'));
const UserPages = lazy(() => import('../user/UserPages'));
const VideoPages = lazy(() => import('../video/VideoPages'));

export default () => (
    <Layout>
        <Route exact path='/' component={Home} />
        <PrivateRoute path='/dashboard' allowAll component={UserDashboard} />
        <Suspense fallback={<LoadingPage />}>
            <Route path='/admin' component={AdminPages} />
        </Suspense>
        {Configuration.enableFeature.bookings &&
            <Suspense fallback={<LoadingPage />}>
                <Route path='/booking' component={BookingPages} />
            </Suspense>
        }
        <Suspense fallback={<LoadingPage />}>
            <Route path='/info' component={InfoPages} />
        </Suspense>
        {Configuration.enableFeature.medicals &&
            <Suspense fallback={<LoadingPage />}>
                <Route path='/medical' component={MedicalPages} />
            </Suspense>
        }
        <Suspense fallback={<LoadingPage />}>
            <Route path='/message' component={MessagePages} />
        </Suspense>
        <Suspense fallback={<LoadingPage />}>
            <Route path='/library' component={LibraryPages} />
        </Suspense>
        <Suspense fallback={<LoadingPage />}>
            <PrivateRoute path='/monitor' adminOnly component={MonitorPages} />
        </Suspense>
        <Suspense fallback={<LoadingPage />}>
            <Route path='/organisation' component={OrganisationPages} />
        </Suspense>
        {Configuration.enableFeature.overheadJoin &&
            <Suspense fallback={<LoadingPage />}>
                <Route path='/overhead-join' component={OverheadJoinPages} />
            </Suspense>
        }
        {Configuration.enableFeature.medicals &&
            <Suspense fallback={<LoadingPage />}>
                <Route path='/practice' component={PracticePages} />
            </Suspense>
        }
        <Suspense fallback={<LoadingPage />}>
            <Route path='/report' component={ReportPages} />
        </Suspense>
        {Configuration.enableFeature.specialistPractices &&
            <Suspense fallback={<LoadingPage />}>
                <Route path='/specialist' component={SpecialistPages} />
            </Suspense>
        }
        <Suspense fallback={<LoadingPage />}>
            <Route path='/test' component={TestPages} />
        </Suspense>
        <Suspense fallback={<LoadingPage />}>
            <Route path='/user' component={UserPages} />
        </Suspense>
        <Suspense fallback={<LoadingPage />}>
            <Route path='/video' component={VideoPages} />
        </Suspense>
        <Suspense fallback={<LoadingPage />}>
            <Route path='/view' component={ViewPages} />
        </Suspense>
    </Layout>
);

const LoadingPage = () => (
    <Page defaultHelmet>
        <Loading />
    </Page>
);
