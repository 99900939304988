import { Action, Reducer } from "redux";
import { store } from "../..";
import { getDateTimeText } from "../../components/support/dateTimeSupport";
import { filledFormActionCreatorUtilities } from "../form/FilledFormStore";
import { FilledForm } from "../form/FormCommon";
import { postJson } from "../myfetch";
import { dispatchErrorToast, dispatchToast } from "../notification/NotificationStore";

// STATE //////////////////////////////////////////////////////////////////////

export interface UploadPilotResultState {
	isUploading?: boolean;
	isFinding?: boolean;
	result: PilotResultToUpload;
}

type Outcome = 'Negative' | 'Not negative';
type Format = 'Document' | 'Form';

export interface PilotResultToUpload {
	identityIsConfirmed?: boolean;
	consentIsObtained?: boolean;
	uploadCode?: string;
	format: Format;
	documentTypeId?: string;
	outcome?: Outcome;
}


// ACTIONS ////////////////////////////////////////////////////////////////////

interface SetPilotResultToUploadFieldAction { type: 'SET_PILOT_RESULT_TO_UPLOAD_FIELD'; name: string; value: any; isRequired: boolean; }
interface ResetPilotResultToUploadFieldsAction { type: 'RESET_PILOT_RESULT_TO_UPLOAD_FIELDS'; }

interface RequestUploadPilotResultAction { type: 'REQUEST_UPLOAD_PILOT_RESULT'; }
interface ReceiveUploadPilotResultAction { type: 'RECEIVE_UPLOAD_PILOT_RESULT'; isOk: boolean; }

type KnownAction =
	SetPilotResultToUploadFieldAction | ResetPilotResultToUploadFieldsAction
	| RequestUploadPilotResultAction | ReceiveUploadPilotResultAction
	;


// ACTION INVOKERS ////////////////////////////////////////////////////////////

export const uploadPilotResultActionInvokers = {
	setResultToUploadField: (name: string, value: any, isRequired: boolean) => store.dispatch({ type: 'SET_PILOT_RESULT_TO_UPLOAD_FIELD', name, value, isRequired, } as KnownAction),
	resetResultToUploadFields: () => store.dispatch({ type: 'RESET_PILOT_RESULT_TO_UPLOAD_FIELDS', } as KnownAction),

	requestUploadResult: async (userId: string, files?: FileList, filledForm?: FilledForm) => {
		try {
			store.dispatch({ type: 'REQUEST_UPLOAD_PILOT_RESULT', } as KnownAction);
			const originalResult = store.getState().organisation.uploadPilotResult!.result;
			const result = {
				...originalResult,
				documentTypeId: +originalResult.documentTypeId!,
				userId,
				dateTimeOfResult: files?.length ? getDateTimeText(new Date(files[0].lastModified)) : undefined,
				editedAnswers: !filledForm ? undefined : filledFormActionCreatorUtilities.getAnswersFor(filledForm),
			};
			let formData = new FormData();
			formData.append('details', JSON.stringify(result));
			if (files?.length)
				formData.append('result', files[0]);
			await postJson(`/api/documents/uploads`, formData)
			store.dispatch({ type: 'RECEIVE_UPLOAD_PILOT_RESULT', isOk: true, } as KnownAction);
			dispatchToast(true, 'success', 'Upload Result', 'The result has been uploaded.');
			return true;
		} catch (error) {
			store.dispatch({ type: 'RECEIVE_UPLOAD_PILOT_RESULT', isOk: false, } as KnownAction);
			dispatchErrorToast(true, 'Upload Result', error);
			return false;
		}
	},
};


// REDUCER ////////////////////////////////////////////////////////////////////

export const reducer: Reducer<UploadPilotResultState | null> = (state: UploadPilotResultState | undefined | null, incomingAction: Action) => {
	if (state === undefined || incomingAction.type === 'REQUEST_LOGOUT')
		return null;
	const action = incomingAction as KnownAction;
	switch (action.type) {
		case 'SET_PILOT_RESULT_TO_UPLOAD_FIELD':
			if (!state)
				state = { result: { format: 'Form', }, };
			return {
				...state,
				result: {
					...state.result,
					[action.name]: (action.value === '' && !action.isRequired) ? null : action.value,
				},
				isUploading: false,
			} as UploadPilotResultState;
		case 'RESET_PILOT_RESULT_TO_UPLOAD_FIELDS':
			return {
				...state,
				result: {
					...state!.result,
					identityIsConfirmed: undefined,
					consentIsObtained: undefined,
					outcome: undefined,
				},
			};
		case 'REQUEST_UPLOAD_PILOT_RESULT':
			return {
				...state!,
				isUploading: true,
			} as UploadPilotResultState;
		case 'RECEIVE_UPLOAD_PILOT_RESULT':
			return {
				...state!,
				isUploading: action.isOk ? undefined : false,
			} as UploadPilotResultState;
 		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		default: const exhaustiveCheck: never = action;
	}
	return state;
}
