import { Action, Reducer } from 'redux';
import { AppThunkAction } from '..';
import { ListCriteria, ListPage, ListState, ListStatus2 } from '../list/ListCommon';
import { criteriaMatches, findExistingPage, getCriteriaUrl } from '../listSupport';
import { deleteJson, getJson, handleWithAction, handleWithErrorToast, putJson } from '../myfetch';
import { dispatchToast } from '../notification/NotificationStore';
import { Video } from './VideoCommon';

// From SkyCert v1

// STATE **********************************************************************

const numberOfVideosPagesToKeep: number = 3;

export interface VideoListState extends ListState<Video> { }

export enum VideoListOrderBy {
	Role = 'Role',
	Category = 'Category',
	Name = 'Name',
	Sequence = 'Sequence',
	Uploaded = 'Uploaded',
	FileName = 'FileName',
}

export enum VideoListFilter1 {
	All = 'All',
	// The other filters are the (non-administrator) UserRole enumerations.
}


// ACTIONS ********************************************************************

interface RequestVideoListAction { type: 'REQUEST_VIDEO_LIST'; criteria: ListCriteria; }
interface ReceiveVideoListSuccessAction { type: 'RECEIVE_VIDEO_LIST_SUCCESS'; numberOfPages: number; totalNumberOfItems: number; page: ListPage<Video>; }
interface ReceiveVideoListFailureAction { type: 'RECEIVE_VIDEO_LIST_FAILURE'; }

interface ReceiveDeleteVideoSuccessAction { type: 'RECEIVE_DELETE_VIDEO_SUCCESS'; numberOfPages: number; totalNumberOfItems: number; page: ListPage<Video>; }

interface InvalidateVideoListAction { type: 'INVALIDATE_VIDEO_LIST'; }

interface ReceiveMoveVideoUpDownSuccessAction { type: 'RECEIVE_MOVE_VIDEO_UP_DOWN_SUCCESS'; numberOfPages: number; totalNumberOfItems: number; page: ListPage<Video>; }

type KnownAction = RequestVideoListAction | ReceiveVideoListSuccessAction | ReceiveVideoListFailureAction
	| ReceiveDeleteVideoSuccessAction
	| InvalidateVideoListAction
	| ReceiveMoveVideoUpDownSuccessAction
	;

// ACTION CREATORS ************************************************************

export const videoListActionCreators = {
	getVideoList: (criteria: ListCriteria): AppThunkAction<Action> => (dispatch, getState) => {
		const videoState = getState().video;
		if (videoState.videos && videoState.videos.listStatus !== ListStatus2.Idle) {
			const state: VideoListState = videoState.videos!;
			if (criteriaMatches(criteria, state.criteria)
				&& findExistingPage<Video>(state, criteria, 'RECEIVE_VIDEO_LIST_SUCCESS', dispatch))
				return;
		}
		// The requested page is not already loaded.
		const criteriaUrlSuffix: string = getCriteriaUrl(criteria);
		const url: string = `api/videos?${criteriaUrlSuffix}`;
		getJson(url)
			.then(response => {
				dispatch({ type: 'RECEIVE_VIDEO_LIST_SUCCESS', numberOfPages: response.numberOfPages, page: response.page, totalNumberOfItems: response.totalNumberOfItems, } as KnownAction);
			})
			.catch(handleWithAction('RECEIVE_VIDEO_LIST_FAILURE'));
		dispatch({ type: 'REQUEST_VIDEO_LIST', criteria, } as KnownAction);
	},

	deleteVideo: (video: Video, criteria: ListCriteria): AppThunkAction<Action> => (dispatch, getState) => {
		const criteriaUrlSuffix: string = getCriteriaUrl(criteria);
		deleteJson(`api/videos/${video.videoId}?${criteriaUrlSuffix}`)
			.then(response => {
				dispatch({ type: 'RECEIVE_DELETE_VIDEO_SUCCESS', numberOfPages: response.numberOfPages, page: response.page, totalNumberOfItems: response.totalNumberOfItems, } as KnownAction);
			})
			.catch(handleWithErrorToast('Video'));
	},

	invalidateVideoList: () => ({ type: 'INVALIDATE_VIDEO_LIST', } as KnownAction),

	moveVideoUpDown: (videoId: number, direction: string, criteria: ListCriteria): AppThunkAction<Action> => (dispatch, getState) => {
		const criteriaUrlSuffix: string = getCriteriaUrl(criteria);
		putJson(`api/videos/${videoId}/sequence?${criteriaUrlSuffix}`, { value: direction, })
			.then(response => {
				if (response.page) {
					dispatch({ type: 'RECEIVE_MOVE_VIDEO_UP_DOWN_SUCCESS', numberOfPages: response.numberOfPages, page: response.page, totalNumberOfItems: response.totalNumberOfItems, } as KnownAction);
					videoListActionCreators.getVideoList(getState().video.videos!.criteria)(dispatch, getState);
				} else {
					dispatchToast(true, 'primary', 'Video', response.message);
				}
			})
			.catch(handleWithErrorToast('Video'));
	},
};

// REDUCERS *******************************************************************

export const reducer: Reducer<VideoListState | null> = (state: VideoListState | null | undefined, action: KnownAction) => {
	if (state === undefined)
		return null;
	state = state!;	// @@@ I hope this works
	switch (action.type) {
		case 'REQUEST_VIDEO_LIST':
			const criteriaMatch: boolean = state && criteriaMatches(action.criteria, state.criteria);
			return {
				criteria: action.criteria,
				listStatus: ListStatus2.Loading,
				numberOfPages: criteriaMatch ? state.numberOfPages : 1,
				pages: criteriaMatch ? state.pages : [],
			};
		case 'RECEIVE_VIDEO_LIST_SUCCESS':
			let newPages: ListPage<Video>[] = [
				action.page,
				...state.pages.filter(page => page.pageNumber !== action.page.pageNumber),
			];
			while (newPages.length > numberOfVideosPagesToKeep) newPages.pop();
			return {
				...state,
				criteria: {
					...state.criteria,
					pageNumber: action.page.pageNumber,
				},
				listStatus: ListStatus2.Loaded,
				numberOfPages: action.numberOfPages,
				totalNumberOfItems: action.totalNumberOfItems,
				pages: newPages,
			};
		case 'RECEIVE_VIDEO_LIST_FAILURE':
			return {
				...state,
				listStatus: ListStatus2.Failure,
			};
		case 'RECEIVE_DELETE_VIDEO_SUCCESS':
		case 'RECEIVE_MOVE_VIDEO_UP_DOWN_SUCCESS':
			return {
				...state,
				numberOfPages: action.numberOfPages,
				totalNumberOfItems: action.totalNumberOfItems,
				pages: [action.page,],
			};
		case 'INVALIDATE_VIDEO_LIST':
			if (!state)
				return state;
			return {
				...state,
				listStatus: ListStatus2.Dirty,
				pages: [],
			}
		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		default: const exhaustiveCheck: never = action;
	}
	return state;
}
