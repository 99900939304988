import * as React from 'react';
import { useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { RouteChildrenProps } from 'react-router';
import { Col, FormFeedback, FormGroup, FormText, Input, InputGroup, InputGroupAddon, Row } from 'reactstrap';
import { getDateFromPeriod, getDateText, getDateTimeText, getPeriodFromDate, IntervalType, parseDate } from './dateTimeSupport';
import { FormField, FormLabel } from './FormFields';
import './TextEditors.css';


///////////////////////////////////////////////////////////////////////////////

export interface BasicTextEditorProps {
	type?: string;	// Defaults to 'text'
	id?: string;
	name?: string;
	value?: any;
	hidden?: boolean;
	readOnly?: boolean;
	required?: boolean;
	autoFocus?: boolean;
	placeholder?: string;
	prefix?: string;
	suffix?: string;
	pattern?: string;
	error?: boolean;
	errorText?: string;
	feedback?: string;	// success, warning, danger
	controlClassName?: string;
	autoComplete?: string;
	maxLength?: number;
	attr?: any;
	onChangeField?: (name: string, value: any, isRequired: boolean) => void;
	onChangeValue?: (value: string) => void;
	onChangeFieldRaw?: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

export const BasicTextEditor = (props: BasicTextEditorProps) => {
	if (props.hidden)
		return null;
	const isOk = props.readOnly
		|| (
			!props.error
			&& (!props.required || props.value)
			&& (!props.pattern || !props.value || props.value.toString().search(new RegExp('^' + props.pattern + '$')) === 0)
			&& (!props.maxLength || !props.value || (props.value as string).length <= props.maxLength)
		);
	const controlClassName = (props.controlClassName ?? '')
		+ (props.feedback ? ' feedback-' + props.feedback : '');
	const pattern = props.pattern || (isOk ? undefined : props.value + '#@#');
	return (
		<>
			<OptionalTextInputGroup prefix={props.prefix} suffix={props.suffix}>
				<Input type={props.type ?? 'text'} id={props.id}
					name={props.name}
					value={props.value ?? ''}
					disabled={props.readOnly}
					required={props.required}
					invalid={!isOk}
					autoFocus={props.autoFocus}
					placeholder={props.readOnly ? '' : props.placeholder}
					onChange={e => changeTextField(e, props)}
					className={controlClassName}
					autoComplete={props.autoComplete}
					maxLength={props.maxLength}
					{...props.attr}
					pattern={pattern}
				/>
			</OptionalTextInputGroup>
			{!isOk && !props.readOnly &&
				<FormFeedback invalid='true'>* {props.errorText ?? 'Please complete this field'}</FormFeedback>
			}
			{props.maxLength && (
				<FormText color='muted'>{props.value?.length || 0} / {props.maxLength} characters</FormText>
			)}
		</>
	);
}

///////////////////////////////////////////////////////////////////////////////

export interface TextEditorProps {
	type?: string;	// Defaults to 'text'
	id?: string;
	name?: string;
	label: string;
	value?: any;
	additionalText?: string;
	hidden?: boolean;
	readOnly?: boolean;
	required?: boolean;
	horizontal?: boolean | number;
	autoFocus?: boolean;
	noLabelWrap?: boolean;
	placeholder?: string;
	prefix?: string;
	suffix?: string;
	pattern?: string;
	error?: boolean;
	errorText?: string;
	feedback?: string;	// success, warning, danger
	className?: string;
	controlClassName?: string;
	autoComplete?: string;
	maxLength?: number;
	attr?: any;
	onChangeField?: (name: string, value: any, isRequired: boolean) => void;
	onChangeValue?: (value: string) => void;
	onChangeFieldRaw?: (event: React.ChangeEvent<HTMLInputElement>) => void;
	children?: ((props: RouteChildrenProps<any>) => React.ReactNode) | React.ReactNode;
}

export const TextEditor = (props: TextEditorProps) => {
	if (props.hidden)
		return null;
	return (
		<FormGroup row={!!props.horizontal} className={props.className}>
			{props.label !== ' ' &&
				<FormLabel for={props.id} horizontal={props.horizontal} noWrap={props.noLabelWrap}>{props.label}</FormLabel>
			}
			<FormField horizontal={props.horizontal}>
				<BasicTextEditor {...props} />
				{props.additionalText &&
					<FormText color='muted'>{props.additionalText}</FormText>
				}
				{props.children}
			</FormField>
		</FormGroup>
	);
}

const changeTextField = (event: React.ChangeEvent<HTMLInputElement>, props: BasicTextEditorProps) => {
	const target = event.target;
	props.onChangeField?.(props.name!, target.value, target.required);
	props.onChangeValue?.(target.value);
	props.onChangeFieldRaw?.(event);
}

const OptionalTextInputGroup = (props: any) => (
	props.prefix || props.suffix
		? <InputGroup>
			{props.prefix &&
				<InputGroupAddon addonType='prepend'>{props.prefix}</InputGroupAddon>
			}
			{props.children}
			{props.suffix &&
				<InputGroupAddon addonType='append'>{props.suffix}</InputGroupAddon>
			}
		</InputGroup>
		: <>{props.children}</>
)

///////////////////////////////////////////////////////////////////////////////

// TextEditorProps defaults for NumberEditor
// type: 'text' (not 'number')
// pattern: '\d*(\.\d*)?'

export const NumberEditor = (props: TextEditorProps) => (
	<TextEditor
		type='text'
		{...props}
		pattern={props.pattern || '\\d*(\\.\\d*)?'}
	>
		{props.children}
	</TextEditor>
)

// TextEditorProps defaults for EmailEditor
// type: 'email'
// pattern: '[0-9a-zA-Z+_.-]+@[0-9a-zA-Z.-]+'

export const EmailEditor = (props: TextEditorProps) => (
	<TextEditor
		type='text'
		{...props}
		pattern={props.pattern || '[0-9a-zA-Z+_.-]+@[0-9a-zA-Z.-]+'}
	>
		{props.children}
	</TextEditor>
)

// TextEditorProps defaults for TimeEditor and TimeSpanEditor
// type: 'text' (not 'time')
// placeholder: 'hh:mm'
// pattern: '(?:0?\\d|1\\d|2[0123]):[0-5]\\d' and '\\d{1,2}:[0-5]\\d'

export const TimeEditor = (props: TextEditorProps) => (
	<TextEditor
		type='text'
		{...props}
		placeholder={props.placeholder || 'hh:mm'}
		pattern={props.pattern || '(?:0?\\d|1\\d|2[0123]):[0-5]\\d'}
	>
		{props.children}
	</TextEditor>
)

export const TimeSpanEditor = (props: TextEditorProps) => (
	<TextEditor
		type='text'
		{...props}
		placeholder={props.placeholder || 'hh:mm'}
		pattern={props.pattern || '\\d{1,2}:[0-5]\\d'}
	>
		{props.children}
	</TextEditor>
);

// TextEditorProps defaults for PasswordEditor
// type: 'password'
// placeholder: 'Password'

export const PasswordEditor = (props: TextEditorProps) => (
	<TextEditor
		type='password'
		placeholder='Password'
		{...props}
	>
		{props.children}
	</TextEditor>
)

///////////////////////////////////////////////////////////////////////////////

export interface DateTimeEditorProps {
	id?: string;
	name?: string;
	label: string;
	value?: any;
	additionalText?: string;
	hidden?: boolean;
	readOnly?: boolean;
	required?: boolean;
	horizontal?: boolean | number;
	autoFocus?: boolean;
	noLabelWrap?: boolean;
	placeholder?: string;
	error?: boolean;
	errorText?: string;
	feedback?: string;	// success, warning, danger
	className?: string;
	controlClassName?: string;
	attr?: any;
	onChangeField?: (name: string, value: any, isRequired: boolean) => void;
	onChangeValue?: (value: string) => void;
	onChangeFieldRaw?: (event: React.FocusEvent<HTMLInputElement>) => void;
	children?: ((props: RouteChildrenProps<any>) => React.ReactNode) | React.ReactNode;
}
interface DateTimeEditorWrapperProps extends DateTimeEditorProps {
	renderDatePicker: (input: JSX.Element) => JSX.Element;
}

const BasicDateTimeEditor = (props: DateTimeEditorWrapperProps) => {
	if (props.hidden)
		return null;
	const isOk = props.readOnly
		|| (
			!props.error
			&& !(props.required && !props.value)
		);
	const controlClassName = (props.controlClassName ?? '')
		+ (props.feedback ? ' feedback-' + props.feedback : '');
	const pattern = isOk ? undefined : props.value + '#@#';
	const input = (
		<Input type='text' id={props.id}
			name={props.name}
			value={props.value ?? ''}
			disabled={props.readOnly}
			required={props.required}
			invalid={!isOk}
			autoFocus={props.autoFocus}
			className={controlClassName}
			{...props.attr}
			pattern={pattern}
		/>
	);
	const haveLabel = props.label !== ' ';
	return (
		<FormGroup row={!!props.horizontal} className={props.className}>
			{haveLabel &&
				<FormLabel for={props.id} horizontal={props.horizontal} noWrap={props.noLabelWrap}>{props.label}</FormLabel>
			}
			<FormField horizontal={props.horizontal} addBreak={haveLabel}>
				{props.renderDatePicker(input)}
				{!isOk && !props.readOnly &&
					<FormFeedback invalid='true'>* {props.errorText ?? 'Please complete this field'}</FormFeedback>
				}
				{props.additionalText &&
					<FormText color='muted'>{props.additionalText}</FormText>
				}
				{props.children}
			</FormField>
		</FormGroup>
	);
}

const ChangeDateTimeField = (date: string, props: DateTimeEditorProps) => {
	props.onChangeField?.(props.name!, date, !!props.required);
	props.onChangeValue?.(date);
}

// TODO @@@ set local='en-NZ'
export const DateEditor = (props: DateTimeEditorProps) => (
	<BasicDateTimeEditor
		{...props}
		renderDatePicker={input => (
			<DatePicker
				selected={props.value ? new Date(props.value) : undefined}
				dateFormat={['dd MMM yyyy', 'd MMMM yy', 'd MMMM yyyy', 'd MMM yy', 'd MMM yyyy', 'd/M/yy', 'd/M/yyyy', 'd M yy', 'd M yyyy']}
				onChange={date => ChangeDateTimeField(getDateText(date as Date), props)}
				onChangeRaw={props.onChangeFieldRaw}
				customInput={input}
				showMonthDropdown
				showYearDropdown
				required={props.required}
				dropdownMode='select'
				readOnly={props.readOnly}
				placeholderText={props.readOnly ? '' : (props.placeholder ?? props.label)}
			/>
		)}
	/>
);

export const DateTimeEditor = (props: DateTimeEditorProps) => (
	<BasicDateTimeEditor
		{...props}
		renderDatePicker={input => (
			<DatePicker
				selected={props.value ? new Date(props.value) : undefined}
				dateFormat={['dd MMM yyyy HH:mm', 'd MMMM yy H:mm', 'd MMMM yyyy H:mm', 'd MMM yy H:mm', 'd MMM yyyy H:mm', 'd/M/yy H:mm', 'd/M/yyyy H:mm', 'd M yy H:mm', 'd M yyyy H:mm']}
				timeFormat='HH:mm'
				onChange={date => ChangeDateTimeField(getDateTimeText(date as Date), props)}
				onChangeRaw={props.onChangeFieldRaw}
				customInput={input}
				showMonthDropdown
				showYearDropdown
				showTimeSelect
				required={props.required}
				timeIntervals={5}
				dropdownMode='select'
				readOnly={props.readOnly}
				placeholderText={props.readOnly ? '' : (props.placeholder ?? props.label)}
			/>
		)}
	/>
);

//export const TimeEditor = (props: DateTimeEditorProps) => (
//	<DateTimeEditorWrapper
//		{...props}
//		renderDatePicker={input => (
//			<DatePicker
//				selected={props.value ? new Date(props.value) : undefined}
//				dateFormat='HH:mm'
//				onChange={date => ChangeDateTimeField(getTimeText24Hm(date as Date), props)}
//				onChangeRaw={props.onChangeFieldRaw}
//				customInput={input}
//				showTimeSelect
//				showTimeSelectOnly
//				readOnly={props.readOnly}
//				placeholderText={props.readOnly ? '' : (props.placeholder ?? props.label)}
//			/>
//		)}
//	/>
//);

///////////////////////////////////////////////////////////////////////////////

export interface TextAreaEditorProps {
	id?: string;
	name?: string;
	label: string;
	value?: any;
	additionalText?: string;
	hidden?: boolean;
	readOnly?: boolean;
	required?: boolean;
	horizontal?: boolean | number;
	placeholder?: string;
	rows?: number;	// Defaults to 5
	pattern?: string;
	error?: boolean;
	errorText?: string;
	feedback?: string;	// success, warning, danger
	className?: string;
	controlClassName?: string;
	maxLength?: number;
	attr?: any;
	onChangeField?: (name: string, value: any, isRequired: boolean) => void;
	onChangeValue?: (value: string) => void;
	onChangeFieldRaw?: (event: React.ChangeEvent<HTMLInputElement>) => void;
	children?: ((props: RouteChildrenProps<any>) => React.ReactNode) | React.ReactNode;
}

export const TextAreaEditor = (props: TextAreaEditorProps) => {
	if (props.hidden)
		return null;
	const isOk = props.readOnly
		|| (
			!props.error
			&& (!props.required || props.value)
			&& (!props.pattern || !props.value || props.value.toString().search(new RegExp('^' + props.pattern + '$')) === 0)
			&& (!props.maxLength || !props.value || (props.value as string).length <= props.maxLength)
		);
	const controlClassName = (props.controlClassName ?? '')
		+ (isOk ? '' : ' is-invalid')
		+ (props.feedback ? ' feedback-' + props.feedback : '');
	const pattern = props.pattern || (isOk ? undefined : props.value + '#@#');
	return (
		<FormGroup row={!!props.horizontal} className={props.className}>
			<FormLabel for={props.id} horizontal={props.horizontal}>{props.label}</FormLabel>
			<FormField horizontal={props.horizontal}>
				<Input type='textarea' id={props.id}
					name={props.name}
					value={props.value ?? ''}
					disabled={props.readOnly}
					required={props.required}
					placeholder={props.readOnly ? '' : (props.placeholder ?? props.label)}
					onChange={e => changeTextAreaField(e, props)}
					rows={props.rows ?? 2}
					className={controlClassName}
					maxLength={props.maxLength}
					{...props.attr}
					innerRef={loadTextAreaField}
					pattern={pattern}
				/>
				{!isOk && !props.readOnly &&
					<FormFeedback invalid='true'>* {props.errorText ?? 'Please complete this field'}</FormFeedback>
				}
				{!props.additionalText ? null
					: <p className='text-muted'>{props.additionalText}</p>
				}
				{props.children}
			</FormField>
		</FormGroup>
	);
}

const loadTextAreaField = (textArea: HTMLTextAreaElement | undefined) => {
	if (textArea)
		textArea.style.height = (textArea.scrollHeight + 5).toString() + 'px';
}

const changeTextAreaField = (event: React.ChangeEvent<HTMLInputElement>, props: TextAreaEditorProps) => {
	const target = event.target;
	if (target.scrollHeight > target.clientHeight) {
		target.style.height = (target.scrollHeight + 5).toString() + 'px';
	}
	props.onChangeField?.(props.name!, target.value, target.required);
	props.onChangeValue?.(target.value);
	props.onChangeFieldRaw?.(event);
}

///////////////////////////////////////////////////////////////////////////////

export interface SetPasswordEditorProps {
	id?: string;
	hidden?: boolean;
	horizontal?: boolean | number;
	onChangePassword: (password: string) => void;
	children?: ((props: RouteChildrenProps<any>) => React.ReactNode) | React.ReactNode;
}

export const SetPasswordEditor = (props: SetPasswordEditorProps) => {
	const [password1, setPassword1] = useState('');
	const [password2, setPassword2] = useState('');
	if (props.hidden)
		return null;
	const isOk1 = password1 && password1.length >= 8;
	const isOk2 = password1 && password1 === password2;
	return <>
		<TextEditor type='password' id={props.id ? props.id + '-1' : undefined}
			label='New password'
			value={password1}
			required
			horizontal={props.horizontal}
			error={!isOk1}
			errorText='The password must be at least 8 characters long, containing some uppercase letters, some lowercase letters, and some numbers.'
			onChangeValue={p => { setPassword1(p); changePasswordField(p, password2, props); }}
			attr={{ autoComplete: 'new-password' }}
		/>
		<TextEditor type='password' id={props.id ? props.id + '-2' : undefined}
			label='Confirm password'
			value={password2}
			required
			horizontal={props.horizontal}
			error={!isOk2}
			errorText='The passwords do not match.'
			onChangeValue={p => { setPassword2(p); changePasswordField(password1, p, props); }}
			attr={{ autoComplete: 'new-password' }}
		/>
	</>;
}

const changePasswordField = (password1: string, password2: string, props: SetPasswordEditorProps) => {
	const isOk = password1 && password1.length >= 8 && password1 === password2;
	props.onChangePassword(isOk ? password1.trim() : '');
}

///////////////////////////////////////////////////////////////////////////////

export interface IntervalDateEditorProps {
	id?: string;
	name?: string;
	label: string;
	value?: any;	// Either date|startFromDate or date (startFromDate defaults to today)
	additionalText?: string;
	hidden?: boolean;
	readOnly?: boolean;
	required?: boolean;
	horizontal?: boolean | number;
	errorText?: string;
	className?: string;
	period: IntervalType;
	onChangeField?: (name: string, value: any, isRequired: boolean) => void;
	onChangeValue?: (value: string) => void;
	children?: ((props: RouteChildrenProps<any>) => React.ReactNode) | React.ReactNode;
}

const now = new Date();
const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());

export const IntervalDateEditor = (props: IntervalDateEditorProps) => {
	const [startFrom, setStartFrom] = useState({ date: today, text: '', });
	const [date, setDate] = useState('');

	useEffect(() => {
		const value: string = props.value || '';
		const breakPos = value ? value.indexOf('|') : -1;
		const startFromDate = breakPos === -1 ? today : parseDate(value.substr(breakPos + 1))!;
		const startFromText = getDateText(startFromDate);
		setDate(breakPos === -1 ? value : value.substr(0, breakPos));
		setStartFrom({ date: startFromDate, text: startFromText, });
    // eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.value]);

	const save = (newExpiry: string) => {
		const value = newExpiry + '|' + startFrom.text;
		setDate(newExpiry);
		props.onChangeValue?.(value);
		props.onChangeField?.(props.name!, value, !!props.required);
	}

	if (props.hidden)
		return null;
	const isOk = !props.required || parseDate(date) !== null;
	return (
		<FormGroup row={!!props.horizontal} className={props.className}>
			<FormLabel for={props.id} horizontal={props.horizontal}>{props.label}</FormLabel>
			<FormField horizontal={props.horizontal}>
				<IntervalFieldsRow
					id={props.id}
					name={props.name}
					startFromDate={startFrom.date}
					startFromText={startFrom.text}
					period={props.period}
					value={date}
					onChangeValue={save}
					required={props.required}
					readOnly={props.readOnly}
				/>
				{!isOk && !props.readOnly &&
					<FormText color='danger'>* {props.errorText ?? 'Please complete this field'}</FormText>
				}
				{props.additionalText &&
					<FormText color='muted'>{props.additionalText}</FormText>
				}
				{props.children}
			</FormField>
		</FormGroup>
	);
}


///////////////////////////////////////////////////////////////////////////////

export interface IntervalFieldsRowProps {
	id?: string;
	name?: string;
	startFromDate: Date;
	startFromText: string;
	period: IntervalType;
	value: string;
	readOnly?: boolean;
	required?: boolean;
	controlClassName?: string;
	placeholder?: string;
	attr?: any;
	colXs?: number;	// Defaults to 6
	onChangeField?: (name: string, value: any, isRequired: boolean) => void;
	onChangeValue?: (value: string) => void;
	children?: ((props: RouteChildrenProps<any>) => React.ReactNode) | React.ReactNode;
}

export const IntervalFieldsRow = (props: IntervalFieldsRowProps) => {
	const [state, setState] = useState({ period: '', dateText: '', dateValue: null as Date | null, });

	useEffect(() => {
		if (props.value) {
			const dateText = props.value;
			const dateValue = parseDate(props.value);
			const period = getPeriodFromDate(props.startFromDate, props.period, dateValue);
			if (period !== state.period && period + '.' !== state.period)
				setState({ period, dateText, dateValue, });
		}
    // eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.value, props.startFromDate]);

	const changePeriod = (period: string) => {
		const dateValue = getDateFromPeriod(props.startFromDate, props.period, parseFloat(period));
		const dateText = dateValue ? getDateText(dateValue) : '';
		setState({ period, dateText, dateValue, });
		props.onChangeField?.(props.name!, dateText, !!props.required);
		props.onChangeValue?.(dateText);
	}

	const changeDate = (dateValue: Date | null) => {
		const dateText = dateValue ? getDateText(dateValue) : '';
		props.onChangeField?.(props.name!, dateText, !!props.required);
		props.onChangeValue?.(dateText);
	}

	const isOk = !props.required || parseDate(props.value) !== null;
	return (
		<Row>
			<Col xs={props.colXs ?? 6}>
				<NumberEditor
					id={props.id ? props.id + '-period' : undefined}
					label=' '
					additionalText={props.period + ' from ' + props.startFromText}
					value={state.period}
					disabled={props.readOnly}
					required={props.required}
					invalid={!isOk}
					placeholder={props.readOnly ? '' : props.period}
					onChangeValue={changePeriod}
					className={props.controlClassName}
					{...props.attr}
				/>
			</Col>
			<Col xs={props.colXs ?? 6}>
				<BasicDateTimeEditor
					label=' '
					renderDatePicker={input => (
						<DatePicker
							id={props.id}
							selected={state.dateValue}
							dateFormat={['dd MMM yyyy', 'd MMMM yy', 'd MMMM yyyy', 'd MMM yy', 'd MMM yyyy', 'd/M/yy', 'd/M/yyyy', 'd M yy', 'd M yyyy']}
							onChange={changeDate}
							customInput={input}
							showMonthDropdown
							showYearDropdown
							required={props.required}
							dropdownMode='select'
							readOnly={props.readOnly}
							placeholderText={props.readOnly ? '' : (props.placeholder ?? 'expiry')}
							{...props.attr}
						/>
					)}
				/>
			</Col>
			{props.children}
		</Row>
	);
}
