import * as React from 'react';
import { useSelector } from 'react-redux';
import { Redirect, Route, RouteChildrenProps, RouteComponentProps } from 'react-router';
import { ApplicationState } from '../../store';
import { LoginStatus, RequiredActionFlags } from '../../store/user/LoginStore';
import { UserRole } from '../../store/user/UserCommon';
import AdministratorTermsAndConditions from '../info/termsandconditions/AdministratorTermsAndConditions';
import FlyingOrganisationStaffTermsAndConditions from '../info/termsandconditions/FlyingOrganisationStaffTermsAndConditions';
import MedicalExaminerTermsAndConditions from '../info/termsandconditions/MedicalExaminerTermsAndConditions';
import PilotTermsAndConditions from '../info/termsandconditions/PilotTermsAndConditions';
import RegulatoryAuthorityAgentTermsAndConditions from '../info/termsandconditions/RegulatoryAuthorityAgentTermsAndConditions';
import SpecialistTermsAndConditions from '../info/termsandconditions/SpecialistTermsAndConditions';
import ChangePassword from '../user/ChangePassword';
import SecurityQuestions from '../user/SecurityQuestions';
import TwoFactor from '../user/TwoFactor';
import { Login } from './Login';
import { Page } from './Page';

interface PrivateRouteProps {
    allowAll?: boolean;
    adminOnly?: boolean;
    roles?: UserRole[];
    component: React.ComponentType<RouteComponentProps<any>> | React.ComponentType<any>;
    children?: ((props: RouteChildrenProps<any>) => React.ReactNode) | React.ReactNode;
    [key: string]: any;
}

export default ({ allowAll, adminOnly, roles, component: Component, children, ...rest }: PrivateRouteProps) => {
    const login = useSelector(state => (state as ApplicationState).user.login);
    return (
        <Route {...rest} render={props => {
            const checkForActions = (login.loggedIn?.actions.actionsRequested || 0)
                | (login.loggedIn?.actions.actionsRequired || 0);

            if ((checkForActions & RequiredActionFlags.AcceptTermsAndConditions) !== 0) {
                const termsAndConditions = getTermsAndConditionsForRole(login.loggedIn!.role);
                if (termsAndConditions)
                    return termsAndConditions;
            }

            if ((checkForActions & RequiredActionFlags.ConfigureSecurityQuestions) !== 0) {
                return <SecurityQuestions />;
            } else if ((checkForActions & RequiredActionFlags.ConfigureTwoFactorAuthentication) !== 0) {
                return <TwoFactor />;
            } else if ((checkForActions & RequiredActionFlags.ChangePassword) !== 0) {
                return <ChangePassword />;
            } else if (login.status !== LoginStatus.LoggedIn || isPermittedRole(login.loggedIn!.role, allowAll, adminOnly, roles)) {
                return (
                    <>
                        {login.status !== LoginStatus.LoggedIn &&
                            <Login />
                        }
                        {login.status === LoginStatus.LoggedIn || login.isTimeout
                            ? (
                                <Component {...props}>
                                    {children}
                                </Component>
                            )
                            : <Page />
                        }
                    </>
                );
            } else if (login.credentials.role === UserRole.Administrator) {
                return <Redirect to='/admin/dashboard' />;
            } else {
                return <Redirect to='/dashboard' />;
            }
        }} />
    );
}

const isPermittedRole = (role: string, allowAll?: boolean, adminOnly?: boolean, requiredRoles?: string[]) => {
    if (allowAll || role === UserRole.Administrator)
        return true;
    if (adminOnly)
        return false;
    if (!requiredRoles || requiredRoles.length === 0)
        return true;
    for (var iRole in requiredRoles) {
        if (role === requiredRoles[iRole])
            return true;
    }
    return false;
}

const getTermsAndConditionsForRole = (role: string) => {
    switch (role) {
        case UserRole.Administrator: return <AdministratorTermsAndConditions />;
        case UserRole.FlyingOrganisationStaff: return <FlyingOrganisationStaffTermsAndConditions />;
        case UserRole.MedicalExaminer: return <MedicalExaminerTermsAndConditions />;
        case UserRole.Nurse: return <MedicalExaminerTermsAndConditions />;
        case UserRole.Pilot: return <PilotTermsAndConditions />;
        case UserRole.RegulatoryAuthorityAgent: return <RegulatoryAuthorityAgentTermsAndConditions />;
        case UserRole.Specialist: return <SpecialistTermsAndConditions />;
        case UserRole.SpecialistStaff: return <SpecialistTermsAndConditions />;
        //default: return undefined;
    }
    console.log('The terms and conditions have not been provided for the role ' + role);
    return undefined;
}
