import * as React from 'react';
import { useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { CardBody } from 'reactstrap';
import { ApplicationState } from '../../store';
import { UserRole } from '../../store/user/UserCommon';
import { StandardSummarySize } from '../support/Summaries';
import { View } from '../support/Views';
import * as Configuration from '../../Configuration';

export default () => {
	const loggedIn = useSelector(state => (state as ApplicationState).user.login.loggedIn);
	const history = useHistory();

	if (!loggedIn)
		return null;

	const goToReports = () => {
		if (loggedIn.role === UserRole.Pilot)
			history.push('/report/user-metrics/me');
		else history.push('/report/all');
	}

	return (
		<StandardSummarySize>
			<View header='Reports' onClickHeader={goToReports}>
				<CardBody>
					{Configuration.enableFeature.myMetrics &&
						<div><Link to='/report/user-metrics/me'>My metrics</Link></div>
					}
					{loggedIn.role === UserRole.MedicalExaminer || loggedIn.role === UserRole.Nurse
						? <div><Link to='/practice/pilots'>Select a pilot</Link></div>
						: loggedIn.role === UserRole.Administrator
							? <div><Link to='/practice/all'>Select practice then pilot</Link></div>
							: null
					}
					{loggedIn.role !== UserRole.Pilot &&
						<div className='mt-3'><Link to='/report/all'>All reports</Link></div>
					}
				</CardBody>
			</View>
		</StandardSummarySize>
	);
}
