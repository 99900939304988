// From SkyCert v1

export const removeInvalidOptions = (currentOptions: string | undefined, validOptions: string | undefined) => {
	if (!currentOptions || !validOptions) return undefined;
	const allValidOptions: string[] = validOptions.split('|');
	const allCurrentOptions: string[] = currentOptions.split('|');
	let options: string | undefined = undefined;
	for (var option of allCurrentOptions) {
		// eslint-disable-next-line no-loop-func
		if (!!allValidOptions.find(opt => opt === option)) {
			if (options)
				options += '|';
			else options = '';
			options += option;
		}
	}
	return options;
}
