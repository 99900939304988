import { Action, Reducer } from 'redux';
import { AppThunkAction } from '..';
import { getJson, handleWithActionAndErrorToast } from '../myfetch';
import { LoadStatus2 } from '../StoreCommon';
import { Invoice } from './PracticeCommon';

// From SkyCert v1

// STATE **********************************************************************

export interface SelectedInvoiceState {
	status: LoadStatus2;
	invoice?: Invoice;
}

// ACTIONS ********************************************************************

interface RequestLoadInvoiceAction { type: 'REQUEST_LOAD_INVOICE'; invoiceId: string }
interface ReceiveLoadInvoiceAction { type: 'RECEIVE_LOAD_INVOICE'; invoice?: Invoice; }

type KnownAction = RequestLoadInvoiceAction | ReceiveLoadInvoiceAction
	;

// ACTION CREATORS ************************************************************

export const selectedInvoiceActionCreators = {
	requestLoadInvoice: (medicalPracticeId: number, invoiceId: number): AppThunkAction<Action> => (dispatch, getState) => {
		getJson(`api/practices/${medicalPracticeId}/invoices/${invoiceId}`)
			.then(response => {
				dispatch({ type: 'RECEIVE_LOAD_INVOICE', invoice: response.invoice, } as KnownAction);
			})
			.catch(handleWithActionAndErrorToast('Invoice', 'RECEIVE_LOAD_INVOICE'));
		dispatch({ type: 'REQUEST_LOAD_INVOICE' } as KnownAction);
	},
};

// REDUCERS *******************************************************************

export const reducer: Reducer<SelectedInvoiceState | null> = (state: SelectedInvoiceState | null | undefined, action: KnownAction) => {
	if (state === undefined)
		return null;
	state = state!;	// @@@ I hope this works
	switch (action.type) {
		case 'REQUEST_LOAD_INVOICE':
			return {
				status: LoadStatus2.Loading,
			}
		case 'RECEIVE_LOAD_INVOICE':
			return {
				status: !!action.invoice ? LoadStatus2.Loaded : LoadStatus2.Failure,
				invoice: action.invoice,
			};
		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		default: const exhaustiveCheck: never = action;
	}
	return state;
}
