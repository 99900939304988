import { Action, Reducer } from "redux";
import { store } from "../..";

// From SkyCert v1

const AutoHideTimeout = 15000;
const AutoClearTimeout = 150;
let lastToastId: number = 0;

export interface NotificationState {
	toasts: NotificationToast[];
}

export interface NotificationToast {
	toastId: number;
	icon?: string;
	title: string;
	details: (string | JSX.Element)[];
	autoHide: boolean;
	isClosed?: boolean;
}

const initialState: NotificationState = {
	toasts: [],
}

interface AddNotificationToastAction { type: 'ADD_NOTIFICATION_TOAST'; toast: NotificationToast; }
interface CloseNotificationToastAction { type: 'CLOSE_NOTIFICATION_TOAST'; toastId: number; }
interface CloseNotificationAllToastsAction { type: 'CLOSE_NOTIFICATION_ALL_TOASTS'; }
interface ClearNotificationToastAction { type: 'CLEAR_NOTIFICATION_TOAST'; toastId: number; }
interface ClearNotificationAllToastsAction { type: 'CLEAR_NOTIFICATION_ALL_TOASTS'; }

type KnownAction =
	AddNotificationToastAction
	| CloseNotificationToastAction | CloseNotificationAllToastsAction
	| ClearNotificationToastAction | ClearNotificationAllToastsAction
	;

const notificationActionCreators = {
	addToast: (toastId: number, autoHide: boolean, icon: string | undefined, title: string, ...details: (string | JSX.Element)[]) => ({ type: 'ADD_NOTIFICATION_TOAST', toast: { toastId, icon, title, details, autoHide, }, } as KnownAction),
	closeToast: (toastId: number) => ({ type: 'CLOSE_NOTIFICATION_TOAST', toastId, } as KnownAction),
	closeAllToasts: () => ({ type: 'CLOSE_NOTIFICATION_ALL_TOASTS', } as KnownAction),
	clearToast: (toastId: number) => ({ type: 'CLEAR_NOTIFICATION_TOAST', toastId, } as KnownAction),
	clearAllToasts: () => ({ type: 'CLEAR_NOTIFICATION_ALL_TOASTS', } as KnownAction),
};

export const dispatchToast = (autoHide: boolean | number, icon: string | undefined, title: string, ...details: (string | JSX.Element)[]) => {
	// TODO @@@ Remove this check when the full conversion to v2 is complete
	// and JsonDataResult and Result2 are gone
	if (details.length === 0 || (details.length === 1 && !details[0])) {
		if (icon === 'danger')
			details = ['Failure.', 'Today is not your day.'];
		else details = ['Success.',];
	}
	const toastId = ++lastToastId;
	store.dispatch(notificationActionCreators.addToast(toastId, !!autoHide, icon, title, ...details));
	if (autoHide) {
		if (autoHide === true)
			autoHide = AutoHideTimeout;
		else autoHide *= 1000;
		setTimeout(() => dispatchCloseToast(toastId), autoHide);
	}
};

export const dispatchErrorToast = (autoHide: boolean | number, title: string, error: any) => {
	// TODO @@@ error.message should be obsolete once a full conversion to v2 is done
	let details = [error.title || error.message,];
	if (error.detail) {
		if (typeof error.detail === 'string') {
			details.push(error.detail);
		} else {
			for (let i = 0; i < error.detail.length; i++) {
				details.push(error.detail[i]);
			}
		}
	}
	if (error.errors) {
		for (let f in error.errors) {
			if (f !== 'dto') {
				let prefix = f ? f + ': ' : '';
				if (prefix.substr(0, 2) === '$.')
					prefix = prefix.substr(2);
				// eslint-disable-next-line no-loop-func
				error.errors[f].forEach((e: string) => {
					details.push(prefix + e);
					//details.push(e);
				});
			}
		}
	}
	dispatchToast(autoHide, 'danger', title, ...details);
};

export const dispatchCloseToast = (toastId: number) => {
	store.dispatch(notificationActionCreators.closeToast(toastId));
	setTimeout(() => { store.dispatch(notificationActionCreators.clearToast(toastId)); },
		AutoClearTimeout);
};

export const dispatchCloseAllToasts = () => {
	store.dispatch(notificationActionCreators.closeAllToasts());
	setTimeout(() => { store.dispatch(notificationActionCreators.clearAllToasts()); },
		AutoClearTimeout);
};

export const reducer: Reducer<NotificationState> = (state: NotificationState | undefined, incomingAction: Action): NotificationState => {
	if (state === undefined || incomingAction.type === 'REQUEST_LOGOUT')
		return initialState;
	const action: KnownAction = incomingAction as KnownAction;
	switch (action.type) {
		case 'ADD_NOTIFICATION_TOAST':
			return {
				toasts: [...state.toasts, action.toast,],
			};
		case 'CLOSE_NOTIFICATION_TOAST':
			return {
				toasts: state.toasts.map(t => t.toastId === action.toastId
					? { ...t, isClosed: true, }
					: t
				),
			}
		case 'CLOSE_NOTIFICATION_ALL_TOASTS':
			return {
				toasts: state.toasts.map(t => { return { ...t, isClosed: true, }; }),
			};
		case 'CLEAR_NOTIFICATION_TOAST':
			return {
				toasts: state.toasts.filter(t => t.toastId !== action.toastId),
			}
		case 'CLEAR_NOTIFICATION_ALL_TOASTS':
			return {
				toasts: [],
			};
		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		default: const exhaustiveCheck: never = action;
	}
	return state;
}
