import { Action, combineReducers, Reducer } from 'redux';
import * as DocumentLibraryStore from './DocumentLibraryStore';

// STATE **********************************************************************

export interface LibraryState {
	documentLibrary: DocumentLibraryStore.DocumentLibraryState | null;
}

// REDUCERS *******************************************************************

const combinedReducer: Reducer<LibraryState> = combineReducers<LibraryState>({
	documentLibrary: DocumentLibraryStore.reducer,
});

export const reducer: Reducer<LibraryState> = (state: LibraryState | undefined, action: Action) => {
	if (action.type === 'REQUEST_LOGOUT')
		state = undefined;
	return combinedReducer(state, action);
}
