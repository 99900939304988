import { Action, Reducer } from 'redux';
import { AppThunkAction } from '..';
import { deleteJson, getJson, handleWithActionAndErrorToast, postJson, putJson } from '../myfetch';
import { LoadStatus2 } from '../StoreCommon';

// From SkyCert v1

// STATE **********************************************************************

export interface AppointmentListState {
	medicalPracticeId?: number;
	practiceLocationId: string;
	selectedDate: Date;
	status: LoadStatus2;
	examinerLocationAppointments: ExaminerLocationAppointmentsState[];
}

export interface ExaminerLocationAppointmentsState {
	examinerName?: string;
	medicalExaminerUserId?: string;
	locationName: string;
	practiceLocationId: number;
	externalBookingMessage?: string;
	internalBookingMessage?: string;
	appointments: ExaminerLocationAppointmentState[];
}
export interface ExaminerLocationAppointmentState {
	appointmentId: number;
	startTime: string;
	nurseFinishTime?: string;
	finishTime: string;
	isBlocked?: boolean;
	isLocked?: boolean;
	lockReason?: string;
	examinerName?: string;
	medicalExaminerUserId?: string;
	jurisdictions?: string;
	pilotDetails?: AppointmentPilotState;
}
export interface AppointmentPilotState {
	pilotName: string;
	pilotUserId: string;
	regulatoryAuthorityId?: string;
	phoneNumber: string;
	emailAddress: string;
	flyingOrganisation?: string;
	initialOrRenewal: string;
	age: number;
	dateOfBirth: string;
	classesAppliedFor: string;
	jurisdictionsAppliedFor: string;
	isBookingStar: boolean;
	testsDue: TestDueState[];
	isApplicationSigned: boolean;
	receiptNumber: string;
	notes: string;
	medicalId?: number;
	notesDocumentTypeId?: number;
	notesFormId?: number;
}
export interface TestDueState {
	name: string;
	isDone: boolean;
}

// ACTIONS ********************************************************************

interface InvalidateAppointmentListAction { type: 'INVALIDATE_APPOINTMENT_LIST'; }

interface RequestAppointmentListAction { type: 'REQUEST_APPOINTMENT_LIST'; medicalPracticeId?: number; practiceLocationId: string; selectedDate: Date; }
interface ReceiveAppointmentListSuccessAction { type: 'RECEIVE_APPOINTMENT_LIST_SUCCESS'; medicalPracticeId?: number; practiceLocationId: string; selectedDate: Date; examinerLocationAppointments: ExaminerLocationAppointmentsState[]; }
interface ReceiveAppointmentListFailureAction { type: 'RECEIVE_APPOINTMENT_LIST_FAILURE'; }

interface RequestUpdateAppointmentAction { type: 'REQUEST_UPDATE_APPOINTMENT'; }
interface ReceiveUpdateApplicationAction { type: 'RECEIVE_UPDATE_APPOINTMENT'; examinerLocationAppointments?: ExaminerLocationAppointmentsState[]; }

type KnownAction = InvalidateAppointmentListAction
	| RequestAppointmentListAction | ReceiveAppointmentListSuccessAction | ReceiveAppointmentListFailureAction
	| RequestUpdateAppointmentAction | ReceiveUpdateApplicationAction
	;

// ACTION CREATORS ************************************************************

export const appointmentListActionCreators = {
	invalidateAppointmentList: () => ({ type: 'INVALIDATE_APPOINTMENT_LIST', } as KnownAction),

	getAppointments: (medicalPracticeId: number | undefined, practiceLocationId: string, selectedDate: Date, asPilot: boolean): AppThunkAction<Action> => (dispatch, getState) => {
		if (typeof selectedDate === 'string')
			selectedDate = new Date(selectedDate);
		const dateMonth: number = selectedDate.getMonth() + 1;
		const dateDay: number = selectedDate.getDate();
		const dateText: string = `${selectedDate.getFullYear()}-${dateMonth < 10 ? '0' : ''}${dateMonth}-${dateDay < 10 ? '0' : ''}${dateDay}`;;
		// TODO @@@ Check if the appointments are already loaded?
		const medicalPracticeSuffix: string = !medicalPracticeId ? '' : `&medicalPracticeId=${medicalPracticeId}`;
		const practiceLocationSuffix: string = practiceLocationId === '' ? '' : `&practiceLocationId=${practiceLocationId}`;
		getJson(`api/appointment?date=${dateText}&asPilot=${asPilot}${medicalPracticeSuffix}${practiceLocationSuffix}`)
			.then(response => {
				dispatch({ type: 'RECEIVE_APPOINTMENT_LIST_SUCCESS', medicalPracticeId, practiceLocationId, selectedDate, examinerLocationAppointments: response.examinerLocationAppointments, } as KnownAction);
			})
			.catch(handleWithActionAndErrorToast('Appointments', 'RECEIVE_APPOINTMENT_LIST_FAILURE'));
		dispatch({ type: 'REQUEST_APPOINTMENT_LIST', medicalPracticeId, practiceLocationId, selectedDate, } as KnownAction);
	},

	newAppointment: (medicalExaminerUserId: string, practiceLocationId: number, date: string, startTime: string, nurseFinishTime: string | undefined, finishTime: string, listMedicalPracticeId: number | undefined, listPracticeLocationId: string, selectedDate: Date): AppThunkAction<Action> => (dispatch, getState) => {
		const dateMonth: number = selectedDate.getMonth() + 1;
		const dateDay: number = selectedDate.getDate();
		const dateText: string = `${selectedDate.getFullYear()}-${dateMonth < 10 ? '0' : ''}${dateMonth}-${dateDay < 10 ? '0' : ''}${dateDay}`;;
		postJson(`api/appointment?medicalPracticeId=${listMedicalPracticeId || 0}&practiceLocationId=${listPracticeLocationId || 0}&date=${dateText}`, { medicalExaminerUserId, practiceLocationId, date, startTime, nurseFinishTime, finishTime, isBlocked: true, })
			.then(response => {
				dispatch({ type: 'RECEIVE_UPDATE_APPOINTMENT', examinerLocationAppointments: response.examinerLocationAppointments, } as KnownAction);
			})
			.catch(handleWithActionAndErrorToast('Appointment', 'RECEIVE_UPDATE_APPOINTMENT'));
		dispatch({ type: 'REQUEST_UPDATE_APPOINTMENT', } as KnownAction);
	},

	updateAppointment: (appointmentId: number, medicalExaminerUserId: string, practiceLocationId: number, date: string, startTime: string, nurseFinishTime: string | undefined, finishTime: string, isBlocked: boolean, isLocked: boolean, lockReason: string | undefined, listMedicalPracticeId: number | undefined, listPracticeLocationId: string, selectedDate: Date): AppThunkAction<Action> => (dispatch, getState) => {
		const dateMonth: number = selectedDate.getMonth() + 1;
		const dateDay: number = selectedDate.getDate();
		const dateText: string = `${selectedDate.getFullYear()}-${dateMonth < 10 ? '0' : ''}${dateMonth}-${dateDay < 10 ? '0' : ''}${dateDay}`;;
		putJson(`api/appointments/${appointmentId}?medicalPracticeId=${listMedicalPracticeId || 0}&practiceLocationId=${listPracticeLocationId || 0}&date=${dateText}`, { medicalExaminerUserId, practiceLocationId, date, startTime, nurseFinishTime, finishTime, isBlocked, isLocked, lockReason, })
			.then(response => {
				dispatch({ type: 'RECEIVE_UPDATE_APPOINTMENT', examinerLocationAppointments: response.examinerLocationAppointments, } as KnownAction);
			})
			.catch(handleWithActionAndErrorToast('Appointment', 'RECEIVE_UPDATE_APPOINTMENT'));
		dispatch({ type: 'REQUEST_UPDATE_APPOINTMENT', } as KnownAction);
	},

	deleteAppointment: (appointmentId: number, listMedicalPracticeId: number | undefined, listPracticeLocationId: string, selectedDate: Date): AppThunkAction<Action> => (dispatch, getState) => {
		const dateMonth: number = selectedDate.getMonth() + 1;
		const dateDay: number = selectedDate.getDate();
		const dateText: string = `${selectedDate.getFullYear()}-${dateMonth < 10 ? '0' : ''}${dateMonth}-${dateDay < 10 ? '0' : ''}${dateDay}`;;
		deleteJson(`api/appointments/${appointmentId}?medicalPracticeId=${listMedicalPracticeId || 0}&practiceLocationId=${listPracticeLocationId || 0}&date=${dateText}`)
			.then(response => {
				dispatch({ type: 'RECEIVE_UPDATE_APPOINTMENT', examinerLocationAppointments: response.examinerLocationAppointments, } as KnownAction);
			})
			.catch(handleWithActionAndErrorToast('Appointment', 'RECEIVE_UPDATE_APPOINTMENT'));
		dispatch({ type: 'REQUEST_UPDATE_APPOINTMENT', } as KnownAction);
	},

	cancelBooking: (appointmentId: number, listMedicalPracticeId: number | undefined, listPracticeLocationId: string, selectedDate: Date, asPilot: boolean): AppThunkAction<Action> => (dispatch, getState) => {
		const dateMonth: number = selectedDate.getMonth() + 1;
		const dateDay: number = selectedDate.getDate();
		const dateText: string = `${selectedDate.getFullYear()}-${dateMonth < 10 ? '0' : ''}${dateMonth}-${dateDay < 10 ? '0' : ''}${dateDay}`;;
		deleteJson(`api/appointments/${appointmentId}/booking?medicalPracticeId=${listMedicalPracticeId || 0}&practiceLocationId=${listPracticeLocationId || 0}&date=${dateText}&asPilot=${asPilot}`)
			.then(response => {
				dispatch({ type: 'RECEIVE_UPDATE_APPOINTMENT', examinerLocationAppointments: response.examinerLocationAppointments, } as KnownAction);
			})
			.catch(handleWithActionAndErrorToast('Booking', 'RECEIVE_UPDATE_APPOINTMENT'));
		dispatch({ type: 'REQUEST_UPDATE_APPOINTMENT', } as KnownAction);
	},
};

// REDUCERS *******************************************************************

export const reducer: Reducer<AppointmentListState | null> = (state: AppointmentListState | null | undefined, action: KnownAction) => {
	if (state === undefined)
		return null;
	state = state!;	// @@@ I hope this works
	switch (action.type) {
		case 'INVALIDATE_APPOINTMENT_LIST':
			return {
				...state,
				status: LoadStatus2.Idle,
			};
		case 'REQUEST_APPOINTMENT_LIST':
			return {
				medicalPracticeId: action.medicalPracticeId,
				practiceLocationId: action.practiceLocationId,
				selectedDate: action.selectedDate,
				status: LoadStatus2.Loading,
				examinerLocationAppointments: [],
			};
		case 'RECEIVE_APPOINTMENT_LIST_SUCCESS':
			return {
				...state,
				medicalPracticeId: action.medicalPracticeId,
				practiceLocationId: action.practiceLocationId,
				selectedDate: action.selectedDate,
				status: LoadStatus2.Loaded,
				examinerLocationAppointments: action.examinerLocationAppointments,
			}
		case 'RECEIVE_APPOINTMENT_LIST_FAILURE':
			return {
				...state,
				status: LoadStatus2.Failure,
				examinerLocationAppointments: [],
			};
		case 'REQUEST_UPDATE_APPOINTMENT':
			return {
				...state,
				status: LoadStatus2.Saving,
			};
		case 'RECEIVE_UPDATE_APPOINTMENT':
			return {
				...state,
				status: !action.examinerLocationAppointments ? LoadStatus2.Failure : LoadStatus2.Loaded,
				examinerLocationAppointments: action.examinerLocationAppointments || state.examinerLocationAppointments,
			};
		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		default: const exhaustiveCheck: never = action;
	}

	return state;
};
