import * as React from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { CardBody } from 'reactstrap';
import { ApplicationState } from '../../store';
import { SummaryLoadStatus2 } from '../../store/summary/SummaryCommon';
import * as UserSummaryStore from '../../store/summary/UserSummaryStore';
import Loading from '../support/Loading';
import { StandardSummarySize } from '../support/Summaries';
import { View } from '../support/Views';

// From SkyCert v1

type PilotBookingSummaryProps =
	UserSummaryStore.PilotBookingState
	& typeof UserSummaryStore.userSummaryActionCreators
	& RouteComponentProps<{}>;

// Architecture: v2

class PilotBookingSummary extends React.Component<PilotBookingSummaryProps, {}> {
	// eslint-disable-next-line @typescript-eslint/no-useless-constructor
	constructor(props: any) {
		super(props);
	}

	componentDidMount() {
		this.props.requestPilotBookingSummary();
    }

	public render() {
		const mode: string = !this.props.bookingDate || this.props.daysToBooking! > 7
			? 'primary'
			: this.props.daysToBooking! > 1 ? 'warning' : 'danger';
		return <StandardSummarySize>
			<View color={mode} header='Bookings' onClickHeader={this.goToBooking}>
				<CardBody>
					{this.renderBody()}
				</CardBody>
            </View>
		</StandardSummarySize>;
	}

	goToBooking = () => {
		this.props.history.push('/booking/pilot/me');
	}

	private renderBody() {
		if (this.props.status === SummaryLoadStatus2.Loading) {
			return <Loading />;
		} else if (this.props.status === SummaryLoadStatus2.Loaded) {
			return <>
				{!this.props.bookingDate ? <p>No booking.</p>
					: <p>
						{this.props.bookingTime}&nbsp;&nbsp;{this.props.bookingDate}.<br />
						{this.props.daysToBooking! === 0 ? 'Today' : this.props.daysToBooking! === 1 ? 'Tomorrow' : this.props.daysToBooking! + ' days time'}.
					</p>
				}
				<p><Link to='/booking/pilot/me'>Medical appointment</Link></p>
			</>;
		} else if (this.props.status === SummaryLoadStatus2.Failure) {
			return <p className='text-danger'>Unable to load.</p>;
		} else {	// SummaryLoadStatus2.Idle, blank
			return null;	// This should only ever occur briefly.
		}
	}
}

export default connect(
	(state: ApplicationState) => state.summary.pilotBooking || {},
	UserSummaryStore.userSummaryActionCreators
)(PilotBookingSummary) /*as typeof PilotBookingSummary*/;
