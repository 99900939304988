import { Action, Reducer } from 'redux';
import { AppThunkAction } from '..';
import { getJson, handleWithActionAndErrorToast } from '../myfetch';
import { LoadStatus2 } from '../StoreCommon';

// From SkyCert v1

// STATE **********************************************************************

export interface BookingDatesState {
	medicalPracticeId?: number;
	practiceLocationId: string;
	firstMonth: Date;
	status: LoadStatus2;
	dates: BookingDayState[];
}
export interface BookingDayState {
	date: string;
	numberOfAppointments: number;
	numberOfBookings: number;
	isExternalBookingDay: boolean;
}

// ACTIONS ********************************************************************

interface InvalidateBookingDatesListAction { type: 'INVALIDATE_BOOKING_DATES_LIST'; }

interface RequestBookingDatesAction { type: 'REQUEST_BOOKING_DATES'; medicalPracticeId?: number; practiceLocationId: string; firstMonth: Date; }
interface ReceiveBookingDatesSuccessAction { type: 'RECEIVE_BOOKING_DATES_SUCCESS'; medicalPracticeId?: number; practiceLocationId: string; firstMonth: Date; dates: BookingDayState[]; }
interface ReceiveBookingDatesFailureAction { type: 'RECEIVE_BOOKING_DATES_FAILURE'; }

type KnownAction = InvalidateBookingDatesListAction
	| RequestBookingDatesAction | ReceiveBookingDatesSuccessAction | ReceiveBookingDatesFailureAction
	;

// ACTION CREATORS ************************************************************

export const bookingDatesActionCreators = {
	invalidateBookingDatesList: () => ({ type: 'INVALIDATE_BOOKING_DATES_LIST', } as KnownAction),

	// medicalPracticeId = undefined for all, 0 for profile default, n for selected practice
	getBookingDates: (medicalPracticeId: number | undefined, practiceLocationId: string, firstMonth: Date): AppThunkAction<Action> => (dispatch, getState) => {
		if (typeof firstMonth === 'string')
			firstMonth = new Date(firstMonth);
		firstMonth = new Date(firstMonth.getFullYear(), firstMonth.getMonth(), 1);
		const medicalPracticeSuffix: string = !medicalPracticeId ? '' : `&medicalPracticeId=${medicalPracticeId}`;
		const practiceLocationSuffix: string = practiceLocationId === '' ? '' : `&practiceLocationId=${practiceLocationId}`;
		getJson(`api/appointments/dates?numberOfMonths=3&month=${firstMonth.getMonth() + 1}&year=${firstMonth.getFullYear()}${medicalPracticeSuffix}${practiceLocationSuffix}`)
			.then(response => {
				dispatch({ type: 'RECEIVE_BOOKING_DATES_SUCCESS', medicalPracticeId, practiceLocationId, firstMonth, dates: response.dates, } as KnownAction);
			})
			.catch(handleWithActionAndErrorToast('Booking Dates', 'RECEIVE_BOOKING_DATES_FAILURE'));
		dispatch({ type: 'REQUEST_BOOKING_DATES', medicalPracticeId, practiceLocationId, firstMonth, } as KnownAction);
	},
};

// REDUCERS *******************************************************************

export const reducer: Reducer<BookingDatesState | null> = (state: BookingDatesState | null | undefined, action: KnownAction) => {
	if (state === undefined)
		return null;
	state = state!;	// @@@ I hope this works
	switch (action.type) {
		case 'INVALIDATE_BOOKING_DATES_LIST':
			return {
				...state,
				status: LoadStatus2.Idle,
			};
		case 'REQUEST_BOOKING_DATES':
			return {
				medicalPracticeId: action.medicalPracticeId,
				practiceLocationId: action.practiceLocationId,
				firstMonth: action.firstMonth,
				status: LoadStatus2.Loading,
				dates: [],
				};
		case 'RECEIVE_BOOKING_DATES_SUCCESS':
			return {
				...state,
				medicalPracticeId: action.medicalPracticeId,
				practiceLocationId: action.practiceLocationId,
				firstMonth: action.firstMonth,
				status: LoadStatus2.Loaded,
				dates: action.dates,
			};
		case 'RECEIVE_BOOKING_DATES_FAILURE':
			return {
				...state,
				status: LoadStatus2.Failure,
				dates: [],
			};
		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		default: const exhaustiveCheck: never = action;
	}

	return state;
};
