import { Action, combineReducers, Reducer } from 'redux';
import * as ParentPilotListStore from '../list/ParentPilotListStore';
import * as InvoiceListStore from './InvoiceListStore';
import * as PracticeListStore from './PracticeListStore';
import * as SelectedInvoiceStore from './SelectedInvoiceStore';
import * as SelectedPracticeStore from './SelectedPracticeStore';

// From SkyCert v1

// STATE **********************************************************************

export interface PracticeState {
	selectedPractice: SelectedPracticeStore.SelectedPracticeState | null;
	list: PracticeListStore.PracticeListState | null;
	listOfPracticePilots: ParentPilotListStore.ParentPilotListState | null;
	listOfInvoices: InvoiceListStore.InvoiceListState | null;
	selectedInvoice: SelectedInvoiceStore.SelectedInvoiceState | null;
}

// ACTION CREATORS ************************************************************

export const listOfPracticePilotsActionCreators = ParentPilotListStore.parentPilotListActionCreators('PRACTICE', 'practices', 'practice', 'listOfPracticePilots');

// REDUCERS *******************************************************************

const combinedReducer: Reducer<PracticeState> = combineReducers<PracticeState>({
	selectedPractice: SelectedPracticeStore.reducer,
	list: PracticeListStore.reducer,
	listOfPracticePilots: ParentPilotListStore.parentPilotListReducer('PRACTICE'),
	listOfInvoices: InvoiceListStore.reducer,
	selectedInvoice: SelectedInvoiceStore.reducer,
})

export const reducer: Reducer<PracticeState> = (state: PracticeState | undefined, action: Action) => {
	if (action.type === 'REQUEST_LOGOUT')
		state = undefined;
	return combinedReducer(state, action);
}
