import * as React from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { CardBody } from 'reactstrap';
import { ApplicationState } from '../../store';
import { SummaryLoadStatus2 } from '../../store/summary/SummaryCommon';
import * as UserSummaryStore from '../../store/summary/UserSummaryStore';
import Loading from '../support/Loading';
import { StandardSummarySize } from '../support/Summaries';
import { View } from '../support/Views';

// From SkyCert v1

type AllMedicalsSummaryProps =
	UserSummaryStore.MedicalsState
	& typeof UserSummaryStore.userSummaryActionCreators
	& RouteComponentProps<{}>;

// Architecture: v2

class AllMedicalsSummary extends React.Component<AllMedicalsSummaryProps, {}> {
	// eslint-disable-next-line @typescript-eslint/no-useless-constructor
	constructor(props: any) {
		super(props);
	}

	componentDidMount() {
		this.props.requestMedicalsSummary();
    }

	public render() {
		const mode = this.props.numberOfUnassignedHl7LabResults ? 'danger' : 'success';
		return <StandardSummarySize>
			<View color={mode} header='Medicals' onClickHeader={this.goToMedicals}>
				<CardBody>
					{this.renderBody()}
				</CardBody>
            </View>
		</StandardSummarySize>;
	}

	goToMedicals = () => {
		this.props.history.push('/medical/all');
	}

	private renderBody() {
		if (this.props.status === SummaryLoadStatus2.Loading) {
			return <Loading />;
		} else if (this.props.status === SummaryLoadStatus2.Loaded) {
			return (
				<>
					<p>
						{this.props.numberOfMedicals && <>{this.props.numberOfMedicals} medicals.<br /></>}
						{this.props.numberOfMedicalsBeingProcessed} processing.<br />
						{this.props.numberOfUnsignedMedicals} unsigned.
					</p>
					{!!this.props.numberOfUnassignedHl7LabResults &&
						<p>{this.props.numberOfUnassignedHl7LabResults} unassigned HL7 results.</p>
					}
					<Link to='/medical/all'>View medicals</Link>
					{!!this.props.numberOfUnassignedHl7LabResults &&
						<>
							<br />
							<Link to='/medical/all-unassigned-hl7-results'>Unassigned HL7 results</Link>
						</>
					}
				</>
			);
		} else if (this.props.status === SummaryLoadStatus2.Failure) {
			return <p className='text-danger'>Unable to load.</p>;
		} else {	// SummaryLoadStatus2.Idle, blank
			return null;	// This should only ever occur briefly.
		}
	}
}

export default connect(
	(state: ApplicationState) => state.summary.medicals || {},
	UserSummaryStore.userSummaryActionCreators
)(AllMedicalsSummary) /*as typeof AllMedicalsSummary*/;
