import * as React from 'react';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { CardBody } from 'reactstrap';
import { ApplicationState } from '../../store';
import { SummaryLoadStatus2 } from '../../store/summary/SummaryCommon';
import { UserMessagesSummaryState, userSummaryActionInvokers } from '../../store/summary/UserSummaryStore';
import { UserRole } from '../../store/user/UserCommon';
import Loading from '../support/Loading';
import NewFeature from '../support/NewFeature';
import { StandardSummarySize } from '../support/Summaries';
import { View } from '../support/Views';

export default () => {
	const messages = useSelector(state => (state as ApplicationState).summary.userMessages);
	const loggedIn = useSelector(state => (state as ApplicationState).user.login.loggedIn);
	const history = useHistory();

	useEffect(() => {
		userSummaryActionInvokers.requestUserMessages();
	}, []);

	const goToMessages = () => {
		history.push('/message/me');
	}

	const groupName = loggedIn?.role === UserRole.Administrator ? 'administrator'
		: (loggedIn?.role === UserRole.MedicalExaminer || loggedIn?.role === UserRole.Nurse) ? 'practice'
		: loggedIn?.role === UserRole.FlyingOrganisationStaff ? 'organisation'
		: loggedIn?.role === UserRole.RegulatoryAuthorityAgent ? 'authority'
		: null;

	return (
		<StandardSummarySize>
			<View header='Secure Messaging' onClickHeader={goToMessages} color={!messages?.summary.numberOfMyUnreadMessages && !messages?.summary.numberOfGroupUnreadMessages ? 'primary' : 'danger'}>
				<CardBody>
					<NewFeature id='UserMessages' endDate='1 Nov 2021'
						description={<><p><strong>Secure messaging between SkyCert users is now available.</strong></p><p>If you are logged in when the message is sent, you will see a pop-up notification that you have received a message.</p><p>Otherwise you may receive an email informing you that you have outstanding messages.</p></>}
					>
						<SummaryBody summary={messages} groupName={groupName} />
						<p className='mb-0'>
							<Link to='/message/me'>View messages</Link>
						</p>
					</NewFeature>
				</CardBody>
			</View>
		</StandardSummarySize>
	);
}


interface SummaryBodyProps {
	summary: UserMessagesSummaryState | null;
	groupName: string | null;
}

const SummaryBody = (props: SummaryBodyProps) => {
	if (!props.summary || props.summary.status === SummaryLoadStatus2.Idle) {
		return null;	// This should only ever occur briefly.
	} else if (props.summary.status === SummaryLoadStatus2.Loading) {
		return <Loading />;
	} else if (props.summary.status === SummaryLoadStatus2.Loaded) {
		return (
			<>
				<p>
					{props.summary.summary.numberOfMyMessages} messages.<br />
					{props.summary.summary.numberOfMyUnreadMessages} unread.
				</p>
				{!!props.groupName &&
					<p>
						{props.summary.summary.numberOfGroupMessages} {props.groupName} messages.<br />
						{props.summary.summary.numberOfGroupUnreadMessages} unread.
					</p>
				}
			</>
		);
	} else if (props.summary.status === SummaryLoadStatus2.Failure) {
		return <p className='text-danger'>Unable to load.</p>;
	} else return null;
}
