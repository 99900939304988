import { Action, Reducer } from 'redux';
import { store } from '../..';
import { List2Criteria, List2State, List2Status } from '../list/ListCommon';
import { getCriteriaUrl2 } from '../listSupport';
import { medicalListActionInvokers } from '../medical/MedicalListStore';
import { getJson, postJson, putJson } from '../myfetch';
import { dispatchErrorToast, dispatchToast } from '../notification/NotificationStore';

// STATE **********************************************************************

export const Categories = ['Cardiovascular', 'Diabetes', 'Drug/Alcohol', 'ENT', 'Gastrointestinal', 'Gynaecology', 'Haematology', 'Medication', 'Musculoskeletal', 'Neurology', 'Oncology', 'Opthalmology', 'Pregnancy', 'Psychiatric', 'Renal/Urology', 'Respiratory', 'Other',];
export const CategoryIds = ['Cardiovascular', 'Diabetes', 'Drug Alcohol', 'ENT', 'Gastrointestinal', 'Gynaecology', 'Haematology', 'Medication', 'Musculoskeletal', 'Neurology', 'Oncology', 'Opthalmology', 'Pregnancy', 'Psychiatric', 'Renal Urology', 'Respiratory', 'Other',];
export const Significances = ['Important', 'Suspension/Disqualification', 'AMC', 'To be reviewed by ME', 'To be reviewed by authority', 'Exclude from medical PDFs', 'Pass (negative)', 'Fail (not negative)', 'Self test'];
export const SignificanceIds = ['Important', 'Suspension Disqualification', 'AMC', 'To be reviewed by ME', 'To be reviewed by authority', 'Exclude from medical PDFs', 'Negative', 'Not negative', 'Self test'];

export interface DocumentLibraryState {
	list?: List2State<LibraryDocument>;
	pilotUserId?: string;
	selectedLibraryDocument?: LibraryDocument;
	isSaving?: boolean;
}

export interface LibraryDocument {
	documentId: string;
	medicalId?: number;
	typeName: string;
	documentType: string;
	documentTypeId?: string;
	alternativeName?: string;
	lastUpdate: string;
	category: string;
	significance: string;
	isLink?: string;
	finalisedBy?: string;
}

export enum DocumentLibraryListOrderBy {
	Type = 'Type',
	Name = 'Name',
	Alternative = 'Alternative',
	LastUpdate = 'LastUpdate',
}

export enum DocumentLibraryListFilter1 {
	All = 'All',
	Medicals = 'Medicals',
	Forms = 'Forms',
	Documents = 'Documents',
}

// ACTIONS ********************************************************************

interface RequestDocumentLibraryAction { type: 'REQUEST_DOCUMENT_LIBRARY'; criteria: List2Criteria; pilotUserId: string; }
interface ReceiveDocumentLibrarySuccessAction { type: 'RECEIVE_DOCUMENT_LIBRARY_SUCCESS'; numberOfPages: number, totalNumberOfItems: number; items: LibraryDocument[]; }
interface ReceiveDocumentLibraryFailureAction { type: 'RECEIVE_DOCUMENT_LIBRARY_FAILURE'; }

interface InvalidateDocumentLibraryAction { type: 'INVALIDATE_DOCUMENT_LIBRARY'; }

interface SelectLibraryDocumentAction { type: 'SELECT_LIBRARY_DOCUMENT'; pilotUserId?: string; document?: LibraryDocument; }
interface UpdateSelectedLibraryDocumentFieldAction { type: 'UPDATE_SELECTED_LIBRARY_DOCUMENT_FIELD'; name: string; value: any; isRequired: boolean; }

interface RequestSaveSelectedLibraryDocumentAction { type: 'REQUEST_SAVE_SELECTED_LIBRARY_DOCUMENT'; }
interface ReceiveSaveSelectedLibraryDocumentAction { type: 'RECEIVE_SAVE_SELECTED_LIBRARY_DOCUMENT'; document?: LibraryDocument; }

type KnownAction = RequestDocumentLibraryAction | ReceiveDocumentLibrarySuccessAction | ReceiveDocumentLibraryFailureAction
	| InvalidateDocumentLibraryAction
	| SelectLibraryDocumentAction | UpdateSelectedLibraryDocumentFieldAction
	| RequestSaveSelectedLibraryDocumentAction | ReceiveSaveSelectedLibraryDocumentAction
	;

// Action Invokers ************************************************************

export const documentLibraryActionInvokers = {
	requestDocumentLibrary: async (criteria: List2Criteria, pilotUserId: string) => {
		store.dispatch({ type: 'REQUEST_DOCUMENT_LIBRARY', criteria, pilotUserId, } as KnownAction);
		try {
			const response = await getJson(`/api/users/${pilotUserId}/documents?${getCriteriaUrl2(criteria)}`);
			store.dispatch({ type: 'RECEIVE_DOCUMENT_LIBRARY_SUCCESS', numberOfPages: response.numberOfPages, totalNumberOfItems: response.totalNumberOfItems, items: response.items, } as KnownAction);
		} catch (error) {
			store.dispatch({ type: 'RECEIVE_DOCUMENT_LIBRARY_FAILURE', } as KnownAction);
			dispatchErrorToast(true, 'Document Library', error);
		}
	},

	requestSaveSelectedLibraryDocument: async () => {
		store.dispatch({ type: 'REQUEST_SAVE_SELECTED_LIBRARY_DOCUMENT', } as KnownAction);
		try {
			const state = store.getState().library.documentLibrary;
			let category = state!.selectedLibraryDocument!.category;
			if (!category)
				category = 'None';
			else category = category.replace(/\|/g, ',');
			let significance = state!.selectedLibraryDocument!.significance;
			if (!significance)
				significance = 'None';
			else significance = significance.replace(/\|/g, ',');
			const document = {
				...state!.selectedLibraryDocument,
				category,
				significance,
			}
			const response = await putJson(`/api/users/${state!.pilotUserId}/documents/${state!.selectedLibraryDocument!.documentId}`, document);
			store.dispatch({ type: 'RECEIVE_SAVE_SELECTED_LIBRARY_DOCUMENT', document: response.document, });
			medicalListActionInvokers.invalidateCurrentMedicalsPage();
		} catch (error) {
			store.dispatch({ type: 'RECEIVE_SAVE_SELECTED_LIBRARY_DOCUMENT', });
			dispatchErrorToast(true, 'DocumentLibrary', error);
		}
	},

	invalidateDocumentLibrary: () => { store.dispatch({ type: 'INVALIDATE_DOCUMENT_LIBRARY', } as KnownAction); },

	selectLibraryDocument: (pilotUserId?: string, document?: LibraryDocument) => { store.dispatch({ type: 'SELECT_LIBRARY_DOCUMENT', pilotUserId, document, } as KnownAction); },
	updateSelectedLibraryDocumentField: (name: string, value: any, isRequired: boolean) => { store.dispatch({ type: 'UPDATE_SELECTED_LIBRARY_DOCUMENT_FIELD', name, value, isRequired, } as KnownAction); },

	requestAddDocument: async (userId: string, documentTypeId: number, alternativeName: string | undefined, files: FileList, password: string | undefined) => {
		let formData = new FormData();
		formData.append('details', JSON.stringify({ documentTypeId, alternativeName, password, }));
		var { document, support, } = DocumentLibrarySupport.getFiles(files);
		if (!document) {
			dispatchToast(true, 'danger', 'Upload Document', 'Please select one and only one document to upload.');
			return;
		}
		formData.append('document', document);
		if (support)
			formData.append('support', support);
		try {
			const response = await postJson(`api/documents?userId=${userId}`, formData);
			dispatchToast(true, 'success', 'Upload Document', response.message);
			documentLibraryActionInvokers.invalidateDocumentLibrary();
		} catch (error) {
			dispatchErrorToast(true, 'Upload Document', error);
		}
	},
}


// SUPPORT ********************************************************************

const DocumentLibrarySupport = {
	getFiles: (files: FileList) => {
		if (files.length === 1)
			return { document: files[0], support: undefined, };
		else if (files.length !== 2)
			return {};

		// This code makes the assumption that multiple files involve a .csv file
		var document: File | undefined = undefined;
		var support: File | undefined = undefined;
		for (let iFile = 0; iFile < files.length; iFile++) {
			const file = files[iFile];
			const isCsv = file.name.match(/.csv/i);
			if (isCsv) {
				if (document)
					return {};
				document = file;
			}
			else {
				if (support)
					return {};
				support = file;
			}
		}
		return { document, support, };
	},
}


// REDUCERS *******************************************************************

export const reducer: Reducer<DocumentLibraryState | null> = (state: DocumentLibraryState | undefined | null, incomingAction: Action) => {
	if (state === undefined)
		return null;
	const action = incomingAction as KnownAction;
	switch (action.type) {
		case 'REQUEST_DOCUMENT_LIBRARY':
			return {
				...state,
				list: {
					criteria: action.criteria,
					listStatus: List2Status.Loading,
					numberOfPages: 0,
					totalNumberOfItems: undefined,
					items: [],
				},
				pilotUserId: action.pilotUserId,
				selectedLibraryDocument: undefined,
			};
		case 'RECEIVE_DOCUMENT_LIBRARY_SUCCESS':
			return {
				...state,
				list: {
					criteria: state!.list!.criteria,
					listStatus: List2Status.Loaded,
					numberOfPages: action.numberOfPages,
					totalNumberOfItems: action.totalNumberOfItems,
					items: action.items,
				},
			};
		case 'RECEIVE_DOCUMENT_LIBRARY_FAILURE':
			return {
				...state,
				list: {
					...state!.list!,
					listStatus: List2Status.Failure,
				},
			};
		case 'INVALIDATE_DOCUMENT_LIBRARY':
			if (!state?.list)
				return state;
			return {
				...state,
				list: {
					...state!.list!,
					listStatus: List2Status.Dirty,
				},
				selectedLibraryDocument: undefined,
			}
		case 'SELECT_LIBRARY_DOCUMENT':
			const document = action.document === undefined ? undefined : {
				...action.document,
				category: action.document!.category.replace(/,\s*/g, '|'),
				significance: action.document!.significance.replace(/,\s*/g, '|'),
			};
			return {
				...state,
				pilotUserId: action.pilotUserId,
				selectedLibraryDocument: document,
			};
		case 'UPDATE_SELECTED_LIBRARY_DOCUMENT_FIELD':
			return {
				...state,
				selectedLibraryDocument: {
					...state!.selectedLibraryDocument!,
					[action.name]: (action.value === '' && !action.isRequired) ? null : action.value,
				},
			};
		case 'REQUEST_SAVE_SELECTED_LIBRARY_DOCUMENT':
			return {
				...state,
				isSaving: true,
			};
		case 'RECEIVE_SAVE_SELECTED_LIBRARY_DOCUMENT':
			return {
				...state,
				list: state?.list === undefined ? undefined : {
					...state.list,
					items: action.document === undefined ? state!.list!.items
						: (state!.list!.items.map(d => d.documentId === state!.selectedLibraryDocument!.documentId ? action.document! : d)),
					listStatus: action.document === undefined ? state.list.listStatus : List2Status.Dirty,
				},
				selectedLibraryDocument: action.document ? undefined : state!.selectedLibraryDocument,
				isSaving: undefined,
			};
		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		default: const exhaustiveCheck: never = action;
	}
	return state;
}
