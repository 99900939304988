import * as React from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { CardBody } from 'reactstrap';
import { ApplicationState } from '../../store';
import { SummaryLoadStatus2 } from '../../store/summary/SummaryCommon';
import * as UserSummaryStore from '../../store/summary/UserSummaryStore';
import Loading from '../support/Loading';
import { StandardSummarySize } from '../support/Summaries';
import { View } from '../support/Views';

// From SkyCert v2

type PracticePilotsSummaryProps =
	UserSummaryStore.PracticePilotsState
	& typeof UserSummaryStore.userSummaryActionCreators
	& RouteComponentProps<{}>;

// Architecture: v2

class PracticePilotsSummary extends React.Component<PracticePilotsSummaryProps, {}> {
	// eslint-disable-next-line @typescript-eslint/no-useless-constructor
	constructor(props: any) {
		super(props);
	}

	componentDidMount() {
		this.props.requestPracticePilotsSummary();
    }

	public render() {
		return <StandardSummarySize>
			<View header='Pilots' onClickHeader={this.goToPilots}>
				<CardBody>
					{this.renderBody()}
                    <Link to='/practice/pilots'>Manage</Link>
				</CardBody>
            </View>
		</StandardSummarySize>;
	}

	goToPilots = () => {
		this.props.history.push('/practice/pilots');
	}

	private renderBody() {
		if (this.props.practicePilotsStatus === SummaryLoadStatus2.Loading) {
			return <Loading />;
		} else if (this.props.practicePilotsStatus === SummaryLoadStatus2.Loaded) {
			return <p>
				{this.props.numberOfCurrentPilots} current pilots.<br />
				{this.props.numberOfFormerPilots} former pilots.
			</p>;
		} else if (this.props.practicePilotsStatus === SummaryLoadStatus2.Failure) {
			return <p className='text-danger'>Unable to load.</p>;
		} else {	// SummaryLoadStatus2.Idle
			return null;	// This should only ever occur briefly.
		}
	}
}

export default connect(
	(state: ApplicationState) => state.summary.practicePilots || {},
	UserSummaryStore.userSummaryActionCreators
)(PracticePilotsSummary) /*as typeof PracticePilotsSummary*/;
