import { Action, Reducer } from 'redux';
import { store } from '../..';
import { List2Criteria, List2State, List2Status } from '../list/ListCommon';
import { getCriteriaUrl2 } from '../listSupport';
import { deleteJson, getJson, putJson } from '../myfetch';
import { dispatchErrorToast, dispatchToast } from '../notification/NotificationStore';
import { LoadStatus2 } from '../StoreCommon';

// STATE **********************************************************************

export interface Hl7State {
	unassignedHl7List?: List2State<Hl7Summary>;
	assignStatus?: LoadStatus2;	// undefined, Saving
	stopAlertingStatus?: LoadStatus2;	// undefined, Saving (stopping alerts), Changed (alerts have been stopped)
	deleteStatus?: LoadStatus2;	// undefined, Saving
}

export interface Hl7Summary {
	filledFormId: string;
	dateProcessed: string;
	pilotId?: string;
	pilotName: string;
	pilotDateOfBirth: string;
	requestDescription: string;
	isAlerting: string;
}

// ACTIONS ********************************************************************

interface RequestUnassignedHl7ListAction {
	type: 'REQUEST_UNASSIGNED_HL7_LIST';
	criteria: List2Criteria;
}
interface ReceiveUnassignedHl7ListSuccessAction {
	type: 'RECEIVE_UNASSIGNED_HL7_LIST_SUCCESS';
	numberOfPages: number;
	totalNumberOfItems: number;
	items: Hl7Summary[];
}
interface ReceiveUnassignedHl7ListFailureAction { type: 'RECEIVE_UNASSIGNED_HL7_LIST_FAILURE'; }

interface InvalidateUnassignedHl7ListAction { type: 'INVALIDATE_UNASSIGNED_HL7_LIST'; }

interface RequestAssignHl7Action { type: 'REQUEST_ASSIGN_HL7'; }
interface ReceiveAssignHl7Action { type: 'RECEIVE_ASSIGN_HL7'; }

interface RequestStopHl7AlertsAction { type: 'REQUEST_STOP_HL7_ALERTS'; }
interface ReceiveStopHl7AlertsAction { type: 'RECEIVE_STOP_HL7_ALERTS'; isOk: boolean; }

interface RequestDeleteHl7ResultsAction { type: 'REQUEST_DELETE_HL7_RESULTS'; }
interface ReceiveDeleteHl7ResultsAction { type: 'RECEIVE_DELETE_HL7_RESULTS'; isOk: boolean; }

type KnownAction = RequestUnassignedHl7ListAction | ReceiveUnassignedHl7ListSuccessAction | ReceiveUnassignedHl7ListFailureAction
	| InvalidateUnassignedHl7ListAction
	| RequestAssignHl7Action | ReceiveAssignHl7Action
	| RequestStopHl7AlertsAction | ReceiveStopHl7AlertsAction
	| RequestDeleteHl7ResultsAction | ReceiveDeleteHl7ResultsAction
	;

// ACTION INVOKERS ************************************************************

export const hl7ActionInvokers = {
	invalidateUnassignedHl7List: () => store.dispatch({ type: 'INVALIDATE_UNASSIGNED_HL7_LIST', isOk: false, } as KnownAction),

	requestUnassignedHl7List: async (criteria: List2Criteria) => {
		store.dispatch({ type: 'REQUEST_UNASSIGNED_HL7_LIST', criteria, } as KnownAction);
		try {
			const response = await getJson(`api/filledforms/unassignedhl7?${getCriteriaUrl2(criteria)}`);
			store.dispatch({
				type: 'RECEIVE_UNASSIGNED_HL7_LIST_SUCCESS',
				numberOfPages: response.numberOfPages,
				totalNumberOfItems: response.totalNumberOfItems,
				items: response.items,
			} as KnownAction);
		} catch (error) {
			store.dispatch({ type: 'RECEIVE_UNASSIGNED_HL7_LIST_FAILURE', } as KnownAction);
			dispatchErrorToast(true, 'HL7', error);
		}
	},

	assignHl7ToPilotMedical: async (filledFormId: string, regulatoryAuthorityId: string) => {
		store.dispatch({ type: 'REQUEST_ASSIGN_HL7', });
		try {
			const response = await putJson(`api/filledforms/unassignedhl7/${filledFormId}/pilot`, { regulatoryAuthorityId, });
			store.dispatch({ type: 'RECEIVE_ASSIGN_HL7', } as KnownAction);
			dispatchToast(true, 'success', 'Assign HL7 Results', response.message);
			hl7ActionInvokers.invalidateUnassignedHl7List();
			return true;
		} catch (error) {
			store.dispatch({ type: 'RECEIVE_ASSIGN_HL7', } as KnownAction);
			dispatchErrorToast(true, 'Assign HL7 Results', error);
			return false;
		}
	},

	requestStopHl7Alerts: async (filledFormId: string | number) => {
		store.dispatch({ type: 'REQUEST_STOP_HL7_ALERTS', } as KnownAction);
		try {
			await putJson(`api/filledforms/unassignedhl7/${filledFormId}/isAlerting`, { value: false, });
			store.dispatch({ type: 'RECEIVE_STOP_HL7_ALERTS', isOk: true, } as KnownAction);
			hl7ActionInvokers.invalidateUnassignedHl7List();
		} catch (error) {
			store.dispatch({ type: 'RECEIVE_STOP_HL7_ALERTS', isOk: false, } as KnownAction)
			dispatchErrorToast(true, 'HL7', error);
		}
	},

	deleteHl7Results: async (filledFormId: string | number) => {
		store.dispatch({ type: 'REQUEST_DELETE_HL7_RESULTS', } as KnownAction);
		try {
			const response = await deleteJson(`api/filledforms/unassignedhl7/${filledFormId}`);
			store.dispatch({ type: 'RECEIVE_DELETE_HL7_RESULTS', isOk: true, } as KnownAction);
			dispatchToast(true, 'success', 'Delete HL7 Results', response.message);
			hl7ActionInvokers.invalidateUnassignedHl7List();
			return true;
		} catch (error) {
			store.dispatch({ type: 'RECEIVE_DELETE_HL7_RESULTS', isOk: false, } as KnownAction)
			dispatchErrorToast(true, 'HL7', error);
			return false;
		}
	},
}


// REDUCERS *******************************************************************

export const reducer: Reducer<Hl7State | null> = (state: Hl7State | undefined | null, incomingAction: Action) => {
	if (state === undefined || incomingAction.type === 'REQUEST_LOGOUT')
		return null;
	const action = incomingAction as KnownAction;
	switch (action.type) {
		case 'REQUEST_UNASSIGNED_HL7_LIST':
			return {
				...state,
				unassignedHl7List: {
					criteria: action.criteria,
					listStatus: List2Status.Loading,
					numberOfPages: 0,
					totalNumberOfItems: undefined,
					items: [],
				},
			};
		case 'RECEIVE_UNASSIGNED_HL7_LIST_SUCCESS':
			return {
				...state,
				unassignedHl7List: {
					criteria: state!.unassignedHl7List!.criteria,
					listStatus: List2Status.Loaded,
					numberOfPages: action.numberOfPages,
					totalNumberOfItems: action.totalNumberOfItems,
					items: action.items,
				}
			};
		case 'RECEIVE_UNASSIGNED_HL7_LIST_FAILURE':
			return {
				...state,
				unassignedHl7List: {
					...state!.unassignedHl7List!,
					listStatus: List2Status.Failure,
				}
			};
		case 'INVALIDATE_UNASSIGNED_HL7_LIST':
			return {
				...state,
				unassignedHl7List: {
					...state!.unassignedHl7List!,
					listStatus: List2Status.Dirty,
				},
			}
		case 'REQUEST_ASSIGN_HL7':
			return {
				...state,
				assignStatus: LoadStatus2.Saving,
			};
		case 'RECEIVE_ASSIGN_HL7':
			return {
				...state,
				assignStatus: undefined,
			};
		case 'REQUEST_STOP_HL7_ALERTS':
			return {
				...state,
				stopAlertingStatus: LoadStatus2.Saving,
			};
		case 'RECEIVE_STOP_HL7_ALERTS':
			return {
				...state,
				stopAlertingStatus: action.isOk ? LoadStatus2.Changed : undefined,
			};
		case 'REQUEST_DELETE_HL7_RESULTS':
			return {
				...state,
				deleteStatus: LoadStatus2.Saving,
			};
		case 'RECEIVE_DELETE_HL7_RESULTS':
			return {
				...state,
				deleteStatus: undefined,
			};
		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		default: const exhaustiveCheck: never = action;
	}
	return state;
};
