import { Action, Reducer } from 'redux';
import { AppThunkAction } from '..';
import { deleteJsonWithPayload, handleWithActionAndErrorToast, postJson } from '../myfetch';
import { dispatchToast } from '../notification/NotificationStore';
import { appointmentListActionCreators } from './AppointmentListStore';
import { bookingDatesActionCreators } from './BookingDatesStore';

// From SkyCert v1

// STATE **********************************************************************

export interface PracticeConfigurationState {
	buildTemplate: PracticeBuildTemplateState;
	reviewTemplate: PracticeReviewTemplateState;
	generateAppointments: PracticeGenerateAppointmentsScope;
	isGenerating: boolean;
}

export interface PracticeBuildTemplateState {
	dayStartTime: string;
	dayFinishByTime: string;
	appointmentDuration: string;
	nurseDuration?: string;
	morningTeaFrom: string;
	morningTeaFor: string;
	lunchFrom: string;
	lunchFor: string;
	afternoonTeaFrom: string;
	afternoonTeaFor: string;
}

export interface PracticeReviewTemplateState {
	isExternalBooking: boolean;
	appointments: PracticeReviewAppointmentState[];
}

export interface PracticeReviewAppointmentState {
	startTime: string;
	nurseFinishTime?: string;
	finishTime: string;
	isBlocked: boolean;
}

export interface PracticeGenerateAppointmentsScope {
	startDate: string;
	endDate?: string;
	everyOfTheseDays: string;
	practiceLocationId: number;
	medicalExaminers: string;
}

// ACTIONS ********************************************************************

interface PreparePracticeConfigurationAction { type: 'PREPARE_PRACTICE_CONFIGURATION'; }

interface UpdateBuildTemplateFieldAction { type: 'UPDATE_BOOKING_BUILD_TEMPLATE_FIELD'; name: string; value: any; isRequired: boolean; }
interface ClearGenerateeExaminersAndLocationsAction { type: 'CLEAR_GENERATE_EXAMINERS_AND_LOCATIONS';}

interface SetReviewTemplateAction { type: 'SET_BOOKING_REVIEW_TEMPLATE'; isExternalBooking: boolean; appointments: PracticeReviewAppointmentState[]; }
interface SetReviewTemplateTimesAction { type: 'SET_BOOKING_REVIEW_TEMPLATE_TIMES'; index: number; startTime: string; nurseFinishTime?: string; finishTime: string; }
interface DeleteReviewTemplateAppointmentAction { type: 'DELETE_BOOKING_REVIEW_TEMPLATE_APPOINTMENT'; index: number; }
interface NewReviewTemplateAppointmentAction { type: 'NEW_BOOKING_REVIEW_TEMPLATE_APPOINTMENT'; startTime: string; nurseFinishTime?: string; finishTime: string; }
interface ToggleReviewTemplateBlockedAction { type: 'SET_BOOKING_REVIEW_TEMPLATE_BLOCKED'; index: number; }

interface UpdateGenerateAppointmentsFieldAction { type: 'UPDATE_BOOKING_GENERATE_APPOINTMENTS_FIELD'; name: string; value: any; isRequired: boolean; }

interface UpdateIsGeneratingAction { type: 'UPDATE_BOOKING_IS_GENERATING_APPOINTMENTS'; isGenerating?: boolean; }

type KnownAction =
	PreparePracticeConfigurationAction
	| UpdateBuildTemplateFieldAction | ClearGenerateeExaminersAndLocationsAction
	| SetReviewTemplateAction | SetReviewTemplateTimesAction | DeleteReviewTemplateAppointmentAction
	| NewReviewTemplateAppointmentAction | ToggleReviewTemplateBlockedAction
	| UpdateGenerateAppointmentsFieldAction
	| UpdateIsGeneratingAction
	;

// ACTION CREATORS ************************************************************

export const practiceConfigurationActionCreators = {
	preparePracticeConfiguration: () => ({ type: 'PREPARE_PRACTICE_CONFIGURATION', } as KnownAction),

	updateBuildTemplateField: (name: string, value: any, isRequired: boolean) => ({ type: 'UPDATE_BOOKING_BUILD_TEMPLATE_FIELD', name, value, isRequired } as KnownAction),
	clearGenerateExaminersAndLocations: () => ({ type: 'CLEAR_GENERATE_EXAMINERS_AND_LOCATIONS', } as KnownAction),

	buildTemplate: (template: PracticeBuildTemplateState) => ({
			type: 'SET_BOOKING_REVIEW_TEMPLATE',
			isExternalBooking: false,
			appointments: utilities.createAppointmentTemplate(template),
	} as KnownAction),

	buildExternalBooking: () => ({ type: 'SET_BOOKING_REVIEW_TEMPLATE', isExternalBooking: true, appointments: [], } as KnownAction),

	clearReviewTemplate: () => ({ type: 'SET_BOOKING_REVIEW_TEMPLATE', isExternalBooking: false, appointments: [], } as KnownAction),

	setReviewTemplateTimes: (index: number, startTime: string, nurseFinishTime: string | undefined, finishTime: string) => ({ type: 'SET_BOOKING_REVIEW_TEMPLATE_TIMES', index, startTime, nurseFinishTime, finishTime, } as KnownAction),
	deleteReviewTemplateAppointment: (index: number) => ({ type: 'DELETE_BOOKING_REVIEW_TEMPLATE_APPOINTMENT', index, } as KnownAction),
	newReviewTemplateAppointment: (startTime: string, nurseFinishTime: string | undefined, finishTime: string) => ({ type: 'NEW_BOOKING_REVIEW_TEMPLATE_APPOINTMENT', startTime, nurseFinishTime, finishTime, } as KnownAction),
	toggleReviewTemplateBlocked: (index: number) => ({ type: 'SET_BOOKING_REVIEW_TEMPLATE_BLOCKED', index, } as KnownAction),

	updateGenerateAppointmentsField: (name: string, value: any, isRequired: boolean) => ({ type: 'UPDATE_BOOKING_GENERATE_APPOINTMENTS_FIELD', name, value, isRequired } as KnownAction),

	generateAppointments: (medicalPracticeId: number | undefined, scope: PracticeGenerateAppointmentsScope, template: PracticeReviewTemplateState): AppThunkAction<Action> => (dispatch, getState) => {
		postJson(`api/appointments/template/${medicalPracticeId || 0}`, { scope, template, })
			.then(response => {
				dispatch({ type: 'UPDATE_BOOKING_IS_GENERATING_APPOINTMENTS', isGenerating: false, } as KnownAction);
				dispatch(bookingDatesActionCreators.invalidateBookingDatesList());
				dispatch(appointmentListActionCreators.invalidateAppointmentList());
				dispatchToast(true, 'success', 'Appointments', response.message);
			})
			.catch(handleWithActionAndErrorToast('Appointments', 'UPDATE_BOOKING_IS_GENERATING_APPOINTMENTS'));
		dispatch({ type: 'UPDATE_BOOKING_IS_GENERATING_APPOINTMENTS', isGenerating: true, } as KnownAction);
	},

	deleteReviewAppointments: (medicalPracticeId: number | undefined, scope: PracticeGenerateAppointmentsScope): AppThunkAction<Action> => (dispatch, getState) => {
		deleteJsonWithPayload(`api/appointments/template/${medicalPracticeId || 0}`, scope)
			.then(response => {
				dispatch({ type: 'UPDATE_BOOKING_IS_GENERATING_APPOINTMENTS', isGenerating: false, } as KnownAction);
				dispatch(bookingDatesActionCreators.invalidateBookingDatesList());
				dispatch(appointmentListActionCreators.invalidateAppointmentList());
				if (!response.isWarning)
					dispatchToast(true, 'success', 'Appointments', response.message);
				else dispatchToast(true, 'warning', 'Appointments', response.message);
			})
			.catch(handleWithActionAndErrorToast('Appointments', 'UPDATE_BOOKING_IS_GENERATING_APPOINTMENTS'));
		dispatch({ type: 'UPDATE_BOOKING_IS_GENERATING_APPOINTMENTS', isGenerating: true, } as KnownAction);
	},
};

// SUPPORT ********************************************************************

interface TimeInHoursAndMinutes {
	hour: number;
	minute: number;
}

interface TimeFromAndFor {
	fromTime: TimeInHoursAndMinutes;
	forTime: TimeInHoursAndMinutes;
}

interface CreateAppointmentTemplateDetails {
	appointmentDuration: TimeInHoursAndMinutes;
	nurseDuration?: TimeInHoursAndMinutes;
	gapDuration: TimeInHoursAndMinutes;
	finishBy: TimeInHoursAndMinutes;
	breaks: TimeFromAndFor[];
}

const utilities = {
	createAppointmentTemplate: (template: PracticeBuildTemplateState): PracticeReviewAppointmentState[] => {
		const details: CreateAppointmentTemplateDetails = utilities.getCreateAppointmentTemplateDetails(template);
		let startTime: TimeInHoursAndMinutes = utilities.getTimeInHoursAndMinutes(template.dayStartTime);
		let appointments: PracticeReviewAppointmentState[] = [];
		let nextBreakIndex: number = 0;
		while (true) {
			const nurseFinishTime: TimeInHoursAndMinutes | undefined = !details.nurseDuration ? undefined : utilities.getFinishTimeInHoursAndMinutes(startTime, details.nurseDuration);
			const finishTime: TimeInHoursAndMinutes = utilities.getFinishTimeInHoursAndMinutes(startTime, details.appointmentDuration);
			if (utilities.isTimeGreaterThan(finishTime, details.finishBy))
				break;
			appointments.push({
				startTime: utilities.getTimeString(startTime),
				nurseFinishTime: !nurseFinishTime ? undefined : utilities.getTimeString(nurseFinishTime),
				finishTime: utilities.getTimeString(finishTime),
				isBlocked: false,
			});
			startTime = utilities.getFinishTimeInHoursAndMinutes(startTime, details.gapDuration);
			if (nextBreakIndex < details.breaks.length
				&& !utilities.isTimeGreaterThan(details.breaks[nextBreakIndex].fromTime, startTime)) {
				startTime = utilities.getFinishTimeInHoursAndMinutes(startTime, details.breaks[nextBreakIndex].forTime);
				nextBreakIndex++;
			}
		}
		return appointments;
	},

	getCreateAppointmentTemplateDetails: (template: PracticeBuildTemplateState): CreateAppointmentTemplateDetails => {
		const appointmentDuration: TimeInHoursAndMinutes = utilities.getTimeInHoursAndMinutes(template.appointmentDuration);
		let nurseDuration: TimeInHoursAndMinutes | undefined = !template.nurseDuration ? undefined : utilities.getTimeInHoursAndMinutes(template.nurseDuration);
		if (nurseDuration && nurseDuration.hour === appointmentDuration.hour && nurseDuration.minute === appointmentDuration.minute)
			nurseDuration = undefined;
		const gapDuration: TimeInHoursAndMinutes = utilities.getGapDuration(appointmentDuration, nurseDuration);
		const finishBy: TimeInHoursAndMinutes = utilities.getTimeInHoursAndMinutes(template.dayFinishByTime);
		let breaks: TimeFromAndFor[] = [];
		if (!!template.morningTeaFrom) {
			breaks.push({
				fromTime: utilities.getTimeInHoursAndMinutes(template.morningTeaFrom),
				forTime: utilities.getTimeInHoursAndMinutes(template.morningTeaFor),
			});
		}
		if (!!template.lunchFrom) {
			breaks.push({
				fromTime: utilities.getTimeInHoursAndMinutes(template.lunchFrom),
				forTime: utilities.getTimeInHoursAndMinutes(template.lunchFor),
			});
		}
		if (!!template.afternoonTeaFrom) {
			breaks.push({
				fromTime: utilities.getTimeInHoursAndMinutes(template.afternoonTeaFrom),
				forTime: utilities.getTimeInHoursAndMinutes(template.afternoonTeaFor),
			});
		}

		return {
			appointmentDuration,
			nurseDuration,
			gapDuration,
			//gapDuration: {
			//	hour: appointmentDuration.hour - (!nurseDuration ? 0 : nurseDuration.hour),
			//	minute: appointmentDuration.minute - (!nurseDuration ? 0 : nurseDuration.minute),
			//},
			finishBy,
			breaks,
		};
	},

	getTimeInHoursAndMinutes: (time: string): TimeInHoursAndMinutes => {
		const breakPos = time.indexOf(':');
		return {
			hour: parseInt(time.substr(0, breakPos)),
			minute: parseInt(time.substr(breakPos + 1)),
		};
	},

	getFinishTimeInHoursAndMinutes: (startTime: TimeInHoursAndMinutes, timeSpan: TimeInHoursAndMinutes): TimeInHoursAndMinutes => {
		let finishTime: TimeInHoursAndMinutes = {
			hour: startTime.hour,
			minute: startTime.minute,
		};
		finishTime.hour += timeSpan.hour;
		finishTime.minute += timeSpan.minute;
		finishTime = utilities.normaliseTimeInHoursAndMinutes(finishTime);
		return finishTime;
	},

	getGapDuration: (appointmentDuration: TimeInHoursAndMinutes, nurseDuration?: TimeInHoursAndMinutes) => {
		let gapDuration: TimeInHoursAndMinutes = {
			hour: appointmentDuration.hour - (nurseDuration?.hour ?? 0),
			minute: appointmentDuration.minute - (nurseDuration?.minute ?? 0),
		};
		gapDuration = utilities.normaliseTimeInHoursAndMinutes(gapDuration);
		if (nurseDuration && utilities.isTimeGreaterThan(nurseDuration, gapDuration))
			gapDuration = nurseDuration;
		return gapDuration;
	},

	normaliseTimeInHoursAndMinutes: (time: TimeInHoursAndMinutes): TimeInHoursAndMinutes => {
		while (time.minute >= 60) {
			time.minute -= 60;
			time.hour++;
		}
		while (time.minute < 0) {
			time.minute += 60;
			time.hour--;
		}
		return time;
	},

	getTimeString: (time: TimeInHoursAndMinutes): string => {
		return (time.hour < 10 ? '0' : '') + time.hour.toString() + ':' + (time.minute < 10 ? '0' : '') + time.minute.toString();
	},

	isTimeGreaterThan: (time1: TimeInHoursAndMinutes, time2: TimeInHoursAndMinutes): boolean => {
		return time1.hour > time2.hour
			|| (time1.hour === time2.hour && time1.minute > time2.minute);
	},
}

// INITIAL STATE **************************************************************

const initialState: PracticeConfigurationState = {
	buildTemplate: {
		dayStartTime: '09:00',
		dayFinishByTime: '17:00',
		appointmentDuration: '0:45',
		morningTeaFrom: '10:30',
		morningTeaFor: '0:15',
		lunchFrom: '12:15',
		lunchFor: '0:30',
		afternoonTeaFrom: '14:30',
		afternoonTeaFor: '0:15',
	},
	reviewTemplate: {
		isExternalBooking: false,
		appointments: [],
	},
	generateAppointments: {
		startDate: '',
		everyOfTheseDays: 'Monday|Tuesday|Wednesday|Thursday|Friday',
		practiceLocationId: 0,
		medicalExaminers: '',
	},
	isGenerating: false,
};

// REDUCERS *******************************************************************

export const reducer: Reducer<PracticeConfigurationState | null> = (state: PracticeConfigurationState | null | undefined, action: KnownAction) => {
	if (state === undefined)
		return null;
	state = state!;	// @@@ I hope this works
	switch (action.type) {
		case 'PREPARE_PRACTICE_CONFIGURATION':
			return initialState;
		case 'UPDATE_BOOKING_BUILD_TEMPLATE_FIELD':
			return {
				...state,
				buildTemplate: {
					...state.buildTemplate,
					[action.name]: (action.value === '' && !action.isRequired) ? null : action.value,
				},
			};
		case 'CLEAR_GENERATE_EXAMINERS_AND_LOCATIONS':
			return {
				...state,
				generateAppointments: {
					...state.generateAppointments,
					medicalExaminers: '',
					practiceLocationId: 0,
				},
			};
		case 'SET_BOOKING_REVIEW_TEMPLATE':
			return {
				...state,
				reviewTemplate: {
					isExternalBooking: action.isExternalBooking,
					appointments: action.appointments,
				}
			};
		case 'SET_BOOKING_REVIEW_TEMPLATE_TIMES':
			return {
				...state,
				reviewTemplate: {
					...state.reviewTemplate,
					appointments: state.reviewTemplate.appointments
						.map((a, index) => {
							if (index === action.index) {
								return {
									...a,
									startTime: action.startTime,
									nurseFinishTime: action.nurseFinishTime,
									finishTime: action.finishTime,
								};
							} else {
								return a;
							}
						})
						.sort((a, b) => a.startTime < b.startTime ? -1 : a.startTime === b.startTime ? 0 : 1)					,
				},
			};
		case 'DELETE_BOOKING_REVIEW_TEMPLATE_APPOINTMENT':
			return {
				...state,
				reviewTemplate: {
					...state.reviewTemplate,
					appointments: state.reviewTemplate.appointments.filter((a, index) => index !== action.index),
				},
			};
		case 'NEW_BOOKING_REVIEW_TEMPLATE_APPOINTMENT':
			return {
				...state,
				reviewTemplate: {
					...state.reviewTemplate,
					appointments: [
						...state.reviewTemplate.appointments,
						{
							startTime: action.startTime,
							nurseFinishTime: action.nurseFinishTime,
							finishTime: action.finishTime,
							isBlocked: false,
						},
					].sort((a, b) => a.startTime < b.startTime ? -1 : a.startTime === b.startTime ? 0 : 1),
				},
			};
		case 'SET_BOOKING_REVIEW_TEMPLATE_BLOCKED':
			return {
				...state,
				reviewTemplate: {
					...state.reviewTemplate,
					appointments: state.reviewTemplate.appointments
						.map((a, index) => {
							return {
								...a,
								isBlocked: index === action.index ? !a.isBlocked : a.isBlocked,
							};
						}),
				},
			};
		case 'UPDATE_BOOKING_GENERATE_APPOINTMENTS_FIELD':
			return {
				...state,
				generateAppointments: {
					...state.generateAppointments,
					[action.name]: (action.value === '' && !action.isRequired) ? null : action.value,
				}
			};
		case 'UPDATE_BOOKING_IS_GENERATING_APPOINTMENTS':
			return {
				...state,
				isGenerating: !!action.isGenerating,
			};
		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		default: const exhaustiveCheck: never = action;
	}

	return state;
};
