import * as React from 'react';
import { CardBody } from 'reactstrap';
import { StandardSummarySize } from '../support/Summaries';
import { View } from '../support/Views';

// From SkyCert v1

export default class SuspensionManagementSummary extends React.Component<{}, {}> {
	public render() {
		return <StandardSummarySize>
			<View header='Suspension Management'>
				<CardBody>
					<i>coming soon</i>
				</CardBody>
            </View>
		</StandardSummarySize>;
	}
}
