import * as React from 'react';
import { connect } from 'react-redux';
import * as Configuration from '../../Configuration';
import { Link, RouteComponentProps } from 'react-router-dom';
import { CardBody } from 'reactstrap';
import { ApplicationState } from '../../store';
import { SummaryStatus } from '../../store/summary/SummaryCommon';
import * as UserSummaryStore from '../../store/summary/UserSummaryStore';
import Loading from '../support/Loading';
import { SwitchableSummarySize } from '../support/Summaries';
import { View } from '../support/Views';

// From SkyCert v1

type PilotSummaryProps =
	ApplicationState
	& typeof UserSummaryStore.userSummaryActionCreators
	& RouteComponentProps<{}>;

class PilotSummary extends React.Component<PilotSummaryProps, {}> {
	// eslint-disable-next-line @typescript-eslint/no-useless-constructor
	constructor(props: any) {
		super(props);
	}

	componentDidMount() {
		this.props.requestPilotSummary();
    }

	public render() {
		let mode: string = 'primary';
		if (Configuration.enableFeature.medicals && this.props.summary.pilot && this.props.summary.pilot!.status !== SummaryStatus.Loading) {
			if (!this.props.summary.pilot!.isProfileFilled
				|| this.props.summary.pilot!.regulatoryAuthorityIdStatus === 'Missing') {
				mode = 'danger';
			} else if (this.props.summary.pilot!.regulatoryAuthorityIdStatus === 'Requested') {
				mode = 'warning';
			}
		}
		return <SwitchableSummarySize size={Configuration.enableFeature.medicals && mode === 'danger' ? 'veryLarge' : 'standard'}>
			<View color={mode} header='Profile' onClickHeader={this.goToProfile}>
				<CardBody>
					{this.renderBody()}
					{Configuration.enableFeature.medicals && mode === 'danger' &&
						<p>
							<h4><strong>Please update all of the details in your pilot profile <span className='text-danger'>before you do anything else</span>.</strong></h4>
							<p>e.g. before you start an application form or book an appointment in SkyCert.</p>
							<p>SkyCert needs this information for your application form, booking, etc.  There are also help video in the information box as a first line of help</p>
						</p>
					}
					<Link to='/user/manage/me'>Manage</Link>
				</CardBody>
            </View>
		</SwitchableSummarySize>;
	}

	goToProfile = () => {
		this.props.history.push('/user/manage/me');
	}

	private renderBody() {
		if (!this.props.summary.pilot || this.props.summary.pilot!.status === SummaryStatus.Loading) {
			return <p><Loading /></p>;
		} else if (this.props.summary.pilot!.status === SummaryStatus.Success) {
			let regulatoryAuthorityText: string =
				this.props.summary.pilot!.regulatoryAuthorityIdStatus === 'Missing'
					? 'No ' + this.props.globalSettings.raName + ' ID.'
					: this.props.globalSettings.raName + ' ID present.';
			return <p>
				{this.props.summary.pilot!.isProfileFilled ? 'Profile OK.' : 'Incomplete profile.'}<br />
				{regulatoryAuthorityText}
			</p>;
		} else {	// SummaryStatus.Failure
            return <p className='text-danger'>Unable to load!</p>;
		}
	}
}

export default connect(
	(state: ApplicationState) => state,
	UserSummaryStore.userSummaryActionCreators
)(PilotSummary) /*as typeof PilotSummary*/;
