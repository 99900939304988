import { Action, Reducer } from "redux";
import { AppThunkAction } from "..";
import { store } from "../..";
import { deleteJson, doDelete, doPut, getJson, handleWithActionAndErrorToast, handleWithErrorToast, postJson, putJson, respondWithActionAndToast, respondWithToast } from "../myfetch";
import { dispatchErrorToast, dispatchToast } from "../notification/NotificationStore";
import { LoadStatus2 } from "../StoreCommon";
import { loginActionCreators, RequiredActionFlags } from "./LoginStore";
import { selectedUserActionCreators } from "./SelectedUserStore";

export const SecurityQuestions: string[] = [
	'What airfield did you solo at?',
	'What plane would you buy if you had the money?',
	'What car would you buy if you had the money?',
	'What boat would you buy if you had the money?',
	'If you could fly any plane what would it be?',
	'What is your favourite procedure?',
	'What is your favourite nickname?',
	'What time of the day was your first child born?',
	'What is your favourite airfield to visit?',
	'What is the name of your favourite pet?',
	'What city did you meet your first spouse?',
	'What is you favourite flower?',
	'What is your favourite food?',
	'What was your first paid job?',
	'What are the best kind of patients?',
	'The best place to bandage is?',
	'The best place to give an injection?',
	'What is your favourite sport?',
	'What is your favourite season?',
	'What is your favourite hobby?',
	'Which way should toilet paper hang, over or under?',
	'What do you need help with most often?',
	'What is your favourite colour?',
	'What was the colour of your first car?',
	'What was your first car?',
	'What size bed do you have?',
	'Do you like cats, dogs or frogs?',
	'What primary school did you go to?',
	'What was your birth weight?',
	'What was the combination of you first bike lock?',
	'What is your least favourite chore?',
	'What is your favourite film genre?',
	'What is your favourite film star?',
	'Are you an Apple or PC person?',
	'Do you think that aliens exist?',
	"What's something that will always be in fashion, no matter how much time passes?",
	'Who do you go out of your way to be nice to?',
	'Who was your craziest / most interesting teacher?',
	"What's the most expensive thing you've broken?",
	'What mythical creature do you wish actually existed?',
	"What's something people don't worry about but really should?",
	"What's the funniest actual name you've heard of someone having?",
	'Which charity or charitable cause is most deserving of money?',
	"What was cool when you were young but isn't cool now?",
	'What game have you spent the most hours playing?',
	'If animals could talk, which animal would be the most annoying?',
	"What's the most ridiculous thing you have bought?",
	"What outdoor activity haven't you tried but would like to?",
	"What's the scariest non-horror movie?",
	'What brand are you most loyal to?',
	'What movie or book do you know the most quotes from?',
	"What's the most annoying noise?",
	"What's the coolest animal you've seen in the wild?",
	"What's your secret talent?",
	'Who is the most creative person you know?',
	'Who is your favourite relative?',
	"What's your good luck charm?",
	"What profession doesn't get enough credit or respect?",
	'What did you eat so much of that now you hate it?',
	"What's the most amazing place in nature you've been?",
];

export interface AccountState {
	changePasswordSubmitting?: boolean;	// undefined, true
	requestPasswordResetStatus?: LoadStatus2;	// undefined, Saving, Loaded
	resetPasswordStatus?: LoadStatus2;	// undefined, Saving, Loaded
	confirmEmailStatus?: LoadStatus2;	// undefined, Saving, Loaded

	securityQuestionsStatus?: LoadStatus2;
	securityQuestions?: SecurityQuestionsState;
	resetSecurityQuestionsSubmitting?: boolean;

	twoFactorStatus?: LoadStatus2;
	twoFactor?: TwoFactorState;
	resetTwoFactorSubmitting?: boolean;
}

export interface SecurityQuestionsState {
	question1Id: number;
	question1Answer: string;
	question2Id: number;
	question2Answer: string;
	question3Id: number;
	question3Answer: string;
	question4Id: number;
	question4Answer: string;
	question5Id: number;
	question5Answer: string;
	question6Id: number;
	question6Answer: string;
	question7Id: number;
	question7Answer: string;
}

export interface TwoFactorState {
	token: string;
	key: string;
	keyUrl: string;
}

// Actions ////////////////////////////////////////////////////////////////////

interface RequestChangePasswordAction { type: 'REQUEST_CHANGE_PASSWORD'; }
interface ReceiveChangePasswordAction { type: 'RECEIVE_CHANGE_PASSWORD'; }

interface ClearRequestPasswordResetAction { type: 'CLEAR_REQUEST_PASSWORD_RESET'; }
interface RequestRequestPasswordResetAction { type: 'REQUEST_REQUEST_PASSWORD_RESET'; }
interface ReceiveRequestPasswordResetSuccessAction { type: 'RECEIVE_REQUEST_PASSWORD_RESET_SUCCESS'; }

interface ClearResetPasswordAction { type: 'CLEAR_RESET_PASSWORD'; }
interface RequestResetPasswordAction { type: 'REQUEST_RESET_PASSWORD'; }
interface ReceiveResetPasswordSuccessAction { type: 'RECEIVE_RESET_PASSWORD_SUCCESS'; }

interface ClearResetPasswordAction { type: 'CLEAR_RESET_PASSWORD'; }
interface RequestResetPasswordAction { type: 'REQUEST_RESET_PASSWORD'; }
interface ReceiveResetPasswordSuccessAction { type: 'RECEIVE_RESET_PASSWORD_SUCCESS'; }

interface RequestConfirmEmailAction { type: 'REQUEST_CONFIRM_EMAIL'; }
interface ReceiveConfirmEmailSuccessAction { type: 'RECEIVE_CONFIRM_EMAIL_SUCCESS'; }
interface ReceiveConfirmEmailFailureAction { type: 'RECEIVE_CONFIRM_EMAIL_FAILURE'; }

interface ClearSecurityQuestionsAction { type: 'CLEAR_SECURITY_QUESTIONS'; }
interface RequestSecurityQuestionsAction { type: 'REQUEST_SECURITY_QUESTIONS'; }
interface ReceiveSecurityQuestionsAction { type: 'RECEIVE_SECURITY_QUESTIONS'; securityQuestions?: SecurityQuestionsState; }
interface UpdateSecurityQuestionAction { type: 'UPDATE_SECURITY_QUESTION'; name: string; value: any; }
interface RequestSaveSecurityQuestionsAction { type: 'REQUEST_SAVE_SECURITY_QUESTIONS'; }
interface ReceiveSaveSecurityQuestionsAction { type: 'RECEIVE_SAVE_SECURITY_QUESTIONS'; isOk?: boolean; }

interface RequestTwoFactorConfigurationAction { type: 'REQUEST_TWO_FACTOR_CONFIGURATION'; }
interface ReceiveTwoFactorConfigurationAction { type: 'RECEIVE_TWO_FACTOR_CONFIGURATION'; twoFactor?: TwoFactorState; oldTfaCode?: string; }
interface RequestSaveTwoFactorConfigurationAction { type: 'REQUEST_SET_TWO_FACTOR_CONFIGURATION'; }
interface ReceiveSaveTwoFactorConfigurationAction { type: 'RECEIVE_SET_TWO_FACTOR_CONFIGURATION'; isOk?: boolean; }
interface RequestDisableTwoFactorAction { type: 'REQUEST_DISABLE_TWO_FACTOR'; }
interface ReceiveDisableTwoFactorAction { type: 'RECEIVE_DISABLE_TWO_FACTOR'; isOk?: boolean; oldTfaCode?: string; }
interface ClearTwoFactorTokensAction { type: 'CLEAR_TWO_FACTOR_TOKENS'; }

type KnownAction =
	RequestChangePasswordAction | ReceiveChangePasswordAction
	| ClearRequestPasswordResetAction | RequestRequestPasswordResetAction
	| ReceiveRequestPasswordResetSuccessAction
	| ClearResetPasswordAction | RequestResetPasswordAction
	| ReceiveResetPasswordSuccessAction
	| RequestConfirmEmailAction
	| ReceiveConfirmEmailSuccessAction | ReceiveConfirmEmailFailureAction
	| RequestSecurityQuestionsAction | ReceiveSecurityQuestionsAction
	| ClearSecurityQuestionsAction | UpdateSecurityQuestionAction
	| RequestSaveSecurityQuestionsAction | ReceiveSaveSecurityQuestionsAction
	| RequestTwoFactorConfigurationAction | ReceiveTwoFactorConfigurationAction
	| RequestSaveTwoFactorConfigurationAction | ReceiveSaveTwoFactorConfigurationAction
	| RequestDisableTwoFactorAction | ReceiveDisableTwoFactorAction
	| ClearTwoFactorTokensAction
	;

// Action invokers ////////////////////////////////////////////////////////////

export const accountActionInvokers = {
	setUserPassword: (userId: string, newPassword: string, setIsBusy: (isBusy: boolean) => void) => {
		doPut(`api/account/password/${userId}`, { newPassword, }, 'Password', setIsBusy);
	},

	resetUserSecurityQuestions: async (userId: string, newDateRequired: string, setIsBusy: (isBusy: boolean) => void) => {
		const response = await doDelete(`api/account/securityquestions/${userId}?dateRequired=${newDateRequired}`, 'Security Questions', setIsBusy);
		if (!!response)
			store.dispatch(selectedUserActionCreators.updateSelectedUserField('dateSecurityQuestionsRequired', newDateRequired, true));
	},

	resetUserTwoFactor: async (userId: string, newDateRequired: string, ignoreDateRequired: boolean, setIsBusy: (isBusy: boolean) => void) => {
		const response = await doDelete(`api/account/twofactor/${userId}?dateRequired=${newDateRequired}&ignoreDateRequired=${ignoreDateRequired ? 'true' : 'false'}`, 'Two Factor', setIsBusy);
		if (!!response)
			store.dispatch(selectedUserActionCreators.updateSelectedUserField('dateTwoFactorRequired', newDateRequired, true));
	},
}

// Action Creators ////////////////////////////////////////////////////////////

export const accountActionCreators = {
	changePassword: (oldPassword: string, newPassword: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
		putJson('api/account/password', { oldPassword, newPassword, })
			// TODO @@@ Mark password changed
			.then(respondWithActionAndToast('Password', 'RECEIVE_CHANGE_PASSWORD'))
			.catch(handleWithActionAndErrorToast('Password', 'RECEIVE_CHANGE_PASSWORD'));
		dispatch({ type: 'REQUEST_CHANGE_PASSWORD', } as KnownAction);
	},

	setPassword: (userId: string, newPassword: string): AppThunkAction<KnownAction> => async (dispatch, getState) => {
		dispatch({ type: 'REQUEST_CHANGE_PASSWORD', } as KnownAction);
		try {
			const response = await putJson(`api/account/password/${userId}`, { newPassword, });
			dispatchToast(true, 'success', 'Password', response.message);
		} catch (error) {
			dispatchErrorToast(true, 'Password', error);
		}
		dispatch({ type: 'RECEIVE_CHANGE_PASSWORD', } as KnownAction);
	},


	clearRequestPasswordReset: () => ({ type: 'CLEAR_REQUEST_PASSWORD_RESET', }),

	requestPasswordReset: (userName: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
		postJson(`api/account/password?userName=${userName}`, {})
			.then(respondWithActionAndToast('Password', 'RECEIVE_REQUEST_PASSWORD_RESET_SUCCESS'))
			.catch(handleWithActionAndErrorToast('Password', 'CLEAR_REQUEST_PASSWORD_RESET'));
		dispatch({ type: 'REQUEST_REQUEST_PASSWORD_RESET', } as KnownAction);
	},

	clearResetPassword: () => ({ type: 'CLEAR_RESET_PASSWORD', }),

	resetPassword: (userId: string, code: string, newPassword: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
		postJson(`api/account/password/${userId}`, { code, newPassword, })
			.then(respondWithActionAndToast('Password', 'RECEIVE_RESET_PASSWORD_SUCCESS'))
			.catch(handleWithActionAndErrorToast('Password', 'CLEAR_RESET_PASSWORD'));
		dispatch({ type: 'REQUEST_RESET_PASSWORD', } as KnownAction);
	},

	confirmEmail: (userId: string, code: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
		putJson(`api/account/confirmemail/${userId}`, { code, })
			.then(respondWithActionAndToast('Email Address', 'RECEIVE_CONFIRM_EMAIL_SUCCESS'))
			.catch(handleWithActionAndErrorToast('Email Address', 'RECEIVE_CONFIRM_EMAIL_FAILURE'));
		dispatch({ type: 'REQUEST_CONFIRM_EMAIL', } as KnownAction);
	},

	// userId should be 'id' if userName is specified
	requestNewConfirmationEmail: (userIdOrUserName: string): AppThunkAction<KnownAction> => async (dispatch, getState) => {
		try {
			const response = await postJson(`api/account/confirmemail/${userIdOrUserName}`, undefined);
			dispatchToast(true, 'success', 'Email Address', response.message)
		} catch (error) {
			dispatchErrorToast(true, 'Email Address', error);
		}
	},

	acceptTermsAndConditions: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
		postJson('api/account/termsandconditions', { value: true, })
			.then(response => {
				dispatchToast(true, 'success', 'Terms and Conditions', response.message);
				dispatch(loginActionCreators.markTermsAndConditionsAccepted() as Action);
			})
			.catch(handleWithErrorToast('Terms and Conditions'));
	},

	clearSecurityQuestions: () => ({ type: 'CLEAR_SECURITY_QUESTIONS', }),

	getSecurityQuestions: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
		getJson('api/account/securityquestions')
			.then(response => {
				dispatch({ type: 'RECEIVE_SECURITY_QUESTIONS', securityQuestions: response.securityQuestions, } as KnownAction);
			})
			.catch(handleWithActionAndErrorToast('Security Questions', 'RECEIVE_SECURITY_QUESTIONS'));
		dispatch({ type: 'REQUEST_SECURITY_QUESTIONS', } as KnownAction);
	},

	updateSecurityQuestion: (name: string, value: any) => ({ type: 'UPDATE_SECURITY_QUESTION', name, value, }),

	saveSecurityQuestions: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
		const securityQuestions = getState().user.account!.securityQuestions;
		putJson('api/account/securityquestions', securityQuestions)
			.then(response => {
				dispatch({ type: 'RECEIVE_SAVE_SECURITY_QUESTIONS', isOk: true, } as KnownAction);
				dispatchToast(true, 'success', 'Security Questions', response.message);
				dispatch(loginActionCreators.markSecurityQuestionsSaved() as Action);
			})
			.catch(handleWithActionAndErrorToast('Security Questions', 'RECEIVE_SAVE_SECURITY_QUESTIONS'));
		dispatch({ type: 'REQUEST_SAVE_SECURITY_QUESTIONS', } as KnownAction);
	},

	getTwoFactorConfiguration: (oldTfaCode: string | undefined = undefined): AppThunkAction<KnownAction> => (dispatch, getState) => {
		const suffix = oldTfaCode ? `?oldTfaCode=${oldTfaCode}` : '';
		getJson(`api/account/twofactor${suffix}`)
			.then(response => {
				dispatch({ type: 'RECEIVE_TWO_FACTOR_CONFIGURATION', twoFactor: response.twoFactor, } as KnownAction);
			})
			.catch(error => {
				dispatch({ type: 'RECEIVE_TWO_FACTOR_CONFIGURATION', oldTfaCode, } as KnownAction);
				dispatchErrorToast(true, 'Two Factor', error);
			});
		dispatch({ type: 'REQUEST_TWO_FACTOR_CONFIGURATION', } as KnownAction);
	},

	setTwoFactorConfiguration: (tfaCode: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
		const twoFactor = getState().user.account!.twoFactor!;
		postJson('api/account/twofactor', { token: twoFactor.token, tfaCode, })
			.then(response => {
				dispatch({ type: 'RECEIVE_SET_TWO_FACTOR_CONFIGURATION', isOk: true, } as KnownAction);
				dispatchToast(true, 'success', 'Two Factor', response.message);
				dispatch(loginActionCreators.markTwoFactorConfigured() as Action);
			})
			.catch(handleWithActionAndErrorToast('Two Factor', 'RECEIVE_SET_TWO_FACTOR_CONFIGURATION'));
		dispatch({ type: 'REQUEST_SET_TWO_FACTOR_CONFIGURATION', } as KnownAction);
	},

	disableTwoFactor: (oldTfaCode: string | undefined = undefined): AppThunkAction<KnownAction> => (dispatch, getState) => {
		const suffix = oldTfaCode ? `?oldTfaCode=${oldTfaCode}` : '';
		deleteJson(`api/account/twofactor${suffix}`)
			.then(response => {
				dispatch({ type: 'RECEIVE_DISABLE_TWO_FACTOR', isOk: true, } as KnownAction);
				dispatchToast(true, 'success', 'Two Factor', response.message);
				dispatch(loginActionCreators.markTwoFactorDisabled() as Action);
				if (oldTfaCode)
					accountActionCreators.getTwoFactorConfiguration()(dispatch, getState);
				else dispatch(loginActionCreators.clearLoginActionFlag(RequiredActionFlags.ConfigureTwoFactorAuthentication) as Action);
			})
			.catch(error => {
				dispatch({ type: 'RECEIVE_DISABLE_TWO_FACTOR', isOk: false, oldTfaCode, } as KnownAction);
				dispatchErrorToast(true, 'Two Factor', error);
			});
		dispatch({ type: 'REQUEST_DISABLE_TWO_FACTOR', } as KnownAction);
	},

	clearRememberMe: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
		deleteJson('api/account/twofactor/rememberme')
			.then(respondWithToast('Remember Me'))
			.catch(handleWithErrorToast('Remember Me'));
	},

	clearTwoFactorTokens: () => ({ type: 'CLEAR_TWO_FACTOR_TOKENS', }),
};

// Initial State //////////////////////////////////////////////////////////////

export const initialSecurityQuestions: SecurityQuestionsState = {
	question1Id: 0,
	question1Answer: '',
	question2Id: 0,
	question2Answer: '',
	question3Id: 0,
	question3Answer: '',
	question4Id: 0,
	question4Answer: '',
	question5Id: 0,
	question5Answer: '',
	question6Id: 0,
	question6Answer: '',
	question7Id: 0,
	question7Answer: '',
}

// Reducer ////////////////////////////////////////////////////////////////////

export const accountReducer: Reducer<AccountState | null> = (state: AccountState | null | undefined, incomingAction: Action) => {
	if (state === undefined)
		return null;
	const action = incomingAction as KnownAction;
	switch (action.type) {
		case 'REQUEST_CHANGE_PASSWORD':
			return {
				...state,
				changePasswordSubmitting: true,
			};
		case 'RECEIVE_CHANGE_PASSWORD':
			return {
				...state,
				changePasswordSubmitting: undefined,
			};
		case 'CLEAR_REQUEST_PASSWORD_RESET':
			return {
				...state,
				requestPasswordResetStatus: undefined,
			};
		case 'REQUEST_REQUEST_PASSWORD_RESET':
			return {
				...state,
				requestPasswordResetStatus: LoadStatus2.Saving,
			};
		case 'RECEIVE_REQUEST_PASSWORD_RESET_SUCCESS':
			return {
				...state,
				requestPasswordResetStatus: LoadStatus2.Loaded,
			};
		case 'CLEAR_RESET_PASSWORD':
			return {
				...state,
				resetPasswordStatus: undefined,
			};
		case 'REQUEST_RESET_PASSWORD':
			return {
				...state,
				resetPasswordStatus: LoadStatus2.Saving,
			};
		case 'RECEIVE_RESET_PASSWORD_SUCCESS':
			return {
				...state,
				resetPasswordStatus: LoadStatus2.Loaded,
			};
		case 'REQUEST_CONFIRM_EMAIL':
			return {
				...state,
				confirmEmailStatus: LoadStatus2.Saving,
			};
		case 'RECEIVE_CONFIRM_EMAIL_SUCCESS':
			return {
				...state,
				confirmEmailStatus: LoadStatus2.Loaded,
			};
		case 'RECEIVE_CONFIRM_EMAIL_FAILURE':
			return {
				...state,
				confirmEmailStatus: undefined,
			};
		case 'CLEAR_SECURITY_QUESTIONS':
			return {
				...state,
				securityQuestionsStatus: LoadStatus2.Loaded,
				securityQuestions: initialSecurityQuestions,
			};
		case 'REQUEST_SECURITY_QUESTIONS':
			return {
				...state,
				securityQuestionsStatus: LoadStatus2.Loading,
				securityQuestions: undefined,
			};
		case 'RECEIVE_SECURITY_QUESTIONS':
			return {
				...state,
				securityQuestionsStatus: action.securityQuestions ? LoadStatus2.Loaded : LoadStatus2.Failure,
				securityQuestions: action.securityQuestions,
			};
		case 'UPDATE_SECURITY_QUESTION':
			return {
				...state,
				securityQuestions: {
					...state!.securityQuestions!,
					[action.name]: action.value,
				},
				securityQuestionsStatus: LoadStatus2.Changed,
			};
		case 'REQUEST_SAVE_SECURITY_QUESTIONS':
			return {
				...state,
				securityQuestionsStatus: LoadStatus2.Saving,
			};
		case 'RECEIVE_SAVE_SECURITY_QUESTIONS':
			return {
				...state,
				securityQuestionsStatus: action.isOk ? LoadStatus2.Loaded : LoadStatus2.Changed,
			};
		case 'REQUEST_TWO_FACTOR_CONFIGURATION':
			return {
				...state,
				twoFactorStatus: LoadStatus2.Loading,
				twoFactor: undefined,
			};
		case 'RECEIVE_TWO_FACTOR_CONFIGURATION':
			return {
				...state,
				twoFactorStatus: action.twoFactor ? LoadStatus2.Changed
					: (action.oldTfaCode ? undefined : LoadStatus2.Failure),
				twoFactor: action.twoFactor,
			};
		case 'REQUEST_SET_TWO_FACTOR_CONFIGURATION':
			return {
				...state,
				twoFactorStatus: LoadStatus2.Saving,
			};
		case 'RECEIVE_SET_TWO_FACTOR_CONFIGURATION':
			return {
				...state,
				twoFactorStatus: action.isOk ? undefined : LoadStatus2.Changed,
			}
		case 'REQUEST_DISABLE_TWO_FACTOR':
			return {
				...state,
				twoFactorStatus: LoadStatus2.Saving,
			};
		case 'RECEIVE_DISABLE_TWO_FACTOR':
			return {
				...state,
				twoFactorStatus: action.isOk ? undefined : LoadStatus2.Loaded,
				twoFactor: action.isOk ? undefined : state!.twoFactor,
			};
		case 'CLEAR_TWO_FACTOR_TOKENS':
			return {
				...state,
				twoFactorStatus: undefined,
				twoFactor: undefined,
			};
		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		default: const exhaustiveCheck: never = action;
	}
	return state;
}
