import { Action, Reducer } from "redux";
import { store } from "../..";
import { deleteJson, getJson, postJson, putJson } from "../myfetch";
import { dispatchErrorToast, dispatchToast } from "../notification/NotificationStore";
import { LoadStatus2 } from "../StoreCommon";

// STATE //////////////////////////////////////////////////////////////////////

export interface TestSchedulesState {
	status: LoadStatus2;
	testSchedules?: TestSchedule[];
	flyingOrganisationId?: string;
	selectedTestSchedule?: TestSchedule;
	isSaving?: boolean;	// undefined = no changes, false = changed, true = saving
}

export interface TestSchedule {
	organisationTestScheduleId: number;
	name: string;
	items: TestScheduleItem[];
}

export interface TestScheduleItem {
	documentTypeId: number;
	documentTypeName: string;
	repeatInDays?: number;
}


// ACTIONS ////////////////////////////////////////////////////////////////////

interface RequestGetOrganisationTestSchedulesAction { type: 'REQUEST_GET_ORGANISATION_TEST_SCHEDULES'; }
interface ReceiveGetOrganisationTestSchedulesAction {
	type: 'RECEIVE_GET_ORGANISATION_TEST_SCHEDULES';
	testSchedules?: TestSchedule[];
	flyingOrganisationId?: string;
}

interface RequestNewOrganisationTestScheduleAction { type: 'REQUEST_NEW_ORGANISATION_TEST_SCHEDULE'; }
interface ReceiveNewOrganisationTestScheduleAction {
	type: 'RECEIVE_NEW_ORGANISATION_TEST_SCHEDULE';
	name?: string;
	testSchedules?: TestSchedule[];
}

interface SelectOrganisationTestScheduleAction { type: 'SELECT_ORGANISATION_TEST_SCHEDULE'; organisationTestScheduleId?: number; }

interface UpdateOrganisationTestScheduleFieldAction { type: 'UPDATE_ORGANISATION_TEST_SCHEDULE_FIELD'; name: string; value: any; isRequired: boolean; }
interface UpdateOrganisationTestScheduleItemFieldAction { type: 'UPDATE_ORGANISATION_TEST_SCHEDULE_ITEM_FIELD'; name: string; value: any; isRequired: boolean; }

interface RequestSaveOrganisationTestScheduleAction { type: 'REQUEST_SAVE_ORGANISATION_TEST_SCHEDULE'; }
interface ReceiveSaveOrganisationTestScheduleAction {
	type: 'RECEIVE_SAVE_ORGANISATION_TEST_SCHEDULE';
	testSchedules?: TestSchedule[];
}

interface RequestDeleteOrganisationTestScheduleAction { type: 'REQUEST_DELETE_ORGANISATION_TEST_SCHEDULE'; }
interface ReceiveDeleteOrganisationTestScheduleAction {
	type: 'RECEIVE_DELETE_ORGANISATION_TEST_SCHEDULE';
	testSchedules?: TestSchedule[];
}

type KnownAction =
	RequestGetOrganisationTestSchedulesAction | ReceiveGetOrganisationTestSchedulesAction
	| RequestNewOrganisationTestScheduleAction | ReceiveNewOrganisationTestScheduleAction
	| SelectOrganisationTestScheduleAction
	| UpdateOrganisationTestScheduleFieldAction | UpdateOrganisationTestScheduleItemFieldAction
	| RequestSaveOrganisationTestScheduleAction | ReceiveSaveOrganisationTestScheduleAction
	| RequestDeleteOrganisationTestScheduleAction | ReceiveDeleteOrganisationTestScheduleAction
	;


// ACTION INVOKERS ////////////////////////////////////////////////////////////

export const organisationTestScheduleActionInvokers = {
	getSchedules: async (flyingOrganisationId: string) => {
		try {
			store.dispatch({ type: 'REQUEST_GET_ORGANISATION_TEST_SCHEDULES', } as KnownAction);
			const response = await getJson(`/api/organisations/${flyingOrganisationId}/testschedules`);
			store.dispatch({ type: 'RECEIVE_GET_ORGANISATION_TEST_SCHEDULES', testSchedules: response.testSchedules, flyingOrganisationId, } as KnownAction);
		} catch (error) {
			store.dispatch({ type: 'RECEIVE_GET_ORGANISATION_TEST_SCHEDULES', } as KnownAction);
			dispatchErrorToast(true, 'Test Schedules', error);
        }
	},

	newSchedule: async (name: string) => {
		try {
			const state = store.getState().organisation.testSchedules!;
			store.dispatch({ type: 'REQUEST_NEW_ORGANISATION_TEST_SCHEDULE', } as KnownAction);
			const response = await postJson(`/api/organisations/${state.flyingOrganisationId}/testschedules`, { value: name, });
			store.dispatch({ type: 'RECEIVE_NEW_ORGANISATION_TEST_SCHEDULE', name, testSchedules: response.testSchedules, } as KnownAction);
		} catch (error) {
			store.dispatch({ type: 'RECEIVE_NEW_ORGANISATION_TEST_SCHEDULE', } as KnownAction);
			dispatchErrorToast(true, 'Test Schedule', error);
		}
	},

	selectSchedule: (organisationTestScheduleId?: number) => store.dispatch({ type: 'SELECT_ORGANISATION_TEST_SCHEDULE', organisationTestScheduleId, } as KnownAction),

	updateScheduleField: (name: string, value: any, isRequired: boolean) => store.dispatch({ type: 'UPDATE_ORGANISATION_TEST_SCHEDULE_FIELD', name, value, isRequired, } as KnownAction),
	updateScheduleItemField: (name: string, value: any, isRequired: boolean) => store.dispatch({ type: 'UPDATE_ORGANISATION_TEST_SCHEDULE_ITEM_FIELD', name, value, isRequired, } as KnownAction),

	saveSchedule: async () => {
		try {
			const state = store.getState().organisation.testSchedules!;
			store.dispatch({ type: 'REQUEST_SAVE_ORGANISATION_TEST_SCHEDULE', } as KnownAction);
			const response = await putJson(`/api/organisations/${state.flyingOrganisationId}/testschedules/${state.selectedTestSchedule!.organisationTestScheduleId}`, state.selectedTestSchedule);
			store.dispatch({ type: 'RECEIVE_SAVE_ORGANISATION_TEST_SCHEDULE', testSchedules: response.testSchedules, } as KnownAction);
			dispatchToast(true, 'success', 'Test Schedule', 'The schedule has been saved.');
		} catch (error) {
			store.dispatch({ type: 'RECEIVE_SAVE_ORGANISATION_TEST_SCHEDULE', } as KnownAction);
			dispatchErrorToast(true, 'Test Schedule', error);
		}
	},

	deleteSchedule: async () => {
		try {
			const state = store.getState().organisation.testSchedules!;
			store.dispatch({ type: 'REQUEST_DELETE_ORGANISATION_TEST_SCHEDULE', } as KnownAction);
			const response = await deleteJson(`/api/organisations/${state.flyingOrganisationId}/testschedules/${state.selectedTestSchedule!.organisationTestScheduleId}`);
			store.dispatch({ type: 'RECEIVE_DELETE_ORGANISATION_TEST_SCHEDULE', testSchedules: response.testSchedules, } as KnownAction);
			dispatchToast(true, 'success', 'Test Schedule', 'The schedule has been deleted.');
		} catch (error) {
			store.dispatch({ type: 'RECEIVE_DELETE_ORGANISATION_TEST_SCHEDULE', } as KnownAction);
			dispatchErrorToast(true, 'Test Schedule', error);
		}
	},
};


// REDUCER ////////////////////////////////////////////////////////////////////

export const reducer: Reducer<TestSchedulesState | null> = (state: TestSchedulesState | undefined | null, incomingAction: Action) => {
	if (state === undefined || incomingAction.type === 'REQUEST_LOGOUT')
		return null;
	const action = incomingAction as KnownAction;
	switch (action.type) {
		case 'REQUEST_GET_ORGANISATION_TEST_SCHEDULES':
			return {
				status: LoadStatus2.Loading,
			} as TestSchedulesState;
		case 'RECEIVE_GET_ORGANISATION_TEST_SCHEDULES':
			return {
				status: action.testSchedules === undefined ? LoadStatus2.Failure : LoadStatus2.Loaded,
				testSchedules: action.testSchedules,
				flyingOrganisationId: action.flyingOrganisationId,
				selectedTestSchedule: undefined,
				isSaving: undefined,
			} as TestSchedulesState;

		case 'REQUEST_NEW_ORGANISATION_TEST_SCHEDULE':
			return {
				...state,
				isSaving: true,
			} as TestSchedulesState;
		case 'RECEIVE_NEW_ORGANISATION_TEST_SCHEDULE':
			return {
				...state,
				testSchedules: action.testSchedules || state!.testSchedules,
				selectedTestSchedule: action.testSchedules?.find(s => s.name === action.name),
				isSaving: undefined,
			} as TestSchedulesState;

		case 'SELECT_ORGANISATION_TEST_SCHEDULE':
			if (action.organisationTestScheduleId)
				return {
					...state,
					selectedTestSchedule: state!.testSchedules!.find(s => s.organisationTestScheduleId === action.organisationTestScheduleId),
					isSaving: undefined,
				} as TestSchedulesState;
			else return {
				...state,
				selectedTestSchedule: undefined,
				isSaving: undefined,
			} as TestSchedulesState;

		case 'UPDATE_ORGANISATION_TEST_SCHEDULE_FIELD':
			return {
				...state,
				selectedTestSchedule: {
					...state?.selectedTestSchedule,
					[action.name]: (action.value === '' && !action.isRequired) ? null : action.value,
				},
				isSaving: false,
			} as TestSchedulesState;
		case 'UPDATE_ORGANISATION_TEST_SCHEDULE_ITEM_FIELD':
			const newValue = (action.value === '' && !action.isRequired) ? null : action.value;
			return {
				...state,
				selectedTestSchedule: {
					...state!.selectedTestSchedule,
					items: state?.selectedTestSchedule?.items.map(i => i.documentTypeName === action.name ? { ...i, repeatInDays: newValue, } : i),
				},
				isSaving: false,
			} as TestSchedulesState;

		case 'REQUEST_SAVE_ORGANISATION_TEST_SCHEDULE':
			return {
				...state,
				status: LoadStatus2.Saving,
			} as TestSchedulesState;
		case 'RECEIVE_SAVE_ORGANISATION_TEST_SCHEDULE':
			return {
				...state,
				status: LoadStatus2.Loaded,
				testSchedules: action.testSchedules || state!.testSchedules,
				selectedTestSchedule: undefined,
				isSaving: undefined,
			} as TestSchedulesState;

		case 'REQUEST_DELETE_ORGANISATION_TEST_SCHEDULE':
			return {
				...state,
				status: LoadStatus2.Saving,
			} as TestSchedulesState;
		case 'RECEIVE_DELETE_ORGANISATION_TEST_SCHEDULE':
			return {
				...state,
				status: LoadStatus2.Loaded,
				testSchedules: action.testSchedules || state!.testSchedules,
			} as TestSchedulesState;

 		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		default: const exhaustiveCheck: never = action;
	}
	return state;
}
