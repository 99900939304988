import { Action, Reducer } from 'redux';
import { AppThunkAction } from '..';
import { getJson, handleWithActionAndErrorToast, handleWithErrorToast, postJson, putJson } from '../myfetch';
import { dispatchToast } from '../notification/NotificationStore';
import { LoadStatus2 } from '../StoreCommon';
import { SecondaryRole, UserRole } from '../user/UserCommon';
import { MedicalPractice, PracticeExaminer, PracticeLocation, PracticeNurse } from './PracticeCommon';
import { practiceListActionCreators } from './PracticeListStore';

// From SkyCert v1

// STATE **********************************************************************

export interface SelectedPracticeState {
	status2: LoadStatus2;
	medicalPractice?: MedicalPractice;
	medicalPracticeSaved?: boolean;

	selectedLocation?: PracticeLocation;
	selectedLocationSaving?: boolean;
	selectedLocationSaved?: boolean;

	selectedExaminer?: PracticeExaminer;
	selectedExaminerSaving?: boolean;
	selectedExaminerSaved?: boolean;

	selectedNurse?: PracticeNurse;
	selectedNurseSaving?: boolean;
	selectedNurseSaved?: boolean;
}

// ACTIONS ********************************************************************

interface PrepareNewPracticeAction { type: 'PREPARE_NEW_PRACTICE' }
interface ReceivePracticeDefaultValuesSuccessAction { type: 'RECEIVE_PRACTICE_DEFAULT_VALUES_SUCCESS', defaultValues: any, }

interface RequestSaveNewPracticeAction { type: 'REQUEST_SAVE_NEW_PRACTICE' }
interface ReceiveSaveNewPracticeAction { type: 'RECEIVE_SAVE_NEW_PRACTICE'; medicalPractice?: MedicalPractice; }

interface RequestLoadPracticeAction { type: 'REQUEST_LOAD_PRACTICE'; medicalPracticeId: string }
interface ReceiveLoadPracticeSuccessAction { type: 'RECEIVE_LOAD_PRACTICE_SUCCESS'; medicalPractice: MedicalPractice }
interface ReceiveLoadPracticeFailureAction { type: 'RECEIVE_LOAD_PRACTICE_FAILURE' }

interface UpdateSelectedPracticeFieldAction { type: 'UPDATE_SELECTED_PRACTICE_FIELD'; name: string; value: any; isRequired: boolean; }

interface RequestSaveSelectedPracticeAction { type: 'REQUEST_SAVE_SELECTED_PRACTICE' }
interface ReceiveSaveSelectedPracticeAction { type: 'RECEIVE_SAVE_SELECTED_PRACTICE'; medicalPractice?: MedicalPractice; }

interface PrepareNewPracticeExaminerAction { type: 'PREPARE_NEW_PRACTICE_EXAMINER' }
interface RequestSaveNewPracticeExaminerAction { type: 'REQUEST_SAVE_NEW_PRACTICE_EXAMINER' }
interface ReceiveSaveNewPracticeExaminerAction { type: 'RECEIVE_SAVE_NEW_PRACTICE_EXAMINER'; practiceExaminers?: PracticeExaminer[]; }

interface SelectPracticeExaminerAction { type: 'SELECT_PRACTICE_EXAMINER'; practiceExaminerId: number; }
interface ClearSelectedPracticeExaminerAction { type: 'CLEAR_SELECTED_PRACTICE_EXAMINER' }
interface UpdateSelectedPracticeExaminerFieldAction { type: 'UPDATE_SELECTED_PRACTICE_EXAMINER_FIELD'; name: string; value: any; isRequired: boolean; }

interface RequestSaveSelectedPracticeExaminerAction { type: 'REQUEST_SAVE_SELECTED_PRACTICE_EXAMINER' }
interface ReceiveSaveSelectedPracticeExaminerAction { type: 'RECEIVE_SAVE_SELECTED_PRACTICE_EXAMINER'; practiceExaminers?: PracticeExaminer[]; }

interface PrepareNewPracticeNurseAction { type: 'PREPARE_NEW_PRACTICE_NURSE' }
interface RequestSaveNewPracticeNurseAction { type: 'REQUEST_SAVE_NEW_PRACTICE_NURSE' }
interface ReceiveSaveNewPracticeNurseAction { type: 'RECEIVE_SAVE_NEW_PRACTICE_NURSE'; practiceNurses?: PracticeNurse[]; }

interface SelectPracticeNurseAction { type: 'SELECT_PRACTICE_NURSE'; nurseUserId: string; }
interface ClearSelectedPracticeNurseAction { type: 'CLEAR_SELECTED_PRACTICE_NURSE' }
interface UpdateSelectedPracticeNurseFieldAction { type: 'UPDATE_SELECTED_PRACTICE_NURSE_FIELD'; name: string; value: any; isRequired: boolean; }

interface RequestSaveSelectedPracticeNurseAction { type: 'REQUEST_SAVE_SELECTED_PRACTICE_NURSE' }
interface ReceiveSaveSelectedPracticeNurseAction { type: 'RECEIVE_SAVE_SELECTED_PRACTICE_NURSE'; practiceNurses?: PracticeNurse[]; }

interface PrepareNewPracticeLocationAction { type: 'PREPARE_NEW_PRACTICE_LOCATION' }
interface RequestSaveNewPracticeLocationAction { type: 'REQUEST_SAVE_NEW_PRACTICE_LOCATION' }
interface ReceiveSaveNewPracticeLocationAction { type: 'RECEIVE_SAVE_NEW_PRACTICE_LOCATION'; practiceLocations?: PracticeLocation[]; }

interface SelectPracticeLocationAction { type: 'SELECT_PRACTICE_LOCATION'; practiceLocationId: number; }
interface ClearSelectedPracticeLocationAction { type: 'CLEAR_SELECTED_PRACTICE_LOCATION' }
interface UpdateSelectedPracticeLocationFieldAction { type: 'UPDATE_SELECTED_PRACTICE_LOCATION_FIELD'; name: string; value: any; isRequired: boolean; }

interface RequestSaveSelectedPracticeLocationAction { type: 'REQUEST_SAVE_SELECTED_PRACTICE_LOCATION' }
interface ReceiveSaveSelectedPracticeLocationAction { type: 'RECEIVE_SAVE_SELECTED_PRACTICE_LOCATION'; practiceLocations?: PracticeLocation[]; }

type KnownAction = PrepareNewPracticeAction | ReceivePracticeDefaultValuesSuccessAction
	| RequestSaveNewPracticeAction | ReceiveSaveNewPracticeAction
	| RequestLoadPracticeAction | ReceiveLoadPracticeSuccessAction | ReceiveLoadPracticeFailureAction
	| UpdateSelectedPracticeFieldAction
	| RequestSaveSelectedPracticeAction | ReceiveSaveSelectedPracticeAction
	| PrepareNewPracticeExaminerAction | RequestSaveNewPracticeExaminerAction | ReceiveSaveNewPracticeExaminerAction
	| SelectPracticeExaminerAction | ClearSelectedPracticeExaminerAction | UpdateSelectedPracticeExaminerFieldAction
	| RequestSaveSelectedPracticeExaminerAction | ReceiveSaveSelectedPracticeExaminerAction
	| PrepareNewPracticeNurseAction | RequestSaveNewPracticeNurseAction | ReceiveSaveNewPracticeNurseAction
	| SelectPracticeNurseAction | ClearSelectedPracticeNurseAction | UpdateSelectedPracticeNurseFieldAction
	| RequestSaveSelectedPracticeNurseAction | ReceiveSaveSelectedPracticeNurseAction
	| PrepareNewPracticeLocationAction | RequestSaveNewPracticeLocationAction | ReceiveSaveNewPracticeLocationAction
	| SelectPracticeLocationAction | ClearSelectedPracticeLocationAction | UpdateSelectedPracticeLocationFieldAction
	| RequestSaveSelectedPracticeLocationAction | ReceiveSaveSelectedPracticeLocationAction
	;

// ACTION CREATORS ************************************************************

export const selectedPracticeActionCreators = {
	prepareNewPractice: () => ({ type: 'PREPARE_NEW_PRACTICE' } as KnownAction),

	requestPracticeDefaultValues: (): AppThunkAction<Action> => (dispatch, getState) => {
		getJson('api/practice/defaultvalues')
			.then(response => {
				dispatch({ type: 'RECEIVE_PRACTICE_DEFAULT_VALUES_SUCCESS', defaultValues: response.defaultValues, } as KnownAction);
			})
			.catch(handleWithErrorToast('Medical Practice'));
	},

	saveNewPractice: (medicalPractice: MedicalPractice): AppThunkAction<Action> => (dispatch, getState) => {
		postJson('api/practices', medicalPractice)
			.then(response => {
				dispatch({ type: 'RECEIVE_SAVE_NEW_PRACTICE', medicalPractice: response.medicalPractice, } as KnownAction);
				dispatch(practiceListActionCreators.invalidateCurrentPracticesPage());
				dispatchToast(true, 'success', 'Medical Practice', response.message);
			})
			.catch(handleWithActionAndErrorToast('Medical Practice', 'RECEIVE_SAVE_NEW_PRACTICE'));
		dispatch({ type: 'REQUEST_SAVE_NEW_PRACTICE' } as KnownAction);
	},

	// Set practiceId to 'me' to retrive currently logged in practice.
	loadPractice: (practiceId: string): AppThunkAction<Action> => (dispatch, getState) => {
		// TODO @@@ Check if it is already loaded
		getJson(`api/practices/${practiceId}`)
			.then(response => {
				dispatch({ type: 'RECEIVE_LOAD_PRACTICE_SUCCESS', medicalPractice: response.medicalPractice, } as KnownAction);
			})
			.catch(handleWithActionAndErrorToast('Medical Practice', 'RECEIVE_LOAD_PRACTICE_FAILURE'));
		dispatch({ type: 'REQUEST_LOAD_PRACTICE' } as KnownAction);
	},

	updateSelectedPracticeField: (name: string, value: any, isRequired: boolean) => ({ type: 'UPDATE_SELECTED_PRACTICE_FIELD', name, value, isRequired } as KnownAction),

	saveSelectedPractice: (medicalPractice: MedicalPractice): AppThunkAction<Action> => (dispatch, getState) => {
		const practice: MedicalPractice = {
			...medicalPractice,
			practiceLocations: [],
			practiceExaminers: [],
			practiceNurses: [],
		};
		putJson(`api/practices/${medicalPractice.medicalPracticeId}`, practice)
			.then(response => {
				dispatch({ type: 'RECEIVE_SAVE_SELECTED_PRACTICE', medicalPractice: response.medicalPractice, } as KnownAction);
				dispatch(practiceListActionCreators.invalidateCurrentPracticesPage());
				dispatchToast(true, 'success', 'Medical Practice', response.message);
			})
			.catch(handleWithActionAndErrorToast('Medical Practice', 'RECEIVE_SAVE_SELECTED_PRACTICE'));
		dispatch({ type: 'REQUEST_SAVE_SELECTED_PRACTICE' } as KnownAction);
	},

	prepareNewPracticeExaminer: () => ({ type: 'PREPARE_NEW_PRACTICE_EXAMINER' } as KnownAction),

	requestSaveNewPracticeExaminer: (medicalPracticeId: number, practiceExaminer: PracticeExaminer): AppThunkAction<Action> => (dispatch, getState) => {
		// TODO This data conversion is only temporary until a SecondaryUserRole can be implemented
		const data = {
			...practiceExaminer,
			role: practiceExaminer.role.replace(' ', ''),
		};
		postJson(`api/practices/${medicalPracticeId}/examiners`, data)
			.then(response => {
				dispatch({ type: 'RECEIVE_SAVE_NEW_PRACTICE_EXAMINER', practiceExaminers: response.practiceExaminers, } as KnownAction);
				dispatchToast(true, 'success', 'Practice Examiner', response.message);
			})
			.catch(handleWithActionAndErrorToast('Practice Examiner', 'RECEIVE_SAVE_NEW_PRACTICE_EXAMINER'));
		dispatch({ type: 'REQUEST_SAVE_NEW_PRACTICE_EXAMINER' } as KnownAction);
	},

	selectPracticeExaminer: (practiceExaminerId: number) => ({ type: 'SELECT_PRACTICE_EXAMINER', practiceExaminerId } as KnownAction),
	clearSelectedPracticeExaminer: () => ({ type: 'CLEAR_SELECTED_PRACTICE_EXAMINER' } as KnownAction),

	updateSelectedPracticeExaminerField: (name: string, value: any, isRequired: boolean) => ({ type: 'UPDATE_SELECTED_PRACTICE_EXAMINER_FIELD', name, value, isRequired } as KnownAction),

	requestSaveSelectedPracticeExaminer: (medicalPracticeId: number, practiceExaminer: PracticeExaminer): AppThunkAction<Action> => (dispatch, getState) => {
		// TODO This data conversion is only temporary until a SecondaryUserRole can be implemented
		const data = {
			...practiceExaminer,
			role: practiceExaminer.role.replace(' ', ''),
		};
		putJson(`api/practices/${medicalPracticeId}/examiners/${practiceExaminer.practiceExaminerId}`, data)
			.then(response => {
				dispatch({ type: 'RECEIVE_SAVE_SELECTED_PRACTICE_EXAMINER', practiceExaminers: response.practiceExaminers, } as KnownAction);
				// TODO Invalidate list of practices
				dispatchToast(true, 'success', 'Practice Examiner', response.message);
			})
			.catch(handleWithActionAndErrorToast('Practice Examiner', 'RECEIVE_SAVE_SELECTED_PRACTICE_EXAMINER'));
		dispatch({ type: 'REQUEST_SAVE_SELECTED_PRACTICE_EXAMINER' } as KnownAction);
	},

	prepareNewPracticeNurse: () => ({ type: 'PREPARE_NEW_PRACTICE_NURSE' } as KnownAction),

	requestSaveNewPracticeNurse: (medicalPracticeId: number, practiceNurse: PracticeNurse, password: string): AppThunkAction<Action> => (dispatch, getState) => {
		let newNurseDetails = { ...practiceNurse, password };
		postJson(`api/practices/${medicalPracticeId}/nurses`, newNurseDetails)
			.then(response => {
				dispatch({ type: 'RECEIVE_SAVE_NEW_PRACTICE_NURSE', practiceNurses: response.practiceNurses, } as KnownAction);
				dispatchToast(true, 'success', 'Practice Nurse', response.message);

			})
			.catch(handleWithActionAndErrorToast('Practice Nurse', 'RECEIVE_SAVE_NEW_PRACTICE_NURSE'));
		dispatch({ type: 'REQUEST_SAVE_NEW_PRACTICE_NURSE' } as KnownAction);
	},

	selectPracticeNurse: (nurseUserId: string) => ({ type: 'SELECT_PRACTICE_NURSE', nurseUserId } as KnownAction),
	clearSelectedPracticeNurse: () => ({ type: 'CLEAR_SELECTED_PRACTICE_NURSE' } as KnownAction),

	updateSelectedPracticeNurseField: (name: string, value: any, isRequired: boolean) => ({ type: 'UPDATE_SELECTED_PRACTICE_NURSE_FIELD', name, value, isRequired } as KnownAction),

	requestSaveSelectedPracticeNurse: (medicalPracticeId: number, practiceNurse: PracticeNurse): AppThunkAction<Action> => (dispatch, getState) => {
		putJson(`api/practices/${medicalPracticeId}/nurses/${practiceNurse.nurseUserId}`, practiceNurse)
			.then(response => {
				dispatch({ type: 'RECEIVE_SAVE_SELECTED_PRACTICE_NURSE', practiceNurses: response.practiceNurses, } as KnownAction);
				dispatchToast(true, 'success', 'Practice Nurse', response.message);
			})
			.catch(handleWithActionAndErrorToast('Practice Nurse', 'RECEIVE_SAVE_SELECTED_PRACTICE_NURSE'));
		dispatch({ type: 'REQUEST_SAVE_SELECTED_PRACTICE_NURSE' } as KnownAction);
	},

	prepareNewPracticeLocation: () => ({ type: 'PREPARE_NEW_PRACTICE_LOCATION' } as KnownAction),

	saveNewPracticeLocation: (medicalPracticeId: number, practiceLocation: PracticeLocation): AppThunkAction<Action> => (dispatch, getState) => {
		postJson(`api/practices/${medicalPracticeId}/locations`, practiceLocation)
			.then(response => {
				dispatch({ type: 'RECEIVE_SAVE_NEW_PRACTICE_LOCATION', practiceLocations: response.practiceLocations, } as KnownAction);
				dispatchToast(true, 'success', 'Location', response.message);
			})
			.catch(handleWithActionAndErrorToast('Location', 'RECEIVE_SAVE_NEW_PRACTICE_LOCATION'));
		dispatch({ type: 'REQUEST_SAVE_NEW_PRACTICE_LOCATION' } as KnownAction);
	},

	selectPracticeLocation: (practiceLocationId: number) => ({ type: 'SELECT_PRACTICE_LOCATION', practiceLocationId } as KnownAction),
	clearSelectedPracticeLocation: () => ({ type: 'CLEAR_SELECTED_PRACTICE_LOCATION' } as KnownAction),

	updateSelectedPracticeLocationField: (name: string, value: any, isRequired: boolean) => ({ type: 'UPDATE_SELECTED_PRACTICE_LOCATION_FIELD', name, value, isRequired } as KnownAction),

	saveSelectedPracticeLocation: (medicalPracticeId: number, practiceLocation: PracticeLocation): AppThunkAction<Action> => (dispatch, getState) => {
		putJson(`api/practices/${medicalPracticeId}/locations/${practiceLocation.practiceLocationId}`, practiceLocation)
			.then(response => {
				dispatch({ type: 'RECEIVE_SAVE_SELECTED_PRACTICE_LOCATION', practiceLocations: response.practiceLocations, } as KnownAction);
				// TODO Invalidate list of practices
				dispatchToast(true, 'success', 'Location', response.message);
			})
			.catch(handleWithActionAndErrorToast('Location', 'RECEIVE_SAVE_SELECTED_PRACTICE_LOCATION'));
		dispatch({ type: 'REQUEST_SAVE_SELECTED_PRACTICE_LOCATION' } as KnownAction);
	},
};

// INITIAL STATE **************************************************************

const initialState: SelectedPracticeState = {
	status2: LoadStatus2.Idle,
}

const initialPracticeState: MedicalPractice = {
	medicalPracticeId: 0,
	isActive: true,
	name: '',
	phoneNumber: '',
	mobileNumber: '',
	email: '',
	localAddress: '',
	localSuburb: '',
	localCity: '',
	localState: '',
	localPostcode: '',
	localCountry: '',
	postalAddress: '',
	postalSuburb: '',
	postalCity: '',
	postalState: '',
	postalPostcode: '',
	postalCountry: '',
	isOverseas: false,
	notifyOfBookings: 'None',
	ccWhenSendingDocuments: false,
	ccWhenSendingMedicals: false,
	notifyOfReminderCategoryActions: false,
	disallowPilotsMessagingPractice: false,
	practiceLocations: [],
	practiceExaminers: [],
	practiceNurses: [],
}

const initialPracticeExaminerState: PracticeExaminer = {
	practiceExaminerId: 0,
	medicalExaminerUserId: '',
	medicalExaminerName: '',
	role: SecondaryRole.MedicalExaminer,
	medicalPracticeToInvoiceId: 0,
	medicalPracticeToInvoiceName: '',
	isActive: true,
	isReadOnly: false,
	canImpersonate: false,
}

const initialPracticeNurseState: PracticeNurse = {
	nurseUserId: '',
	userName: '',
	firstName: '',
	lastName: '',
	knownAs: '',
	phoneNumber: '',
	email: '',
	role: UserRole.Nurse,
	isActive: true,
	canImpersonate: false,
}

const initialPracticeLocationState: PracticeLocation = {
	practiceLocationId: 0,
	isActive: true,
	showOnFindPracticePage: true,
	name: '',
	address: '',
	regionId: '',
	regionFullName: '',
}

// REDUCERS *******************************************************************

export const reducer: Reducer<SelectedPracticeState | null> = (state: SelectedPracticeState | null | undefined, action: KnownAction) => {
	if (state === undefined)
		return null;
	state = state!;	// @@@ I hope this works
	switch (action.type) {
		case 'PREPARE_NEW_PRACTICE':
			return {
				status2: LoadStatus2.Loaded,
				medicalPractice: initialPracticeState,
				isUploading: false,
			} as SelectedPracticeState;
		case 'RECEIVE_PRACTICE_DEFAULT_VALUES_SUCCESS':
			return {
				...state,
				medicalPractice: {
					...state.medicalPractice,
					...action.defaultValues,
				},
			} as SelectedPracticeState;
		case 'REQUEST_SAVE_NEW_PRACTICE':
			return {
				...state,
				status2: LoadStatus2.Saving,
			} as SelectedPracticeState;
		case 'RECEIVE_SAVE_NEW_PRACTICE':
			return {
				...state,
				status2: action.medicalPractice ? LoadStatus2.Loaded : LoadStatus2.Failure,
				medicalPractice: action.medicalPractice,
				medicalPracticeSaved: !!action.medicalPractice,
			} as SelectedPracticeState;
		case 'REQUEST_LOAD_PRACTICE':
			return {
				...initialState,
				status2: LoadStatus2.Loading,
			} as SelectedPracticeState;
		case 'RECEIVE_LOAD_PRACTICE_SUCCESS':
			return {
				...state,
				status2: LoadStatus2.Loaded,
				medicalPractice: action.medicalPractice,
				medicalPracticeSaved: true,
			} as SelectedPracticeState;
		case 'RECEIVE_LOAD_PRACTICE_FAILURE':
			return {
				...state,
				status2: LoadStatus2.Failure,
				medicalPractice: undefined,
				medicalPracticeSaved: undefined,
			} as SelectedPracticeState;
		case 'UPDATE_SELECTED_PRACTICE_FIELD':
			return {
				...state,
				medicalPractice: {
					...state.medicalPractice,
					[action.name]: (action.value === '' && !action.isRequired) ? null : action.value,
				},
				medicalPracticeSaved: undefined,
			} as SelectedPracticeState;
		case 'REQUEST_SAVE_SELECTED_PRACTICE':
			return {
				...state,
				status2: LoadStatus2.Saving,
			} as SelectedPracticeState;
		case 'RECEIVE_SAVE_SELECTED_PRACTICE':
			return {
				...state,
				status2: LoadStatus2.Loaded,
				medicalPractice: action.medicalPractice || state.medicalPractice,
				medicalPracticeSaved: !!action.medicalPractice,
			} as SelectedPracticeState;
		case 'PREPARE_NEW_PRACTICE_EXAMINER':
			return {
				...state,
				selectedExaminer: initialPracticeExaminerState,
				selectedExaminerSaving: undefined,
				selectedExaminerSaved: undefined,
			} as SelectedPracticeState;
		case 'REQUEST_SAVE_NEW_PRACTICE_EXAMINER':
			return {
				...state,
				selectedExaminerSaving: true,
			} as SelectedPracticeState;
		case 'RECEIVE_SAVE_NEW_PRACTICE_EXAMINER':
			return {
				...state,
				medicalPractice: {
					...state.medicalPractice!,
					practiceExaminers: action.practiceExaminers || state.medicalPractice!.practiceExaminers,
				},
				selectedExaminer: action.practiceExaminers ? undefined : state.selectedExaminer,
				selectedExaminerSaving: undefined,
				selectedExaminerSaved: !!action.practiceExaminers,
			} as SelectedPracticeState;
		case 'SELECT_PRACTICE_EXAMINER':
			return {
				...state,
				selectedExaminer: state.medicalPractice!.practiceExaminers.find(pe => pe.practiceExaminerId === action.practiceExaminerId),
				selectedExaminerSaving: undefined,
				selectedExaminerSaved: true,
			} as SelectedPracticeState;
		case 'CLEAR_SELECTED_PRACTICE_EXAMINER':
			return {
				...state,
				selectedExaminer: undefined,
				selectedExaminerSaving: undefined,
				selectedExaminerSaved: undefined,
			} as SelectedPracticeState;
		case 'UPDATE_SELECTED_PRACTICE_EXAMINER_FIELD':
			return {
				...state,
				selectedExaminer: {
					...state.selectedExaminer,
					[action.name]: (action.value === '' && !action.isRequired) ? null : action.value,
				},
				selectedExaminerSaved: undefined,
			} as SelectedPracticeState;
		case 'REQUEST_SAVE_SELECTED_PRACTICE_EXAMINER':
			return {
				...state,
				selectedExaminerSaving: true,
			} as SelectedPracticeState;
		case 'RECEIVE_SAVE_SELECTED_PRACTICE_EXAMINER':
			return {
				...state,
				medicalPractice: {
					...state.medicalPractice!,
					practiceExaminers: action.practiceExaminers || state.medicalPractice!.practiceExaminers,
				},
				selectedExaminer: action.practiceExaminers ? undefined : state.selectedExaminer,
				selectedExaminerSaving: undefined,
				selectedExaminerSaved: !!action.practiceExaminers,
			} as SelectedPracticeState;
		case 'PREPARE_NEW_PRACTICE_NURSE':
			return {
				...state,
				selectedNurse: initialPracticeNurseState,
				selectedNurseSaving: undefined,
				selectedNurseSaved: undefined,
			} as SelectedPracticeState;
		case 'REQUEST_SAVE_NEW_PRACTICE_NURSE':
			return {
				...state,
				selectedNurseSaving: true,
			} as SelectedPracticeState;
		case 'RECEIVE_SAVE_NEW_PRACTICE_NURSE':
			return {
				...state,
				medicalPractice: {
					...state.medicalPractice!,
					practiceNurses: action.practiceNurses || state.medicalPractice!.practiceNurses
				},
				selectedNurse: action.practiceNurses ? undefined : state.selectedNurse,
				selectedNurseSaving: undefined,
				selectedNurseSaved: !!action.practiceNurses,
			} as SelectedPracticeState;
		case 'SELECT_PRACTICE_NURSE':
			return {
				...state,
				selectedNurse: state.medicalPractice!.practiceNurses.find(pn => pn.nurseUserId === action.nurseUserId),
				selectedNurseSaving: undefined,
				selectedNurseSaved: true,
			} as SelectedPracticeState;
		case 'CLEAR_SELECTED_PRACTICE_NURSE':
			return {
				...state,
				selectedNurse: undefined,
				selectedNurseSaving: undefined,
				selectedNurseSaved: undefined,
			} as SelectedPracticeState;
		case 'UPDATE_SELECTED_PRACTICE_NURSE_FIELD':
			return {
				...state,
				selectedNurse: {
					...state.selectedNurse,
					[action.name]: (action.value === '' && !action.isRequired) ? null : action.value,
				},
				selectedNurseSaved: undefined,
			} as SelectedPracticeState;
		case 'REQUEST_SAVE_SELECTED_PRACTICE_NURSE':
			return {
				...state,
				selectedNurseSaving: true,
			} as SelectedPracticeState;
		case 'RECEIVE_SAVE_SELECTED_PRACTICE_NURSE':
			return {
				...state,
				medicalPractice: {
					...state.medicalPractice!,
					practiceNurses: action.practiceNurses || state.medicalPractice!.practiceNurses,
				},
				selectedNurse: action.practiceNurses ? undefined : state.selectedNurse,
				selectedNurseSaving: undefined,
				selectedNurseSaved: !!action.practiceNurses,
			} as SelectedPracticeState;
		case 'PREPARE_NEW_PRACTICE_LOCATION':
			return {
				...state,
				selectedLocation: initialPracticeLocationState,
				selectedLocationSaving: undefined,
				selectedLocationSaved: undefined,
			} as SelectedPracticeState;
		case 'REQUEST_SAVE_NEW_PRACTICE_LOCATION':
			return {
				...state,
				selectedLocationSaving: true,
			} as SelectedPracticeState;
		case 'RECEIVE_SAVE_NEW_PRACTICE_LOCATION':
			return {
				...state,
				medicalPractice: {
					...state.medicalPractice!,
					practiceLocations: action.practiceLocations || state.medicalPractice!.practiceLocations,
				},
				selectedLocation: action.practiceLocations ? undefined : state.selectedLocation,
				selectedLocationSaving: undefined,
				selectedLocationSaved: !!action.practiceLocations,
			} as SelectedPracticeState;
		case 'SELECT_PRACTICE_LOCATION':
			return {
				...state,
				selectedLocation: state.medicalPractice!.practiceLocations.find(pe => pe.practiceLocationId === action.practiceLocationId),
				selectedLocationSaving: undefined,
				selectedLocationSaved: true,
			} as SelectedPracticeState;
		case 'CLEAR_SELECTED_PRACTICE_LOCATION':
			return {
				...state,
				selectedLocation: undefined,
				selectedLocationSaving: undefined,
				selectedLocationSaved: undefined,
			} as SelectedPracticeState;
		case 'UPDATE_SELECTED_PRACTICE_LOCATION_FIELD':
			return {
				...state,
				selectedLocation: {
					...state.selectedLocation,
					[action.name]: (action.value === '' && !action.isRequired) ? null : action.value,
				},
				selectedLocationSaved: undefined,
			} as SelectedPracticeState;
		case 'REQUEST_SAVE_SELECTED_PRACTICE_LOCATION':
			return {
				...state,
				selectedLocationSaving: true,
			} as SelectedPracticeState;
		case 'RECEIVE_SAVE_SELECTED_PRACTICE_LOCATION':
			return {
				...state,
				medicalPractice: {
					...state.medicalPractice!,
					practiceLocations: action.practiceLocations || state.medicalPractice!.practiceLocations,
				},
				selectedLocation: action.practiceLocations ? undefined : state.selectedLocation,
				selectedLocationSaving: undefined,
				selectedLocationSaved: !!action.practiceLocations,
			} as SelectedPracticeState;
		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		default: const exhaustiveCheck: never = action;
	}
	return state;
}
