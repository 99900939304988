import * as React from 'react';
import { Helmet } from 'react-helmet';
import { UserRole } from '../../../store/user/UserCommon';
import { Page } from '../../app/Page';
import { AcceptTermsAndConditions, TermsAndConditionsAcceptance } from './TermsAndConditionsSupport';

export default () => (
	<Page>
		<Helmet>
			<title>SkyCert Administrator Terms and Conditions</title>
			<meta name='description' content="Terms and Conditions for the use of SkyCert by SkyCert administrators." />
			<meta name='robots' content='noindex, nofollow' />
		</Helmet>
		<h1>Terms and Conditions</h1>
		<h2>For SkyCert Administrators</h2>
		<TermsAndConditionsAcceptance role={UserRole.Administrator} />
		<p>Please contact SkyCert for these terms and conditions.</p>
		<AcceptTermsAndConditions role={UserRole.Administrator} />
	</Page>
);
