import { Action, Reducer } from 'redux';
import { AppThunkAction } from '..';
import { getJson, handleWithActionAndErrorToast } from '../myfetch';
import { LoadStatus2 } from '../StoreCommon';

// STATE **********************************************************************

const numberOfUrlsToKeep = 3;

export interface VideoUrlsState {
	status: LoadStatus2;
	urls: VideoUrl[];
}

interface VideoUrl {
	fileName: string;
	url: string;
}

// ACTIONS ********************************************************************

interface RequestVideoUrlAction { type: 'REQUEST_VIDEO_URL'; }
interface ReceiveVideoUrlSuccessAction { type: 'RECEIVE_VIDEO_URL_SUCCESS'; url: VideoUrl; }
interface ReceiveVideoUrlFailureAction { type: 'RECEIVE_VIDEO_URL_FAILURE'; }

type KnownAction = RequestVideoUrlAction | ReceiveVideoUrlSuccessAction | ReceiveVideoUrlFailureAction
	;

// ACTION CREATORS ************************************************************

export const videoUrlsActionCreators = {
	getVideoUrl: (fileName: string): AppThunkAction<Action> => (dispatch, getState) => {
		const state = getState().video;
		if (state && state.videoUrls) {
			const url = state.videoUrls.urls.find(vu => vu.fileName === fileName);
			if (url)
				return;
		}
		getJson(`api/videos/url/${fileName}`)
			.then(response => {
				dispatch({ type: 'RECEIVE_VIDEO_URL_SUCCESS', url: { fileName, url: response.url }, } as KnownAction);
			})
			.catch(handleWithActionAndErrorToast('Video', 'RECEIVE_VIDEO_URL_FAILURE'));
		dispatch({ type: 'REQUEST_VIDEO_URL', });
	},
};

// REDUCERS *******************************************************************

export const videoUrlsReducer: Reducer<VideoUrlsState | null> = (state: VideoUrlsState | null | undefined, action: KnownAction) => {
	if (state === undefined)
		return null;
	state = state!;	// @@@ I hope this works
	switch (action.type) {
		case 'REQUEST_VIDEO_URL':
			return {
				status: LoadStatus2.Loading,
				urls: state ? state.urls : [],
			};
		case 'RECEIVE_VIDEO_URL_SUCCESS':
			let urls = state ? state.urls.filter(u => u.fileName !== action.url.fileName) : [];
			urls.push(action.url);
			while (urls.length > numberOfUrlsToKeep) {
				urls = urls.filter((u, i) => i !== 0);
			}
			return {
				status: LoadStatus2.Idle,
				urls,
			};
		case 'RECEIVE_VIDEO_URL_FAILURE':
			return {
				status: LoadStatus2.Idle,
				urls: state.urls,
			};
		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		default: const exhaustiveCheck: never = action;
	}
	return state;
}
