import { Reducer } from 'redux';
import { store } from '../..';
import { List2Criteria, List2State, List2Status } from '../list/ListCommon';
import { getCriteriaUrl2 } from '../listSupport';
import { getJson, postJson } from '../myfetch';
import { dispatchErrorToast } from '../notification/NotificationStore';


// STATE **********************************************************************

export interface ResultUploadCodeListState {
	uploadCodesList?: List2State<ResultUploadCodeSummary>;
}

export interface ResultUploadCodeSummary {
	resultUploadCodeId: number;
	specialistPracticeName: string;
	securityCode: number;
	created: string;
	expires?: string;
	maximumUses: number;
	actualUses: number;
	invoicedUses: number;
	suppliedTo?: string;
}


export enum ResultUploadListOrderBy {
	Practice = 'Practice',
	Created = 'Created',
	Expires = 'Expires',
}

export enum ResultUploadListFilter1 {
	All = 'All',
}


// ACTIONS ********************************************************************

interface RequestLoadResultUploadCodeListAction { type: 'REQUEST_LOAD_RESULT_UPLOAD_CODE_LIST'; criteria: List2Criteria; }
interface ReceiveLoadResultUploadCodeListSuccessAction { type: 'RECEIVE_LOAD_RESULT_UPLOAD_CODE_LIST_SUCCESS'; numberOfPages: number; totalNumberOfItems: number; items: ResultUploadCodeSummary[]; }
interface ReceiveLoadResultUploadCodeListFailureAction { type: 'RECEIVE_LOAD_RESULT_UPLOAD_CODE_LIST_FAILURE'; }

interface InvalidateListOfResultUploadCodesAction { type: 'INVALIDATE_LIST_OF_RESULT_UPLOAD_CODES'; }

type KnownAction =
	RequestLoadResultUploadCodeListAction | ReceiveLoadResultUploadCodeListSuccessAction | ReceiveLoadResultUploadCodeListFailureAction
	| InvalidateListOfResultUploadCodesAction
	;


// ACTION INVOKERS ************************************************************

export const resultUploadCodeListActionInvokers = {
	requestLoadResultUploadCodes: async (criteria: List2Criteria) => {
		try {
			store.dispatch({ type: 'REQUEST_LOAD_RESULT_UPLOAD_CODE_LIST', criteria, } as KnownAction);
			const criteriaUrlSuffix = getCriteriaUrl2(criteria);
			const url = `api/specialists/uploadcodes?${criteriaUrlSuffix}`;
			const response = await getJson(url);
			store.dispatch({ type: 'RECEIVE_LOAD_RESULT_UPLOAD_CODE_LIST_SUCCESS', numberOfPages: response.numberOfPages, totalNumberOfItems: response.totalNumberOfItems, items: response.items, } as KnownAction);
		} catch (error) {
			store.dispatch({ type: 'RECEIVE_LOAD_RESULT_UPLOAD_CODE_LIST_FAILURE', } as KnownAction);
			dispatchErrorToast(true, 'Upload Codes', error);
		}
	},

	invalidateListOfUploadCodes: () => {
		store.dispatch({ type: 'INVALIDATE_LIST_OF_RESULT_UPLOAD_CODES', } as KnownAction);
	},

	requestNewUploadCode: async (specialistPracticeId: number | string, numberOfCodes: number, expires: string | undefined, maximumNumberOfUses: number, forFlyingOrganisationId: number | string | undefined, forSpecialistPracticeId: number | string | undefined): Promise<number[] | undefined> => {
		try {
			const response = await postJson(`api/specialists/${specialistPracticeId}/uploadcodes`, { numberOfCodes, expires, maximumNumberOfUses, forFlyingOrganisationId, forSpecialistPracticeId, });
			resultUploadCodeListActionInvokers.invalidateListOfUploadCodes();
			return response.securityCodes;
		} catch (error) {
			dispatchErrorToast(true, 'New Upload Code', error);
			return undefined;
        }
    },
}


// REDUCERS *******************************************************************

export const reducer: Reducer<ResultUploadCodeListState | null> = (state: ResultUploadCodeListState | null | undefined, action: KnownAction) => {
	if (state === undefined)
		return null;
	state = state!;
	switch (action.type) {
		case 'REQUEST_LOAD_RESULT_UPLOAD_CODE_LIST':
			return {
				uploadCodesList: {
					criteria: action.criteria,
					listStatus: List2Status.Loading,
					numberOfPages: 0,
					totalNumberOfItems: undefined,
					items: [],
                },
			} as ResultUploadCodeListState;
		case 'RECEIVE_LOAD_RESULT_UPLOAD_CODE_LIST_SUCCESS':
			return {
				...state,
				uploadCodesList: {
					criteria: state!.uploadCodesList!.criteria,
					listStatus: List2Status.Loaded,
					numberOfPages: action.numberOfPages,
					totalNumberOfItems: action.totalNumberOfItems,
					items: action.items,
				},
			} as ResultUploadCodeListState;
		case 'RECEIVE_LOAD_RESULT_UPLOAD_CODE_LIST_FAILURE':
			return {
				uploadCodesList: {
					...state!.uploadCodesList!,
					listStatus: List2Status.Failure,
				},
			} as ResultUploadCodeListState;
		case 'INVALIDATE_LIST_OF_RESULT_UPLOAD_CODES':
			return {
				uploadCodesList: {
					...state!.uploadCodesList!,
					listStatus: List2Status.Dirty,
				},
			} as ResultUploadCodeListState;
		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		default: const exhaustiveCheck: never = action;
	}
	return state;
}
