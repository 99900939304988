export enum LoadStatus2 {
	Idle,
	Loading,
	Loaded,
	Changed,
	Failure,
	Saving,
}

// From SkyCert v1

export enum SelectionStatus {
	Loading,
	Loaded,
	Failure,
	Saving,
	NewPractice,
	Created,
}
