import * as React from 'react';
import { AiOutlineSchedule } from 'react-icons/ai';
import { BiConversation } from 'react-icons/bi';
import { FaAngleDoubleDown, FaAngleDoubleUp, FaArrowDown, FaArrowLeft, FaArrowRight, FaArrowUp, FaBriefcaseMedical, FaCalendarAlt, FaCaretLeft, FaCaretRight, FaChartLine, FaCheckCircle, FaClinicMedical, FaCloudUploadAlt, FaEdit, FaExchangeAlt, FaExclamationTriangle, FaFileDownload, FaFileInvoiceDollar, FaFileMedicalAlt, FaFilm, FaFlask, FaHistory, FaInfoCircle, FaMinusCircle, FaPaperclip, FaPause, FaPencilAlt, FaPlay, FaRegCopy, FaRegFilePdf, FaReply, FaReplyAll, FaSave, FaSearch, FaShare, FaSortDown, FaSortUp, FaTable, FaTimes, FaTimesCircle, FaTrashAlt, FaTrashRestore, FaUser, FaUserFriends } from 'react-icons/fa';
import { FiRotateCw } from 'react-icons/fi';
import { GiSpy } from 'react-icons/gi';
import { ImBooks } from 'react-icons/im';
import { MdEmail } from 'react-icons/md';
import { RiCheckboxMultipleLine } from 'react-icons/ri';
import { Link, LinkProps } from 'react-router-dom';
import { Button, ButtonProps } from 'reactstrap';

export const Acceptable = (props: any) => <FaCheckCircle title='Acceptable' {...props} />;
export const Booking = (props: any) => <FaCalendarAlt title='Appointment booking' {...props} />;
export const ChangeView = (props: any) => <FaExchangeAlt title='Change view' {...props} />;
export const Clear = (props: any) => <FaTimes title='Clear' {...props} />;
export const Collapse = (props: any) => <FaAngleDoubleUp title='Collapse' {...props} />;
export const Conversation = (props: any) => <BiConversation title='View conversation' {...props} />;
export const CreatePdf = (props: any) => <FaRegFilePdf title='Create PDF' {...props} />;
export const Delete = (props: any) => <FaTrashAlt title='Delete/archive' {...props} />;
export const Edit = (props: any) => <FaPencilAlt title='Edit' {...props} />;
export const Email = (props: any) => <MdEmail title='Email' {...props} />;
export const Expand = (props: any) => <FaAngleDoubleDown title='Expand' {...props} />;
export const Forward = (props: any) => <FaShare title='Forward' {...props} />;
export const GraphReport = (props: any) => <FaChartLine title='Reports' {...props} />;
export const History = (props: any) => <FaHistory title='History / trends' {...props} />;
export const Impersonate = (props: any) => <GiSpy title='Impersonate' {...props} />;
export const Information = (props: any) => <FaInfoCircle {...props} />;
export const Invoices = (props: any) => <FaFileInvoiceDollar title='List of invoices' {...props} />;
export const Library = (props: any) => <ImBooks title='Document library' {...props} />;
export const LinkThis = (props: any) => <FaPaperclip title='Link into current medical' {...props} />;
export const Medical = (props: any) => <FaFileMedicalAlt {...props} />;
export const Medicals = (props: any) => <FaBriefcaseMedical title='All medicals' {...props} />;
export const MedicalTestsNeeded = (props: any) => <FaFlask title='Medical Tests Needed' {...props} />;
export const MoveDown = (props: any) => <FaArrowDown title='Move down' {...props} />;
export const MoveLeft = (props: any) => <FaArrowLeft title='Move left' {...props} />;
export const MoveRight = (props: any) => <FaArrowRight title='Move right' {...props} />;
export const MoveUp = (props: any) => <FaArrowUp title='Move up' {...props} />;
export const NewWindow = (props: any) => <FaRegCopy title='Open in a new window' {...props} />;
export const NextMonth = (props: any) => <FaCaretRight title='Next month' {...props} />;
export const Notes = (props: any) => <FaPencilAlt title='Notes' {...props} />;
export const NotAcceptable = (props: any) => <FaTimesCircle title='Not acceptable' {...props} />;
export const OpenDownload = (props: any) => <FaFileDownload title='Open / download' {...props} />;
export const Pause = (props: any) => <FaPause title='Pause updates' {...props} />;
export const Pilots = (props: any) => <FaUserFriends title='List of pilots' {...props} />;
export const Resume = (props: any) => <FaPlay title='Resume updates' {...props} />;
export const Practice = (props: any) => <FaClinicMedical title='Practice profile' {...props} />;
export const PreviousMonth = (props: any) => <FaCaretLeft title='Previous month' {...props} />;
export const Properties = (props: any) => <FaEdit title='Edit properties' {...props} />;
export const Remove = (props: any) => <FaMinusCircle title='Remove' {...props} />;
export const Refer = (props: any) => <FaShare title='Refer' {...props} />;
export const Reply = (props: any) => <FaReply title='Reply' {...props} />;
export const ReplyAll = (props: any) => <FaReplyAll title='Reply to all' {...props} />;
export const Reports = (props: any) => <FaChartLine title='Reports' {...props} />;
export const Requirements = (props: any) => <RiCheckboxMultipleLine title='Mandatory requirements' {...props} />;
export const Restore = (props: any) => <FaTrashRestore title='Restore' {...props} />;
export const Rotate = (props: any) => <FiRotateCw title='Rotate' {...props} />;
export const Save = (props: any) => <FaSave title='Save' {...props} />;
export const Saved = (props: any) => <FaSave {...props} />;
export const Search = (props: any) => <FaSearch title='Search' {...props} />;
export const SortAscending = (props: any) => <FaSortUp {...props} />;
export const SortDescending = (props: any) => <FaSortDown {...props} />;
export const TableReport = (props: any) => <FaTable {...props} />;
export const TestSchedules = (props: any) => <AiOutlineSchedule title='Test schedules' {...props} />;
export const Upload = (props: any) => <FaCloudUploadAlt {...props} />;
export const User = (props: any) => <FaUser title='User profile' {...props} />;
export const Videos = (props: any) => <FaFilm title='List of videos' {...props} />;
export const Warning = (props: any) => <FaExclamationTriangle {...props} />;

export interface InProps {
	icon: React.ComponentType<any> | JSX.Element;
	doubleSpace?: boolean;	// Not currently used
}

export const InLink = (props: InProps & LinkProps) => {
	const { icon, className, ...rest } = props;
	const useIcon = typeof icon === 'function' ? (icon as any)() : icon;
	const useClassName = 'text-decoration-none ' + (className || '');
	return <Link {...rest} className={useClassName}>&nbsp;{useIcon}&nbsp;</Link>
}

export const InButton = (props: InProps & ButtonProps) => {
	const { icon, className, ...rest } = props;
	const useIcon = typeof icon === 'function' ? (icon as any)() : icon;
	const useClassName = 'text-decoration-none p-0 ' + (className || '');
	return <Button color='link' {...rest} className={useClassName}>&nbsp;{useIcon}&nbsp;</Button>
}
