import * as React from 'react';
import Propellor from '../res/propellor-t.gif';

type LoadingProps = {
	action?: string;
};

export default (props: LoadingProps) => (
	<span>{props.action || 'Loading'}...<img src={Propellor} alt='' /></span>
)
