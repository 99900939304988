import * as React from 'react';
import Helmet from 'react-helmet';
import { RouteChildrenProps } from 'react-router';
import { Container } from 'reactstrap';
import * as Configuration from '../../Configuration';
import './Page.css';

export interface PageProps {
	wide?: boolean;
	defaultHelmet?: boolean | string;
	children?: ((props: RouteChildrenProps<any>) => React.ReactNode) | React.ReactNode;
}

export const Page = (props: PageProps) => {
	return (
		<Container className={'page' + (props.wide ? ' wide' : '')}>
			{props.defaultHelmet &&
				<Helmet>
					<title>{Configuration.text.siteName}{typeof props.defaultHelmet === 'string' ? ' - ' + props.defaultHelmet : ''}</title>
					<meta name='description' content='' />
					<meta name='robots' content='noindex, nofollow' />
				</Helmet>
			}
			{props.children}
		</Container>
	);
}
