import { Action, Reducer } from 'redux';
import { AppThunkAction } from '..';
import { ListCriteria, ListPage, ListState, ListStatus2 } from '../list/ListCommon';
import { criteriaMatches, findExistingPage, getCriteriaUrl } from '../listSupport';
import { getJson, handleWithAction } from '../myfetch';

// From SkyCert v1

// STATE **********************************************************************

const numberOfOrganisationsPagesToKeep: number = 3;

export interface OrganisationListState extends ListState<OrganisationSummary> { };

export interface OrganisationSummary {
	organisationId: string;
	name: string;
	isActive: boolean;
	numberOfStaff: number;
	numberOfPilots: number;
	numberOfLocations: number;
}

export enum OrganisationListOrderBy {
	Name = 'Name',
	NumberOfStaff = 'NumberOfStaff',
	NumberOfPilots = 'NumberOfPilots',
	NumberOfLocations = 'NumberOfLocations',
}

export enum OrganisationListFilter1 {
	All = 'All',
	Active = 'Active',
	Inactive = 'Inactive',
	OrganisationsWithoutStaff = 'OrganisationsWithoutStaff',
}

// ACTIONS ********************************************************************

interface RequestOrganisationListAction {
	type: 'REQUEST_ORGANISATION_LIST';
	criteria: ListCriteria;
}
interface ReceiveOrganisationListSuccessAction {
	type: 'RECEIVE_ORGANISATION_LIST_SUCCESS';
	numberOfPages: number;
	totalNumberOfItems: number;
	page: ListPage<OrganisationSummary>;
}
interface ReceiveOrganisationListFailureAction {
	type: 'RECEIVE_ORGANISATION_LIST_FAILURE';
}
interface InvalidateCurrentOrganisationListAction {
	type: 'INVALIDATE_CURRENT_ORGANISATION_LIST';
}
type KnownAction = RequestOrganisationListAction | ReceiveOrganisationListSuccessAction | ReceiveOrganisationListFailureAction
	| InvalidateCurrentOrganisationListAction
	;

// ACTION CREATORS ************************************************************

export const organisationListActionCreators = {
	requestOrganisationList: (criteria: ListCriteria): AppThunkAction<Action> => (dispatch, getState) => {
		const organisationState = getState().organisation;
		if (organisationState.list && organisationState.list.listStatus !== ListStatus2.Idle) {
			const state: OrganisationListState = organisationState.list!;
			if (criteriaMatches(criteria, state.criteria)
				&& findExistingPage<OrganisationSummary>(state, criteria, 'RECEIVE_ORGANISATION_LIST_SUCCESS', dispatch))
				return;
		}
		// The requested page is not already loaded.
		const criteriaUrlSuffix: string = getCriteriaUrl(criteria);
		getJson(`api/organisations?${criteriaUrlSuffix}`)
			.then(response => {
				dispatch({ type: 'RECEIVE_ORGANISATION_LIST_SUCCESS', numberOfPages: response.numberOfPages, page: response.page, totalNumberOfItems: response.totalNumberOfItems, } as KnownAction);
			})
			.catch(handleWithAction('RECEIVE_ORGANISATION_LIST_FAILURE'));
		dispatch({ type: 'REQUEST_ORGANISATION_LIST', criteria, } as KnownAction);
	},

	invalidateCurrentOrganisationsPage: () => ({ type: 'INVALIDATE_CURRENT_ORGANISATION_LIST' } as KnownAction),
};

// REDUCERS *******************************************************************

export const reducer: Reducer<OrganisationListState | null> = (state: OrganisationListState | null | undefined, action: KnownAction) => {
	if (state === undefined)
		return null;
	state = state!;	// @@@ I hope this works
	switch (action.type) {
		case 'REQUEST_ORGANISATION_LIST':
			const criteriaMatch: boolean = state && criteriaMatches(action.criteria, state.criteria);
			return {
				criteria: action.criteria,
				listStatus: ListStatus2.Loading,
				numberOfPages: criteriaMatch ? state.numberOfPages : 1,
				pages: criteriaMatch ? state.pages : [],
			};
		case 'RECEIVE_ORGANISATION_LIST_SUCCESS':
			let newPages: ListPage<OrganisationSummary>[] = [
				action.page,
				...state.pages.filter(page => page.pageNumber !== action.page.pageNumber),
			];
			while (newPages.length > numberOfOrganisationsPagesToKeep) newPages.pop();
			return {
				...state,
				criteria: {
					...state.criteria,
					pageNumber: action.page.pageNumber,
				},
				listStatus: ListStatus2.Loaded,
				numberOfPages: action.numberOfPages,
				totalNumberOfItems: action.totalNumberOfItems,
				pages: newPages,
			};
		case 'RECEIVE_ORGANISATION_LIST_FAILURE':
			return {
				...state,
				listStatus: ListStatus2.Failure,
			};
		case 'INVALIDATE_CURRENT_ORGANISATION_LIST':
			if (!state)
				return state;
			return {
				...state,
				listStatus: ListStatus2.Dirty,
				pages: [],
			}
		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		default: const exhaustiveCheck: never = action;
	}

	return state;
};
