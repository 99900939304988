import * as React from 'react';
import { MyDialog } from '../support/MyDialog';
import Footer from './Footer';
import NavMenu from './NavMenu';
import { Toaster } from './Toaster';

export default (props: { children?: React.ReactNode }) => (
    <>
        <MyDialog />
        <Toaster />
        <NavMenu />
        {props.children}
        <Footer />
    </>
);
