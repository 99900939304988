import { History } from 'history';
import * as React from 'react';
import { connect, useSelector } from 'react-redux';
import { Link, RouteComponentProps, useHistory } from 'react-router-dom';
import { Button, CardBody } from 'reactstrap';
import { ApplicationState } from '../../store';
import { MedicalStatus, SpecialDocumentType } from '../../store/medical/MedicalCommon';
import { SummaryLoadStatus2 } from '../../store/summary/SummaryCommon';
import { PilotMedicalState, userSummaryActionCreators, userSummaryActionInvokers } from '../../store/summary/UserSummaryStore';
import { NewMedicalDialog } from '../medical/medicalsupport/NewMedicalDialog';
import Loading from '../support/Loading';
import { StandardSummarySize } from '../support/Summaries';
import { View, ViewBody } from '../support/Views';


export const PilotMedicalSummary2 = () => {
	const pilotMedical = useSelector(state => (state as ApplicationState).summary.pilotMedical);
	const history = useHistory();

	React.useEffect(() => {
		userSummaryActionInvokers.requestPilotMedicalSummary();
	}, []);

	const newMedical = (applicationType: SpecialDocumentType | undefined) => applicationType && history.push('/medical/new-medical/' + applicationType);

	return (
		<NewMedicalDialog
			onNewMedical={newMedical}
			render={renderSummary(pilotMedical, history)}
		/>
	);
}


const renderSummary = (pilotMedical: PilotMedicalState | null, history: History<any>) => (newMedical: () => void) => {
	const goToMedical = () => {
		if (!pilotMedical) {
			// Do nothing - not loaded yet.
		} else if (pilotMedical.currentMedicalStatus === MedicalStatus.None) {
			newMedical();
		} else {	// Preparing, Processing, Completed
			history.push(`/medical/manage/${pilotMedical.currentMedicalId!}`);
		}
	}

	return (
		<StandardSummarySize>
			<View header='Medicals' onClickHeader={goToMedical}>
				<ViewBody>
					{renderBody(pilotMedical, newMedical)}
				</ViewBody>
			</View>
		</StandardSummarySize>
	);
}


const renderBody = (pilotMedical: PilotMedicalState | null, newMedical: () => void) => {
	if (!pilotMedical) {
		return null;	// This should only ever occur briefly
	}
	else if (pilotMedical.status === SummaryLoadStatus2.Loading) {
		return <Loading />;
	} else if (pilotMedical.status === SummaryLoadStatus2.Loaded) {
		return (
			<>
				<p>
					Current medical: {pilotMedical.currentMedicalStatus}
				</p>
				{pilotMedical.currentMedicalStatus === MedicalStatus.None && (
					<Button color='link' className='p-0' onClick={newMedical}><strong>Start application form</strong></Button>
				)}
				{pilotMedical.currentMedicalStatus !== MedicalStatus.None && (
					<>
						<Link to='/medical/pilot/me'>Past medicals</Link>
						<br />
						<Link to={`/medical/manage/${pilotMedical.currentMedicalId!}`}>View current medical</Link>
					</>
				)}
				{pilotMedical.currentMedicalStatus === MedicalStatus.Completed && (
					<>
						<br />
						<Link to='#' onClick={newMedical}>Start application form</Link>
					</>
				)}
			</>
		);
	} else if (pilotMedical.status === SummaryLoadStatus2.Failure) {
		return <p className='text-danger'>Unable to load.</p>;
	} else {	// SummaryLoadStatus2.Idle, blank
		return null;	// This should only ever occur briefly.
	}
}






// TODO @@@ The remainder is obsolete




// From SkyCert v1

type PilotMedicalSummaryProps =
	PilotMedicalState
	& typeof userSummaryActionCreators
	& RouteComponentProps<{}>;

// Architecture: v2

class PilotMedicalSummary extends React.Component<PilotMedicalSummaryProps, {}> {
	// eslint-disable-next-line @typescript-eslint/no-useless-constructor
	constructor(props: any) {
		super(props);
	}

	componentDidMount() {
		this.props.requestPilotMedicalSummary();
    }

	public render() {
		return <StandardSummarySize>
			<View header='Medicals' onClickHeader={this.goToMedicals}>
				<CardBody>
					{this.renderBody()}
				</CardBody>
            </View>
		</StandardSummarySize>;
	}

	goToMedicals = () => {
		if (this.props.currentMedicalStatus === MedicalStatus.None) {
			this.props.history.push('/medical/new-medical');
		} else {	// Preparing, Processing, Completed
			this.props.history.push(`/medical/manage/${this.props.currentMedicalId!}`);
		}
	}

	private renderBody() {
		if (this.props.status === SummaryLoadStatus2.Loading) {
			return <Loading />;
		} else if (this.props.status === SummaryLoadStatus2.Loaded) {
			return (
				<>
					<p>
						Current medical: {this.props.currentMedicalStatus}
					</p>
					{this.props.currentMedicalStatus === MedicalStatus.None &&
						<strong><Link to='/medical/new-medical'>Start application form</Link></strong>
					}
					{this.props.currentMedicalStatus !== MedicalStatus.None &&
						<>
							<Link to='/medical/pilot/me'>Past medicals</Link>
							<br />
							<Link to={`/medical/manage/${this.props.currentMedicalId!}`}>View current medical</Link >
						</>
					}
					{this.props.currentMedicalStatus === MedicalStatus.Completed &&
						<>
							<br />
							<Link to='/medical/new-medical'>Start application form</Link>
						</>
					}
				</>
			);
		} else if (this.props.status === SummaryLoadStatus2.Failure) {
			return <p className='text-danger'>Unable to load.</p>;
		} else {	// SummaryLoadStatus2.Idle, blank
			return null;	// This should only ever occur briefly.
		}
	}
}

export default connect(
	(state: ApplicationState) => state.summary.pilotMedical || {},
	userSummaryActionCreators
)(PilotMedicalSummary) /*as typeof PilotMedicalSummary*/;
