import { Action, Reducer } from 'redux';
import { AppThunkAction } from '..';
import { ListCriteria, ListPage, ListState, ListStatus2 } from '../list/ListCommon';
import { criteriaMatches, findExistingPage, getCriteriaUrl } from '../listSupport';
import { getJson, handleWithAction } from '../myfetch';
import { Invoice } from './PracticeCommon';

// From SkyCert v1

// STATE **********************************************************************

const numberOfInvoicesPagesToKeep: number = 3;

export interface InvoiceListState extends ListState<Invoice> {
	medicalPracticeId: string;
}

export enum InvoicesListOrderBy {
	Practice = 'Practice',
	Number = 'Number',
	Date = 'Date',
	Paid = 'Paid',
}

export enum InvoicesListFilter1 {
	All = 'All',
	Invoices = 'Invoices',
	CreditNotes = 'CreditNotes',
}

// ACTIONS ********************************************************************

interface RequestInvoiceListAction {
	type: 'REQUEST_INVOICE_LIST';
	medicalPracticeId: string;
	criteria: ListCriteria;
}
interface ReceiveInvoiceListSuccessAction {
	type: 'RECEIVE_INVOICE_LIST_SUCCESS';
	numberOfPages: number;
	totalNumberOfItems: number;
	page: ListPage<Invoice>;
}
interface ReceiveInvoiceListFailureAction { type: 'RECEIVE_INVOICE_LIST_FAILURE'; }

interface InvalidateCurrentInvoiceListAction { type: 'INVALIDATE_CURRENT_INVOICE_LIST'; }

type KnownAction = RequestInvoiceListAction | ReceiveInvoiceListSuccessAction | ReceiveInvoiceListFailureAction
	| InvalidateCurrentInvoiceListAction
	;

// ACTION CREATORS ************************************************************

export const invoiceListActionCreators = {
	requestInvoiceList: (medicalPracticeId: string, criteria: ListCriteria): AppThunkAction<Action> => (dispatch, getState) => {
		const practiceState = getState().practice;
		if (practiceState.listOfInvoices && practiceState.listOfInvoices.listStatus !== ListStatus2.Idle) {
			const state: InvoiceListState = practiceState.listOfInvoices!;
			if (medicalPracticeId === state.medicalPracticeId
				&& criteriaMatches(criteria, state.criteria)
				&& findExistingPage<Invoice>(state, criteria, 'RECEIVE_INVOICE_LIST_SUCCESS', dispatch))
				return;
		}
		// The requested page is not already loaded.
		const criteriaUrlSuffix: string = getCriteriaUrl(criteria);
		const url: string = `api/practices/${medicalPracticeId}/invoices?${criteriaUrlSuffix}`;
		getJson(url)
			.then(response => {
				dispatch({ type: 'RECEIVE_INVOICE_LIST_SUCCESS', numberOfPages: response.numberOfPages, page: response.page, totalNumberOfItems: response.totalNumberOfItems, } as KnownAction);
			})
			.catch(handleWithAction('RECEIVE_INVOICE_LIST_FAILURE'));
		dispatch({ type: 'REQUEST_INVOICE_LIST', medicalPracticeId, criteria, } as KnownAction);
	},

	invalidateCurrentInvoicesPage: () => ({ type: 'INVALIDATE_CURRENT_INVOICE_LIST' } as KnownAction),
};

// REDUCERS *******************************************************************

export const reducer: Reducer<InvoiceListState | null> = (state: InvoiceListState | null | undefined, action: KnownAction) => {
	if (state === undefined)
		return null;
	state = state!;	// @@@ I hope this works
	switch (action.type) {
		case 'REQUEST_INVOICE_LIST':
			const criteriaMatch: boolean = state && criteriaMatches(action.criteria, state.criteria);
			return {
				medicalPracticeId: action.medicalPracticeId,
				criteria: action.criteria,
				listStatus: ListStatus2.Loading,
				numberOfPages: criteriaMatch ? state.numberOfPages : 1,
				pages: criteriaMatch ? state.pages : [],
				canCreateNewApplication: false,
			};
		case 'RECEIVE_INVOICE_LIST_SUCCESS':
			let newPages: ListPage<Invoice>[] = [
				action.page,
				...state.pages.filter(page => page.pageNumber !== action.page.pageNumber),
			];
			while (newPages.length > numberOfInvoicesPagesToKeep) newPages.pop();
			return {
				...state,
				criteria: {
					...state.criteria,
					pageNumber: action.page.pageNumber,
				},
				listStatus: ListStatus2.Loaded,
				numberOfPages: action.numberOfPages,
				totalNumberOfItems: action.totalNumberOfItems,
				pages: newPages,
			};
		case 'RECEIVE_INVOICE_LIST_FAILURE':
			return {
				...state,
				listStatus: ListStatus2.Failure,
			};
		case 'INVALIDATE_CURRENT_INVOICE_LIST':
			if (!state)
				return state;
			return {
				...state,
				listStatus: ListStatus2.Dirty,
				pages: [],
			}
		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		default: const exhaustiveCheck: never = action;
	}

	return state;
};
