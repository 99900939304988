import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { CardBody } from 'reactstrap';
import * as Configuration from '../../Configuration';
import { ApplicationState } from '../../store';
import { SummaryLoadStatus2 } from '../../store/summary/SummaryCommon';
import { userSummaryActionCreators } from '../../store/summary/UserSummaryStore';
import { FeatureSetFlags } from '../../store/user/LoginStore';
import NewFeature from '../support/NewFeature';
import { StandardSummarySize } from '../support/Summaries';
import { View } from '../support/Views';

export default () => {
	const userVideos = useSelector(state => (state as ApplicationState).summary.userVideos);
	const globalSettings = useSelector(state => (state as ApplicationState).globalSettings);
	const loggedIn = useSelector(state => (state as ApplicationState).user.login.loggedIn);
	const history = useHistory();
	const dispatch = useDispatch();

	React.useEffect(() => {
		dispatch(userSummaryActionCreators.requestUserVideosSummary());
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<StandardSummarySize>
			<View header='Information' onClickHeader={() => history.push('/info/pilot')}>
				<CardBody>
					<div>
						<div><Link to='/info/pilot'>{loggedIn!.displayRole} information</Link></div>
						{Configuration.enableFeature.medicals &&
							<>
								<div><Link to='/info/pilot/first-medical'>Get your first medical</Link></div>
								<div><Link to='/info/pilot/renew-medical'>Renew your medical</Link></div>
								<div><Link to='/info/pilot/find-practice'>Find a practice</Link></div>
								{(loggedIn!.featureSet & FeatureSetFlags.Medical_Tests_Needed) !== 0 &&
									<NewFeature id='MedicalTestsNeeded'
										endDate='1 Aug 2021'
										description='The linked page will give an indication of what standard tests are required based on your age at the GME.'>
										<div><Link to='/medical/what-tests/me'>What tests are needed</Link></div>
									</NewFeature>
								}
								<div><a href={globalSettings.publicStorageUrl + '/SkyCert-Age-Chart.pdf'} target='_blank' rel='noopener noreferrer'>SkyCert Age Chart</a></div>
							</>
						}
						<NewFeature id='HelpVideos'
							force={userVideos?.status === SummaryLoadStatus2.Loaded ? undefined : false}
							expected={userVideos?.summary.dateOfLastVideo}
							description='New help videos have been added.'
						>
							<div><Link to='/info/videos'>Help videos</Link></div>
						</NewFeature>
					</div>
				</CardBody>
			</View>
		</StandardSummarySize>
	);
}
