import * as React from 'react';
import { useDispatch } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { CardBody } from 'reactstrap';
import { userSummaryActionCreators } from '../../store/summary/UserSummaryStore';
import { StandardSummarySize } from '../support/Summaries';
import { View } from '../support/Views';

export const SpecialistInfoSummary = () => {
	const history = useHistory();
	const dispatch = useDispatch();

	React.useEffect(() => {
		dispatch(userSummaryActionCreators.requestUserVideosSummary());
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<StandardSummarySize>
			<View header='Information' onClickHeader={() => history.push('/info/specialist')}>
				<CardBody>
					<div>
						<div><Link to='/info/specialist'>Specialist information</Link></div>
						<div><Link to='/info/videos'>Help videos</Link></div>
					</div>
				</CardBody>
			</View>
		</StandardSummarySize>
	);
}
