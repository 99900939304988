import * as React from 'react';
import { useState } from 'react';
import { RouteChildrenProps, RouteComponentProps } from 'react-router';
import { Col, CustomInput, FormGroup, FormText, Input, Label, Row } from 'reactstrap';
import { FormField, FormLabel } from './FormFields';
import { TextAreaEditor } from './TextEditors';


///////////////////////////////////////////////////////////////////////////////

interface YesNoEditorProps {
	id?: string;
	name?: string;
	label: string;
	value?: any;
	additionalText?: string;
	hidden?: boolean;
	readOnly?: boolean;
	required?: boolean;
	horizontal?: boolean | number;
	errorText?: string;
	yesText?: string;
	noText?: string;
	className?: string;
	controlClassName?: string;
	attr?: any;
	onChangeField?: (name: string, value: any, isRequired: boolean) => void;
	onChangeValue?: (value: string | undefined) => void;
	onChangeFieldRaw?: (event: React.ChangeEvent<HTMLInputElement>) => void;
	children?: ((props: RouteChildrenProps<any>) => React.ReactNode) | React.ReactNode;
}

export const YesNoEditor = (props: YesNoEditorProps) => {
	if (props.hidden)
		return null;
	const isOk = !props.required || props.readOnly || props.value;
	const xs1: number = !props.horizontal ? 7
		: typeof props.horizontal === 'number' ? props.horizontal : 3;
	const xs2: number = !props.horizontal ? 2 : 3;
	const id = props.id || props.name || 'YesNoEditor';
	return (
		<FormGroup row className={(props.className || '') + (isOk ? '' : ' was-validated')}>
			<Col xs={xs1}>
				<Label>{props.label}</Label>
			</Col>
			<OptionalYesNoRowCol xs={props.horizontal ? 12 - xs1 : undefined}>
				<Col xs={xs2} className={props.controlClassName}>
					<CustomInput type='checkbox' id={id + '-yes'}
						name={props.name}
						label={props.yesText || 'Yes'}
						checked={props.value === 'yes'}
						onChange={e => changeYesNoField(e, props)}
						className={props.controlClassName}
						required={!isOk}
						disabled={props.readOnly}
						{...props.attr}
					/>
				</Col>
				<Col xs={xs2} className={props.controlClassName}>
					<CustomInput type='checkbox' id={id + '-no'}
						name={props.name}
						label={props.noText || 'No'}
						checked={props.value === 'no'}
						onChange={e => changeYesNoField(e, props)}
						className={props.controlClassName}
						required={!isOk}
						disabled={props.readOnly}
						{...props.attr}
					/>
				</Col>
				{!isOk && 
					<Col xs={12}>
						<FormText color='danger'>* {props.errorText || 'Please select Yes or No'}</FormText>
						<br />
					</Col>
				}
				{props.additionalText && 
					<Col xs={12}>
						<FormText color='muted'>
							{props.additionalText}
						</FormText>
					</Col>
				}
				{props.children &&
					<Col xs={12}>
						{props.children}
					</Col>
				}
			</OptionalYesNoRowCol>
		</FormGroup>
	);
}

const changeYesNoField = (event: React.ChangeEvent<HTMLInputElement>, props: YesNoEditorProps) => {
	if (props.onChangeField || props.onChangeValue) {
		const target = event.target;
		let value = undefined;
		if (target.id.endsWith('yes'))
			value = target.checked ? 'yes' : '';
		else if (target.id.endsWith('no'))
			value = target.checked ? 'no' : '';
		if (props.onChangeField)
			props.onChangeField(props.name!, value, !!props.required);
		if (props.onChangeValue)
			props.onChangeValue(value);
	}
	if (props.onChangeFieldRaw)
		props.onChangeFieldRaw(event);
}

interface OptionalYesNoRowColProps {
	xs?: number;
	children?: ((props: RouteChildrenProps<any>) => React.ReactNode) | React.ReactNode;
}

const OptionalYesNoRowCol = (props: OptionalYesNoRowColProps) => {
	if (props.xs) {
		return (
			<Col xs={props.xs}>
				<Row>
					{props.children}
				</Row>
			</Col>
		);
	} else {
		return <>{props.children}</>;
	}
}


///////////////////////////////////////////////////////////////////////////////

interface CheckboxEditorProps {
	id?: string;
	name?: string;
	label: string;
	value?: any;
	additionalText?: string;
	hidden?: boolean;
	readOnly?: boolean;
	required?: boolean;
	horizontal?: boolean | number;
	errorText?: string;
	className?: string;
	controlClassName?: string;
	attr?: any;
	onChangeField?: (name: string, value: any, isRequired: boolean) => void;
	onChangeValue?: (value: boolean) => void;
	onChangeFieldRaw?: (event: React.ChangeEvent<HTMLInputElement>) => void;
	children?: ((props: RouteChildrenProps<any>) => React.ReactNode) | React.ReactNode;
}

export const CheckboxEditor = (props: CheckboxEditorProps) => {
	if (props.hidden)
		return null;
	const value = typeof props.value === 'boolean' ? !!props.value : props.value === 'true';
	const isOk = !props.required || props.readOnly || value;
	const xs1: number = !props.horizontal ? 1
		: typeof props.horizontal === 'number' ? props.horizontal : 3;
	const id = props.id || props.name || 'CheckboxEditor';
	return (
		<FormGroup row className={(props.className || '') + (isOk ? '' : ' was-validated')}>
			{props.horizontal &&
				<FormLabel horizontal={props.horizontal} for={id}>{props.label}</FormLabel>
			}
			<FormField horizontal={xs1}>
				<CustomInput type='checkbox' id={id}
					name={props.name || props.id}
					label={props.horizontal ? '' : props.label}
					checked={value}
					onChange={e => changeCheckboxField(e, props)}
					className={(props.controlClassName || '') + ' font-weight-bold'}
					required={!isOk}
					disabled={props.readOnly}
					{...props.attr}
				/>
				{!isOk &&
					<FormText color='danger'>* {props.errorText || 'Please select this option'}</FormText>
				}
				{props.additionalText &&
					<FormText color='muted'>{props.additionalText}</FormText>
				}
				{props.children}
			</FormField>
		</FormGroup>
	);
}

const changeCheckboxField = (event: React.ChangeEvent<HTMLInputElement>, props: CheckboxEditorProps) => {
	const target = event.target;
	if (props.onChangeField)
		props.onChangeField(props.name!, target.checked, !!props.required);
	if (props.onChangeValue)
		props.onChangeValue(event.target.checked);
	if (props.onChangeFieldRaw)
		props.onChangeFieldRaw(event);
}


///////////////////////////////////////////////////////////////////////////////

export type EditorWithExplainProps = {
	explanation: string;
	inverted?: boolean;
	onChangeExplanation?: (explanation: string) => void;
}

const editorWithExplain = (props: any, Editor: React.ComponentType<RouteComponentProps<any>> | React.ComponentType<any>, value: any, explainIf: any) => {
	const { className, explanation, inverted, onChangeExplanation, ...editorProps } = props;
	const showExplain = value === explainIf;
	return <>
		<Editor
			{...editorProps}
			value={value}
		>
			{showExplain &&
				<TextAreaEditor
					name={props.id + 'ex'}
					value={props.explanation}
					label=''
					placeholder='Please explain'
					required
					readOnly={props.isReadOnly}
					onChangeValue={props.onChangeExplanation}
					additionalText='Include relevant year(s)'
				/>
			}
		</Editor>
	</>;
}


export const CheckboxExplainEditor = (props: CheckboxEditorProps & EditorWithExplainProps) =>
	editorWithExplain(props, CheckboxEditor, typeof props.value === 'boolean' ? !!props.value : props.value === 'true', !props.inverted);

export const YesNoExplainEditor = (props: YesNoEditorProps & EditorWithExplainProps) =>
	editorWithExplain(props, YesNoEditor, props.value, props.inverted ? 'no' : 'yes');


///////////////////////////////////////////////////////////////////////////////

interface CheckboxesEditorProps {
	id?: string;
	name?: string;
	label: string;	// List delimited with |.
	value?: any;
	additionalText?: string;
	options: string[];
	ids?: string[];	// If different from options.  Defaults to options.
	editable?: boolean;
	multiline?: boolean;
	hidden?: boolean;
	readOnly?: boolean;
	required?: boolean;
	horizontal?: boolean | number;
	errorText?: string;
	className?: string;
	controlClassName?: string;
	attr?: any;
	onChangeField?: (name: string, value: any, isRequired: boolean) => void;
	onChangeValue?: (value: string) => void;
	onChangeFieldRaw?: (event: React.ChangeEvent<HTMLInputElement>) => void;
	children?: ((props: RouteChildrenProps<any>) => React.ReactNode) | React.ReactNode;
}

export const CheckboxesEditor = (props: CheckboxesEditorProps) => {
	if (props.hidden)
		return null;
	const ids = props.ids || props.options;
	let values = ((props.value || '') as string).split('|');
	const isOk = !props.required || !!props.value;
	const id = props.id || props.name || 'CheckboxesEditor';
	return (
		<FormGroup row={!!props.horizontal} className={(props.className || '') + (isOk ? '' : ' was-validated')}>
			<FormLabel horizontal={props.horizontal} for={id + '-' + ids[0]}>{props.label}</FormLabel>
			<FormField horizontal={props.horizontal}>
				<div>
					{props.options.map((option, iOption) => {
						const oId = ids[iOption];
						const iValue = values.indexOf(oId);
						const checked = iValue !== -1;
						if (checked)
							values = values.filter((v, iv2) => iv2 !== iValue);
						return (
							<CustomInput type='checkbox' id={id + '-' + oId} key={oId}
								name={props.name}
								label={option}
								checked={checked}
								onChange={e => changeCheckboxesField(e, props)}
								className={props.controlClassName}
								required={!isOk}
								disabled={props.readOnly}
								inline={!props.multiline}
								{...props.attr}
							/>
						);
					})}
					{props.editable &&
						<Row>
							<Col xs={1}>
								<CustomInput type='checkbox' id={id + '--extra'}
									name={props.name}
									checked={values.length === 1 && values[0]}
									onChange={e => changeCheckboxesField(e, props)}
									className={props.controlClassName}
									required={!isOk}
									disabled={props.readOnly}
									{...props.attr}
								/>
							</Col>
							<Col xs={6}>
								<Input type='text' id={id + '--edit'}
									value={values.length === 1 ? values[0] : ''}
									disabled={props.readOnly}
									required={!isOk}
									onChange={e => changeCheckboxesField(e, props)}
									className={props.controlClassName}
									{...props.attr}
								/>
							</Col>
						</Row>
					}
				</div>
				{!isOk &&
					<FormText color='danger'>* {props.errorText || 'Please select an option'}</FormText>
				}
				{props.additionalText &&
					<FormText color='muted'>{props.additionalText}</FormText>
				}
				{props.children}
			</FormField>
		</FormGroup>
	);
}

const changeCheckboxesField = (event: React.ChangeEvent<HTMLInputElement>, props: CheckboxesEditorProps) => {
	if (props.onChangeField || props.onChangeValue) {
		const id = props.id || props.name || 'CheckboxesEditor';
		const target = event.target;
		let values = ((props.value || '') as string).split('|');
		let value = target.id.substr(id.length + 1);
		if (value === '-extra') {
			if (!target.checked) {
				const ids = props.ids || props.options;
				values = values.filter(v => ids.indexOf(v) !== -1);
			}
		} else if (value === '-edit') {
			const ids = props.ids || props.options;
			values = values.filter(v => ids.indexOf(v) !== -1);
			values.push(target.value);
		} else {
			if (!!props.ids) {
				const index = props.options.indexOf(value);
				if (index !== -1)
					value = props.ids![index];
			}
			if (event.target.checked) {
				if (values.length === 1 && values[0] === '')
					values[0] = value;
				else values.push(value);
			} else {
				const iValue = values.indexOf(value);
				if (iValue !== -1)
					values = values.filter((v, iv2) => iv2 !== iValue);
			}
		}
		const setValue = values.join('|');
		if (props.onChangeField)
			props.onChangeField(props.name!, setValue, !!props.required);
		if (props.onChangeValue)
			props.onChangeValue(setValue);
	}
	if (props.onChangeFieldRaw)
		props.onChangeFieldRaw(event);
}


///////////////////////////////////////////////////////////////////////////////

interface RadiosEditorProps {
	id?: string;
	name?: string;
	label: string;
	value?: any;
	additionalText?: string;
	options: string[];
	ids?: string[];	// If different from options.  Defaults to options.
	editable?: boolean;
	multiline?: boolean;
	hidden?: boolean;
	readOnly?: boolean;
	required?: boolean;
	horizontal?: boolean | number;
	errorText?: string;
	className?: string;
	controlClassName?: string;
	attr?: any;
	onChangeField?: (name: string, value: any, isRequired: boolean) => void;
	onChangeValue?: (value: string) => void;
	onChangeFieldRaw?: (event: React.ChangeEvent<HTMLInputElement>) => void;
	children?: ((props: RouteChildrenProps<any>) => React.ReactNode) | React.ReactNode;
}

export const RadiosEditor = (props: RadiosEditorProps) => {
	if (props.hidden)
		return null;
	const ids = props.ids || props.options;
	const isOk = !props.required || !!props.value;
	let isMatched = false;
	const id = props.id || props.name || 'RadiosEditor';
	return (
		<FormGroup row={!!props.horizontal} className={(props.className || '') + (isOk ? '' : ' was-validated')}>
			{props.label && <FormLabel horizontal={props.horizontal} for={id + '-' + ids[0]}>{props.label}</FormLabel>}
			<FormField horizontal={props.horizontal}>
				<div>
					{props.options.map((option, iOption) => {
						const oId = ids[iOption];
						const checked = oId === props.value;
						if (checked)
							isMatched = true;
						return (
							<CustomInput type='radio' id={id + '-' + oId} key={iOption}
								name={props.name}
								label={option}
								checked={checked}
								onChange={e => changeRadiosField(e, props)}
								className={props.controlClassName}
								required={!isOk}
								disabled={props.readOnly}
								inline={!props.multiline}
								{...props.attr}
							/>
						);
					})}
					{props.editable &&
						<Row>
							<Col xs={1}>
								<CustomInput type='radio' id={id + '--extra'}
									name={props.name}
									checked={!isMatched && props.value}
									onChange={e => changeRadiosField(e, props)}
									className={props.controlClassName}
									required={!isOk}
									disabled={props.readOnly}
									{...props.attr}
								/>
							</Col>
							<Col xs={6}>
								<Input type='text' id={id + '--edit'}
									value={isMatched ? '' : props.value}
									disabled={props.readOnly}
									required={!isOk}
									onChange={e => changeRadiosField(e, props)}
									className={props.controlClassName}
									{...props.attr}
								/>
							</Col>
						</Row>
					}
				</div>
				{!isOk &&
					<FormText color='danger'>* {props.errorText || 'Please select an option'}</FormText>
				}
				{props.additionalText &&
					<FormText color='muted'>{props.additionalText}</FormText>
				}
				{props.children}
			</FormField>
		</FormGroup>
	);
}

const changeRadiosField = (event: React.ChangeEvent<HTMLInputElement>, props: RadiosEditorProps) => {
	if (props.onChangeField || props.onChangeValue) {
		const target = event.target;
		const id = props.id || props.name || 'RadiosEditor';
		let value = target.id.substr(id.length + 1);
		if (value === '-edit')
			value = target.value;
		if (props.onChangeField)
			props.onChangeField(props.name!, value, !!props.required);
		if (props.onChangeValue)
			props.onChangeValue(value);
	}
	if (props.onChangeFieldRaw)
		props.onChangeFieldRaw(event);
}


///////////////////////////////////////////////////////////////////////////////

interface SelectEditorProps {
	id: string;
	name?: string;
	label: string;
	value?: any;
	valueIsNumber?: boolean;
	additionalText?: string;
	prompt?: string;
	promptForUnselect?: string;
	hidden?: boolean;
	readOnly?: boolean;
	required?: boolean;
	horizontal?: boolean | number;
	errorText?: string;
	className?: string;
	controlClassName?: string;
	attr?: any;
	onChangeField?: (name: string, value: any, isRequired: boolean) => void;
	onChangeValue?: (value: string) => void;
	onChangeFieldRaw?: (event: React.ChangeEvent<HTMLInputElement>) => void;
	children?: ((props: RouteChildrenProps<any>) => React.ReactNode) | React.ReactNode;
}

export const SelectEditor = (props: SelectEditorProps) => {
	if (props.hidden)
		return null;
	const isOk: boolean = !props.required || !!props.value;
	const id = props.id || props.name;
	return (
		<FormGroup row={!!props.horizontal} className={(props.className || '') + (isOk ? '' : ' was-validated')}>
			<FormLabel for={id} horizontal={props.horizontal}>{props.label}</FormLabel>
			<FormField horizontal={props.horizontal}>
				<CustomInput type='select' id={id}
					name={props.name}
					value={props.value || ''}
					disabled={props.readOnly}
					required={props.required}
					onChange={e => changeSelectField(e, props)}
					className={props.controlClassName}
					{...props.attr}
				>
					{props.required && !props.value &&
						<option value=''>{props.prompt || 'Please select one...'}</option>
					}
					{!props.required &&
						<option value=''>{props.promptForUnselect || 'None'}</option>
					}
					{props.children}
				</CustomInput>
				{!isOk && !props.readOnly &&
					<FormText color='danger'>* {props.errorText || 'Please select an option'}</FormText>
				}
				{props.additionalText &&
					<FormText color='muted'>{props.additionalText}</FormText>
				}
			</FormField>
		</FormGroup>
	);
}

const changeSelectField = (event: React.ChangeEvent<HTMLInputElement>, props: SelectEditorProps) => {
	if (props.onChangeField || props.onChangeValue) {
		const target = event.target;
		let value: any = target.value;
		if (!props.required || value !== '') {
			if (props.valueIsNumber)
				value = value === '' ? 0 : parseInt(value);
			if (props.onChangeField)
				props.onChangeField(props.name!, value, target.required);
			if (props.onChangeValue)
				props.onChangeValue(value);
		}
	}
	if (props.onChangeFieldRaw)
		props.onChangeFieldRaw(event);
}


///////////////////////////////////////////////////////////////////////////////

interface SelectFileEditorProps {
	id?: string;
	name?: string;
	label: string;
	files?: FileList;
	additionalText?: string;
	hidden?: boolean;
	readOnly?: boolean;
	required?: boolean;
	multiple?: boolean;
	horizontal?: boolean | number;
	errorText?: string;
	className?: string;
	accept?: string;
	controlClassName?: string;
	attr?: any;
	onChangeValue?: (files: FileList | undefined) => void;
	onChangeFieldRaw?: (event: React.ChangeEvent<HTMLInputElement>) => void;
	children?: ((props: RouteChildrenProps<any>) => React.ReactNode) | React.ReactNode;
}

export const SelectFileEditor = (props: SelectFileEditorProps) => {
	const [key, setKey] = useState(0);
	const [hasFiles, setHasFiles] = useState(!!props.files);

	if (hasFiles && !props.files)
		setKey(key + 1);
	const newHasFiles = !!props.files;
	if (hasFiles !== newHasFiles)
		setHasFiles(newHasFiles);

	if (props.hidden)
		return null;
	const isOk: boolean = !props.required || !!props.files;
	const id = props.id || props.name;
	return (
		<FormGroup row={!!props.horizontal} className={(props.className || '') + (isOk ? '' : ' was-validated')}>
			<FormLabel for={id} horizontal={props.horizontal}>{props.label}</FormLabel>
			<FormField horizontal={props.horizontal}>
				<CustomInput type='file' id={id} key={key}
					name={props.name}
					files={props.files}
					disabled={props.readOnly}
					required={props.required}
					multiple={!!props.multiple}
					onChange={e => changeSelectFileField(e, props)}
					className={props.controlClassName + ' text-nowrap'}
					accept={props.accept}
					{...props.attr}
				>
					{props.children}
				</CustomInput>
				{!isOk && !props.readOnly &&
					<FormText color='danger'>* {props.errorText || 'Please select a file'}</FormText>
				}
				{props.additionalText &&
					<FormText color='muted'>{props.additionalText}</FormText>
				}
			</FormField>
		</FormGroup>
	);
}

const changeSelectFileField = (event: React.ChangeEvent<HTMLInputElement>, props: SelectFileEditorProps) => {
	if (props.onChangeValue) {
		const files = event.target.files;
		props.onChangeValue(files || undefined);
	}
	if (props.onChangeFieldRaw)
		props.onChangeFieldRaw(event);
}
