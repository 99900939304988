// This should reflect FieldLength.cs.
export enum FieldLength {
    UserIdLength = 50,
    UserNameLength = 50,
    PasswordMinimumLength = 8,
    PasswordMaximumLength = 20,
    SecurityQuestionLength = 20,
    SecurityQuestionEncryptedLength = 90,
    TwoFactorSecretLength = 20,
    TwoFactorSecretEncryptedLength = 90,

    RoleNameLength = 30,
    FirstNameLength = 50,
    MiddleNamesLength = 100,
    LastNameLength = 50,
    KnownAsLength = 50,
    Initials = 5,
    PreviousNamesLength = 100,
    TitleLength = 20,
    GenderLength = 1,
    DateOfDeathLength = 20,
    OccupationLength = 30,

    HealthIdLength = 20,
    JurisdictionIdLength = 20,
    RegulatoryAuthorityIdLength = 10,
    EmailLength = 50,
    PhoneNumberLength = 20,
    WebsiteLength = 80,
    SecurityCode = 16,

    DoctorRegistrationId = 16,
    InformationTestCode = 40,

    AddressLength = 100,
    SuburbLength = 50,
    CityLength = 50,
    StateLength = 50,
    PostcodeLength = 10,
    CountryLength = 20,

    DocumentNameLength = 80,
    DocumentGroupNameLength = 20,
    DocumentAlternativeNameLength = 24,
    DocumentCodeLength = 20,
    FileNameLength = 50,
    FieldNameLength = 600,
    DocumentCodesList = 100,
    ReasonLength = 30,
    UploadedByGroup = 8,

    SectionNameLength = 50,
    SectionPanelModeLength = 10,
    SectionGroupNameLength = 30,
    QuestionTextLength = 200,
    QuestionOptionsLength = 200,
    QuestionPrefixLength = 10,
    QuestionSuffixLength = 10,
    QuestionPatternLength = 100,
    AnswerValueLength = 50,

    EndorsementNameLength = 5,
    EndorsementDescriptionLength = 300,

    MedicalExaminerJurisdictionsLength = 50,

    MedicalPracticeNameLength = 50,
    LocationNameLength = 100,
    LocationAddressLength = 200,
    PracticeTestsAvailableLength = 60,
    RegionNameLength = 50,
    MedicalPracticeLogoLength = 32,

    FlyingOrganisationNameLength = 50,
    EmployeeIdLength = 16,

    UserEventDescriptionLength = 200,
    AuditLogDescriptionLength = 200,

    SettingNameLength = 50,
    SettingTypeLength = 20,
    SettingValueLength = 50,

    StandardDefaultValueNameLength = 50,

    ChecklistCodeLength = 10,
    ChecklistNameLength = 20,
    ChecklistBox3LabelLength = 20,
    ChecklistItemTextLength = 50,

    ReminderTextLength = 60,

    InvoiceNumberLength = 8,
    InvoiceTextLength = 60,

    VideoNameLength = 30,
    ResourceNameLength = 30,
    ResourceDescriptionLength = 300,

    UserMetricCodeLength = 20,
    UserMetricNameLength = 30,
    UserMetricSeriesTypeLength = 5,
    UserMetricYLabelLength = 10,

    ApiSiteNameLength = 8,
    ApiMethodLength = 7,
    ApiPathLength = 120,
    ApiQueryLength = 100,

    IpInfoLocationLength = 20,
    IpInfoCountryLength = 2,

    AirfieldCodeLength = 5,
    AirfieldNameLength = 30,

    PurchaseOrderNumberLength = 16,
    SettingsKeyLength = 8,

    ReportCodeLength = 20,
    ReportNameLength = 50,

    EmailTemplateName = 62,
    EmailSubject = 158,
    EmailDoNotReply = 494,

    MessageSubjectLength = 100,

    MedicalTestName = 16,
    MatchAlternativeName = 16,

    MandatoryRequirementDescription = 60,

    UploadCode = 6,

    TestScheduleName = 16,
}
