import * as React from 'react';
import { useSelector } from 'react-redux';
import { CardBody } from 'reactstrap';
import { ApplicationState } from '../../store';
import { LargeSummarySize } from '../support/Summaries';
import { View } from '../support/Views';

export default () => {
	const globalSettings = useSelector(state => (state as ApplicationState).globalSettings);
	return (
		<LargeSummarySize>
			<View header='Other Services Available' color='active'>
				<CardBody>
					<p><strong>
						There is a lot more to the medical module than is visible on this login.<br />
						{globalSettings.raName}'s login has been limited to look-up only.
					</strong></p>
					<p>
						SkyCert's interactive modules can be configured to handle all tasks of government regulators.
					</p>
					<p>
						If you would like to know more, or upgrade your subscription from simple lookup-up, please contact SkyCert.
					</p>
				</CardBody>
			</View>
		</LargeSummarySize>
	);
}
