import * as React from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import * as Configuration from '../../Configuration';
import { CardBody } from 'reactstrap';
import { ApplicationState } from '../../store';
import { SummaryLoadStatus2 } from '../../store/summary/SummaryCommon';
import * as UserSummaryStore from '../../store/summary/UserSummaryStore';
import * as LoginStore from '../../store/user/LoginStore';
import Loading from '../support/Loading';
import { StandardSummarySize } from '../support/Summaries';
import { View } from '../support/Views';
import { SecondaryRole, UserRole } from '../../store/user/UserCommon';

// From SkyCert v1

type OrganisationPilotsSummaryProps =
	LoginStore.LoginState
	& UserSummaryStore.OrganisationPilotsState
	& typeof UserSummaryStore.userSummaryActionCreators
	& RouteComponentProps<{}>;

// Architecture: v2

class OrganisationPilotsSummary extends React.Component<OrganisationPilotsSummaryProps, {}> {
	// eslint-disable-next-line @typescript-eslint/no-useless-constructor
	constructor(props: any) {
		super(props);
	}

	componentDidMount() {
		this.props.requestOrganisationPilotsSummary();
    }

	public render() {
		return <StandardSummarySize>
			<View header={Configuration.text.pilots} onClickHeader={this.goToPilots}>
				<CardBody>
					{this.renderBody()}
					<Link to={Configuration.urls.organisationPilots}>Manage</Link>
					{Configuration.enableFeature.uploadResults && this.props.loggedIn!.role === UserRole.FlyingOrganisationStaff && this.props.loggedIn!.secondaryRole !== SecondaryRole.Staff && 
						<>
							<br />
							<Link to='/organisation/test-schedules/myOrganisation'>Test schedules</Link>
						</>
                    }
				</CardBody>
            </View>
		</StandardSummarySize>;
	}

	goToPilots = () => {
		this.props.history.push(Configuration.urls.organisationPilots);
	}

	private renderBody() {
		if (this.props.organisationPilotsStatus === SummaryLoadStatus2.Loading) {
			return <Loading />;
		} else if (this.props.organisationPilotsStatus === SummaryLoadStatus2.Loaded) {
			return <p>
				{this.props.numberOfCurrentPilots} current.<br />
				{this.props.numberOfFormerPilots} former.
			</p>;
		} else if (this.props.organisationPilotsStatus === SummaryLoadStatus2.Failure) {
			return <p className='text-danger'>Unable to load.</p>;
		} else {	// SummaryLoadStatus2.Idle
			return null;	// This should only ever occur briefly.
		}
	}
}

export default connect(
	//(state: ApplicationState) => state.summary.organisationPilots || {},
	(state: ApplicationState) => {
		return {
			...state.user.login,
			...state.summary.organisationPilots,
		}
	},
	UserSummaryStore.userSummaryActionCreators
)(OrganisationPilotsSummary) /*as typeof OrganisationPilotsSummary*/;
