// From SkyCert v1

export enum MedicalStatus {
	None = 'None',
	Processing = 'Processing',
	Completed = 'Completed',
}

export enum MedicalPdfStatus {
	NotBuilt = 'NotBuilt',
	Building = 'Building',
	HasErrorsNotNotified = 'HasErrorsNotNotified',
	HasErrors = 'HasErrors',
	Completed = 'Completed',
	OutOfDate = 'OutOfDate',
}

export enum MedicalReminderCategory {
	None = 0,
	_04_Waiting_on_Pilot = 1,
	_08_AMC_CAA_Expert = 2,
	_07_AMC_ME_Expert = 4,
	_01_ME_to_Action = 8,
	_06_Waiting_on_CAA = 16,
	_02_Nurse_Admin_Action = 32,
	_05_Waiting_on_Specialist = 64,
	_09_Needs_90_Day_Recheck = 128,
	_10_Suspension_Disqualification = 256,
	_11_Surveillance_Pilot = 512,
	_03_Pilot_on_Extension_Certificate = 1024,
}

export enum RegulatoryAuthorityReminderCategory {
	None = 0,
	_01_Medical_To_Action = 1,
	_02_AMC_To_Action = 2,
	_03_Extension_To_Action = 4,
	_04_Suspension_To_Action = 8,
	_05_Urgent_ID_Request_To_Action = 16,
}

export enum MedicalDocumentType {
	CompletedMedical = 'Completed Medical',
	CompletedPilotInformation = 'Completed Pilot Information',
}

export enum SpecialDocumentType {
	Pilot_Application = 'Pilot Application',
	Land_Transport_Application = 'Land Transport Application',
	Documents_Included = 'Documents Included',
	Complete_Medical = 'Complete Medical',
	Pilot_Document = 'Pilot Document',
	Medical_Examination_Report = 'Medical Examination Report',
	ECG = 'ECG',
	Blood_Test_Lipids_CVD_Risk = 'Blood Test Lipids CVD Risk',
	Spirometry = 'Spirometry',
	Audiometry = 'Audiometry',
	Eye_Report = 'Eye Report',
	Certificate_Class_1_And_2 = 'Certificate Class 1 And 2',
	Certificate_Class_3 = 'Certificate Class 3',
	Medical_Assessment_Report = 'Medical Assessment Report',
	// OBSOLETE @@@ ID_Request = 14,
	AMC_Request = 'AMC Request',
	Sent_Medical = 'Sent Medical',
	Extension = 'Extension',
	Lab_Request = 'Lab Request',
	Invoice = 'Invoice',
	Reminders = 'Reminders',
	OverseasInvoice = 'OverseasInvoice',
	ConditionLift = 'ConditionLift',
	ConditionAdd = 'ConditionAdd',
	Virtual_MER = 'Virtual MER',
	Virtual_Medical_Request = 'Virtual Medical Request',
	Complex_Case = 'Complex Case',
	HL7_Results = 'HL7 Results',
	Pre_Audit_Medical = 'Pre Audit Medical',
	Pre_Audit_Extension = 'Pre Audit Extension',
}

export interface Medical {
	medicalId: number;
	status: MedicalStatus;
	medicalPdfStatus: MedicalPdfStatus;
	lastUpdate: string;
	sentToRegulatoryAuthority: string;
	medicalReminderCategories: MedicalReminderCategory;
	authorityReminderCategories: RegulatoryAuthorityReminderCategory;
	checklistRequired: number;
	checklistDone: number;
	checklistSeen: number;
	pilotRegulatoryAuthorityId?: string;
	pilotName: string;
	pilotAge: number;
	pilotUserId: string;
	isBookingStar?: boolean;
	classesAppliedFor: string;
	practice?: string;
	medicalPracticeId?: number;
	examiner?: string;
	medicalExaminerUserId?: string;
	userIsExaminer: boolean;
	medicalApplicationStatus: string;
	medicalApplicationHasErrors: boolean;
	medicalApplicationLastUpdate: string;
	medicalApplicationSigned: string;
	medicalDocuments: MedicalDocument[];
	medicalForms: MedicalForm[];
	mandatoryRequirements?: MedicalMandatoryRequirement[];
	medicalExaminerReminders?: MedicalExaminerReminder[];
	pilotReminders?: MedicalExaminerReminder[];
	errors?: string;
	warnings?: string;
}

export interface MedicalDocument {
	medicalDocumentId: number;
	documentTypeId: number;
	documentTypeName: string;
	alternativeTypeName?: string;
	dateUploaded: string;
	isGeneratedPdf: boolean;
	isLink: boolean;
	emailToAddress?: string;
	canRotate: boolean;
	canDelete: boolean;
	canEmail?: boolean;
	canEditProperties: boolean;
	isActive: boolean;
	deletedReason?: string;
	category: string;
	significance: string;
	isOutOfDate: boolean;
}

export interface MedicalForm {
	filledFormId: number;
	documentTypeId: number;
	documentTypeName: string;
	alternativeTypeName?: string;
	status: string;
	canPdf: boolean;
	canDelete: boolean;
	specialDocumentType: string;
	isActive: boolean;
	isLink: boolean;
	deletedReason?: string;
	lastUpdate: string;
	category: string;
	significance: string;
	isTargetDocumentOutOfDate: boolean;
}

export interface MedicalMandatoryRequirement {
	mandatoryRequirementId: number;
	isActive: boolean;
	description: string;
	dueBy?: string;
	isPast?: boolean;
	meStatus: string;
	meStatusDate?: string;
	meComments?: string;
	raStatus: string;
	raStatusDate?: string;
	raComments?: string;
	pilotRaId?: string;
	pilotName?: string;
}

export interface MedicalExaminerReminder {
	reminderId: number;
	text: string;
	warningDate: string;
	warningSent: string;
	earlyWarningDate: string;
	earlyWarningSent: string;
}
