import * as React from 'react';
import { SecondaryRole, UserRole } from "./store/user/UserCommon";

export const text = {
    siteName: 'OneKete',
    flyingOrganisation: 'Employer',
    flyingOrganisationStaff: 'Staff',
    organisation: 'Employer',
    organisations: 'Employers',
    pilot: 'Individual',
    pilots: 'Individuals',
}

export const enableFeature = {
    aviationActivities: false,
    bookings: false,
    chooseUserName: true,
    invoices: false,
    medicals: false,
    myMetrics: false,
    overheadJoin: false,
    pilotRoleInOrganisationRoles: false,
    regulatoryAuthorityId: false,
    reminders: true,
    specialistPractices: true,
    uploadResults: true,
    uploadResultForNewUser: false,
}

export const urls = { 
    organisationPilots: '/organisation/myOrganisation/employees',
    organisationPilotsMatch: '/organisation/:parentId/employees',
    organisationPilotsPart: 'employees',
}

export const LoginRoles = () => {
    return (
        <>
            <option value={UserRole.Employer}>Employer</option>,
            <option value={UserRole.Employee}>Employee</option>,
            <option value={UserRole.Individual}>Individual</option>,
            <option value={UserRole.Pharmacist}>Pharmacist</option>,
            <option value={UserRole.PharmacyStaff}>Pharmacy staff</option>,
        </>
    );
}

export const NewUserRoles = () => {
    return (
        <>
            <option value={UserRole.Employee}>Employee</option>,
            <option value={UserRole.Employer}>Employer</option>,
            <option value={UserRole.Pharmacist}>Pharmacist</option>,
        </>
    );
}

interface NewOrganisationStaffMemberRoles { hasOwnerPrivilege?: boolean; }
export const NewOrganisationStaffMemberRoles = (props: NewOrganisationStaffMemberRoles) => {
    return (
        <>
            <option value={SecondaryRole.Staff}>Staff</option>
            <option value={SecondaryRole.Administrator}>Administrator</option>
            {props.hasOwnerPrivilege &&
                <option value={SecondaryRole.Owner}>Owner</option>
            }
        </>
    );
}
