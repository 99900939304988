import { Action, Reducer } from "redux";
import { store } from "../..";

// STATE //////////////////////////////////////////////////////////////////////

export interface AlertsState {
	states: MyDialogState[];
}

interface MyDialogState {
	alert?: MyAlertState;
	confirm?: MyConfirmState;
	prompt?: MyPromptState;
	prompts?: MyPromptsState;
}


export type DialogContentType = string | string[] | JSX.Element | JSX.Element[];


export interface MyAlertStateOptional {
	onClose?: () => void;
	backdrop?: boolean | 'static';
}

export interface MyAlertState extends MyAlertStateOptional {
	heading: string;
	content: DialogContentType;
}


export enum MyConfirmOption {
	Yes = 1,
	No = 2,
	YesNo = Yes | No,

	Ok = 4,
	Cancel = 8,
	OkCancel = Ok | Cancel,
}

export interface MyConfirmStateOptional {
	onClose?: () => void;
	backdrop?: boolean | 'static';
}

export interface MyConfirmState extends MyConfirmStateOptional {
	heading: string;
	content: DialogContentType;
	options: MyConfirmOption;
	onAction: (option: MyConfirmOption) => void;
}


export interface MyPromptStateOptional {
	additionalText?: string;
	initialValue?: string;
	maxLength?: number;
	pattern?: string;
	required?: boolean;
	onClose?: () => void;
	backdrop?: boolean | 'static';
}

export interface MyPromptState extends MyPromptStateOptional {
	heading: string;
	label: string;
	onOk: (value?: string) => void;
}


export interface MyPromptsEditorDefinition {
	label: string;
	maxLength?: number;
	pattern?: string;
	required?: boolean;
	editorType?: React.ComponentType<any>;
}

export interface MyPromptsState {
	heading: string;
	editors: MyPromptsEditorDefinition[];
	initialValues: any[];
	onOk: (values: any[]) => void;
	onClose?: () => void;
}


// ACTIONS ////////////////////////////////////////////////////////////////////

interface OpenMyAlertDialogAction { type: 'OPEN_MY_ALERT_DIALOG'; alert: MyAlertState; }
interface OpenMyConfirmDialogAction { type: 'OPEN_MY_CONFIRM_DIALOG'; confirm: MyConfirmState; }
interface OpenMyPromptDialogAction { type: 'OPEN_MY_PROMPT_DIALOG'; prompt: MyPromptState; }
interface OpenMyPromptsDialogAction { type: 'OPEN_MY_PROMPTS_DIALOG'; prompts: MyPromptsState; }
interface CloseMyDialogAction { type: 'CLOSE_MY_DIALOG'; index: number; }

type KnownAction =
	OpenMyAlertDialogAction | OpenMyConfirmDialogAction | OpenMyPromptDialogAction | OpenMyPromptsDialogAction
	| CloseMyDialogAction;


// ACTION INVOKERS ////////////////////////////////////////////////////////////

export const alertsActionInvokers = {
	alert: (heading: string, content: DialogContentType, optional?: MyAlertStateOptional) => store.dispatch({ type: 'OPEN_MY_ALERT_DIALOG', alert: { heading, content, ...optional, }, }),
	confirm: (heading: string, content: DialogContentType, options: MyConfirmOption, onAction: (option: MyConfirmOption) => void, optional?: MyConfirmStateOptional) => store.dispatch({ type: 'OPEN_MY_CONFIRM_DIALOG', confirm: { heading, content, options, onAction, ...optional, }, } as KnownAction),
	prompt: (heading: string, label: string, onOk: (value?: string) => void, optional?: MyPromptStateOptional) => store.dispatch({ type: 'OPEN_MY_PROMPT_DIALOG', prompt: { heading, label, onOk, ...optional, }, } as KnownAction),
	prompts: (heading: string, editors: MyPromptsEditorDefinition[], initialValues: any[], onOk: (values: any[]) => void, onClose?: () => void) => store.dispatch({ type: 'OPEN_MY_PROMPTS_DIALOG', prompts: { heading, editors, initialValues, onOk, onClose, }, } as KnownAction),

	close: () => store.dispatch({ type: 'CLOSE_MY_DIALOG', index: store.getState().alerts!.states.length - 1, } as KnownAction),
};


// REDUCER ////////////////////////////////////////////////////////////////////

export const reducer: Reducer<AlertsState | null> = (state: AlertsState | undefined | null, incomingAction: Action) => {
	if (state === undefined || incomingAction.type === 'REQUEST_LOGOUT')
		return null;
	const action = incomingAction as KnownAction;
	let states = state?.states ?? [];
	switch (action.type) {
		case 'OPEN_MY_ALERT_DIALOG':
			states.push({ alert: action.alert, });
			return {
				states,
			};
		case 'OPEN_MY_CONFIRM_DIALOG':
			states.push({ confirm: action.confirm, });
			return {
				states,
			};
		case 'OPEN_MY_PROMPT_DIALOG':
			states.push({ prompt: action.prompt, });
			return {
				states,
			};
		case 'OPEN_MY_PROMPTS_DIALOG':
			states.push({ prompts: action.prompts, });
			return {
				states,
			};
		case 'CLOSE_MY_DIALOG':
			states = states.filter((s, i) => i !== action.index);
			return {
				states,
			};
 		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		default: const exhaustiveCheck: never = action;
	}
	return state;
}
