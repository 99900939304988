import { Action, Reducer } from 'redux';
import { AppThunkAction } from '..';
import { ListStatus2 } from '../list/ListCommon';
import { deleteJson, getJson, handleWithActionAndErrorToast, postJson, putJson } from '../myfetch';
import { VideoCategory } from './VideoCommon';

// From SkyCert v1

// STATE **********************************************************************

export interface VideoCategoryState {
	status: ListStatus2;
	categoriesRole: string;
	categories: VideoCategory[];

	selectedVideoCategory?: VideoCategory;
	selectedVideoCategorySaving?: boolean;
	selectedVideoCategorySaved?: boolean;
}

// ACTIONS ********************************************************************

interface RequestVideoCategoryListAction { type: 'REQUEST_VIDEO_CATEGORY_LIST'; categoriesRole: string; }
interface ReceiveVideoCategoryListAction { type: 'RECEIVE_VIDEO_CATEGORY_LIST'; categories?: VideoCategory[]; }

interface PrepareNewVideoCategoryAction { type: 'PREPARE_NEW_VIDEO_CATEGORY'; }
interface SelectVideoCategoryAction { type: 'SELECT_VIDEO_CATEGORY'; videoCategoryId: number; }
interface ClearSelectedVideoCategoryAction { type: 'CLEAR_SELECTED_VIDEO_CATEGORY'; }

interface UpdateSelectedVideoCategoryFieldAction { type: 'UPDATE_SELECTED_VIDEO_CATEGORY_FIELD'; name: string; value: any; isRequired: boolean; }

interface RequestSaveVideoCategoryAction { type: 'REQUEST_SAVE_VIDEO_CATEGORY'; }
interface ReceiveSaveVideoCategoryAction { type: 'RECEIVE_SAVE_VIDEO_CATEGORY'; categories?: VideoCategory[]; }

interface RequestDeleteVideoCategoryAction { type: 'REQUEST_DELETE_VIDEO_CATEGORY'; categories: VideoCategory[]; }
interface ReceiveDeleteVideoCategoryAction { type: 'RECEIVE_DELETE_VIDEO_CATEGORY'; categories?: VideoCategory[]; }

interface RequestMoveVideoCategoryDownAction { type: 'REQUEST_MOVE_VIDEO_CATEGORY_DOWN'; }
interface ReceiveMoveVideoCategoryDownAction { type: 'RECEIVE_MOVE_VIDEO_CATEGORY_DOWN'; categories?: VideoCategory[]; }

type KnownAction = RequestVideoCategoryListAction | ReceiveVideoCategoryListAction
	| PrepareNewVideoCategoryAction | SelectVideoCategoryAction | ClearSelectedVideoCategoryAction
	| UpdateSelectedVideoCategoryFieldAction
	| RequestSaveVideoCategoryAction | ReceiveSaveVideoCategoryAction
	| RequestDeleteVideoCategoryAction | ReceiveDeleteVideoCategoryAction
	| RequestMoveVideoCategoryDownAction | ReceiveMoveVideoCategoryDownAction
	;

// ACTION CREATORS ************************************************************

export const videoCategoryActionCreators = {
	getVideoCategoryList: (role: string): AppThunkAction<Action> => (dispatch, getState) => {
		const state = getState().video.categories;
		if (state && role === state.categoriesRole) return;
		getJson(`api/videos/categories?role=${role}`)
			.then(response => {
				dispatch({ type: 'RECEIVE_VIDEO_CATEGORY_LIST', categories: response.categories, } as KnownAction);
			})
			.catch(handleWithActionAndErrorToast('Category List', 'RECEIVE_VIDEO_CATEGORY_LIST'));
		dispatch({ type: 'REQUEST_VIDEO_CATEGORY_LIST', categoriesRole: role, } as KnownAction);
	},

	prepareNewVideoCategory: () => ({ type: 'PREPARE_NEW_VIDEO_CATEGORY', } as KnownAction),
	selectVideoCategory: (videoCategoryId: number) => ({ type: 'SELECT_VIDEO_CATEGORY', videoCategoryId, } as KnownAction),
	clearVideoCategory: () => ({ type: 'CLEAR_SELECTED_VIDEO_CATEGORY', } as KnownAction),

	updateSelectedVideoCategoryField: (name: string, value: any, isRequired: boolean) => ({ type: 'UPDATE_SELECTED_VIDEO_CATEGORY_FIELD', name, value, isRequired } as KnownAction),

	saveVideoCategory: (videoCategory: VideoCategory): AppThunkAction<Action> => (dispatch, getState) => {
		const url: string = videoCategory.videoCategoryId
			? `api/videos/categories/${videoCategory.videoCategoryId}`
			: 'api/videos/categories';
		const method = videoCategory.videoCategoryId ? putJson : postJson;
		method(url, videoCategory)
			.then(response => {
				dispatch({ type: 'RECEIVE_SAVE_VIDEO_CATEGORY', categories: response.categories, } as KnownAction);
			})
			.catch(handleWithActionAndErrorToast('Video Category', 'RECEIVE_SAVE_VIDEO_CATEGORY'));
		dispatch({ type: 'REQUEST_SAVE_VIDEO_CATEGORY' } as KnownAction);
	},

	deleteVideoCategory: (videoCategory: VideoCategory): AppThunkAction<Action> => (dispatch, getState) => {
		deleteJson(`api/videos/categories/${videoCategory.videoCategoryId}`)
			.then(response => {
				dispatch({ type: 'RECEIVE_DELETE_VIDEO_CATEGORY', categories: response.categories, } as KnownAction);
			})
			.catch(handleWithActionAndErrorToast('Video Category', 'RECEIVE_DELETE_VIDEO_CATEGORY'));
		dispatch({ type: 'REQUEST_DELETE_VIDEO_CATEGORY', } as KnownAction);
	},

	moveVideoCategoryDown: (videoCategory: VideoCategory): AppThunkAction<Action> => (dispatch, getState) => {
		putJson(`api/videos/categories/${videoCategory.videoCategoryId}/sequence`, { direction: 'down', })
			.then(response => {
				dispatch({ type: 'RECEIVE_MOVE_VIDEO_CATEGORY_DOWN', categories: response.categories, } as KnownAction);
			})
			.catch(handleWithActionAndErrorToast('Video Category', 'RECEIVE_MOVE_VIDEO_CATEGORY_DOWN'));
		dispatch({ type: 'REQUEST_MOVE_VIDEO_CATEGORY_DOWN', } as KnownAction);
	},
};

// INITIAL STATE **************************************************************

const initialVideoCategoryState: VideoCategory = {
	videoCategoryId: 0,
	name: '',
	role: '',
	sequence: 0,
}

// REDUCERS *******************************************************************

export const reducer: Reducer<VideoCategoryState | null> = (state: VideoCategoryState | null | undefined, action: KnownAction) => {
	if (state === undefined)
		return null;
	state = state!;	// @@@ I hope this works
	switch (action.type) {
		case 'REQUEST_VIDEO_CATEGORY_LIST':
			return {
				status: ListStatus2.Loading,
				categoriesRole: action.categoriesRole,
				categories: [],
			};
		case 'RECEIVE_VIDEO_CATEGORY_LIST':
			return {
				...state,
				status: action.categories ? ListStatus2.Loaded : ListStatus2.Failure,
				categories: action.categories || [],
			};
		case 'PREPARE_NEW_VIDEO_CATEGORY':
			return {
				...state,
				selectedVideoCategory: {
					...initialVideoCategoryState,
					role: state.categoriesRole,
				},
				selectedVideoCategorySaving: undefined,
				selectedVideoCategorySaved: undefined,
			};
		case 'SELECT_VIDEO_CATEGORY':
			return {
				...state,
				selectedVideoCategory: state.categories.find(vc => vc.videoCategoryId === action.videoCategoryId),
				selectedVideoCategorySaving: undefined,
				selectedVideoCategorySaved: true,
			};
		case 'CLEAR_SELECTED_VIDEO_CATEGORY':
			return {
				...state,
				selectedVideoCategory: undefined,
				selectedVideoCategorySaving: undefined,
				selectedVideoCategorySaved: undefined,
			};
		case 'UPDATE_SELECTED_VIDEO_CATEGORY_FIELD':
			return {
				...state,
				selectedVideoCategory: {
					...state.selectedVideoCategory!,
					[action.name]: (action.value === '' && !action.isRequired) ? null : action.value,
				},
				selectedVideoCategorySaved: undefined,
			};
		case 'REQUEST_SAVE_VIDEO_CATEGORY':
			return {
				...state,
				selectedVideoCategorySaving: true,
			};
		case 'RECEIVE_SAVE_VIDEO_CATEGORY':
			return {
				...state,
				categories: action.categories || state.categories,
				selectedVideoCategory: action.categories ? undefined : state.selectedVideoCategory,
				selectedVideoCategorySaving: undefined,
				selectedVideoCategorySaved: !!action.categories,
			};
		case 'REQUEST_DELETE_VIDEO_CATEGORY':
			return {
				...state,
				status: ListStatus2.Deleting,
			};
		case 'REQUEST_MOVE_VIDEO_CATEGORY_DOWN':
			return {
				...state,
				status: ListStatus2.Moving,
			};
		case 'RECEIVE_DELETE_VIDEO_CATEGORY':
		case 'RECEIVE_MOVE_VIDEO_CATEGORY_DOWN':
			return {
				...state,
				status: ListStatus2.Loaded,
				categories: action.categories || state.categories,
			};
		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		default: const exhaustiveCheck: never = action;
	}
	return state;
}
