import * as AlertsStore from './alert/AlertsStore';
import * as BookingStore from './booking/BookingStore';
import * as FormStore from './form/FormStore';
import * as LibraryStore from './library/LibraryStore';
import * as MedicalStore from './medical/MedicalStore';
import * as MessageStore from './message/MessageStore';
import * as MonitorStore from './monitor/MonitorStore';
import * as NotificationStore from './notification/NotificationStore';
import * as OrganisationStore from './organisation/OrganisationStore';
import * as OverheadJoinStore from './overheadjoin/OverheadJoinStore';
import * as PracticeStore from './practice/PracticeStore';
import * as ReportStore from './report/ReportStore';
import * as SelectionsStore from './selections/SelectionsStore';
import * as GlobalSettingsStore from './settings/GlobalSettingsStore';
import * as SpecialistStore from './specialist/SpecialistStore';
import * as SummaryStore from './summary/SummaryStore';
import * as UserStore from './user/UserStore';
import * as VideoStore from './video/VideoStore';

// The top-level state object
export interface ApplicationState {
    alerts: AlertsStore.AlertsState | null;
    booking: BookingStore.BookingState; // TODO @@@ Make optional
    form: FormStore.FormState;  // TODO @@@ Make optional
    globalSettings: GlobalSettingsStore.GlobalSettingsState;
    library: LibraryStore.LibraryState;
    medical: MedicalStore.MedicalState; // TODO @@@ Make optional
    message: MessageStore.MessageState | null;
    monitor: MonitorStore.MonitorState | null;
    notification: NotificationStore.NotificationState;
    organisation: OrganisationStore.OrganisationState;  // TODO @@@ Make optional
    overheadJoin: OverheadJoinStore.OverheadJoinState;  // TODO @@@ Make optional
    practice: PracticeStore.PracticeState;  // TODO @@@ Make optional
    report: ReportStore.ReportState;    // TODO @@@ Make optional
    selections: SelectionsStore.SelectionsState;    // TODO @@@ Make optional?
    specialist: SpecialistStore.SpecialistState;
    summary: SummaryStore.SummaryState; // TODO @@@ Make optional
    user: UserStore.UserState;
    video: VideoStore.VideoState;   // TODO @@@ Make optional
}

// Whenever an action is dispatched, Redux will update each top-level application state property using
// the reducer with the matching name. It's important that the names match exactly, and that the reducer
// acts on the corresponding ApplicationState property type.
export const reducers = {
    alerts: AlertsStore.reducer,
    booking: BookingStore.reducer,
    form: FormStore.reducer,
    globalSettings: GlobalSettingsStore.reducer,
    library: LibraryStore.reducer,
    medical: MedicalStore.reducer,
    message: MessageStore.reducer,
    monitor: MonitorStore.reducer,
    notification: NotificationStore.reducer,
    organisation: OrganisationStore.reducer,
    overheadJoin: OverheadJoinStore.reducer,
    practice: PracticeStore.reducer,
    report: ReportStore.reducer,
    selections: SelectionsStore.reducer,
    specialist: SpecialistStore.reducer,
    summary: SummaryStore.reducer,
    user: UserStore.reducer,
    video: VideoStore.reducer,
};

// This type can be used as a hint on action creators so that its 'dispatch' and 'getState' params are
// correctly typed to match your store.
export interface AppThunkAction<TAction> {
    (dispatch: (action: TAction) => void, getState: () => ApplicationState): void;
}
