import { Action, Reducer } from 'redux';
import { AppThunkAction } from '..';
import { store } from '../..';
import { List2Criteria, List2State, List2Status, ListCriteria } from '../list/ListCommon';
import { getCriteriaUrl2 } from '../listSupport';
import { getJson, handleWithAction, handleWithErrorToast, postJson } from '../myfetch';
import { dispatchToast } from '../notification/NotificationStore';
import { MedicalReminderCategory, RegulatoryAuthorityReminderCategory } from './MedicalCommon';

// From SkyCert v1

// STATE **********************************************************************

export interface MedicalListState {
	medicalsList?: List2State<MedicalSummary>;
	pilotUserId: string;
	practiceId: string;
	canCreateNewApplication: boolean;
	canCreateReissue: boolean;
}

export interface MedicalSummary {
	medicalId: number;
	pilotName: string;
	pilotRegulatoryAuthorityId: string;
	pilotUserId: string;
	practiceName: string;
	examinerName: string;
	status: string;
	lastUpdate: string;
	sentToRegulatoryAuthority: string;
	medicalReminderCategories: MedicalReminderCategory;
	medicalReminderCategoriesText: string;
	regulatoryAuthorityReminderCategories: RegulatoryAuthorityReminderCategory;
	regulatoryAuthorityReminderCategoriesText: string;
	hasToBeReviewed: boolean;
}

export enum MedicalsListOrderBy {
	Pilot = 'Pilot',
	PilotRegulatoryAuthorityId = 'PilotRAID',
	Practice = 'Practice',
	Examiner = 'Examiner',
	LastUpdate = 'LastUpdate',
	LastView = 'LastView',
	SentToRegulatoryAuthority = 'SentToRA',
}

export enum MedicalsListFilter1 {
	All = 'All',
	Mine = 'Mine',
	Unsigned = 'Unsigned',
	ReadyForAuthority = 'Ready',
	MedicalsReady = 'Medicals',
	AmcsReady = 'AMCs',
	ExtensionsReady = 'Extensions',
	SuspensionsReady = 'Suspensions',
	UrgentIdRequestsReady = 'UrgentIdRequests',
}

// ACTIONS ********************************************************************

interface RequestMedicalListAction {
	type: 'REQUEST_MEDICAL_LIST';
	pilotUserId: string;
	practiceId: string;
	criteria: ListCriteria;
}
interface ReceiveMedicalListSuccessAction {
	type: 'RECEIVE_MEDICAL_LIST_SUCCESS';
	numberOfPages: number;
	totalNumberOfItems: number;
	items: MedicalSummary[];
	canCreateNewApplication: boolean;
	canCreateReissue: boolean;
}
interface ReceiveMedicalListFailureAction { type: 'RECEIVE_MEDICAL_LIST_FAILURE'; }
interface InvalidateCurrentMedicalListAction { type: 'INVALIDATE_CURRENT_MEDICAL_LIST'; }
type KnownAction = RequestMedicalListAction | ReceiveMedicalListSuccessAction | ReceiveMedicalListFailureAction
	| InvalidateCurrentMedicalListAction
	;

// ACTION INVOKERS ************************************************************

export const medicalListActionInvokers = {
	requestMedicalList: async (pilotUserId: string | undefined, practiceId: string | undefined, criteria: List2Criteria) => {
		const pilotUrlSuffix: string = !pilotUserId ? '' : `&pilotId=${pilotUserId}`;
		const practiceUrlSuffix: string = !practiceId ? '' : `&practiceId=${practiceId}`;
		const criteriaUrlSuffix: string = getCriteriaUrl2(criteria);
		const url: string = `api/medicals?${criteriaUrlSuffix}${pilotUrlSuffix}${practiceUrlSuffix}`;
		getJson(url)
			.then(response => {
				store.dispatch({ type: 'RECEIVE_MEDICAL_LIST_SUCCESS', numberOfPages: response.numberOfPages, totalNumberOfItems: response.totalNumberOfItems, items: response.items, canCreateNewApplication: response.canCreateNewApplication, canCreateReissue: response.canCreateReissue, } as KnownAction);
			})
			.catch(handleWithAction('RECEIVE_MEDICAL_LIST_FAILURE'));
		store.dispatch({ type: 'REQUEST_MEDICAL_LIST', pilotUserId, practiceId, criteria, } as KnownAction);
	},

	invalidateCurrentMedicalsPage: () => { store.dispatch({ type: 'INVALIDATE_CURRENT_MEDICAL_LIST' } as KnownAction); },
}

// ACTION CREATORS ************************************************************

export const medicalListActionCreators = {
	requestNewApplication: (pilotUserId: string): AppThunkAction<Action> => (dispatch, getState) => {
		postJson(`api/filledforms/medicalapplications/pilot/${pilotUserId}`, {})
			.then(response => {
				dispatchToast(true, 'success', 'New Application', response.message);
				medicalListActionInvokers.invalidateCurrentMedicalsPage();
			})
			.catch(handleWithErrorToast('New Application'));
	},

	requestReissue: (pilotUserId: string, conditionMode: string): AppThunkAction<Action> => (dispatch, getState) => {
		postJson(`api/filledforms/medicalapplications/pilot/${pilotUserId}/reissue?condition=${conditionMode}`, {})
			.then(response => {
				dispatchToast(true, 'success', 'Reissue Medical', response.message);
				medicalListActionInvokers.invalidateCurrentMedicalsPage();
			})
			.catch(handleWithErrorToast('Reissue Medical'));
	},
};

// REDUCERS *******************************************************************

// TODO @@@ Obsolete
export const reducer: Reducer<MedicalListState | null> = (state: MedicalListState | null | undefined, action: KnownAction) => {
	if (state === undefined)
		return null;
	state = state!;	// @@@ I hope this works
	switch (action.type) {
		case 'REQUEST_MEDICAL_LIST':
			return {
				medicalsList: {
					criteria: action.criteria,
					listStatus: List2Status.Loading,
					numberOfPages: 0,
					totalNumberOfItems: undefined,
					items: [],
				},
				pilotUserId: action.pilotUserId,
				practiceId: action.practiceId,
				canCreateNewApplication: false,
				canCreateReissue: false,
			};
		case 'RECEIVE_MEDICAL_LIST_SUCCESS':
			return {
				...state,
				medicalsList: {
					criteria: state!.medicalsList!.criteria,
					listStatus: List2Status.Loaded,
					numberOfPages: action.numberOfPages,
					totalNumberOfItems: action.totalNumberOfItems,
					items: action.items,
				},
				canCreateNewApplication: action.canCreateNewApplication,
				canCreateReissue: action.canCreateReissue,
			};
		case 'RECEIVE_MEDICAL_LIST_FAILURE':
			return {
				...state,
				medicalsList: {
					...state!.medicalsList!,
					listStatus: List2Status.Failure,
				},
			};
		case 'INVALIDATE_CURRENT_MEDICAL_LIST':
			if (!state)
				return state;
			return {
				...state,
				medicalsList: {
					...state!.medicalsList!,
					listStatus: List2Status.Dirty,
				},
			}
		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		default: const exhaustiveCheck: never = action;
	}

	return state;
};
