import { Action, combineReducers, Reducer } from 'redux';
import { reportListReducer, ReportListState } from './ReportListStore';
import { selectedReportReducer, SelectedReportState } from './SelectedReportStore';
import * as SelectedUserMeasuresStore from './SelectedUserMeasuresStore';

// From SkyCert v1

// STATE **********************************************************************

export interface ReportState {
	selectedUserMeasures: SelectedUserMeasuresStore.SelectedUserMeasuresState | null;
	reportList: ReportListState | null;
	selectedReport: SelectedReportState | null;
}

// REDUCERS *******************************************************************

const combinedReducer: Reducer<ReportState> = combineReducers<ReportState>({
	selectedUserMeasures: SelectedUserMeasuresStore.reducer,
	reportList: reportListReducer,
	selectedReport: selectedReportReducer,
})

export const reducer: Reducer<ReportState> = (state: ReportState | undefined, action: Action) => {
	if (action.type === 'REQUEST_LOGOUT')
		state = undefined;
	return combinedReducer(state, action);
}
