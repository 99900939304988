export enum UserRole {
	Administrator = 'Administrator',
	MedicalExaminer = 'Medical Examiner',
	Nurse = 'Nurse',
	RegulatoryAuthorityAgent = 'Regulatory Authority Agent',

	FlyingOrganisationStaff = 'Flying Organisation Staff',
	Employer = 'Employer',

	Pilot = 'Pilot',
	AirTrafficController = 'Air Traffic Controller',
	Employee = 'Employee',
	Firefighter = 'Firefighter',
	Individual = 'Individual',
	Seafarer = 'Seafarer',
	Skydiver = 'Skydiver',
	TruckDriver = 'Truck Driver',

	Specialist = 'Specialist',
	Audiologist = 'Audiologist',
	Optometrist = 'Optometrist',
	Pharmacist = 'Pharmacist',

	SpecialistStaff = 'Specialist Staff',
	PharmacyStaff = 'Pharmacy Staff',
}

export enum SecondaryRole {
	Administrator = 'Administrator',
	AdministratorNurse = 'AdministratorNurse',
	Agent = 'Agent',
	FlightDirector = 'FlightDirector',
	Locum = 'Locum',
	MedicalExaminer = 'MedicalExaminer',
	Nurse = 'Nurse',
	Owner = 'Owner',
	Specialist = 'Specialist',
	Staff = 'Staff',
	SuperUser = 'SuperUser',

	None = '',
}

// From SkyCert v1

export interface User {
	status: string;
	isLockedOut?: boolean;
	isEmailConfirmed?: boolean;
	userId: string;
	userName: string;

	dateSecurityQuestionsRequired: string;
	dateTwoFactorRequired: string;

	regulatoryAuthorityId?: string;
	firstName?: string;
	middleNames?: string;
	lastName: string;
	knownAs: string;
	initials: string;
	previousNames?: string;
	title?: string;
	businessPhoneNumber?: string;
	privatePhoneNumber?: string;
	mobilePhoneNumber?: string;
	email: string;
	buddyEmail?: string;
	uploadUserNumber: number;
	uploadSecurityCode?: string;
	localAddress: string;
	localSuburb?: string;
	localCity: string;
	localRegionId?: string;
	localRegionName?: string;
	localState?: string;
	localPostcode: string;
	localCountry: string;
	postalAddress?: string;
	postalSuburb?: string;
	postalCity?: string;
	postalState?: string;
	postalPostcode?: string;
	postalCountry?: string;
	featureSet: number;
	allowAdministratorImpersonationUntil?: string;
	isSystemTester: boolean;

	administrator?: Administrator;
	flyingOrganisationStaff?: FlyingOrganisationStaff;
	medicalExaminer?: MedicalExaminer;
	nurse?: Nurse;
	pilot?: Pilot;
	regulatoryAuthorityAgent?: RegulatoryAuthorityAgent;
	specialist?: Specialist;
	specialistStaff?: SpecialistStaff;
}

export interface Administrator {
	isActive: boolean;
	role: string;
	dateTCAccepted?: string;
	dateTCRequired?: string;
}

export interface Pilot {
	isActive: boolean;
	dateOfBirth: string;
	placeOfBirth: string;
	dateOfDeath: string;
	gender: string;
	healthId?: string;
	mainOccupation?: string;
	medicalCertificateDeadline?: string;
	reasonForDeadline?: string;
	class1MultiExpiry?: string;
	noClass1MultiReminder?: boolean;
	class1SingleExpiry?: string;
	noClass1SingleReminder?: boolean;
	class2Expiry?: string;
	noClass2Reminder?: boolean;
	class3Expiry?: string;
	noClass3Reminder?: boolean;
	canEditExpiry: boolean;
	optOutOfSharedReports: boolean;
	medicalPracticeId?: string;
	medicalExaminerUserId?: string;
	secondaryJurisdictionCountry?: string;
	secondaryJurisdictionId?: string;
	organisationPilots: OrganisationPilot[];
	flyFixedWing: boolean;
	flyRotary: boolean;
	overheadJoinFlyFromAirfieldId?: number;
	overheadJoinFlyFromAirfieldRegionId?: number;
	overheadJoinAirfields?: OverheadJoinAirfield[];
	accessOverheadJoin: boolean;
	iAmACommercialPilot: boolean;
	overheadJoinBlockByAdministrator: boolean;
	dateTCAccepted?: string;
	dateTCRequired?: string;
}

export interface OverheadJoinAirfield {
	airfieldId: number;
	displayName: string;
}

export enum PilotRoleInOrganisation {
	None = 0,
	Paid_Pilot = 1,
	Unpaid_Pilot = 2,
	Pilot_Non_Flying_Role = 4,
	Flight_Attendant = 8,
	Instructor = 16,
	Administrator = 32,
	Flying_Student = 64,
}

export interface OrganisationPilot {
	isActive: boolean;
	organisationPilotId: number;
	flyingOrganisationId: number;
	flyingOrganisationName: string;
	employeeId?: string;
	allowOrganisationToManageBookings: boolean;
	allowOrganisationToViewPilotDocument: boolean;
	onlyAllowFlightOrganisationStaffUserId?: string;
	onlyAllowFlightOrganisationStaffName?: string;
	organisationTestScheduleId?: string;
	organisationTestScheduleName?: string;
	roles: PilotRoleInOrganisation;
	roleNames: string;
}

export interface FlyingOrganisationStaff {
	isActive: boolean;
	flyingOrganisations: StaffMemberOrganisation[];
	dateTCAccepted?: string;
	dateTCRequired?: string;
}

export interface StaffMemberOrganisation {
	organisationStaffMemberId: number;
	flyingOrganisationId: number;
	flyingOrganisationName: string;
	role: UserRole;
	effectiveTo: string;
}

export interface MedicalExaminer {
	isActive: boolean;
	regulatoryAuthorityLicenceExpiry?: string;
	jurisdictions?: string;
	registrationId?: string;
	informationTestCode?: string;
	hasPhoto: boolean;
	associatedLaboratories: string;
	standardLabTests: string;
	bloodPressureMedicationLabTests: string;
	notifyOfBookings: string;
	ccWhenSendingDocuments: boolean;
	ccWhenSendingMedicals: boolean;
	practiceList: ExaminerPractice[];
	dateTCAccepted?: string;
	dateTCRequired?: string;
}

export interface ExaminerPractice {
	practiceExaminerId: number;
	medicalPracticeId: number;
	medicalPracticeName: string;
	role: UserRole;
	effectiveTo: string;
}

export interface Nurse {
	isActive: boolean;
	medicalPracticeId: number;
	role: UserRole;
	isReadOnly: boolean;
	effectiveTo?: string;
	dateTCAccepted?: string;
	dateTCRequired?: string;
}

export interface RegulatoryAuthorityAgent {
	isActive: boolean;
	role: UserRole;
	dateTCAccepted?: string;
	dateTCRequired?: string;
}

export interface Specialist {
	isActive: boolean;
	practiceList: PracticeOfSpecialist[];
	dateTCAccepted?: string;
	dateTCRequired?: string;
}

export interface PracticeOfSpecialist {
	practiceOfSpecialistId: number;
	specialistPracticeId: number;
	specialistPracticeName: string;
	role: UserRole;
	effectiveTo: string;
}

export interface SpecialistStaff {
	isActive: boolean;
	specialistPracticeId: number;
	role: UserRole;
	effectiveTo?: string;
	dateTCAccepted?: string;
	dateTCRequired?: string;
}

export interface Reminder {
	reminderId: number;
	text: string;
	earlyWarningDate?: string;
	earlyWarningSent?: string;
	warningDate: string;
	warningSent?: string;
	reminderType: string;
	remindUserId: string;
	remindUserName: string;
	associatedUserId?: string;
	associatedUserName?: string;
}
