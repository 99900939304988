import { Action } from "redux";
import { AppThunkAction } from "..";
import { criteriaMatches, getCriteriaUrl } from "../listSupport";
import { getJson, handleWithAction } from "../myfetch";
import { ListCriteria, ListPage, ListState, ListStatus2 } from "./ListCommon";

export const NumberOfParentPilotsPagesToKeep: number = 3;

export interface ParentPilotListState extends ListState<ParentPilotSummary> {
	parentId: string;
}

export interface ParentPilotSummary {
	userId: string;
	regulatoryAuthorityId?: number;
	firstName: string;
	lastName: string;
	fullName: string;
	email: string;
	mobile: string;
	city: string;
	suspendedOrDisqualified?: string;
	class1SingleExpiry: string;
	class1MultiExpiry: string;
	class2Expiry: string;
	class3Expiry: string;
	reminderExpiry: string;
	dateBooked: string;
	manageBookings: boolean;
	class1Conditions?: string;
	class2Conditions?: string;
	class3Conditions?: string;
	organisationRoles?: string;
	summaryForPilotMedicalId?: number;
	summaryForPilotDocumentId?: number;
}

export enum ParentPilotListOrderBy {
	RegulatoryAuthorityId = 'RaId',
	FirstName = 'FirstName',
	LastName = 'LastName',
	City = 'City',
	Class1Single = 'Class1Single',
	Class1Multi = 'Class1Multi',
	Class2 = 'Class2',
	Class3 = 'Class3',
	Reminder = 'Reminder',
	DateBooked = 'DateBooked',
}

export enum ParentPilotListFilter1 {
	Current = 'Current',
	Former = 'Former',
	Class1Single = 'Class1Single',
	Class1Multi = 'Class1Multi',
	Class2 = 'Class2',
	Class3 = 'Class3',
	Administrator = 'Administrator',
	FlightAttendant = 'FlightAttendant',
	FlyingStudent = 'FlyingStudent',
	Instructor = 'Instructor',
	PilotNonFlyingRole = 'PilotNonFlyingRole',
	PaidPilot = 'PaidPilot',
	UnpaidPilot = 'UnpaidPilot',
}

export const parentPilotListActionCreators = (parentEventName: string, parentApi: string, storeFieldName1: string, storeFieldName2: string) => ({
	getParentPilotList: (parentId: string, criteria: ListCriteria, state?: ParentPilotListState): AppThunkAction<Action> => (dispatch, getState) => {
		const criteriaUrlSuffix: string = getCriteriaUrl(criteria);
		const url: string = `api/${parentApi}/${parentId || (parentApi === 'practices' ? 'myPractice' : 'myOrganisation')}/pilots?${criteriaUrlSuffix}`;
		getJson(url)
			.then(response => {
				dispatch({ type: `RECEIVE_${parentEventName}_PILOT_LIST`, numberOfPages: response.numberOfPages, page: response.page, totalNumberOfItems: response.totalNumberOfItems, } as Action);
			})
			.catch(handleWithAction(`RECEIVE_${parentEventName}_PILOT_LIST`));
		dispatch({ type: `REQUEST_${parentEventName}_PILOT_LIST`, parentId, criteria, } as Action);
	},

	invalidateCurrentParentPilotsPage: () => ({ type: `INVALIDATE_CURRENT_${parentEventName}_PILOT_LIST`, } as Action),
})

export const parentPilotListReducer = (parentEventName: string) => (state: ParentPilotListState | null | undefined, action: /*Action*/any) => {
	if (state === undefined)
		return null;
	state = state!;	// @@@ I hope this works
	switch (action.type) {
		case `REQUEST_${parentEventName}_PILOT_LIST`:
			const criteriaMatch: boolean = state && criteriaMatches(action.criteria, state.criteria);
			return {
				parentId: action.parentId,
				criteria: action.criteria,
				listStatus: ListStatus2.Loading,
				numberOfPages: criteriaMatch ? state.numberOfPages : 1,
				pages: criteriaMatch ? state.pages : [],
			};
		case `RECEIVE_${parentEventName}_PILOT_LIST`:
			if (!action.page)
				return {
					...state,
					listStatus: ListStatus2.Failure,
				};
			let newPages: ListPage<ParentPilotSummary>[] = [
				action.page!,
				...state.pages.filter(page => page.pageNumber !== action.page!.pageNumber),
			];
			while (newPages.length > NumberOfParentPilotsPagesToKeep) newPages.pop();
			return {
				...state,
				criteria: {
					...state.criteria,
					pageNumber: action.page!.pageNumber,
				},
				listStatus: ListStatus2.Loaded,
				numberOfPages: action.numberOfPages!,
				totalNumberOfItems: action.totalNumberOfItems!,
				pages: newPages,
			};
		case `CHANGE_${parentEventName}_PILOT_LIST_VIEW`:
			return {
				...state,
				criteria: {
					...state.criteria,
					viewNumber: action.viewNumber,
				},
			};
		case `INVALIDATE_CURRENT_${parentEventName}_PILOT_LIST`:
			if (!state)
				return state;
			return {
				...state,
				listStatus: ListStatus2.Dirty,
				pages: [],
			};
		//default:
		//	const exhaustiveCheck: never = action;
	}
	return state;
}
