import * as React from 'react';
import { useParams } from 'react-router';
import { getAccessToken } from '../../store/myfetch';
import PrivateRoute from '../app/PrivateRoute';
import Loading from '../support/Loading';
import { Page } from './Page';

export default () => (
    <>
        <PrivateRoute path='/view/medicals/:medicalId/documents/:medicalDocumentId' allowAll component={ViewMedicalDocument} />
    </>
);


const ViewMedicalDocument = () => {
    const { medicalId, medicalDocumentId, } = useParams() as any;
    var downloadUrl = `/download/medicals/${medicalId}/documents/${medicalDocumentId}?access-token=${getAccessToken()}`;
    window.location.href = downloadUrl;
    return (
        <Page>
            <Loading />
        </Page>
    );
}
