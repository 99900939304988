import * as React from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { CardBody } from 'reactstrap';
import { ApplicationState } from '../../store';
import { SummaryStatus } from '../../store/summary/SummaryCommon';
import * as UserSummaryStore from '../../store/summary/UserSummaryStore';
import Loading from '../support/Loading';
import { StandardSummarySize } from '../support/Summaries';
import { View } from '../support/Views';
import * as Configuration from '../../Configuration';

// From SkyCert v1

type OrganisationSummaryProps =
	UserSummaryStore.OrganisationState
	& typeof UserSummaryStore.userSummaryActionCreators
	& RouteComponentProps<{}>;

class OrganisationSummary extends React.Component<OrganisationSummaryProps, {}> {
	// eslint-disable-next-line @typescript-eslint/no-useless-constructor
	constructor(props: any) {
		super(props);
	}

	componentDidMount() {
		this.props.requestOrganisationSummary();
    }

	public render() {
		return <StandardSummarySize>
			<View header={Configuration.text.organisation} onClickHeader={this.goToOrganisation}>
				<CardBody>
					{this.renderBody()}
					<Link to='/organisation/manage/myOrganisation'>Manage</Link>
				</CardBody>
            </View>
		</StandardSummarySize>;
	}

	goToOrganisation = () => {
		this.props.history.push('/organisation/manage/myOrganisation');
	}

	private renderBody() {
		if (this.props.status === SummaryStatus.Loading) {
			return <p><Loading /></p>;
		} else if (this.props.status === SummaryStatus.Success) {
			return <p>
				{this.props.summary.numberOfActiveStaff} staff.
			</p>;
		} else {	// SummaryStatus.Failure
            return <p className='text-danger'>Unable to load!</p>;
		}
	}
}

export default connect(
	(state: ApplicationState) => state.summary.organisation || {},
	UserSummaryStore.userSummaryActionCreators
)(OrganisationSummary);
