import { Action, Reducer } from 'redux';
import { AppThunkAction } from '..';
import { getJson, handleWithActionAndErrorToast, postJson, putJson } from '../myfetch';
import { dispatchToast } from '../notification/NotificationStore';
import { LoadStatus2 } from '../StoreCommon';
import { Video } from './VideoCommon';
import { videoListActionCreators } from './VideoListStore';

// From SkyCert v1

// STATE **********************************************************************

export interface SelectedVideoState {
	status: LoadStatus2;
	video?: Video;
	saved?: boolean;
	isUploading?: boolean;
}

// ACTIONS ********************************************************************

interface PrepareNewVideoAction { type: 'PREPARE_NEW_VIDEO'; }

interface RequestLoadVideoAction { type: 'REQUEST_LOAD_VIDEO'; }
interface ReceiveLoadVideoAction { type: 'RECEIVE_LOAD_VIDEO'; video?: Video; }

interface UpdateSelectedVideoFieldAction { type: 'UPDATE_SELECTED_VIDEO_FIELD'; name: string; value: any; isRequired: boolean; }

interface RequestSaveSelectedVideoAction { type: 'REQUEST_SAVE_SELECTED_VIDEO' }
interface ReceiveSaveSelectedVideoAction { type: 'RECEIVE_SAVE_SELECTED_VIDEO'; video?: Video; }

interface RequestUploadVideoFileAction { type: 'REQUEST_UPLOAD_VIDEO_FILE'; }
interface ReceiveUploadVideoFileAction { type: 'RECEIVE_UPLOAD_VIDEO_FILE'; video?: Video; }

type KnownAction = PrepareNewVideoAction
	| RequestLoadVideoAction | ReceiveLoadVideoAction
	| UpdateSelectedVideoFieldAction
	| RequestSaveSelectedVideoAction | ReceiveSaveSelectedVideoAction
	| RequestUploadVideoFileAction | ReceiveUploadVideoFileAction
	;

// ACTION CREATORS ************************************************************

export const selectedVideoActionCreators = {
	prepareNewVideo: () => ({ type: 'PREPARE_NEW_VIDEO', } as KnownAction),

	getVideo: (videoId: number): AppThunkAction<Action> => (dispatch, getState) => {
		// The requested video is not already loaded.
		getJson(`api/videos/${videoId}`)
			.then(response => {
				dispatch({ type: 'RECEIVE_LOAD_VIDEO', video: response.video, } as KnownAction);
			})
			.catch(handleWithActionAndErrorToast('Video', 'RECEIVE_LOAD_VIDEO'));
		dispatch({ type: 'REQUEST_LOAD_VIDEO', } as KnownAction);
	},

	updateSelectedVideoField: (name: string, value: any, isRequired: boolean) => ({ type: 'UPDATE_SELECTED_VIDEO_FIELD', name, value, isRequired } as KnownAction),

	saveVideo: (video: Video): AppThunkAction<Action> => (dispatch, getState) => {
		const url: string = video.videoId ? `api/videos/${video.videoId}` : `api/videos`;
		const method = video.videoId ? putJson : postJson;
		method(url, video)
			.then(response => {
				dispatch({ type: 'RECEIVE_SAVE_SELECTED_VIDEO', video: response.video, } as KnownAction);
				dispatch(videoListActionCreators.invalidateVideoList());
				dispatchToast(true, 'success', 'Video', response.message);
			})
			.catch(handleWithActionAndErrorToast('Video', 'RECEIVE_SAVE_SELECTED_VIDEO'));
		dispatch({ type: 'REQUEST_SAVE_SELECTED_VIDEO' } as KnownAction);
	},

	uploadVideoFile: (videoId: number, file: File): AppThunkAction<Action> => (dispatch, getState) => {
		let formData = new FormData();
		formData.append('file', file);
		postJson(`api/videos/${videoId}/videofile`, formData)
			.then(response => {
				dispatch({ type: 'RECEIVE_UPLOAD_VIDEO_FILE', video: response.video, } as KnownAction);
				dispatch(videoListActionCreators.invalidateVideoList());
				dispatchToast(true, 'success', 'Video File');
			})
			.catch(handleWithActionAndErrorToast('Video File', 'RECEIVE_UPLOAD_VIDEO_FILE'));
		dispatch({ type: 'REQUEST_UPLOAD_VIDEO_FILE', } as KnownAction);
	},
};

// INITIAL STATE **************************************************************

const initialVideoState: Video = {
	videoId: 0,
	name: '',
	fileName: '',
	sequence: 0,
	videoCategoryId: '',
	videoCategoryName: '',
	role: '',
}

// REDUCERS *******************************************************************

export const reducer: Reducer<SelectedVideoState | null> = (state: SelectedVideoState | null | undefined, action: KnownAction) => {
	if (state === undefined)
		return null;
	state = state!;	// @@@ I hope this works
	switch (action.type) {
		case 'PREPARE_NEW_VIDEO':
			return {
				...state,
				status: LoadStatus2.Loaded,
				video: initialVideoState,
			};
		case 'REQUEST_LOAD_VIDEO':
			return {
				...state,
				status: LoadStatus2.Loading,
				video: undefined,
				saved: undefined,
			}
		case 'RECEIVE_LOAD_VIDEO':
			return {
				...state,
				status: LoadStatus2.Loaded,
				video: action.video,
				saved: !!action.video,
			};
		case 'UPDATE_SELECTED_VIDEO_FIELD':
			return {
				...state,
				video: {
					...state.video!,
					[action.name]: (action.value === '' && !action.isRequired) ? null : action.value,
				},
				saved: undefined,
			};
		case 'REQUEST_SAVE_SELECTED_VIDEO':
			return {
				...state,
				status: LoadStatus2.Saving,
			};
		case 'RECEIVE_SAVE_SELECTED_VIDEO':
			return {
				...state,
				status: LoadStatus2.Loaded,
				video: action.video || state.video,
				saved: !!action.video,
			};
		case 'REQUEST_UPLOAD_VIDEO_FILE':
			return {
				...state,
				isUploading: true,
			};
		case 'RECEIVE_UPLOAD_VIDEO_FILE':
			return {
				...state,
				video: action.video || state.video,
				isUploading: undefined,
			};
		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		default: const exhaustiveCheck: never = action;
	}
	return state;
}
