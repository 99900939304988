import { Action, Reducer } from 'redux';
import { AppThunkAction } from '..';
import { getJson, postJson, putJson } from '../myfetch';
import { dispatchErrorToast, dispatchToast } from '../notification/NotificationStore';
import { LoadStatus2 } from '../StoreCommon';
import { Reminder } from '../user/UserCommon';
import { reminderListActionCreators } from './ReminderListStore';

// From SkyCert v1

// STATE **********************************************************************

export interface SelectedReminderState {
	status: LoadStatus2;
	reminder?: Reminder;
	saved?: boolean;
}

// ACTIONS ********************************************************************

interface PrepareNewReminderAction { type: 'PREPARE_NEW_REMINDER'; remindUserId?: string; remindUserName?: string; associatedUserId?: string; associatedUserName?: string; }

interface RequestLoadReminderAction { type: 'REQUEST_LOAD_REMINDER'; }
interface ReceiveLoadReminderAction { type: 'RECEIVE_LOAD_REMINDER'; reminder?: Reminder; }

interface UpdateSelectedReminderFieldAction { type: 'UPDATE_SELECTED_REMINDER_FIELD'; name: string; value: any; isRequired: boolean; }

interface RequestSaveSelectedReminderAction { type: 'REQUEST_SAVE_SELECTED_REMINDER' }
interface ReceiveSaveSelectedReminderAction { type: 'RECEIVE_SAVE_SELECTED_REMINDER'; reminder?: Reminder; }

type KnownAction = PrepareNewReminderAction //| ReceivePracticeDefaultValuesSuccessAction
	| RequestLoadReminderAction | ReceiveLoadReminderAction
	| UpdateSelectedReminderFieldAction
	| RequestSaveSelectedReminderAction | ReceiveSaveSelectedReminderAction
	;

// ACTION CREATORS ************************************************************

export const selectedReminderActionCreators = {
	prepareNewReminder: (remindUserId: string | undefined, remindUserName: string | undefined, associatedUserId: string | undefined, associatedUserName: string | undefined) => ({ type: 'PREPARE_NEW_REMINDER', remindUserId, remindUserName, associatedUserId, associatedUserName, } as KnownAction),

	requestLoadReminder: (reminderId: number): AppThunkAction<Action> => (dispatch, getState) => {
		const userState = getState().user;
		if (userState.listOfReminders) {
			// See if the requested reminder is already loaded.
			for (var page of userState.listOfReminders!.pages) {
				for (var reminder of page.items) {
					if (reminder.reminderId === reminderId) {
						dispatch({ type: 'RECEIVE_LOAD_REMINDER', reminder, } as KnownAction);
						return;
					}
				}
			}
		}
		// The requested reminder is not already loaded.
		getJson(`api/reminders/${reminderId}`)
			.then(response => {
				dispatch({ type: 'RECEIVE_LOAD_REMINDER', reminder: response.reminder, } as KnownAction);
			})
			.catch((error: Error) => {
				dispatch({ type: 'RECEIVE_LOAD_REMINDER', } as KnownAction);
				dispatchErrorToast(true, 'Reminders', error);
			});
		dispatch({ type: 'REQUEST_LOAD_REMINDER', } as KnownAction);
	},

	updateSelectedReminderField: (name: string, value: any, isRequired: boolean) => ({ type: 'UPDATE_SELECTED_REMINDER_FIELD', name, value, isRequired } as KnownAction),

	requestSaveSelectedReminder: (reminder: Reminder): AppThunkAction<Action> => (dispatch, getState) => {
		const url: string = reminder.reminderId ? `api/users/${reminder.remindUserId}/reminders/${reminder.reminderId}` : `api/users/${reminder.remindUserId}/reminders`;
		const method = reminder.reminderId ? putJson : postJson;
		method(url, reminder)
			.then(response => {
				dispatch({ type: 'RECEIVE_SAVE_SELECTED_REMINDER', reminder: response.reminder, } as KnownAction);
				dispatch(reminderListActionCreators.invalidateCurrentRemindersPage());
				dispatchToast(true, 'success', 'Reminder', response.message);
			})
			.catch((error: Error) => {
				dispatch({ type: 'RECEIVE_SAVE_SELECTED_REMINDER', } as KnownAction);
				dispatchErrorToast(true, 'Reminder', error);
			});
		dispatch({ type: 'REQUEST_SAVE_SELECTED_REMINDER' } as KnownAction);
	},
};

// INITIAL STATE **************************************************************

const initialReminderState: Reminder = {
	reminderId: 0,
	text: '',
	warningDate: '',
	reminderType: 'Custom',
	remindUserId: 'me',
	remindUserName: '',
}

// REDUCERS *******************************************************************

export const reducer: Reducer<SelectedReminderState | null> = (state: SelectedReminderState | null | undefined, action: KnownAction) => {
	if (state === undefined)
		return null;
	state = state!;	// @@@ I hope this works
	switch (action.type) {
		case 'PREPARE_NEW_REMINDER':
			return {
				...state,
				status: LoadStatus2.Loaded,
				reminder: {
					...initialReminderState,
					remindUserId: action.remindUserId || 'me',
					remindUserName: action.remindUserName || '',
					associatedUserId: action.associatedUserId,
					associatedUserName: action.associatedUserName,
				},
			};
		case 'REQUEST_LOAD_REMINDER':
			return {
				...state,
				status: LoadStatus2.Loading,
				reminder: undefined,
				saved: undefined,
			}
		case 'RECEIVE_LOAD_REMINDER':
			return {
				...state,
				status: LoadStatus2.Loaded,
				reminder: action.reminder,
				saved: !!action.reminder,
			};
		case 'UPDATE_SELECTED_REMINDER_FIELD':
			return {
				...state,
				reminder: {
					...state.reminder!,
					[action.name]: (action.value === '' && !action.isRequired) ? null : action.value,
				},
				saved: undefined,
			};
		case 'REQUEST_SAVE_SELECTED_REMINDER':
			return {
				...state,
				status: LoadStatus2.Saving,
			};
		case 'RECEIVE_SAVE_SELECTED_REMINDER':
			return {
				...state,
				status: LoadStatus2.Loaded,
				reminder: action.reminder || state.reminder,
				saved: !!action.reminder,
			};
		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		default: const exhaustiveCheck: never = action;
	}
	return state;
}
