import { Action, Reducer } from "redux";

export interface GlobalSettingsState {
	publicStorageUrl: string;
	raName: string;
	taxName: string;
	siteName: string;
	hostName: string;
	displaySiteName: string;
	jurisdictions: string;
	updateDateTime: string;
	updateVersion: string;
}

export const reducer: Reducer<GlobalSettingsState> = (state: GlobalSettingsState | undefined, incomingAction: Action): GlobalSettingsState => {
	return (state || null) as GlobalSettingsState;	// Always provided via the startup script
}
