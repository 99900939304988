import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { CardBody } from 'reactstrap';
import { ApplicationState } from '../../store';
import { getAccessToken } from '../../store/myfetch';
import { SummaryLoadStatus2 } from '../../store/summary/SummaryCommon';
import { userSummaryActionCreators } from '../../store/summary/UserSummaryStore';
import NewFeature from '../support/NewFeature';
import { StandardSummarySize } from '../support/Summaries';
import { View } from '../support/Views';

export default () => {
	const userVideos = useSelector(state => (state as ApplicationState).summary.userVideos);
	const globalSettings = useSelector(state => (state as ApplicationState).globalSettings);
	const history = useHistory();
	const dispatch = useDispatch();

	React.useEffect(() => {
		dispatch(userSummaryActionCreators.requestUserVideosSummary());
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<StandardSummarySize>
			<View header='Information' onClickHeader={() => history.push('/info/medical-examiner')}>
				<CardBody>
					<div>
						<div><Link to='/info/medical-examiner'>Medical examiner information</Link></div>
						<div><a href={globalSettings.publicStorageUrl + '/SkyCert-Age-Chart.pdf'} target='_blank' rel='noopener noreferrer'>SkyCert Age Chart</a></div>
						<NewFeature id='HelpVideos'
							force={userVideos?.status === SummaryLoadStatus2.Loaded ? undefined : false}
							expected={userVideos?.summary.dateOfLastVideo}
							description='New help videos have been added.'
						>
							<div><Link to='/info/videos'>Help videos</Link></div>
						</NewFeature>
						<div><Link to={`download/resources/MEManual.pdf?access-token=${getAccessToken()}`} target='_blank'>PDF Manual</Link></div>
					</div>
				</CardBody>
			</View>
		</StandardSummarySize>
	);
}
