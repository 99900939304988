export enum List2Status {
	Idle,
	Loading,
	Loaded,
	Failure,
	Dirty,
}

export interface List2Criteria {
	itemsPerPage: number;
	filter1: string;
	filter2: string;
	filter3?: string;
	orderBy: string;
	orderAscending: boolean;
	pageNumber: number;
}

export const DefaultList2Criteria = {
	itemsPerPage: 10,
	filter1: '',
	filter2: '',
	orderBy: '',
	orderAscending: true,
	pageNumber: 0,
}

export interface List2State<T> {
	criteria: List2Criteria;
	listStatus: List2Status;
	numberOfPages: number;
	totalNumberOfItems?: number;
	items: T[];
}

// From SkyCert v1

export enum ListStatus2 {
	Idle,
	Loading,
	Loaded,
	Failure,
	Deleting,
	Moving,
	// The following is required for when the list is invalidated while displayed
	Dirty,
}

export interface ListCriteria {
	itemsPerPage: number;
	filter1: string;
	filter2: string;
	orderBy: string;
	orderAscending: boolean;
	pageNumber: number;
	viewNumber: number;
}

export interface ListPage<T> {
	pageNumber: number;
	items: T[];
}

export interface ListState<T> {
	criteria: ListCriteria;
	listStatus: ListStatus2;
	numberOfPages: number;
	totalNumberOfItems?: number;
	pages: ListPage<T>[];
}
