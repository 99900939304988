import { List2Criteria, ListCriteria, ListState } from "./list/ListCommon";

export const getCriteriaUrl2 = (criteria: List2Criteria) => {
	let suffix = `page=${criteria.pageNumber}&itemsPerPage=${criteria.itemsPerPage}&orderBy=${criteria.orderBy}&orderAscending=${criteria.orderAscending}&filter1=${criteria.filter1}&filter2=${criteria.filter2}`;
	if (criteria.filter3)
		suffix = suffix + '&filter3=' + criteria.filter3;
	return suffix;
}

// From SkyCert v1

export function criteriaMatches(criteria1: ListCriteria, criteria2: ListCriteria) {
	return criteria1.itemsPerPage === criteria2.itemsPerPage
		&& criteria1.filter1 === criteria2.filter1 && criteria1.filter2 === criteria2.filter2
		&& criteria1.orderBy === criteria2.orderBy && criteria1.orderAscending === criteria2.orderAscending;
}

export function findExistingPage<SummaryT>(state: ListState<SummaryT>, criteria: ListCriteria, actionType: string, dispatch: any): boolean {
	for (let iPage: number = 0; iPage < state.pages.length; iPage++) {
		if (criteria.pageNumber === state.pages[iPage].pageNumber) {
			if (iPage !== 0) {
				dispatch({
					type: actionType,
					numberOfPages: state.numberOfPages,
					totalNumberOfItems: state.totalNumberOfItems,
					page: state.pages[iPage],
				});
			}
			return true;
		}
	}
	return false;
}

export function getCriteriaUrl(criteria: ListCriteria) {
	return `page=${criteria.pageNumber}&itemsPerPage=${criteria.itemsPerPage}&orderBy=${criteria.orderBy}&orderAscending=${criteria.orderAscending}&filter1=${criteria.filter1}&filter2=${criteria.filter2}`;
}
