import * as React from 'react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { CardBody } from 'reactstrap';
import { ApplicationState } from '../../store';
import { SummaryLoadStatus2 } from '../../store/summary/SummaryCommon';
import { userSummaryActionCreators } from '../../store/summary/UserSummaryStore';
import NewFeature from '../support/NewFeature';
import { StandardSummarySize } from '../support/Summaries';
import { View } from '../support/Views';

export default () => {
	const userVideos = useSelector(state => (state as ApplicationState).summary.userVideos);
	const history = useHistory();
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(userSummaryActionCreators.requestUserVideosSummary());
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<StandardSummarySize>
			<View header='Information' onClickHeader={() => history.push('/info/videos')}>
				<CardBody>
					<NewFeature id='HelpVideos'
						force={userVideos?.status === SummaryLoadStatus2.Loaded ? undefined : false}
						expected={userVideos?.summary.dateOfLastVideo}
						description='New help videos have been added.'
					>
						<div><Link to='/info/videos'>Help videos</Link></div>
					</NewFeature>
				</CardBody>
			</View>
		</StandardSummarySize>
	);
}
