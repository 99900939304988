import { Action, Reducer } from "redux";
import { store } from "../..";
import { getDateTimeText } from "../../components/support/dateTimeSupport";
import { filledFormActionCreatorUtilities } from "../form/FilledFormStore";
import { FilledForm } from "../form/FormCommon";
import { getJson, postJson } from "../myfetch";
import { dispatchErrorToast, dispatchToast } from "../notification/NotificationStore";

// STATE //////////////////////////////////////////////////////////////////////

export interface UploadResultState {
	isUploading?: boolean;
	isFinding?: boolean;
	result: ResultToUpload;
}

type NewOrExistingUser = 'Existing user' | 'New user';
type Outcome = 'Negative' | 'Not negative';
type Format = 'Document' | 'Form';

export interface ResultToUpload {
	newOrExistingUser?: NewOrExistingUser;

	userNumber?: string;
	securityCode?: string;
	userFullName?: string;

	lastName?: string;
	email?: string;
	phoneNumber?: string;

	identityIsConfirmed?: boolean;
	consentIsObtained?: boolean;
	uploadCode?: string;
	format: Format;
	documentTypeId?: string;
	outcome?: Outcome;
}


// ACTIONS ////////////////////////////////////////////////////////////////////

interface SetResultToUploadFieldAction { type: 'SET_RESULT_TO_UPLOAD_FIELD'; name: string; value: any; isRequired: boolean; }
interface ResetResultToUploadFieldsAction { type: 'RESET_RESULT_TO_UPLOAD_FIELDS'; }

interface RequestFindUserForResultAction { type: 'REQUEST_FIND_USER_FOR_RESULT'; }
interface ReceiveFindUserForResultAction { type: 'RECEIVE_FIND_USER_FOR_RESULT', fullName?: string; }

interface RequestUploadResultAction { type: 'REQUEST_UPLOAD_RESULT'; }
interface ReceiveUploadResultAction { type: 'RECEIVE_UPLOAD_RESULT'; isOk: boolean; }

type KnownAction =
	SetResultToUploadFieldAction | ResetResultToUploadFieldsAction
	| RequestFindUserForResultAction | ReceiveFindUserForResultAction
	| RequestUploadResultAction | ReceiveUploadResultAction
	;


// ACTION INVOKERS ////////////////////////////////////////////////////////////

export const uploadResultActionInvokers = {
	setResultToUploadField: (name: string, value: any, isRequired: boolean) => store.dispatch({ type: 'SET_RESULT_TO_UPLOAD_FIELD', name, value, isRequired, } as KnownAction),
	resetResultToUploadFields: () => store.dispatch({ type: 'RESET_RESULT_TO_UPLOAD_FIELDS', } as KnownAction),

	requestFindUser: async () => {
		store.dispatch({ type: 'REQUEST_FIND_USER_FOR_RESULT', } as KnownAction);
		try {
			const result = store.getState().specialist.uploadResult!.result;
			const response = await getJson(`/api/documents/uploads/users?userNumber=${result.userNumber}&securityCode=${result.securityCode}`);
			store.dispatch({ type: 'RECEIVE_FIND_USER_FOR_RESULT', fullName: response.fullName, } as KnownAction);
		} catch (error) {
			store.dispatch({ type: 'RECEIVE_FIND_USER_FOR_RESULT', } as KnownAction);
			dispatchErrorToast(true, 'Find User', error);
        }
	},

	requestUploadResult: async (files?: FileList, filledForm?: FilledForm) => {
		try {
			store.dispatch({ type: 'REQUEST_UPLOAD_RESULT', } as KnownAction);
			const originalResult = store.getState().specialist.uploadResult!.result;
			const result = {
				...originalResult,
				userNumber: originalResult.userNumber ? +originalResult.userNumber : undefined,
				documentTypeId: +originalResult.documentTypeId!,
				dateTimeOfResult: files?.length ? getDateTimeText(new Date(files[0].lastModified)) : undefined,
				editedAnswers: !filledForm ? undefined : filledFormActionCreatorUtilities.getAnswersFor(filledForm),
			};
			let formData = new FormData();
			formData.append('details', JSON.stringify(result));
			if (files?.length)
				formData.append('result', files[0]);
			const mode = result.newOrExistingUser === 'Existing user' ? 'existing' : 'new';
			await postJson(`/api/documents/uploads?mode=${mode}`, formData)
			store.dispatch({ type: 'RECEIVE_UPLOAD_RESULT', isOk: true, } as KnownAction);
			dispatchToast(true, 'success', 'Upload Result', 'The result has been uploaded.');
			return true;
		} catch (error) {
			store.dispatch({ type: 'RECEIVE_UPLOAD_RESULT', isOk: false, } as KnownAction);
			dispatchErrorToast(true, 'Upload Result', error);
			return false;
		}
	},
};


// REDUCER ////////////////////////////////////////////////////////////////////

export const reducer: Reducer<UploadResultState | null> = (state: UploadResultState | undefined | null, incomingAction: Action) => {
	if (state === undefined || incomingAction.type === 'REQUEST_LOGOUT')
		return null;
	const action = incomingAction as KnownAction;
	switch (action.type) {
		case 'SET_RESULT_TO_UPLOAD_FIELD':
			if (!state)
				state = { result: { format: 'Form', }, };
			return {
				...state,
				result: {
					...state.result,
					[action.name]: (action.value === '' && !action.isRequired) ? null : action.value,
					userFullName: action.name === 'userNumber' || action.name === 'securityCode' ? undefined : state.result.userFullName,
				},
				isUploading: false,
			} as UploadResultState;
		case 'RESET_RESULT_TO_UPLOAD_FIELDS':
			return {
				...state,
				result: {
					...state!.result,
					identityIsConfirmed: undefined,
					consentIsObtained: undefined,
					outcome: undefined,
                },
			};
		case 'REQUEST_FIND_USER_FOR_RESULT':
			return {
				...state!,
				isFinding: true,
			} as UploadResultState;
		case 'RECEIVE_FIND_USER_FOR_RESULT':
			return {
				...state!,
				result: {
					...state!.result,
					userFullName: action.fullName,
				},
				isFinding: false,
			} as UploadResultState;
		case 'REQUEST_UPLOAD_RESULT':
			return {
				...state!,
				isUploading: true,
			} as UploadResultState;
		case 'RECEIVE_UPLOAD_RESULT':
			return {
				...state!,
				result: {
					...state!.result,
					documentTypeId: action.isOk ? undefined : state!.result.documentTypeId,
				},
				isUploading: action.isOk ? undefined : false,
			} as UploadResultState;
 		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		default: const exhaustiveCheck: never = action;
	}
	return state;
}
