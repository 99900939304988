import { Action, Reducer } from 'redux';
import { AppThunkAction } from '..';
import { store } from '../..';
import { getJson, handleWithActionAndErrorToast } from '../myfetch';
import { dispatchErrorToast } from '../notification/NotificationStore';
import { SummaryLoadStatus2, SummaryStatus } from './SummaryCommon';

// From SkyCert v1

// STATE **********************************************************************

export interface AppointmentsState {
	status: SummaryLoadStatus2;
	numberOfAppointments: number;
	numberOfBookings: number;
	numberOfBookingsTomorrow: number;
	numberOfBookingsToday: number;
}

export interface AssociatedOrganisationsState {
	status: SummaryLoadStatus2;
	summary: AssociatedOrganisationsSummaryState;
}
export interface AssociatedOrganisationsSummaryState {
	numberOfOrganisations: number;
}

export interface DocumentLibrarySummaryState {
	status: SummaryLoadStatus2;
	detail: DocumentLibrarySummaryDetail;
}
export interface DocumentLibrarySummaryDetail {
	numberOfDocuments: number;
}

export interface InvoicesState {
	status: SummaryLoadStatus2;
	summary: InvoicesSummaryState;
}
export interface InvoicesSummaryState {
	numberOfInvoices: number;
	invoiceFrom?: string;
}

export interface MandatoryRequirementsSummaryState {
	status: SummaryLoadStatus2;
	summary: MandatoryRequirementsSummaryDetail;
}
export interface MandatoryRequirementsSummaryDetail {
	numberOfActiveRequirements: number;
	numberOfOverdueRequirements: number;
	numberOfImminentRequirements: number;
}

export interface MedicalsState {
	status: SummaryLoadStatus2;
	numberOfUnsignedMedicals: number;
	numberOfMedicalsBeingProcessed: number;
	numberOfMedicals?: number;
	numberOfUnassignedHl7LabResults?: number;
}

export interface OrganisationPilotsState {
	organisationPilotsStatus: SummaryLoadStatus2;
	numberOfCurrentPilots: number;
	numberOfFormerPilots: number;
}

export interface OrganisationState {
	status: SummaryStatus;
	summary: OrganisationSummaryState;
}
export interface OrganisationSummaryState {
	numberOfActiveStaff: number;
}

export interface OverheadJoinSummaryState {
	status: SummaryLoadStatus2;
	summary: OverheadJoinSummaryDetail;
}
export interface OverheadJoinSummaryDetail {
	summaryType: number;	// 0=unknown, 1=admin totals, 2=offering pilot, 3=requesting pilot
	numberOfMatches: number;
	numberOfOffers: number;
}

export interface PilotBookingState {
	status: SummaryLoadStatus2;
	bookingDate: string;
	bookingTime: string;
	daysToBooking: number;
}

export interface PilotMedicalState {
	status: SummaryLoadStatus2;
	currentMedicalStatus: string;
	currentMedicalId?: number;
}

export interface PilotState {
	status: SummaryStatus;
	isProfileFilled: boolean;
	regulatoryAuthorityIdStatus: string;
}

export interface PracticePilotsState {
	practicePilotsStatus: SummaryLoadStatus2;
	numberOfCurrentPilots: number;
	numberOfFormerPilots: number;
}

export interface PracticeState {
	status: SummaryStatus;
	numberOfMedicalExaminers: number;
	numberOfNurses: number;
	numberOfPilots: number;
}

export interface RemindersState {
	status: SummaryLoadStatus2;
	numberOfRemindersTotal: number;
	numberOfReminders: number;
	numberOfRemindersWarning: number;
	numberOfRemindersEarlyWarning: number;
	reminderText?: string;
	reminderDate?: string;
}

export interface SpecialistPracticeState {
	status: SummaryLoadStatus2;
	summary: SpecialistPracticeSummaryState;
}
export interface SpecialistPracticeSummaryState {
	numberOfSpecialists: number;
	numberOfStaff: number;
}

export interface UserMessagesSummaryState {
	status: SummaryLoadStatus2;
	summary: UserMessagesSummaryDetail;
}
export interface UserMessagesSummaryDetail {
	numberOfMyMessages: number;
	numberOfMyUnreadMessages: number;
	numberOfGroupMessages?: number;
	numberOfGroupUnreadMessages?: number;
}

export interface UserVideosSummaryState {
	status: SummaryLoadStatus2;
	summary: UserVideosSummaryDetail;
}
export interface UserVideosSummaryDetail {
	dateOfLastVideo: string;
}

// ACTIONS ********************************************************************

interface RequestAssociatedOrganisationsSummaryAction { type: 'REQUEST_ASSOCIATED_ORGANISATIONS_SUMMARY'; }
interface ReceiveAssociatedOrganisationsSummaryAction {
	type: 'RECEIVE_ASSOCIATED_ORGANISATIONS_SUMMARY';
	summary?: AssociatedOrganisationsSummaryState;
}
type KnownAssociatedOrganisationsAction = RequestAssociatedOrganisationsSummaryAction | ReceiveAssociatedOrganisationsSummaryAction;

interface RequestBookingsSummaryAction { type: 'REQUEST_BOOKINGS_SUMMARY'; }
interface ReceiveBookingsSummarySuccessAction {
	type: 'RECEIVE_BOOKINGS_SUMMARY_SUCCESS';
	numberOfAppointments: number;
	numberOfBookings: number;
	numberOfBookingsTomorrow: number;
	numberOfBookingsToday: number;
}
interface ReceiveBookingsSummaryFailureAction { type: 'RECEIVE_BOOKINGS_SUMMARY_FAILURE'; }
type KnownBookingsAction = RequestBookingsSummaryAction | ReceiveBookingsSummarySuccessAction | ReceiveBookingsSummaryFailureAction;

interface RequestDocumentLibrarySummaryAction { type: 'REQUEST_DOCUMENT_LIBRARY_SUMMARY'; }
interface ReceiveDocumentLibrarySummaryAction { type: 'RECEIVE_DOCUMENT_LIBRARY_SUMMARY'; detail?: DocumentLibrarySummaryDetail }
type KnownDocumentLibrarySummaryAction = RequestDocumentLibrarySummaryAction | ReceiveDocumentLibrarySummaryAction;

interface RequestInvoicesSummaryAction { type: 'REQUEST_INVOICES_SUMMARY'; }
interface ReceiveInvoicesSummarySuccessAction {
	type: 'RECEIVE_INVOICES_SUMMARY_SUCCESS';
	summary: InvoicesSummaryState;
}
interface ReceiveInvoicesSummaryFailureAction { type: 'RECEIVE_INVOICES_SUMMARY_FAILURE'; }
type KnownInvoicesAction = RequestInvoicesSummaryAction | ReceiveInvoicesSummarySuccessAction | ReceiveInvoicesSummaryFailureAction;

interface RequestMandatoryRequirementsSummaryAction { type: 'REQUEST_MANDATORY_REQUIREMENTS_SUMMARY'; }
interface ReceiveMandatoryRequirementsSummarySuccessAction {
	type: 'RECEIVE_MANDATORY_REQUIREMENTS_SUMMARY_SUCCESS';
	summary: MandatoryRequirementsSummaryDetail;
}
interface ReceiveMandatoryRequirementsSummaryFailureAction { type: 'RECEIVE_MANDATORY_REQUIREMENTS_SUMMARY_FAILURE'; }
type KnownMandatoryRequirementsAction = RequestMandatoryRequirementsSummaryAction | ReceiveMandatoryRequirementsSummarySuccessAction | ReceiveMandatoryRequirementsSummaryFailureAction;

interface RequestMedicalsSummaryAction { type: 'REQUEST_MEDICALS_SUMMARY'; }
interface ReceiveMedicalsSummarySuccessAction {
	type: 'RECEIVE_MEDICALS_SUMMARY_SUCCESS';
	numberOfUnsignedMedicals: number;
	numberOfMedicalsBeingProcessed: number;
	numberOfMedicals?: number;
	numberOfUnassignedHl7LabResults?: number;
}
interface ReceiveMedicalsSummaryFailureAction { type: 'RECEIVE_MEDICALS_SUMMARY_FAILURE'; }
type KnownMedicalsAction = RequestMedicalsSummaryAction | ReceiveMedicalsSummarySuccessAction | ReceiveMedicalsSummaryFailureAction;

interface RequestOrganisationSummaryAction { type: 'REQUEST_ORGANISATION_SUMMARY'; }
interface ReceiveOrganisationSummarySuccessAction {
	type: 'RECEIVE_ORGANISATION_SUMMARY_SUCCESS';
	summary: OrganisationSummaryState;
}
interface ReceiveOrganisationSummaryFailureAction { type: 'RECEIVE_ORGANISATION_SUMMARY_FAILURE'; }
type KnownOrganisationAction = RequestOrganisationSummaryAction | ReceiveOrganisationSummarySuccessAction | ReceiveOrganisationSummaryFailureAction;

interface RequestPilotBookingSummaryAction { type: 'REQUEST_PILOT_BOOKING_SUMMARY'; }
interface ReceivePilotBookingSummarySuccessAction { type: 'RECEIVE_PILOT_BOOKING_SUMMARY_SUCCESS'; bookingDate: string; bookingTime: string; daysToBooking: number; }
interface ReceivePilotBookingSummaryFailureAction { type: 'RECEIVE_PILOT_BOOKING_SUMMARY_FAILURE'; }
type KnownPilotBookingAction = RequestPilotBookingSummaryAction | ReceivePilotBookingSummarySuccessAction | ReceivePilotBookingSummaryFailureAction;

interface RequestPilotMedicalSummaryAction { type: 'REQUEST_PILOT_MEDICAL_SUMMARY'; }
interface ReceivePilotMedicalSummarySuccessAction {
	type: 'RECEIVE_PILOT_MEDICAL_SUMMARY_SUCCESS';
	currentMedicalStatus: string;
	currentMedicalId: number;
}
interface ReceivePilotMedicalSummaryFailureAction { type: 'RECEIVE_PILOT_MEDICAL_SUMMARY_FAILURE'; }
type KnownPilotMedicalAction = RequestPilotMedicalSummaryAction | ReceivePilotMedicalSummarySuccessAction | ReceivePilotMedicalSummaryFailureAction;

interface RequestPilotSummaryAction { type: 'REQUEST_PILOT_SUMMARY'; }
interface ReceivePilotSummarySuccessAction {
	type: 'RECEIVE_PILOT_SUMMARY_SUCCESS';
	isProfileFilled: boolean;
	regulatoryAuthorityIdStatus: string;
}
interface ReceivePilotSummaryFailureAction { type: 'RECEIVE_PILOT_SUMMARY_FAILURE'; }
type KnownPilotAction = RequestPilotSummaryAction | ReceivePilotSummarySuccessAction | ReceivePilotSummaryFailureAction;

interface RequestPracticePilotsSummaryAction { type: 'REQUEST_PRACTICE_PILOTS_SUMMARY'; }
interface ReceivePracticePilotsSummarySuccessAction {
	type: 'RECEIVE_PRACTICE_PILOTS_SUMMARY_SUCCESS';
	numberOfCurrentPilots: number;
	numberOfFormerPilots: number;
}
interface ReceivePracticePilotsSummaryFailureAction { type: 'RECEIVE_PRACTICE_PILOTS_SUMMARY_FAILURE'; }
type KnownPracticePilotsAction = RequestPracticePilotsSummaryAction | ReceivePracticePilotsSummarySuccessAction | ReceivePracticePilotsSummaryFailureAction;

interface RequestOrganisationPilotsSummaryAction { type: 'REQUEST_ORGANISATION_PILOTS_SUMMARY'; }
interface ReceiveOrganisationPilotsSummarySuccessAction {
	type: 'RECEIVE_ORGANISATION_PILOTS_SUMMARY_SUCCESS';
	numberOfCurrentPilots: number;
	numberOfFormerPilots: number;
}
interface ReceiveOrganisationPilotsSummaryFailureAction { type: 'RECEIVE_ORGANISATION_PILOTS_SUMMARY_FAILURE'; }
type KnownOrganisationPilotsAction = RequestOrganisationPilotsSummaryAction | ReceiveOrganisationPilotsSummarySuccessAction | ReceiveOrganisationPilotsSummaryFailureAction;

interface RequestOverheadJoinSummaryAction { type: 'REQUEST_OVERHEAD_JOIN_SUMMARY'; }
interface ReceiveOverheadJoinSummarySuccessAction { type: 'RECEIVE_OVERHEAD_JOIN_SUMMARY_SUCCESS'; summary: OverheadJoinSummaryDetail; }
interface ReceiveOverheadJoinSummaryFailureAction { type: 'RECEIVE_OVERHEAD_JOIN_SUMMARY_FAILURE'; }
type KnownOverheadJoinAction = RequestOverheadJoinSummaryAction | ReceiveOverheadJoinSummarySuccessAction | ReceiveOverheadJoinSummaryFailureAction;

interface RequestPracticeSummaryAction { type: 'REQUEST_PRACTICE_SUMMARY'; }
interface ReceivePracticeSummarySuccessAction {
	type: 'RECEIVE_PRACTICE_SUMMARY_SUCCESS';
	numberOfMedicalExaminers: number;
	numberOfNurses: number;
	numberOfPilots: number;
}
interface ReceivePracticeSummaryFailureAction { type: 'RECEIVE_PRACTICE_SUMMARY_FAILURE'; }
type KnownPracticeAction = RequestPracticeSummaryAction | ReceivePracticeSummarySuccessAction | ReceivePracticeSummaryFailureAction;

interface RequestRemindersSummaryAction { type: 'REQUEST_REMINDERS_SUMMARY'; }
interface ReceiveRemindersSummarySuccessAction {
	type: 'RECEIVE_REMINDERS_SUMMARY_SUCCESS';
	numberOfRemindersTotal: number;
	numberOfReminders: number;
	numberOfRemindersWarning: number;
	numberOfRemindersEarlyWarning: number;
	reminderText?: string;
	reminderDate?: string;
}
interface ReceiveRemindersSummaryFailureAction { type: 'RECEIVE_REMINDERS_SUMMARY_FAILURE'; }
type KnownRemindersAction = RequestRemindersSummaryAction | ReceiveRemindersSummarySuccessAction | ReceiveRemindersSummaryFailureAction;

interface RequestSpecialistPracticeSummaryAction { type: 'REQUEST_SPECIALIST_PRACTICE_SUMMARY'; }
interface ReceiveSpecialistPracticeSummaryAction {
	type: 'RECEIVE_SPECIALIST_PRACTICE_SUMMARY';
	summary?: SpecialistPracticeSummaryState;
}
type KnownSpecialistPracticeAction = RequestSpecialistPracticeSummaryAction | ReceiveSpecialistPracticeSummaryAction;

interface RequestUserMessagesSummaryAction { type: 'REQUEST_USER_MESSAGES_SUMMARY'; }
interface ReceiveUserMessagesSummaryAction { type: 'RECEIVE_USER_MESSAGES_SUMMARY'; summary?: UserMessagesSummaryDetail; }
type KnownUserMessagesAction = RequestUserMessagesSummaryAction | ReceiveUserMessagesSummaryAction;

interface RequestUserVideosSummaryAction { type: 'REQUEST_USER_VIDEOS_SUMMARY'; }
interface ReceiveUserVideosSummaryAction { type: 'RECEIVE_USER_VIDEOS_SUMMARY'; summary?: UserVideosSummaryDetail; }
type KnownUserVideosAction = RequestUserVideosSummaryAction | ReceiveUserVideosSummaryAction;

// ACTION INVOKERS ////////////////////////////////////////////////////////////

export const userSummaryActionInvokers = {
	requestAssociatedOrganisationsSummary: async () => {
		try {
			store.dispatch({ type: 'REQUEST_ASSOCIATED_ORGANISATIONS_SUMMARY' } as KnownAssociatedOrganisationsAction);
			const response = await getJson('api/specialists/summary/organisations');
			store.dispatch({ type: 'RECEIVE_ASSOCIATED_ORGANISATIONS_SUMMARY', summary: response.summary, } as KnownAssociatedOrganisationsAction);
		} catch (error) {
			store.dispatch({ type: 'RECEIVE_ASSOCIATED_ORGANISATIONS_SUMMARY', } as KnownAssociatedOrganisationsAction);
			if (error.status !== 401)
				dispatchErrorToast(true, 'Associated Organisations', error);
		}
	},

	requestDocumentLibrary: async () => {
		store.dispatch({ type: 'REQUEST_DOCUMENT_LIBRARY_SUMMARY', } as KnownDocumentLibrarySummaryAction);
		try {
			const response = await getJson('/api/documents/summary');
			store.dispatch({ type: 'RECEIVE_DOCUMENT_LIBRARY_SUMMARY', detail: response.detail, } as KnownDocumentLibrarySummaryAction);
		} catch (error) {
			store.dispatch({ type: 'RECEIVE_DOCUMENT_LIBRARY_SUMMARY', } as 
				KnownDocumentLibrarySummaryAction);
			dispatchErrorToast(true, 'Document Library', error);
		}
	},

	requestMandatoryRequirements: async () => {
		store.dispatch({ type: 'REQUEST_MANDATORY_REQUIREMENTS_SUMMARY', } as KnownMandatoryRequirementsAction);
		try {
			const response = await getJson('/api/mandatoryrequirements/summary');
			store.dispatch({ type: 'RECEIVE_MANDATORY_REQUIREMENTS_SUMMARY_SUCCESS', summary: response.summary, } as KnownMandatoryRequirementsAction);
		} catch (error) {
			store.dispatch({ type: 'RECEIVE_MANDATORY_REQUIREMENTS_SUMMARY_FAILURE', } as KnownMandatoryRequirementsAction);
			dispatchErrorToast(true, 'Mandatory Requirements', error);
		}
	},

	requestPilotMedicalSummary: async () => {
		store.dispatch({ type: 'REQUEST_PILOT_MEDICAL_SUMMARY' } as KnownPilotMedicalAction);
		try {
			const response = await getJson('api/medicals/summary');
			store.dispatch({
				type: 'RECEIVE_PILOT_MEDICAL_SUMMARY_SUCCESS',
				currentMedicalStatus: response.currentMedicalStatus,
				currentMedicalId: response.currentMedicalId,
			} as KnownPilotMedicalAction);
		} catch (error) {
			store.dispatch({ type: 'RECEIVE_PILOT_MEDICAL_SUMMARY_FAILURE', });
			if (error.status !== 401)
				dispatchErrorToast(true, 'Medical', error);
		}
	},

	requestSpecialistPracticeSummary: async () => {
		try {
			store.dispatch({ type: 'REQUEST_SPECIALIST_PRACTICE_SUMMARY' } as KnownSpecialistPracticeAction);
			const response = await getJson('api/specialists/summary');
			store.dispatch({ type: 'RECEIVE_SPECIALIST_PRACTICE_SUMMARY', summary: response.summary, } as KnownSpecialistPracticeAction);
		} catch (error) {
			store.dispatch({ type: 'RECEIVE_SPECIALIST_PRACTICE_SUMMARY', } as KnownSpecialistPracticeAction);
			if (error.status !== 401)
				dispatchErrorToast(true, 'Specialist Practice', error);
		}
	},

	requestUserMessages: async () => {
		store.dispatch({ type: 'REQUEST_USER_MESSAGES_SUMMARY', } as KnownUserMessagesAction);
		try {
			const response = await getJson('/api/users/me/messages/summary');
			store.dispatch({ type: 'RECEIVE_USER_MESSAGES_SUMMARY', summary: response.summary, } as KnownUserMessagesAction);
		} catch (error) {
			store.dispatch({ type: 'RECEIVE_USER_MESSAGES_SUMMARY', } as KnownUserMessagesAction);
			dispatchErrorToast(true, 'Messages', error);
		}
	},
};

// ACTION CREATORS ************************************************************

export const userSummaryActionCreators = {
	requestPilotSummary: (): AppThunkAction<Action> => (dispatch, getState) => {
		getJson('api/users/me/pilot/summary')
			.then(response => {
				dispatch({ type: 'RECEIVE_PILOT_SUMMARY_SUCCESS', isProfileFilled: response.isProfileFilled, regulatoryAuthorityIdStatus: response.regulatoryAuthorityIdStatus } as KnownPilotAction);
			})
			.catch(handleWithActionAndErrorToast('Pilot', 'RECEIVE_PILOT_SUMMARY_FAILURE'));
		dispatch({ type: 'REQUEST_PILOT_SUMMARY' } as KnownPilotAction);
    },

	requestPracticeSummary: (): AppThunkAction<Action> => (dispatch, getState) => {
		getJson('api/practices/myPractice/summary')
			.then(response => {
				dispatch({
					type: 'RECEIVE_PRACTICE_SUMMARY_SUCCESS', numberOfMedicalExaminers: response.numberOfMedicalExaminers,
					numberOfNurses: response.numberOfNurses, numberOfPilots: response.numberOfPilots
				} as KnownPracticeAction);
			})
			.catch(handleWithActionAndErrorToast('Medical Practice', 'RECEIVE_PRACTICE_SUMMARY_FAILURE'));
		dispatch({ type: 'REQUEST_PRACTICE_SUMMARY' } as KnownPracticeAction);
	},

	requestOrganisationSummary: (): AppThunkAction<Action> => (dispatch, getState) => {
		getJson('api/organisations/myOrganisation/summary')
			.then(response => {
				dispatch({
					type: 'RECEIVE_ORGANISATION_SUMMARY_SUCCESS',
					summary: response.summary,
				} as KnownOrganisationAction);
			})
			.catch(handleWithActionAndErrorToast('Flying Organisation', 'RECEIVE_ORGANISATION_SUMMARY_FAILURE'));
		dispatch({ type: 'REQUEST_ORGANISATION_SUMMARY' } as KnownOrganisationAction);
	},

	requestPilotMedicalSummary: (): AppThunkAction<Action> => async (dispatch, getState) => {
		dispatch({ type: 'REQUEST_PILOT_MEDICAL_SUMMARY' } as KnownPilotMedicalAction);
		try {
			const response = await getJson('api/medicals/summary');
			dispatch({
				type: 'RECEIVE_PILOT_MEDICAL_SUMMARY_SUCCESS',
				currentMedicalStatus: response.currentMedicalStatus,
				currentMedicalId: response.currentMedicalId,
			} as KnownPilotMedicalAction);
		} catch (error) {
			dispatch({ type: 'RECEIVE_PILOT_MEDICAL_SUMMARY_FAILURE', });
			if (error.status !== 401)
				dispatchErrorToast(true, 'Medical', error);
		}
	},

	requestPilotBookingSummary: (): AppThunkAction<Action> => (dispatch, getState) => {
		// TODO What url?
		getJson('api/appointments/summary')
			.then(response => {
				dispatch({
					type: 'RECEIVE_PILOT_BOOKING_SUMMARY_SUCCESS',
					bookingDate: response.bookingDate,
					bookingTime: response.bookingTime,
					daysToBooking: response.daysToBooking,
				} as KnownPilotBookingAction);
			})
			.catch(handleWithActionAndErrorToast('Bookings', 'RECEIVE_PILOT_BOOKING_SUMMARY_FAILURE'));
		dispatch({ type: 'REQUEST_PILOT_BOOKING_SUMMARY' } as KnownPilotBookingAction);
	},

	requestMedicalsSummary: (): AppThunkAction<Action> => (dispatch, getState) => {
		// TODO What url?
		getJson('api/medicals/summary')
			.then(response => {
				dispatch({
					type: 'RECEIVE_MEDICALS_SUMMARY_SUCCESS',
					numberOfUnsignedMedicals: response.numberOfUnsignedMedicals,
					numberOfMedicalsBeingProcessed: response.numberOfMedicalsBeingProcessed,
					numberOfMedicals: response.numberOfMedicals,
					numberOfUnassignedHl7LabResults: response.numberOfUnassignedHl7LabResults, 
				} as KnownMedicalsAction);
			})
			.catch(handleWithActionAndErrorToast('Medicals', 'RECEIVE_MEDICALS_SUMMARY_FAILURE'));
		dispatch({ type: 'REQUEST_MEDICALS_SUMMARY' } as KnownMedicalsAction);
	},

	requestBookingsSummary: (): AppThunkAction<Action> => (dispatch, getState) => {
		// TODO What url?
		getJson('api/appointments/summary')
			.then(response => {
				dispatch({
					type: 'RECEIVE_BOOKINGS_SUMMARY_SUCCESS',
					numberOfAppointments: response.numberOfAppointments,
					numberOfBookings: response.numberOfBookings,
					numberOfBookingsTomorrow: response.numberOfBookingsTomorrow,
					numberOfBookingsToday: response.numberOfBookingsToday,
				} as KnownBookingsAction);
			})
			.catch(handleWithActionAndErrorToast('Bookings', 'RECEIVE_BOOKINGS_SUMMARY_FAILURE'));
		dispatch({ type: 'REQUEST_BOOKINGS_SUMMARY' } as KnownBookingsAction);
	},

	requestPracticePilotsSummary: (): AppThunkAction<Action> => (dispatch, getState) => {
		// TODO What url?
		getJson('api/practices/myPractice/pilots/summary')
			.then(response => {
				dispatch({
					type: 'RECEIVE_PRACTICE_PILOTS_SUMMARY_SUCCESS',
					numberOfCurrentPilots: response.numberOfCurrentPilots,
					numberOfFormerPilots: response.numberOfFormerPilots,
				} as KnownPracticePilotsAction);
			})
			.catch(handleWithActionAndErrorToast('Pilots', 'RECEIVE_PRACTICE_PILOTS_SUMMARY_FAILURE'));
		dispatch({ type: 'REQUEST_PRACTICE_PILOTS_SUMMARY' } as KnownPracticePilotsAction);
	},

	requestOrganisationPilotsSummary: (): AppThunkAction<Action> => (dispatch, getState) => {
		// TODO What url?
		getJson('api/organisations/myOrganisation/pilots/summary')
			.then(response => {
				dispatch({
					type: 'RECEIVE_ORGANISATION_PILOTS_SUMMARY_SUCCESS',
					numberOfCurrentPilots: response.numberOfCurrentPilots,
					numberOfFormerPilots: response.numberOfFormerPilots,
				} as KnownOrganisationPilotsAction);
			})
			.catch(handleWithActionAndErrorToast('Pilots', 'RECEIVE_ORGANISATION_PILOTS_SUMMARY_FAILURE'));
		dispatch({ type: 'REQUEST_ORGANISATION_PILOTS_SUMMARY' } as KnownOrganisationPilotsAction);
	},

	requestRemindersSummary: (): AppThunkAction<Action> => (dispatch, getState) => {
		// TODO What url?
		getJson('api/users/me/reminders/summary')
			.then(response => {
				dispatch({
					type: 'RECEIVE_REMINDERS_SUMMARY_SUCCESS',
					numberOfRemindersTotal: response.numberOfRemindersTotal,
					numberOfReminders: response.numberOfReminders,
					numberOfRemindersWarning: response.numberOfRemindersWarning,
					numberOfRemindersEarlyWarning: response.numberOfRemindersEarlyWarning,
					reminderText: response.reminderText,
					reminderDate: response.reminderDate,
				} as KnownRemindersAction);
			})
			.catch(handleWithActionAndErrorToast('Reminders', 'RECEIVE_REMINDERS_SUMMARY_FAILURE'));
		dispatch({ type: 'REQUEST_REMINDERS_SUMMARY' } as KnownRemindersAction);
	},

	requestInvoicesSummary: (): AppThunkAction<Action> => (dispatch, getState) => {
		getJson('api/practices/myPractice/invoices/summary')
			.then(response => {
				dispatch({
					type: 'RECEIVE_INVOICES_SUMMARY_SUCCESS',
					summary: response.summary,
				} as KnownInvoicesAction);
			})
			.catch(handleWithActionAndErrorToast('Invoices', 'RECEIVE_INVOICES_SUMMARY_FAILURE'));
		dispatch({ type: 'REQUEST_INVOICES_SUMMARY' } as KnownInvoicesAction);
	},

	requestOverheadJoinSummary: (): AppThunkAction<Action> => (dispatch, getState) => {
		getJson('api/overheadjoin/summary')
			.then(response => {
				dispatch({
					type: 'RECEIVE_OVERHEAD_JOIN_SUMMARY_SUCCESS',
					summary: response.summary,
				} as KnownOverheadJoinAction);
			})
			.catch(handleWithActionAndErrorToast('Invoices', 'RECEIVE_OVERHEAD_JOIN_SUMMARY_FAILURE'));
		dispatch({ type: 'REQUEST_OVERHEAD_JOIN_SUMMARY' } as KnownOverheadJoinAction);
	},

	requestUserVideosSummary: (): AppThunkAction<KnownUserVideosAction> => async (dispatch, getState) => {
		dispatch({ type: 'REQUEST_USER_VIDEOS_SUMMARY', });
		try {
			const response = await getJson('/api/videos/summary');
			dispatch({ type: 'RECEIVE_USER_VIDEOS_SUMMARY', summary: response.summary, });
		} catch (error) {
			dispatch({ type: 'RECEIVE_USER_VIDEOS_SUMMARY', });
			dispatchErrorToast(true, 'Videos', error);
		}
	},
};

// INITIAL STATE **************************************************************

const initialAppointmentsState: AppointmentsState = {
	status: SummaryLoadStatus2.Idle,
	numberOfAppointments: 0,
	numberOfBookings: 0,
	numberOfBookingsTomorrow: 0,
	numberOfBookingsToday: 0,
};

const initialAssociatedOrganisationsState: AssociatedOrganisationsState = {
	status: SummaryLoadStatus2.Idle,
	summary: {
		numberOfOrganisations: 0,
	},
};

const initialDocumentLibraryState: DocumentLibrarySummaryState = {
	status: SummaryLoadStatus2.Idle,
	detail: {
		numberOfDocuments: 0,
	},
}

const initialInvoicesState: InvoicesState = {
	status: SummaryLoadStatus2.Idle,
	summary: {
		numberOfInvoices: 0,
	}
};

const initialMandatoryRequirementsState: MandatoryRequirementsSummaryState = {
	status: SummaryLoadStatus2.Idle,
	summary: {
		numberOfActiveRequirements: 0,
		numberOfOverdueRequirements: 0,
		numberOfImminentRequirements: 0,
	},
}

const initialMedicalsState: MedicalsState = {
	status: SummaryLoadStatus2.Idle,
	numberOfUnsignedMedicals: 0,
	numberOfMedicalsBeingProcessed: 0,
	numberOfMedicals: 0,
};

const initialOrganisationPilotsState: OrganisationPilotsState = {
	organisationPilotsStatus: SummaryLoadStatus2.Idle,
	numberOfCurrentPilots: 0,
	numberOfFormerPilots: 0,
};

const initialOrganisationState: OrganisationState = {
	status: SummaryStatus.Loading,
	summary: {
		numberOfActiveStaff: 0,
	},
};

const initialOverheadJoinState: OverheadJoinSummaryState = {
	status: SummaryLoadStatus2.Idle,
	summary: {
		summaryType: 0,
		numberOfMatches: 0,
		numberOfOffers: 0,
	},
}

const initialPilotBookingState: PilotBookingState = {
	status: SummaryLoadStatus2.Idle,
	bookingDate: '',
	bookingTime: '',
	daysToBooking: 0,
};

const initialPilotMedicalState: PilotMedicalState = {
	status: SummaryLoadStatus2.Idle,
	currentMedicalStatus: '',
	currentMedicalId: 0,
};

const initialPilotState: PilotState = {
	status: SummaryStatus.Loading,
	isProfileFilled: true,
	regulatoryAuthorityIdStatus: 'Present',
};

const initialPracticePilotsState: PracticePilotsState = {
	practicePilotsStatus: SummaryLoadStatus2.Idle,
	numberOfCurrentPilots: 0,
	numberOfFormerPilots: 0,
};

const initialPracticeState: PracticeState = {
	status: SummaryStatus.Loading,
	numberOfMedicalExaminers: 0,
	numberOfNurses: 0,
	numberOfPilots: 0,
};

const initialRemindersState: RemindersState = {
	status: SummaryLoadStatus2.Idle,
	numberOfRemindersTotal: 0,
	numberOfReminders: 0,
	numberOfRemindersWarning: 0,
	numberOfRemindersEarlyWarning: 0,
};

const initialSpecialistPracticeState: SpecialistPracticeState = {
	status: SummaryLoadStatus2.Idle,
	summary: {
		numberOfSpecialists: 0,
		numberOfStaff: 0,
    },
};

const initialUserMessagesState: UserMessagesSummaryState = {
	status: SummaryLoadStatus2.Idle,
	summary: {
		numberOfMyMessages: 0,
		numberOfMyUnreadMessages: 0,
	},
}

const initialUserVideosState: UserVideosSummaryState = {
	status: SummaryLoadStatus2.Idle,
	summary: {
		dateOfLastVideo: '',
	},
}

// REDUCERS *******************************************************************

export const appointmentsReducer: Reducer<AppointmentsState | null> = (state: AppointmentsState | null | undefined, action: KnownBookingsAction) => {
	if (state === undefined)
		return null;
	state = state!;	// @@@ I hope this works
	switch (action.type) {
		case 'REQUEST_BOOKINGS_SUMMARY':
			return {
				...(state || initialAppointmentsState),
				status: SummaryLoadStatus2.Loading,
			};
		case 'RECEIVE_BOOKINGS_SUMMARY_SUCCESS':
			return {
				status: SummaryLoadStatus2.Loaded,
				numberOfAppointments: action.numberOfAppointments,
				numberOfBookings: action.numberOfBookings,
				numberOfBookingsTomorrow: action.numberOfBookingsTomorrow,
				numberOfBookingsToday: action.numberOfBookingsToday,
			};
		case 'RECEIVE_BOOKINGS_SUMMARY_FAILURE':
			return {
				...state,
				status: SummaryLoadStatus2.Failure,
			};
		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		default: const exhaustiveCheck: never = action;
	}

	return state;
};

export const associatedOrganisationsReducer: Reducer<AssociatedOrganisationsState | null> = (state: AssociatedOrganisationsState | null | undefined, action: KnownAssociatedOrganisationsAction) => {
	if (state === undefined)
		return null;
	state = state!;
	switch (action.type) {
		case 'REQUEST_ASSOCIATED_ORGANISATIONS_SUMMARY':
			return {
				...(state ?? initialAssociatedOrganisationsState),
				status: SummaryLoadStatus2.Loading,
			} as AssociatedOrganisationsState;
		case 'RECEIVE_ASSOCIATED_ORGANISATIONS_SUMMARY':
			return {
				status: action.summary ? SummaryLoadStatus2.Loaded : SummaryLoadStatus2.Failure,
				summary: action.summary ?? initialAssociatedOrganisationsState.summary,
			} as AssociatedOrganisationsState;
		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		default: const exhaustiveCheck: never = action;
	}
	return state;
};

export const documentLibraryReducer: Reducer<DocumentLibrarySummaryState | null> = (state: DocumentLibrarySummaryState | null | undefined, action: KnownDocumentLibrarySummaryAction) => {
	if (state === undefined)
		return null;
	state = state!;
	switch (action.type) {
		case 'REQUEST_DOCUMENT_LIBRARY_SUMMARY':
			return {
				...(state || initialDocumentLibraryState),
				status: SummaryLoadStatus2.Loading,
			};
		case 'RECEIVE_DOCUMENT_LIBRARY_SUMMARY':
			return {
				status: action.detail ? SummaryLoadStatus2.Loaded : SummaryLoadStatus2.Failure,
				detail: action.detail ?? state.detail,
			};
		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		default: const exhaustiveCheck: never = action;
	}
	return state;
}

export const invoicesReducer: Reducer<InvoicesState | null> = (state: InvoicesState | null | undefined, action: KnownInvoicesAction) => {
	if (state === undefined)
		return null;
	state = state!;	// @@@ I hope this works
	switch (action.type) {
		case 'REQUEST_INVOICES_SUMMARY':
			return {
				...(state || initialInvoicesState),
				status: SummaryLoadStatus2.Loading,
			};
		case 'RECEIVE_INVOICES_SUMMARY_SUCCESS':
			return {
				status: SummaryLoadStatus2.Loaded,
				summary: action.summary,
			};
		case 'RECEIVE_INVOICES_SUMMARY_FAILURE':
			return {
				...state,
				status: SummaryLoadStatus2.Failure,
			};
		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		default: const exhaustiveCheck: never = action;
	}

	return state;
};

export const mandatoryRequirementsReducer: Reducer<MandatoryRequirementsSummaryState | null> = (state: MandatoryRequirementsSummaryState | null | undefined, action: KnownMandatoryRequirementsAction) => {
	if (state === undefined)
		return null;
	state = state!;
	switch (action.type) {
		case 'REQUEST_MANDATORY_REQUIREMENTS_SUMMARY':
			return {
				...(state || initialMandatoryRequirementsState),
				status: SummaryLoadStatus2.Loading,
			};
		case 'RECEIVE_MANDATORY_REQUIREMENTS_SUMMARY_SUCCESS':
			return {
				status: SummaryLoadStatus2.Loaded,
				summary: action.summary,
			};
		case 'RECEIVE_MANDATORY_REQUIREMENTS_SUMMARY_FAILURE':
			return {
				...state,
				status: SummaryLoadStatus2.Failure,
			};
		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		default: const exhaustiveCheck: never = action;
	}

	return state;
};

export const medicalsReducer: Reducer<MedicalsState | null> = (state: MedicalsState | null | undefined, action: KnownMedicalsAction) => {
	if (state === undefined)
		return null;
	state = state!;	// @@@ I hope this works
	switch (action.type) {
		case 'REQUEST_MEDICALS_SUMMARY':
			return {
				...(state || initialMedicalsState),
				status: SummaryLoadStatus2.Loading,
			};
		case 'RECEIVE_MEDICALS_SUMMARY_SUCCESS':
			return {
				status: SummaryLoadStatus2.Loaded,
				numberOfUnsignedMedicals: action.numberOfUnsignedMedicals,
				numberOfMedicalsBeingProcessed: action.numberOfMedicalsBeingProcessed,
				numberOfMedicals: action.numberOfMedicals,
				numberOfUnassignedHl7LabResults: action.numberOfUnassignedHl7LabResults,
			};
		case 'RECEIVE_MEDICALS_SUMMARY_FAILURE':
			return {
				...state,
				status: SummaryLoadStatus2.Failure,
			};
		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		default: const exhaustiveCheck: never = action;
	}

	return state;
};

export const organisationPilotsReducer: Reducer<OrganisationPilotsState | null> = (state: OrganisationPilotsState | null | undefined, action: KnownOrganisationPilotsAction) => {
	if (state === undefined)
		return null;
	state = state!;	// @@@ I hope this works
	switch (action.type) {
		case 'REQUEST_ORGANISATION_PILOTS_SUMMARY':
			return {
				...(state || initialOrganisationPilotsState),
				organisationPilotsStatus: SummaryLoadStatus2.Loading,
			};
		case 'RECEIVE_ORGANISATION_PILOTS_SUMMARY_SUCCESS':
			return {
				organisationPilotsStatus: SummaryLoadStatus2.Loaded,
				numberOfCurrentPilots: action.numberOfCurrentPilots,
				numberOfFormerPilots: action.numberOfFormerPilots,
			};
		case 'RECEIVE_ORGANISATION_PILOTS_SUMMARY_FAILURE':
			return {
				...state,
				organisationPilotsStatus: SummaryLoadStatus2.Failure,
			};
		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		default: const exhaustiveCheck: never = action;
	}

	return state;
};

export const organisationReducer: Reducer<OrganisationState | null> = (state: OrganisationState | null | undefined, action: KnownOrganisationAction) => {
	if (state === undefined)
		return null;
	state = state!;	// @@@ I hope this works
	switch (action.type) {
		case 'REQUEST_ORGANISATION_SUMMARY':
			if (state)
				return {
					...state,
					status: SummaryStatus.Loading,
				};
			else return initialOrganisationState;
		case 'RECEIVE_ORGANISATION_SUMMARY_SUCCESS':
			return {
				status: SummaryStatus.Success,
				summary: action.summary,
			};
		case 'RECEIVE_ORGANISATION_SUMMARY_FAILURE':
			return {
				...state,
				status: SummaryStatus.Failure,
			};
		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		default: const exhaustiveCheck: never = action;
	}

	return state;
};

export const overheadJoinReducer: Reducer<OverheadJoinSummaryState | null> = (state: OverheadJoinSummaryState | null | undefined, action: KnownOverheadJoinAction) => {
	if (state === undefined)
		return null;
	state = state!;
	switch (action.type) {
		case 'REQUEST_OVERHEAD_JOIN_SUMMARY':
			return {
				...(state || initialOverheadJoinState),
				status: SummaryLoadStatus2.Loading,
			};
		case 'RECEIVE_OVERHEAD_JOIN_SUMMARY_SUCCESS':
			return {
				status: SummaryLoadStatus2.Loaded,
				summary: action.summary,
			};
		case 'RECEIVE_OVERHEAD_JOIN_SUMMARY_FAILURE':
			return {
				...state,
				status: SummaryLoadStatus2.Failure,
			};
		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		default: const exhaustiveCheck: never = action;
	}

	return state;
};

export const pilotBookingReducer: Reducer<PilotBookingState | null> = (state: PilotBookingState | null | undefined, action: KnownPilotBookingAction) => {
	if (state === undefined)
		return null;
	state = state!;	// @@@ I hope this works
	switch (action.type) {
		case 'REQUEST_PILOT_BOOKING_SUMMARY':
			return {
				...(state || initialPilotBookingState),
				status: SummaryLoadStatus2.Loading,
			};
		case 'RECEIVE_PILOT_BOOKING_SUMMARY_SUCCESS':
			return {
				status: SummaryLoadStatus2.Loaded,
				bookingDate: action.bookingDate,
				bookingTime: action.bookingTime,
				daysToBooking: action.daysToBooking,
			};
		case 'RECEIVE_PILOT_BOOKING_SUMMARY_FAILURE':
			return {
				...state,
				status: SummaryLoadStatus2.Failure,
			};
		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		default: const exhaustiveCheck: never = action;
	}

	return state;
};

export const pilotMedicalReducer: Reducer<PilotMedicalState | null> = (state: PilotMedicalState | null | undefined, action: KnownPilotMedicalAction) => {
	if (state === undefined)
		return null;
	state = state!;	// @@@ I hope this works
	switch (action.type) {
		case 'REQUEST_PILOT_MEDICAL_SUMMARY':
			return {
				...(state || initialPilotMedicalState),
				status: SummaryLoadStatus2.Loading,
			};
		case 'RECEIVE_PILOT_MEDICAL_SUMMARY_SUCCESS':
			return {
				status: SummaryLoadStatus2.Loaded,
				currentMedicalStatus: action.currentMedicalStatus,
				currentMedicalId: action.currentMedicalId,
			};
		case 'RECEIVE_PILOT_MEDICAL_SUMMARY_FAILURE':
			return {
				...state,
				status: SummaryLoadStatus2.Failure,
			};
		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		default: const exhaustiveCheck: never = action;
	}

	return state;
};

export const pilotReducer: Reducer<PilotState | null> = (state: PilotState | null | undefined, action: KnownPilotAction) => {
	if (state === undefined)
		return null;
	state = state!;	// @@@ I hope this works
	switch (action.type) {
		case 'REQUEST_PILOT_SUMMARY':
			if (state) {
				return {
					...state,
					status: SummaryStatus.Loading,
				};
			} else {
				return initialPilotState;
			}
		case 'RECEIVE_PILOT_SUMMARY_SUCCESS':
			return {
				status: SummaryStatus.Success,
				isProfileFilled: action.isProfileFilled,
				regulatoryAuthorityIdStatus: action.regulatoryAuthorityIdStatus,
			};
		case 'RECEIVE_PILOT_SUMMARY_FAILURE':
            return {
                ...state,
				status: SummaryStatus.Failure,
            };
		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		default: const exhaustiveCheck: never = action;
    }

    return state;
};

export const practicePilotsReducer: Reducer<PracticePilotsState | null> = (state: PracticePilotsState | null | undefined, action: KnownPracticePilotsAction) => {
	if (state === undefined)
		return null;
	state = state!;	// @@@ I hope this works
	switch (action.type) {
		case 'REQUEST_PRACTICE_PILOTS_SUMMARY':
			return {
				...(state || initialPracticePilotsState),
				practicePilotsStatus: SummaryLoadStatus2.Loading,
			};
		case 'RECEIVE_PRACTICE_PILOTS_SUMMARY_SUCCESS':
			return {
				practicePilotsStatus: SummaryLoadStatus2.Loaded,
				numberOfCurrentPilots: action.numberOfCurrentPilots,
				numberOfFormerPilots: action.numberOfFormerPilots,
			};
		case 'RECEIVE_PRACTICE_PILOTS_SUMMARY_FAILURE':
			return {
				...state,
				practicePilotsStatus: SummaryLoadStatus2.Failure,
			};
		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		default: const exhaustiveCheck: never = action;
	}

	return state;
};

export const practiceReducer: Reducer<PracticeState | null> = (state: PracticeState | null | undefined, action: KnownPracticeAction) => {
	if (state === undefined)
		return null;
	state = state!;	// @@@ I hope this works
	switch (action.type) {
		case 'REQUEST_PRACTICE_SUMMARY':
			if (state) {
				return {
					...state,
					status: SummaryStatus.Loading,
				};
			} else {
				return initialPracticeState;
			}
		case 'RECEIVE_PRACTICE_SUMMARY_SUCCESS':
			return {
				status: SummaryStatus.Success,
				numberOfMedicalExaminers: action.numberOfMedicalExaminers,
				numberOfNurses: action.numberOfNurses,
				numberOfPilots: action.numberOfPilots,
			};
		case 'RECEIVE_PRACTICE_SUMMARY_FAILURE':
			return {
				...state,
				status: SummaryStatus.Failure,
			};
		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		default: const exhaustiveCheck: never = action;
	}

	return state;
};

export const remindersReducer: Reducer<RemindersState | null> = (state: RemindersState | null | undefined, action: KnownRemindersAction) => {
	if (state === undefined)
		return null;
	state = state!;	// @@@ I hope this works
	switch (action.type) {
		case 'REQUEST_REMINDERS_SUMMARY':
			return {
				...(state || initialRemindersState),
				status: SummaryLoadStatus2.Loading,
			};
		case 'RECEIVE_REMINDERS_SUMMARY_SUCCESS':
			return {
				status: SummaryLoadStatus2.Loaded,
				numberOfRemindersTotal: action.numberOfRemindersTotal,
				numberOfReminders: action.numberOfReminders,
				numberOfRemindersWarning: action.numberOfRemindersWarning,
				numberOfRemindersEarlyWarning: action.numberOfRemindersEarlyWarning,
				reminderText: action.reminderText,
				reminderDate: action.reminderDate,
			};
		case 'RECEIVE_REMINDERS_SUMMARY_FAILURE':
			return {
				...state,
				status: SummaryLoadStatus2.Failure,
			};
		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		default: const exhaustiveCheck: never = action;
	}

	return state;
};

export const specialistPracticeReducer: Reducer<SpecialistPracticeState | null> = (state: SpecialistPracticeState | null | undefined, action: KnownSpecialistPracticeAction) => {
	if (state === undefined)
		return null;
	state = state!;
	switch (action.type) {
		case 'REQUEST_SPECIALIST_PRACTICE_SUMMARY':
			return {
				...(state ?? initialSpecialistPracticeState),
				status: SummaryLoadStatus2.Loading,
			} as SpecialistPracticeState;
		case 'RECEIVE_SPECIALIST_PRACTICE_SUMMARY':
			return {
				status: action.summary ? SummaryLoadStatus2.Loaded : SummaryLoadStatus2.Failure,
				summary: action.summary ?? initialSpecialistPracticeState.summary,
			} as SpecialistPracticeState;
		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		default: const exhaustiveCheck: never = action;
	}
	return state;
};

export const userMessagesReducer: Reducer<UserMessagesSummaryState | null> = (state: UserMessagesSummaryState | null | undefined, action: KnownUserMessagesAction) => {
	if (state === undefined)
		return null;
	state = state!;
	switch (action.type) {
		case 'REQUEST_USER_MESSAGES_SUMMARY':
			return {
				...(state || initialUserMessagesState),
				status: SummaryLoadStatus2.Loading,
			};
		case 'RECEIVE_USER_MESSAGES_SUMMARY':
			return {
				status: action.summary ? SummaryLoadStatus2.Loaded : SummaryLoadStatus2.Failure,
				summary: action.summary || initialUserMessagesState.summary,
			};
		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		default: const exhaustiveCheck: never = action;
	}
	return state;
}

export const userVideosReducer: Reducer<UserVideosSummaryState | null> = (state: UserVideosSummaryState | null | undefined, action: KnownUserVideosAction) => {
	if (state === undefined)
		return null;
	state = state!;
	switch (action.type) {
		case 'REQUEST_USER_VIDEOS_SUMMARY':
			return {
				...(state || initialUserVideosState),
				status: SummaryLoadStatus2.Loading,
			};
		case 'RECEIVE_USER_VIDEOS_SUMMARY':
			return {
				status: action.summary ? SummaryLoadStatus2.Loaded : SummaryLoadStatus2.Failure,
				summary: action.summary || initialUserVideosState.summary,
			};
		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		default: const exhaustiveCheck: never = action;
	}
	return state;
};
