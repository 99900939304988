import * as React from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { UserRole } from '../../../store/user/UserCommon';
import { Page } from '../../app/Page';
import { AcceptTermsAndConditions, TermsAndConditionsAcceptance } from './TermsAndConditionsSupport';

// TODO @@@ Add the other roles into this page (search for air traffic controller on this page)
export default () => (
	<Page>
		<Helmet>
			<title>SkyCert Pilot Terms and Conditions</title>
			<meta name='description' content="Terms and Conditions for the use of SkyCert by pilots, skydivers, and air traffic controllers." />
			<meta name='robots' content='noindex, nofollow' />
		</Helmet>
		<h1>Terms and Conditions</h1>
		<h2>For Pilots, Skydivers, and Air Traffic Controllers</h2>
		<TermsAndConditionsAcceptance role={UserRole.Pilot} />
		<h3>1. Background</h3>
		<p>1.1 SkyCert Limited ("SkyCert", "we", "us" and "our") operates the website www.skycert.nz, www.skycert.co.nz, and www.skycert-demo.azurewebsites.net ("Website") which can be used by pilots, skydivers, and air traffic controllers and other individuals ("you", "your") to assist organise and comply with your medical certification requirements.</p>
		<p>1.2 These Terms of Use apply to any services that SkyCert supplies to you through or in relation to the Website ("Services"). These Terms of Use comprise our contract with you for the provision of the Services.</p>
		<p>1.3 You confirm your understanding and acceptance of these Terms of Use when you first log in to the Website by clicking 'Agree'. By clicking 'Agree' you are also consenting to our <Link to='/info/privacy-policy' target='blank'>Privacy Policy</Link>.</p>
		<h3>2. Your Use of the Services</h3>
		<p>2.1 In using the Services, you will:</p>
		<ol type='a'>
			<li>provide accurate information about yourself including using the same name that you use in everyday life and promptly update notify us if any of your information (including your contact information) when it requires updating;</li>
			<li>only use the Services for the purposes which they are intended;</li>
			<li>maintain the security of your password and login ID and not disclose such information to any third party;</li>
			<li>immediately notify us and change your password if you believe your account has been accessed by a third party or your password has been compromised or otherwise disclosed to any third party;</li>
			<li>not permit any third party to enter any information into the Website on your behalf;</li>
			<li>not knowingly introduce, and take all reasonable precautions to prevent the introduction of, any malware (including viruses, ransomware, spyware and disabling code) into our operating environment (including the Website) or any other infrastructure, network or system owned or used by us; and</li>
			<li>not do, or omit to do, anything which you are aware or ought reasonably to be aware, could have an adverse effect on: our brand or reputation (or which could otherwise bring our brand or reputation into disrepute).</li>
		</ol>
		<h3>3. Intellectual Property</h3>
		<p>3.1 You agree that we own all intellectual property rights, including any patents, trade marks, design rights and copyright, in the Website (and its content), the Services and in any other computer programs, advertising materials, websites, electronic data, manuals, videos, documents and other material that we produce in connection with any Services or otherwise supply to you ("Intellectual Property").</p>
		<p>3.2 Your use of the Website and Services does not provide you with any right, title or interest in the Intellectual Property other than as expressly set out in these tTerms of Use.</p>
		<p>3.3 You agree not to, at any time, directly or indirectly challenge or contest (nor assist any other person to challenge or contest) our right and title to, and interest in, the Intellectual Property.</p>
		<h3>4. Liability & Warranties</h3>
		<p>4.1 Subject to clause 4.2, you acknowledge that:</p>
		<ol type='a'>
			<li>all Services are provided "as is" and any express or implied warranties and/or representations (other than warranties or conditions which may not lawfully be excluded), including implied warranties of merchantability and fitness for a particular purpose, are disclaimed or otherwise excluded;</li>
			<li>without prejudice to the generality of clause 4.1a, we make no warranty or representation regarding the availability of the Services or that the Website and/or Services (including any data or personal information that you provide to us) will be free from security breaches or other unauthorised access and we may suspend, deactivate or limit your access to the Website and/or Services
				<ol type='i'>
					<li>if we reasonably believe that you or someone authorised by you breaches these Terms of Use;</li>
					<li>if we consider necessary due to technical or security issues;</li>
					<li>if we are requested to do so by a government or law enforcement agency; or</li>
					<li>if it is otherwise considered necessary by us at our sole discretion;</li>
				</ol>
			</li>
			<li>your use of any Services is at your sole risk and responsibility.</li>
		</ol>
		<p>4.2 The Consumer Guarantees Act 1993 implies terms, conditions and warranties into some contracts for the supply of goods and services and prohibits the exclusion, restriction and modification of such terms ("Prescribed Terms"). Nothing in these Terms of Use operate so as to exclude, limit, restrict or modify:</p>
		<ol type='a'>
			<li>the application of the Prescribed Terms;</li>
			<li>the exercise of a right conferred by any of the Prescribed Terms;</li>
			<li>our liability for breach of a Prescribed Term (except to the extent that liability may be restricted in accordance with Clause 4.4 below); or</li>
			<li>any other liability that may not be excluded, limited, restricted or modified under any applicable laws.</li>
		</ol>
		<p>4.3 Subject to clause 4.2, we will not be liable to you or any third party (whether for breach of contract, tort, for breach of statute or on any other basis) for any:</p>
		<ol type='a'>
			<li>indirect, consequential or special loss, damage or injury; or</li>
			<li>loss of data, profits, revenue, business opportunity, anticipated savings, wasted overheads or damage to goodwill, regardless of whether in the circumstances it is deemed direct, indirect, consequential or special loss.</li>
		</ol>
		<p>4.4 Subject to clauses 4.1, 4.2 and 4.3, if we are deemed to be liable to you for any loss or damage of any kind whatsoever, arising from the supply of Services by us to you whether suffered or incurred by you or another person or entity and whether in contract or tort (including our negligence) or otherwise, then you agree that our liability will be limited to the fullest extent permitted by law.</p>
		<h3>5. Indemnity</h3>
		<p>5.1 You agree to indemnify us from and against any actions, claims and liabilities arising out of or relating to your breach of these Terms of Use (including any warranty) or any unlawful use of our Website or otherwise arising from your use of the Services.</p>
		<h3>6. Term and Termination</h3>
		<p>6.1 These Terms of Use will come into force from the date upon which you accept them ("Commencement Date") and shall continue until terminated in accordance with this clause 6.</p>
		<p>6.2 Either party may terminate your use of the Services by providing the other party with 30 days notice in writing.</p>
		<p>6.3 We may immediately terminate these Terms of Use and/or suspend provision of Services under these Terms of Use upon written notice to you if:</p>
		<ol type='a'>
			<li>a Default Event occurs with respect to you;</li>
			<li>you commit material breach of this Agreement which is not capable of being remedied; or</li>
			<li>you commit a material breach of this Agreement that is reasonably capable of being remedied, but has not been remedied within 20 business days after receiving a notice from the First Party requiring it to remedy the relevant breach.</li>
		</ol>
		<p>6.4 Each of the following events will be a Default Event for the purposes of clause 6.3(a):</p>
		<ol>
			<li>you go into liquidation, or a receiver, administrator, statutory manager or similar officer is appointed to all or any part of your business or assets;</li>
			<li>you become or are deemed by law to be insolvent;</li>
			<li>you cease, or threaten to cease, to carry on all or substantially all of your business; and/or</li>
			<li>you make any composition, arrangement or assignment with or for the benefit of your creditors.</li>
		</ol>
		<p>6.5 Any termination or suspension will not affect any accrued rights we may have at the time of termination or suspension including any damages for any breach of your obligations to us under these Terms of Use.</p>
		<h3>7. Miscellaneous</h3>
		<p>7.1 We may assign or otherwise transfer any of our rights under these Terms of Use to any other person without your consent. You agree that we may novate our obligations under these Terms of Use to any third party and you will enter into any form of novation that we may reasonably require to give effect to this upon request.</p>
		<p>7.2 We may revise or amend these Terms of Use from time to time. If we do make any such changes we will notify you of the revised Terms of Use and you must accept the revised Terms of Use before continuing to use the Services and/or Website.</p>
		<p>7.3 If we fail to enforce any of the provisions contained in these Terms of Use it shall not be deemed to be a waiver of any of the rights or obligations we have under our contract with you.</p>
		<p>7.4 If any of the provisions of these Terms of Use are determined to be invalid, void or illegal or unenforceable, the validity, legality and enforceability of the remaining provisions shall not be affected, prejudiced or impaired.</p>
		<p>7.5 These Terms of Use are governed by the laws of New Zealand and all disputes or claims in relation to these Terms of Use will be subject to the exclusive jurisdiction of the New Zealand courts.</p>
		<AcceptTermsAndConditions role={UserRole.Pilot} />
	</Page>
);
