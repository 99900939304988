import { Action, Reducer } from 'redux';
import { AppThunkAction } from '..';
import { store } from '../..';
import { getJson, handleWithActionAndErrorToast, postJson, putJson } from '../myfetch';
import { dispatchErrorToast, dispatchToast } from '../notification/NotificationStore';
import { LoadStatus2 } from '../StoreCommon';
import { SecondaryRole } from '../user/UserCommon';
import { FlyingOrganisation, OrganisationLocation, OrganisationServiceProvider, OrganisationStaffMember } from './OrganisationCommon';
import { organisationListActionCreators } from './OrganisationListStore';

// From SkyCert v1

// STATE **********************************************************************

export interface SelectedOrganisationState {
	loadStatus: LoadStatus2;
	hasChanges: boolean;
	flyingOrganisation?: FlyingOrganisation;

	hasSelectedStaffMemberChanges: boolean;
	isSavingSelectedStaffMember: boolean;
	selectedStaffMember?: OrganisationStaffMember;

	hasSelectedLocationChanges: boolean;
	isSavingSelectedLocation: boolean;
	selectedLocation?: OrganisationLocation;

	selectedServiceProvider?: OrganisationServiceProvider;
	isSavingServiceProvider?: boolean;	// undefined = no changes, false = unsaved changes
}

// ACTIONS ********************************************************************

interface PrepareNewOrganisationAction { type: 'PREPARE_NEW_ORGANISATION'; }

interface RequestLoadOrganisationAction { type: 'REQUEST_LOAD_ORGANISATION'; flyingOrganisationId: string; }
interface ReceiveLoadOrganisationAction { type: 'RECEIVE_LOAD_ORGANISATION'; flyingOrganisation?: FlyingOrganisation; }

interface UpdateSelectedOrganisationFieldAction { type: 'UPDATE_ORGANISATION_FIELD'; name: string; value: any; isRequired: boolean; }

interface RequestSaveOrganisationAction { type: 'REQUEST_SAVE_ORGANISATION'; }
interface ReceiveSaveOrganisationAction { type: 'RECEIVE_SAVE_ORGANISATION'; flyingOrganisation?: FlyingOrganisation; }

interface SelectOrganisationStaffMemberAction { type: 'SELECT_ORGANISATION_STAFF_MEMBER'; organisationStaffMemberId: number; }
interface ClearSelectedOrganisationStaffMemberAction { type: 'CLEAR_SELECTED_ORGANISATION_STAFF_MEMBER'; }
interface PrepareNewOrganisationStaffMemberAction { type: 'PREPARE_NEW_ORGANISATION_STAFF_MEMBER'; }

interface UpdateSelectedOrganisationStaffMemberFieldAction { type: 'UPDATE_SELECTED_ORGANISATION_STAFF_MEMBER_FIELD'; name: string; value: any; isRequired: boolean; }

interface RequestSaveOrganisationStaffMemberAction { type: 'REQUEST_SAVE_ORGANISATION_STAFF_MEMBER'; }
interface ReceiveSaveOrganisationStaffMemberAction { type: 'RECEIVE_SAVE_ORGANISATION_STAFF_MEMBER'; organisationStaffMembers?: OrganisationStaffMember[]; }

interface SelectOrganisationLocationAction { type: 'SELECT_ORGANISATION_LOCATION'; organisationLocationId: number; }
interface ClearSelectedOrganisationLocationAction { type: 'CLEAR_SELECTED_ORGANISATION_LOCATION'; }
interface PrepareNewOrganisationLocationAction { type: 'PREPARE_NEW_ORGANISATION_LOCATION'; }

interface UpdateSelectedOrganisationLocationFieldAction { type: 'UPDATE_SELECTED_ORGANISATION_LOCATION_FIELD'; name: string; value: any; isRequired: boolean; }

interface RequestSaveOrganisationLocationAction { type: 'REQUEST_SAVE_ORGANISATION_LOCATION'; }
interface ReceiveSaveOrganisationLocationAction { type: 'RECEIVE_SAVE_ORGANISATION_LOCATION'; organisationLocations?: OrganisationLocation[]; }

interface SelectOrganisationServiceProviderAction { type: 'SELECT_ORGANISATION_SERVICE_PROVIDER'; organisationServiceProviderId?: number; }
interface PrepareNewOrganisationServiceProviderAction { type: 'PREPARE_NEW_ORGANISATION_SERVICE_PROVIDER'; }
interface UpdateSelectedOrganisationServiceProviderFieldAction { type: 'UPDATE_SELECTED_ORGANISATION_SERVICE_PROVIDER_FIELD'; name: string; value: any; isRequired: boolean }

interface RequestSaveOrganisationServiceProviderAction { type: 'REQUEST_SAVE_ORGANISATION_SERVICE_PROVIDER'; }
interface ReceiveSaveOrganisationServiceProviderAction { type: 'RECEIVE_SAVE_ORGANISATION_SERVICE_PROVIDER'; organisationServiceProviders?: OrganisationServiceProvider[]; }

type KnownAction = PrepareNewOrganisationAction
	| RequestLoadOrganisationAction | ReceiveLoadOrganisationAction 
	| UpdateSelectedOrganisationFieldAction
	| RequestSaveOrganisationAction | ReceiveSaveOrganisationAction
	| SelectOrganisationStaffMemberAction | ClearSelectedOrganisationStaffMemberAction | PrepareNewOrganisationStaffMemberAction
	| UpdateSelectedOrganisationStaffMemberFieldAction
	| RequestSaveOrganisationStaffMemberAction | ReceiveSaveOrganisationStaffMemberAction
	| SelectOrganisationLocationAction | ClearSelectedOrganisationLocationAction | PrepareNewOrganisationLocationAction
	| UpdateSelectedOrganisationLocationFieldAction
	| RequestSaveOrganisationLocationAction | ReceiveSaveOrganisationLocationAction
	| PrepareNewOrganisationServiceProviderAction | SelectOrganisationServiceProviderAction
	| UpdateSelectedOrganisationServiceProviderFieldAction
	| RequestSaveOrganisationServiceProviderAction | ReceiveSaveOrganisationServiceProviderAction
	;

// ACTION INVOKERS ************************************************************

export const selectedOrganisationActionInvokers = {
	prepareNewServiceProvider: () => store.dispatch({ type: 'PREPARE_NEW_ORGANISATION_SERVICE_PROVIDER', } as KnownAction),
	selectServiceProvider: (organisationServiceProviderId?: number) => store.dispatch({ type: 'SELECT_ORGANISATION_SERVICE_PROVIDER', organisationServiceProviderId, } as KnownAction),
	updateServiceProviderField: (name: string, value: any, isRequired: boolean) => store.dispatch({ type: 'UPDATE_SELECTED_ORGANISATION_SERVICE_PROVIDER_FIELD', name, value, isRequired, } as KnownAction),

	saveServiceProvider: async () => {
		try {
			store.dispatch({ type: 'REQUEST_SAVE_ORGANISATION_SERVICE_PROVIDER', } as KnownAction);
			const state = store.getState().organisation.selectedOrganisation!
			const serviceProvider = state.selectedServiceProvider!;
			const url = serviceProvider.organisationServiceProviderId
				? `api/organisations/${state.flyingOrganisation!.flyingOrganisationId}/serviceProviders/${serviceProvider.organisationServiceProviderId}`
				: `api/organisations/${state.flyingOrganisation!.flyingOrganisationId}/serviceProviders`
				;
			const method = serviceProvider.organisationServiceProviderId ? putJson : postJson;
			const response = await method(url, serviceProvider);
			store.dispatch({ type: 'RECEIVE_SAVE_ORGANISATION_SERVICE_PROVIDER', organisationServiceProviders: response.organisationServiceProviders, } as KnownAction);
			dispatchToast(true, 'success', 'Service Provider', response.message);
		} catch (error) {
			dispatchErrorToast(true, 'Service Provider', error);
			store.dispatch({ type: 'RECEIVE_SAVE_ORGANISATION_SERVICE_PROVIDER', } as KnownAction);
		}
    },
}

// ACTION CREATORS ************************************************************

export const selectedOrganisationActionCreators = {
	prepareNewOrganisation: () => ({ type: 'PREPARE_NEW_ORGANISATION' } as KnownAction),

	// Set organisationId to 'me' to retrive currently logged in organisation.
	loadOrganisation: (organisationId: string): AppThunkAction<Action> => (dispatch, getState) => {
		// TODO @@@ Check if it is already loaded
		getJson(`api/organisations/${organisationId}`)
			.then(response => {
				dispatch({ type: 'RECEIVE_LOAD_ORGANISATION', flyingOrganisation: response.flyingOrganisation, } as KnownAction);
			})
			.catch(handleWithActionAndErrorToast('Flying Organisation', 'RECEIVE_LOAD_ORGANISATION'));
		dispatch({ type: 'REQUEST_LOAD_ORGANISATION', } as KnownAction);
	},

	updateSelectedOrganisationField: (name: string, value: any, isRequired: boolean) => ({ type: 'UPDATE_ORGANISATION_FIELD', name, value, isRequired, } as KnownAction),

	saveOrganisation: (): AppThunkAction<Action> => (dispatch, getState) => {
		const state: SelectedOrganisationState = getState().organisation.selectedOrganisation!;
		const organisation: FlyingOrganisation = {
			...state.flyingOrganisation!,
			organisationLocations: [],
			organisationStaffMembers: [],
			organisationServiceProviders: [],
		};
		const url: string = organisation.flyingOrganisationId
			? `api/organisations/${organisation.flyingOrganisationId}`
			: `api/organisations`;
		const method = organisation.flyingOrganisationId ? putJson : postJson;
		method(url, organisation)
			.then(response => {
				dispatch({ type: 'RECEIVE_SAVE_ORGANISATION', flyingOrganisation: response.flyingOrganisation, } as KnownAction);
				dispatch(organisationListActionCreators.invalidateCurrentOrganisationsPage());
				dispatchToast(true, 'success', 'Organisation', response.message);
			})
			.catch(handleWithActionAndErrorToast('Organisation', 'RECEIVE_SAVE_ORGANISATION'));
		dispatch({ type: 'REQUEST_SAVE_ORGANISATION', } as KnownAction);
	},

	selectOrganisationStaffMember: (organisationStaffMemberId: number) => ({ type: 'SELECT_ORGANISATION_STAFF_MEMBER', organisationStaffMemberId, } as KnownAction),
	clearSelectedOrganisationStaffMember: () => ({ type: 'CLEAR_SELECTED_ORGANISATION_STAFF_MEMBER', } as KnownAction),
	prepareNewOrganisationStaffMember: () => ({ type: 'PREPARE_NEW_ORGANISATION_STAFF_MEMBER', } as KnownAction),

	updateSelectedOrganisationStaffMemberField: (name: string, value: any, isRequired: boolean) => ({ type: 'UPDATE_SELECTED_ORGANISATION_STAFF_MEMBER_FIELD', name, value, isRequired, } as KnownAction),

	saveOrganisationStaffMember: (): AppThunkAction<Action> => (dispatch, getState) => {
		const state: SelectedOrganisationState = getState().organisation.selectedOrganisation!;
		const organisationStaffMember: OrganisationStaffMember = state.selectedStaffMember!;
		const url: string = organisationStaffMember.organisationStaffMemberId
			? `api/organisations/${state.flyingOrganisation!.flyingOrganisationId}/staff/${organisationStaffMember.organisationStaffMemberId}`
			: `api/organisations/${state.flyingOrganisation!.flyingOrganisationId}/staff`;
		const method = organisationStaffMember.organisationStaffMemberId ? putJson : postJson;
		method(url, organisationStaffMember)
			.then(response => {
				dispatch({ type: 'RECEIVE_SAVE_ORGANISATION_STAFF_MEMBER', organisationStaffMembers: response.organisationStaffMembers, } as KnownAction);
				dispatchToast(true, 'success', 'Staff Member', response.message);
			})
			.catch(handleWithActionAndErrorToast('Staff Member', 'RECEIVE_SAVE_ORGANISATION_STAFF_MEMBER'));
		dispatch({ type: 'REQUEST_SAVE_ORGANISATION_STAFF_MEMBER', } as KnownAction);
	},

	selectOrganisationLocation: (organisationLocationId: number) => ({ type: 'SELECT_ORGANISATION_LOCATION', organisationLocationId, } as KnownAction),
	clearSelectedOrganisationLocation: () => ({ type: 'CLEAR_SELECTED_ORGANISATION_LOCATION', } as KnownAction),
	prepareNewOrganisationLocation: () => ({ type: 'PREPARE_NEW_ORGANISATION_LOCATION', } as KnownAction),

	updateSelectedOrganisationLocationField: (name: string, value: any, isRequired: boolean) => ({ type: 'UPDATE_SELECTED_ORGANISATION_LOCATION_FIELD', name, value, isRequired, } as KnownAction),

	saveOrganisationLocation: (): AppThunkAction<Action> => (dispatch, getState) => {
		const state: SelectedOrganisationState = getState().organisation.selectedOrganisation!;
		const organisationLocation: OrganisationLocation = state.selectedLocation!;
		const url: string = organisationLocation.organisationLocationId
			? `api/organisations/${state.flyingOrganisation!.flyingOrganisationId}/locations/${organisationLocation.organisationLocationId}`
			: `api/organisations/${state.flyingOrganisation!.flyingOrganisationId}/locations`;
		const method = organisationLocation.organisationLocationId ? putJson : postJson;
		method(url, organisationLocation)
			.then(response => {
				dispatch({ type: 'RECEIVE_SAVE_ORGANISATION_LOCATION', organisationLocations: response.organisationLocations, } as KnownAction);
				dispatchToast(true, 'success', 'Location', response.message);
			})
			.catch(handleWithActionAndErrorToast('Location', 'RECEIVE_SAVE_ORGANISATION_LOCATION'));
		dispatch({ type: 'REQUEST_SAVE_ORGANISATION_LOCATION', } as KnownAction);
	},
};

// INITIAL STATE **************************************************************

const initialState: SelectedOrganisationState = {
	loadStatus: LoadStatus2.Idle,
	hasChanges: false,
	hasSelectedStaffMemberChanges: false,
	isSavingSelectedStaffMember: false,
	hasSelectedLocationChanges: false,
	isSavingSelectedLocation: false,
}

const initialOrganisationState: FlyingOrganisation = {
	flyingOrganisationId: 0,
	isActive: true,
	name: '',
	phoneNumber: '',
	mobileNumber: '',
	email: '',
	aviationActivities: 0,
	localAddress: '',
	localSuburb: '',
	localCity: '',
	localState: '',
	localPostcode: '',
	localCountry: '',
	postalAddress: '',
	postalSuburb: '',
	postalCity: '',
	postalState: '',
	postalPostcode: '',
	postalCountry: '',
	allowTesting: false,
	organisationLocations: [],
	organisationStaffMembers: [],
	organisationServiceProviders: [],
}

const initialOrganisationStaffMemberState: OrganisationStaffMember = {
	organisationStaffMemberId: 0,
	flyingOrganisationStaffUserId: '',
	flyingOrganisationStaffName: '',
	role: SecondaryRole.Staff,
	permissions: 0,
	isActive: true,
	canImpersonate: false,
}

const initialOrganisationLocationState: OrganisationLocation = {
	organisationLocationId: 0,
	regionId: 0,
	regionFullName: '',
	isActive: true,
	showOnFindOrganisationPage: true,
	name: '',
	address: '',
}

const initialOrganisationServiceProviderState: OrganisationServiceProvider = {
	organisationServiceProviderId: 0,
	isActive: true,
	specialistPracticeId: 0,
	specialistPracticeName: '',
	serviceProviderActivities: 0,
	serviceProviderActivityNames: '',
}

// REDUCERS *******************************************************************

export const reducer: Reducer<SelectedOrganisationState | null> = (state: SelectedOrganisationState | null | undefined, action: KnownAction) => {
	if (state === undefined)
		return null;
	state = state!;	// @@@ I hope this works
	switch (action.type) {
		case 'PREPARE_NEW_ORGANISATION':
			return {
				...initialState,
				loadStatus: LoadStatus2.Loaded,
				hasChanges: true,
				flyingOrganisation: initialOrganisationState,
			};
		case 'REQUEST_LOAD_ORGANISATION':
			return {
				...initialState,
				loadStatus: LoadStatus2.Loading,
			};
		case 'RECEIVE_LOAD_ORGANISATION':
			return {
				...initialState,
				loadStatus: action.flyingOrganisation ? LoadStatus2.Loaded : LoadStatus2.Failure,
				hasChanges: false,
				flyingOrganisation: action.flyingOrganisation,
			};
		case 'UPDATE_ORGANISATION_FIELD':
			return {
				...state,
				hasChanges: true,
				flyingOrganisation: {
					...state.flyingOrganisation,
					[action.name]: (action.value === '' && !action.isRequired) ? null : action.value,
				},
			} as SelectedOrganisationState;
		case 'REQUEST_SAVE_ORGANISATION':
			return {
				...state,
				loadStatus: LoadStatus2.Saving,
			} as SelectedOrganisationState;
		case 'RECEIVE_SAVE_ORGANISATION':
			return {
				...state,
				loadStatus: LoadStatus2.Loaded,
				hasChanges: !action.flyingOrganisation,
				flyingOrganisation: action.flyingOrganisation || state.flyingOrganisation,
			} as SelectedOrganisationState;
		case 'SELECT_ORGANISATION_STAFF_MEMBER':
			return {
				...state,
				hasSelectedStaffMemberChanges: false,
				isSavingSelectedStaffMember: false,
				selectedStaffMember: state.flyingOrganisation!.organisationStaffMembers.find(osm => osm.organisationStaffMemberId === action.organisationStaffMemberId),
			} as SelectedOrganisationState;
		case 'CLEAR_SELECTED_ORGANISATION_STAFF_MEMBER':
			return {
				...state,
				hasSelectedStaffMemberChanges: false,
				isSavingSelectedStaffMember: false,
				selectedStaffMember: undefined,
			} as SelectedOrganisationState;
		case 'PREPARE_NEW_ORGANISATION_STAFF_MEMBER':
			return {
				...state,
				hasSelectedStaffMemberChanges: true,
				isSavingSelectedStaffMember: false,
				selectedStaffMember: initialOrganisationStaffMemberState,
			} as SelectedOrganisationState;
		case 'UPDATE_SELECTED_ORGANISATION_STAFF_MEMBER_FIELD':
			return {
				...state,
				hasSelectedStaffMemberChanges: true,
				selectedStaffMember: {
					...state.selectedStaffMember!,
					[action.name]: (action.value === '' && !action.isRequired) ? null : action.value,
				},
			} as SelectedOrganisationState;
		case 'REQUEST_SAVE_ORGANISATION_STAFF_MEMBER':
			return {
				...state,
				isSavingSelectedStaffMember: true,
			} as SelectedOrganisationState;
		case 'RECEIVE_SAVE_ORGANISATION_STAFF_MEMBER':
			return {
				...state,
				flyingOrganisation: {
					...state.flyingOrganisation!,
					organisationStaffMembers: action.organisationStaffMembers || state.flyingOrganisation!.organisationStaffMembers,
				},
				hasSelectedStaffMemberChanges: action.organisationStaffMembers ? false: state.hasSelectedStaffMemberChanges,
				isSavingSelectedStaffMember: false,
				selectedStaffMember: action.organisationStaffMembers ? undefined : state.selectedStaffMember,
			} as SelectedOrganisationState;
		case 'SELECT_ORGANISATION_LOCATION':
			return {
				...state,
				hasSelectedLocationChanges: false,
				isSavingSelectedLocation: false,
				selectedLocation: state.flyingOrganisation!.organisationLocations.find(ol => ol.organisationLocationId === action.organisationLocationId),
			} as SelectedOrganisationState;
		case 'CLEAR_SELECTED_ORGANISATION_LOCATION':
			return {
				...state,
				hasSelectedLocationChanges: false,
				isSavingSelectedLocation: false,
				selectedLocation: undefined,
			} as SelectedOrganisationState;
		case 'PREPARE_NEW_ORGANISATION_LOCATION':
			return {
				...state,
				hasSelectedLocationChanges: true,
				isSavingSelectedLocation: false,
				selectedLocation: initialOrganisationLocationState,
			} as SelectedOrganisationState;
		case 'UPDATE_SELECTED_ORGANISATION_LOCATION_FIELD':
			return {
				...state,
				hasSelectedLocationChanges: true,
				selectedLocation: {
					...state.selectedLocation!,
					[action.name]: (action.value === '' && !action.isRequired) ? null : action.value,
				},
			} as SelectedOrganisationState;
		case 'REQUEST_SAVE_ORGANISATION_LOCATION':
			return {
				...state,
				isSavingSelectedLocation: true,
			} as SelectedOrganisationState;
		case 'RECEIVE_SAVE_ORGANISATION_LOCATION':
			return {
				...state,
				flyingOrganisation: {
					...state.flyingOrganisation!,
					organisationLocations: action.organisationLocations || state.flyingOrganisation!.organisationLocations,
				},
				hasSelectedLocationChanges: action.organisationLocations ? false : state.hasSelectedLocationChanges,
				isSavingSelectedLocation: false,
				selectedLocation: action.organisationLocations ? undefined : state.selectedLocation,
			} as SelectedOrganisationState;

		case 'PREPARE_NEW_ORGANISATION_SERVICE_PROVIDER':
			return {
				...state,
				selectedServiceProvider: initialOrganisationServiceProviderState,
				isSavingServiceProvider: false,
			};
		case 'SELECT_ORGANISATION_SERVICE_PROVIDER':
			return {
				...state,
				selectedServiceProvider: !action.organisationServiceProviderId ? undefined
					: state.flyingOrganisation!.organisationServiceProviders.find(osp => osp.organisationServiceProviderId === action.organisationServiceProviderId),
				isSavingServiceProvider: undefined,
			};
		case 'UPDATE_SELECTED_ORGANISATION_SERVICE_PROVIDER_FIELD':
			return {
				...state,
				selectedServiceProvider: {
					...state.selectedServiceProvider!,
					[action.name]: (action.value === '' && !action.isRequired) ? null : action.value,
				},
				isSavingServiceProvider: false,
			};

		case 'REQUEST_SAVE_ORGANISATION_SERVICE_PROVIDER':
			return {
				...state,
				isSavingServiceProvider: true,
			};
		case 'RECEIVE_SAVE_ORGANISATION_SERVICE_PROVIDER':
			return {
				...state,
				flyingOrganisation: {
					...state.flyingOrganisation!,
					organisationServiceProviders: action.organisationServiceProviders || state.flyingOrganisation!.organisationServiceProviders,
				},
				selectedServiceProvider: action.organisationServiceProviders ? undefined : state.selectedServiceProvider,
				isSavingServiceProvider: action.organisationServiceProviders ? undefined : false,
			};

		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		default: const exhaustiveCheck: never = action;
	}
	return state;
}
