import { Action, Reducer } from 'redux';
import { AppThunkAction } from '..';
import { deleteJson, getJson, handleWithActionAndErrorToast, putJson } from '../myfetch';
import { dispatchToast } from '../notification/NotificationStore';
import { LoadStatus2 } from '../StoreCommon';
import { removeInvalidOptions } from '../textSupport';
import { appointmentListActionCreators } from './AppointmentListStore';
import { bookingDatesActionCreators } from './BookingDatesStore';

// From SkyCert v1

// STATE **********************************************************************

export interface CurrentBookingState {
	status: LoadStatus2;
	profileMedicalPracticeId?: number;
	bookingDetails: CurrentBookingDetailsState;
	isSaved?: boolean;	// true if save was successful, false if save failed, undefined if changes not saved
	appointment?: CurrentBookingAppointmentState;
	originalAppointment?: CurrentBookingAppointmentState;
}

export interface CurrentBookingDetailsState {
	appointmentId?: number;
	bookingType?: string;
	bookingJurisdictions?: string;
}
export interface CurrentBookingAppointmentState {
	appointmentId: number;
	locationName: string;
	date: string;
	startTime: string;
	examinerName: string;
	jurisdictions?: string;
	canCancel: boolean;
}

// ACTIONS ********************************************************************

interface RequestCurrentBookingAction { type: 'REQUEST_CURRENT_BOOKING'; }
interface ReceiveCurrentBookingSuccessAction { type: 'RECEIVE_CURRENT_BOOKING_SUCCESS'; profileMedicalPracticeId?: number; bookingDetails: CurrentBookingDetailsState; appointment?: CurrentBookingAppointmentState; }
interface ReceiveCurrentBookingFailureAction { type: 'RECEIVE_CURRENT_BOOKING_FAILURE'; }

interface SetBookingAppointmentAction { type: 'SET_BOOKING_APPOINTMENT'; appointment: CurrentBookingAppointmentState; }
interface UpdateBookingDetailsFieldAction { type: 'UPDATE_BOOKING_DETAILS_FIELD'; name: string; value: any; isRequired: boolean; }

interface RequestSaveAppointmentAction { type: 'REQUEST_SAVE_BOOKING_APPOINTMENT'; }
interface ReceiveSaveAppointmentAction { type: 'RECEIVE_SAVE_BOOKING_APPOINTMENT'; isOk?: boolean; }

interface RequestCancelAppointmentBookingAction { type: 'REQUEST_CANCEL_APPOINTMENT_BOOKING'; }
interface ReceiveCancelAppointmentBookingAction { type: 'RECEIVE_CANCEL_APPOINTMENT_BOOKING'; isOk?: boolean; }

type KnownAction = RequestCurrentBookingAction | ReceiveCurrentBookingSuccessAction | ReceiveCurrentBookingFailureAction
	| SetBookingAppointmentAction | UpdateBookingDetailsFieldAction
	| RequestSaveAppointmentAction | ReceiveSaveAppointmentAction
	| RequestCancelAppointmentBookingAction | ReceiveCancelAppointmentBookingAction
	;

// ACTION CREATORS ************************************************************

export const currentBookingActionCreators = {
	requestCurrentBooking: (pilotUserId: string | undefined): AppThunkAction<Action> => (dispatch, getState) => {
		const userIdSuffix: string = !pilotUserId ? '' : `?pilotUserId=${pilotUserId}`;
		getJson(`api/appointments/current${userIdSuffix}`)
			.then(response => {
				dispatch({ type: 'RECEIVE_CURRENT_BOOKING_SUCCESS', profileMedicalPracticeId: response.profileMedicalPracticeId, bookingDetails: response.bookingDetails, appointment: response.appointment, } as KnownAction);
			})
			.catch(handleWithActionAndErrorToast('Current Booking', 'RECEIVE_CURRENT_BOOKING_FAILURE'));
		dispatch({ type: 'REQUEST_CURRENT_BOOKING', } as KnownAction);
	},

	setBookingAppointment: (appointment: CurrentBookingAppointmentState) => ({ type: 'SET_BOOKING_APPOINTMENT', appointment, } as KnownAction),

	updateBookingField: (name: string, value: any, isRequired: boolean) => ({ type: 'UPDATE_BOOKING_DETAILS_FIELD', name, value, isRequired, } as KnownAction),

	requestSaveBooking: (pilotUserId: string, booking: CurrentBookingDetailsState): AppThunkAction<Action> => (dispatch, getState) => {
		putJson(`api/appointments/${booking.appointmentId!}/booking`, { pilotUserId, bookingType: booking.bookingType, bookingJurisdictions: booking.bookingJurisdictions, })
			.then(response => {
				dispatch({ type: 'RECEIVE_SAVE_BOOKING_APPOINTMENT', isOk: true, } as KnownAction);
				dispatchToast(true, 'success', 'Current Booking', response.message);
			})
			.catch(handleWithActionAndErrorToast('Current Booking', 'RECEIVE_SAVE_BOOKING_APPOINTMENT'))
			.then(x => {
				dispatch(appointmentListActionCreators.invalidateAppointmentList());
				dispatch(bookingDatesActionCreators.invalidateBookingDatesList());
			});
		dispatch({ type: 'REQUEST_SAVE_BOOKING_APPOINTMENT', } as KnownAction);
	},

	requestCancelBooking: (appointmentId: number): AppThunkAction<Action> => (dispatch, getState) => {
		deleteJson(`api/appointments/${appointmentId}/booking`)
			.then(response => {
				dispatch({ type: 'RECEIVE_CANCEL_APPOINTMENT_BOOKING', isOk: true, } as KnownAction);
				dispatchToast(true, 'success', 'Current Booking', response.message);
				dispatch(appointmentListActionCreators.invalidateAppointmentList());
				dispatch(bookingDatesActionCreators.invalidateBookingDatesList());
			})
			.catch(handleWithActionAndErrorToast('Current Booking', 'RECEIVE_CANCEL_APPOINTMENT_BOOKING'));
		dispatch({ type: 'REQUEST_CANCEL_APPOINTMENT_BOOKING', } as KnownAction);
	},
};

// REDUCERS *******************************************************************

export const reducer: Reducer<CurrentBookingState | null> = (state: CurrentBookingState | null | undefined, action: KnownAction) => {
	if (state === undefined)
		return null;
	state = state!;	// @@@ I hope this works
	switch (action.type) {
		case 'REQUEST_CURRENT_BOOKING':
			return {
				status: LoadStatus2.Loading,
				bookingDetails: {},
			};
		case 'RECEIVE_CURRENT_BOOKING_SUCCESS':
			return {
				status: LoadStatus2.Loaded,
				profileMedicalPracticeId: action.profileMedicalPracticeId,
				bookingDetails: action.bookingDetails,
				isSaved: true,
				appointment: action.appointment,
				originalAppointment: action.appointment,
			};
		case 'RECEIVE_CURRENT_BOOKING_FAILURE':
			return {
				status: LoadStatus2.Failure,
				bookingDetails: {},
				isSaved: false,
			};
		case 'SET_BOOKING_APPOINTMENT':
			return {
				...state,
				bookingDetails: {
					...state.bookingDetails,
					appointmentId: action.appointment.appointmentId,
					bookingJurisdictions: removeInvalidOptions(state.bookingDetails.bookingJurisdictions, action.appointment.jurisdictions),
				},
				appointment: action.appointment,
				isSaved: undefined,
			};
		case 'UPDATE_BOOKING_DETAILS_FIELD':
			return {
				...state,
				isSaved: undefined,
				bookingDetails: {
					...state.bookingDetails,
					[action.name]: (action.value === '' && !action.isRequired) ? null : action.value,
				},
			};
		case 'REQUEST_SAVE_BOOKING_APPOINTMENT':
			return {
				...state,
				status: LoadStatus2.Saving,
			};
		case 'RECEIVE_SAVE_BOOKING_APPOINTMENT':
			return {
				...state,
				status: LoadStatus2.Loaded,
				isSaved: !!action.isOk,
				originalAppointment: action.isOk ? state.appointment : state.originalAppointment,
			};
		case 'REQUEST_CANCEL_APPOINTMENT_BOOKING':
			return {
				...state,
				status: LoadStatus2.Saving,
			};
		case 'RECEIVE_CANCEL_APPOINTMENT_BOOKING':
			return {
				...state,
				status: LoadStatus2.Loaded,
				isSaved: !!action.isOk,
				appointment: undefined,
				originalAppointment: undefined,
				bookingDetails: {
					...state.bookingDetails,
					appointmentId: undefined,
				},
			};
		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		default: const exhaustiveCheck: never = action;
	}

	return state;
};
