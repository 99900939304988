import { Action, combineReducers, Reducer } from 'redux';
import * as SelectedVideoStore from './SelectedVideoStore';
import * as VideoCategoryStore from './VideoCategoryStore';
import * as VideoListStore from './VideoListStore';
import { videoUrlsReducer, VideoUrlsState } from './VideoUrlsStore';

// From SkyCert v1

// STATE **********************************************************************

export interface VideoState {
	categories: VideoCategoryStore.VideoCategoryState | null;
	videos: VideoListStore.VideoListState | null;
	selectedVideo: SelectedVideoStore.SelectedVideoState | null;
	videoUrls: VideoUrlsState | null;
}

// REDUCERS *******************************************************************

const combinedReducer: Reducer<VideoState> = combineReducers<VideoState>({
	categories: VideoCategoryStore.reducer,
	videos: VideoListStore.reducer,
	selectedVideo: SelectedVideoStore.reducer,
	videoUrls: videoUrlsReducer,
})

export const reducer: Reducer<VideoState> = (state: VideoState | undefined, action: Action) => {
	if (action.type === 'REQUEST_LOGOUT')
		state = undefined;
	return combinedReducer(state, action);
}
