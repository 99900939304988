import * as React from 'react';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Spinner, Toast, ToastBody, ToastHeader } from 'reactstrap';
import { ApplicationState } from '../../store';
import { dispatchCloseToast, NotificationToast } from '../../store/notification/NotificationStore';
import './Toaster.css';

export const Toaster = () => {
	const toasts = useSelector(state => (state as ApplicationState).notification!.toasts);

	if (!toasts || toasts.length === 0)
		return null;

	return (
		<div id='toaster' className='p-3 rounded'>
			{toasts.map(t => <Slice toast={t} key={t.toastId} />)}
		</div>
	);
}


interface SliceProps {
	toast: NotificationToast;
}

const Slice = (props: SliceProps) => {
	const [showAllDetails, setShowAllDetails] = useState(false);

	let details = props.toast.details;
	if (details.length > 6 && !showAllDetails) {
		const showAll = (e: React.MouseEvent<HTMLAnchorElement>) => {
			e.preventDefault();
			e.stopPropagation();
			setShowAllDetails(true);
		}
		const extra = <Link to='#' onClick={showAll}>Show {details.length - 5} more</Link>;
		details = details.slice(0, 5);
		details.push(extra);
	}
	return (
		<Toast className='clickable' isOpen={!props.toast.isClosed} onClick={() => dispatchCloseToast(props.toast.toastId)} key={props.toast.toastId}>
			<ToastHeader icon={props.toast.autoHide ? <Spinner color={props.toast.icon} size='sm' /> : props.toast.icon} toggle={() => { }}>
				{props.toast.title}
							&nbsp;&nbsp;&nbsp;
						</ToastHeader>
			<ToastBody>
				{details.map((d, i) => (
					<React.Fragment key={i}>
						{i !== 0 && <br />}
						{d}
					</React.Fragment>
				))}
			</ToastBody>
		</Toast>
	);
}
