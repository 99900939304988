import * as React from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { CardBody } from 'reactstrap';
import { ApplicationState } from '../../store';
import { SummaryStatus } from '../../store/summary/SummaryCommon';
import * as UserSummaryStore from '../../store/summary/UserSummaryStore';
import Loading from '../support/Loading';
import { StandardSummarySize } from '../support/Summaries';
import { View } from '../support/Views';

// From SkyCert v1

type MedicalPracticeSummaryProps =
	UserSummaryStore.PracticeState
	& typeof UserSummaryStore.userSummaryActionCreators
	& RouteComponentProps<{}>;

class MedicalPracticeSummary extends React.Component<MedicalPracticeSummaryProps, {}> {
	// eslint-disable-next-line @typescript-eslint/no-useless-constructor
	constructor(props: any) {
		super(props);
	}

	componentDidMount() {
		this.props.requestPracticeSummary();
    }

	public render() {
		return <StandardSummarySize>
			<View header='Practice' onClickHeader={this.goToPractice}>
				<CardBody>
					{this.renderBody()}
					<Link to='/practice/manage/myPractice'>Manage</Link>
                </CardBody>
            </View>
		</StandardSummarySize>;
	}

	goToPractice = () => {
		this.props.history.push('/practice/manage/myPractice');
	}

	private renderBody() {
		if (this.props.status === SummaryStatus.Loading) {
			return <p><Loading /></p>;
		} else if (this.props.status === SummaryStatus.Success) {
			return <p>
				{this.props.numberOfMedicalExaminers} examiners.<br />
				{this.props.numberOfNurses} nurses.
			</p>;
		} else {	// SummaryStatus.Failure
            return <p className='text-danger'>Unable to load!</p>;
		}
	}
}

export default connect(
	(state: ApplicationState) => state.summary.practice || {},
	UserSummaryStore.userSummaryActionCreators
)(MedicalPracticeSummary) /*as typeof MedicalPracticeSummary*/;
