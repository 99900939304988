import { Action, Reducer } from 'redux';
import { AppThunkAction } from '..';
import { ListCriteria, ListPage, ListState, ListStatus2 } from '../list/ListCommon';
import { criteriaMatches, getCriteriaUrl } from '../listSupport';
import { deleteJson, getJson, handleWithAction, handleWithErrorToast } from '../myfetch';
import { Reminder } from './UserCommon';

// From SkyCert v1

// STATE **********************************************************************

const numberOfRemindersPagesToKeep: number = 3;

export interface ReminderListState extends ListState<Reminder> {
	remindUserId: string;
	associatedUserId: string;
}

export enum RemindersListOrderBy {
	EarlyWarning = 'EarlyWarning',
	Warning = 'Warning',
	Text = 'Text',
	Type = 'Type',
	User = 'User',
	Associated = 'Associated',
}

export enum RemindersListFilter1 {
}

// ACTIONS ********************************************************************

interface RequestReminderListAction {
	type: 'REQUEST_REMINDER_LIST';
	remindUserId: string;
	associatedUserId: string;
	criteria: ListCriteria;
}
interface ReceiveReminderListSuccessAction {
	type: 'RECEIVE_REMINDER_LIST_SUCCESS';
	numberOfPages: number;
	totalNumberOfItems: number;
	page: ListPage<Reminder>;
}
interface ReceiveReminderListFailureAction { type: 'RECEIVE_REMINDER_LIST_FAILURE'; }

interface InvalidateCurrentReminderListAction { type: 'INVALIDATE_CURRENT_REMINDER_LIST'; }

type KnownAction = RequestReminderListAction | ReceiveReminderListSuccessAction | ReceiveReminderListFailureAction
	| InvalidateCurrentReminderListAction
	;

// ACTION CREATORS ************************************************************

export const reminderListActionCreators = {
	requestReminderList: (remindUserId: string, associatedUserId: string, criteria: ListCriteria): AppThunkAction<Action> => (dispatch, getState) => {
		const userState = getState().user;
		if (userState.listOfReminders && userState.listOfReminders.listStatus !== ListStatus2.Idle) {
			const state: ReminderListState = userState.listOfReminders!;
			if (remindUserId === state.remindUserId && associatedUserId === state.associatedUserId
				&& criteria.itemsPerPage === state.criteria.itemsPerPage
				&& criteria.filter1 === state.criteria.filter1 && criteria.filter2 === state.criteria.filter2
				&& criteria.orderBy === state.criteria.orderBy && criteria.orderAscending === state.criteria.orderAscending) {
				// See if the requested page is already loaded.
				for (let iPage: number = 0; iPage < state.pages.length; iPage++) {
					if (criteria.pageNumber === state.pages[iPage].pageNumber) {
						if (iPage !== 0) {
							dispatch({
								type: 'RECEIVE_REMINDER_LIST_SUCCESS',
								numberOfPages: state.numberOfPages,
								totalNumberOfItems: state.totalNumberOfItems,
								page: state.pages[iPage],
							} as KnownAction);
						}
						return;
					}
				}
			}
		}
		// The requested page is not already loaded.
		// TODO @@@ IS THIS USED? @@@@@@@@@@@@@@@@@
		const associatedUserUrlSuffix: string = !associatedUserId ? '' : `/${associatedUserId}`;
		const criteriaUrlSuffix: string = getCriteriaUrl(criteria);
		const url: string = `api/users/${remindUserId}/reminders${associatedUserUrlSuffix}?${criteriaUrlSuffix}`;
		getJson(url)
			.then(response => {
				dispatch({ type: 'RECEIVE_REMINDER_LIST_SUCCESS', numberOfPages: response.numberOfPages, page: response.page, canCreateNewApplication: response.canCreateNewApplication, totalNumberOfItems: response.totalNumberOfItems, } as KnownAction);
			})
			.catch(handleWithAction('RECEIVE_REMINDER_LIST_FAILURE'));
		dispatch({ type: 'REQUEST_REMINDER_LIST', remindUserId, associatedUserId, criteria, } as KnownAction);
	},

	requestDeleteReminder: (reminderId: number): AppThunkAction<Action> => (dispatch, getState) => {
		deleteJson(`api/reminders/${reminderId}`)
			.then(response => {
				dispatch(reminderListActionCreators.invalidateCurrentRemindersPage());
			})
			.catch(handleWithErrorToast('Delete Reminder'));
	},

	invalidateCurrentRemindersPage: () => ({ type: 'INVALIDATE_CURRENT_REMINDER_LIST' } as KnownAction),
};

// REDUCERS *******************************************************************

export const reducer: Reducer<ReminderListState | null> = (state: ReminderListState | null | undefined, action: KnownAction) => {
	if (state === undefined)
		return null;
	state = state!;	// @@@ I hope this works
	switch (action.type) {
		case 'REQUEST_REMINDER_LIST':
			const criteriaMatch: boolean = state && criteriaMatches(action.criteria, state.criteria);
			return {
				remindUserId: action.remindUserId,
				associatedUserId: action.associatedUserId,
				criteria: action.criteria,
				listStatus: ListStatus2.Loading,
				numberOfPages: criteriaMatch ? state.numberOfPages : 1,
				pages: criteriaMatch ? state.pages : [],
				canCreateNewApplication: false,
			};
		case 'RECEIVE_REMINDER_LIST_SUCCESS':
			let newPages: ListPage<Reminder>[] = [
				action.page,
				...state.pages.filter(page => page.pageNumber !== action.page.pageNumber),
			];
			while (newPages.length > numberOfRemindersPagesToKeep) newPages.pop();
			return {
				...state,
				criteria: {
					...state.criteria,
					pageNumber: action.page.pageNumber,
				},
				listStatus: ListStatus2.Loaded,
				numberOfPages: action.numberOfPages,
				totalNumberOfItems: action.totalNumberOfItems,
				pages: newPages,
			};
		case 'RECEIVE_REMINDER_LIST_FAILURE':
			return {
				...state,
				listStatus: ListStatus2.Failure,
			};
		case 'INVALIDATE_CURRENT_REMINDER_LIST':
			if (!state)
				return state;
			return {
				...state,
				listStatus: ListStatus2.Dirty,
				pages: [],
			}
		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		default: const exhaustiveCheck: never = action;
	}

	return state;
};
