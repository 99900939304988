import * as React from 'react';
import { RouteChildrenProps } from 'react-router';
import ChildrenProps from './ChildrenProps';
import { LayoutSize } from './Layouts';


///////////////////////////////////////////////////////////////////////////////

export const StandardSummarySize = (props: ChildrenProps) => (
	<LayoutSize gridClassName='col-6 col-sm-4 col-lg-3 col-xl-2'>
		{props.children}
	</LayoutSize>
);


export const LargeSummarySize = (props: ChildrenProps) => (
	<LayoutSize gridClassName='col-12 col-sm-8 col-lg-6 col-xl-4'>
		{props.children}
	</LayoutSize>
);


export const VeryLargeSummarySize = (props: ChildrenProps) => (
	<LayoutSize gridClassName='col-12 col-lg-9 col-xl-8'>
		{props.children}
	</LayoutSize>
);


///////////////////////////////////////////////////////////////////////////////

interface SwitchableSummarySizeProps {
	size?: 'standard' | 'large' | 'veryLarge';	// Defaults to standard
	children?: ((props: RouteChildrenProps<any>) => React.ReactNode) | React.ReactNode;
}

export const SwitchableSummarySize = (props: SwitchableSummarySizeProps) => {
	switch (props.size || 'standard') {
		case 'standard':
			return <StandardSummarySize>{props.children}</StandardSummarySize>;
		case 'large':
			return <LargeSummarySize>{props.children}</LargeSummarySize>;
		case 'veryLarge':
			return <VeryLargeSummarySize>{props.children}</VeryLargeSummarySize>;
		default:
			return null;	// TODO @@@ Throw an error here?
	}
}
