import * as React from 'react';


export interface DialogProviderProps {
    render: (toggleIsOpen: () => void) => void;
    dialog: (toggleIsOpen: () => void) => void;
}

export const DialogProvider = (props: DialogProviderProps) => {
    const [isOpen, setIsOpen] = React.useState(false);
    const toggleIsOpen = () => setIsOpen(value => !value);

    return <>
        {props.render(toggleIsOpen)}
        {isOpen && props.dialog(toggleIsOpen)}
    </>;
}
