import * as React from 'react';
import { ReactNode, useEffect, useState } from 'react';
import { RouteChildrenProps } from 'react-router';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import * as Icon from './Icon';

interface NewFeatureProps {
	id: string;
	description: ReactNode;
	endDate?: Date | string;
	inline?: boolean;
	expected?: string;
	force?: boolean;
	children?: ((props: RouteChildrenProps<any>) => React.ReactNode) | React.ReactNode;
}

export default (props: NewFeatureProps) => {
	const [isOpen, setIsOpen] = useState(false);
	const [isSeen, setIsSeen] = useState(true);

	var expected = props.expected || 'seen';

	useEffect(() => {
		var seen = window.localStorage.getItem('newFeature' + props.id) === expected;
		if (seen) {
			setIsSeen(true);
			return;
		}
		var endDate = typeof props.endDate === 'string' ? new Date(props.endDate) : props.endDate;
		var endTime = endDate?.getTime();
		if (endTime && new Date().getTime() > endTime) {
			setIsSeen(true);
			window.localStorage.setItem('newFeature' + props.id, expected);
			return;
		}
		setIsSeen(false);
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [expected]);

	const showNew = props.force === undefined ? !isSeen : props.force;
	if (showNew) {
		const toggle = () => setIsOpen(!isOpen);
		const clear = () => {
			setIsOpen(!isOpen);
			setIsSeen(true);
			window.localStorage.setItem('newFeature' + props.id, expected);
		}
		const outerStyle: React.CSSProperties = {
			backgroundColor: '#e84249',
			padding: '10px',
		};
		const innerStyle: React.CSSProperties = {
			backgroundColor: 'white',
			padding: '5px',
		};
		const info = (
			<>
				&nbsp;
				<Button type='button' color='link' onClick={toggle} title='Click here to see what has changed'>
					<Icon.Information style={{ color: '#e84249', }} />
				</Button>
				<Modal isOpen={isOpen} toggle={toggle}>
					<ModalHeader toggle={toggle}>New Feature</ModalHeader>
					<ModalBody>
						{props.description}
					</ModalBody>
					<ModalFooter>
						<Button color='primary' onClick={clear}>Don't remind me again</Button>
						<Button color='secondary' onClick={toggle}>Remind me later</Button>
					</ModalFooter>
				</Modal>
			</>
		);
		if (props.inline) {
			return (
				<span style={outerStyle}>
					<span style={innerStyle}>
						{props.children}
						{info}
					</span>
				</span>
			);
		} else {
			return (
				<div style={outerStyle} id='ii'>
					<div style={innerStyle}>
						{props.children}
						{info}
					</div>
				</div>
			);
		}
	} else {
		return (
			<>
				{props.children}
			</>
		);
	}
}
