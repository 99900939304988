import { store } from "..";
import { dispatchErrorToast, dispatchToast } from "./notification/NotificationStore";
import { LoginStatus } from "./user/LoginStore";

// Anti-forgery ///////////////////////////////////////////////////////////////

let accessToken: string = '';

export const getAccessToken = () => accessToken;

export const setAccessToken = (value?: string, store?: any) => {
	accessToken = value || '';
}

// Fetch //////////////////////////////////////////////////////////////////////

export const myfetch = (method: string, url: string, data: any = undefined, init: RequestInit = {}): Promise<Response> => {
	const isFormData = data instanceof FormData;
	const body = data && !isFormData
		? JSON.stringify(data).replace(/[\u007F-\uFFFF]/g, function (c) { return "\\u" + ("0000" + c.charCodeAt(0).toString(16)).substr(-4); })
		: data;
	if (body)
		init = { ...init, body, };
	var headers: HeadersInit = { ...init.headers, 'Access-Token': accessToken, };
	if (data && !isFormData)
		headers = { ...headers, 'Content-Type': 'application/json', };
	return fetch(url, {
		...init,
		headers,
		method,
		credentials: 'same-origin',
		cache: 'no-store',
	});
}

export const fetchJson = async (method: string, url: string, data: any = undefined, init: RequestInit = {}): Promise<any> => {
	const response = await myfetch(method, url, data, init);
	if (response.status === 401) {
		const login = store.getState().user.login;
		if (login.status === LoginStatus.LoggedIn && new Date().getTime() >= (login.loggedIn?.expires ?? 0))
			store.dispatch({ type: 'TIMEOUT_LOGIN', });
	}
	const responseContentType = getContentType(response);
	if (responseContentType === 'application/json') {
		const json = await response.json();
		if (response.ok || response.status === 300) {
			// TODO @@@ This check is needed since some of the older APIs don't use nested objects
			if (json.status)
				return json;
			else return { ...json, status: response.status, };
		} else if (json.name) {
			const error = json as Error;
			const name = error.name.substr(0, error.name.length - 5);
			// eslint-disable-next-line no-throw-literal
			throw { title: `A "${name}" error occurred.`, detail: error.message, status: response.status, };
		} else {
			throw json;
		}
	} else if (responseContentType === 'application/problem+json') {
		const json = await response.json();
		throw json;
	} else {
		if (response.ok)
			// eslint-disable-next-line no-throw-literal
			throw { title: 'Invalid data was received.', detail: `The type was "${responseContentType}".`, status: response.status, };
		// eslint-disable-next-line no-throw-literal
		else throw { title: 'There was an error processing this request.', detail: ['No explanation was provided.', 'Please contact SkyCert.'], status: response.status, };
	}
}

export const getJson = (url: string): Promise<any> => fetchJson('GET', url, undefined);

export const postJson = (url: string, body: any): Promise<any> => fetchJson('POST', url, body);

export const putJson = (url: string, body: any): Promise<any> => fetchJson('PUT', url, body);

export const deleteJson = (url: string): Promise<any> => fetchJson('DELETE', url, undefined);
export const deleteJsonWithPayload = (url: string, body: any): Promise<any> => fetchJson('DELETE', url, body);


// TODO @@@ Do i want to keep this?

const doFetch = async (method: string, url: string, data: any, title: string, setIsBusy: (isBusy: boolean) => void, init: RequestInit = {}): Promise<any> => {
	try {
		setIsBusy(true);
		// TODO @@@ Allow configurable content type?
		const response = await fetchJson(method, url, data, init);
		dispatchToast(true, 'success', title, response.message);
		return response;
	} catch (error) {
		dispatchErrorToast(true, title, error);
		return undefined;
	} finally {
		setIsBusy(false);
	}
}

export const doGet = (url: string, title: string, setIsBusy: (isBusy: boolean) => void): Promise<any> => doFetch('GET', url, undefined, title, setIsBusy);

export const doPost = (url: string, body: any, title: string, setIsBusy: (isBusy: boolean) => void): Promise<any> => doFetch('POST', url, body, title, setIsBusy);

export const doPut = (url: string, body: any, title: string, setIsBusy: (isBusy: boolean) => void): Promise<any> => doFetch('PUT', url, body, title, setIsBusy);

export const doDelete = (url: string, title: string, setIsBusy: (isBusy: boolean) => void): Promise<any> => doFetch('DELETE', url, undefined, title, setIsBusy);


const getContentType = (response: Response) => {
	let contentType: string = response.headers.get('Content-Type') || '';
	let pos = contentType.indexOf(';');
	if (pos !== -1)
		contentType = contentType.substr(0, pos);
	return contentType;
}

// OLD Responses //////////////////////////////////////////////////////////////

export const respondWithAction = (type: string) => {
	return (response: any) => {
		store.dispatch({ type, });
	}
}

export const respondWithToast = (title: string) => {
	return (response: any) => {
		dispatchToast(true, 'success', title, response.message);
	}
}

export const respondWithActionAndToast = (title: string, type: string) => {
	return (response: any) => {
		store.dispatch({ type, });
		dispatchToast(true, 'success', title, response.message);
	}
}

// OLD Errors /////////////////////////////////////////////////////////////////

export const handleWithAction = (type: string) => {
	return (error: any) => {
		store.dispatch({ type, });
	};
}

export const handleWithErrorToast = (title: string) => {
	return (error: any) => {
		if (error.status !== 401)
			dispatchErrorToast(true, title, error);
	};
}

export const handleWithActionAndErrorToast = (title: string, type: string) => {
	return (error: any) => {
		store.dispatch({ type, });
		if (error.status !== 401)
			dispatchErrorToast(true, title, error);
	};
}
