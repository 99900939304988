import { Action, combineReducers, Reducer } from 'redux';
import * as OrganisationListStore from './OrganisationListStore';
import * as ParentPilotListStore from '../list/ParentPilotListStore';
import * as SelectedOrganisationStore from './SelectedOrganisationStore';
import * as TestSchedulesStore from './TestSchedulesStore';
import * as UploadPilotResultStore from './UploadPilotResultStore';

// From SkyCert v1

// STATE **********************************************************************

export interface OrganisationState {
	list: OrganisationListStore.OrganisationListState | null;
	listOfOrganisationPilots: ParentPilotListStore.ParentPilotListState | null;
	selectedOrganisation: SelectedOrganisationStore.SelectedOrganisationState | null;
	testSchedules: TestSchedulesStore.TestSchedulesState | null;
	uploadPilotResult: UploadPilotResultStore.UploadPilotResultState | null;
}

// ACTION CREATORS ************************************************************

export const listOfOrganisationPilotsActionCreators = ParentPilotListStore.parentPilotListActionCreators('ORGANISATION', 'organisations', 'organisation', 'listOfOrganisationPilots');

// REDUCERS *******************************************************************

const combinedReducer: Reducer<OrganisationState> = combineReducers<OrganisationState>({
	list: OrganisationListStore.reducer,
	listOfOrganisationPilots: ParentPilotListStore.parentPilotListReducer('ORGANISATION'),
	selectedOrganisation: SelectedOrganisationStore.reducer,
	testSchedules: TestSchedulesStore.reducer,
	uploadPilotResult: UploadPilotResultStore.reducer,
})

export const reducer: Reducer<OrganisationState> = (state: OrganisationState | undefined, action: Action) => {
	if (action.type === 'REQUEST_LOGOUT')
		state = undefined;
	return combinedReducer(state, action);
}
