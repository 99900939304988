import { Reducer } from "redux";
import { store } from "../..";
import { List2Criteria, List2State, List2Status } from "../list/ListCommon";
import { getCriteriaUrl2 } from "../listSupport";
import { getJson } from "../myfetch";
import { dispatchErrorToast } from "../notification/NotificationStore";

// STATE //////////////////////////////////////////////////////////////////////

export interface SpecialistOrganisationListState {
	organisationList?: List2State<SpecialistOrganisationSummary>;
}

export interface SpecialistOrganisationSummary {
	organisationId: string;
	name: string;
	isActive: boolean;
}

export enum SpecialistOrganisationListOrderBy {
	Name = 'Name',
}

export enum SpecialistOrganisationListFilter1 {
	All = 'All',
}


// ACTIONS ////////////////////////////////////////////////////////////////////

interface RequestSpecialistOrganisationListAction {
	type: 'REQUEST_SPECIALIST_ORGANISATION_LIST';
	criteria: List2Criteria;
}
interface ReceiveSpecialistOrganisationListSuccessAction {
	type: 'RECEIVE_SPECIALIST_ORGANISATION_LIST_SUCCESS';
	numberOfPages: number;
	totalNumberOfItems: number;
	items: SpecialistOrganisationSummary[];
}
interface ReceiveSpecialistOrganisationListFailureAction { type: 'RECEIVE_SPECIALIST_ORGANISATION_LIST_FAILURE'; }

type KnownAction =
	RequestSpecialistOrganisationListAction | ReceiveSpecialistOrganisationListSuccessAction | ReceiveSpecialistOrganisationListFailureAction
	;


// ACTION INVOKERS ////////////////////////////////////////////////////////////

export const specialistOrganisationListActionInvokers = {
	requestSpecialistOrganisationList: async (specialistPracticeId: string | number, criteria: List2Criteria) => {
		try {
			store.dispatch({ type: 'REQUEST_SPECIALIST_ORGANISATION_LIST', criteria, } as KnownAction);
			const criteriaUrlSuffix = getCriteriaUrl2(criteria);
			const url = `api/specialists/${specialistPracticeId}/organisations?${criteriaUrlSuffix}`;
			const response = await getJson(url);
			store.dispatch({ type: 'RECEIVE_SPECIALIST_ORGANISATION_LIST_SUCCESS', numberOfPages: response.numberOfPages, totalNumberOfItems: response.totalNumberOfItems, items: response.items, } as KnownAction);
		} catch (error) {
			store.dispatch({ type: 'RECEIVE_SPECIALIST_ORGANISATION_LIST_FAILURE', } as KnownAction);
			dispatchErrorToast(true, 'Organisation List', error);
        }
	},
};


// REDUCER ////////////////////////////////////////////////////////////////////

export const reducer: Reducer<SpecialistOrganisationListState | null> = (state: SpecialistOrganisationListState | undefined | null, action: KnownAction) => {
	if (state === undefined)
		return null;
	switch (action.type) {
		case 'REQUEST_SPECIALIST_ORGANISATION_LIST':
			return {
				organisationList: {
					criteria: action.criteria,
					listStatus: List2Status.Loading,
					numberOfPages: 0,
					totalNumberOfItems: undefined,
					items: [],
                },
			};
		case 'RECEIVE_SPECIALIST_ORGANISATION_LIST_SUCCESS':
			return {
				...state,
				organisationList: {
					criteria: state!.organisationList!.criteria,
					listStatus: List2Status.Loaded,
					numberOfPages: action.numberOfPages,
					totalNumberOfItems: action.totalNumberOfItems,
					items: action.items,
				},
			};
		case 'RECEIVE_SPECIALIST_ORGANISATION_LIST_FAILURE':
			return {
				...state,
				organisationList: {
					...state!.organisationList!,
					listStatus: List2Status.Failure,
				},
			};
 		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		default: const exhaustiveCheck: never = action;
	}
	return state;
}
