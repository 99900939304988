import * as React from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { CardBody } from 'reactstrap';
import { ApplicationState } from '../../store';
import { SummaryLoadStatus2 } from '../../store/summary/SummaryCommon';
import * as UserSummaryStore from '../../store/summary/UserSummaryStore';
import * as LoginStore from '../../store/user/LoginStore';
import { UserRole } from '../../store/user/UserCommon';
import Loading from '../support/Loading';
import { StandardSummarySize } from '../support/Summaries';
import { View } from '../support/Views';

// From SkyCert v1

type AllAppointmentsSummaryProps = {
		summary: UserSummaryStore.AppointmentsState;
		login: LoginStore.LoginState;
	}
	& typeof UserSummaryStore.userSummaryActionCreators
	& RouteComponentProps<{}>;

class AllAppointmentsSummary extends React.Component<AllAppointmentsSummaryProps, {}> {
	// eslint-disable-next-line @typescript-eslint/no-useless-constructor
	constructor(props: any) {
		super(props);
	}

	componentDidMount() {
		this.props.requestBookingsSummary();
	}

	public render() {
		return <StandardSummarySize>
			<View header='Appointments' onClickHeader={this.goToAppointments}>
				<CardBody>
					{this.renderBody()}
					<p><Link to={'/booking/practice/' + (this.props.login.loggedIn!.role === UserRole.Administrator ? 'all' : 'myPractice')}>Medical appointments</Link></p>
				</CardBody>
			</View>
		</StandardSummarySize>;
	}

	goToAppointments = () => {
		this.props.history.push('/booking/practice/' + (this.props.login.loggedIn!.role === UserRole.Administrator ? 'all' : 'myPractice'));
	}

	private renderBody() {
		if (!this.props.summary
			|| this.props.summary.status === SummaryLoadStatus2.Idle) {
			return null;	// This should only ever occur briefly.
		} else if (this.props.summary.status === SummaryLoadStatus2.Loading) {
				return <Loading />;
		} else if (this.props.summary.status === SummaryLoadStatus2.Loaded) {
			return <p>
				{this.props.summary.numberOfAppointments} appointments.<br />
				{this.props.summary.numberOfBookings} bookings.<br />
				{this.props.summary.numberOfBookingsTomorrow} bookings tomorrow.<br />
				{this.props.summary.numberOfBookingsToday} bookings today.<br />
			</p>;
		} else if (this.props.summary.status === SummaryLoadStatus2.Failure) {
			return <p className='text-danger'>Unable to load.</p>;
		}
	}
}

export default connect(
	(state: ApplicationState) => {
		return {
			summary: state.summary.appointments,
			login: state.user.login,
		};
	},
	UserSummaryStore.userSummaryActionCreators
)(AllAppointmentsSummary) /*as typeof AllAppointmentsSummary*/;
