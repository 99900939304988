export const monthNames = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December',];
const isoDateFormat = /^\s*(\d{4})-(\d{2})-(\d{2})\s*$/;
const dmyDateFormat = /^\s*(\d+)[/-](\d+)[/-](\d+)\s*$/;
const isoDateTimeFormat = /^\s*(\d{4})-(\d{2})-(\d{2})(?:[Tt ](.*))?$/;
const dmyDateTimeFormat = /^\s*(\d+)[/-](\d+)[/-](\d+)(?:[Tt ](.*))?$/;
export const timeFormatString = '(?:0?\\d|1\\d|2[0123]):[0-5]\\d';
export const timeRangeFormatString = ' *(' + timeFormatString + ') *(?:- *(' + timeFormatString + ') *)?- *(' + timeFormatString + ') *';
export const timeRangeFormat = new RegExp('^' + timeRangeFormatString + '$');
const OneDay = 1000 * 60 * 60 * 24;

export type IntervalType = 'years' | 'months' | 'days';

export const parseDate = (s: string): Date | null => {
	return implementParseDateTime(s, isoDateFormat, dmyDateFormat);
}

export const parseDateTime = (s: string): Date | null => {
	return implementParseDateTime(s, isoDateTimeFormat, dmyDateTimeFormat);
}

const implementParseDateTime = (s: string, isoFormat: RegExp, dmyFormat: RegExp): Date | null => {
	if (!s) return null;

	let day: number = 0;
	let month: number = 0;
	let year: number = 0;
	var matches = null;
	if ((matches = isoFormat.exec(s)) !== null) {
		day = parseInt(matches[3]);
		month = parseInt(matches[2]);
		year = parseInt(matches[1]);
	} else if ((matches = dmyFormat.exec(s)) !== null) {
		day = parseInt(matches[1]);
		month = parseInt(matches[2]);
		year = parseInt(matches[3]);
		if (year < 100) {
			const centuryBreak: number = new Date().getFullYear() + 5 - 2000;
			if (year > centuryBreak) year += 1900;
			else year += 2000;
		}
	}
	if (matches !== null) {
		if (matches.length === 4) {	// Date format
			return new Date(year, month, day);
		} else {	// matches.length === 5 Date and time format
			s = year + '-';
			if (month < 10) s += '0';
			s += month + '-';
			if (day < 10) s += '0';
			s += day + ' ' + matches[4];
		}
	}
	const t: number = Date.parse(s);
	if (isNaN(t)) return null;
	else return new Date(t);
}

export const getDateText = (date: Date): string => (
	date ?	`${date.getDate()} ${monthNames[date.getMonth()].substr(0, 3)} ${date.getFullYear()}` : ''
);

export const extractStartAndFinishTime = (timeRange: string) => {
	var m = timeRange.match(timeRangeFormat);
	if (m) {
		let startTime: string = m[1];
		if (startTime.length === 4) startTime = '0' + startTime;
		let nurseFinishTime: string | undefined = m[2];
		if (!!nurseFinishTime && nurseFinishTime.length === 4) nurseFinishTime = '0' + nurseFinishTime;
		let finishTime: string = m[3];
		if (finishTime.length === 4) finishTime = '0' + finishTime;
		return {
			startTime,
			nurseFinishTime,
			finishTime,
		};
	} else {
		return null;
	}
}

export const getDateTimeText = (date: Date): string => (
	date ? getDateText(date) + ' ' + getTimeText24Hm(date) : ''
);

export const getTimeText24Hms = (time: Date) => (
	`${getTwoDigitNumber(time.getHours())}:${getTwoDigitNumber(time.getMinutes())}:${getTwoDigitNumber(time.getSeconds())}`
);

export const getTimeText12Hms = (time: Date) => {
	const hours24 = time.getHours();
	const hours12 = hours24 > 12 ? hours24 - 12 : (hours24 === 0 ? 12 : hours24);
	const amPm = hours24 > 12 ? 'pm' : 'am';
	return `${getTwoDigitNumber(hours12)}:${getTwoDigitNumber(time.getMinutes())}:${getTwoDigitNumber(time.getSeconds())}${amPm}`
}

export const getTimeText24Hm = (time: Date) => (
	`${getTwoDigitNumber(time.getHours())}:${getTwoDigitNumber(time.getMinutes())}`
);

export const getTimeText12Hm = (time: Date) => {
	const hours24 = time.getHours();
	const hours12 = hours24 > 12 ? hours24 - 12 : (hours24 === 0 ? 12 : hours24);
	const amPm = hours24 >= 12 ? 'pm' : 'am';
	return `${hours12}:${getTwoDigitNumber(time.getMinutes())}${amPm}`
}

export const getTwoDigitNumber = (value: number) => {
	if (value === 0)
		return '00';
	else if (value < 10)
		return '0' + value;
	else return value;
}

export const getDateFromPeriod = (startFrom: Date, periodName: IntervalType, period: number) => {
	if (isNaN(period))
		return null;
	switch (periodName) {
		case 'days':
			return getDateOnly(addDays(startFrom, period));
		case 'months':
			return getDateOnly(addMonths(startFrom, period));
		case 'years':
			return getDateOnly(addYears(startFrom, period));
		default:
			console.error(`The period ${periodName} is not supported.`);
			return null;
	}
};

export const addDays = (fromDate: Date, days: number) =>
	new Date(fromDate.getTime() + (days * OneDay));

export const addMonths = (fromDate: Date, months: number) => {
	// TODO @@@ Handle fractional months?
	let month = fromDate.getMonth() + months;
	let year = fromDate.getFullYear();
	while (month >= 12) {
		month -= 12;
		year++;
	}
	return getDateInMonth(new Date(year, month, fromDate.getDate()), month);
}

export const addYears = (fromDate: Date, years: number) => {
	const fromYear = fromDate.getFullYear();
	const fromMonth = fromDate.getMonth();
	const fromDay = fromDate.getDate();
	const wholeYears = Math.trunc(years);
	const partYear = years - wholeYears;
	const year = fromYear + wholeYears;
	const date1 = getDateInMonth(new Date(year, fromMonth, fromDay), fromMonth);
	const date2 = getDateInMonth(new Date(year + 1, fromMonth, fromDay), fromMonth);
	const date1time = date1.getTime();
	return new Date(date1time + ((date2.getTime() - date1time) * partYear));
}

export const getPeriodFromDate = (startFrom: Date, periodName: IntervalType, date: Date | null) => {
	if (!date)
		return '';
	switch (periodName) {
		case 'days':
			return Math.round(getDaysBetween(startFrom, date)).toString();
		case 'months':
			return Math.round(getMonthsBetween(startFrom, date)).toString();
		case 'years':
			return (Math.round(getYearsBetween(startFrom, date) * 100) / 100).toString();
		default:
			console.error(`The period ${periodName} is not supported.`);
			return '';
	}
}

export const getDaysBetween = (fromDate: Date, toDate: Date) =>
	(toDate.getTime() - fromDate.getTime()) / OneDay;

export const getMonthsBetween = (fromDate: Date, toDate: Date) => {
	const fromYear = fromDate.getFullYear();
	const fromMonth = fromDate.getMonth();
	const fromDay = fromDate.getDate();
	const toYear = toDate.getFullYear();
	const toMonth = toDate.getMonth();
	const toDay = toDate.getDate();
	let months = (toYear - fromYear) * 12 + toMonth - fromMonth;
	let date1: Date;
	let date2: Date;
	if (fromDay <= toDay) {
		date1 = getDateInMonth(new Date(toYear, toMonth, fromDay), toMonth);
		const useMonth = toMonth === 11 ? 1 : toMonth + 1;
		date2 = getDateInMonth(toMonth === 11 ? new Date(toYear + 1, useMonth, toDay) : new Date(toYear, useMonth, fromDay), useMonth);
	} else {
		months--;
		const useMonth = toMonth === 0 ? 11 : toMonth - 1;
		date1 = getDateInMonth(toMonth === 0 ? new Date(toYear - 1, 11, fromDay) : new Date(toYear, toMonth - 1, fromDay), useMonth);
		date2 = getDateInMonth(new Date(toYear, toMonth, fromDay), toMonth);
	}
	const date1time = date1.getTime();
	months += (toDate.getTime() - date1time) / (date2.getTime() - date1time);
	return months;
};

export const getYearsBetween = (fromDate: Date, toDate: Date) => {
	const fromYear = fromDate.getFullYear();
	const fromMonth = fromDate.getMonth();
	const fromDay = fromDate.getDate();
	const toYear = toDate.getFullYear();
	const toMonth = toDate.getMonth();
	const toDay = toDate.getDate();
	let years = toYear - fromYear;
	let date1: Date;
	let date2: Date;
	if (fromMonth < toMonth || (fromMonth === toMonth && fromDay <= toDay)) {
		date1 = getDateInMonth(new Date(toYear, fromMonth, fromDay), fromMonth);
		date2 = getDateInMonth(new Date(toYear + 1, fromMonth, fromDay), fromMonth);
	} else {
		years--;
		date1 = getDateInMonth(new Date(toYear - 1, fromMonth, fromDay), fromMonth);
		date2 = getDateInMonth(new Date(toYear, fromMonth, fromDay), fromMonth);
	}
	const date1time = date1.getTime();
	years += (toDate.getTime() - date1time) / (date2.getTime() - date1time);
	return years;
};

export const getDateInMonth = (date: Date, month: number) =>
	date.getMonth() === month ? date : new Date(date.getTime() - (date.getDate() * OneDay));

export const getDateOnly = (date: Date) =>
	new Date(date.getFullYear(), date.getMonth(), date.getDate());
