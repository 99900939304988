import { Action, Reducer } from 'redux';
import { AppThunkAction } from '..';
import { store } from '../..';
import { MedicalReminderCategory } from '../medical/MedicalCommon';
import { deleteJson, getJson, handleWithActionAndErrorToast, handleWithErrorToast, postJson, putJson } from '../myfetch';
import { dispatchErrorToast, dispatchToast } from '../notification/NotificationStore';
import { LoadStatus2 } from '../StoreCommon';
import { reminderListActionCreators } from './ReminderListStore';
import { Administrator, FlyingOrganisationStaff, MedicalExaminer, Nurse, OrganisationPilot, OverheadJoinAirfield, Pilot, RegulatoryAuthorityAgent, Specialist, SpecialistStaff, User, UserRole } from './UserCommon';
import { userListActionCreators } from './UserListStore';

// From SkyCert v1

// STATE **********************************************************************

export interface SelectedUserState {
	status: SelectedUserStatus;
	newUserType?: string;	// 'User' (admin) or 'Pilot' (self-signup)
	newUserRole?: string;
	userId?: string;	// TODO Remove this temporary fix - needs architecture v2
	user?: User;
	isUserChanged?: UserProfileChanged;
	checklistStatus: LoadStatus2;
	checklist?: Checklist;
	reminderCategoriesStatus: LoadStatus2;
	reminderCategories?: MedicalReminderCategory;

	hasSelectedOrganisationPilotChanges: boolean;
	isSavingSelectedOrganisationPilot: boolean;
	selectedOrganisationPilot?: OrganisationPilot;
}

export enum UserProfileChanged {
	None = 0,
	User = 1,
	Administrator = 2,
	Flying_Organisation_Staff_Member = 4,
	Medical_Examiner = 8,
	Nurse = 16,
	Pilot = 32,
	Regulatory_Authority_Agent = 64,
	Specialist = 128,
	Specialist_Staff = 256,
}

export enum SelectedUserStatus {
	Loading,
	Loaded,
	Failure,
	Saving,
	NewUser,
	Created,
	Deleting,
	Deleted,
}

export interface Checklist {
	checklistRequired?: number;
	checklistDone?: number;
	checklistSeen?: number;
}

// ACTIONS ********************************************************************

interface PrepareNewUserAction { type: 'PREPARE_NEW_USER', newUserType: string }
interface SetNewUserRoleAction { type: 'SET_NEW_USER_ROLE', newUserRole: string }
interface RequestNewUserAction { type: 'REQUEST_NEW_USER' }
interface ReceiveNewUserAction { type: 'RECEIVE_NEW_USER'; isOk?: boolean; }

interface RequestDeleteUserAction { type: 'REQUEST_DELETE_USER'; }
interface ReceiveDeleteUserAction { type: 'RECEIVE_DELETE_USER'; isOk?: boolean; }

interface RequestLoadUserAction { type: 'REQUEST_LOAD_USER', userId: string }
interface ReceiveLoadUserSuccessAction { type: 'RECEIVE_LOAD_USER_SUCCESS', user: User }
interface ReceiveLoadUserFailureAction { type: 'RECEIVE_LOAD_USER_FAILURE' }

interface UpdateSelectedUserFieldAction { type: 'UPDATE_SELECTED_USER_FIELD'; name: string; value: any; isRequired: boolean; }
interface UpdateSelectedAdministratorFieldAction { type: 'UPDATE_SELECTED_ADMINISTRATOR_FIELD'; role: UserRole; name: string; value: any; isRequired: boolean; }
interface UpdateSelectedFlyingOrganisationStaffFieldAction { type: 'UPDATE_SELECTED_FLYING_ORGANISATION_STAFF_FIELD'; role: UserRole; name: string; value: any; isRequired: boolean; }
interface UpdateSelectedMedicalExaminerFieldAction { type: 'UPDATE_SELECTED_MEDICAL_EXAMINER_FIELD'; role: UserRole; name: string; value: any; isRequired: boolean; }
interface UpdateSelectedNurseFieldAction { type: 'UPDATE_SELECTED_NURSE_FIELD'; role: UserRole; name: string; value: any; isRequired: boolean; }
interface UpdateSelectedPilotFieldAction { type: 'UPDATE_SELECTED_PILOT_FIELD'; role: UserRole; name: string; value: any; isRequired: boolean; }
interface UpdateSelectedRegulatoryAuthorityAgentFieldAction { type: 'UPDATE_SELECTED_REGULATORY_AUTHORITY_AGENT_FIELD'; role: UserRole; name: string; value: any; isRequired: boolean; }
interface UpdateSelectedSpecialistFieldAction { type: 'UPDATE_SELECTED_SPECIALIST_FIELD'; role: UserRole; name: string; value: any; isRequired: boolean; }
interface UpdateSelectedSpecialistStaffFieldAction { type: 'UPDATE_SELECTED_SPECIALIST_STAFF_FIELD'; role: UserRole; name: string; value: any; isRequired: boolean; }

interface AddOverheadJoinAirfieldAction { type: 'ADD_OVERHEAD_JOIN_AIRFIELD'; airfield: OverheadJoinAirfield; }
interface RemoveOverheadJoinAirfieldAction { type: 'REMOVE_OVERHEAD_JOIN_AIRFIELD'; airfieldId: number; }

interface RequestSetUserStatusAction { type: 'REQUEST_SET_USER_STATUS'; userId: string; status: string }
interface ReceiveSetUserStatusSuccessAction { type: 'RECEIVE_SET_USER_STATUS_SUCCESS', status: string }
interface ReceiveSetUserStatusFailureAction { type: 'RECEIVE_SET_USER_STATUS_FAILURE' }

interface RequestAddRoleAction { type: 'REQUEST_ADD_ROLE'; userId: string; roleName: string }
interface ReceiveAddRoleSuccessAction { type: 'RECEIVE_ADD_ROLE_SUCCESS'; roleFieldName: string }
interface ReceiveAddRoleFailureAction { type: 'RECEIVE_ADD_ROLE_FAILURE' }

// The following are also used for saving the individual roles.
interface RequestSaveSelectedUserAction { type: 'REQUEST_SAVE_SELECTED_USER' }
interface ReceiveSaveSelectedUserAction { type: 'RECEIVE_SAVE_SELECTED_USER'; isOk?: boolean; userProfileChanged?: UserProfileChanged; }

interface SelectOrganisationPilotAction { type: 'SELECT_ORGANISATION_PILOT'; organisationPilotId: number; }
interface ClearSelectedOrganisationPilotAction { type: 'CLEAR_SELECTED_ORGANISATION_PILOT'; }
interface PrepareNewOrganisationPilotAction { type: 'PREPARE_NEW_ORGANISATION_PILOT'; }

interface UpdateSelectedOrganisationPilotFieldAction { type: 'UPDATE_SELECTED_ORGANISATION_PILOT_FIELD'; name: string; value: any; isRequired: boolean; }

interface RequestSaveOrganisationPilotAction { type: 'REQUEST_SAVE_ORGANISATION_PILOT'; }
interface ReceiveSaveOrganisationPilotAction { type: 'RECEIVE_SAVE_ORGANISATION_PILOT'; organisationPilots?: OrganisationPilot[]; }

interface RequestLoadSelectedPilotsMedicalChecklistAction { type: 'REQUEST_LOAD_SELECTED_PILOTS_MEDICAL_CHECKLIST'; }
interface ReceiveLoadSelectedPilotsMedicalChecklistAction { type: 'RECEIVE_LOAD_SELECTED_PILOTS_MEDICAL_CHECKLIST'; checklist: Checklist; }

interface RequestLoadSelectedPilotsMedicalReminderCategoriesAction { type: 'REQUEST_LOAD_SELECTED_PILOTS_MEDICAL_REMINDER_CATEGORIES'; }
interface ReceiveLoadSelectedPilotsMedicalReminderCategoriesAction { type: 'RECEIVE_LOAD_SELECTED_PILOTS_MEDICAL_REMINDER_CATEGORIES'; reminderCategories: MedicalReminderCategory; }

interface ReceiveLoadSelectedPilotsOrganisationPilotAction { type: 'RECEIVE_LOAD_SELECTED_PILOTS_ORGANISATION_PILOT'; organisationPilot?: OrganisationPilot; }

type KnownAction = PrepareNewUserAction | SetNewUserRoleAction | RequestNewUserAction | ReceiveNewUserAction
	| RequestDeleteUserAction | ReceiveDeleteUserAction
	| RequestLoadUserAction | ReceiveLoadUserSuccessAction | ReceiveLoadUserFailureAction
	| UpdateSelectedUserFieldAction | UpdateSelectedAdministratorFieldAction | UpdateSelectedPilotFieldAction
	| UpdateSelectedMedicalExaminerFieldAction | UpdateSelectedNurseFieldAction | UpdateSelectedRegulatoryAuthorityAgentFieldAction
	| UpdateSelectedSpecialistFieldAction | UpdateSelectedSpecialistStaffFieldAction
	| UpdateSelectedFlyingOrganisationStaffFieldAction
	| RequestSetUserStatusAction | ReceiveSetUserStatusSuccessAction | ReceiveSetUserStatusFailureAction
	| RequestAddRoleAction | ReceiveAddRoleSuccessAction | ReceiveAddRoleFailureAction
	| RequestSaveSelectedUserAction | ReceiveSaveSelectedUserAction
	| SelectOrganisationPilotAction | ClearSelectedOrganisationPilotAction | PrepareNewOrganisationPilotAction
	| UpdateSelectedOrganisationPilotFieldAction
	| AddOverheadJoinAirfieldAction | RemoveOverheadJoinAirfieldAction
	| RequestSaveOrganisationPilotAction | ReceiveSaveOrganisationPilotAction
	| RequestLoadSelectedPilotsMedicalChecklistAction | ReceiveLoadSelectedPilotsMedicalChecklistAction
	| RequestLoadSelectedPilotsMedicalReminderCategoriesAction | ReceiveLoadSelectedPilotsMedicalReminderCategoriesAction
	| ReceiveLoadSelectedPilotsOrganisationPilotAction
	;

// ACTION INVOKERS ************************************************************

export const selectedUserActionInvokers = {
	updateSelectedSpecialistField: (name: string, value: any, isRequired: boolean) => store.dispatch({ type: 'UPDATE_SELECTED_SPECIALIST_FIELD', name, value, isRequired } as KnownAction),
	updateSelectedSpecialistStaffField: (name: string, value: any, isRequired: boolean) => store.dispatch({ type: 'UPDATE_SELECTED_SPECIALIST_STAFF_FIELD', name, value, isRequired } as KnownAction),

	requestSaveSelectedSpecialist: async (userId: string, specialist: Specialist) => {
		store.dispatch({ type: 'REQUEST_SAVE_SELECTED_USER' } as KnownAction);
		try {
			const response = await putJson(`api/users/${userId}/specialist`, specialist);
			store.dispatch({ type: 'RECEIVE_SAVE_SELECTED_USER', isOk: true, userProfileChanged: UserProfileChanged.Specialist, } as KnownAction);
			store.dispatch(userListActionCreators.invalidateCurrentUsersPage());
			dispatchToast(true, 'success', 'Specialist', response.message);
		} catch (error) {
			store.dispatch({ type: 'RECEIVE_SAVE_SELECTED_USER', } as KnownAction);
			dispatchErrorToast(true, 'Specialist', error);
        }
	},

	requestSaveSelectedSpecialistStaff: async (userId: string, specialistStaff: SpecialistStaff) => {
		store.dispatch({ type: 'REQUEST_SAVE_SELECTED_USER' } as KnownAction);
		try {
			const response = await putJson(`api/users/${userId}/specialistStaff`, specialistStaff);
			store.dispatch({ type: 'RECEIVE_SAVE_SELECTED_USER', isOk: true, userProfileChanged: UserProfileChanged.Specialist_Staff, } as KnownAction);
			store.dispatch(userListActionCreators.invalidateCurrentUsersPage());
			dispatchToast(true, 'success', 'Specialist Staff', response.message);
		} catch (error) {
			store.dispatch({ type: 'RECEIVE_SAVE_SELECTED_USER', } as KnownAction);
			dispatchErrorToast(true, 'Specialist Staff', error);
		}
	},
}


// ACTION CREATORS ************************************************************

// TODO userId now part of state is unecessarily passed to many of these action creators
export const selectedUserActionCreators = {
	prepareNewUser: (newUserType: string) => ({ type: 'PREPARE_NEW_USER', newUserType } as KnownAction),

	setNewUserRole: (newUserRole: string) => ({ type: 'SET_NEW_USER_ROLE', newUserRole, } as KnownAction),

	requestNewPilot: (user: User, password: string): AppThunkAction<Action> => (dispatch, getState) => {
		postJson('api/account/signuppilot', { ...user, role: UserRole.Pilot, password })
			.then(response => {
				dispatch({ type: 'RECEIVE_NEW_USER', isOk: true } as KnownAction);
				dispatchToast(true, 'success', 'Sign Up', response.message);
			})
			.catch(handleWithActionAndErrorToast('Sign Up', 'RECEIVE_NEW_USER'));
		dispatch({ type: 'REQUEST_NEW_USER' } as KnownAction);
	},

	requestNewUser: (role: UserRole, user: User, password: string): AppThunkAction<Action> => (dispatch, getState) => {
		let newUserDetails = { ...user, role, password };
		postJson('api/account/newuser', newUserDetails)
			.then(response => {
				// TODO @@@ Are both these dispatches necessary?
				dispatch({ type: 'RECEIVE_NEW_USER', isOk: true } as KnownAction);
				dispatch({ type: 'PREPARE_NEW_USER', } as KnownAction);
				dispatch(userListActionCreators.invalidateCurrentUsersPage());
				dispatchToast(true, 'success', 'New User', response.message);
			})
			.catch(handleWithActionAndErrorToast('New User', 'RECEIVE_NEW_USER'));
		dispatch({ type: 'REQUEST_NEW_USER' } as KnownAction);
	},

	requestDeleteUser: (userId: string): AppThunkAction<Action> => (dispatch, getState) => {
		deleteJson(`api/users/${userId}`)
			.then(response => {
				dispatch({ type: 'RECEIVE_DELETE_USER', isOk: true, } as KnownAction);
				dispatch(userListActionCreators.invalidateCurrentUsersPage());
			})
			.catch(handleWithActionAndErrorToast('Delete User', 'RECEIVE_DELETE_USER'));
		dispatch({ type: 'REQUEST_DELETE_USER', } as KnownAction);
	},

	// Set userId to 'me' to retrive currently logged in user.
	requestLoadUser: (userId: string): AppThunkAction<Action> => (dispatch, getState) => {
		// TODO Fix this with architecture v2
		getJson(`api/users/${userId}`)
			.then(response => {
				dispatch({ type: 'RECEIVE_LOAD_USER_SUCCESS', user: response } as KnownAction);
			})
			.catch(handleWithActionAndErrorToast('User', 'RECEIVE_LOAD_USER_FAILURE'));
		dispatch({ type: 'REQUEST_LOAD_USER', userId } as KnownAction);
	},

	updateSelectedUserField: (name: string, value: any, isRequired: boolean) => ({ type: 'UPDATE_SELECTED_USER_FIELD', name, value, isRequired } as KnownAction),
	updateSelectedAdministratorField: (name: string, value: any, isRequired: boolean) => ({ type: 'UPDATE_SELECTED_ADMINISTRATOR_FIELD', name, value, isRequired } as KnownAction),
	updateSelectedFlyingOrganisationStaffField: (name: string, value: any, isRequired: boolean) => ({ type: 'UPDATE_SELECTED_FLYING_ORGANISATION_STAFF_FIELD', name, value, isRequired } as KnownAction),
	updateSelectedMedicalExaminerField: (name: string, value: any, isRequired: boolean) => ({ type: 'UPDATE_SELECTED_MEDICAL_EXAMINER_FIELD', name, value, isRequired } as KnownAction),
	updateSelectedNurseField: (name: string, value: any, isRequired: boolean) => ({ type: 'UPDATE_SELECTED_NURSE_FIELD', name, value, isRequired } as KnownAction),
	updateSelectedPilotField: (name: string, value: any, isRequired: boolean) => ({ type: 'UPDATE_SELECTED_PILOT_FIELD', name, value, isRequired } as KnownAction),
	updateSelectedRegulatoryAuthorityAgentField: (name: string, value: any, isRequired: boolean) => ({ type: 'UPDATE_SELECTED_REGULATORY_AUTHORITY_AGENT_FIELD', name, value, isRequired } as KnownAction),

	addOverheadJoinAirfield: (airfield: OverheadJoinAirfield) => ({ type: 'ADD_OVERHEAD_JOIN_AIRFIELD', airfield, }),
	removeOverheadJoinAirfield: (airfieldId: number) => ({ type: 'REMOVE_OVERHEAD_JOIN_AIRFIELD', airfieldId, }),

	requestSetUserStatus: (userId: string, status: string): AppThunkAction<Action> => (dispatch, getState) => {
		putJson(`api/users/${userId}/status`, { value: status, })
			.then(response => {
				dispatch({ type: 'RECEIVE_SET_USER_STATUS_SUCCESS', status: response.status, } as KnownAction);
				dispatch(userListActionCreators.invalidateCurrentUsersPage());
				dispatchToast(true, 'success', 'User Status', response.message);
			})
			.catch(handleWithActionAndErrorToast('User Status', 'RECEIVE_SET_USER_STATUS_FAILURE'));
		dispatch({ type: 'REQUEST_SET_USER_STATUS' } as KnownAction);
	},

	requestAddUserRole: (userId: string, role: UserRole, roleFieldName: string): AppThunkAction<Action> => (dispatch, getState) => {
		postJson(`api/users/${userId}/${role}`, { })
			.then(response => {
				dispatch({ type: 'RECEIVE_ADD_ROLE_SUCCESS', roleFieldName } as KnownAction);
				dispatch(userListActionCreators.invalidateCurrentUsersPage());
				dispatchToast(true, 'success', 'User Role', response.message);
			})
			.catch(handleWithActionAndErrorToast('User Role', 'RECEIVE_ADD_ROLE_FAILURE'));
		dispatch({ type: 'REQUEST_ADD_ROLE' } as KnownAction);
	},

	requestSaveSelectedUser: (user: User, password: string | undefined): AppThunkAction<Action> => (dispatch, getState) => {
		putJson(`api/users/${user.userId}`, { ...user, password, })
			.then(response => {
				dispatch({ type: 'RECEIVE_SAVE_SELECTED_USER', isOk: true, userProfileChanged: UserProfileChanged.User, } as KnownAction);
				dispatch(userListActionCreators.invalidateCurrentUsersPage());
				dispatchToast(true, 'success', 'User', response.message);
			})
			.catch(handleWithActionAndErrorToast('User', 'RECEIVE_SAVE_SELECTED_USER'));
		dispatch({ type: 'REQUEST_SAVE_SELECTED_USER' } as KnownAction);
	},

	requestSaveSelectedAdministrator: (userId: string, administrator: Administrator): AppThunkAction<Action> => (dispatch, getState) => {
		putJson(`api/users/${userId}/administrator`, administrator)
			.then(response => {
				dispatch({ type: 'RECEIVE_SAVE_SELECTED_USER', isOk: true, userProfileChanged: UserProfileChanged.Administrator, } as KnownAction);
				dispatch(userListActionCreators.invalidateCurrentUsersPage());
				dispatchToast(true, 'success', 'Administrator', response.message);
			})
			.catch(handleWithActionAndErrorToast('Administrator', 'RECEIVE_SAVE_SELECTED_USER'));
		dispatch({ type: 'REQUEST_SAVE_SELECTED_USER' } as KnownAction);
	},

	requestSaveSelectedFlyingOrganisationStaff: (userId: string, flyingOrganisationStaff: FlyingOrganisationStaff): AppThunkAction<Action> => (dispatch, getState) => {
		putJson(`api/users/${userId}/flyingorganisationstaff`, flyingOrganisationStaff)
			.then(response => {
				dispatch({ type: 'RECEIVE_SAVE_SELECTED_USER', isOk: true, userProfileChanged: UserProfileChanged.Flying_Organisation_Staff_Member, } as KnownAction);
				dispatch(userListActionCreators.invalidateCurrentUsersPage());
				dispatch(reminderListActionCreators.invalidateCurrentRemindersPage());
				dispatchToast(true, 'success', 'Flying Organisation Staff', response.message);
			})
			.catch(handleWithActionAndErrorToast('Staff Member', 'RECEIVE_SAVE_SELECTED_USER'));
		dispatch({ type: 'REQUEST_SAVE_SELECTED_USER' } as KnownAction);
	},

	requestSaveSelectedPilot: (userId: string, pilot: Pilot): AppThunkAction<Action> => (dispatch, getState) => {
		//myput(`api/users/${userId}/pilot`, { ...pilot, dob: pilot.dateOfBirth, })
		putJson(`api/users/${userId}/pilot`, pilot)
			.then(response => {
				dispatch({ type: 'RECEIVE_SAVE_SELECTED_USER', isOk: true, userProfileChanged: UserProfileChanged.Pilot, } as KnownAction);
				dispatch(userListActionCreators.invalidateCurrentUsersPage());
				dispatch(reminderListActionCreators.invalidateCurrentRemindersPage());
				dispatchToast(true, 'success', 'Pilot', response.message);
			})
			.catch(handleWithActionAndErrorToast('Pilot', 'RECEIVE_SAVE_SELECTED_USER'));
		dispatch({ type: 'REQUEST_SAVE_SELECTED_USER' } as KnownAction);
	},

	requestSaveSelectedMedicalExaminer: (userId: string, medicalExaminer: MedicalExaminer): AppThunkAction<Action> => (dispatch, getState) => {
		putJson(`api/users/${userId}/medicalexaminer`, medicalExaminer)
			.then(response => {
				dispatch({ type: 'RECEIVE_SAVE_SELECTED_USER', isOk: true, userProfileChanged: UserProfileChanged.Medical_Examiner, } as KnownAction);
				dispatch(userListActionCreators.invalidateCurrentUsersPage());
				dispatchToast(true, 'success', 'Medical Examiner', response.message);
			})
			.catch(handleWithActionAndErrorToast('Medical Examiner', 'RECEIVE_SAVE_SELECTED_USER'));
		dispatch({ type: 'REQUEST_SAVE_SELECTED_USER' } as KnownAction);
	},

	requestSaveSelectedNurse: (userId: string, nurse: Nurse): AppThunkAction<Action> => (dispatch, getState) => {
		putJson(`api/users/${userId}/nurse`, nurse)
			.then(response => {
				dispatch({ type: 'RECEIVE_SAVE_SELECTED_USER', isOk: true, userProfileChanged: UserProfileChanged.Nurse, } as KnownAction);
				dispatch(userListActionCreators.invalidateCurrentUsersPage());
				dispatchToast(true, 'success', 'Nurse', response.message);
			})
			.catch(handleWithActionAndErrorToast('Nurse', 'RECEIVE_SAVE_SELECTED_USER'));
		dispatch({ type: 'REQUEST_SAVE_SELECTED_USER' } as KnownAction);
	},

	requestSaveSelectedRegulatoryAuthorityAgent: (userId: string, regulatoryAuthorityAgent: RegulatoryAuthorityAgent): AppThunkAction<Action> => (dispatch, getState) => {
		putJson(`api/users/${userId}/regulatoryauthorityagent`, regulatoryAuthorityAgent)
			.then(response => {
				dispatch({ type: 'RECEIVE_SAVE_SELECTED_USER', isOk: true, userProfileChanged: UserProfileChanged.Regulatory_Authority_Agent, } as KnownAction);
				dispatch(userListActionCreators.invalidateCurrentUsersPage());
				dispatchToast(true, 'success', 'Authority Agent', response.message);
			})
			.catch(handleWithActionAndErrorToast('@@@ Regulatory Authority Agent', 'RECEIVE_SAVE_SELECTED_USER'));
		dispatch({ type: 'REQUEST_SAVE_SELECTED_USER' } as KnownAction);
	},

	requestLoadSelectedPilotsMedicalChecklist: (userId: string): AppThunkAction<Action> => (dispatch, getState) => {
		const userState = getState().user;
		if (userState.selectedUser && userState.selectedUser!.userId === userId && userState.selectedUser!.checklistStatus !== LoadStatus2.Idle)
			return;
		getJson(`api/users/${userId}/Medicals/MedicalChecklist`)
			.then(response => {
				dispatch({ type: 'RECEIVE_LOAD_SELECTED_PILOTS_MEDICAL_CHECKLIST', checklist: response.checklist, } as KnownAction);
			})
			.catch(handleWithActionAndErrorToast('Medical Checklist', 'RECEIVE_LOAD_SELECTED_PILOTS_MEDICAL_CHECKLIST'));
		dispatch({ type: 'REQUEST_LOAD_SELECTED_PILOTS_MEDICAL_CHECKLIST' } as KnownAction);
	},

	requestLoadSelectedPilotsReminderCategories: (userId: string): AppThunkAction<Action> => (dispatch, getState) => {
		const userState = getState().user;
		if (userState.selectedUser && userState.selectedUser!.userId === userId && userState.selectedUser!.reminderCategoriesStatus !== LoadStatus2.Idle)
			return;
		getJson(`api/users/${userId}/Medicals/MedicalReminderCategories`)
			.then(response => {
				dispatch({ type: 'RECEIVE_LOAD_SELECTED_PILOTS_MEDICAL_REMINDER_CATEGORIES', reminderCategories: response.reminderCategories, } as KnownAction);
			})
			.catch(handleWithActionAndErrorToast('Reminder Categories', 'RECEIVE_LOAD_SELECTED_PILOTS_MEDICAL_REMINDER_CATEGORIES'));
		dispatch({ type: 'REQUEST_LOAD_SELECTED_PILOTS_MEDICAL_REMINDER_CATEGORIES' } as KnownAction);
	},

	requestLoadSelectedPilotsOrganisationPilot: (userId: string, organisationId: string): AppThunkAction<Action> => (dispatch, getState) => {
		const userState = getState().user;
		if (userState.selectedUser && userState.selectedUser!.userId === userId && userState.selectedUser!.selectedOrganisationPilot)
			return;
		getJson(`api/users/${userId}/Pilot/Organisations?flyingOrganisationId=${organisationId}`)
			.then(response => {
				dispatch({ type: 'RECEIVE_LOAD_SELECTED_PILOTS_ORGANISATION_PILOT', organisationPilot: response.organisationPilot, } as KnownAction);
			})
			.catch(handleWithActionAndErrorToast('Organisation', 'RECEIVE_LOAD_SELECTED_PILOTS_ORGANISATION_PILOT'));
	},

	requestSpecialAction: (b: string): AppThunkAction<Action> => (dispatch, getState) => {
		postJson('api/account/specialaction/me/me?a=1', { b, })
			.then(response => {
				// TODO Use a better approach
				dispatchToast(true, 'success', 'Special Action', response.message);
			})
			.catch(handleWithErrorToast('Special Action'));
		dispatchToast(true, 'primary', 'Special Action', 'Special action is being taken.', 'Please wait.');
	},

	selectOrganisationPilot: (organisationPilotId: number) => ({ type: 'SELECT_ORGANISATION_PILOT', organisationPilotId, } as KnownAction),
	clearSelectedOrganisationPilot: () => ({ type: 'CLEAR_SELECTED_ORGANISATION_PILOT', } as KnownAction),
	prepareNewOrganisationPilot: () => ({ type: 'PREPARE_NEW_ORGANISATION_PILOT', } as KnownAction),

	updateSelectedOrganisationPilotField: (name: string, value: any, isRequired: boolean) => ({ type: 'UPDATE_SELECTED_ORGANISATION_PILOT_FIELD', name, value, isRequired, } as KnownAction),

	saveOrganisationPilot: (): AppThunkAction<Action> => (dispatch, getState) => {
		const state: SelectedUserState = getState().user.selectedUser!;
		const organisationPilot: OrganisationPilot = state.selectedOrganisationPilot!;
		const url: string = organisationPilot.organisationPilotId
			? `api/users/${state.user!.userId}/pilot/organisations/${organisationPilot.organisationPilotId}`
			: `api/users/${state.user!.userId}/pilot/organisations`;
		const method = organisationPilot.organisationPilotId ? putJson : postJson;
		method(url, organisationPilot)
			.then(response => {
				dispatch({ type: 'RECEIVE_SAVE_ORGANISATION_PILOT', organisationPilots: response.organisationPilots, } as KnownAction);
				dispatchToast(true, 'success', 'Organisation', response.message);
			})
			.catch(handleWithActionAndErrorToast('Organisation', 'RECEIVE_SAVE_ORGANISATION_PILOT'));
		dispatch({ type: 'REQUEST_SAVE_ORGANISATION_PILOT', } as KnownAction);
	},
};

// INITIAL STATE **************************************************************

const initialState: SelectedUserState = {
	status: SelectedUserStatus.Loading,
	checklistStatus: LoadStatus2.Idle,
	reminderCategoriesStatus: LoadStatus2.Idle,

	hasSelectedOrganisationPilotChanges: false,
	isSavingSelectedOrganisationPilot: false,
}

const initialUserState: User = {
	status: '',
	userId: '',
	userName: '',
	
	dateSecurityQuestionsRequired: '',
	dateTwoFactorRequired: '',

	firstName: '',
	middleNames: '',
	lastName: '',
	knownAs: '',
	initials: '',
	previousNames: '',
	title: '',
	email: '',
	uploadUserNumber: 0,
	localAddress: '',
	localSuburb: '',
	localCity: '',
	localState: '',
	localPostcode: '',
	localCountry: '',
	featureSet: 0,
	isSystemTester: false,
}

const initialOrganisationPilotState: OrganisationPilot = {
	isActive: true,
	organisationPilotId: 0,
	roles: 0,
	roleNames: '',
	allowOrganisationToManageBookings: false,
	allowOrganisationToViewPilotDocument: false,
	flyingOrganisationId: 0,
	flyingOrganisationName: '',
}

// REDUCERS *******************************************************************

export const reducer: Reducer<SelectedUserState | null> = (state: SelectedUserState | null | undefined, action: KnownAction) => {
	if (state === undefined)
		return null;
	state = state!;	// @@@ I hope this works
	switch (action.type) {
		case 'PREPARE_NEW_USER':
			return {
				...initialState,
				status: SelectedUserStatus.NewUser,
				newUserType: action.newUserType,
				userId: undefined,
				user: initialUserState,
				isUserChanged: UserProfileChanged.None,
			};
		case 'SET_NEW_USER_ROLE':
			return {
				...state,
				newUserRole: action.newUserRole,
			}
		case 'REQUEST_NEW_USER':
			return {
				...state,
				status: SelectedUserStatus.Saving,
			};
		case 'RECEIVE_NEW_USER':
			return {
				...state,
				status: action.isOk ? SelectedUserStatus.Created : SelectedUserStatus.NewUser,
				isUserChanged: action.isOk ? UserProfileChanged.None : state.isUserChanged,
			};
		case 'REQUEST_DELETE_USER':
			return {
				...state,
				status: SelectedUserStatus.Deleting,
			};
		case 'RECEIVE_DELETE_USER':
			return {
				...state,
				status: action.isOk ? SelectedUserStatus.Deleted : SelectedUserStatus.Loaded,
				isUserChanged: action.isOk ? UserProfileChanged.None : state.isUserChanged,
			};
		case 'REQUEST_LOAD_USER':
			return {
				...initialState,
				userId: action.userId,
			};
		case 'RECEIVE_LOAD_USER_SUCCESS':
			return {
				...state,
				status: SelectedUserStatus.Loaded,
				user: action.user,
				isUserChanged: UserProfileChanged.None,
			};
		case 'RECEIVE_LOAD_USER_FAILURE':
			return {
				...state,
				status: SelectedUserStatus.Failure,
				userId: undefined,
				user: undefined,
			};
		case 'UPDATE_SELECTED_USER_FIELD':
			return {
				...state,
				user: {
					...state!.user,
					[action.name]: (action.value === '' && !action.isRequired) ? null : action.value,
				},
				isUserChanged: state.isUserChanged! | UserProfileChanged.User,
			} as SelectedUserState;
		case 'UPDATE_SELECTED_ADMINISTRATOR_FIELD':
			return {
				...state,
				user: {
					...state!.user,
					administrator: {
						...state!.user!.administrator,
						[action.name]: (action.value === '' && !action.isRequired) ? null : action.value,
					},
				},
				isUserChanged: state.isUserChanged! | UserProfileChanged.Administrator,
			} as SelectedUserState;
		case 'UPDATE_SELECTED_FLYING_ORGANISATION_STAFF_FIELD':
			return {
				...state,
				user: {
					...state!.user,
					flyingOrganisationStaff: {
						...state!.user!.flyingOrganisationStaff,
						[action.name]: (action.value === '' && !action.isRequired) ? null : action.value,
					},
				},
				isUserChanged: state.isUserChanged! | UserProfileChanged.Flying_Organisation_Staff_Member,
			} as SelectedUserState;
		case 'UPDATE_SELECTED_PILOT_FIELD':
			return {
				...state,
				user: {
					...state!.user,
					pilot: {
						...state!.user!.pilot,
						[action.name]: (action.value === '' && !action.isRequired) ? null : action.value,
					},
				},
				isUserChanged: state.isUserChanged! | UserProfileChanged.Pilot,
			} as SelectedUserState;
		case 'UPDATE_SELECTED_MEDICAL_EXAMINER_FIELD':
			return {
				...state,
				user: {
					...state!.user,
					medicalExaminer: {
						...state!.user!.medicalExaminer,
						[action.name]: (action.value === '' && !action.isRequired) ? null : action.value,
					},
				},
				isUserChanged: state.isUserChanged! | UserProfileChanged.Medical_Examiner,
			} as SelectedUserState;
		case 'UPDATE_SELECTED_NURSE_FIELD':
			return {
				...state,
				user: {
					...state!.user,
					nurse: {
						...state!.user!.nurse,
						[action.name]: (action.value === '' && !action.isRequired) ? null : action.value,
					},
				},
				isUserChanged: state.isUserChanged! | UserProfileChanged.Nurse,
			} as SelectedUserState;
		case 'UPDATE_SELECTED_REGULATORY_AUTHORITY_AGENT_FIELD':
			return {
				...state,
				user: {
					...state!.user,
					regulatoryAuthorityAgent: {
						...state!.user!.regulatoryAuthorityAgent,
						[action.name]: (action.value === '' && !action.isRequired) ? null : action.value,
					},
				},
				isUserChanged: state.isUserChanged! | UserProfileChanged.Regulatory_Authority_Agent,
			} as SelectedUserState;
		case 'UPDATE_SELECTED_SPECIALIST_FIELD':
			return {
				...state,
				user: {
					...state!.user,
					specialist: {
						...state!.user!.specialist,
						[action.name]: (action.value === '' && !action.isRequired) ? null : action.value,
					},
				},
				isUserChanged: state.isUserChanged! | UserProfileChanged.Specialist,
			} as SelectedUserState;
		case 'UPDATE_SELECTED_SPECIALIST_STAFF_FIELD':
			return {
				...state,
				user: {
					...state!.user,
					specialistStaff: {
						...state!.user!.specialistStaff,
						[action.name]: (action.value === '' && !action.isRequired) ? null : action.value,
					},
				},
				isUserChanged: state.isUserChanged! | UserProfileChanged.Specialist_Staff,
			} as SelectedUserState;
		case 'ADD_OVERHEAD_JOIN_AIRFIELD':
			const overheadJoinAirfields = state!.user!.pilot!.overheadJoinAirfields
				? [...state!.user!.pilot!.overheadJoinAirfields, action.airfield]
				: [action.airfield];
			return {
				...state,
				user: {
					...state!.user!,
					pilot: {
						...state!.user!.pilot!,
						overheadJoinAirfields,
					},
				},
				isUserChanged: state.isUserChanged! | UserProfileChanged.Pilot,
			};
		case 'REMOVE_OVERHEAD_JOIN_AIRFIELD':
			return {
				...state,
				user: {
					...state!.user!,
					pilot: {
						...state!.user!.pilot!,
						overheadJoinAirfields: state!.user!.pilot!.overheadJoinAirfields!.filter(a => a.airfieldId !== action.airfieldId),
					},
				},
				isUserChanged: state.isUserChanged! | UserProfileChanged.Pilot,
			};
		case 'REQUEST_SET_USER_STATUS':
			return {
				...state,
				status: SelectedUserStatus.Saving,
			};
		case 'RECEIVE_SET_USER_STATUS_SUCCESS':
			return {
				...state,
				status: SelectedUserStatus.Loaded,
				user: {
					...state!.user,
					status: action.status,
				},
			} as SelectedUserState;
		case 'RECEIVE_SET_USER_STATUS_FAILURE':
			return {
				...state,
				status: SelectedUserStatus.Failure,
			};
		case 'REQUEST_ADD_ROLE':
			return {
				...state,
				status: SelectedUserStatus.Saving,
			};
		case 'RECEIVE_ADD_ROLE_SUCCESS':
			return {
				...state,
				status: SelectedUserStatus.Loaded,
				user: {
					...state!.user!,
					[action.roleFieldName]: { isActive: true },
				},
			};
		case 'RECEIVE_ADD_ROLE_FAILURE':
			return {
				...state,
				status: SelectedUserStatus.Loaded,
			}
		case 'REQUEST_SAVE_SELECTED_USER':
			return {
				...state,
				status: SelectedUserStatus.Saving,
			} as SelectedUserState;
		case 'RECEIVE_SAVE_SELECTED_USER':
			return {
				...state,
				status: SelectedUserStatus.Loaded,
				isUserChanged: action.isOk ? state.isUserChanged! & ~action.userProfileChanged! : state.isUserChanged,
			} as SelectedUserState;
		case 'REQUEST_LOAD_SELECTED_PILOTS_MEDICAL_CHECKLIST':
			return {
				...state,
				checklistStatus: LoadStatus2.Loading,
				checklist: undefined,
			} as SelectedUserState;
		case 'RECEIVE_LOAD_SELECTED_PILOTS_MEDICAL_CHECKLIST':
			return {
				...state,
				checklistStatus: action.checklist ? LoadStatus2.Loaded : LoadStatus2.Failure,
				checklist: action.checklist,
			} as SelectedUserState;
		case 'REQUEST_LOAD_SELECTED_PILOTS_MEDICAL_REMINDER_CATEGORIES':
			return {
				...state,
				reminderCategoriesStatus: LoadStatus2.Loading,
				reminderCategories: undefined,
			} as SelectedUserState;
		case 'RECEIVE_LOAD_SELECTED_PILOTS_MEDICAL_REMINDER_CATEGORIES':
			return {
				...state,
				reminderCategoriesStatus: action.reminderCategories !== undefined ? LoadStatus2.Loaded : LoadStatus2.Failure,
				reminderCategories: action.reminderCategories,
			} as SelectedUserState;
		case 'RECEIVE_LOAD_SELECTED_PILOTS_ORGANISATION_PILOT':
			return {
				...state,
				selectedOrganisationPilot: action.organisationPilot,
			} as SelectedUserState;
		case 'SELECT_ORGANISATION_PILOT':
			return {
				...state,
				hasSelectedOrganisationPilotChanges: false,
				isSavingSelectedOrganisationPilot: false,
				selectedOrganisationPilot: state!.user!.pilot!.organisationPilots.find(op => op.organisationPilotId === action.organisationPilotId),
			} as SelectedUserState;
		case 'CLEAR_SELECTED_ORGANISATION_PILOT':
			return {
				...state,
				hasSelectedOrganisationPilotChanges: false,
				isSavingSelectedOrganisationPilot: false,
				selectedOrganisationPilot: undefined,
			} as SelectedUserState;
		case 'PREPARE_NEW_ORGANISATION_PILOT':
			return {
				...state,
				hasSelectedOrganisationPilotChanges: true,
				isSavingSelectedOrganisationPilot: false,
				selectedOrganisationPilot: initialOrganisationPilotState,
			} as SelectedUserState;
		case 'UPDATE_SELECTED_ORGANISATION_PILOT_FIELD':
			return {
				...state,
				hasSelectedOrganisationPilotChanges: true,
				selectedOrganisationPilot: {
					...state!.selectedOrganisationPilot!,
					[action.name]: (action.value === '' && !action.isRequired) ? null : action.value,
				},
			} as SelectedUserState;
		case 'REQUEST_SAVE_ORGANISATION_PILOT':
			return {
				...state,
				isSavingSelectedOrganisationPilot: true,
			} as SelectedUserState;
		case 'RECEIVE_SAVE_ORGANISATION_PILOT':
			if (state!.user!.pilot) {
				return {
					...state,
					user: {
						...state!.user!,
						pilot: {
							...state!.user!.pilot!,
							organisationPilots: action.organisationPilots || state!.user!.pilot!.organisationPilots,
						}
					},
					hasSelectedOrganisationPilotChanges: action.organisationPilots ? false : state!.hasSelectedOrganisationPilotChanges,
					isSavingSelectedOrganisationPilot: false,
					selectedOrganisationPilot: action.organisationPilots ? undefined : state!.selectedOrganisationPilot,
				} as SelectedUserState;
			} else {
				return {
					...state,
					hasSelectedOrganisationPilotChanges: action.organisationPilots ? false : state!.hasSelectedOrganisationPilotChanges,
					isSavingSelectedOrganisationPilot: false,
				} as SelectedUserState;
			}
		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		default: const exhaustiveCheck: never = action;
	}
	return state;
}
