import { Action, combineReducers, Reducer } from 'redux';
import * as MandatoryRequirementsStore from './MandatoryRequirementsStore';
import * as MedicalListStore from './MedicalListStore';
import * as MedicalTestsNeededStore from './MedicalTestsNeededStore';
import * as SelectedMedicalStore from './SelectedMedicalStore';

// From SkyCert v1

// STATE **********************************************************************

export interface MedicalState {
	selectedMedical: SelectedMedicalStore.SelectedMedicalState;
	listOfMedicals: MedicalListStore.MedicalListState | null;
	testsNeeded: MedicalTestsNeededStore.MedicalTestsNeededState | null;
	mandatoryRequirements: MandatoryRequirementsStore.MandatoryRequirementsState | null;
}

// REDUCERS *******************************************************************

const combinedReducer: Reducer<MedicalState> = combineReducers<MedicalState>({
	selectedMedical: SelectedMedicalStore.reducer,
	listOfMedicals: MedicalListStore.reducer,
	testsNeeded: MedicalTestsNeededStore.reducer,
	mandatoryRequirements: MandatoryRequirementsStore.reducer,
});

export const reducer: Reducer<MedicalState> = (state: MedicalState | undefined, action: Action) => {
	if (state === undefined || action.type === 'REQUEST_LOGOUT')
		state = undefined;
	return combinedReducer(state, action);
}
