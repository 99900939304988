import * as React from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { CardBody } from 'reactstrap';
import { ApplicationState } from '../../store';
import { SummaryLoadStatus2 } from '../../store/summary/SummaryCommon';
import * as UserSummaryStore from '../../store/summary/UserSummaryStore';
import Loading from '../support/Loading';
import { StandardSummarySize } from '../support/Summaries';
import { View } from '../support/Views';

// From SkyCert v1

type AllInvoicesSummaryProps =
	UserSummaryStore.InvoicesState
	& typeof UserSummaryStore.userSummaryActionCreators
	& RouteComponentProps<{}>;

// Architecture: v2

class AllInvoicesSummary extends React.Component<AllInvoicesSummaryProps, {}> {
	// eslint-disable-next-line @typescript-eslint/no-useless-constructor
	constructor(props: any) {
		super(props);
	}

	componentDidMount() {
		this.props.requestInvoicesSummary();
    }

	public render() {
		return <StandardSummarySize>
			<View color='primary' header='Invoices' onClickHeader={this.goToInvoices}>
				<CardBody>
					{this.renderBody()}
					<Link to='/practice/invoice/list'>Manage</Link>
				</CardBody>
            </View>
		</StandardSummarySize>;
	}

	goToInvoices = () => {
		this.props.history.push('/practice/invoice/list');
	}

	private renderBody() {
		if (this.props.status === SummaryLoadStatus2.Loading) {
			return <Loading />;
		} else if (this.props.status === SummaryLoadStatus2.Loaded) {
			if (!this.props.summary) {
				return <p>NO!</p>;
			} else if (this.props.summary.invoiceFrom) {
				return <p>
					You will be charged for all medicals from {this.props.summary.invoiceFrom}.
				</p>;
			} else if (this.props.summary.numberOfInvoices === 0) {
				return <p>
					You are not being charged currently.
				</p>;
			} else {
				return <p>
					{this.props.summary.numberOfInvoices} invoices.
				</p>;
			}
		} else if (this.props.status === SummaryLoadStatus2.Failure) {
			return <p className='text-danger'>Unable to load.</p>;
		} else {	// SummaryLoadStatus2.Idle, blank
			return null;	// This should only ever occur briefly.
		}
	}
}

export default connect(
	(state: ApplicationState) => state.summary.invoices || {},
	UserSummaryStore.userSummaryActionCreators
)(AllInvoicesSummary) /*as typeof AllInvoicesSummary*/;
