import { Action, combineReducers, Reducer } from 'redux';
import * as FilledFormStore from './FilledFormStore';
import * as HistoryTrendStore from './HistoryTrendStore';
import * as Hl7Store from './Hl7Store';

// From SkyCert v1

// STATE **********************************************************************

export interface FormState {
	filledForms: FilledFormStore.FilledFormsState | null;
	hl7: Hl7Store.Hl7State | null;
	historyTrends: HistoryTrendStore.HistoryTrendState | null;
}

// REDUCERS *******************************************************************

const combinedReducer: Reducer<FormState> = combineReducers<FormState>({
	filledForms: FilledFormStore.reducer,
	hl7: Hl7Store.reducer,
	historyTrends: HistoryTrendStore.reducer,
})

export const reducer: Reducer<FormState> = (state: FormState | undefined, action: Action) => {
	if (action.type === 'REQUEST_LOGOUT')
		state = undefined;
	return combinedReducer(state, action);
}
