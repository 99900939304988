import { Reducer } from 'redux';
import { AppThunkAction } from '..';
import { getJson } from '../myfetch';
import { LoadStatus2 } from '../StoreCommon';

// STATE **********************************************************************

export interface ReportListState {
	status?: LoadStatus2;
	categories?: ReportListCategory[];
}

export interface ReportListCategory {
	reportCategoryId: number;
	name: string;
	definitions: ReportListDefinition[];
}

export interface ReportListDefinition {
	reportDefinitionId: number;
	name: string;
	description: string;
	hasTableQuery: boolean;
	hasGraphQuery: boolean;
}

// ACTIONS ********************************************************************

interface RequestGetReportListAction { type: 'REQUEST_GET_REPORT_LIST'; }
interface ReceiveGetReportListAction { type: 'RECEIVE_GET_REPORT_LIST'; categories?: ReportListCategory[]; }

type KnownAction =
	RequestGetReportListAction | ReceiveGetReportListAction
	;

// ACTION CREATORS ************************************************************

export const reportListActionCreators = {
	getReportList: (): AppThunkAction<KnownAction> => async (dispatch, getState) => {
		dispatch({ type: 'REQUEST_GET_REPORT_LIST', });
		try {
			const response = await getJson('api/reports');
			dispatch({ type: 'RECEIVE_GET_REPORT_LIST', categories: response.categories, });
		} catch (error) {
			dispatch({ type: 'RECEIVE_GET_REPORT_LIST', });
		}
	},
};

// REDUCERS *******************************************************************

export const reportListReducer: Reducer<ReportListState | null> = (state: ReportListState | null | undefined, action: KnownAction) => {
	if (state === undefined)
		return null;
	state = state!;	// @@@ I hope this works
	switch (action.type) {
		case 'REQUEST_GET_REPORT_LIST':
			return {
				status: LoadStatus2.Loading,
			};
		case 'RECEIVE_GET_REPORT_LIST':
			return {
				status: action.categories ? LoadStatus2.Loaded : LoadStatus2.Failure,
				categories: action.categories,
			};
		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		default: const exhaustiveCheck: never = action;
	}
	return state;
}
