import { History } from 'history';
import * as React from 'react';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import * as Configuration from '../../Configuration';
import { Collapse, Container, DropdownItem, DropdownMenu, DropdownToggle, Nav, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink, UncontrolledDropdown } from 'reactstrap';
import { Dispatch } from 'redux';
import { ApplicationState } from '../../store';
import { FeatureSetFlags, loginActionInvokers, LoginState, LoginStatus } from '../../store/user/LoginStore';
import { SecondaryRole, UserRole } from '../../store/user/UserCommon';
import HeaderLogo from '../res/OneKete-logo-header.png';
import './NavMenu.css';

export default () => {
    const login = useSelector(state => (state as ApplicationState).user.login);
    const globalSettings = useSelector(state => (state as ApplicationState).globalSettings);
    const dispatch = useDispatch();
    const [isOpen, setIsOpen] = useState(false);
    const history = useHistory();

    return (
        <header>
            <Navbar className="navbar-expand-sm navbar-toggleable-sm" light>
                <Container>
                    <NavbarBrand tag={Link} to="/">
                        <img src={HeaderLogo} alt='OneKete logo' />
                    </NavbarBrand>
                    {globalSettings.displaySiteName &&
                        <NavbarBrand className='sitename rounded'>
                            {globalSettings.displaySiteName}
                        </NavbarBrand>
                    }
                    <NavbarToggler onClick={() => setIsOpen(!isOpen)} className="mr-2" />
                    <Collapse isOpen={isOpen} navbar>
                        <Nav navbar>
                            {renderMainMenu(login)}
                        </Nav>
                    </Collapse>
                    <Collapse className="d-sm-inline-flex flex-sm-row-reverse" isOpen={isOpen} navbar>
                        <Nav navbar>
                            {renderLogin(login, dispatch, history)}
                        </Nav>
                    </Collapse>
                </Container>
            </Navbar>
        </header>
    );
}

const renderMainMenu = (login: LoginState) => {
    if (login.status !== LoginStatus.LoggedIn)
        return null;
    const role = login.loggedIn!.role;
    switch (role) {
        case UserRole.Administrator:
            return (
                <>
                    <NavItem>
                        <NavLink tag={Link} to='/admin/dashboard'>DASHBOARD</NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink tag={Link} to='/message/me'>MESSAGES</NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink tag={Link} to='/user/all'>USERS</NavLink>
                    </NavItem>
                    {Configuration.enableFeature.medicals &&
                        <>
                            <NavItem>
                                <NavLink tag={Link} to='/practice/all'>PRACTICES</NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink tag={Link} to='/medical/all'>MEDICALS</NavLink>
                            </NavItem>
                        </>
                    }
                    <NavItem>
                        <NavLink tag={Link} to='/organisation/all'>ORGANISATIONS</NavLink>
                    </NavItem>
                </>
            );
        case UserRole.FlyingOrganisationStaff:
            return (
                <>
                    <NavItem>
                        <NavLink tag={Link} to='/dashboard'>DASHBOARD</NavLink>
                    </NavItem>
                    {(login.loggedIn!.featureSet & FeatureSetFlags.User_Messages) !== 0 &&
                        <NavItem>
                            <NavLink tag={Link} to='/message/me'>MESSAGES</NavLink>
                        </NavItem>
                    }
                    {login.loggedIn!.connectionName &&
                        <NavItem>
                            <NavLink tag={Link} to={Configuration.urls.organisationPilots}>{Configuration.text.pilots.toUpperCase()}</NavLink>
                        </NavItem>
                    }
                    <NavItem>
                        <NavLink tag={Link} to='/user/reminder/list/me'>REMINDERS</NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink tag={Link} to='/organisation/manage/myOrganisation'>ORGANISATION</NavLink>
                    </NavItem>
                </>
            );
        case UserRole.MedicalExaminer:
        case UserRole.Nurse:
            return (
                <>
                    <NavItem>
                        <NavLink tag={Link} to='/dashboard'>DASHBOARD</NavLink>
                    </NavItem>
                    {login.loggedIn!.connectionName &&
                        <NavItem>
                            <NavLink tag={Link} to='/medical/all'>MEDICALS</NavLink>
                        </NavItem>
                    }
                    {(login.loggedIn!.featureSet & FeatureSetFlags.User_Messages) !== 0 &&
                        <NavItem>
                            <NavLink tag={Link} to='/message/me'>MESSAGES</NavLink>
                        </NavItem>
                    }
                    {login.loggedIn!.connectionName &&
                        login.loggedIn!.secondaryRole !== SecondaryRole.Locum &&
                        <NavItem>
                            <NavLink tag={Link} to='/practice/pilots'>PILOTS</NavLink>
                        </NavItem>
                    }
                    {login.loggedIn!.connectionName &&
                        <NavItem>
                            <NavLink tag={Link} to='/booking/practice/myPractice'>APPOINTMENTS</NavLink>
                        </NavItem>
                    }
                    <NavItem>
                        <NavLink tag={Link} to='/user/reminder/list/me'>REMINDERS</NavLink>
                    </NavItem>
                </>
            );
        case UserRole.Pilot:
            return (
                <>
                    <NavItem>
                        <NavLink tag={Link} to='/dashboard'>DASHBOARD</NavLink>
                    </NavItem>
                    {(login.loggedIn!.featureSet & FeatureSetFlags.User_Messages) !== 0 &&
                        <NavItem>
                            <NavLink tag={Link} to='/message/me'>MESSAGES</NavLink>
                        </NavItem>
                    }
                    <NavItem>
                        <NavLink tag={Link} to='/user/manage/me'>PROFILE</NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink tag={Link} to='/user/reminder/list/me'>REMINDERS</NavLink>
                    </NavItem>
                    {Configuration.enableFeature.medicals &&
                        <NavItem>
                            <NavLink tag={Link} to='/medical/pilot/me'>MEDICALS</NavLink>
                        </NavItem>
                    }
                    {Configuration.enableFeature.bookings &&
                        <NavItem>
                            <NavLink tag={Link} to='/booking/pilot/me'>BOOKING</NavLink>
                        </NavItem>
                    }
                </>
            );
        case UserRole.RegulatoryAuthorityAgent:
            return (
                <>
                    <NavItem>
                        <NavLink tag={Link} to='/dashboard'>DASHBOARD</NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink tag={Link} to='/medical/all'>MEDICALS</NavLink>
                    </NavItem>
                    {(login.loggedIn!.featureSet & FeatureSetFlags.User_Messages) !== 0 &&
                        <NavItem>
                            <NavLink tag={Link} to='/message/me'>MESSAGES</NavLink>
                        </NavItem>
                    }
                    <NavItem>
                        <NavLink tag={Link} to='/user/reminder/list/me'>REMINDERS</NavLink>
                    </NavItem>
                </>
            );
        case UserRole.Specialist:
        case UserRole.SpecialistStaff:
            return (
                <>
                    <NavItem>
                        <NavLink tag={Link} to='/dashboard'>DASHBOARD</NavLink>
                    </NavItem>
                    {(login.loggedIn!.featureSet & FeatureSetFlags.User_Messages) !== 0 &&
                        <NavItem>
                            <NavLink tag={Link} to='/message/me'>MESSAGES</NavLink>
                        </NavItem>
                    }
                    <NavItem>
                        <NavLink tag={Link} to='/specialist/upload'>UPLOAD</NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink tag={Link} to='/user/reminder/list/me'>REMINDERS</NavLink>
                    </NavItem>
                </>
            );
        default:
            throw Error(`The role ${role} is not supported yet.`);
    }
}

const renderLogin = (login: LoginState, dispatch: Dispatch<any>, history: History<any>) => {
    const onLogout = () => {
        loginActionInvokers.logout();
        history.push('/');
    }
    if (login.status !== LoginStatus.LoggedIn) {
        return (
            <>
                <NavItem>
                    <NavLink tag={Link} to="/user/sign-up" rel='nofollow'>SIGN UP</NavLink>
                </NavItem>
                <NavItem>
                    <NavLink tag={Link} to="/dashboard" rel='nofollow'>LOGIN</NavLink>
                </NavItem>
            </>
        );
    } else {
        return (
            <UncontrolledDropdown nav inNavbar>
                <DropdownToggle nav caret>
                    {login.loggedIn!.displayName}
                </DropdownToggle>
                <DropdownMenu right>
                    <DropdownItem>
                        <NavLink tag={Link} to='/user/manage/me'>
                            My Profile
                        </NavLink>
                    </DropdownItem>
                    <DropdownItem>
                        <NavLink tag={Link} to='/user/change-password'>
                            Change Password
                        </NavLink>
                    </DropdownItem>
                    {(login.loggedIn!.featureSet & FeatureSetFlags.Terms_And_Conditions) !== 0 &&
                        <DropdownItem>
                            <NavLink tag={Link} to={`/info/terms-and-conditions/${login.loggedIn!.role.replace(/ /g, '-')}`}>
                                {login.loggedIn!.displayRole}&nbsp;Terms and Conditions
                            </NavLink>
                        </DropdownItem>
                    }
                    <DropdownItem>
                        <NavLink tag={Link} to='/user/security-questions'>
                            Manage Security Questions
                        </NavLink>
                    </DropdownItem>
                    <DropdownItem>
                        <NavLink tag={Link} to='/user/two-factor'>
                            Manage Two Factor Authentication
                        </NavLink>
                    </DropdownItem>
                    <DropdownItem>
                        <NavLink tag={Link} to='/' onClick={onLogout}>
                            Logout
                        </NavLink>
                    </DropdownItem>
                </DropdownMenu>
            </UncontrolledDropdown>
        );
    }
}
