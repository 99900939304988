import { Action, Reducer } from 'redux';
import { AppThunkAction } from '..';
import { store } from '../..';
import { SpecialDocumentType } from '../medical/MedicalCommon';
import { getJson, postJson, putJson } from '../myfetch';
import { dispatchErrorToast, dispatchToast } from '../notification/NotificationStore';
import { FilledForm, FilledFormAnswer, FilledFormStatus } from './FormCommon';

// From SkyCert v1

// Architecture: v2

// STATE **********************************************************************

export interface FilledFormsState {
	filledForm1: FilledFormState;
	filledForm2: FilledFormState;
	filledForm3: FilledFormState;
}

export interface FilledFormState {
	loadStatus: FilledFormLoadStatus;
	filledForm?: FilledForm;
	saved?: boolean;	// true if save was successful, false if save failed, undefined if changes not saved
}

export enum FilledFormLoadStatus {
	Idle,
	Loading,
	Loaded,
	Failure,
	Saving,
}

// ACTIONS ********************************************************************

interface RequestLoadFilledFormAction { type: 'REQUEST_LOAD_FILLED_FORM'; formNumber: number; }
interface ReceiveLoadFilledFormAction { type: 'RECEIVE_LOAD_FILLED_FORM'; formNumber: number; filledForm?: FilledForm; }

interface RequestSaveFilledFormAction { type: 'REQUEST_SAVE_FILLED_FORM_ACTION'; formNumber: number; }
interface ReceiveSaveFilledFormAction { type: 'RECEIVE_SAVE_FILLED_FORM_ACTION'; formNumber: number; filledForm?: FilledForm; }

interface RequestSetFilledFormStatusAction { type: 'REQUEST_SET_FILLED_FORM_STATUS'; formNumber: number; status: string; }
interface ReceiveSetFilledFormStatusAction { type: 'RECEIVE_SET_FILLED_FORM_STATUS'; formNumber: number; filledForm?: FilledForm; }

interface ClearFilledFormAction { type: 'CLEAR_FILLED_FORM_ACTION'; formNumber: number; }
interface ClearAllFilledFormsAction { type: 'CLEAR_ALL_FILLED_FORMS_ACTION' }
interface MoveFilledFormRightAction { type: 'MOVE_FILLED_FORM_RIGHT'; formNumber: number; }

interface UpdateFilledFormAnswerAction { type: 'UPDATE_FILLED_FORM_ANSWER_ACTION'; formNumber: number; formQuestionId: number; value: string[]; explanation: string[]; }

interface RequestSaveAndCalculateAction { type: 'REQUEST_SAVE_AND_CALCULATE'; formNumber: number; }

type KnownAction = RequestLoadFilledFormAction | ReceiveLoadFilledFormAction
	| RequestSaveFilledFormAction | ReceiveSaveFilledFormAction
	| RequestSetFilledFormStatusAction | ReceiveSetFilledFormStatusAction
	| ClearFilledFormAction | ClearAllFilledFormsAction | MoveFilledFormRightAction
	| UpdateFilledFormAnswerAction
	| RequestSaveAndCalculateAction
	;


// ACTION INVOKERS ////////////////////////////////////////////////////////////

export const filledFormActionInvokers = {
	getMedicalApplicationForm: (formNumber: number, medicalId: string | number | undefined, applicationType: SpecialDocumentType) => {
		const suffix: string = medicalId ? medicalId.toString() : `new?type=${applicationType}`;
		const url: string = `api/filledforms/medicalapplications/${suffix}`;
		actionInvokerUtilities.getFilledForm(formNumber, url);
	},

	updateFilledFormAnswer: (formNumber: number, formQuestionId: number, value: string[], explanation: string[]) => store.dispatch({ type: 'UPDATE_FILLED_FORM_ANSWER_ACTION', formNumber, formQuestionId, value, explanation, } as KnownAction),
	updateFilledForm1Answer: (formQuestionId: number, value: string[], explanation: string[]) => store.dispatch({ type: 'UPDATE_FILLED_FORM_ANSWER_ACTION', formNumber: 1, formQuestionId, value, explanation, } as KnownAction),

	saveMedicalApplicationForm: (formNumber: number, medicalId?: number, applicationType?: SpecialDocumentType) => {
		const url: string = medicalId ? `api/filledforms/medicalapplications/${medicalId}` : `api/filledforms/medicalapplications?type=${applicationType}`;
		const method = medicalId ? putJson : postJson;
		actionInvokerUtilities.saveFilledForm(formNumber, url, method);
	},

	signMedicalApplicationForm: (formNumber: number, medicalId: string | number) => actionInvokerUtilities.setFilledFormStatus(formNumber, 'Signed', `api/filledforms/medicalapplications/${medicalId}/status`),

	clearAllFilledForms: () => store.dispatch({ type: 'CLEAR_ALL_FILLED_FORMS_ACTION', } as KnownAction),

	newForm: async (formNumber: number, userId: string, documentTypeId: number | string) => {
		const url = `/api/filledforms/new/${userId}/${documentTypeId}`;
		actionInvokerUtilities.getFilledForm(formNumber, url);
    },
};


const actionInvokerUtilities = {
	getFilledForm: async (formNumber: number, url: string) => {
		store.dispatch({ type: 'REQUEST_LOAD_FILLED_FORM', formNumber } as KnownAction);
		try {
			const response = await getJson(url);
			store.dispatch({ type: 'RECEIVE_LOAD_FILLED_FORM', formNumber, filledForm: response, } as KnownAction);
		} catch (error) {
			store.dispatch({ type: 'RECEIVE_LOAD_FILLED_FORM', formNumber, } as KnownAction);
			dispatchErrorToast(true, 'Form', error);
		}
	},

	saveFilledForm: async (formNumber: number, url: string, method = putJson) => {
		store.dispatch({ type: 'REQUEST_SAVE_FILLED_FORM_ACTION', formNumber } as KnownAction);
		try {
			const filledForm: FilledForm = (store.getState().form.filledForms! as any)['filledForm' + formNumber].filledForm!;
			const sendData: any = {
				filledFormId: filledForm.filledFormId,
				documentTypeId: filledForm.documentTypeId,
				editedAnswers: filledFormActionCreatorUtilities.getAnswersFor(filledForm),
			};
			const response = await method(url, sendData);
			store.dispatch({ type: 'RECEIVE_SAVE_FILLED_FORM_ACTION', formNumber, filledForm: response, } as KnownAction);
			if (response.validationErrors) {
				dispatchToast(false, 'warning', 'Form', 'The form has been saved but has errors:', ...response.validationErrors);
			}
			store.dispatch({ type: 'INVALIDATE_SELECTED_MEDICAL', });
		} catch (error) {
			store.dispatch({ type: 'RECEIVE_SAVE_FILLED_FORM_ACTION', formNumber, } as KnownAction);
			dispatchErrorToast(true, 'Form', error);
		}
	},

	setFilledFormStatus: async (formNumber: number, newStatus: string, url: string) => {
		store.dispatch({ type: 'REQUEST_SET_FILLED_FORM_STATUS', formNumber } as KnownAction);
		try {
			const response = await putJson(url, { value: newStatus, });
			store.dispatch({ type: 'RECEIVE_SET_FILLED_FORM_STATUS', formNumber, filledForm: response, } as KnownAction);
			dispatchToast(true, 'success', 'Form Status', `The form status has been set to ${newStatus}.`);
		} catch (error) {
			store.dispatch({ type: 'RECEIVE_SET_FILLED_FORM_STATUS', formNumber, } as KnownAction);
			dispatchErrorToast(true, 'Form Status', error);
		}
	},
};


// ACTION CREATORS ************************************************************

export const filledFormActionCreators = {
	requestNewMedicalForm: (formNumber: number, medicalId: number, documentTypeId: number): AppThunkAction<Action> => (dispatch, getState) => {
		const url: string = `api/filledforms/medicals/${medicalId}/new/${documentTypeId}`;
		filledFormActionCreatorUtilities.requestGetFilledForm(formNumber, url)(dispatch, getState);
	},
	requestGetMedicalForm: (formNumber: number, medicalId: number, filledFormId: number): AppThunkAction<Action> => (dispatch, getState) => {
		const url: string = `api/filledforms/medicals/${medicalId}/${filledFormId}`;
		filledFormActionCreatorUtilities.requestGetFilledForm(formNumber, url)(dispatch, getState);
	},
	requestResetExpiryDates: (formNumber: number): AppThunkAction<Action> => (dispatch, getState) => {
		const filledForm: FilledForm = (getState().form.filledForms! as any)['filledForm' + formNumber].filledForm!;
		const url: string = `api/filledforms/medicals/${filledForm.medicalId}/${filledForm.filledFormId}?resetExpiryDates=true`;
		filledFormActionCreatorUtilities.requestGetFilledForm(formNumber, url)(dispatch, getState);
	},
	requestSaveMedicalForm: (formNumber: number, medicalId: number): AppThunkAction<Action> => (dispatch, getState) => {
		const filledForm: FilledForm = (getState().form.filledForms! as any)['filledForm' + formNumber].filledForm!;
		const urlSuffix: string = filledForm.filledFormId ? `/${filledForm.filledFormId}` : '';
		const url: string = `api/filledforms/medicals/${medicalId}${urlSuffix}`;
		const method = filledForm.filledFormId ? putJson : postJson;
		filledFormActionCreatorUtilities.requestSaveFilledForm(formNumber, url, method)(dispatch, getState);
	},
	requestSetMedicalFormStatus: (formNumber: number, medicalId: number, newStatus: FilledFormStatus): AppThunkAction<Action> => (dispatch, getState) => {
		const filledForm: FilledForm = (getState().form.filledForms! as any)['filledForm' + formNumber].filledForm!;
		filledFormActionCreatorUtilities.requestSetFilledFormStatus(formNumber, newStatus,
			`api/filledforms/medicals/${medicalId}/${filledForm.filledFormId}/status`)(dispatch, getState);
	},

	requestGetUnassignedHl7Form: (formNumber: number, filledFormId: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
		const url = `api/filledforms/unassignedhl7/${filledFormId}`;
		filledFormActionCreatorUtilities.requestGetFilledForm(formNumber, url)(dispatch, getState);
	},

	clearFilledForm: (formNumber: number) => ({ type: 'CLEAR_FILLED_FORM_ACTION', formNumber } as KnownAction),
	clearAllFilledForms: () => ({ type: 'CLEAR_ALL_FILLED_FORMS_ACTION' } as KnownAction),
	moveFilledFormRight: (formNumber: number) => ({ type: 'MOVE_FILLED_FORM_RIGHT', formNumber, } as KnownAction),

	updateFilledFormAnswer: (formNumber: number, formQuestionId: number, value: string[], explanation: string[]) => ({ type: 'UPDATE_FILLED_FORM_ANSWER_ACTION', formNumber, formQuestionId, value, explanation, } as KnownAction),

	saveAndCalculate: (formNumber: number, medicalId?: number, pattern?: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
		dispatch({ type: 'REQUEST_SAVE_AND_CALCULATE', formNumber, });
		const filledForm: FilledForm = (getState().form.filledForms! as any)['filledForm' + formNumber].filledForm!;
		const urlSuffix: string = filledForm.filledFormId ? `/${filledForm.filledFormId}` : '';
		const url: string = `api/filledforms/medicals/${medicalId}${urlSuffix}?calculate=${pattern || 'all'}`;
		const method = filledForm.filledFormId ? putJson : postJson;
		filledFormActionCreatorUtilities.requestSaveFilledForm(formNumber, url, method)(dispatch, getState);
	},
};

export const filledFormActionCreatorUtilities = {
	requestGetFilledForm: (formNumber: number, url: string): AppThunkAction<Action> => (dispatch, getState) => {
		getJson(url)
			.then(response => {
				dispatch({ type: 'RECEIVE_LOAD_FILLED_FORM', formNumber, filledForm: response, } as KnownAction);
			})
			.catch(error => {
				dispatch({ type: 'RECEIVE_LOAD_FILLED_FORM', formNumber, } as KnownAction);
				dispatchErrorToast(true, 'Form', error);
			});
		dispatch({ type: 'REQUEST_LOAD_FILLED_FORM', formNumber } as KnownAction);
	},

	requestSaveFilledForm: (formNumber: number, url: string, method = putJson): AppThunkAction<Action> => (dispatch, getState) => {
		const filledForm: FilledForm = (getState().form.filledForms! as any)['filledForm' + formNumber].filledForm!;
		const sendData: any = {
			filledFormId: filledForm.filledFormId,
			documentTypeId: filledForm.documentTypeId,
			editedAnswers: filledFormActionCreatorUtilities.getAnswersFor(filledForm),
		};
		method(url, sendData)
			.then(response => {
				dispatch({ type: 'RECEIVE_SAVE_FILLED_FORM_ACTION', formNumber, filledForm: response, } as KnownAction);
				if (response.validationErrors) {
					dispatchToast(false, 'warning', 'Form', 'The form has been saved but has errors:', ...response.validationErrors);
				}
				dispatch({ type: 'INVALIDATE_SELECTED_MEDICAL', });
			})
			.catch(error => {
				dispatch({ type: 'RECEIVE_SAVE_FILLED_FORM_ACTION', formNumber, } as KnownAction);
				dispatchErrorToast(true, 'Form', error);
			});
		dispatch({ type: 'REQUEST_SAVE_FILLED_FORM_ACTION', formNumber } as KnownAction);
	},

	requestSetFilledFormStatus: (formNumber: number, newStatus: string, url: string): AppThunkAction<Action> => (dispatch, getState) => {
		putJson(url, { value: newStatus, })
			.then(response => {
				dispatch({ type: 'RECEIVE_SET_FILLED_FORM_STATUS', formNumber, filledForm: response, } as KnownAction);
				dispatchToast(true, 'success', 'Form Status', `The form status has been set to ${newStatus}.`);
			})
			.catch(error => {
				dispatch({ type: 'RECEIVE_SET_FILLED_FORM_STATUS', formNumber, } as KnownAction);
				dispatchErrorToast(true, 'Form Status', error);
			});
		dispatch({ type: 'REQUEST_SET_FILLED_FORM_STATUS', formNumber } as KnownAction);
	},

	getAnswersFor: (filledForm: FilledForm) => {
		let answers: any = [];
		filledForm.formSections.forEach(fs => {
			let formAnswers: FilledFormAnswer[] = fs.formAnswers;
			if (fs.isCollapsible
				&& (formAnswers[0].formQuestionType === 'Checkbox' || formAnswers[0].formQuestionType === 'CheckboxInverted'
					|| formAnswers[0].formQuestionType === 'YesNo' || formAnswers[0].formQuestionType === 'YesNoInverted'
					|| formAnswers[0].formQuestionType === 'Options' || formAnswers[0].formQuestionType === 'OptionsInverted')) {
				let isEnabled: boolean = false;
				let value: string = formAnswers[0].value[0];
				if (formAnswers[0].formQuestionType === 'Checkbox')
					isEnabled = value === 'true';
				else if (formAnswers[0].formQuestionType === 'CheckboxInverted')
					isEnabled = value !== 'true';
				else if (formAnswers[0].formQuestionType === 'YesNo')
					isEnabled = value === 'yes';
				else if (formAnswers[0].formQuestionType === 'YesNoInverted')
					isEnabled = value === 'no';
				else if (formAnswers[0].formQuestionType === 'OptionsInverted')
					isEnabled = !(!!value && value !== formAnswers[0].options!.split('|')[0]);
				else isEnabled = !!value && value !== formAnswers[0].options!.split('|')[0];
				if (!isEnabled)
					formAnswers = [formAnswers[0]];
			}
			formAnswers.forEach(fa => {
				answers.push({
					formQuestionId: fa.formQuestionId,
					value: fa.value,
					explanation: fa.explanation,
				});
			});
		});
		return answers;
	}
};

// INITIAL STATE **************************************************************

const initialFilledFormState: FilledFormState = {
	loadStatus: FilledFormLoadStatus.Idle,
}

const initialState: FilledFormsState = {
	filledForm1: initialFilledFormState,
	filledForm2: initialFilledFormState,
	filledForm3: initialFilledFormState,
}

// REDUCERS *******************************************************************

// TODO @@@ Don't hard code the details
const Condition020Type = '020';
const Condition020Details = 'Restricted in accordance with medical directions in letter dated {date}';
const Condition059Type = '059';
const Condition059Details = 'Subject to medical surveillance as specified in examiner\'s letter dated {date}';

export const reducer: Reducer<FilledFormsState | null> = (state: FilledFormsState | null | undefined, action: KnownAction) => {
	if (state === undefined)
		return null;
	state = state!;	// @@@ I hope this works
	switch (action.type) {
		case 'REQUEST_LOAD_FILLED_FORM':
			return {
				...(state || initialState),
				['filledForm' + action.formNumber]: {
					loadStatus: FilledFormLoadStatus.Loading,
					filledForm: undefined,
					saved: undefined,
				},
			};
		case 'RECEIVE_LOAD_FILLED_FORM':
			return {
				...state,
				['filledForm' + action.formNumber]: {
					loadStatus: !action.filledForm ? FilledFormLoadStatus.Failure : FilledFormLoadStatus.Loaded,
					filledForm: action.filledForm,
					saved: action.filledForm && action.filledForm!.filledFormId !== 0 ? true : undefined,
				},
			};
		case 'REQUEST_SAVE_FILLED_FORM_ACTION': {
			const filledFormName: string = 'filledForm' + action.formNumber;
			return {
				...state,
				[filledFormName]: {
					...(state as any)[filledFormName],
					loadStatus: FilledFormLoadStatus.Saving,
				}
			};
		}
		case 'RECEIVE_SAVE_FILLED_FORM_ACTION': {
			const filledFormName: string = 'filledForm' + action.formNumber;
			const filledForm: any = action.filledForm || (state as any)[filledFormName].filledForm!;
			return {
				...state,
				[filledFormName]: {
					loadStatus: FilledFormLoadStatus.Loaded,
					filledForm,
					saved: !!action.filledForm,
				}
			};
		}
		case 'CLEAR_FILLED_FORM_ACTION':
			return {
				...state,
				['filledForm' + action.formNumber]: initialFilledFormState,
			};
		case 'CLEAR_ALL_FILLED_FORMS_ACTION':
			return initialState;
		case 'MOVE_FILLED_FORM_RIGHT':
			const leftFormName: string = 'filledForm' + action.formNumber;
			const leftForm: FilledFormState = (state as any)[leftFormName];
			const formNumber1: number = action.formNumber + 1;
			const rightFormName: string = 'filledForm' + formNumber1;
			const rightForm: FilledFormState = (state as any)[rightFormName];
			return {
				...state,
				[leftFormName]: rightForm,
				[rightFormName]: leftForm,
			};
		case 'UPDATE_FILLED_FORM_ANSWER_ACTION': {
			const filledFormName: string = 'filledForm' + action.formNumber;
			const oldFormState: FilledFormState = (state as any)[filledFormName];
			const formQuestion: FilledFormAnswer = getFilledFormQuestion(oldFormState.filledForm!, action.formQuestionId);
			const updateCustomCode: number = formQuestion.customCode && 3000 <= formQuestion.customCode && formQuestion.customCode < 4000 ?
				formQuestion.customCode : 0;
			const isRequirementType = formQuestion.customCode === 4977;
			const has020 = isRequirementType && !!action.value.find(v => v === '020');
			const has059 = isRequirementType && !!action.value.find(v => v === '059');
			const formSections = oldFormState.filledForm!.formSections.map(fs => {
				if (isRequirementType && fs.customCode === 1003) {	// 1003 is for EndorsementsSectionEditor
					let anyEndorsements: FilledFormAnswer = { ...fs.formAnswers[0] };
					let codes: FilledFormAnswer = { ...fs.formAnswers[1] };
					let details: FilledFormAnswer = { ...fs.formAnswers[2] };
					if (has020) {
						if (anyEndorsements.value[0] !== 'true') {
							anyEndorsements.value[0] = 'true';
							codes.value[0] = Condition020Type;
							details.value[0] = Condition020Details;
						} else if (!codes.value.find(v => v === Condition020Type)) {
							codes.value.push(Condition020Type);
							codes.explanation.push('');
							details.value.push(Condition020Details);
							details.explanation.push('');
						}
					} else if (codes.value.length === 1 && codes.value[0] === Condition020Type) {
						anyEndorsements.value[0] = 'false';
						codes.value[0] = '';
						details.value[0] = '';
					} else {
						const index = codes.value.indexOf(Condition020Type);
						if (index !== -1) {
							codes.value = codes.value.filter((v, i) => i !== index);
							details.value = details.value.filter((v, i) => i !== index);
						}
					}
					if (has059) {
						if (anyEndorsements.value[0] !== 'true') {
							anyEndorsements.value[0] = 'true';
							codes.value[0] = Condition059Type;
							details.value[0] = Condition059Details;
						} else if (!codes.value.find(v => v === Condition059Type)) {
							codes.value.push(Condition059Type);
							codes.explanation.push('');
							details.value.push(Condition059Details);
							details.explanation.push('');
						}
					} else if (codes.value.length === 1 && codes.value[0] === Condition059Type) {
						anyEndorsements.value[0] = 'false';
						codes.value[0] = '';
						details.value[0] = '';
					} else {
						const index = codes.value.indexOf(Condition059Type);
						if (index !== -1) {
							codes.value = codes.value.filter((v, i) => i !== index);
							details.value = details.value.filter((v, i) => i !== index);
						}
					}
					return {
						...fs,
						formAnswers: [anyEndorsements, codes, details, ...fs.formAnswers.slice(3)],
					};
				}
				return {
					...fs,
					formAnswers: fs.formAnswers.map(fa => fa.formQuestionType === 'Calculation'
						? {
							...fa,
							value: [''],
							explanation: [undefined],
						}
						: fa
					),
				};
			});
			const newFormState = {
				...oldFormState,
				saved: undefined,
				filledForm: {
					...oldFormState.filledForm!,
					formSections: formSections.map(fs => {
						return {
							...fs,
							formAnswers: fs.formAnswers.map(fa => fa.formQuestionId === action.formQuestionId
								|| (formQuestion.customCode !== 0 && fa.customCode && fa.customCode === updateCustomCode)
								? {
									...fa,
									value: action.value,
									explanation: action.explanation,
								}
								: fa
							),
						};
					}),
				},
			};
			return {
				...state,
				[filledFormName]: newFormState,
			};
		}
		case 'REQUEST_SAVE_AND_CALCULATE': {
			const filledFormName: string = 'filledForm' + action.formNumber;
			const oldFormState: FilledFormState = (state as any)[filledFormName];
			const newFormState = {
				...oldFormState,
				saved: undefined,
				filledForm: {
					...oldFormState.filledForm!,
					formSections: oldFormState.filledForm!.formSections.map(fs => {
						return {
							...fs,
							formAnswers: fs.formAnswers.map(fa => fa.formQuestionType === 'Calculation'
								? {
									...fa,
									value: [ 'Calculating ', ],
									explanation: [ undefined, ],
								}
								: fa
							),
						};
					}),
				},
			};
			return {
				...state,
				[filledFormName]: newFormState,
			};
		}
		case 'REQUEST_SET_FILLED_FORM_STATUS':
			const filledFormName: string = 'filledForm' + action.formNumber;
			return {
				...state,
				[filledFormName]: {
					...(state as any)[filledFormName],
					loadStatus: FilledFormLoadStatus.Saving,
				},
			};
		case 'RECEIVE_SET_FILLED_FORM_STATUS': {
			const filledFormName: string = 'filledForm' + action.formNumber;
			const filledForm: any = action.filledForm || (state as any)[filledFormName].filledForm!;
			return {
				...state,
				[filledFormName]: {
					loadStatus: FilledFormLoadStatus.Loaded,
					filledForm,
					saved: !!action.filledForm,
				}
			};
		}
		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		default: const exhaustiveCheck: never = action;
	}
	return state;
}

const getFilledFormQuestion = (filledForm: FilledForm, formQuestionId: number): FilledFormAnswer => {
	for (let section of filledForm.formSections) {
		for (let question of section.formAnswers) {
			if (question.formQuestionId === formQuestionId)
				return question;
		}
	}
	throw new Error();	// Should never get here
}
