import * as React from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { CardBody } from 'reactstrap';
import { ApplicationState } from '../../store';
import { SummaryLoadStatus2 } from '../../store/summary/SummaryCommon';
import * as UserSummaryStore from '../../store/summary/UserSummaryStore';
import Loading from '../support/Loading';
import { StandardSummarySize } from '../support/Summaries';
import { View } from '../support/Views';

// From SkyCert v1

type AllRemindersSummaryProps =
	UserSummaryStore.RemindersState
	& typeof UserSummaryStore.userSummaryActionCreators
	& RouteComponentProps<{}>;

// Architecture: v2

class AllRemindersSummary extends React.Component<AllRemindersSummaryProps, {}> {
	// eslint-disable-next-line @typescript-eslint/no-useless-constructor
	constructor(props: any) {
		super(props);
	}

	componentDidMount() {
		this.props.requestRemindersSummary();
    }

	public render() {
		const mode: string = this.props.status !== SummaryLoadStatus2.Loaded ? 'primary'
			: this.props.numberOfRemindersWarning !== 0 ? 'danger'
				: this.props.numberOfRemindersEarlyWarning !== 0 ? 'warning'
					: 'primary';
		return <StandardSummarySize>
			<View color={mode} header='Reminders' onClickHeader={this.goToReminders}>
				<CardBody>
					{this.renderBody()}
					<Link to='/user/reminder/list/me'>Manage</Link>
					{this.props.numberOfReminders === this.props.numberOfRemindersTotal ? null : <span><br /><Link to='/user/reminder/list/all'>Manage all</Link></span>}
				</CardBody>
            </View>
		</StandardSummarySize>;
	}

	goToReminders = () => {
		this.props.history.push('/user/reminder/list/me');
	}

	private renderBody() {
		if (this.props.status === SummaryLoadStatus2.Loading) {
			return <Loading />;
		} else if (this.props.status === SummaryLoadStatus2.Loaded) {
			return <>
				{this.props.numberOfReminders !== this.props.numberOfRemindersTotal &&
					<p>{this.props.numberOfRemindersTotal} total.</p>
				}
				<p>
					{this.props.numberOfReminders} total.<br />
					{this.props.numberOfRemindersWarning} reminders.<br />
					{this.props.numberOfRemindersEarlyWarning} early warnings.
				</p>
				{!!this.props.reminderDate &&
					<p>Next on {this.props.reminderDate}<br />{this.props.reminderText}</p>
				}
			</>;
		} else if (this.props.status === SummaryLoadStatus2.Failure) {
			return <p className='text-danger'>Unable to load.</p>;
		} else {	// SummaryLoadStatus2.Idle, blank
			return null;	// This should only ever occur briefly.
		}
	}
}

export default connect(
	(state: ApplicationState) => state.summary.reminders || {},
	UserSummaryStore.userSummaryActionCreators
)(AllRemindersSummary) /*as typeof AllRemindersSummary*/;
