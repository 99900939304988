import * as React from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { Col, Container, Row } from 'reactstrap';
import Banner1 from '../res/ok-banner.jpg';
import './Home.css';

export default () => (
	<>
		<Helmet>
			<title>OneKete</title>
			<meta name='description' content='OneKete.' />
			<meta name='robots' content='index, follow' />
		</Helmet>
		<img src={Banner1} alt='' className='banner' />
		<div className='bannertext'>
			<div>
				<div>Welcome to</div>
				<div>OneKete</div>
			</div>
		</div>
		<div className='mantra'>
			<Container>
				<Row>
					<Col className='qed' xs={4}>
						<div><span>Q</span>uick</div>
						<div>&nbsp;&nbsp;&nbsp;&nbsp;<span>E</span>asy</div>
						<div>well&nbsp;<span>D</span>one</div>
					</Col>
					<Col xs={4}>
						<p>
							OneKete is part of SkyCert.
							SkyCert provides simple and secure storage and management of pilot's medical records.
						</p>
						<p>
							Our mantra is Quick, Easy, and Well Done.
						</p>
						<p>
							OneKete builds on this platform to provide simple and secure management of your test results.
							We have a great deal of extra features that we intend to add into OneKete.
							These will be progressively rolled out.
							We look forward to you enjoying the benefits of OneKete.
							You will be as excited as we are when you start to see our future plans to help you coming online.
						</p>
					</Col>
					<Col xs={4}>
						<p>
							We would also be interested to hear if you have any ideas you would like us to add for you.
							OneKete has been written with the user in mind.
						</p>
						<p className='mantrabutton'>
							<Link to='/user/sign-up' rel='nofollow'><span>SIGN UP TO ONEKETE</span></Link>
						</p>
					</Col>
				</Row>
			</Container>
		</div>
    </>
);
