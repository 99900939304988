import * as React from 'react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Prompt } from 'react-router';
import { Button, Form } from 'reactstrap';
import { ApplicationState } from '../../store';
import { LoadStatus2 } from '../../store/StoreCommon';
import { accountActionCreators, AccountState, SecurityQuestions } from '../../store/user/AccountStore';
import { loginActionCreators, LoginState, RequiredActionFlags } from '../../store/user/LoginStore';
import { Page } from '../app/Page';
import { FieldLength } from '../support/FieldLength';
import Loading from '../support/Loading';
import { SelectEditor } from '../support/NonTextEditors';
import { TextEditor } from '../support/TextEditors';
import { StandardViewSize } from '../support/Views';
import * as Configuration from '../../Configuration';

const NumberOfSecurityQuestions = 5;

export default () => {
	const userState = useSelector(state => (state as ApplicationState).user);
	const account = userState.account;
	const login = userState.login;
	const dispatch = useDispatch();

	// If have date required and requested but not required
	const showAskLater: boolean = !!login.loggedIn!.actions.dateQuestionsRequired
		&& (login.loggedIn!.actions.actionsRequested & RequiredActionFlags.ConfigureSecurityQuestions) !== 0
		&& (login.loggedIn!.actions.actionsRequired & RequiredActionFlags.ConfigureSecurityQuestions) === 0;
	// If have date required and not required yet
	const showTimeText: boolean = !!login.loggedIn!.actions.dateQuestionsRequired
		&& (login.loggedIn!.actions.actionsRequired & RequiredActionFlags.ConfigureSecurityQuestions) === 0;
	return (
		<Page defaultHelmet='Security Questions'>
			<Prompt when={!!account && account.securityQuestionsStatus === LoadStatus2.Changed}
				message={'Your unsaved edits will be lost.\nAre you sure you want to leave this page?'}
			/>
			<h1>Security Questions</h1>
			<p>
				These security questions will be used to authenticate you should you need to recover access to your {Configuration.text.siteName} account.
			</p>
			{showTimeText &&
				<p>You can continue to use {Configuration.text.siteName} until {login.loggedIn!.actions.dateQuestionsRequired}, but from this date you must configure the security questions to continue using {Configuration.text.siteName}.</p>
			}
			{showAskLater &&
				<p><Button color='warning' onClick={() => dispatch(loginActionCreators.clearLoginActionFlag(RequiredActionFlags.ConfigureSecurityQuestions | RequiredActionFlags.ConfigureTwoFactorAuthentication))}>Ask me later</Button></p>
			}
			<StandardViewSize>
				<SecurityQuestionsForm
					account={account}
					login={login}
					dispatch={dispatch}
				/>
			</StandardViewSize>
		</Page>
	);
}

interface SecurityQuestionsFormProps {
	account?: AccountState | null;
	login: LoginState;
	dispatch: any;
}

const SecurityQuestionsForm = (props: SecurityQuestionsFormProps) => {
	const FormName = 'securityQuestionsForm';

	useEffect(() => {
		if ((props.login.loggedIn!.actions.actionsRequested & RequiredActionFlags.ConfigureSecurityQuestions) === 0)
			props.dispatch(accountActionCreators.getSecurityQuestions())
		else props.dispatch(accountActionCreators.clearSecurityQuestions())
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const saveAnswers = (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault();
		let form: any = document.getElementById(FormName);
		if (form.checkValidity())
			props.dispatch(accountActionCreators.saveSecurityQuestions());
	}

	if (!props.account || !props.account.securityQuestionsStatus) {
		return null;
	} else if (props.account.securityQuestionsStatus === LoadStatus2.Loading) {
		return <Loading />;
	} else if (props.account.securityQuestionsStatus === LoadStatus2.Saving) {
		return <Loading action='Saving' />;
	} else if (props.account.securityQuestionsStatus === LoadStatus2.Failure) {
		return <p className='text-danger'>Unable to load the security questions.  If this continues please contact {Configuration.text.siteName}.</p>;
	} else if (props.account.securityQuestionsStatus === LoadStatus2.Loaded
		|| props.account.securityQuestionsStatus === LoadStatus2.Changed) {
		let controls: JSX.Element[] = [];
		const securityQuestionsState = props.account.securityQuestions! as any;
		for (let iAnswer = 1; iAnswer <= NumberOfSecurityQuestions; iAnswer++) {
			const iAnswerText = iAnswer.toString();
			if (iAnswer > 1)
				controls.push(<hr key={'h' + iAnswerText} />);
			const questionName = `question${iAnswer}Id`;
			const answerName = `question${iAnswer}Answer`;
			controls.push(
				<SelectEditor name={questionName} key={'s' + iAnswerText} id={'selectSecurityQuestion' + iAnswerText}
					label={`Question ${iAnswer}`}
					required
					prompt='Select a security question'
					value={securityQuestionsState[questionName]}
					valueIsNumber
					onChangeValue={value => props.dispatch(accountActionCreators.updateSecurityQuestion(questionName, value))}
				>
					{SecurityQuestions.map((q, index) => <option value={index + 1} key={index}>{q}</option>)}
				</SelectEditor>
			);
			controls.push(
				<TextEditor name={answerName} key={'t' + iAnswerText}
					label='Answer'
					required
					value={securityQuestionsState[answerName]}
					onChangeValue={value => props.dispatch(accountActionCreators.updateSecurityQuestion(answerName, value))}
					maxLength={FieldLength.SecurityQuestionLength}
				/>
			);
		}
		return (
			<Form onSubmit={saveAnswers} id={FormName}>
				{controls}
				<p>
					<Button type='submit' className='col-12' color='primary'>Save</Button>
				</p>
			</Form>
		);
	} else {
		throw new Error(props.account.securityQuestionsStatus + ' is not a supported status.');
	}
}