import * as React from 'react';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import { ApplicationState } from '../../store';
import { loginActionInvokers, LoginStatus } from '../../store/user/LoginStore';
import { UserRole } from '../../store/user/UserCommon';
import Loading from '../support/Loading';
import { LoginSelectConnectionEditor, LoginWithSecurityQuestionsEditor, LoginWithTwoFactorEditor, LoginWithUsernameEditor } from './LoginEditors';

export const Login = () => {
	const [password, setPassword] = useState('');
	const login = useSelector(state => (state as ApplicationState).user.login);
	const globalSettings = useSelector(state => (state as ApplicationState).globalSettings);
	const location = useLocation();
	const history = useHistory();

	React.useEffect(() => {
		if (!login.isTimeout) {
			const pathname = location.pathname.toLowerCase();
			if (pathname.startsWith('/admin/'))
				loginActionInvokers.updateLoginCredentials('role', UserRole.Administrator, true);
			else if (pathname === '/dashboard' && login.credentials.role === UserRole.Administrator)
				loginActionInvokers.updateLoginCredentials('role', UserRole.MedicalExaminer, true);
		}
	//  eslint-disable-next-line react-hooks/exhaustive-deps
	}, [location.pathname]);

	const loginNow = (pwd?: string) => {
		if (!!pwd)
			setPassword(pwd);
		loginActionInvokers.loginNow(pwd || password);
	}

	const loginConnection = (connectionId: number) => {
		loginActionInvokers.updateLoginCredentials('connectionId', connectionId, false);
		loginActionInvokers.loginNow(password);
	}

	const logout = () => {
		loginActionInvokers.logout();
		history.push('/');
	}

	if (login.status === LoginStatus.LoggedIn)
		return null;

	return (
		<Modal backdrop='static' isOpen toggle={logout}>
			<ModalHeader>Login</ModalHeader>
			<ModalBody>
				{login.status === LoginStatus.EnteringDetails &&
					<LoginWithUsernameEditor
						raName={globalSettings.raName}
						credentials={login.credentials}
						isTimeout={!!login.isTimeout}
						onUpdateCredentials={loginActionInvokers.updateLoginCredentials}
						onLogin={loginNow}
						onCancel={logout}
					/>
				}
				{login.status === LoginStatus.EnteringTfa &&
					<LoginWithTwoFactorEditor
						credentials={login.credentials}
						allowSecurityQuestions={login.questions?.[0] !== -1}
						onUpdateCredentials={loginActionInvokers.updateLoginCredentials}
						onLogin={loginNow}
					onCancel={logout}
					onUseSecurityQuestions={loginActionInvokers.useLoginSecurityQuestions}
					/>
				}
				{login.status === LoginStatus.EnteringAnswers &&
					<LoginWithSecurityQuestionsEditor
						credentials={login.credentials}
						questions={login.questions!}
						onUpdateCredentials={loginActionInvokers.updateLoginCredentials}
						onLogin={loginNow}
						onCancel={logout}
					/>
				}
				{login.status === LoginStatus.SelectingConnection &&
					<LoginSelectConnectionEditor
						role={login.credentials.role}
						connections={login.connections!}
						onLogin={loginConnection}
						onCancel={logout}
					/>
				}
				{login.status === LoginStatus.Submitting &&
					<Loading action='Logging in' />
				}
			</ModalBody>
		</Modal>
	);
}
